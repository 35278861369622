import { DocumentNode, gql } from '@apollo/client'

import { Seller } from 'types/types'

export type ISetSellerStatusIndex = {
  setSellerStatusIndex: Seller
}

export type ISetSellerStatusIndexParams = {
  id: IPrimaryKey
  status_index: boolean
}

export const buildSetSellerStatusIndexQuery = (fields: string): DocumentNode => gql`
  mutation setSellerStatusIndex($id: ID!, $status_index: Boolean!) {
    setSellerStatusIndex(id: $id, status_index: $status_index) {
      ${fields}
    }
  }
`
