// import { Seller } from 'types/types';

const baseSellerDefaultFields = {
  closed: false,
  show_variants: false,
  hide_amount: false,
  only_authorised_order: false,
  expanded_view: false,
}

export default baseSellerDefaultFields
