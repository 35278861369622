import { DocumentNode, gql } from '@apollo/client'

import { FileInput, IOrder } from 'types/types'

export type IAddOrderFilesResponse = {
  addOrderFiles?: IOrder
}

export type IAddOrderFilesRequest = {
  order_id: IPrimaryKey
  files?: FileInput[]
}

export const buildAddOrderFilesQuery = (fields: string): DocumentNode => gql`
  mutation addOrderFiles($order_id: ID!, $files: [FileInput]) {
    addOrderFiles(order_id: $order_id, files: $files) {
      ${fields}
    }
  }
`
