import React, { useMemo, useCallback, useEffect } from 'react'
import { useQuery, useLazyQuery } from '@apollo/client'
import { useField } from 'formik'

import Preloader from 'components/Preloader'
import Link from 'components/Link'
import SelectField from 'components/SelectField'
import SelectInput from 'components/Inputs/SelectInput'
import { ICategoryAttribute } from 'types/types'
import { buildGetCategoriesQuery, IGetCategories, IGetCategoriesParams } from 'graphql/queries/getCategories'
import { buildGetCategoryQuery, IGetCategory, IGetCategoryParams } from 'graphql/queries/getCategory'
import useCmsParams from 'hooks/useCmsParams'
import { H4 } from 'components/Typography'

import styles from './categoryGroupInput.module.scss'

type ICategoryGroupInputProps = {
  name: string
  category_id: string | null
}

type IAttributeInputProps = {
  name: string
  categoryAttribute: ICategoryAttribute
}

const GET_CATEGORIES = buildGetCategoriesQuery(`
  id
  name
`)

const GET_CATEGORY = buildGetCategoryQuery(`
  id
  category_attributes {
    id
    attribute {
      id
      name
    }
    attribute_values {
      id
      attribute_id
      value
    }
  }
`)

const AttributeInput: React.FC<IAttributeInputProps> = ({ name, categoryAttribute }) => {
  const { attribute, attribute_values } = categoryAttribute

  const [{ value }, meta, { setValue, setTouched }] = useField<string | null>(name)

  const attributeOptions = useMemo(
    () =>
      attribute_values.map((option) => ({
        id: option.id,
        name: option.value,
      })),
    [attribute_values],
  )

  const onChangeHandler = useCallback(
    (valueId: string) => {
      setTouched(true)
      setValue(valueId)
    },
    [setValue, setTouched],
  )

  const onClear = useCallback(() => {
    setTouched(true)
    setValue(null)
  }, [setValue, setTouched])

  return (
    <SelectField
      className={styles.block}
      label={attribute.name}
      placeholder="Выберите атрибут"
      value={value}
      options={attributeOptions}
      onChange={onChangeHandler}
      onClear={onClear}
    />
  )
}

const CategoryGroupInput: React.FC<ICategoryGroupInputProps> = ({ name, category_id }) => {
  const { sellerId } = useCmsParams()

  const { data: categoriesData, loading: categoriesLoading } = useQuery<IGetCategories, IGetCategoriesParams>(
    GET_CATEGORIES,
    {
      variables: { seller_id: sellerId },
      fetchPolicy: 'network-only',
    },
  )

  const categoriesOptions = useMemo(() => {
    if (!categoriesData) return []
    return categoriesData.getCategories.list
  }, [categoriesData])

  const [getCategory, { data: categoryData, loading: categoryLoading }] = useLazyQuery<
    IGetCategory,
    IGetCategoryParams
  >(GET_CATEGORY, {
    fetchPolicy: 'network-only',
  })

  const categoryAttributes = useMemo(() => {
    if (!categoryData) return []
    return categoryData.getCategory.category_attributes
  }, [categoryData])

  useEffect(() => {
    if (!category_id) return
    getCategory({ variables: { id: category_id } })
  }, [category_id])

  return (
    <div>
      <H4 className={styles.title} color="textSecondary">
        Категории
      </H4>
      {categoriesLoading ? (
        <div className={styles.loaderContainer}>
          <Preloader variant="relative" />
        </div>
      ) : (
        <>
          <Link href={`/${sellerId}/catalog/categories`} className={styles.link}>
            Настроить категории
          </Link>

          <SelectInput
            className={styles.categorySelect}
            name="category_id"
            label="Категория товара"
            hasClear
            showActiveId
            options={categoriesOptions}
          />

          {category_id ? (
            <>
              <Link href={`/${sellerId}/catalog/categories/${category_id}`} className={styles.link}>
                Настроить атрибуты
              </Link>
              <div>
                {categoryLoading ? (
                  <div className={styles.loaderContainer}>
                    <Preloader variant="relative" />
                  </div>
                ) : (
                  categoryAttributes.map((categoryAttribute) => {
                    const attrId = categoryAttribute.attribute.id
                    return (
                      <AttributeInput
                        key={attrId}
                        name={`${name}.${attrId}`}
                        categoryAttribute={categoryAttribute}
                      />
                    )
                  })
                )}
              </div>
            </>
          ) : null}
        </>
      )}
    </div>
  )
}

export default CategoryGroupInput
