import React, { useCallback, useState } from 'react'
import { useField } from 'formik'

import DropMenu, { TitleCounter } from 'components/DropMenu'
import Button from 'components/Button'
import Text from 'components/Typography'
import { IClientFormValues } from 'components/forms/ClientForm'
import ListControlGroup from 'components/ListControlGroup'
import Link from 'components/Link'
import EditIcon from 'components/Icons/EditIcon'
import TrashIcon from 'components/Icons/TrashIcon'
import useBoolean from 'hooks/useBoolean'
import useCmsParams from 'hooks/useCmsParams'
import formikFieldEvent from 'utils/formInputs/formikFieldEvent'

import CreateCompanyDialog from '../CreateCompanyDialog'
import EditCompanyDialog from '../EditCompanyDialog'

import styles from './CompaniesInput.module.scss'

type ICompaniesListProps = {
  companies: IClientFormValues['companies']
  onEdit: (payload: number) => void
  onDelete: (payload: number) => void
}

const Row: React.FC = ({ children }) => <div className={styles.row}>{children}</div>

const CompaniesList: React.FC<ICompaniesListProps> = ({ companies, onEdit, onDelete }) => {
  const { sellerId, clientId } = useCmsParams()

  return (
    <>
      <Row>
        <Text bold size="small">
          Наименование
        </Text>
        <Text bold size="small">
          ИНН
        </Text>
      </Row>

      {companies.map((company, index) => (
        <Row key={index}>
          {company.id ? (
            <Link className={styles.name} href={`/${sellerId}/clients/${clientId}/company/${company.id}`}>
              {company.name}
            </Link>
          ) : (
            <Text className={styles.name}>{company.name}</Text>
          )}

          <Text>{company.inn}</Text>
          <ListControlGroup>
            <Link component="button" startIcon={<EditIcon />} onClick={() => onEdit(index)}>
              Редактировать
            </Link>
            {companies.length > 1 ? (
              <Link component="button" startIcon={<TrashIcon />} onClick={() => onDelete(index)}>
                Удалить
              </Link>
            ) : null}
          </ListControlGroup>
        </Row>
      ))}
    </>
  )
}

const FIELD_NAME = 'companies'

const CompaniesInput: React.FC = () => {
  const [isOpen, setIsOpen] = useBoolean()

  const [{ value: companies, onChange }, meta] = useField<IClientFormValues['companies']>(FIELD_NAME)

  const [initValues, setInitValues] = useState<any | null>(null)
  const [editIndex, setEditIndex] = useState<number | null>(null)

  const onSuccess = useCallback(
    (payload: IClientFormValues['companies']) => {
      onChange(formikFieldEvent(FIELD_NAME, companies.concat(payload)))
    },
    [onChange, companies],
  )

  const onEditOpen = useCallback(
    (editIndexPayload: number) => {
      setEditIndex(editIndexPayload)
      setInitValues(companies[editIndexPayload])
    },
    [companies],
  )

  const onEditClose = useCallback(() => {
    setEditIndex(null)
    setInitValues(null)
  }, [])

  const onEditSuccess = useCallback(
    (payload: IClientFormValues['companies'][0]) => {
      if (editIndex === null) throw new Error('No edit index')
      const updated = companies.map((item, index) => (index === editIndex ? payload : item))
      onChange(formikFieldEvent(FIELD_NAME, updated))

      onEditClose()
    },
    [companies, editIndex, onChange, onEditClose],
  )

  const onDelete = useCallback(
    (deleteIndex: number) => {
      const updated = companies.filter((_, index) => index !== deleteIndex)
      onChange(formikFieldEvent(FIELD_NAME, updated))
    },
    [companies, onChange],
  )

  return (
    <DropMenu resetPadding title={<TitleCounter counter={companies.length}>Компании</TitleCounter>}>
      <Button className={styles.addCompany} variant="outlined" onMouseDown={setIsOpen.on}>
        Добавить компанию
      </Button>

      {meta.touched && meta.error && (
        <Text className={styles.error} color="error">
          {meta.error}
        </Text>
      )}

      <CreateCompanyDialog isOpen={isOpen} onClose={setIsOpen.off} onSuccess={onSuccess} />

      <EditCompanyDialog
        isOpen={Boolean(initValues)}
        initialValues={initValues}
        onClose={onEditClose}
        onSuccess={onEditSuccess}
      />

      {companies.length ? (
        <CompaniesList companies={companies} onEdit={onEditOpen} onDelete={onDelete} />
      ) : null}
    </DropMenu>
  )
}

export default CompaniesInput
