import { gql } from '@apollo/client'

import { Status } from 'types/types'

export type IDeletePriceRuleParams = {
  id: IPrimaryKey
}

export type IDeletePriceRule = {
  delPriceRule: Status
}

export const DELETE_PRICE_RULE = gql`
  mutation delPriceRule($id: ID!) {
    delPriceRule(id: $id) {
      status
      message
    }
  }
`
