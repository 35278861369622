import React from 'react'
import { useMutation } from '@apollo/client'

import useNotifyCms from 'hooks/useNotifyCms'
import useCmsParams from 'hooks/useCmsParams'
import {
  ISetSellerClosed,
  ISetSellerClosedParams,
  buildSetSellerClosedQuery,
} from 'graphql/mutations/setSellerClosed'
import Switch from 'components/Switch'
import Text from 'components/Typography'
import { Seller } from 'types/types'
import defaultFieldsHelper from 'utils/defaultFieldsHelper'
import baseSellerDefaultFields from 'consts/defaultFields/baseSeller'

type IAuthAvailabilityProps = {
  seller: Seller
}

const SET_SELLER = buildSetSellerClosedQuery(`
  id
  closed
`)

const AuthAvailability: React.FC<IAuthAvailabilityProps> = ({ seller }) => {
  const { closed } = defaultFieldsHelper(seller, baseSellerDefaultFields)

  const addNotify = useNotifyCms()
  const { sellerId } = useCmsParams()

  const [onSetSellerClosed, { loading }] = useMutation<ISetSellerClosed, ISetSellerClosedParams>(SET_SELLER, {
    variables: {
      id: sellerId,
      closed: !closed,
    },
    onCompleted: () => addNotify('success'),
    onError: () => addNotify('error'),
    update(cache, { data: resData }) {
      if (!resData?.setSellerClosed) throw new Error('Something went wrong')

      cache.modify({
        fields: {
          // NOTE: НЕ Вызывает обновление всех getSeller (потому что нет поля closed в других запросах?)
          getSeller(currentSeller: Seller) {
            return {
              ...currentSeller,
              closed: resData.setSellerClosed.closed,
            }
          },
        },
      })
    },
  })

  return (
    <Switch
      disabled={loading}
      checked={closed}
      placement="right"
      gap="medium"
      label={<Text>Закрыть магазин</Text>}
      onChange={() => onSetSellerClosed()}
    />
  )
}

export default AuthAvailability
