import React from 'react'

import { H2 } from 'components/Typography'
import Button from 'components/Button'

import { IDeleteCatalogItem } from '../../types'

import styles from './ConfirmState.module.scss'

type IConfirmStateProps = {
  deleteCategories: IDeleteCatalogItem[]
  onSubmit: () => void
  onCancel: () => void
}

const translateId: Record<IDeleteCatalogItem, string> = {
  products: 'товары',
  attributes: 'атрибуты',
  groups: 'группы',
  categories: 'категории',
}

const buildTitle = (payload: IDeleteCatalogItem[]) => {
  let res = ''

  if (payload.length === 4) {
    return 'Вы уверены, что хотите очистить весь каталог'
  }

  const translatedItems = payload.map((item) => translateId[item])

  if (translatedItems.length === 3) {
    res = `все ${translatedItems.at(0)}, ${translatedItems.at(1)} и ${translatedItems.at(2)}`
  }

  if (translatedItems.length === 2) {
    res = `все ${translatedItems.join(' и ')}`
  }

  if (translatedItems.length === 1) {
    res = `все ${translatedItems[0]}`
  }

  return `Вы уверены, что хотите удалить ${res}?`
}

const ConfirmState: React.FC<IConfirmStateProps> = ({ deleteCategories, onSubmit, onCancel }) => (
  <>
    <H2>{buildTitle(deleteCategories)}</H2>

    <div className={styles.controlsGroup}>
      <Button onClick={onSubmit}>Да, удалить</Button>
      <Button variant="outlined" onClick={onCancel}>
        Отменить
      </Button>
    </div>
  </>
)

export default ConfirmState
