import { DocumentNode, gql } from '@apollo/client'

import { IImportSchedule } from 'types/types'

export type IGetImportSchedulesParams = {
  seller_id: IPrimaryKey
}

export type IGetImportSchedules = {
  getImportSchedules: IImportSchedule[]
}

export const buildGetImportSchedulesQuery = (fields: string): DocumentNode => gql`
  query getImportSchedules($seller_id: ID!) {
    getImportSchedules(seller_id: $seller_id) {
      ${fields}
    }
  }
`
