import React from 'react'
import clsx from 'clsx'

import { ReactComponent as SuccessfullIcon } from 'assets/img/successfully.svg' // TODO: refactor, add ui-kit icons page
import Button from 'components/Button'
import { H2 } from 'components/Typography'
import Dialog from 'components/Dialog'
import DialogContent from 'components/DialogContent'
import DialogCloseButton from 'components/DialogCloseButton'

import styles from './successItemDialog.module.scss'

type ISuccessDialogProps = {
  isOpen: boolean
  className?: string
  title: string
  toItemText: string
  onClose: () => void
  onSubmitBtn?: () => void
  onToItem: () => void
}

const SuccessItemDialog: React.FC<ISuccessDialogProps> = ({
  isOpen,
  className,
  title,
  toItemText,
  onClose,
  onSubmitBtn = onClose,
  onToItem,
}) => (
  <Dialog isOpen={isOpen} onClose={onClose}>
    <DialogCloseButton onClick={onClose} />
    <DialogContent className={clsx(styles.content, className)}>
      <SuccessfullIcon className={styles.icon} />
      <H2 className={styles.title}>{title}</H2>
      <div className={styles.actionsGroup}>
        <Button onClick={onSubmitBtn}>Ок</Button>
        <Button variant="outlined" onClick={onToItem}>
          {toItemText}
        </Button>
      </div>
    </DialogContent>
  </Dialog>
)

export default SuccessItemDialog
