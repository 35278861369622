import { gql } from '@apollo/client'

import { Status } from 'types/types'

export type IDeleteGroup = {
  delGroup: Status
}

export type IDeleteGroupParams = {
  id: IPrimaryKey
}

export const DELETE_GROUP = gql`
  mutation delGroup($id: ID!) {
    delGroup(id: $id) {
      status
      message
    }
  }
`
