import { DocumentNode, gql, QueryHookOptions, useQuery } from '@apollo/client'

import { IApiAccess } from 'types/types'

export type IGetApiAccesses = {
  getApiAccesses: IApiAccess[]
}

export type IGetApiAccessesParams = {
  seller_id: IPrimaryKey
}

export const buildGetApiAccessesQuery = (fields: string): DocumentNode => gql`
  query getApiAccesses($seller_id: ID!) {
    getApiAccesses(seller_id: $seller_id) {
      ${fields}
    }
  }
`

export const useGetApiAccessesQuery = <TData = IGetApiAccesses, TOptions = IGetApiAccessesParams>(
  fields: DocumentNode,
  options?: QueryHookOptions<TData, TOptions>,
) => useQuery<TData, TOptions>(fields, options)
