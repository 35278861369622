import React, { ReactElement, ReactNode, FC } from 'react'
import FormControlLabel, { FormControlLabelProps } from '@material-ui/core/FormControlLabel'

import Checkbox from '../Checkbox'
import type { ICheckboxProps } from '../Checkbox'

type IControlLabel = Omit<FormControlLabelProps, 'control' | 'label'> & {
  control?: ReactElement
}

export type ICheckboxLabelProps = ICheckboxProps & {
  label: ReactNode
  controlLabel?: IControlLabel
}

const CheckboxLabel: FC<ICheckboxLabelProps> = ({ className, label, controlLabel, ...props }) => (
  <FormControlLabel className={className} control={<Checkbox {...props} />} label={label} {...controlLabel} />
)

export default CheckboxLabel
