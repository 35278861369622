import React, { memo } from 'react'

import Link from 'components/Link'
import Text from 'components/Typography'
import ListControlGroup from 'components/ListControlGroup'
import EditIcon from 'components/Icons/EditIcon'
import TrashIcon from 'components/Icons/TrashIcon'
import useCmsParams from 'hooks/useCmsParams'
import { IManager } from 'types/types'
import withErrorBoundary from 'hocs/withErrorBoundary'
import DeleteManager from 'components/ModelControls/DeleteManager'

import Row from '../Row'

import styles from './manager.module.scss'

type IManagerProps = {
  manager: IManager
  refetchList: () => void
}

const Manager: React.FC<IManagerProps> = ({ manager, refetchList }) => {
  const { id: managerId, name, clients } = manager

  const { sellerId } = useCmsParams()

  return (
    <Row>
      <Text>
        <Link href={`/${sellerId}/managers/${managerId}/`}>{name}</Link>
      </Text>

      <Text align="center">{clients.length}</Text>

      <ListControlGroup className={styles.controlsGroup}>
        <Link href={`/${sellerId}/managers/${managerId}`} startIcon={<EditIcon />}>
          Редактировать
        </Link>
        <DeleteManager
          managerId={managerId}
          onSuccess={refetchList}
          render={({ onClick }) => (
            <Link component="button" startIcon={<TrashIcon />} onClick={onClick}>
              Удалить
            </Link>
          )}
        />
      </ListControlGroup>
    </Row>
  )
}

export default withErrorBoundary(memo(Manager))
