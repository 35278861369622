import { DocumentNode, QueryHookOptions, gql, useQuery } from '@apollo/client'

import { IClient } from 'types/types'

export type IGetClientParams = {
  id: IPrimaryKey
}

export type IGetClient = {
  getClient: IClient
}

export const GET_CLIENT = gql`
  query getClient($id: ID!) {
    getClient(id: $id) {
      id
      seller_id
      name
      email
      phone
      contract_number
      contract_date
      company_name
      delivery_address
      legal_address
      inn
      status
      email_verified_at
      created_at
      updated_at
      price_rules {
        id
        name
        type
        parameters {
          discount_type
          discount_ratio
        }
      }
      orders {
        id
        number
        created_at
        updated_at
        products {
          id
          price
          amount
        }
      }
    }
  }
`

export const buildGetClientQuery = (fields: string): DocumentNode => gql`
  query getClient($id: ID!) {
    getClient(id: $id) {
      ${fields}
    }
  }
`

export const useGetClientQuery = <TData = IGetClient, TOptions = IGetClientParams>(
  fields: DocumentNode,
  options?: QueryHookOptions<TData, TOptions>,
) => useQuery<TData, TOptions>(fields, options)
