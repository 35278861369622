import React from 'react'
import MuiRadio, { RadioProps } from '@material-ui/core/Radio'

import styles from './radio.module.scss'

export type IRadioProps = RadioProps

const Radio: React.FC<IRadioProps> = (props) => (
  <MuiRadio
    icon={<span className={styles.icon} />}
    checkedIcon={<span className={styles.checkedIcon} />}
    {...props}
  />
)

export default Radio
