import { DocumentNode, gql } from '@apollo/client'

import { Category } from 'types/types'

export type ISetCategoryStatus = {
  setCategoryStatus: Category
}

export type ISetCategoryStatusParams = {
  id: IPrimaryKey
  status: boolean
}

export const buildSetCategoryStatusQuery = (fields: string): DocumentNode => gql`
  mutation setCategoryStatus($id: ID!, $status: Boolean!) {
    setCategoryStatus(id: $id, status: $status) {
      ${fields}
    }
  }
`
