import React, { useEffect } from 'react'

import stub from 'assets/img/stubIcon.svg'

import styles from './screenBlocker.module.scss'

const ScreenBlocker: React.FC = () => {
  useEffect(() => {
    document.querySelector('html')?.classList.add('admin')
    document.querySelector('body')?.classList.add('admin')
  }, [])

  return (
    <div className={styles.stub}>
      <img className={styles.img} src={stub} alt="" />
      <p className={styles.text}>
        Работа с платформой <span className={styles.name}>Sellty</span> возможна только в полноэкранном режиме
        с компьютера
      </p>
    </div>
  )
}

export default ScreenBlocker
