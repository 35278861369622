import React, { useCallback, useState } from 'react'
import { useMutation } from '@apollo/client'

import {
  EXPORT_PRICE_LIST_TABLE,
  IExportPriceListTable,
  IExportPriceListTableParams,
} from 'graphql/mutations/exportPriceListTable'
import useNotifyCms from 'hooks/useNotifyCms'
import HiddenDownload from 'components/HiddenDownload'

type IRenderParams = {
  loading: boolean
  onClick: () => void
}

type IExportPriceListTableProps = {
  priceListId: IPrimaryKey
  onSuccess: () => void
  render: (params: IRenderParams) => void
}

const ExportPriceListTable: React.FC<IExportPriceListTableProps> = ({ priceListId, onSuccess, render }) => {
  const addNotify = useNotifyCms()

  const [downloadUrl, setDownloadUrl] = useState<string | undefined>()

  const clearSetDownloadUrl = useCallback(() => setDownloadUrl(undefined), [])

  const [onExportPriceListTable, { loading }] = useMutation<
    IExportPriceListTable,
    IExportPriceListTableParams
  >(EXPORT_PRICE_LIST_TABLE, {
    variables: {
      id: priceListId,
    },
    onCompleted: ({ exportPriceListTable }) => {
      const { message, status } = exportPriceListTable

      if (!status) {
        addNotify({ kind: 'warning', message: 'Не удалось скачать прайс лист' })
        return
      }

      setDownloadUrl(message)
      onSuccess()
    },
    onError: () => {
      addNotify('error')
    },
  })

  return (
    <>
      {render({ loading, onClick: () => onExportPriceListTable() })}
      <HiddenDownload downloadUrl={downloadUrl} clearUrl={clearSetDownloadUrl} />
    </>
  )
}

export default ExportPriceListTable
