import { gql } from '@apollo/client'

import { Status } from 'types/types'

export type IDeleteContact = {
  delContact: Status
}

export type IDeleteContactParams = {
  id: IPrimaryKey
}

export const DELETE_CONTACT = gql`
  mutation delContact($id: ID!) {
    delContact(id: $id) {
      status
      message
    }
  }
`
