import { DocumentNode, gql, useMutation, MutationHookOptions } from '@apollo/client'

import { IManager, IManagerInput } from 'types/types'

export type ISetManager = {
  setManager: IManager
}

export type ISetManagerParams = {
  id: IPrimaryKey
  input: IManagerInput
}

export const buildSetManagerQuery = (fields: string): DocumentNode => gql`
  mutation setManager($id: ID!, $input: ManagerInput) {
    setManager(id: $id, input: $input) {
      ${fields}
    }
  }
`

export const useSetManagerMutation = <TData = ISetManager, TOptions = ISetManagerParams>(
  fields: DocumentNode,
  options?: MutationHookOptions<TData, TOptions>,
) => useMutation<TData, TOptions>(fields, options)
