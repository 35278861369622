import { gql } from '@apollo/client'

import { Status } from 'types/types'

export type IExportPriceListTable = {
  exportPriceListTable: Status
}

export type IExportPriceListTableParams = {
  id: IPrimaryKey
}

export const EXPORT_PRICE_LIST_TABLE = gql`
  mutation exportPriceListTable($id: ID!) {
    exportPriceListTable(id: $id) {
      status
      message
      data
    }
  }
`
