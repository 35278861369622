import { Overrides } from '@material-ui/core/styles/overrides'

const formLabel: Overrides['MuiFormLabel'] = {
  root: {
    marginBottom: '8px',
    color: '#121239',
    lineHeight: '22px',

    '&$focused': {
      color: '#121239',
    },
    '&$error': {
      color: '#121239',
    },
    '&$disabled': {
      color: '#999CA6',
    },
  },
  asterisk: {
    color: '#F9423A',
  },
}

export default formLabel
