import React, { FC, useCallback } from 'react'

import DropMenu from 'components/DropMenu'
import UiLayout from 'components/ui-kit/UiLayout'
import { ISettings } from 'types/types'

import InputColor from '../InputColor'

import styles from './Colors.module.scss'

type IColorName =
  | 'bg_color'
  | 'bt_color'
  | 'hd_color'
  | 'lk_color'
  | 'main_color'
  | 'second_color'
  | 'tx_color'
  | 'fill_color'

type IColors = Required<Pick<ISettings, IColorName>>

type ColorsProps = {
  colors: IColors
  setColors: React.Dispatch<React.SetStateAction<IColors>>
}

const Colors: FC<ColorsProps> = ({ colors, setColors }) => {
  const { bg_color, bt_color, hd_color, lk_color, main_color, second_color, tx_color, fill_color } = colors

  const onColorChange = useCallback(
    (name: IColorName) => (color: string) => setColors((prevState) => ({ ...prevState, [name]: color })),
    [setColors],
  )

  return (
    <DropMenu title="Цвета">
      <div className={styles.wrapper}>
        <UiLayout spanText="Цвет заголовков" layoutClassName={styles.uiLayout}>
          <InputColor color={hd_color} setColor={onColorChange('hd_color')} />
        </UiLayout>
        <UiLayout spanText="Цвет текста" layoutClassName={styles.uiLayout}>
          <InputColor color={tx_color} setColor={onColorChange('tx_color')} />
        </UiLayout>
        <UiLayout spanText="Цвет ссылок" layoutClassName={styles.uiLayout}>
          <InputColor color={lk_color} setColor={onColorChange('lk_color')} />
        </UiLayout>
        <UiLayout spanText="Цвет фона" layoutClassName={styles.uiLayout}>
          <InputColor color={bg_color} setColor={onColorChange('bg_color')} />
        </UiLayout>
        <UiLayout spanText="Цвет кнопок" layoutClassName={styles.uiLayout}>
          <InputColor color={bt_color} setColor={onColorChange('bt_color')} />
        </UiLayout>
        <UiLayout spanText="Цвет хедера" layoutClassName={styles.uiLayout}>
          <InputColor color={main_color} setColor={onColorChange('main_color')} />
        </UiLayout>
        <UiLayout spanText="Цвет футера" layoutClassName={styles.uiLayout}>
          <InputColor color={second_color} setColor={onColorChange('second_color')} />
        </UiLayout>
        <UiLayout spanText="Цвет текста в кнопке" layoutClassName={styles.uiLayout}>
          <InputColor color={fill_color} setColor={onColorChange('fill_color')} />
        </UiLayout>
      </div>
    </DropMenu>
  )
}

export default Colors
