import { gql } from '@apollo/client'

import { Status } from 'types/types'

export type IDeletePriceList = {
  delPriceList: Status
}

export type IDeletePriceListParams = {
  id: IPrimaryKey
}

export const DELETE_PRICE_LIST = gql`
  mutation delPriceList($id: ID!) {
    delPriceList(id: $id) {
      status
      message
    }
  }
`
