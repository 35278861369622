import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import Link from 'components/Link'
import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import useNotifyCms from 'hooks/useNotifyCms'
import useBoolean from 'hooks/useBoolean'
import { COPY_CATEGORY, ICopyCategory, ICopyCategoryParams } from 'graphql/mutations/copyCategory'
import { DELETE_CATEGORY, IDeleteCategory, IDeleteCategoryParams } from 'graphql/mutations/deleteCategory'
import CopyIcon from 'components/Icons/CopyIcon'
import TrashIcon from 'components/Icons/TrashIcon'
import SuccessItemDialog from 'components/Dialogs/SuccessItemDialog'
import useCmsParams from 'hooks/useCmsParams'
import { useLeavePage } from 'hocs/withLeaveThisPageModal'

const title = {
  delete: 'Вы уверены, что хотите удалить категорию?',
  copy: 'Вы действительно хотите создать копию категории?',
}

const Controls: React.FC = () => {
  const { sellerId, categoryId } = useCmsParams()
  const addNotify = useNotifyCms()
  const { push } = useHistory()
  const { unblocking } = useLeavePage()

  const [isCopySuccess, setIsCopySuccess] = useBoolean()
  const [confirmDialog, setConfirmDialog] = useState<'delete' | 'copy' | null>(null)

  const [onCopyCategory, { data: copyData }] = useMutation<ICopyCategory, ICopyCategoryParams>(
    COPY_CATEGORY,
    {
      variables: {
        id: categoryId,
      },
      onCompleted: () => {
        setConfirmDialog(null)
        setIsCopySuccess.on()
      },
      onError: () => addNotify('error'),
    },
  )

  const [onDeleteCategory] = useMutation<IDeleteCategory, IDeleteCategoryParams>(DELETE_CATEGORY, {
    variables: {
      id: categoryId,
    },
    onCompleted: () => {
      push(`/${sellerId}/catalog/categories/`)
      addNotify('success')
    },
    onError: () => addNotify('error'),
  })

  const onConfirmDialogSubmit = useCallback(() => {
    if (confirmDialog === 'copy') onCopyCategory()
    if (confirmDialog === 'delete') onDeleteCategory()
  }, [onCopyCategory, onDeleteCategory, confirmDialog])

  const onToItemClick = useCallback(() => {
    setIsCopySuccess.off()
    unblocking()
    push(`/${sellerId}/catalog/categories/${copyData?.copyCategory?.message}`)
  }, [setIsCopySuccess, unblocking, push, sellerId, copyData])

  return (
    <>
      <Link component="button" onClick={() => setConfirmDialog('copy')} startIcon={<CopyIcon />}>
        Создать копию
      </Link>
      <Link component="button" onClick={() => setConfirmDialog('delete')} startIcon={<TrashIcon />}>
        Удалить категорию
      </Link>

      <SuccessItemDialog
        isOpen={isCopySuccess}
        onClose={setIsCopySuccess.off}
        title="Категория успешно скопирована"
        toItemText="К категории"
        onToItem={onToItemClick}
      />

      <ConfirmDialog
        isOpen={!!confirmDialog}
        title={confirmDialog === 'delete' ? title.delete : title.copy}
        submitBtnText={confirmDialog === 'delete' ? 'Да, удалить' : 'Да, создать'}
        onSubmit={onConfirmDialogSubmit}
        onClose={() => setConfirmDialog(null)}
      />
    </>
  )
}

export default Controls
