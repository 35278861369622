import { DocumentNode, gql } from '@apollo/client'

import { Product } from 'types/types'

export type ISetProductStatusParams = {
  id: string
  status: boolean
}

export type ISetProductStatusResponse = {
  setProductStatus: Product
}

export const buildSetProductStatusQuery = (fields: string): DocumentNode => gql`
  mutation setProductStatus($id: ID!, $status: Boolean!) {
    setProductStatus(id: $id, status: $status) {
      ${fields}
    }
  }
`
