import React, { useCallback, useMemo } from 'react'
import { useField } from 'formik'

import DropMenu from 'components/DropMenu'
import Button from 'components/Button'
import Text from 'components/Typography'
import RelatedProductsDialog from 'components/Dialogs/RelatedProductsDialog'
import useBoolean from 'hooks/useBoolean'
import { IBaseProductFormValues } from 'components/forms/BaseProductForm/types'
import formikFieldEvent from 'utils/formInputs/formikFieldEvent'
import { IProduct } from 'types/types'
import { MAX_RELATED_PRODUCTS } from 'components/Dialogs/RelatedProductsDialog/consts'

import Product from './components/Product'

import styles from './relatedProducts.module.scss'

const name = 'related_products'

const RelatedProducts: React.FC = () => {
  const [{ value: products, onChange: setProducts }] =
    useField<IBaseProductFormValues['related_products']>(name)

  const [isRelatedProductsModalOpen, setIsRelatedProductsModal] = useBoolean()

  const isDisabledAddButton = useMemo(() => products.length === MAX_RELATED_PRODUCTS, [products])

  const removeProduct = useCallback(
    (payload: IPrimaryKey) => {
      setProducts(
        formikFieldEvent(
          name,
          products.filter((product) => product.id !== payload),
        ),
      )
    },
    [setProducts, products],
  )

  const handleProductsChange = useCallback(
    (newProducts: IProduct[]) => {
      setProducts(formikFieldEvent(name, newProducts))
    },
    [setProducts],
  )

  return (
    <DropMenu resetPadding initOpen={false} title="Сопутствующие товары">
      {products.map((product) => (
        <Product key={product.id} product={product} onDelete={removeProduct} />
      ))}
      <div className={styles.bottomGroup}>
        <Button
          className={styles.addItemsButton}
          disabled={isDisabledAddButton}
          onClick={setIsRelatedProductsModal.on}
        >
          Добавить товары
        </Button>
        <Text color="textSecondary">
          Товары, которые будут отображаться в магазине в блоке <b>«с этим товаром покупают»</b>
        </Text>
      </div>
      <RelatedProductsDialog
        isOpen={isRelatedProductsModalOpen}
        currentProducts={products}
        onClose={setIsRelatedProductsModal.off}
        onSave={handleProductsChange}
      />
    </DropMenu>
  )
}

export default RelatedProducts
