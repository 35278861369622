import React, { useCallback, useMemo } from 'react'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import { IValueType } from 'types/types'
import useBoolean from 'hooks/useBoolean'
import TextField, { ITextFieldProps } from 'components/TextField'
import ArrowSmall from 'components/Icons/ArrowDownSmallIcon'
import block from 'utils/block'

import styles from './valueTypeField.module.scss'

export type IValueTypeFieldProps = ITextFieldProps & {
  name: string
  value?: IValueType | null
  typeOptions: string[]
  onTypeChange: (value: IValueType) => void
}

const defaultValue: IValueType = {
  type: '',
  value: '',
}

const b = block(styles)

const ValueTypeField: React.FC<IValueTypeFieldProps> = ({
  value: valueProp = defaultValue,
  autoComplete = 'off',
  typeOptions,
  onTypeChange,
  disabled,
  classes = {},
  ...props
}) => {
  const [isOpenOptions, setIsOpenOptions] = useBoolean()

  const { type, value } = valueProp || defaultValue

  const nextClasses = useMemo(() => {
    const { adornedEnd, ...restClasses } = classes
    return {
      ...restClasses,
      adornedEnd: b('adornedEnd', adornedEnd),
    }
  }, [classes])

  const onTypeOptionClick = useCallback(
    (payload: string) => {
      onTypeChange({
        value: value || '',
        type: payload,
      })
      setIsOpenOptions.off()
    },
    [onTypeChange, value, setIsOpenOptions],
  )

  return (
    <TextField
      autoComplete={autoComplete}
      disabled={disabled}
      disableNumberControls
      endAdornment={
        <ClickAwayListener onClickAway={setIsOpenOptions.off}>
          <div className={styles.optionsSlot}>
            <button
              type="button"
              disabled={disabled}
              className={styles.button}
              onClick={setIsOpenOptions.toggle}
            >
              {type}
              <ArrowSmall className={styles.buttonIcon} />
            </button>
            <div className={b('optionsList', { active: isOpenOptions })}>
              {typeOptions.map((option) => (
                <button
                  type="button"
                  key={option}
                  className={styles.optionButton}
                  onClick={() => onTypeOptionClick(option)}
                >
                  {option}
                </button>
              ))}
            </div>
          </div>
        </ClickAwayListener>
      }
      {...props}
      value={value}
      classes={nextClasses}
    />
  )
}

export default ValueTypeField
