import React, { FC, memo } from 'react'
import { Redirect, useHistory } from 'react-router-dom'

import { useAuth } from 'contexts/AuthProvider'
import { H3, H2 } from 'components/Typography'
import Preloader from 'components/Preloader'
import Button from 'components/Button'
import AuthHeader from 'components/AuthHeader'
import { ReactComponent as Clock } from 'assets/img/clockIcon_warning.svg'
import { ReactComponent as Success } from 'assets/img/successfully.svg'
import withErrorBoundary from 'hocs/withErrorBoundary'

import ResetForm from './Reset'

import styles from './resetPassword.module.scss'

type ResetPasswordTypes = {
  location: {
    state: {
      token: string
    }
  }
}

export type ErrorTypes = {
  pass: {
    status: boolean
    message: string
  }
  confirm: {
    status: boolean
    message: string
  }
}

const ResetPassword: FC<ResetPasswordTypes> = ({ location }) => {
  const { verifyToken, verifyTokenData, verifyTokenLoading, resetPasswordData, token } = useAuth()
  const { push } = useHistory()

  React.useEffect(() => {
    verifyToken(location.state.token)
  }, [])

  if (verifyTokenLoading) return <Preloader />

  return (
    <div className={styles.Root}>
      <AuthHeader />
      {resetPasswordData?.resetPassword?.status ? (
        <div className={styles.ErrorRoot}>
          <Success className={styles.Clock} />
          <H2>Пароль успешно изменен</H2>
          <Button variant="outlined" className={styles.Submit} onClick={() => push('/auth/sign-in')}>
            Перейти к авторизации
          </Button>
        </div>
      ) : verifyTokenData?.verifyToken?.status ? (
        <div className={styles.Container}>
          <H3 className={styles.ContainerSubTitle}>Изменение пароля</H3>
          <ResetForm token={location.state.token} />
        </div>
      ) : (
        <div className={styles.ErrorRoot}>
          <Clock className={styles.Clock} />
          <H2>Срок действия ссылки истёк</H2>
          <div className={styles.Text}>Повторите процесс смены пароля</div>
          <Button variant="outlined" className={styles.Submit} onClick={() => push('/auth/recovery')}>
            Повторить
          </Button>
        </div>
      )}

      {token && <Redirect to="/" />}
    </div>
  )
}

export default withErrorBoundary(memo(ResetPassword))
