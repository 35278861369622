import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import SuccessDialog from 'components/Dialogs/SuccessDialog'
import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import Button from 'components/Button'
import Link from 'components/Link'
import useCmsParams from 'hooks/useCmsParams'
import {
  SEND_CONTACT_VERIFY,
  ISendContactVerify,
  ISendContactVerifyParams,
} from 'graphql/mutations/sendContactVerify'
import TrashIcon from 'components/Icons/TrashIcon'
import DeleteContact from 'components/ModelControls/DeleteContact'

import styles from './controls.module.scss'

const Controls: React.FC = () => {
  const { sellerId, contactId } = useCmsParams()
  const { push } = useHistory()

  const [isOpenReset, setOpenResetState] = useState<boolean>(false)
  const [isOpenResetSuccess, setOpenSuccessReset] = useState<boolean>(false)

  const [sendContactVerify] = useMutation<ISendContactVerify, ISendContactVerifyParams>(SEND_CONTACT_VERIFY, {
    onCompleted: () => {
      setOpenResetState(false)
      setOpenSuccessReset(true)
      setTimeout(() => {
        setOpenSuccessReset(false)
      }, 2000)
    },
  })

  const onResetSubmit = () => sendContactVerify({ variables: { id: contactId } })

  const onDeleteSuccess = useCallback(() => {
    push(`/${sellerId}/contacts/`)
  }, [push, sellerId])

  return (
    <>
      <SuccessDialog
        isOpen={isOpenResetSuccess}
        text="Пароль сброшен"
        onClose={() => setOpenSuccessReset(false)}
      />

      <ConfirmDialog
        isOpen={isOpenReset}
        title="Сбросить пароль контакту?"
        onSubmit={onResetSubmit}
        onClose={() => setOpenResetState(false)}
      />

      <Button variant="outlined" onClick={() => setOpenResetState(true)}>
        Сбросить пароль
      </Button>

      <DeleteContact
        contactId={contactId}
        onSuccess={onDeleteSuccess}
        render={({ onClick }) => (
          <Link
            component="button"
            className={styles.deleteButton}
            startIcon={<TrashIcon />}
            onClick={onClick}
          >
            Удалить контакт
          </Link>
        )}
      />
    </>
  )
}

export default Controls
