import { DocumentNode, gql, QueryHookOptions, useQuery } from '@apollo/client'

import type { Paginate, PBanners } from 'types/types'

export type IGetBanners = {
  getBanners: PBanners
}

export type IGetBannersParams = {
  seller_id: IPrimaryKey
  status?: boolean | null
  paginate?: Paginate
}

export const buildGetBannersDefaultQuery = (fields: string): DocumentNode => gql`
  query getBanners(
    $seller_id: ID!
    $status: Boolean
    $paginate: Paginate
  ) {
    getBanners(
      seller_id: $seller_id
      status: $status
      paginate: $paginate
    ) {
      list { ${fields} }
      page
      pages
      elements
    }
  }
`

export const useGetBannersQuery = <TData = IGetBanners, TOptions = IGetBannersParams>(
  fields: DocumentNode,
  options?: QueryHookOptions<TData, TOptions>,
) => useQuery<TData, TOptions>(fields, options)
