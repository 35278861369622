import { gql } from '@apollo/client'

import { Status } from 'types/types'

export type IDeleteOrderFileResponse = {
  data: {
    delOrderFile?: Status
  }
}

export type IDeleteOrderFileRequest = {
  file_id: IPrimaryKey
}

export const DELETE_ORDER_FILE = gql`
  mutation delOrderFile($file_id: ID!) {
    delOrderFile(file_id: $file_id) {
      status
      message
    }
  }
`
