import React, { useCallback } from 'react'
import { Formik, FormikHelpers } from 'formik'
import { object, string } from 'yup'

import { H2 } from 'components/Typography'
import Button from 'components/Button'
import Dialog from 'components/Dialog'
import DialogContent from 'components/DialogContent'
import DialogCloseButton from 'components/DialogCloseButton'
import AddSubmitShortcutFormHelper from 'components/AddSubmitShortcutFormHelper'
import TextInput from 'components/Inputs/TextInput'
import { REQUIRED_MESSAGE } from 'consts/errorMessages'
import { useSetStoreMutation, buildSetStoreQuery } from 'graphql/mutations/setStore'
import useNotifyCms from 'hooks/useNotifyCms'
import convertToFormErrors from 'utils/convertToFormErrors'
import { IStore } from 'types/types'

import styles from './editStoreDialog.module.scss'

type IEditStoreDialogProps = {
  isOpen: boolean
  initialValues: IStore
  onClose: () => void
  onSuccess: () => void
}

const validationSchema = object({
  name: string().required(REQUIRED_MESSAGE),
})

const SET_STORE = buildSetStoreQuery(`name`)

const EditStoreDialog: React.FC<IEditStoreDialogProps> = ({ isOpen, initialValues, onClose, onSuccess }) => {
  const addNotify = useNotifyCms()

  const [setStore, { loading }] = useSetStoreMutation(SET_STORE)

  const onSubmit = useCallback(
    ({ id: storeId, name }: IStore, { setErrors }: FormikHelpers<IStore>) =>
      setStore({
        variables: { id: storeId, name },
      })
        .then(() => {
          addNotify('success')
          onSuccess()
        })
        .catch((error) => {
          addNotify('error')
          const errors = convertToFormErrors(error)
          if (errors) setErrors(errors)
        }),
    [setStore, addNotify, onSuccess],
  )

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <DialogCloseButton onClick={onClose} />

      <DialogContent className={styles.content}>
        <H2 className={styles.title}>Редактирование склада</H2>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <AddSubmitShortcutFormHelper />

              <TextInput
                name="name"
                required
                label="Название склада"
                placeholder="Введите название склада"
                margin="default"
              />

              <div className={styles.controlsGroup}>
                <Button type="submit" disabled={loading}>
                  Сохранить
                </Button>
                <Button variant="outlined" onClick={onClose}>
                  Отменить
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default EditStoreDialog
