import React, { useState, useRef } from 'react'
import clsx from 'clsx'

import useOutsideAlerter from 'hooks/useOutsideAlerter'
import { useAuth } from 'contexts/AuthProvider'
import { ReactComponent as User } from 'assets/img/User.svg'
import { ReactComponent as UserArrow } from 'assets/img/userArrow.svg'
import Link from 'components/Link'
import { userEvents } from 'utils/gtmHelper'
import useCmsParams from 'hooks/useCmsParams'
import useCurrentSeller from 'hooks/useCurrentSeller'

import styles from './header.module.scss'

const Header: React.FC = () => {
  const { user, logout } = useAuth()
  const { sellerId } = useCmsParams()
  const { status = false, status_index: statusIndex = false } = useCurrentSeller()

  const [isActive, setIsActive] = useState<boolean>(false)
  const popupRef = useRef<null | HTMLDivElement>(null)

  useOutsideAlerter(popupRef, setIsActive)

  return (
    <header className={styles.header}>
      <Link href={`/${sellerId}/options/common`}>
        {status ? (
          <p className={styles.header__activeTitle}>
            Активен, {statusIndex ? 'индексируется' : 'не индексируется'}
          </p>
        ) : (
          <p className={styles.header__inactiveTitle}> Не активен </p>
        )}
      </Link>

      <div className={styles.header__btn} onClick={() => setIsActive((prev) => !prev)} ref={popupRef}>
        <div className={styles.header__wrapper}>
          <User />
        </div>
        <UserArrow className={clsx(styles.arrow, { [styles.open]: isActive })} />
        {isActive && (
          <div className={styles.header__popup} onClick={(e) => e.stopPropagation()}>
            <Link href="/profile" className={styles.link}>
              <p className={styles.header__popup_name}>{user?.name}</p>
              <p className={styles.header__popup_email}>{user?.email}</p>
            </Link>
            <Link href="/auth/sign-in" className={styles.link}>
              <button
                type="button"
                className={styles.header__popup_exit}
                onClick={() => {
                  userEvents('logout')
                  logout()
                }}
              >
                Выйти
              </button>
            </Link>
          </div>
        )}
      </div>
    </header>
  )
}

export default Header
