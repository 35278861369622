import React from 'react'

import Button from 'components/Button'
import { H1 } from 'components/Typography'

import styles from './noItems.module.scss'

type INoItemsProps = {
  onClick: () => void
}

const NoItems: React.FC<INoItemsProps> = ({ onClick }) => (
  <div className={styles.container}>
    <H1 className={styles.title}>Здесь будут ссылки на файлы с товарами</H1>
    <p className={styles.text}>
      Вы пока не добавили ни одной ссылки импорта товаров. Добавьте первую ссылку!
    </p>
    <Button onClick={onClick}>Импорт товаров</Button>
  </div>
)

export default NoItems
