import { DocumentNode, gql } from '@apollo/client'

import { IBaseProductInput } from 'types/types'

export type ISetBaseProduct = {
  setBaseProduct: IBaseProductInput
}

export type ISetBaseProductParams = {
  id: IPrimaryKey
  input: IBaseProductInput
}

export const SET_BASE_PRODUCT = gql`
  mutation setBaseProduct($id: ID!, $input: BaseProductInput) {
    setBaseProduct(id: $id, input: $input) {
      id
      name
      description
      seller_id
      category_id
      status
      variants_status
      images {
        id
        name
        path
      }
      products {
        id
        name
        vendor_code
        price
        status
        amount
        amount_wait
        description
        on_order
        multiply
        weight {
          value
          type
        }
        volume {
          value
          type
        }
        images {
          id
          name
          path
        }
      }
      base_product_attributes {
        attribute_values {
          value
          id
          attribute_id
        }
        attribute {
          name
        }
      }
      product_variant_attributes {
        id
        base_product_id
        attribute {
          id
          seller_id
          name
          description
          attribute_values {
            id
            attribute_id
            value
            select
          }
          number
          created_at
          updated_at
        }
        attribute_values {
          id
          attribute_id
          value
          select
        }
      }
    }
  }
`

export const buildSetBaseProductQuery = (fields: string): DocumentNode => gql`
  mutation setBaseProduct($id: ID!, $input: BaseProductInput) {
    setBaseProduct(id: $id, input: $input) {
      ${fields}
    }
  }
`
