import { gql } from '@apollo/client'

import { Status } from 'types/types'

type IDataStatus = Status & {
  data?: null
}

export type IDeleteImportScheduleParams = {
  id: IPrimaryKey
}

export type IDeleteImportSchedule = {
  delImportSchedule: IDataStatus
}

export const DELETE_IMPORT_SCHEDULE = gql`
  mutation delImportSchedule($id: ID!) {
    delImportSchedule(id: $id) {
      status
      message
      data
    }
  }
`
