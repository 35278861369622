import React, { ChangeEvent, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Formik, FormikHelpers } from 'formik'
import { object, string } from 'yup'

import { buildGetSellerQuery, useGetSellerQuery } from 'graphql/queries/getSeller'
import { buildGetPaymentSettingsQuery, useGetPaymentSettings } from 'graphql/queries/getPaymentSettings'
import { buildSetPaymentSettingsQuery, useSetPaymentSettings } from 'graphql/mutations/setPaymentSettings'
import {
  buildSetPaymentMethodStatusQuery,
  useSetPaymentMethodStatus,
} from 'graphql/mutations/setPaymentMethodStatus'
import { CHECK_PAYMENT_METHOD, useCheckPaymentMethod } from 'graphql/mutations/checkPaymentMethod'
import ResultTitle from 'components/ResultTitle'
import PageContainer from 'components/PageContainer'
import GoBackLink from 'components/GoBackLink'
import Preloader from 'components/Preloader'
import ApiError from 'components/ApiError'
import withErrorBoundary from 'hocs/withErrorBoundary'
import useCmsParams from 'hooks/useCmsParams'
import useBoolean from 'hooks/useBoolean'
import useCmsLinks from 'hooks/useCmsLinks'
import DropMenu from 'components/DropMenu'
import Link from 'components/Link'
import Text from 'components/Typography'
import LeavePageDirtyFormHelper from 'components/LeavePageDirtyFormHelper'
import AddSubmitShortcutFormHelper from 'components/AddSubmitShortcutFormHelper'
import FormControlsPanel from 'components/FormControlsPanel'
import TextInput from 'components/Inputs/TextInput'
import SelectInput from 'components/Inputs/SelectInput'
import Button from 'components/Button'
import Switch from 'components/Switch'
import LimitText from 'components/LimitText'
import SuccessIcon from 'components/Icons/SuccessIcon'
import { IPaymentSettings, IPaymentSettingsInput } from 'types/types'
import useGetSearchParams from 'hooks/useGetSearchParams'
import useNotifyCms from 'hooks/useNotifyCms'
import { REQUIRED_MESSAGE } from 'consts/errorMessages'
import convertToFormErrors from 'utils/convertToFormErrors'

import styles from './PaymentMethods.module.scss'

type IPaymentMethodsFormValues = {
  success_status: string
  error_status: string
  wait_status: string
  offline: {
    name: string
    description: string
  }
  yookassa: {
    name: string
    description: string
  }
}

const defaultValues: IPaymentMethodsFormValues = {
  success_status: '',
  error_status: '',
  wait_status: '',
  offline: {
    name: '',
    description: '',
  },
  yookassa: {
    name: '',
    description: '',
  },
}

const validationSchema = object({
  success_status: string().required(REQUIRED_MESSAGE).default(''),
  error_status: string().required(REQUIRED_MESSAGE).default(''),
  wait_status: string().required(REQUIRED_MESSAGE).default(''),
})

const GET_SELLER = buildGetSellerQuery(`
  id
  settings {
    id
    order_statuses {
      id
      title
    }
  }
`)

const SET_PAYMENT_METHOD_STATUS = buildSetPaymentMethodStatusQuery(`
  offline {
    status
  }
  yookassa {
    status
  }
`)

const GET_PAYMENT_SETTINGS = buildGetPaymentSettingsQuery(`
  success_status { id title }
  error_status { id title }
  wait_status { id title }
  offline {
    status
    name
    description
  }
  yookassa {
    link_to_connect
    access_status
    name
    description
    status
  }
`)

const SET_PAYMENT_SETTINGS = buildSetPaymentSettingsQuery(`
  success_status { id title }
  error_status { id title }
  wait_status { id title }
  offline {
    status
    name
    description
  }
  yookassa {
    link_to_connect
    access_status
    name
    description
    status
  }
`)

const prepareInitialValues = (paymentSettings: IPaymentSettings): IPaymentMethodsFormValues => {
  const { success_status, error_status, wait_status, offline, yookassa } = paymentSettings

  return {
    ...defaultValues,
    success_status: success_status?.id || '',
    error_status: error_status?.id || '',
    wait_status: wait_status?.id || '',
    offline: {
      name: offline?.name || '',
      description: offline?.description || '',
    },
    yookassa: {
      name: yookassa?.name || '',
      description: yookassa?.description || '',
    },
  }
}

const prepareSubmitValues = (
  payload: IPaymentMethodsFormValues,
  { isEnabledOffline, isEnabledYooKassa }: { isEnabledOffline: boolean; isEnabledYooKassa: boolean },
): IPaymentSettingsInput => {
  const { success_status, error_status, wait_status, offline, yookassa } = payload

  return {
    success_status_id: success_status,
    error_status_id: error_status,
    wait_status_id: wait_status,
    offline: {
      name: offline.name,
      description: offline.description,
      status: isEnabledOffline,
    },
    yookassa: {
      name: yookassa.name,
      description: yookassa.description,
      status: isEnabledYooKassa,
    },
  }
}

const PaymentMethods: React.FC = () => {
  const { sellerId } = useCmsParams()
  const { integrations } = useCmsLinks()
  const addNotify = useNotifyCms()
  const searchParams = useGetSearchParams<{ code?: string; state?: string }>()

  const [isEnabledOffline, setIsEnabledOffline] = useBoolean()
  const [isEnabledYooKassa, setIsEnabledYooKassa] = useBoolean()

  const [initialValues, setInitialValues] = useState(defaultValues)

  const {
    data: sellerData,
    loading: sellerLoading,
    error: sellerError,
  } = useGetSellerQuery(GET_SELLER, {
    variables: { id: sellerId },
    fetchPolicy: 'network-only',
  })

  const seller = useMemo(() => sellerData?.getSeller, [sellerData])

  const statuses = useMemo(
    () => seller?.settings?.order_statuses?.map(({ id, title }) => ({ id, name: title || '' })) || [],
    [seller],
  )

  const {
    data: paymentData,
    loading: paymentLoading,
    error: paymentError,
    refetch: refetchPayment,
  } = useGetPaymentSettings(GET_PAYMENT_SETTINGS, {
    variables: { seller_id: sellerId },
    fetchPolicy: 'network-only',
    onCompleted({ getPaymentSettings }) {
      setInitialValues(prepareInitialValues(getPaymentSettings))
      setIsEnabledOffline.setValue(getPaymentSettings.offline?.status || false)
      setIsEnabledYooKassa.setValue(getPaymentSettings.yookassa?.status || false)
    },
  })

  const yookassaSettings = useMemo(() => paymentData?.getPaymentSettings.yookassa || null, [paymentData])

  const [setPaymentSettings] = useSetPaymentSettings(SET_PAYMENT_SETTINGS)
  const [setPaymentMethodStatus] = useSetPaymentMethodStatus(SET_PAYMENT_METHOD_STATUS)
  const [checkPaymentMethod] = useCheckPaymentMethod(CHECK_PAYMENT_METHOD, {
    onError() {
      addNotify('error')
    },
    onCompleted(response) {
      if (response.checkPaymentMethod.status) {
        refetchPayment()
      } else {
        addNotify({ kind: 'error', message: 'Не удалось подключить UKassa' })
      }
    },
  })

  const toggleOfflinePayment = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target

      setIsEnabledOffline.setValue(checked)
      setPaymentMethodStatus({
        variables: {
          seller_id: sellerId,
          method: 'OFFLINE',
          status: checked,
        },
      })
    },
    [setIsEnabledOffline, setPaymentMethodStatus, sellerId],
  )

  const toggleYooKassa = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target

      setIsEnabledYooKassa.setValue(checked)
      setPaymentMethodStatus({
        variables: {
          seller_id: sellerId,
          method: 'YOOKASSA',
          status: checked,
        },
      })
    },
    [setIsEnabledYooKassa, setPaymentMethodStatus, sellerId],
  )

  const onSubmit = useCallback(
    (
      values: IPaymentMethodsFormValues,
      { setErrors, resetForm }: FormikHelpers<IPaymentMethodsFormValues>,
    ) => {
      setPaymentSettings({
        variables: {
          seller_id: sellerId,
          input: prepareSubmitValues(values, { isEnabledOffline, isEnabledYooKassa }),
        },
      })
        .then((response) => {
          const nextData = response.data?.setPaymentSettings
          if (!nextData) throw new Error('No setPaymentSettings data')

          const initValues = prepareInitialValues(nextData)
          resetForm({ values: initValues })
          setInitialValues(initValues)
          addNotify('success')
        })
        .catch((resError) => {
          addNotify('error')
          const errors = convertToFormErrors(resError)
          if (errors) setErrors(errors)
        })
    },
    [setPaymentSettings, addNotify, sellerId, isEnabledOffline, isEnabledYooKassa],
  )

  useEffect(() => {
    const { code, state } = searchParams
    if (!code || !state) return
    const json_data = JSON.stringify({ code, state })
    checkPaymentMethod({ variables: { seller_id: sellerId, method: 'YOOKASSA', json_data } })
  }, [searchParams, sellerId, checkPaymentMethod])

  if (sellerLoading || paymentLoading) {
    return (
      <PageContainer>
        <GoBackLink href={integrations} />
        <ResultTitle>Способы оплаты</ResultTitle>
        <Preloader />
      </PageContainer>
    )
  }

  if (sellerError || paymentError) {
    return (
      <PageContainer>
        <GoBackLink href={integrations} />
        <ResultTitle>Способы оплаты</ResultTitle>
        <ApiError error={sellerError || paymentError} />
      </PageContainer>
    )
  }

  if (!seller) return null

  return (
    <PageContainer>
      <GoBackLink href={integrations} />

      <ResultTitle>Способы оплаты</ResultTitle>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <LeavePageDirtyFormHelper />
            <AddSubmitShortcutFormHelper />

            <DropMenu title="Общие настройки">
              <div className={styles.contentWrapper}>
                <SelectInput
                  className={styles.input}
                  name="success_status"
                  required
                  label="Статус для успешной оплаты"
                  placeholder="Выберите статус"
                  options={statuses}
                />
                <SelectInput
                  className={styles.input}
                  name="error_status"
                  required
                  label="Статус для ошибок оплаты"
                  placeholder="Выберите статус"
                  options={statuses}
                />
                <SelectInput
                  name="wait_status"
                  required
                  label="Статус ожидания обработки платежа"
                  placeholder="Выберите статус"
                  options={statuses}
                />
              </div>
            </DropMenu>

            <DropMenu
              title="Оффлайн оплата"
              titleChildren={
                <div className={styles.switchWrapper} onClick={(event) => event.stopPropagation()}>
                  <Switch isChecked={isEnabledOffline} onChange={toggleOfflinePayment} />
                </div>
              }
            >
              <div className={styles.contentWrapper}>
                <TextInput
                  name="offline.name"
                  label="Название для покупателей‍"
                  placeholder="Введите название для покупателей‍"
                  margin="default"
                  inputProps={{ maxLength: 70 }}
                  labelAsideRight={<LimitText limit={70} value={values.offline.name} />}
                />
                <TextInput
                  name="offline.description"
                  label="Описание способа оплаты"
                  placeholder="Введите описание способа оплаты"
                />
              </div>
            </DropMenu>

            <DropMenu
              title="Параметры подключения"
              titleChildren={
                <div className={styles.switchWrapper} onClick={(event) => event.stopPropagation()}>
                  <Switch isChecked={isEnabledYooKassa} onChange={toggleYooKassa} />
                </div>
              }
            >
              <div className={styles.contentWrapper}>
                <div className={styles.accessGroup}>
                  {yookassaSettings?.access_status ? (
                    <div className={styles.successGroup}>
                      <SuccessIcon />
                      <Text>Сервис подключен корректно</Text>
                    </div>
                  ) : (
                    <Link href={yookassaSettings?.link_to_connect || undefined} external>
                      <Button>Подключить сервис</Button>
                    </Link>
                  )}
                </div>
                <TextInput
                  name="yookassa.name"
                  label="Название для покупателей‍"
                  placeholder="Введите название способа оплаты"
                  margin="default"
                  inputProps={{ maxLength: 70 }}
                  labelAsideRight={<LimitText limit={70} value={values.yookassa.name} />}
                />
                <TextInput
                  name="yookassa.description"
                  label="Описание способа оплаты"
                  placeholder="Введите описание для покупателей"
                />
              </div>
            </DropMenu>

            <FormControlsPanel className={styles.controlsPanel}>
              <Button type="submit">Сохранить</Button>
            </FormControlsPanel>
          </form>
        )}
      </Formik>
    </PageContainer>
  )
}

export default withErrorBoundary(memo(PaymentMethods))
