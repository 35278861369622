import React, { useCallback } from 'react'
import { useField } from 'formik'

import DragAndDropField, { IDragAndDropProps } from 'components/DragAndDropField'
import formikFieldEvent from 'utils/formInputs/formikFieldEvent'

type IDragAndDropInputProps = Omit<IDragAndDropProps, 'value' | 'onChange' | 'onDelete'> & {
  name: string
}

type IValueItem = { path?: string; flag?: string } | File | null

const DragAndDropInput: React.FC<IDragAndDropInputProps> = ({ name, ...props }) => {
  const [{ value, onChange }] = useField<IValueItem>(name)

  const onChangeHelper = useCallback(
    (payload: File) => {
      onChange(formikFieldEvent(name, payload))
    },
    [onChange, name],
  )

  const onDelete = useCallback(() => {
    const nextValue = value instanceof File ? null : { flag: 'delete' }
    onChange(formikFieldEvent(name, nextValue))
  }, [onChange, name, value])

  return <DragAndDropField value={value} onChange={onChangeHelper} onDelete={onDelete} {...props} />
}

export default DragAndDropInput
