import React from 'react'

import Button from 'components/Button'
import Link from 'components/Link'
import { H1 } from 'components/Typography'

import styles from './NotFound.module.scss'

const NotFound: React.FC = () => (
  <div className={styles.AdminError}>
    <div className={styles.AdminError__WrapImage}>
      <img src="/images/admin404.svg" alt="404" />
    </div>
    <div className={styles.AdminError__WrapDesc}>
      <H1 className={styles.AdminError__WrapDesc_Title}>К сожалению, страница не найдена</H1>
      <p className={styles.AdminError__WrapDesc_Text}>
        Страница, которую вы запрашиваете, не существует. <br />
        Попробуйте найти необходимую информацию на главной странице.
      </p>
    </div>
    <Link href="/">
      <Button>Перейти на главную</Button>
    </Link>
  </div>
)

export default NotFound
