import { gql } from '@apollo/client'

type IStatus = {
  status: boolean
  message?: string
  data?: string
}

export type IAddExportScheduleParams = {
  seller_id: IPrimaryKey
}

export type IAddExportSchedule = {
  addExportSchedule: IStatus
}

export const ADD_EXPORT_SCHEDULE = gql`
  mutation addExportSchedule($seller_id: ID!) {
    addExportSchedule(seller_id: $seller_id) {
      status
      message
      data
    }
  }
`
