import React from 'react'

import Text from 'components/Typography'
import block from 'utils/block'

import styles from './optionItem.module.scss'

export type IOptionItemProps = {
  showOptionId?: boolean
  value: IPrimaryKey | null
  option: IOption<IPrimaryKey>
  onClick: (value: IPrimaryKey) => void
}

const b = block(styles)

const OptionItem: React.FC<IOptionItemProps> = ({ value, showOptionId = false, option, onClick }) => (
  <button
    className={b('container', { active: value === option.id })}
    type="button"
    onClick={() => onClick(option.id)}
  >
    <Text className={styles.name}>
      {option.name} {showOptionId ? <span className={styles.id}>ID {option.id}</span> : null}
    </Text>
  </button>
)

export default OptionItem
