import React, { memo, useCallback } from 'react'
import { useMutation, useQuery } from '@apollo/client'

import Button from 'components/Button'
import Preloader from 'components/Preloader'
import PageContainer from 'components/PageContainer'
import { IGetGroups, IGetGroupsParams, GET_GROUPS } from 'graphql/queries/getGroups'
import { DELETE_GROUP, IDeleteGroup, IDeleteGroupParams } from 'graphql/mutations/deleteGroup'
import ResultTitle from 'components/ResultTitle'
import Pagination from 'components/Pagination'
import hasPagination from 'utils/hasPagination'
import useCmsParams from 'hooks/useCmsParams'
import useBoolean from 'hooks/useBoolean'
import SuccessDialog from 'components/Dialogs/SuccessDialog'
import ApiError from 'components/ApiError'
import withErrorBoundary from 'hocs/withErrorBoundary'
import useNotifyCms from 'hooks/useNotifyCms'
import useSearchParams from 'hooks/useSearchParams'
import buildPaginateParams from 'utils/buildPaginateParams'

import ModalCreate from './components/ModalCreate'
import CardGroup from './components/CardGroup'
import Empty from './components/Empty'

import styles from './groupsList.module.scss'

type IPayload = {
  page: string
}

const initPayload: IPayload = {
  page: '1',
}

const GroupsList: React.FC = () => {
  const { sellerId } = useCmsParams()
  const addNotify = useNotifyCms()

  const [isSuccessDeleteDialogOpen, setSuccessDeleteDialog] = useBoolean()
  const [isCreateDialogOpen, setSCreateDialog] = useBoolean()
  const [searchParams, setSearchParams] = useSearchParams<IPayload>(initPayload)
  const { page } = searchParams

  const { data, loading, error } = useQuery<IGetGroups, IGetGroupsParams>(GET_GROUPS, {
    variables: {
      seller_id: sellerId,
      paginate: buildPaginateParams(page),
    },
    fetchPolicy: 'network-only',
  })

  const { getGroups: { list: groups = [], elements = 0, pages = 0 } = {} } = data || {}

  const [onDeleteGroup] = useMutation<IDeleteGroup, IDeleteGroupParams>(DELETE_GROUP, {
    refetchQueries: [GET_GROUPS],
    onCompleted: ({ delGroup }) => {
      if (delGroup.status) {
        addNotify({ kind: 'success', message: 'Группа удалена' })
      } else {
        addNotify('warning')
      }
    },
  })

  const onDelete = useCallback(
    (payload: IPrimaryKey) => onDeleteGroup({ variables: { id: payload } }),
    [onDeleteGroup],
  )

  const onPageChange = useCallback(
    (nextPage: number) => {
      setSearchParams((prev) => ({ ...prev, page: nextPage.toString() }))
    },
    [setSearchParams],
  )

  const groupNumbers = groups.map((el) => el.number).filter((el): el is number => typeof el === 'number')

  const isEmpty = !loading && !groups.length

  if (error) {
    return (
      <PageContainer>
        <ResultTitle>Группы</ResultTitle>
        <ApiError error={error} />
        <ModalCreate isOpen={isCreateDialogOpen} onClose={setSCreateDialog.off} numberList={groupNumbers} />
      </PageContainer>
    )
  }

  if (isEmpty) {
    return (
      <PageContainer>
        <Empty onClick={setSCreateDialog.on} />
        <ModalCreate isOpen={isCreateDialogOpen} onClose={setSCreateDialog.off} numberList={groupNumbers} />
      </PageContainer>
    )
  }

  return (
    <PageContainer>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <ResultTitle>Группы</ResultTitle>
          <div className={styles.headerWrapper}>
            <Button onClick={setSCreateDialog.on}>Создать группу</Button>
          </div>

          {groups.map((group) => (
            <CardGroup
              key={group.id}
              group={group}
              numberList={groupNumbers}
              currentPage={page}
              onDelete={onDelete}
            />
          ))}
          {hasPagination(elements) && (
            <Pagination page={Number(page)} count={pages} onChange={onPageChange} />
          )}
        </>
      )}

      <ModalCreate isOpen={isCreateDialogOpen} onClose={setSCreateDialog.off} numberList={groupNumbers} />

      <SuccessDialog
        text="Группа удалена"
        isOpen={isSuccessDeleteDialogOpen}
        onClose={setSuccessDeleteDialog.off}
      />
    </PageContainer>
  )
}

export default withErrorBoundary(memo(GroupsList))
