interface IParams {
  domain?: string
}

const getAppUrl = ({ domain = 'my' }: IParams = {}): string => {
  switch (process.env.REACT_APP_ENV) {
    case 'local':
      return `http://${domain}.localhost:3000/`
    case 'stage':
      return `https://${domain}.stage.sellty.ru/`
    case 'develop':
      return `https://${domain}.test.sellty.ru/`
    case 'prod':
      return `https://${domain}.sellty.ru/`
    default:
      return `https://${domain}.sellty.ru/`
  }
}

export default getAppUrl
