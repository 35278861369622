import React from 'react'

import Input from 'components/ui-kit/input'
import Text from 'components/Typography'
import { ReactComponent as Clock } from 'assets/img/clockIcon_warning.svg' // TODO: replace with icon

import styles from './ordersEmail.module.scss'

type IOrdersEmailProps = {
  verifiedAt: string | null
  oldEmail: string | undefined
  email: string
  setEmail: (value: string) => void
  onResendMailClick: () => void
}

const OrdersEmail: React.FC<IOrdersEmailProps> = ({
  verifiedAt,
  oldEmail,
  email,
  setEmail,
  onResendMailClick,
}) => (
  <div className={styles.container}>
    <Text className={styles.inputLabel}>
      Информация о заказах
      {!verifiedAt && !!oldEmail && <Clock className={styles.labelIcon} />}
    </Text>
    <div className={styles.inputWrapper}>
      <Input className={styles.input} value={email} onChangeHandler={setEmail} />
      <Text color="textSecondary">E-mail для заказов и уведомлений из портала</Text>
    </div>

    {!verifiedAt && !!oldEmail && (
      <div className={styles.verifyGroup}>
        <p className={styles.verifyTitle}>E-mail не был подтвержден</p>
        <p>Чтобы получить доступ ко всем возможностям платформы Sellty, подтвердите ваш e-mail.</p>
        <button className={styles.verifyButton} type="button" onClick={onResendMailClick}>
          Отправить повторно
        </button>
      </div>
    )}
  </div>
)

export default OrdersEmail
