import React, { useCallback, useMemo, useState, createContext } from 'react'
import isString from 'lodash/isString'
import uniqueId from 'lodash/uniqueId'

import ScreenBlocker from './components/ScreenBlocker'

export type IINoticeKind = 'error' | 'warning' | 'success'
export type IINoticeVariant = 'cms'

export type IAddNotice = Omit<INotice, 'id'>

export interface INotice {
  id: string
  kind: IINoticeKind
  variant?: IINoticeVariant
  message?: string | React.ReactNode
}

export interface INotifyContext {
  notifyQueue: INotice[]
  addNotify: (notice: IINoticeKind | IAddNotice, variant?: IINoticeVariant) => void
  removeNotify: (id: string) => void
}

const getMessage: Record<IINoticeKind, string> = {
  success: 'Данные  сохранены',
  warning: 'Сервер не отвечает',
  error: 'Данные не сохранены. Попробуйте еще раз',
}

export const NotifyContext = createContext<INotifyContext>({} as INotifyContext)

const NotifyProvider: React.FC = ({ children }) => {
  const [notifyQueue, setNotifyQueue] = useState<INotice[]>([])

  const addNotify = useCallback((notice: IINoticeKind | IAddNotice, variant?: IINoticeVariant) => {
    const notify: INotice = {
      id: uniqueId('notify'),
      ...(isString(notice)
        ? { kind: notice, message: getMessage[notice], variant }
        : { message: getMessage[notice.kind], variant, ...notice }),
    }

    setNotifyQueue((prevState) => [...prevState, notify])
  }, [])

  const removeNotify = useCallback((id: string) => {
    setNotifyQueue((prevState) => prevState.filter((notify) => notify.id !== id))
  }, [])

  const value: INotifyContext = useMemo(
    () => ({ notifyQueue, addNotify, removeNotify }),
    [notifyQueue, addNotify, removeNotify],
  )

  return (
    <NotifyContext.Provider value={value}>
      <ScreenBlocker />

      {children}
    </NotifyContext.Provider>
  )
}

export default NotifyProvider
