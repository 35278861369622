import React from 'react'

import Text, { H1 } from 'components/Typography'
import Button from 'components/Button'
import useCmsParams from 'hooks/useCmsParams'
import Link from 'components/Link'

import styles from './empty.module.scss'

const Empty: React.FC = () => {
  const { sellerId } = useCmsParams()

  return (
    <div className={styles.container}>
      <H1 className={styles.title}>Здесь будут менеджеры</H1>
      <Text className={styles.subtitle}>У вас пока нет ни одного менеджера. Создайте первый!</Text>
      <Link href={`/${sellerId}/managers/create`}>
        <Button>Создать менеджера</Button>
      </Link>
    </div>
  )
}

export default Empty
