import { DocumentNode, MutationHookOptions, gql, useMutation } from '@apollo/client'

import { Seller } from 'types/types'

export type ISetSellerStoresStatus = {
  setSellerStoresStatus: Seller
}

export type ISetSellerStoresStatusParams = {
  id: IPrimaryKey
  stores_status: boolean
}

export const buildSetSellerStoresStatusQuery = (fileds: string) => gql`
  mutation setSellerStoresStatus($id: ID!, $stores_status: Boolean!) {
    setSellerStoresStatus(id: $id, stores_status: $stores_status) {
      ${fileds}
    }
  }
`

export const useSetSellerStoresStatusMutation = <
  TData = ISetSellerStoresStatus,
  TOptions = ISetSellerStoresStatusParams,
>(
  fields: DocumentNode,
  options?: MutationHookOptions<TData, TOptions>,
) => useMutation<TData, TOptions>(fields, options)
