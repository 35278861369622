import { object, string, date } from 'yup'

import { REQUIRED_MESSAGE, WRONG_EMAIL_FORMAT } from 'consts/errorMessages'

const validationSchema = () =>
  object({
    name: string().required(REQUIRED_MESSAGE),
    second_name: string(),
    email: string().email(WRONG_EMAIL_FORMAT),
    phone: string(),
    email_verified_at: date().nullable(),
  })

export default validationSchema
