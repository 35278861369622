import { gql, MutationHookOptions, useMutation } from '@apollo/client'

import { Status } from 'types/types'

export type ITruncateCategories = {
  truncateCategories: Status
}

export type ITruncateCategoriesParams = {
  seller_id: string
}

export const TRUNCATE_CATEGORIES = gql`
  mutation truncateCategories($seller_id: ID!) {
    truncateCategories(seller_id: $seller_id) {
      status
      message
    }
  }
`

export const useTruncateCategories = <TData = ITruncateCategories, TOptions = ITruncateCategoriesParams>(
  options?: MutationHookOptions<TData, TOptions>,
) => useMutation<TData, TOptions>(TRUNCATE_CATEGORIES, options)
