import { DocumentNode, MutationHookOptions, gql, useMutation } from '@apollo/client'

import { ISettings } from 'types/types'

export type ISetSellerDeliveryOptionParams = {
  seller_id: IPrimaryKey
  delivery_status: boolean
}

export type ISetSellerDeliveryOption = {
  setSellerDeliveryOption: ISettings
}

export const buildSetSellerDeliveryOptionQuery = (fields: string): DocumentNode => gql`
  mutation setSellerDeliveryOption($seller_id: ID!, $delivery_status: Boolean!) {
    setSellerDeliveryOption(seller_id: $seller_id, delivery_status: $delivery_status) {
      ${fields}
    }
  }
`

export const useSetSellerDeliveryOptionMutation = <
  TData = ISetSellerDeliveryOption,
  TOptions = ISetSellerDeliveryOptionParams,
>(
  fields: DocumentNode,
  options?: MutationHookOptions<TData, TOptions>,
) => useMutation<TData, TOptions>(fields, options)
