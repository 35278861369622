import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'

import Text from 'components/Typography'
import Switch from 'components/Switch'
import InfoIcon from 'components/Icons/InfoIcon'
import useCurrentSeller from 'hooks/useCurrentSeller'
import {
  useSetSellerStoresStatusMutation,
  buildSetSellerStoresStatusQuery,
} from 'graphql/mutations/setSellerStoresStatus'
import { useSeller } from 'contexts/SellerProvider'
import useCmsParams from 'hooks/useCmsParams'
import useNotifyCms from 'hooks/useNotifyCms'

import styles from './distributeStores.module.scss'

const SET_SELLER_STORES_STATUS = buildSetSellerStoresStatusQuery(`stores_status`)

const DistributeStores: React.FC = () => {
  const { stores_status } = useCurrentSeller()
  const { refetch } = useSeller()
  const { sellerId } = useCmsParams()
  const addNotify = useNotifyCms()

  const [onSellerStoresStatusChange] = useSetSellerStoresStatusMutation(SET_SELLER_STORES_STATUS, {
    variables: {
      id: sellerId,
      stores_status: !stores_status,
    },
    onCompleted: () => {
      refetch()
    },
    onError: () => {
      addNotify('error')
    },
  })

  return (
    <div className={styles.container}>
      <Switch
        isChecked={stores_status}
        placement="right"
        gap="medium"
        label={<Text>Распределять остатки по складам</Text>}
        onChange={() => onSellerStoresStatusChange()}
      />
      <Tooltip
        title={
          <Text size="caption" color="textSecondary">
            После отключении функции распределения остатков контрагенты будут видеть общий остаток по всем
            складам.
          </Text>
        }
        arrow
        placement="top"
      >
        <div className={styles.touch}>
          <InfoIcon />
        </div>
      </Tooltip>
    </div>
  )
}

export default DistributeStores
