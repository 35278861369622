import React from 'react'
import { DatePicker, DatePickerProps } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

import CalendarIcon from 'components/Icons/CalendarIcon'

import TextField, { ITextFieldProps } from './TextFieldOld'

import styles from './dateField.module.scss'

export type IDateFieldProps = DatePickerProps
export type IDateChange = MaterialUiPickersDate

const DateTextField: React.FC<ITextFieldProps> = ({ InputProps = {}, ...props }) => {
  const { endAdornment = <CalendarIcon className={styles.calendarIcon} />, ...restInputProps } = InputProps

  return (
    <TextField
      InputProps={{
        endAdornment,
        ...restInputProps,
      }}
      {...props}
    />
  )
}

// TODO: needed a refactor, old TextField
const DateField: React.FC<IDateFieldProps> = ({
  autoOk = true,
  variant = 'inline',
  format = 'DD.MM.yyyy',
  TextFieldComponent = DateTextField,
  ...props
}) => (
  <DatePicker
    disableToolbar
    autoOk={autoOk}
    variant={variant}
    format={format}
    TextFieldComponent={TextFieldComponent}
    {...props}
  />
)

export default DateField
