import React, { memo, useMemo } from 'react'

import { IProduct } from 'types/types'
import Text from 'components/Typography'
import Button from 'components/Button'
import renderCurrencySymbol from 'utils/renderCurrencySymbol'
import useCurrentSeller from 'hooks/useCurrentSeller'
import NoImg from 'assets/img/no-img.svg'

import styles from './product.module.scss'

type IProductProps = {
  product: IProduct
  selectedIds: IPrimaryKey[]
  onAddProduct: (payload: IPrimaryKey) => void
  onRemoveProduct: (payload: IPrimaryKey) => void
}

// NOTE: format Price
const formatPrice = (price: number) => {
  const formatter = new Intl.NumberFormat('ru-RU', { style: 'decimal', currency: 'RUB' })
  return formatter.format(price)
}

const Product: React.FC<IProductProps> = ({ product, selectedIds, onAddProduct, onRemoveProduct }) => {
  const { id: productId, name, vendor_code, price, images } = product

  const { settings } = useCurrentSeller()

  const isSelected = useMemo(() => selectedIds.includes(productId), [selectedIds, productId])

  return (
    <div className={styles.container}>
      <img className={styles.image} src={images.length > 0 ? images[0].path : NoImg} alt={name} />
      <div className={styles.infoGroup}>
        <Text className={styles.name}>{name}</Text>
        <Text color="textSecondary" size="small">
          {vendor_code}
        </Text>
      </div>
      <div className={styles.priceGroup}>
        <Text className={styles.priceLabel} size="small">
          Цена
        </Text>
        {price ? (
          <Text bold>
            {formatPrice(Number(price))} {renderCurrencySymbol(settings?.currency)}
          </Text>
        ) : null}
      </div>
      <Button
        className={styles.controlButton}
        onClick={() => (isSelected ? onRemoveProduct(productId) : onAddProduct(productId))}
        color={isSelected ? 'secondary' : 'primary'}
        variant={isSelected ? 'contained' : 'outlined'}
      >
        {isSelected ? 'Удалить' : 'Добавить'}
      </Button>
    </div>
  )
}

export default memo(Product)
