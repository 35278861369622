import React from 'react'

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const CrossFilledIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <circle cx="12" cy="11" r="11" fill="#F9423A" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8892 6.89062L8.11099 14.6688L15.8892 6.89062Z"
      fill="white"
    />
    <path
      d="M15.8892 6.89062L8.11099 14.6688"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.11084 6.8898L15.889 14.668L8.11084 6.8898Z"
      fill="white"
    />
    <path
      d="M8.11084 6.8898L15.889 14.668"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)

export default CrossFilledIcon
