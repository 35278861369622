import React from 'react'

import block from 'utils/block'

import styles from './Preloader.module.scss'

type IPreloaderProps = {
  className?: string
  variant?: 'fixed' | 'relative'
  size?: 'small' | 'medium' | 'large'
}

const b = block(styles)

const Preloader: React.FC<IPreloaderProps> = ({ className, variant = 'fixed', size }) => (
  <div className={b('container', { variant, size }, className)}>
    <svg viewBox="0 0 50 50" className={styles.spinner}>
      <circle className={styles.ring} cx="25" cy="25" r="22.5" />
      <circle className={styles.line} cx="25" cy="25" r="22.5" />
    </svg>
  </div>
)

export default Preloader
