import { DocumentNode, gql, useMutation, MutationHookOptions } from '@apollo/client'

import { IContact } from 'types/types'

export type ISetContactStatusParams = {
  id: IPrimaryKey
  status: boolean
}

export type ISetContactStatus = {
  setContactStatus: IContact
}

export const buildSetContactQuery = (fields: string): DocumentNode => gql`
  mutation setContactStatus($id: ID!, $status: Boolean!) {
    setContactStatus(id: $id, status: $status) {
      ${fields}
    }
  }
`

export const useSetContactStatusMutation = (
  fields: DocumentNode,
  options?: MutationHookOptions<ISetContactStatus, ISetContactStatusParams>,
) => useMutation<ISetContactStatus, ISetContactStatusParams>(fields, options)
