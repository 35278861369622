import React from 'react'

import { useAuth } from 'contexts/AuthProvider'
import { H3 } from 'components/Typography'
import AuthHeader from 'components/AuthHeader'
import ErrorText from 'components/ErrorText'
import withErrorBoundary from 'hocs/withErrorBoundary'

import LoginForm from './components/LoginForm'

import styles from './signIn.module.scss'

export type LoginParamsType = {
  email: string
  password: string
}

const SignIn: React.FC = () => {
  const { login, loginLoading, errors } = useAuth()

  return (
    <div className={styles.Root}>
      <AuthHeader />
      <div className={styles.Container}>
        <H3 className={styles.ContainerSubTitle}>Авторизация</H3>
        <LoginForm submitLoading={loginLoading} onSubmit={login}>
          {errors ? <ErrorText align="center">{errors}</ErrorText> : null}
        </LoginForm>
      </div>
    </div>
  )
}

export default withErrorBoundary(SignIn)
