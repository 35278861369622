import React, { useMemo } from 'react'

import PageContainer from 'components/PageContainer'
import ResultTitle from 'components/ResultTitle'
import DropMenu from 'components/DropMenu'
import Text from 'components/Typography'
import Switch from 'components/Switch'
import Preloader from 'components/Preloader'
import ApiError from 'components/ApiError'
import useCmsParams from 'hooks/useCmsParams'
import useNotifyCms from 'hooks/useNotifyCms'
import { buildGetSellerQuery, useGetSellerQuery } from 'graphql/queries/getSeller'
import {
  useSetSellerDeliveryOptionMutation,
  buildSetSellerDeliveryOptionQuery,
} from 'graphql/mutations/setSellerDeliveryOption'

const GET_SELLER_FIELDS = buildGetSellerQuery(`
  id
  settings {
    delivery_status
  }
`)

const SET_SELLER_FIELDS = buildSetSellerDeliveryOptionQuery(`
  id
  delivery_status
`)

const Delivery: React.FC = () => {
  const { sellerId } = useCmsParams()
  const addNotify = useNotifyCms()

  const { data, loading, error, refetch } = useGetSellerQuery(GET_SELLER_FIELDS, {
    variables: { id: sellerId },
    fetchPolicy: 'network-only',
  })

  const seller = useMemo(() => data?.getSeller || null, [data])

  const deliveryStatus = seller?.settings?.delivery_status || false

  const [onSetSellerDeliveryOption] = useSetSellerDeliveryOptionMutation(SET_SELLER_FIELDS, {
    variables: {
      seller_id: sellerId,
      delivery_status: !deliveryStatus,
    },
    onCompleted: () => {
      refetch()
      addNotify('success')
    },
    onError: () => {
      addNotify('error')
    },
  })

  if (loading) {
    return <Preloader />
  }

  if (error) {
    return (
      <PageContainer>
        <ApiError />
      </PageContainer>
    )
  }

  return (
    <PageContainer>
      <ResultTitle>Доставка</ResultTitle>

      <DropMenu title="Настройка доставки">
        <Switch
          isChecked={deliveryStatus}
          label={<Text>Разрешить доставку</Text>}
          placement="right"
          gap="medium"
          onChange={() => onSetSellerDeliveryOption()}
        />
      </DropMenu>
    </PageContainer>
  )
}

export default Delivery
