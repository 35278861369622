import { DocumentNode, gql, useMutation, MutationHookOptions } from '@apollo/client'

import { IPaymentMethodTypes, Status } from 'types/types'

export type ICheckPaymentMethodParams = {
  seller_id: IPrimaryKey
  method: IPaymentMethodTypes
  json_data: string
}

export type ICheckPaymentMethod = {
  checkPaymentMethod: Status
}

export const CHECK_PAYMENT_METHOD = gql`
  mutation checkPaymentMethod($seller_id: ID!, $method: PaymentMethodTypes!, $json_data: String!) {
    checkPaymentMethod(seller_id: $seller_id, method: $method, json_data: $json_data) {
      status
      message
      data
    }
  }
`

export const useCheckPaymentMethod = <TData = ICheckPaymentMethod, TOptions = ICheckPaymentMethodParams>(
  fields: DocumentNode,
  options?: MutationHookOptions<TData, TOptions>,
) => useMutation<TData, TOptions>(fields, options)
