import React, { useCallback, useState } from 'react'
import { Formik } from 'formik'
import { useMutation } from '@apollo/client'

import { H2 } from 'components/Typography'
import Preloader from 'components/Preloader'
import HiddenDownload from 'components/HiddenDownload'
import Button from 'components/Button'
import DateInput from 'components/Inputs/DateInput'
import {
  EXPORT_ORDERS_TABLE,
  IExportOrdersTable,
  IExportOrdersTableParams,
} from 'graphql/mutations/exportOrdersTable'
import useCmsParams from 'hooks/useCmsParams'
import date from 'utils/date'
import Dialog from 'components/Dialog'
import DialogContent from 'components/DialogContent'
import DialogCloseButton from 'components/DialogCloseButton'

import styles from './importDialog.module.scss'

interface IConfirmDialogProps {
  isOpen: boolean
  onClose: () => void
}

const apiDateFormat = 'yyyy-MM-DD'

const initialValue = {
  start: date(),
  end: date(),
}

const ImportDialog: React.FC<IConfirmDialogProps> = ({ isOpen, onClose }) => {
  const { sellerId } = useCmsParams()

  const [downloadUrl, setDownloadUrl] = useState<string | undefined>()

  const [exportOrders, { loading }] = useMutation<IExportOrdersTable, IExportOrdersTableParams>(
    EXPORT_ORDERS_TABLE,
    {
      onCompleted(data) {
        setDownloadUrl(data.exportOrdersTable.message)
      },
    },
  )

  const onSubmit = useCallback(
    (data: any) =>
      exportOrders({
        variables: {
          seller_id: sellerId,
          start: date(data.start).format(apiDateFormat),
          end: date(data.end).format(apiDateFormat),
        },
      }),
    [sellerId, exportOrders],
  )

  const clearDownloadUrl = useCallback(() => {
    setDownloadUrl(undefined)
    onClose()
  }, [onClose])

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <DialogCloseButton onClick={onClose} />
      <DialogContent className={styles.content}>
        <H2 className={styles.title}>Выгрузка заказов</H2>
        <p className={styles.subTitle}>Укажите даты, за которые хотите выгрузить заказы.</p>
        <Formik initialValues={initialValue} onSubmit={onSubmit}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className={styles.inputsGroup}>
                <DateInput name="start" label="С" />
                <DateInput name="end" label="По" />
              </div>
              {loading && <Preloader />}
              <div className={styles.actionsGroup}>
                <Button type="submit">Выгрузить</Button>
                <Button variant="outlined" onClick={onClose}>
                  Отменить
                </Button>
              </div>
            </form>
          )}
        </Formik>
        <HiddenDownload downloadUrl={downloadUrl} clearUrl={clearDownloadUrl} />
      </DialogContent>
    </Dialog>
  )
}

export default ImportDialog
