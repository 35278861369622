import { DocumentNode, MutationHookOptions, gql, useMutation } from '@apollo/client'

import { IBanner } from 'types/types'

export type ISetBannerStatus = {
  setBannerStatus: IBanner
}

export type ISetBannerStatusParams = {
  id: IPrimaryKey
  status: boolean
}

export const buildSetBannerStatusQuery = (fields: string): DocumentNode => gql`
  mutation setBannerStatus($id: ID!, $status: Boolean!) {
    setBannerStatus(id: $id, status: $status) {
      ${fields}
    }
  }
`

export const useSetBannerStatusMutation = <TData = ISetBannerStatus, TOptions = ISetBannerStatusParams>(
  fields: DocumentNode,
  options?: MutationHookOptions<TData, TOptions>,
) => useMutation<TData, TOptions>(fields, options)
