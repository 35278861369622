import React, { memo, useCallback, useState } from 'react'
import { useMutation } from '@apollo/client'

import {
  ADD_EXPORT_SCHEDULE,
  IAddExportSchedule,
  IAddExportScheduleParams,
} from 'graphql/mutations/addExportSchedule'
import Link from 'components/Link'
import Text from 'components/Typography'
import useCmsParams from 'hooks/useCmsParams'
import useBoolean from 'hooks/useBoolean'
import LoadingDialog from 'components/Dialogs/LoadingDialog'
import ErrorDialog from 'components/Dialogs/ErrorDialog'

import styles from './exportCatalog.module.scss'

const errorMessages: Record<string, string> = {
  'products export already started': 'Экспорт уже запущен, ожидайте ссылку на почту',
  'products export not added': 'Что то пошло не так, попробуйте позже',
}

const ExportCatalog: React.FC = () => {
  const { sellerId } = useCmsParams()

  const [isLoadingDialogOpen, setIsLoadingDialogOpen] = useBoolean()
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useBoolean()
  const [errorMessage, setErrorMessage] = useState<string>('')

  const [onAddExportSchedule] = useMutation<IAddExportSchedule, IAddExportScheduleParams>(
    ADD_EXPORT_SCHEDULE,
    {
      onCompleted({ addExportSchedule }) {
        const { status, message } = addExportSchedule

        if (status) {
          setIsLoadingDialogOpen.on()
        } else {
          setIsErrorDialogOpen.on()
          setErrorMessage(message || '')
        }
      },
    },
  )

  const onExportClick = useCallback(() => {
    onAddExportSchedule({
      variables: {
        seller_id: sellerId,
      },
    })
  }, [onAddExportSchedule, sellerId])

  return (
    <>
      <Link className={styles.exportButton} component="button" onClick={onExportClick}>
        Экспорт каталога
      </Link>

      <LoadingDialog
        isOpen={isLoadingDialogOpen}
        onClose={setIsLoadingDialogOpen.off}
        title="Идет подготовка файла"
        content={
          <Text className={styles.subtitle}>
            Экспорт каталога запущен. <br /> Ожидайте ссылку на скачивание файла на почте.
          </Text>
        }
      />

      <ErrorDialog
        isOpen={isErrorDialogOpen}
        onClose={setIsErrorDialogOpen.off}
        content={<Text>{errorMessages[errorMessage]}</Text>}
      />
    </>
  )
}

export default memo(ExportCatalog)
