import React, { memo, useMemo } from 'react'

import { IOrder, IOrderStatus } from 'types/types'
import useCmsParams from 'hooks/useCmsParams'
import block from 'utils/block'
import Link from 'components/Link'
import Text from 'components/Typography'
import date from 'utils/date'
import createClientEditLink from 'utils/links/createClientEditLink'
import createOrderLink from 'utils/links/createOrderLink'
import createCompanyEditLink from 'utils/links/createCompanyEditLink'
import withErrorBoundary from 'hocs/withErrorBoundary'
import renderCurrencySymbol from 'utils/renderCurrencySymbol'
import createContactEditLink from 'utils/links/createContactEditLink'
import useCurrentSeller from 'hooks/useCurrentSeller'
import { DEFAULT_ORDER_STATUS_COLOR } from 'consts/defaultValues'

import Row from '../Row'

import styles from './orderCard.module.scss'

type IOrderCardProps = {
  order: IOrder
}

type IVisitedProps = {
  visited: boolean
}

const b = block(styles)

const VisitedIndicator: React.FC<IVisitedProps> = ({ visited }) => (
  <div className={b('visitedIndicator', { visited })} />
)

const OrderStatus: React.FC<{ status: IOrderStatus }> = ({ status }) => {
  const { color, title } = status

  return (
    <Text
      className={styles.orderStatus}
      style={{ backgroundColor: color || DEFAULT_ORDER_STATUS_COLOR }}
      size="small"
      bold
      title={title || 'Выберите статус'}
    >
      {title || 'Выберите статус'}
    </Text>
  )
}

const CreatedDate: React.FC<{ order: IOrder }> = ({ order }) => {
  const { new: notVisited = false, created_at } = order

  if (!created_at) return <div>NO_DATE</div>

  const dateTime = date(created_at).format('DD.MM.yyyy')
  const time = date(created_at).format('HH:mm')

  return (
    <Text bold={notVisited} color="textPrimary" size="small">
      {dateTime}{' '}
      <Text variant="inherit" color="textSecondary" component="span">
        {time}
      </Text>
    </Text>
  )
}

const TotalPrice: React.FC<{ price: number; order: IOrder }> = ({ price, order }) => {
  const { new: notVisited = false } = order

  const { settings } = useCurrentSeller()

  return (
    <Text bold={notVisited} color="textPrimary" align="right" size="small">
      {price.toFixed(2).replace('.00', '')} {renderCurrencySymbol(settings?.currency)}
    </Text>
  )
}

const OrderCard: React.FC<IOrderCardProps> = ({ order }) => {
  const {
    id: orderId,
    new: notVisited = false,
    number,
    order_status,
    customer,
    client,
    products,
    contact = null,
    company = null,
  } = order

  const { sellerId } = useCmsParams()

  const isCompany = customer?.company !== null

  const totalPrice = useMemo(
    () => products.reduce((acc, product) => product.price * product.amount + acc, 0),
    [products],
  )

  return (
    <Link href={`/${sellerId}/${createOrderLink(orderId)}`} className={styles.container}>
      <VisitedIndicator visited={notVisited} />
      <Row>
        <Text size="small" bold={notVisited}>
          {number}
        </Text>

        {order_status ? <OrderStatus status={order_status} /> : <div />}

        {company && client ? (
          <Text bold={notVisited} size="small">
            <Link
              href={`/${sellerId}/${createCompanyEditLink(client.id, company.id)}`}
              onClick={(event) => event.stopPropagation()}
            >
              {company.name || 'NO_COMPANY_NAME'}
            </Link>
          </Text>
        ) : (
          <>
            {isCompany ? (
              <Text bold={notVisited} size="small" color="textPrimary">
                {customer?.company?.name || 'NO_COMPANY_NAME'}
              </Text>
            ) : (
              <Text bold={notVisited} size="small" color="textPrimary">
                -
              </Text>
            )}
          </>
        )}

        {isCompany ? (
          <>
            {client ? (
              <Text bold={notVisited} size="small">
                <Link
                  href={`/${sellerId}/${createClientEditLink(client.id)}`}
                  onClick={(event) => event.stopPropagation()}
                >
                  {client.name}
                </Link>
              </Text>
            ) : (
              <Text bold={notVisited} color="textPrimary" size="small">
                {customer?.company?.name || 'NO_CUSTOMER_COMPANY_NAME'}
              </Text>
            )}
          </>
        ) : (
          <>
            {contact ? (
              <Text bold={notVisited} size="small">
                <Link
                  href={`/${sellerId}/${createContactEditLink(contact.id)}`}
                  onClick={(event) => event.stopPropagation()}
                >
                  {contact.name}
                </Link>
              </Text>
            ) : (
              <Text bold={notVisited} color="textPrimary" size="small">
                {customer?.contact?.name || 'NO_CUSTOMER_CONTACT_NAME'}
              </Text>
            )}
          </>
        )}

        <CreatedDate order={order} />
        <TotalPrice price={totalPrice} order={order} />
      </Row>
    </Link>
  )
}

export default withErrorBoundary(memo(OrderCard))
