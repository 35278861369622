import { DocumentNode, MutationHookOptions, gql, useMutation } from '@apollo/client'

import { IExchange1CAccess, IExchange1CAccessInput } from 'types/types'

export type IAdd1CAccess = {
  add1CAccess: IExchange1CAccess
}

export type IAdd1CAccessParams = {
  seller_id: IPrimaryKey
  input?: IExchange1CAccessInput
}

export const buildAdd1CAccessQuery = (fields: string): DocumentNode => gql`
  mutation add1CAccess($seller_id: ID!, $input: Exchange1CAccessInput) {
    add1CAccess(seller_id: $seller_id, input: $input) {
      ${fields}
    }
  }
`

export const useAdd1CAccessMutation = <TData = IAdd1CAccess, TOptions = IAdd1CAccessParams>(
  fields: DocumentNode,
  options?: MutationHookOptions<TData, TOptions>,
) => useMutation<TData, TOptions>(fields, options)
