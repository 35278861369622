import React, { memo } from 'react'

import useCmsParams from 'hooks/useCmsParams'
import { buildGetOrdersQuery, useGetOrdersQuery } from 'graphql/queries/getOrders'
import withErrorBoundary from 'hocs/withErrorBoundary'

import styles from './InfoCounter.module.scss'

export const SIDEBAR_GET_ORDERS = buildGetOrdersQuery('id')

const ORDERS_UPDATE_INTERVAL = 600000

const InfoCounter: React.FC = () => {
  const { sellerId } = useCmsParams()

  const { data: orders } = useGetOrdersQuery(SIDEBAR_GET_ORDERS, {
    variables: {
      seller_id: sellerId,
      paginate: { limit: 1, page: 1 },
    },
    pollInterval: ORDERS_UPDATE_INTERVAL,
    fetchPolicy: 'no-cache',
  })

  const ordersCounter = orders?.getOrders.news || null

  if (!ordersCounter) return null

  return <p className={styles.infoCounter}>{ordersCounter}</p>
}

export default withErrorBoundary(memo(InfoCounter))
