import React, { useCallback } from 'react'
import { useField } from 'formik'

import DateField, { IDateFieldProps, IDateChange } from 'components/DateField'

export type IDateInputProps = Omit<IDateFieldProps, 'value' | 'onChange'> & {
  name: string
}

const DateInput: React.FC<IDateInputProps> = ({ name, ...props }) => {
  const [input, { error, touched, initialTouched }, { setValue }] = useField(name)

  const { value, onChange, ...restInput } = input

  const hasError = (touched || initialTouched) && !!error

  const onChangeHandler = useCallback(
    (date: IDateChange) => {
      if (!date) {
        console.error('No Date')
        return
      }
      setValue(date)
    },
    [setValue],
  )

  return (
    <DateField
      error={hasError}
      helperText={hasError ? error : null}
      {...props}
      {...restInput}
      value={value}
      onChange={onChangeHandler}
    />
  )
}

export default DateInput
