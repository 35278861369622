import React, { memo } from 'react'

import { IPriceList } from 'types/types'

import PriceList from '../PriceList'

type IResultProps = {
  priceLists: IPriceList[]
  refetchList: () => void
}

const Result: React.FC<IResultProps> = ({ priceLists, refetchList }) => (
  <>
    {priceLists.map((priceList) => (
      <PriceList key={priceList.id} priceList={priceList} refetchList={refetchList} />
    ))}
  </>
)

export default memo(Result)
