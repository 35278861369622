import React, { memo, useCallback, useMemo } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { FormikHelpers } from 'formik'

import GoBackLink from 'components/GoBackLink'
import PageContainer from 'components/PageContainer'
import ResultTitle from 'components/ResultTitle'
import Text from 'components/Typography'
import CategoryForm, { ICategoryFormValues } from 'components/forms/CategoryForm'
import useCmsParams from 'hooks/useCmsParams'
import useNotifyCms from 'hooks/useNotifyCms'
import { IGetCategory, IGetCategoryParams, buildGetCategoryQuery } from 'graphql/queries/getCategory'
import { ISetCategory, ISetCategoryParams, buildSetCategoryQuery } from 'graphql/mutations/setCategory'
import { Category, ICategoryAttribute } from 'types/types'
import withErrorBoundary from 'hocs/withErrorBoundary'
import withLeaveThisPageModal from 'hocs/withLeaveThisPageModal'
import convertToFormErrors from 'utils/convertToFormErrors'
import extractId from 'utils/extractId'

import Controls from './components/Controls'

const defaultAttribute = {
  attribute_id: '',
  value_ids: [],
}

const defaultValues: ICategoryFormValues = {
  name: '',
  group_id: null,
  number: '9999',
  status: true,
  attributes: [defaultAttribute],
}

const GET_CATEGORY = buildGetCategoryQuery(`
  id
  name
  group_id
  number
  status
  category_attributes {
    attribute { id }
    attribute_values { id }
  }
`)

const SET_CATEGORY = buildSetCategoryQuery(`
  id
  name
`)

const prepareInitialCategoryAttributes = (payload: ICategoryAttribute[]) => {
  if (!payload.length) {
    return [defaultAttribute]
  }

  return payload.map(({ attribute, attribute_values }) => ({
    attribute_id: attribute.id,
    value_ids: attribute_values.map(extractId),
  }))
}

const prepareInitialValues = ({
  name,
  group_id,
  number,
  status = true,
  category_attributes,
}: Category): ICategoryFormValues => ({
  name,
  group_id: group_id || null,
  number: number ? number.toString() : '',
  status,
  attributes: prepareInitialCategoryAttributes(category_attributes),
})

const prepareSubmitValues = ({
  group_id,
  number,
  attributes,
  ...payload
}: ICategoryFormValues): ISetCategoryParams['input'] => ({
  ...payload,
  group_id: group_id as string,
  number: Number(number),
  attributes: attributes.filter(({ attribute_id }) => Boolean(attribute_id)),
})

const CategoryEdit: React.FC = () => {
  const { sellerId, categoryId } = useCmsParams()
  const addNotify = useNotifyCms()

  const {
    data: categoryData,
    loading: categoryLoading,
    refetch,
  } = useQuery<IGetCategory, IGetCategoryParams>(GET_CATEGORY, {
    variables: { id: categoryId },
    fetchPolicy: 'network-only',
  })

  const initialValues = useMemo(() => {
    if (!categoryData) return defaultValues
    return prepareInitialValues(categoryData.getCategory)
  }, [categoryData])

  const [onSetCategory, { loading }] = useMutation<ISetCategory, ISetCategoryParams>(SET_CATEGORY)

  const onSubmit = useCallback(
    (values: ICategoryFormValues, { setErrors }: FormikHelpers<ICategoryFormValues>) =>
      onSetCategory({
        variables: {
          id: categoryId,
          input: prepareSubmitValues(values),
        },
      })
        .then(() => {
          refetch()
          addNotify('success')
        })
        .catch((error) => {
          addNotify('error')
          const errors = convertToFormErrors(error)
          if (errors) setErrors(errors)
        }),
    [onSetCategory, refetch, addNotify, categoryId],
  )

  return (
    <PageContainer>
      <GoBackLink href={`/${sellerId}/catalog/categories`} />
      {!categoryLoading || !!categoryData ? (
        <>
          <ResultTitle>
            {categoryData?.getCategory.name}{' '}
            <Text variant="inherit" color="textSecondary">{`ID ${categoryData?.getCategory.id}`}</Text>
          </ResultTitle>

          <CategoryForm
            submitLoading={loading}
            initialValues={initialValues}
            onSubmit={onSubmit}
            renderControls={() => <Controls />}
          />
        </>
      ) : null}
    </PageContainer>
  )
}

export default withErrorBoundary(withLeaveThisPageModal(memo(CategoryEdit)))
