import { gql } from '@apollo/client'
import { ModeInput } from 'types/types'

export type addGroupRequestType = {
  addGroup: { seller_id: number; input: ModeInput; id: string; name: string }
}

export const ADD_GROUP = gql`
  mutation addGroup($seller_id: ID!, $input: GroupInput) {
    addGroup(seller_id: $seller_id, input: $input) {
      id
      seller_id
      name
      description
      number
      created_at
      updated_at
    }
  }
`
