import React, { memo, useCallback, useMemo, useState } from 'react'
import { useMutation } from '@apollo/client'
import { FormikHelpers } from 'formik'
import moment from 'moment'

import { useGetClientQuery } from 'graphql/queries/getClient'
import { ISetClient, ISetClientParams } from 'graphql/mutations/setClient'

import Preloader from 'components/Preloader'
import GoBackLink from 'components/GoBackLink'
import ResultTitle from 'components/ResultTitle'
import PageContainer from 'components/PageContainer'
import Text from 'components/Typography'
import useCmsParams from 'hooks/useCmsParams'
import useNotifyCms from 'hooks/useNotifyCms'
import convertToFormErrors from 'utils/convertToFormErrors'
import withErrorBoundary from 'hocs/withErrorBoundary'
import withLeaveThisPageModal from 'hocs/withLeaveThisPageModal'
import ClientForm, { IClientFormValues } from 'components/forms/ClientForm'
import ApiError from 'components/ApiError'

import Controls from './components/Controls'
import Orders from './components/Orders'
import PriceRules from './components/PriceRules'
import Contacts from './components/Contacts'
import DeliveryAddresses from './components/DeliveryAddresses'
import { GET_CLIENT, SET_CLIENT } from './queries'
import prepareInitialValues from './utils/prepareInitialValues'
import prepareSubmitValues from './utils/prepareSubmitValues'
import CompaniesInput from '../ClientCreate/components/CompaniesInput'

import styles from './clientEdit.module.scss'

const defaultValues: IClientFormValues = {
  status: true,
  phone: '',
  email: '',
  name: '',
  managerId: null,
  companies: [],
}

const ClientEdit: React.FC = () => {
  const { sellerId, clientId } = useCmsParams()
  const addNotify = useNotifyCms()

  const [initialFormValues, setInitialFormValues] = useState<IClientFormValues>(defaultValues)

  const { data, loading, error } = useGetClientQuery(GET_CLIENT, {
    variables: { id: clientId },
    fetchPolicy: 'network-only',
    onCompleted: (res) => setInitialFormValues(prepareInitialValues(res.getClient)),
  })

  const client = useMemo(() => data?.getClient, [data])

  const deliveryAddresses = useMemo(() => {
    const addresses = client?.delivery_addresses || []
    if (addresses.length === 0) return null
    return addresses
  }, [client])

  const [setClientInformation, { loading: setClientLoading }] = useMutation<ISetClient, ISetClientParams>(
    SET_CLIENT,
  )

  const onSubmit = useCallback(
    (values: IClientFormValues, { setErrors, resetForm }: FormikHelpers<IClientFormValues>) =>
      setClientInformation({
        variables: {
          id: clientId,
          input: prepareSubmitValues(initialFormValues, values),
        },
      })
        .then((response) => {
          const nextData = response.data?.setClient
          if (!nextData) throw new Error('No setClient data')

          const initValues = prepareInitialValues(nextData)
          resetForm({ values: initValues })
          setInitialFormValues(initValues)
          addNotify('success')
        })
        .catch((resError) => {
          addNotify('error')
          const errors = convertToFormErrors(resError)
          if (errors) setErrors(errors)
        }),
    [setClientInformation, addNotify, clientId, initialFormValues],
  )

  if (loading) return <Preloader />

  if (error) {
    return (
      <PageContainer>
        <ApiError error={error} />
      </PageContainer>
    )
  }

  if (!client) return null

  return (
    <PageContainer>
      <GoBackLink href={`/${sellerId}/clients/list`} />

      <ResultTitle>{client.name}</ResultTitle>

      <ClientForm
        initialValue={initialFormValues}
        submitLoading={setClientLoading}
        onSubmit={onSubmit}
        deliveryAddresses={
          deliveryAddresses ? <DeliveryAddresses deliveryAddresses={deliveryAddresses} /> : null
        }
        createdAt={
          <>
            <Text className={styles.registrationDateTitle} color="textSecondary">
              Дата регистрации
            </Text>
            <Text>{moment(client.created_at).format('DD.MM.YYYY')}</Text>
          </>
        }
        renderControls={() => <Controls />}
      >
        <CompaniesInput />
        <Orders orders={client.orders} />
        <PriceRules price_rules={client.price_rules} />
        <Contacts contacts={client.contacts} />
      </ClientForm>
    </PageContainer>
  )
}

export default withErrorBoundary(withLeaveThisPageModal(memo(ClientEdit)))
