import { Paginate } from 'types/types'

export const DEFAULT_PAGES_LIMIT = 15

export const SEARCH_INPUT_LIMIT = 100
export const VENDOR_CODE_INPUT_LIMIT = 30
export const POSITION_INPUT_LIMIT = 4

export const SEARCH_DEBOUNCE_TIMEOUT = 600
export const DIALOG_OPEN_TIMEOUT = 2000

export const DEFAULT_DATE_FORMAT = 'yyyy-MM-DD HH:mm'

export const maxPaginate: Paginate = {
  limit: 10000,
  page: 1,
}
