import { DocumentNode, MutationHookOptions, gql, useMutation } from '@apollo/client'

import { IApiAccess, IApiAccessInput } from 'types/types'

export type ISetApiAccess = {
  setApiAccess: IApiAccess
}

export type ISetApiAccessParams = {
  id: IPrimaryKey
  input?: IApiAccessInput
}

export const buildSetApiAccessQuery = (fields: string): DocumentNode => gql`
  mutation setApiAccess($id: ID!, $input: ApiAccessInput) {
    setApiAccess(id: $id, input: $input) {
      ${fields}
    }
  }
`

export const useSetApiAccessMutation = <TData = ISetApiAccess, TOptions = ISetApiAccessParams>(
  fields: DocumentNode,
  options?: MutationHookOptions<TData, TOptions>,
) => useMutation<TData, TOptions>(fields, options)
