import { gql, DocumentNode } from '@apollo/client'

import type { Category } from 'types/types'

export type IGetCategory = {
  getCategory: Category
}

export type IGetCategoryParams = {
  id: IPrimaryKey
}

export const buildGetCategoryQuery = (fields: string): DocumentNode => gql`
  query getCategory($id: ID!) {
    getCategory(id: $id) {
      ${fields}
    }
  }
`
