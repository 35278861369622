import React from 'react'
import clsx from 'clsx'

import IconButton from 'components/IconButton'
import CrossSmallIcon from 'components/Icons/CrossSmallIcon'

import styles from './dialogCloseButton.module.scss'

type IDialogCloseButtonProps = {
  className?: string
  onClick: () => void
}

const DialogCloseButton: React.FC<IDialogCloseButtonProps> = ({ className, onClick }) => (
  <IconButton
    className={clsx(styles.button, className)}
    aria-label="Закрыть диалоговое окно"
    onClick={onClick}
  >
    <CrossSmallIcon />
  </IconButton>
)

export default DialogCloseButton
