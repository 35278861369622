import React from 'react'

import { H1 } from 'components/Typography'
import Button from 'components/Button'
import Link from 'components/Link'
import image from 'assets/img/Admin505Error.svg'

import style from './serverError.module.scss'

const ServerError: React.FC = () => (
  <div className={style.ServerError}>
    <div className={style.ServerError__WrapImage}>
      <img src={image} alt="500" />
    </div>
    <div className={style.ServerError__WrapDesc}>
      <H1 className={style.ServerError__WrapDesc_Title}>Кажется, что-то пошло не так</H1>
      <p className={style.ServerError__WrapDesc_Text}>Мы уже работаем над исправлением.</p>
    </div>
    <Link href="/">
      <Button>Обновить страницу</Button>
    </Link>
  </div>
)

export default ServerError
