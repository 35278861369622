import React, { useMemo, useCallback } from 'react'
import { Formik } from 'formik'

import Text, { H2 } from 'components/Typography'
import Button from 'components/Button'
import Dialog from 'components/Dialog'
import DialogContent from 'components/DialogContent'
import DialogCloseButton from 'components/DialogCloseButton'
import TextInput from 'components/Inputs/TextInput'
import numberFormat from 'utils/formInputs/numberFormat'
import { IStores } from 'types/types'

import styles from './productStoreAmountDialog.module.scss'

interface IProductStoreAmountDialogProps {
  isOpen: boolean
  initialStores: IStores[]
  onClose: () => void
  onSubmit: (payload: IStores[]) => void
}

const ProductStoreAmountDialog: React.FC<IProductStoreAmountDialogProps> = ({
  isOpen,
  initialStores,
  onClose,
  onSubmit,
}) => {
  const initialValues = useMemo(
    () => ({
      stores: initialStores,
    }),
    [initialStores],
  )

  const onSubmitHandler = useCallback(
    (payload: { stores: IStores[] }) => onSubmit(payload.stores),
    [onSubmit],
  )

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <DialogCloseButton onClick={onClose} />

      <DialogContent className={styles.content}>
        <H2 className={styles.title}>Количество и хранение</H2>

        <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmitHandler}>
          {({ values, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              {values.stores.map((store, index) => (
                <div className={styles.storeRow} key={index}>
                  <Text className={styles.label} bold>
                    {store.name}
                  </Text>
                  <TextInput
                    name={`stores[${index}].amount`}
                    label="Остаток на складе"
                    placeholder="Введите количество"
                    transform={numberFormat}
                  />
                  <TextInput
                    name={`stores[${index}].amount_wait`}
                    label="Ожидается поступление"
                    placeholder="Введите количество"
                    transform={numberFormat}
                  />
                  <TextInput
                    name={`stores[${index}].multiply`}
                    label="Кратность"
                    placeholder="Введите значение"
                    transform={numberFormat}
                  />
                </div>
              ))}

              <div className={styles.controlsGroup}>
                <Button type="submit">Сохранить</Button>
                <Button variant="outlined" onClick={onClose}>
                  Отменить
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default ProductStoreAmountDialog
