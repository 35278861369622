import { DocumentNode, gql } from '@apollo/client'

import { IImportSchedule, IImportScheduleInput } from 'types/types'

export type ISetImportSchedulesParams = {
  seller_id: IPrimaryKey
  imports: IImportScheduleInput[]
}

export type ISetImportSchedules = {
  setImportSchedules: IImportSchedule[]
}

export const SET_IMPORT_SCHEDULES = gql`
  mutation setImportSchedules($seller_id: ID!, $imports: [ImportScheduleInput]) {
    setImportSchedules(seller_id: $seller_id, imports: $imports) {
      id
      seller_id
      mode
      ext_url
      auto_update
      period_update
      percent_update
      status_update
      last_update
      created_at
      updated_at
    }
  }
`

export const buildSetImportSchedulesQuery = (fields: string): DocumentNode => gql`
  mutation setImportSchedules($seller_id: ID!, $imports: [ImportScheduleInput]) {
    setImportSchedules(seller_id: $seller_id, imports: $imports) {
      ${fields}
    }
  }
`
