import { DocumentNode, gql } from '@apollo/client'

import { Seller } from 'types/types'

export type ISetSellerStatus = {
  setSellerStatus: Seller
}

export type ISetSellerStatusParams = {
  id: IPrimaryKey
  status: boolean
}

export const buildSetSellerStatusQuery = (fields: string): DocumentNode => gql`
  mutation setSellerStatus($id: ID!, $status: Boolean!) {
    setSellerStatus(id: $id, status: $status) {
      ${fields}
    }
  }
`
