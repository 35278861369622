import React, { useEffect, useRef } from 'react'

interface IHiddenDownloadProps {
  downloadUrl?: string
  clearUrl: () => void
}

const style = { display: 'none' }

const HiddenDownload: React.FC<IHiddenDownloadProps> = ({ downloadUrl, clearUrl }) => {
  const downloadLinkRef = useRef<HTMLAnchorElement>(null)

  useEffect(() => {
    if (!downloadUrl) return

    downloadLinkRef.current?.click()

    const timer = setTimeout(() => clearUrl(), 1000)
    return () => clearTimeout(timer)
  }, [downloadUrl])

  return <a style={style} ref={downloadLinkRef} href={downloadUrl} download />
}

export default HiddenDownload
