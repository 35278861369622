import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import Link from 'components/Link'
import useCmsParams from 'hooks/useCmsParams'
import TrashIcon from 'components/Icons/TrashIcon'
import DeleteManager from 'components/ModelControls/DeleteManager'

const Controls: React.FC = () => {
  const { sellerId, managerId } = useCmsParams()
  const { push } = useHistory()

  const onDeleteSuccess = useCallback(() => {
    push(`/${sellerId}/managers/`)
  }, [push, sellerId])

  return (
    <DeleteManager
      managerId={managerId}
      onSuccess={onDeleteSuccess}
      render={({ onClick }) => (
        <Link component="button" startIcon={<TrashIcon />} onClick={onClick}>
          Удалить контакт
        </Link>
      )}
    />
  )
}

export default Controls
