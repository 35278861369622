import React, { ChangeEvent, Fragment, SyntheticEvent, memo, useCallback, useMemo } from 'react'

import block from 'utils/block'
import createUniqId from 'utils/createUniqId'
import Link from 'components/Link'
import UploadIcon from 'components/Icons/UploadIcon'

import styles from './multiDragAndDropField.module.scss'

type IValueItem = { id?: IPrimaryKey; path?: string; flag?: string } | File

export type IMultiDragAndDropFieldProps = {
  className?: string
  filesFormat?: string
  values: IValueItem[]
  onChange: (files: File[]) => void
  onDelete: (removeIndex: number) => void
}

const b = block(styles)

const preventsDefaults = (e: SyntheticEvent) => {
  e.preventDefault()
  e.stopPropagation()
}

type IImageProps = {
  className?: string
  image: IValueItem
  onDeleteClick: () => void
}

const Image: React.FC<IImageProps> = ({ className, image, onDeleteClick }) => {
  const src = image instanceof File ? URL.createObjectURL(image) : image.path

  return (
    <div className={className}>
      <img className={styles.image} src={src} alt="" />
      <Link component="button" className={styles.imageButton} onClick={onDeleteClick}>
        Удалить
      </Link>
    </div>
  )
}

const MultiDragAndDropField: React.FC<IMultiDragAndDropFieldProps> = ({
  className,
  filesFormat = '*',
  values = [],
  onChange,
  onDelete,
}) => {
  const hasFiles = !!values.length

  const inputId = useMemo(() => createUniqId(), [])

  const handleFiles = useCallback(
    (files: FileList) => {
      const filesArray = Object.values(files)
      onChange(filesArray)
    },
    [onChange],
  )

  const onChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault()
      if (!event.currentTarget.files) return
      handleFiles(event.currentTarget.files)
      // @ts-ignore no-param-reassign
      if (event.target.value) event.target.value = ''
    },
    [handleFiles],
  )

  const onDropHandler = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      handleFiles(event.dataTransfer.files)
    },
    [handleFiles],
  )

  const onDragLeaveHandler = useCallback((event: SyntheticEvent) => {
    preventsDefaults(event)
  }, [])

  return (
    <div
      className={b('container', className)}
      onDragOver={preventsDefaults}
      onDragEnter={preventsDefaults}
      onDrop={onDropHandler}
      onDragLeave={onDragLeaveHandler}
    >
      {values.map((image, index) => {
        if (image instanceof File) {
          return <Image key={index} image={image} onDeleteClick={() => onDelete(index)} />
        }

        if (image.flag === 'delete') {
          return <Fragment key={index} />
        }

        return <Image key={index} image={image} onDeleteClick={() => onDelete(index)} />
      })}

      <div className={b('defaultContent', { mini: hasFiles })}>
        <UploadIcon className={styles.loadIcon} fontSize="large" />
        <div>
          <label htmlFor={inputId} className={styles.textAccent}>
            <input
              id={inputId}
              type="file"
              name="file"
              multiple
              accept={filesFormat}
              className={styles.hiddenInput}
              onChange={onChangeHandler}
            />
            {hasFiles ? 'Загрузить еще' : 'Выберите файл'}
          </label>{' '}
          {hasFiles ? null : <span>или перетащите его в эту область</span>}
        </div>
      </div>
    </div>
  )
}

export default memo(MultiDragAndDropField)
