import { gql } from '@apollo/client'

import { Status } from 'types/types'

export type ISendContactVerify = {
  sendContactVerify: Status
}

export type ISendContactVerifyParams = {
  id: IPrimaryKey
}

export const SEND_CONTACT_VERIFY = gql`
  mutation sendContactVerify($id: ID!) {
    sendContactVerify(id: $id) {
      message
      status
    }
  }
`
