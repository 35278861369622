import { gql } from '@apollo/client'

import { Status } from 'types/types'

export type IDeleteBaseProduct = {
  delBaseProduct: Status
}

export type IDeleteBaseProductParams = {
  id: IPrimaryKey
}

export const DELETE_BASE_PRODUCT = gql`
  mutation delBaseProduct($id: ID!) {
    delBaseProduct(id: $id) {
      status
      message
    }
  }
`
