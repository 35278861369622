import React from 'react'

const PaymentMethodIcon: React.FC = () => (
  <svg width="47" height="34" viewBox="0 0 47 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M44.8806 28.1012C44.8806 30.1605 43.2111 31.83 41.1519 31.83H5.72875C3.66955 31.83 2 30.1605 2 28.1012V5.72875C2 3.66955 3.66955 2 5.72875 2H41.1519C43.2111 2 44.8806 3.66955 44.8806 5.72875V28.1012Z"
      stroke="#5D96BA"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 9.45703H44.8806"
      stroke="#5D96BA"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.2865 16.918H33.6934"
      stroke="#234D77"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.5087 16.918H7.59375"
      stroke="#234D77"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.1869 22.5078H7.59375"
      stroke="#234D77"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default PaymentMethodIcon
