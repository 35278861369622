import React, { useCallback } from 'react'
import { useField } from 'formik'

import Button from 'components/Button'

import styles from './addEmptyAttribute.module.scss'

type IAddEmptyAttributeProps = {
  name: string
}

const emptyAttribute = {
  attribute_id: '',
  value_ids: [],
}

const AddEmptyAttribute: React.FC<IAddEmptyAttributeProps> = ({ name }) => {
  const [{ value, onChange }] = useField<any[]>(name)

  const onAdd = useCallback(() => {
    const nextEvent = {
      target: {
        name,
        value: value.concat(emptyAttribute),
      },
    }

    onChange(nextEvent)
  }, [onChange, value, name])

  return (
    <Button variant="outlined" className={styles.addButton} onClick={onAdd}>
      Добавить еще
    </Button>
  )
}

export default AddEmptyAttribute
