import React, { ReactNode } from 'react'

import styles from './button.module.scss'

interface IButtonProps extends ButtonElement {
  icon: ReactNode
}

const Button: React.FC<IButtonProps> = ({ icon, children, ...props }) => (
  <button type="button" className={styles.button} {...props}>
    <span className={styles.iconWrapper}>{icon}</span>
    {children}
  </button>
)

export default Button
