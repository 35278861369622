import React, { useCallback, useEffect, useState } from 'react'
import { useField, useFormikContext } from 'formik'

import DropMenu from 'components/DropMenu'
import Button from 'components/Button'
import Tabs from 'components/Tabs'
import Tab from 'components/Tab'
import TabPanel from 'components/TabPanel'
import Link from 'components/Link'
import Text from 'components/Typography'
import TrashIcon from 'components/Icons/TrashIcon'
import TextInput from 'components/Inputs/TextInput'
import numberFormat from 'utils/formInputs/numberFormat'
import formikFieldEvent from 'utils/formInputs/formikFieldEvent'
import renderCurrencySymbol from 'utils/renderCurrencySymbol'
import useCurrentSeller from 'hooks/useCurrentSeller'

import { ICompanyFormValues } from '../../types'

import styles from './ContractsInput.module.scss'

type IContractsInputProps = {
  name: string
}

const ContractsInput: React.FC<IContractsInputProps> = ({ name }) => {
  const { settings } = useCurrentSeller()

  const [{ value: contracts, onChange }] = useField<ICompanyFormValues['contracts']>(name)
  const { isSubmitting } = useFormikContext<ICompanyFormValues>()

  const [tabIndex, setTabIndex] = useState(0)

  const onDeletedCompany = useCallback(() => {
    setTabIndex(0)
  }, [])

  const onAddContract = useCallback(() => {
    const nextValues = contracts.concat([{ name: '', limit: '', debt: '' }])
    onChange(formikFieldEvent(name, nextValues))
    setTabIndex(contracts.length)
  }, [contracts, name, onChange])

  const onCompanyDelete = useCallback(
    (removeIndex: number) => {
      const nextValues = contracts.filter((_, index) => removeIndex !== index)
      onChange(formikFieldEvent(name, nextValues))
      onDeletedCompany()
    },
    [onDeletedCompany, contracts, name, onChange],
  )

  useEffect(() => {
    if (!isSubmitting) return
    setTabIndex(0)
  }, [isSubmitting])

  return (
    <DropMenu title="Договора">
      <Button variant="outlined" onClick={onAddContract}>
        Добавить договор
      </Button>

      {contracts.length ? (
        <Tabs
          className={styles.tabsGroup}
          variant="scrollable"
          value={tabIndex}
          onChange={(_, index) => setTabIndex(index)}
        >
          {contracts.map((contract, index) => (
            <Tab key={index} value={index} label={contract.name || 'Название договора'} />
          ))}
        </Tabs>
      ) : null}

      {contracts.map((_, index) => (
        <TabPanel key={index} className={styles.panel} value={index} index={tabIndex}>
          <TextInput
            name={`${name}[${index}].name`}
            label="Название"
            placeholder="Введите название договора"
            margin="default"
          />
          <TextInput
            name={`${name}[${index}].limit`}
            label="Кредитный лимит"
            placeholder="Введите кредитный лимит в рублях"
            transform={numberFormat}
            margin="default"
            endAdornment={<Text color="textSecondary">{renderCurrencySymbol(settings?.currency)}</Text>}
          />
          <TextInput
            name={`${name}[${index}].debt`}
            label="Задолженность"
            placeholder="Введите задолженность по договору в рублях"
            transform={numberFormat}
            endAdornment={<Text color="textSecondary">{renderCurrencySymbol(settings?.currency)}</Text>}
          />
          <Link
            className={styles.deleteButton}
            component="button"
            onClick={() => onCompanyDelete(index)}
            startIcon={<TrashIcon />}
          >
            Удалить договор
          </Link>
        </TabPanel>
      ))}
    </DropMenu>
  )
}

export default ContractsInput
