import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'

import Select, { ISelectProps } from 'components/Select'
import StarIcon from 'components/Icons/StarIcon'

export type ISelectFieldProps = ISelectProps & {
  required?: boolean
  error?: boolean
  label?: string
  helperText?: string | null
}

const labelStyle = { display: 'flex', justifyContent: 'flex-start' }

const SelectField: React.FC<ISelectFieldProps> = ({
  required = false,
  error = false,
  fullWidth = true,
  className,
  helperText,
  label,
  ...props
}) => (
  <FormControl error={error} className={className} fullWidth={fullWidth}>
    {label ? (
      <InputLabel style={labelStyle}>
        {label}
        {required ? <StarIcon /> : null}
      </InputLabel>
    ) : null}
    <Select error={error} {...props} />
    {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
  </FormControl>
)

export default SelectField
