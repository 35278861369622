import React, { useCallback } from 'react'
import { useField } from 'formik'

import IconButton from 'components/IconButton'
import TrashIcon from 'components/Icons/TrashIcon'

import { ICategoryFormValues } from '../../types'

import styles from './removeAttributeButton.module.scss'

type IRemoveAttributeButtonProps = {
  name: string
  itemIndex: number
}

const RemoveAttributeButton: React.FC<IRemoveAttributeButtonProps> = ({ name, itemIndex }) => {
  const [{ value, onChange }] = useField<ICategoryFormValues['attributes']>(name)

  const onRemove = useCallback(() => {
    const nextAttributes = value.filter((_, index) => index !== itemIndex)
    const nextEvent = {
      target: {
        name,
        value: nextAttributes,
      },
    }

    onChange(nextEvent)
  }, [onChange, name, itemIndex, value])

  if (itemIndex === 0) {
    return <div className={styles.button} />
  }

  return (
    <IconButton className={styles.button} onClick={onRemove}>
      <TrashIcon color="action" />
    </IconButton>
  )
}

export default RemoveAttributeButton
