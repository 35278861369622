import React, { memo, useCallback, useState } from 'react'
import { useMutation } from '@apollo/client'

import {
  ISetImportSchedules,
  ISetImportSchedulesParams,
  buildSetImportSchedulesQuery,
} from 'graphql/mutations/setImportSchedules'
import {
  DELETE_IMPORT_SCHEDULE,
  IDeleteImportSchedule,
  IDeleteImportScheduleParams,
} from 'graphql/mutations/deleteImportSchedule'
import Button from 'components/Button'
import ApiError from 'components/ApiError'
import DropMenu from 'components/DropMenu'
import Preloader from 'components/Preloader'
import TextField from 'components/TextField'
import RadioGroup from 'components/RadioGroup'
import RadioLabel from 'components/RadioLabel'
import Link from 'components/Link'
import Text from 'components/Typography'
import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import Switch from 'components/Switch'
import SelectField from 'components/SelectField'
import { updateImportTimeOptions, IUpdateImportTime, defaultImportSchemeOption } from 'consts/options'
import useBoolean from 'hooks/useBoolean'
import TrashIcon from 'components/Icons/TrashIcon'
import { IImportSchedule } from 'types/types'
import useCmsParams from 'hooks/useCmsParams'
import dateTz from 'utils/dateTz'
import { DEFAULT_DATE_FORMAT } from 'consts'
import useNotifyCms from 'hooks/useNotifyCms'
import withErrorBoundary from 'hocs/withErrorBoundary'

import styles from './importLink.module.scss'

type IImportLinkProps = {
  schedule: IImportSchedule
  importSchemesOptions: IOption[]
}

const SET_IMPORT_SCHEDULES = buildSetImportSchedulesQuery(`
  id
`)

const ImportLink: React.FC<IImportLinkProps> = ({ schedule, importSchemesOptions }) => {
  const {
    id: scheduleId,
    auto_update = false,
    ext_url = '',
    period_update = 3,
    import_products_scheme_id,
    mode = 0,
    last_update,
  } = schedule

  const { sellerId } = useCmsParams()
  const addNotify = useNotifyCms()

  const [isActive, setIsActive] = useBoolean(auto_update)
  const [isConfirmDialogOpen, setConfirmDialog] = useBoolean()
  const [linkUrl, setLinkUrl] = useState<string>(ext_url)
  const [updateTime, setUpdateTime] = useState<IUpdateImportTime>(
    period_update.toString() as IUpdateImportTime,
  )
  const [updateFrom, setUpdateFrom] = useState<string>(mode.toString())
  const [importSchemeId, setImportSchemeId] = useState<string>(
    import_products_scheme_id ?? defaultImportSchemeOption.id,
  )

  const onUpdateTimeChange = useCallback((payload: string) => {
    setUpdateTime(payload as IUpdateImportTime)
  }, [])

  const onLinkChange = useCallback((event: InputChangeEvent) => setLinkUrl(event.target.value), [])

  const onUpdateFromChange = useCallback((_: any, newValue: string) => {
    setUpdateFrom(newValue)
  }, [])

  const [onSetImportSchedules, { loading: saving, error: saveError }] = useMutation<
    ISetImportSchedules,
    ISetImportSchedulesParams
  >(SET_IMPORT_SCHEDULES, {
    onCompleted: () => addNotify('success'),
    onError: () => addNotify('error'),
  })

  const [onDeleteImportSchedule, { loading: deleting, error: deleteError }] = useMutation<
    IDeleteImportSchedule,
    IDeleteImportScheduleParams
  >(DELETE_IMPORT_SCHEDULE, {
    variables: {
      id: scheduleId,
    },
    onCompleted: () => addNotify('success'),
    onError: () => addNotify('error'),
    update(cache) {
      cache.modify({
        fields: {
          getImportSchedules(currentSchedules: IImportSchedule[]) {
            return currentSchedules.filter((record) => record.id !== scheduleId)
          },
        },
      })
    },
  })

  const onSave = useCallback(() => {
    const ensureCorrectSchemeId = importSchemeId === defaultImportSchemeOption.id ? null : importSchemeId

    onSetImportSchedules({
      variables: {
        seller_id: sellerId,
        imports: [
          {
            id: scheduleId,
            auto_update: isActive,
            ext_url: linkUrl,
            period_update: Number(updateTime),
            mode: Number(updateFrom),
            import_products_scheme_id: ensureCorrectSchemeId,
          },
        ],
      },
    })
  }, [sellerId, onSetImportSchedules, scheduleId, isActive, updateTime, updateFrom, linkUrl, importSchemeId])

  const onDelete = useCallback(() => {
    setConfirmDialog.off()

    onDeleteImportSchedule({
      variables: { id: scheduleId },
    })
  }, [setConfirmDialog, onDeleteImportSchedule, scheduleId])

  return (
    <DropMenu
      title={ext_url}
      titleChildren={
        <div
          className={styles.activeSwitchWrapper}
          role="presentation"
          onClick={(event) => event.stopPropagation()}
        >
          <Switch isChecked={isActive} onChange={setIsActive.toggle} />
        </div>
      }
    >
      {(saving || deleting) && <Preloader />}

      <TextField className={styles.linkInput} label="URL файла" value={linkUrl} onChange={onLinkChange} />

      <div className={styles.timeSelectWrapper}>
        <SelectField
          className={styles.updateTime}
          label="Частота обновления информации"
          value={updateTime}
          options={updateImportTimeOptions}
          onChange={onUpdateTimeChange}
        />
        {!!last_update && (
          <Text color="textSecondary" className={styles.lastUpdate}>
            {`Последнее обновление - ${dateTz(last_update).format(DEFAULT_DATE_FORMAT)}`}
          </Text>
        )}
      </div>

      {importSchemesOptions.length > 1 ? (
        <SelectField
          className={styles.importSchemeSelect}
          label="Конфигурация полей импорта"
          value={importSchemeId}
          options={importSchemesOptions}
          onChange={setImportSchemeId}
        />
      ) : null}

      <RadioGroup
        className={styles.parametersGroup}
        title="Параметры обновления"
        value={updateFrom}
        onChange={onUpdateFromChange}
      >
        <RadioLabel value="0" label="Обновлять все" />
        <RadioLabel value="1" label="Обновлять остатки и цены, создавать новые товары" />
        <RadioLabel value="2" label="Обновлять только остатки и цены" />
      </RadioGroup>

      <ApiError error={saveError} />
      <ApiError error={deleteError} />

      <div className={styles.constolsGroup}>
        <Button onClick={onSave}>Сохранить</Button>
        <Link component="button" startIcon={<TrashIcon />} onClick={setConfirmDialog.on}>
          Удалить ссылку
        </Link>
      </div>

      <ConfirmDialog
        isOpen={isConfirmDialogOpen}
        onClose={setConfirmDialog.off}
        onSubmit={onDelete}
        title="Вы уверены что ходите удалить?"
        submitBtnText="Да, удалить"
      />
    </DropMenu>
  )
}

export default withErrorBoundary(memo(ImportLink))
