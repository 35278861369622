import { gql } from '@apollo/client'

export type VerifyEmailResponseType = {
  verifyEmail: {
    message: string
    status: string
  }
}

export const VERIFY_EMAIL = gql`
  mutation verifyEmail($token: String!) {
    verifyEmail(token: $token) {
      message
      status
    }
  }
`
