import { mergeDeep } from '@apollo/client/utilities'

import { ICompanyFormValues } from 'components/forms/CompanyForm/types'

import { ICompany } from 'types/types'

const prepareContracts = (contracts: ICompany['contracts']): ICompanyFormValues['contracts'] => {
  if (!contracts?.length) return []

  return contracts.map((contract) => mergeDeep({ name: '', limit: '', debt: '' }, contract))
}

const prepareInitialValues = ({ name, inn, contracts }: ICompany): ICompanyFormValues => ({
  name,
  inn,
  contracts: prepareContracts(contracts),
})

export default prepareInitialValues
