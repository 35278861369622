import { gql } from '@apollo/client'

import { Status } from 'types/types'

export type ICopyPriceRuleParams = {
  id: IPrimaryKey
}

export type ICopyPriceRule = {
  copyPriceRule: Status
}

export const COPY_PRICE_RULE = gql`
  mutation copyPriceRule($id: ID!) {
    copyPriceRule(id: $id) {
      status
      message
    }
  }
`
