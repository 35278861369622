import React from 'react'
import { Link, useHistory } from 'react-router-dom'

import { clickOnControlPanelEvent, clickOnOpenSiteEvent } from 'utils/gtmHelper'
import NoImg from 'assets/img/no-img.svg'
import Button from 'components/Button'
import useLinkPublic from 'hooks/useLinkPublic'
import { Seller } from 'types/types'

import styles from './MySitesList.module.scss'

type IMySitesListProps = {
  seller: Seller
}

const MySitesList: React.FC<IMySitesListProps> = ({ seller }) => {
  const { status, name, id, logo, domain = '', company = '', industry = '' } = seller
  const { link } = useLinkPublic(seller)
  const { push } = useHistory()

  return (
    <div className={styles.MySitesList}>
      <div className={styles.MySitesList__logoBlock}>
        <img src={logo || NoImg} alt="" className={styles.MySitesList__logo} />
      </div>
      <div className={styles.MySitesList__controlPanel}>
        <span className={styles.MySitesList__controlPanel__label}>{name}</span>
        <div className={styles.MySitesList__buttonBlock}>
          <Link
            to={`/${id}/catalog/products`}
            className={styles.MySitesList__link}
            onClick={() => clickOnControlPanelEvent(name, company, industry || '')}
          >
            <Button>Панель управления</Button>
          </Link>
          {status ? (
            <a
              href={`http://${link}`}
              target="_blank"
              rel="noreferrer"
              className={styles.MySitesList__linkWrapper}
            >
              <Button
                variant="outlined"
                className={styles.MySitesList__button}
                onClick={() => clickOnOpenSiteEvent(name, domain, company, industry || '')}
              >
                Открыть сайт
              </Button>
            </a>
          ) : (
            <Button
              variant="outlined"
              className={styles.MySitesList__button}
              onClick={() => {
                clickOnOpenSiteEvent(name, domain, company, industry || '')
                push(`/${id}/options/common`)
              }}
            >
              Открыть сайт
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default MySitesList
