import { DocumentNode, QueryHookOptions, gql, useQuery } from '@apollo/client'

import { Seller } from 'types/types'

export type IGetSeller = {
  getSeller: Seller
}

export type IGetSellerParams = {
  id: IPrimaryKey
}

export const buildGetSellerQuery = (fields: string): DocumentNode => gql`
  query getSeller($id: ID!) {
    getSeller(id: $id) {
      ${fields}
    }
  }
`

export const useGetSellerQuery = <TData = IGetSeller, TOptions = IGetSellerParams>(
  fields: DocumentNode,
  options?: QueryHookOptions<TData, TOptions>,
) => useQuery<TData, TOptions>(fields, options)
