import React from 'react'
import MuiButton, { ButtonProps as MuiButtonProps } from '@material-ui/core/Button'
import clsx from 'clsx'

export type IButtonProps = MuiButtonProps & {
  hasHover?: boolean
  // component?: React.ElementType;
}

const Button: React.FC<IButtonProps> = ({ className, hasHover, ...props }) => (
  <MuiButton
    className={clsx({ withIcon: !!props.startIcon || !!props.endIcon, withHover: hasHover }, className)}
    {...props}
  />
)

export default Button
