import React, { ReactNode, memo, useMemo } from 'react'
import { Formik, FormikHelpers } from 'formik'
import { useQuery } from '@apollo/client'

import FormControlsPanel from 'components/FormControlsPanel'
import LeavePageDirtyFormHelper from 'components/LeavePageDirtyFormHelper'
import AddSubmitShortcutFormHelper from 'components/AddSubmitShortcutFormHelper'
import DropMenu from 'components/DropMenu'
import Button from 'components/Button'
import Text from 'components/Typography'
import TextInput from 'components/Inputs/TextInput'
import SelectInput from 'components/Inputs/SelectInput'
import SwitchInput from 'components/Inputs/SwitchInput'
import MultiSelectInput from 'components/Inputs/MultiSelectInput'
import Preloader from 'components/Preloader'
import { IGetGroups, IGetGroupsParams, buildGetGroupsQuery } from 'graphql/queries/getGroups'
import useCmsParams from 'hooks/useCmsParams'
import { IGetAttributes, IGetAttributesParams, buildGetAttributesQuery } from 'graphql/queries/getAttributes'
import { maxPaginate } from 'consts'
import convertAttributesToHash from 'utils/convertAttributesToHash'
import numberFormat from 'utils/formInputs/numberFormat'

import AddEmptyAttribute from './components/AddEmptyAttribute'
import RemoveAttributeButton from './components/RemoveAttributeButton'
import AttributeIdListener from './components/AttributeIdListener'

import { ICategoryFormValues } from './types'
import validationSchema from './utils/validationSchema'

import styles from './categoryForm.module.scss'

type ICategoryFormProps = {
  submitLoading: boolean
  initialValues: ICategoryFormValues
  onSubmit: (values: ICategoryFormValues, { setErrors }: FormikHelpers<ICategoryFormValues>) => void
  renderControls?: () => ReactNode
}

const GET_GROUPS = buildGetGroupsQuery(`
  id
  name
`)

const GET_ATTRIBUTES = buildGetAttributesQuery(`
  id
  name
  attribute_values {
    id
    attribute_id
    value
  }
`)

const Row: React.FC = ({ children }) => <div className={styles.row}>{children}</div>

const CategoryForm: React.FC<ICategoryFormProps> = ({
  submitLoading,
  initialValues,
  onSubmit,
  renderControls = () => {},
}) => {
  const { sellerId } = useCmsParams()

  const { data: groupsData, loading: groupsLoading } = useQuery<IGetGroups, IGetGroupsParams>(GET_GROUPS, {
    variables: { seller_id: sellerId, paginate: maxPaginate },
    fetchPolicy: 'network-only',
  })

  const groupsList = useMemo(() => groupsData?.getGroups.list || [], [groupsData])

  const { data: attributesData, loading: attributesLoading } = useQuery<IGetAttributes, IGetAttributesParams>(
    GET_ATTRIBUTES,
    {
      variables: {
        seller_id: sellerId,
        paginate: maxPaginate,
      },
      fetchPolicy: 'network-only',
    },
  )

  const attributesList = useMemo(() => attributesData?.getAttributes.list || [], [attributesData])

  const attributesHash = useMemo(() => convertAttributesToHash(attributesList), [attributesList])

  if (groupsLoading || attributesLoading) return <Preloader />

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <LeavePageDirtyFormHelper />
          <AddSubmitShortcutFormHelper />
          <DropMenu title="Настройки">
            <Row>
              <TextInput
                name="name"
                required
                className={styles.settingInput}
                label="Название категории"
                placeholder="Введите название категории"
              />
              <SelectInput
                name="group_id"
                className={styles.settingInput}
                required
                label="Группа"
                placeholder="Выберите группу"
                options={groupsList}
              />
            </Row>
            <Row>
              <TextInput
                name="number"
                required
                className={styles.settingInput}
                label="Сортировка"
                placeholder="Введите сортировку"
                transform={numberFormat}
              />
              <SwitchInput
                name="status"
                className={styles.statusInput}
                placement="right"
                gap="medium"
                label={
                  <Text size="small" color="textSecondary">
                    {values.status ? 'активна' : 'неактивна'}{' '}
                  </Text>
                }
              />
            </Row>
          </DropMenu>

          <DropMenu title="Характеристики">
            {values.attributes.map(({ attribute_id }, index) => {
              const attribute = attribute_id ? attributesHash[attribute_id] : null
              const options = attribute
                ? attribute.attribute_values?.map(({ id, value }) => ({ id, name: value })) || []
                : []

              return (
                <Row key={attribute_id}>
                  <SelectInput
                    name={`attributes[${index}].attribute_id`}
                    className={styles.settingInput}
                    hasClear
                    label="Атрибут"
                    placeholder="Выберите атрибут"
                    options={attributesList}
                  />
                  <MultiSelectInput
                    name={`attributes[${index}].value_ids`}
                    className={styles.attributesInput}
                    hasClear
                    label="Значения атрибута"
                    options={options}
                  />
                  <RemoveAttributeButton name="attributes" itemIndex={index} />
                </Row>
              )
            })}
            {values.attributes.map((record, index) => (
              <AttributeIdListener key={index} name={`attributes[${index}]`} />
            ))}
            <AddEmptyAttribute name="attributes" />
          </DropMenu>

          <FormControlsPanel className={styles.controls}>
            <Button type="submit" disabled={submitLoading} className={styles.saveBtn}>
              Сохранить
            </Button>

            {renderControls()}
          </FormControlsPanel>
        </form>
      )}
    </Formik>
  )
}

export default memo(CategoryForm)
