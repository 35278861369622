import React from 'react'

import Text from 'components/Typography'

import DiscountInputs from '../DiscountInputs'

import styles from './all.module.scss'

type IAllProps = {
  name: string
}

const All: React.FC<IAllProps> = ({ name }) => (
  <div className={styles.container}>
    <div className={styles.contentGroup}>
      <Text className={styles.label}>Все товары</Text>
      <Text color="textSecondary" size="small">
        Выбранный прайс-лист будет использован для всех товаров в нем. <br />
        Назначьте к нему дополнительную скидку или наценку, или оставьте поле пустым
      </Text>
    </div>
    <DiscountInputs name={name} />
  </div>
)

export default All
