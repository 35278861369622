import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import Link from 'components/Link'
import ListControlGroup from 'components/ListControlGroup'
import TrashIcon from 'components/Icons/TrashIcon'
import CopyIcon from 'components/Icons/CopyIcon'
import EditIcon from 'components/Icons/EditIcon'
import SuccessItemDialog from 'components/Dialogs/SuccessItemDialog'
import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import { IDeleteCategory, DELETE_CATEGORY } from 'graphql/mutations/deleteCategory'
import { COPY_CATEGORY, ICopyCategory } from 'graphql/mutations/copyCategory'
import { GET_CATEGORIES, GET_CATEGORIES_NUMBERS } from 'graphql/queries/getCategories'
import useCmsParams from 'hooks/useCmsParams'
import useNotifyCms from 'hooks/useNotifyCms'

interface IControlsProps {
  id: string
}

const Controls: React.FC<IControlsProps> = ({ id }) => {
  const { push } = useHistory()
  const { sellerId } = useCmsParams()
  const addNotify = useNotifyCms()

  const [confirmDialog, setConfirmDialog] = useState<'delete' | 'copy' | null>(null)
  const [isCompleteDialogOpen, setIsCompleteDialogOpen] = useState<boolean>(false)

  const [deleteCategory] = useMutation<IDeleteCategory>(DELETE_CATEGORY, {
    refetchQueries: [GET_CATEGORIES, GET_CATEGORIES_NUMBERS],
    onCompleted: () => {
      setConfirmDialog(null)
      addNotify('success')
    },
    onError: () => addNotify('error'),
  })

  const [copyCategory, { data: copyData }] = useMutation<ICopyCategory>(COPY_CATEGORY, {
    refetchQueries: [GET_CATEGORIES, GET_CATEGORIES_NUMBERS],
    onCompleted: () => {
      setConfirmDialog(null)
      setIsCompleteDialogOpen(true)
    },
    onError: () => addNotify('error'),
  })

  const handlerDelete = (payload: number) => deleteCategory({ variables: { id: payload } })

  const handlerCopy = (payload: number) => copyCategory({ variables: { id: payload } })

  const onCopyClick = () => {
    setConfirmDialog('copy')
  }

  const onDeleteClick = () => {
    setConfirmDialog('delete')
  }

  const onSubmitDeleteClick = () => {
    if (confirmDialog === 'delete') {
      handlerDelete(Number(id))
    } else {
      handlerCopy(Number(id))
    }
  }

  const onSuccessDialogClose = () => {
    setIsCompleteDialogOpen(false)
  }

  return (
    <>
      <ListControlGroup>
        <Link href={`/${sellerId}/catalog/categories/${id}`} startIcon={<EditIcon />}>
          Редактировать
        </Link>
        <Link component="button" startIcon={<CopyIcon />} onClick={onCopyClick}>
          Создать копию
        </Link>
        <Link component="button" startIcon={<TrashIcon />} onClick={onDeleteClick}>
          Удалить
        </Link>
      </ListControlGroup>

      <SuccessItemDialog
        isOpen={isCompleteDialogOpen}
        title="Категория успешно скопирована"
        onClose={onSuccessDialogClose}
        toItemText="К категории"
        onToItem={() => push(`/${sellerId}/catalog/categories/${copyData?.copyCategory?.message}`)}
      />

      <ConfirmDialog
        isOpen={!!confirmDialog}
        title={
          confirmDialog === 'delete'
            ? 'Вы уверены, что хотите удалить категорию?'
            : 'Вы действительно хотите создать копию категории?'
        }
        submitBtnText={confirmDialog === 'delete' ? 'Да, удалить' : 'Да, создать'}
        onSubmit={onSubmitDeleteClick}
        onClose={() => setConfirmDialog(null)}
      />
    </>
  )
}

export default Controls
