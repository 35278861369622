import React, { memo, useCallback } from 'react'

import TextField from 'components/TextField'
import SelectField from 'components/SelectField'
import IconButton from 'components/IconButton'
import Text from 'components/Typography'
import CheckboxLabel from 'components/CheckboxLabel'
import TrashIcon from 'components/Icons/TrashIcon'
import { IOrdersColorStatusFormValues, IOrdersColorStatusField } from 'pages/More/types'
import { DEFAULT_ORDER_STATUS_COLOR } from 'consts/defaultValues'

import OptionItem from './components/OptionItem'
import OrderColorButton from './components/OrderColorButton'

import styles from './colorInput.module.scss'

type IColorInputProps = {
  index: number
  status: IOrdersColorStatusField
  setStatuses: React.Dispatch<React.SetStateAction<IOrdersColorStatusFormValues>>
}

const statusOptions = [
  { id: '#47A347', name: 'Зеленый' },
  { id: '#F9C546', name: 'Желтый' },
  { id: '#0FA0DA', name: 'Голубой' },
  { id: '#F9423A', name: 'Красный' },
  { id: '#002FA7', name: 'Синий' },
  { id: '#121239', name: 'Черный' },
  { id: '#5C5F69', name: 'Темно-серый' },
  { id: '#DCDEE4', name: 'Светло-серый' },
  { id: DEFAULT_ORDER_STATUS_COLOR, name: 'Серый' },
]

const SelectStartColor: React.FC<{ color: string }> = ({ color }) => (
  <div className={styles.startColor} style={{ backgroundColor: color }} />
)

const ColorInput: React.FC<IColorInputProps> = ({ index, status, setStatuses }) => {
  const { title, color, out_of_limit } = status

  const onNameChange = useCallback(
    (event: InputChangeEvent) => {
      const { value } = event.target
      setStatuses((prevState) =>
        prevState.map((item, i) => {
          if (i === index) {
            return { ...item, title: value }
          }
          return item
        }),
      )
    },
    [setStatuses, index],
  )

  const onColorChange = useCallback(
    (payload: string | null) => {
      const availableStatus = statusOptions.find((item) => item.id === payload)

      if (!availableStatus) {
        throw new Error('NO COLOR')
      }

      setStatuses((prevState) =>
        prevState.map((item, i) => {
          if (i === index) {
            return { ...item, color: availableStatus.id }
          }
          return item
        }),
      )
    },
    [setStatuses, index],
  )

  const onStatusToggleChange = useCallback(() => {
    setStatuses((prevState) =>
      prevState.map((item, recordIndex) => {
        if (recordIndex === index) {
          return { ...item, out_of_limit: !item.out_of_limit }
        }
        return item
      }),
    )
  }, [setStatuses, index])

  const onDeleteClick = useCallback(() => {
    setStatuses((prevState) => prevState.filter((_, i) => i !== index))
  }, [setStatuses, index])

  return (
    <div className={styles.container}>
      <TextField
        label={`Статус ${index + 1}`}
        placeholder="Введите название"
        value={title}
        onChange={onNameChange}
      />

      <SelectField
        hideSearch
        label="Цвет статуса"
        value={color}
        options={statusOptions}
        onChange={onColorChange}
        renderOption={(params) => <OptionItem key={params.option.id} {...params} />}
        startAdornment={<SelectStartColor color={color} />}
      />

      <div className={styles.limitGroup}>
        <Text className={styles.limitTitle}>Кредитный лимит</Text>
        <CheckboxLabel
          checked={out_of_limit}
          name="out_of_limit"
          label="Исключать из лимита"
          onChange={onStatusToggleChange}
        />
      </div>

      <OrderColorButton className={styles.exampleButton} color={color}>
        {title || 'Новый статус'}
      </OrderColorButton>

      <div className={styles.deleteIconWrapper}>
        {index > 0 ? (
          <IconButton onClick={onDeleteClick}>
            <TrashIcon color="action" />
          </IconButton>
        ) : null}
      </div>
    </div>
  )
}

export default memo(ColorInput)
