import React, { useCallback, useEffect } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import getAppUrl from 'utils/getAppUrl'
import SuccessIcon from 'components/Icons/SuccessIcon'
import HyperlinkIcon from 'components/Icons/HyperlinkIcon'
import useAddNotify from 'hooks/useAddNotify'
import useBoolean from 'hooks/useBoolean'
import useCmsParams from 'hooks/useCmsParams'
import Link from 'components/Link'
import useCurrentSeller from 'hooks/useCurrentSeller'
import { IClient } from 'types/types'

import styles from './copyLink.module.scss'

type ICopyLinkProps = {
  client?: IClient
}

const CopyLink: React.FC<ICopyLinkProps> = ({ client }) => {
  const { orderId } = useCmsParams()
  const addNotify = useAddNotify()
  const { domain = '' } = useCurrentSeller()

  const [isCopySuccess, setIsCopySuccess] = useBoolean()

  const onCopyLink = useCallback(
    (_: string, result: boolean) => {
      if (result) {
        setIsCopySuccess.on()
      } else {
        addNotify({ kind: 'warning', message: 'Ошибка копирования' })
      }
    },
    [addNotify, setIsCopySuccess],
  )

  useEffect(() => {
    if (!isCopySuccess) {
      return undefined
    }
    const timer = setTimeout(() => {
      setIsCopySuccess.off()
    }, 3000)

    return () => {
      clearTimeout(timer)
    }
  }, [isCopySuccess, setIsCopySuccess])

  if (!client) return null

  return (
    <>
      {isCopySuccess ? (
        <div className={styles.successText}>
          <SuccessIcon />
          <p>Ссылка на заказ скопирована</p>
        </div>
      ) : (
        <CopyToClipboard text={`${getAppUrl({ domain })}${client.id}/orders/${orderId}`} onCopy={onCopyLink}>
          <Link component="button" startIcon={<HyperlinkIcon />}>
            Заказ в кабинете клиента
          </Link>
        </CopyToClipboard>
      )}
    </>
  )
}

export default CopyLink
