import React, { memo, useCallback, useMemo, useState } from 'react'
import { FormikHelpers } from 'formik'

import { useSetBannerMutation } from 'graphql/mutations/setBanner'
import { useGetBannerQuery } from 'graphql/queries/getBanner'
import { buildGetSellerQuery, useGetSellerQuery } from 'graphql/queries/getSeller'
import GoBackLink from 'components/GoBackLink'
import PageContainer from 'components/PageContainer'
import ResultTitle from 'components/ResultTitle'
import Preloader from 'components/Preloader'
import ApiError from 'components/ApiError'
import BannerForm, { IBannerFormValues } from 'components/forms/BannerForm'
import useCmsParams from 'hooks/useCmsParams'
import useNotifyCms from 'hooks/useNotifyCms'
import useBoolean from 'hooks/useBoolean'
import withLeaveThisPageModal from 'hocs/withLeaveThisPageModal'
import withErrorBoundary from 'hocs/withErrorBoundary'
import convertToFormErrors from 'utils/convertToFormErrors'
import createUrlFromUploadedFile from 'helpers/createUrlFromUploadedFile'
import convertUploadedImagesToImagesInput from 'utils/convertUploadedImagesToImagesInput'
import defaultFieldsHelper from 'utils/defaultFieldsHelper'
import baseSellerDefaultFields from 'consts/defaultFields/baseSeller'

import { ADD_BANNER, GET_BANNER } from './queries'
import prepareInitialValues from './utils/prepareInitialValues'
import prepareSubmitValues from './utils/prepareSubmitValues'
import Controls from './components/Controls'

const defaultValues: IBannerFormValues = {
  name: '',
  url: '',
  text: '',
  button: '',
  status: false,
  desktopImage: null,
  mobileImage: null,
  tx_color: '#ffffff',
  show_title: true,
}

const loadImage = async (file: File) => {
  const uploadedImgs = await createUrlFromUploadedFile([file], { type: 'banner' })
  const [convertedImg] = convertUploadedImagesToImagesInput(uploadedImgs)
  return convertedImg
}

const loadImages = (
  desktopImage: IBannerFormValues['desktopImage'],
  mobileImage: IBannerFormValues['mobileImage'],
) =>
  Promise.all([
    desktopImage instanceof File ? loadImage(desktopImage) : null,
    mobileImage instanceof File ? loadImage(mobileImage) : null,
  ])

const BannersEdit: React.FC<{ expanded_view?: boolean }> = ({ expanded_view = false }) => {
  const { sellerId, bannerId } = useCmsParams()
  const addNotify = useNotifyCms()

  const [submitLoading, setSubmitLoading] = useBoolean()
  const [initialFormValues, setInitialFormValues] = useState<IBannerFormValues>(defaultValues)

  const { data, loading, error } = useGetBannerQuery(GET_BANNER, {
    variables: { id: bannerId },
    fetchPolicy: 'network-only',
    onCompleted: (res) => setInitialFormValues(prepareInitialValues(res.getBanner)),
  })

  const banner = useMemo(() => data?.getBanner || null, [data])

  const [onSetBanner] = useSetBannerMutation(ADD_BANNER)

  const onSubmit = useCallback(
    async (values: IBannerFormValues, { setErrors, resetForm }: FormikHelpers<IBannerFormValues>) => {
      if (!banner) throw new Error('No banner data')
      setSubmitLoading.on()

      const { desktopImage, mobileImage } = values

      const uploadedImages = await loadImages(desktopImage, mobileImage)

      onSetBanner({
        variables: {
          id: bannerId,
          input: prepareSubmitValues(banner, values, uploadedImages),
        },
      })
        .then((response) => {
          const nextData = response.data?.setBanner
          if (!nextData) throw new Error('No setBanner data')

          const initValues = prepareInitialValues(nextData)
          resetForm({ values: initValues })
          setInitialFormValues(initValues)
          addNotify('success')
        })
        .catch((resError) => {
          addNotify('error')
          const errors = convertToFormErrors(resError)
          if (errors) setErrors(errors)
        })
        .finally(() => setSubmitLoading.off())
    },
    [onSetBanner, addNotify, bannerId, banner, setSubmitLoading],
  )

  if (loading) return <Preloader />

  if (error) {
    return (
      <PageContainer>
        <ApiError error={error} />
      </PageContainer>
    )
  }

  if (!banner) return null

  return (
    <PageContainer>
      <GoBackLink href={`/${sellerId}/options/banners`} />
      <ResultTitle>{banner.name} </ResultTitle>
      <BannerForm
        title="Редактирование баннера"
        isExtendedHomeView={expanded_view}
        initialValue={initialFormValues}
        submitLoading={submitLoading}
        onSubmit={onSubmit}
        renderControls={() => <Controls />}
      />
    </PageContainer>
  )
}

const GET_SELLER = buildGetSellerQuery(`id expanded_view`)

const BannersEditWrapper: React.FC = () => {
  const { sellerId } = useCmsParams()

  const { data, loading } = useGetSellerQuery(GET_SELLER, {
    fetchPolicy: 'no-cache',
    variables: { id: sellerId },
  })

  const { expanded_view } = defaultFieldsHelper(data?.getSeller, baseSellerDefaultFields)

  return loading ? null : <BannersEdit expanded_view={expanded_view} />
}

export default withErrorBoundary(withLeaveThisPageModal(memo(BannersEditWrapper)))
