import { DocumentNode, gql, QueryHookOptions, useQuery } from '@apollo/client'

import { Paginate, PAttributes } from 'types/types'

export type IGetAttributes = {
  getAttributes: PAttributes
}

export type IGetAttributesParams = {
  seller_id: IPrimaryKey
  search?: string
  paginate?: Paginate
}

export const GET_ATTRIBUTES = gql`
  query getAttributes($seller_id: ID!, $search: String, $paginate: Paginate) {
    getAttributes(seller_id: $seller_id, search: $search, paginate: $paginate) {
      page
      pages
      elements
      list {
        id
        name
        description
        attribute_values {
          id
          attribute_id
          value
          select
        }
        number
        created_at
        updated_at
      }
    }
  }
`

export const buildGetAttributesQuery = (fields: string): DocumentNode => gql`
  query getAttributes($seller_id: ID!, $search: String, $paginate: Paginate) {
    getAttributes(seller_id: $seller_id, search: $search, paginate: $paginate) {
      page
      pages
      elements
      list {
        ${fields}
      }
    }
  }
`

export const useGetAttributesQuery = <TData = IGetAttributes, TOptions = IGetAttributesParams>(
  fields: DocumentNode,
  options?: QueryHookOptions<TData, TOptions>,
) => useQuery<TData, TOptions>(fields, options)
