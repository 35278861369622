import React, { FC } from 'react'

import Text, { H1 } from 'components/Typography'
import Link from 'components/Link'
import Dialog from 'components/Dialog'
import DialogCloseButton from 'components/DialogCloseButton'
import DialogContent from 'components/DialogContent'
import { IPriceType } from 'types/types'
import useCmsLinks from 'hooks/useCmsLinks'
import useCurrentSeller from 'hooks/useCurrentSeller'
import renderCurrencySymbol from 'utils/renderCurrencySymbol'

import styles from './productPriceListsDialog.module.scss'

type IProductPriceListsDialogProps = {
  isOpen: boolean
  prices: IPriceType[]
  onClose: () => void
}

// NOTE: check in utils for renderPrice
const formatPrice = (price: string) => {
  const formatter = new Intl.NumberFormat('ru-RU', { style: 'decimal', currency: 'RUB' })
  return formatter.format(Number(price))
}

const Row: FC = ({ children }) => <div className={styles.row}>{children}</div>

const ProductPriceListsDialog: FC<IProductPriceListsDialogProps> = ({ isOpen, prices, onClose }) => {
  const { priceListEditLink } = useCmsLinks()
  const { settings } = useCurrentSeller()

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <DialogCloseButton onClick={onClose} />
      <DialogContent className={styles.content}>
        <H1 className={styles.title}>Прайс листы</H1>
        <Text className={styles.subtitle}>Цены этого товара по всем существующим прайс листам</Text>
        <div>
          <Row>
            <Text bold size="small">
              Наименование
            </Text>
            <Text bold size="small">
              Цена товара
            </Text>
          </Row>
          {prices.map(({ id, name, price }, index) => (
            <Row key={index}>
              {id ? (
                <Link href={priceListEditLink(id)}>{name}</Link>
              ) : (
                <Text className={styles.name}>{name}</Text>
              )}
              <Text className={styles.price} bold>
                {price ? (
                  <span>
                    {formatPrice(price)} {renderCurrencySymbol(settings?.currency)}
                  </span>
                ) : (
                  ''
                )}
              </Text>
            </Row>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ProductPriceListsDialog
