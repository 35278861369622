import { ICompanyFormValues } from 'components/forms/CompanyForm/types'
import { ISetCompanyParams } from 'graphql/mutations/setCompany'
import checkDestroyItems from 'utils/checkDestroyItems'

const prepareContracts = (
  initContracts: ICompanyFormValues['contracts'],
  formContracts: ICompanyFormValues['contracts'],
): ISetCompanyParams['input']['contracts'] => {
  const ensureDeletedItems = checkDestroyItems(initContracts, formContracts)

  return ensureDeletedItems.map(({ _delete, ...record }) => ({
    ...record,
    flag: _delete ? 'delete' : undefined,
  }))
}

const filterEmptyContract = ({ id, name, limit, debt }: ICompanyFormValues['contracts'][number]) => {
  if (id) return true
  return name !== '' || limit !== '' || debt !== ''
}

const prepareSubmitValues = (
  initialValues: ICompanyFormValues,
  formValues: ICompanyFormValues,
): ISetCompanyParams['input'] => {
  const { name, inn } = formValues

  return {
    name,
    inn,
    contracts: prepareContracts(initialValues.contracts, formValues.contracts.filter(filterEmptyContract)),
  }
}

export default prepareSubmitValues
