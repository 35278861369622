import { useCallback, useContext } from 'react'

import { NotifyContext } from 'contexts/NotifyProvider'

type IUseCatchNotify = ({ message }: Error) => void

// TODO: check if working
const useCatchNotify = (): IUseCatchNotify => {
  const { addNotify } = useContext(NotifyContext)

  const onCatchNotify = useCallback(
    ({ message }: Error): void => {
      addNotify({ kind: 'warning', message: message || 'Warning' })
    },
    [addNotify],
  )

  return onCatchNotify
}

export default useCatchNotify
