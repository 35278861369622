import { ISellerInput } from 'types/types'

import { IGeneralFormValues } from '../types'

const prepareSellerSubmit = (payload: IGeneralFormValues): ISellerInput => {
  const { name, company, industry, description, address, phone } = payload
  return {
    name,
    company,
    industry,
    description,
    address,
    phone,
  }
}

export default prepareSellerSubmit
