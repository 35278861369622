import React from 'react'

import DropMenu from 'components/DropMenu'
import TitleCounter from 'components/DropMenu/TitleCounter'
import Text from 'components/Typography'
import Link from 'components/Link'
import Button from 'components/Button'
import { IContact } from 'types/types'

import useCmsParams from 'hooks/useCmsParams'
import styles from './contacts.module.scss'

type IContactsProps = {
  contacts: IContact[]
}

const Contacts: React.FC<IContactsProps> = ({ contacts }) => {
  const { sellerId } = useCmsParams()

  if (!contacts.length) {
    return (
      <DropMenu title="Контакты">
        <div className={styles.noContactsGroup}>
          <Text bold>У текущего контрагента пока нет ни одного контакта.</Text>

          <Link href={`/${sellerId}/contacts/create`}>
            <Button>Создать контакт</Button>
          </Link>
        </div>
      </DropMenu>
    )
  }

  return (
    <DropMenu
      resetPadding
      initOpen={false}
      title={<TitleCounter counter={contacts.length}>Контакты</TitleCounter>}
    >
      <div className={styles.header}>
        <Text bold size="small">
          ФИО
        </Text>
      </div>

      {contacts.map(({ id: contactId, name }) => (
        <Link key={contactId} className={styles.item} href={`/${sellerId}/contacts/${contactId}`}>
          <Text>{name}</Text>
        </Link>
      ))}
    </DropMenu>
  )
}

export default Contacts
