import React, { useCallback, useEffect, useMemo } from 'react'
import { useField } from 'formik'

import { Attribute as IAttribute } from 'types/types'
import SelectField from 'components/SelectField'

import { IVariantsFormValues } from '../../../../../../types'

import styles from './productAttribute.module.scss'

type IProductAttributeProps = {
  name: string
  attributesHash: Record<string, IAttribute>
}

const transformAttributeToOption = ({ id, value }: { id: IPrimaryKey; value: string }) => ({
  id,
  name: value,
})

const ProductAttribute: React.FC<IProductAttributeProps> = ({ name, attributesHash }) => {
  const [{ value: variantAttribute }] = useField<IVariantsFormValues['variantAttribute']>('variantAttribute')
  const [{ value }, _, { setValue, setTouched }] =
    useField<IVariantsFormValues['products'][0]['product_attribute']>(name)

  const attribute = useMemo(
    () => attributesHash[variantAttribute.attributeId],
    [attributesHash, variantAttribute],
  )

  const options = useMemo(() => {
    if (!attribute) return []
    if (!attribute.attribute_values) return []
    const availableOptions = attribute.attribute_values.filter((record) =>
      variantAttribute.valueIds.includes(record.id),
    )
    return availableOptions.map(transformAttributeToOption)
  }, [attribute, variantAttribute])

  const onChange = useCallback(
    (payload: string) => {
      setTouched(true)
      setValue(payload)
    },
    [setTouched, setValue],
  )

  const onClear = useCallback(() => {
    setTouched(true)
    setValue('')
  }, [setTouched, setValue])

  useEffect(() => {
    const isValueAvailable = variantAttribute.valueIds.includes(value)

    if (isValueAvailable) return

    setValue('')
  }, [variantAttribute.valueIds])

  return (
    <div className={styles.attributeWrapper}>
      <SelectField
        portal
        placeholder="Выберите"
        value={value}
        options={options}
        onChange={onChange}
        onClear={onClear}
      />
    </div>
  )
}

export default ProductAttribute
