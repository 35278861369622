import React, { memo, useCallback } from 'react'
import { Draggable, DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd'

import { useSetBannerStatusMutation, buildSetBannerStatusQuery } from 'graphql/mutations/setBannerStatus'
import Link from 'components/Link'
import Text from 'components/Typography'
import Switch from 'components/Switch'
import IconButton from 'components/IconButton'
import SortingIcon from 'components/Icons/SortingIcon'
import { IBanner } from 'types/types'
import useCmsParams from 'hooks/useCmsParams'
import useNotifyCms from 'hooks/useNotifyCms'
import useBoolean from 'hooks/useBoolean'

import Controls from './components/Controls'

import styles from './Banner.module.scss'

type IBannerProps = {
  index: number
  banner: IBanner
  refetchList: () => void
}

const getItemStyle = (draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => ({
  margin: '0 0 4px 0',
  ...draggableStyle,
})

const SET_BANNER = buildSetBannerStatusQuery(`id name status`)

const Banner: React.FC<IBannerProps> = ({ index, banner, refetchList }) => {
  const { id: bannerId, status, name, count } = banner

  const { sellerId } = useCmsParams()
  const addNotify = useNotifyCms()

  const [isDragDisabled, setIsDragDisabled] = useBoolean(true)

  const [onSetBannerStatus] = useSetBannerStatusMutation(SET_BANNER, {
    onCompleted: () => refetchList(),
    onError: () => addNotify('error'),
  })

  const onChangeStatus = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target

      onSetBannerStatus({
        variables: { id: bannerId, status: checked },
      })
    },
    [bannerId, onSetBannerStatus],
  )

  return (
    <Draggable key={bannerId} draggableId={bannerId} index={index} isDragDisabled={isDragDisabled}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(provided.draggableProps.style)}
          className={styles.container}
        >
          <IconButton
            className={styles.sortButton}
            onMouseOver={setIsDragDisabled.off}
            onMouseLeave={setIsDragDisabled.on}
          >
            <SortingIcon />
          </IconButton>

          <Link href={`/${sellerId}/options/banners/${bannerId}`} className={styles.name}>
            {name}
          </Link>

          <Text className={styles.count}>
            <Text color="textSecondary" component="span">
              Переходы:{' '}
            </Text>
            {count ? count.toLocaleString() : 0}
          </Text>

          <Switch
            checked={status}
            placement="right"
            gap="medium"
            label={
              <Text size="small" color="textSecondary">
                {status ? 'активен' : 'неактивен'}
              </Text>
            }
            onChange={onChangeStatus}
          />

          <Controls bannerId={bannerId} refetch={refetchList} />
        </div>
      )}
    </Draggable>
  )
}

export default memo(Banner)
