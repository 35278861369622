import { gql } from '@apollo/client'

export type ResetResponseType = {
  resetPassword: {
    message?: string
    status?: string
  }
}

export const RESET_PASSWORD = gql`
  mutation resetPassword($input: ResetPasswordInput) {
    resetPassword(input: $input) {
      message
      status
    }
  }
`
