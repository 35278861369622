import React, { useState } from 'react'

import Text, { H2 } from 'components/Typography'
import Button from 'components/Button'
import DialogContent from 'components/DialogContent'
import CheckboxLabel from 'components/CheckboxLabel'

import { IDeleteCatalogItem } from '../../types'

import styles from './SelectState.module.scss'

type ISelectStateProps = {
  onSubmit: (payload: IDeleteCatalogItem[]) => void
  onCancel: () => void
}

const options: Array<{ id: IDeleteCatalogItem; title: string; description: string }> = [
  { id: 'products', title: 'Товары', description: 'Все товары будут удалены' },
  { id: 'attributes', title: 'Атрибуты', description: 'Все атрибуты будут удалены' },
  { id: 'groups', title: 'Группы', description: 'Все группы будут удалены' },
  { id: 'categories', title: 'Категории', description: 'Все категории будут удалены' },
]

const defaultValues: Record<IDeleteCatalogItem, boolean> = {
  products: false,
  attributes: false,
  groups: false,
  categories: false,
}

const SelectState: React.FC<ISelectStateProps> = ({ onSubmit, onCancel }) => {
  const [selected, setSelected] = useState(defaultValues)

  const selectedITemsArray = Object.entries(selected)
    .filter(([, value]) => value)
    .map(([key]) => key as IDeleteCatalogItem)

  const onSubmitHandler = () => {
    onSubmit(selectedITemsArray)
  }

  const toggleItem = (event: InputChangeEvent, checked: boolean) => {
    const { value } = event.target
    setSelected((prevState) => ({ ...prevState, [value]: checked }))
  }

  return (
    <DialogContent className={styles.content}>
      <H2 className={styles.title}>Очистить каталог</H2>

      {options.map(({ id, title, description }) => (
        <div key={id} className={styles.itemGroup}>
          <CheckboxLabel label={title} value={id} checked={selected[id]} onChange={toggleItem} />
          <Text className={styles.description} color="textSecondary" size="caption">
            {description}
          </Text>
        </div>
      ))}

      <div className={styles.controlsGroup}>
        <Button disabled={!selectedITemsArray.length} onClick={onSubmitHandler}>
          Удалить
        </Button>
        <Button variant="outlined" onClick={onCancel}>
          Отменить
        </Button>
      </div>
    </DialogContent>
  )
}

export default SelectState
