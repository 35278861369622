import React, { memo, useCallback, useMemo, useState } from 'react'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'

import withErrorBoundary from 'hocs/withErrorBoundary'
import ApiError from 'components/ApiError'
import Button from 'components/Button'
import Pagination from 'components/Pagination'
import NoSearchResult from 'components/NoSearchResult'
import PageContainer from 'components/PageContainer'
import Preloader from 'components/Preloader'
import ResultSearchFilter from 'components/ResultSearchFilter'
import ResultTitle from 'components/ResultTitle'
import Link from 'components/Link'
import hasPagination from 'utils/hasPagination'
import useCmsParams from 'hooks/useCmsParams'
import useSearchParams from 'hooks/useSearchParams'
import { SEARCH_DEBOUNCE_TIMEOUT, SEARCH_INPUT_LIMIT } from 'consts'
import buildPaginateParams from 'utils/buildPaginateParams'
import useBoolean from 'hooks/useBoolean'
import { buildGetManagersQuery, useGetManagersQuery } from 'graphql/queries/getManagers'

import Empty from './components/Empty'
import Result from './components/Result'

import styles from './managersList.module.scss'

type IPayload = {
  search: string
  page: string
}

const initPayload: IPayload = {
  search: '',
  page: '',
}

const GET_MANAGERS = buildGetManagersQuery(`
  id 
  name

  clients {
    id
  }
`)

const ManagersList: React.FC = () => {
  const { sellerId } = useCmsParams()

  const [searchParams, setSearchParams] = useSearchParams<IPayload>(initPayload)
  const { page, search } = searchParams
  const [searchInput, setSearchInput] = useState<string>(search)
  const [initialLoading, setInitialLoading] = useBoolean(true)

  const hasFilters = useMemo(() => !isEqual(initPayload, searchParams), [searchParams])

  const onSearchDebounce = useMemo(
    () =>
      debounce((payload: string) => {
        setSearchParams({ ...searchParams, page: '1', search: payload })
      }, SEARCH_DEBOUNCE_TIMEOUT),
    [setSearchParams, searchParams],
  )

  const { data, loading, error, refetch } = useGetManagersQuery(GET_MANAGERS, {
    variables: {
      seller_id: sellerId,
      search,
      paginate: buildPaginateParams(page),
    },
    onCompleted: () => {
      if (initialLoading) setInitialLoading.off()
    },
    fetchPolicy: 'network-only',
  })

  const { getManagers: { list: managers = [], elements = 0, pages = 0 } = {} } = data || {}

  const isEmpty = !loading && !hasFilters && !managers.length

  const onSearchChange = useCallback(
    (event: InputChangeEvent) => {
      const { value } = event.target
      if (value.length > SEARCH_INPUT_LIMIT) return
      setSearchInput(value)
      onSearchDebounce(value)
    },
    [onSearchDebounce],
  )

  const onSearchClear = useCallback(() => {
    setSearchInput('')
    setSearchParams((prev) => ({ ...prev, page: '1', search: '' }))
  }, [setSearchParams])

  const onPageChange = useCallback(
    (nextPage: number) => {
      setSearchParams((prev) => ({ ...prev, page: nextPage.toString() }))
    },
    [setSearchParams],
  )

  if (initialLoading) return <Preloader />

  if (error) {
    return (
      <PageContainer>
        <ResultTitle>Менеджеры</ResultTitle>
        <div className={styles.filtersGroup}>
          <Link href={`/${sellerId}/managers/create`}>
            <Button>Добавить менеджера</Button>
          </Link>
        </div>
        <ApiError error={error} />
      </PageContainer>
    )
  }

  if (isEmpty) {
    return (
      <PageContainer>
        <Empty />
      </PageContainer>
    )
  }

  return (
    <PageContainer>
      <ResultTitle>Менеджеры</ResultTitle>
      <div className={styles.filtersGroup}>
        <Link href={`/${sellerId}/managers/create`}>
          <Button>Добавить менеджера</Button>
        </Link>

        <ResultSearchFilter
          value={searchInput}
          placeholder="Поиск по менеджерам"
          onChange={onSearchChange}
          onClear={onSearchClear}
        />
      </div>

      {loading ? (
        <Preloader />
      ) : (
        <>
          {managers.length ? <Result managers={managers} refetchList={refetch} /> : null}

          {hasPagination(elements) && (
            <Pagination page={page ? Number(page) : 1} count={pages} onChange={onPageChange} />
          )}

          {managers.length ? null : <NoSearchResult />}
        </>
      )}
    </PageContainer>
  )
}

export default withErrorBoundary(memo(ManagersList))
