import { DocumentNode, gql, useMutation, MutationHookOptions } from '@apollo/client'

import { IPriceList, Maybe, Scalars } from 'types/types'

export type ISetPriceList = {
  setPriceList: IPriceList
}

export type ISetPriceListParams = {
  id: IPrimaryKey
  name: Scalars['String']
  default_price: Scalars['Boolean']
  show_prices?: Maybe<Scalars['Boolean']>
  file?: Maybe<File>
}

export const buildSetPriceListQuery = (fields: string): DocumentNode => gql`
  mutation setPriceList(
    $id: ID!, 
    $name: String!, 
    $default_price: Boolean!, 
    $show_prices: Boolean, 
    $file: Upload
  ) {
    setPriceList(
      id: $id, 
      name: $name, 
      default_price: $default_price, 
      show_prices: $show_prices, 
      file: $file
    ) {
      ${fields}
    }
  }
`

export const useSetPriceListMutation = <TData = ISetPriceList, TOptions = ISetPriceListParams>(
  fields: DocumentNode,
  options?: MutationHookOptions<TData, TOptions>,
) => useMutation<TData, TOptions>(fields, options)
