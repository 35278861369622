import { Overrides } from '@material-ui/core/styles/overrides'

const formControlLabel: Overrides['MuiFormControlLabel'] = {
  root: {
    marginLeft: '0',
    marginRight: '0',
    color: '#121239',
    '&:hover': {
      '& .MuiCheckbox-root': {
        color: '#121239',
      },
    },
  },
  label: {
    marginLeft: '12px',
    color: '#121239',
    userSelect: 'none',
  },
}

export default formControlLabel
