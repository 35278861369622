import React, { memo, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { FormikHelpers } from 'formik'

import { IAddClientParams, buildAddClientQuery, useAddClientMutation } from 'graphql/mutations/addClient'
import ResultTitle from 'components/ResultTitle'
import GoBackLink from 'components/GoBackLink'
import PageContainer from 'components/PageContainer'
import useCmsParams from 'hooks/useCmsParams'
import useNotifyCms from 'hooks/useNotifyCms'
import convertToFormErrors from 'utils/convertToFormErrors'
import phoneFormatSave from 'utils/phoneFormatSave'
import withLeaveThisPageModal from 'hocs/withLeaveThisPageModal'
import withErrorBoundary from 'hocs/withErrorBoundary'
import ClientForm, { IClientFormValues } from 'components/forms/ClientForm'

import CompaniesInput from './components/CompaniesInput'

const initialValue: IClientFormValues = {
  status: true,
  phone: '',
  email: '',
  name: '',
  managerId: null,
  companies: [],
}

const ADD_CLIENT = buildAddClientQuery(`
  id
  name
  status
  phone
  email
  companies {
    id
    name
    inn
  }
`)

const prepareSubmitValues = (payload: IClientFormValues): IAddClientParams['input'] => {
  const { phone, companies, managerId, ...rest } = payload

  return {
    ...rest,
    phone: phoneFormatSave(phone),
    manager_id: managerId,
    companies: companies.map(({ name, inn }) => ({ name, inn })),
  }
}

const ClientCreate: React.FC = () => {
  const { sellerId } = useCmsParams()
  const { replace } = useHistory()
  const addNotify = useNotifyCms()

  const [onAddClient, { loading }] = useAddClientMutation(ADD_CLIENT)

  const onSubmit = useCallback(
    async (values: IClientFormValues, { setErrors }: FormikHelpers<IClientFormValues>) =>
      onAddClient({
        variables: {
          seller_id: sellerId,
          input: prepareSubmitValues(values),
        },
      })
        .then(({ data }) => {
          addNotify('success')
          if (data) replace(`/${sellerId}/clients/profile/${data.addClient.id}`)
        })
        .catch((error) => {
          addNotify('error')
          const errors = convertToFormErrors(error)
          if (errors) setErrors(errors)
        }),
    [onAddClient, sellerId, addNotify, replace],
  )

  return (
    <PageContainer>
      <GoBackLink href={`/${sellerId}/clients/list`} />
      <ResultTitle>Создание контрагента</ResultTitle>
      <ClientForm initialValue={initialValue} submitLoading={loading} onSubmit={onSubmit}>
        <CompaniesInput />
      </ClientForm>
    </PageContainer>
  )
}

export default withErrorBoundary(withLeaveThisPageModal(memo(ClientCreate)))
