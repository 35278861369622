import { useContext } from 'react'

import { SellersContext, ISellerCMS } from 'contexts/SellerProvider'

const useCurrentSeller = (): ISellerCMS => {
  const { seller } = useContext(SellersContext)

  if (!seller) {
    throw new Error('Seller not found')
  }

  return seller
}

export default useCurrentSeller
