import React from 'react'
import clsx from 'clsx'

import styles from './dialogContent.module.scss'

type IDialogContentProps = {
  className?: string
}

const DialogContent: React.FC<IDialogContentProps> = ({ className, children }) => (
  <div className={clsx(styles.dialogContent, className)}>{children}</div>
)

export default DialogContent
