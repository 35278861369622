import { buildGetBaseProductsQuery } from 'graphql/queries/getBaseProducts'

export const GET_BASE_PRODUCTS = buildGetBaseProductsQuery(`
  id
  name
  status
  variants_status
  images {
    id
    path
  }
  products {
    id
    base_product_id
    vendor_code
    amount
    price
    status
    images {
      id
      path
    }
    stores {
      id
      amount
    }
  }
`)
