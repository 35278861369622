import React, { FC, InputHTMLAttributes, useState } from 'react'
import InputMask from 'react-input-mask'

import block from 'utils/block'

import styles from './Input.module.scss'

interface InputPropsType extends InputHTMLAttributes<HTMLInputElement> {
  onChangeHandler?: (value: string) => void
  mask?: string | (string | RegExp)[]
  error?: string | null
  isError?: boolean
  errorHandler?: (isDirt: boolean) => boolean
  classNameRoot?: string
  limit?: number
}

const b = block(styles)

const Input: FC<InputPropsType> = ({
  error,
  mask = '',
  className,
  classNameRoot,
  value,
  errorHandler = () => false,
  isError: isErrorProps = false,
  onChangeHandler,
  limit,
  ...other
}) => {
  const [isDirt, setIsDirt] = useState(false)

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsDirt(true)

    if (other.onChange) {
      other.onChange(e)
    }

    if (onChangeHandler) {
      onChangeHandler(e.target.value)
    }
  }

  const isError = isErrorProps || errorHandler(isDirt)

  return (
    <div className={b('root', classNameRoot)}>
      {limit && (
        <span className={b('counter', { max: typeof value === 'string' ? value.length >= limit : false })}>
          {`${typeof value === 'string' ? value.length : 0}/${limit} символов`}
        </span>
      )}
      {/* @ts-ignore */}
      <InputMask
        mask={mask}
        className={b('input', { error: isError }, className)}
        value={value}
        {...other}
        onChange={handleOnChange}
      />
      {isError && <span className={styles.error}>{error}</span>}
    </div>
  )
}

export default Input
