import { IBannerFormValues } from 'components/forms/BannerForm'
import { IBanner, IBannerImage, Maybe } from 'types/types'

const prepareImage = (image: Maybe<IBannerImage> | undefined) => {
  if (!image) return null
  return image.path || null
}

const prepareInitialValues = ({
  status,
  name,
  url,
  text,
  button,
  desktop_image,
  mobile_image,
  tx_color,
  show_title,
}: IBanner): IBannerFormValues => ({
  name: name ?? '',
  status: status ?? false,
  show_title: show_title ?? true,
  url,
  text: text ?? '',
  button: button ?? '',
  tx_color: tx_color || '#ffffff',
  desktopImage: prepareImage(desktop_image),
  mobileImage: prepareImage(mobile_image),
})

export default prepareInitialValues
