import React, { ReactNode, useEffect } from 'react'

import { H2 } from 'components/Typography'
import Dialog from 'components/Dialog'
import DialogContent from 'components/DialogContent'

import { ReactComponent as SuccessfullyIcon } from 'assets/img/successfully.svg'

import styles from './successDialog.module.scss'

type ISuccessDialogProps = {
  isOpen: boolean
  text: string | ReactNode
  onClose: () => void
}

const SuccessDialog: React.FC<ISuccessDialogProps> = ({ isOpen, text, onClose }) => {
  useEffect(() => {
    if (!isOpen) return
    const timer = setTimeout(() => onClose(), 2000)
    return () => clearTimeout(timer)
  }, [isOpen])

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <DialogContent className={styles.content}>
        <SuccessfullyIcon className={styles.icon} />
        <H2> {text} </H2>
      </DialogContent>
    </Dialog>
  )
}

export default SuccessDialog
