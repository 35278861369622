import { DocumentNode, gql } from '@apollo/client'

import { IBaseProduct, IProductVatiantsInput } from 'types/types'

export type ISetProductVariants = {
  setProductVariants: IBaseProduct
}

export type ISetProductVariantsParams = {
  id: IPrimaryKey
  product_variants: IProductVatiantsInput
}

export const SET_PRODUCT_VARIANTS = gql`
  mutation setProductVariants($id: ID!, $product_variants: ProductVatiantsInput) {
    setProductVariants(id: $id, product_variants: $product_variants) {
      id
      name
      description
      seller_id
      category_id
      status
      variants_status
      images {
        id
        name
        path
      }
      products {
        id
        name
        vendor_code
        price
        status
        amount
        amount_wait
        description
        multiply
        on_order
        images {
          id
          name
          path
        }
        weight {
          value
          type
        }
        length {
          value
          type
        }
        width {
          value
          type
        }
        height {
          value
          type
        }
        volume {
          value
          type
        }
      }
      base_product_attributes {
        attribute_values {
          value
          id
          attribute_id
        }
        attribute {
          name
        }
      }
      product_variant_attributes {
        id
        base_product_id
        attribute {
          id
          seller_id
          name
          description
          attribute_values {
            id
            attribute_id
            value
            select
          }
          number
          created_at
          updated_at
        }
        attribute_values {
          id
          attribute_id
          value
          select
        }
      }
    }
  }
`

export const buildSetProductVariantsQuery = (fields: string): DocumentNode => gql`
  mutation setProductVariants($id: ID!, $product_variants: ProductVatiantsInput) {
    setProductVariants(id: $id, product_variants: $product_variants) {
      ${fields}
    }
  }
`
