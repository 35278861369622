import { IBaseProductFormValues } from 'components/forms/BaseProductForm'
import { IUploadedData } from 'helpers/createUrlFromUploadedFile'
import { IAttributesValuesInput, IBaseProductInput, IStoreInput, IValueType, ImageInput } from 'types/types'
import extractId from 'utils/extractId'

const convertUploadedImagesToImagesInput = (payload: IUploadedData[]): ImageInput[] =>
  payload.map(({ size, status, ...rest }) => rest)

const prepareValueType = ({ value, type }: Required<IValueType>): Required<IValueType> | null => {
  if (!value) return null
  const [first, second] = value.split('.')
  if (second) return { value, type }
  return { value: first, type }
}

const prepareStores = (stores: IBaseProductFormValues['stores']): IStoreInput[] => {
  if (!stores.length) return []
  // omit name from stores
  return stores.map(({ id: storeId, amount, amount_wait, multiply }) => ({
    store_id: storeId,
    amount: Number.isNaN(Number(amount)) ? 0 : Number(amount),
    amount_wait: Number.isNaN(Number(amount_wait)) ? 0 : Number(amount_wait),
    multiply: Number.isNaN(Number(multiply)) ? 0 : Number(multiply),
  }))
}

const prepareSubmitValues = (
  formValues: Omit<IBaseProductFormValues, 'images'> & { uploadedImages: IUploadedData[] },
): IBaseProductInput => {
  const {
    multiply,
    amount,
    amount_wait,
    weight,
    volume,
    length,
    width,
    height,
    uploadedImages,
    attributes,
    description = null,
    number,
    prices, // NOTE: remove unused field
    stores,
    related_products,
    links,
    ...rest
  } = formValues

  const nextAttributes: IAttributesValuesInput[] = Object.entries(attributes).map(([key, value]) => ({
    attribute_id: key,
    value_ids: [value],
  }))

  const convertedUploadedImages = convertUploadedImagesToImagesInput(uploadedImages)

  return {
    ...rest,
    multiply: multiply ? Number(multiply) : 1,
    amount: Number(amount),
    amount_wait: Number(amount_wait),
    weight: prepareValueType(weight),
    volume: prepareValueType(volume),
    length: prepareValueType(length),
    width: prepareValueType({ value: width, type: length.type }),
    height: prepareValueType({ value: height, type: length.type }),
    stores: prepareStores(stores),
    images: convertedUploadedImages,
    attributes: nextAttributes,
    description: description ?? '',
    number: number ? Number(number) : 9999,
    related_product_ids: related_products.map(extractId),
    links,
  }
}

export default prepareSubmitValues
