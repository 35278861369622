import React, { useCallback } from 'react'
import { useField } from 'formik'

import DropMenu from 'components/DropMenu'
import Button from 'components/Button'
import formikFieldEvent from 'utils/formInputs/formikFieldEvent'

import Box from '../Box'
import { IPriceRuleFormValues } from '../../types'

import ParameterGroup from './components/ParameterGroup'

const name = 'groupParameters'

const emptyParameter: IPriceRuleFormValues['groupParameters'][0] = {
  pricelistId: null,
  parameters: [
    {
      discount_ratio: '',
      discount_type: 'discount',
      parameter_type: 'ALL',
      localGroupId: null,
      localValueIds: [],
    },
  ],
}

const Parameters: React.FC = () => {
  const [{ value: groupParameters, onChange }] = useField<IPriceRuleFormValues['groupParameters']>(name)

  const addParameterGroup = useCallback(() => {
    const nextValue = groupParameters.concat(emptyParameter)
    onChange(formikFieldEvent(name, nextValue))
  }, [onChange, groupParameters])

  const removeParameterGroup = useCallback(
    (removeIndex: number) => () => {
      const nextValue = groupParameters.filter((_, index) => index !== removeIndex)
      onChange(formikFieldEvent(name, nextValue))
    },
    [onChange, groupParameters],
  )

  return (
    <DropMenu title="Условия" resetPadding>
      {groupParameters.map((_, index) => (
        <ParameterGroup
          key={index}
          name={`${name}[${index}]`}
          index={index}
          hasDelete={groupParameters.length > 1}
          onRemove={removeParameterGroup}
        />
      ))}
      <Box>
        <Button onClick={addParameterGroup}>Добавить группу условий</Button>
      </Box>
    </DropMenu>
  )
}

export default Parameters
