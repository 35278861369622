import React from 'react'

import { IOrderProduct } from 'types/types'
import renderCurrencySymbol from 'utils/renderCurrencySymbol'
import useCurrentSeller from 'hooks/useCurrentSeller'

import styles from './productItem.module.scss'

interface IProductItemHandlerProps {
  products: IOrderProduct[]
}

interface IProductItemProps {
  product: IOrderProduct
}

const ProductItemGroup: React.FC<IProductItemHandlerProps> = ({ products }) => {
  const { name, vendor_code } = products[0]

  const { settings } = useCurrentSeller()

  return (
    <div className={styles.container}>
      <div className={styles.infoGroup}>
        <p className={styles.name}>{name}</p>
        <p className={styles.vendorCode}>Арт. {vendor_code}</p>
      </div>
      <div className={styles.multiGroup}>
        {products.map((product, index) => (
          <div key={product.id} className={styles.multiItem}>
            <p className={styles.multiOnOrder}>{product.on_order ? 'Под заказ' : 'В наличии'}</p>
            <p className={styles.multiPrice}>
              {index === 0 && (
                <span>
                  {product.price} {renderCurrencySymbol(settings?.currency)}
                </span>
              )}
            </p>
            <p className={styles.multiAmount}>{product.amount}</p>
            <p className={styles.multiTotalPrice}>
              {(Number(product.price) * product.amount).toFixed(2).replace('.00', '')}{' '}
              {renderCurrencySymbol(settings?.currency)}
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}

const ProductItem: React.FC<IProductItemProps> = ({ product }) => {
  const { name, vendor_code, amount = 0, price = 0, on_order = false } = product

  const { settings } = useCurrentSeller()

  return (
    <div className={styles.container}>
      <div className={styles.infoGroup}>
        <p className={styles.name}>{name}</p>
        <p className={styles.vendorCode}>Арт. {vendor_code}</p>
      </div>
      <div className={styles.onOrder}>{on_order ? 'Под заказ' : 'В наличии'}</div>
      <div className={styles.price}>
        {price} {renderCurrencySymbol(settings?.currency)}
      </div>
      <div className={styles.amount}>{amount}</div>
      <div className={styles.totalCost}>
        {(Number(price) * amount).toFixed(2).replace('.00', '')} {renderCurrencySymbol(settings?.currency)}
      </div>
    </div>
  )
}

const ProductItemHandler: React.FC<IProductItemHandlerProps> = ({ products }) => {
  if (products.length > 1) {
    return <ProductItemGroup products={products} />
  }
  return <ProductItem product={products[0]} />
}

export default ProductItemHandler
