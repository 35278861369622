import { buildGetSellerQuery } from 'graphql/queries/getSeller'
import { buildSetSetSellerQuery } from 'graphql/mutations/setSeller'

export const GET_SELLER = buildGetSellerQuery(`
  id
  name
  company
  industry
  description
  logo
  icon
  address
  phone

  settings {
    time_works {
      end
      name
      start
    }
    socials {
      link
      name
    }
  }
`)

export const SET_SELLER = buildSetSetSellerQuery(`
  id
  name
`)
