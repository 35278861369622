import React from 'react'

import TimeIcon from 'components/Icons/TimeIcon'
import AttentionIcon from 'components/Icons/AttentionIcon'
import CrossSmallIcon from 'components/Icons/CrossSmallIcon'
import IconButton from 'components/IconButton'

import styles from './loadFileItem.module.scss'

interface ILoadFileItemProps {
  file: File
  loading: boolean
  error: string | null
  onDelete: () => void
}

const LoadFileItem: React.FC<ILoadFileItemProps> = ({ file, loading, error, onDelete }) => {
  if (loading) {
    return (
      <div className={styles.container}>
        <TimeIcon className={styles.timeIcon} color="disabled" />
        <div>
          <p className={styles.loadingTitle}>{file.name}</p>
          <p className={styles.loadingSubtitle}>Идет загрузка...</p>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <AttentionIcon className={styles.attentionIcon} color="error" />
      <p className={styles.errorTitle}>{file.name}</p>
      <p className={styles.errorMessage}> {error} </p>
      <IconButton onClick={onDelete}>
        <CrossSmallIcon color="disabled" />
      </IconButton>
    </div>
  )
}

export default LoadFileItem
