import React from 'react'
import { useMutation } from '@apollo/client'

import {
  buildSetSellerStatusQuery,
  ISetSellerStatus,
  ISetSellerStatusParams,
} from 'graphql/mutations/setSellerStatus'
import { Seller } from 'types/types'
import Switch from 'components/Switch'
import Text from 'components/Typography'
import useNotifyCms from 'hooks/useNotifyCms'
import useCmsParams from 'hooks/useCmsParams'

type IPublishStoreProps = {
  seller: Seller
}

const SET_SELLER_STATUS = buildSetSellerStatusQuery(`id status`)

const PublishStore: React.FC<IPublishStoreProps> = ({ seller }) => {
  const { status = false } = seller

  const { sellerId } = useCmsParams()
  const addNotify = useNotifyCms()

  const [onSetSellerStatus] = useMutation<ISetSellerStatus, ISetSellerStatusParams>(SET_SELLER_STATUS, {
    variables: {
      id: sellerId,
      status: !status,
    },
    onCompleted: () => addNotify('success'),
    onError: () => addNotify('error'),
    update(cache, { data: resData }) {
      if (!resData?.setSellerStatus) throw new Error('Something went wrong')

      cache.modify({
        broadcast: false,
        fields: {
          // Вызывает обновление всех getSeller
          getSeller(currentSeller: Seller) {
            return {
              ...currentSeller,
              status: resData.setSellerStatus.status,
            }
          },
        },
      })
    },
  })

  return (
    <Switch
      checked={status}
      onChange={() => onSetSellerStatus()}
      label={<Text>Опубликовать магазин</Text>}
      placement="right"
      gap="medium"
    />
  )
}

export default PublishStore
