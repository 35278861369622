import React from 'react'

import {
  useSetSellerIndividualOrderStatus,
  buildSetSellerIndividualOrderStatusQuery,
} from 'graphql/mutations/setSellerIndividualOrderStatus'
import { Seller } from 'types/types'
import Switch from 'components/Switch'
import Text from 'components/Typography'
import useCmsParams from 'hooks/useCmsParams'
import useNotifyCms from 'hooks/useNotifyCms'

type IIndividualOrderProps = {
  individual_order: Seller['individual_order']
}

const SET_SELLER_INDIVIDUAL_ORDER_QUERY = buildSetSellerIndividualOrderStatusQuery(`
  id
  individual_order
`)

const IndividualOrder: React.FC<IIndividualOrderProps> = ({ individual_order = false }) => {
  const { sellerId } = useCmsParams()
  const addNotify = useNotifyCms()

  const [onSetSellerIndividualOrder] = useSetSellerIndividualOrderStatus(SET_SELLER_INDIVIDUAL_ORDER_QUERY, {
    variables: {
      id: sellerId,
      individual_order: !individual_order,
    },
    onCompleted: () => addNotify('success'),
    onError: () => addNotify('error'),
    update(cache, { data: resData }) {
      console.log('resData :>> ', resData)
      if (!resData?.setSellerIndividualOrderStatus) throw new Error('Something went wrong')

      cache.modify({
        fields: {
          getSeller(seller: Seller) {
            return {
              ...seller,
              ...resData.setSellerIndividualOrderStatus,
            }
          },
        },
      })
    },
  })

  return (
    <Switch
      checked={individual_order}
      onChange={() => onSetSellerIndividualOrder()}
      label={<Text>Разрешить заказы от физических лиц</Text>}
      placement="right"
      gap="medium"
    />
  )
}

export default IndividualOrder
