import React from 'react'

import Button from 'components/Button'
import Text from 'components/Typography'
import ResultTitle from 'components/ResultTitle'
import PageContainer from 'components/PageContainer'

import styles from './empty.module.scss'

type IEmptyProps = {
  onClick: () => void
}

const Empty: React.FC<IEmptyProps> = ({ onClick }) => (
  <PageContainer>
    <div className={styles.wrapper}>
      <ResultTitle>Здесь будут группы вашего магазина</ResultTitle>
      <Text className={styles.text}> У вас пока нет ни одной группы. Создайте первую группу!</Text>
      <Button onClick={onClick}>Создать группу</Button>
    </div>
  </PageContainer>
)

export default Empty
