import React, { useCallback } from 'react'

import Text from 'components/Typography'
import Link from 'components/Link'
import ListControlGroup from 'components/ListControlGroup'
import EditIcon from 'components/Icons/EditIcon'
import TrashIcon from 'components/Icons/TrashIcon'
import DeleteStore from 'components/ModelControls/DeleteStore'
import { IStore } from 'types/types'
import useBoolean from 'hooks/useBoolean'
import EditStoreDialog from 'components/Dialogs/EditStoreDialog'

import styles from './store.module.scss'

type IStoreProps = {
  store: IStore
  refetchList: () => void
}

const Store: React.FC<IStoreProps> = ({ store, refetchList }) => {
  const [isEditOpen, setIsEditOpen] = useBoolean(false)

  const onEditSuccess = useCallback(() => {
    refetchList()
    setIsEditOpen.off()
  }, [refetchList, setIsEditOpen])

  return (
    <div className={styles.container}>
      <Text className={styles.name}>{store.name}</Text>
      <ListControlGroup>
        <Link component="button" startIcon={<EditIcon />} onClick={setIsEditOpen.on}>
          Редактировать
        </Link>
        <DeleteStore
          storeId={store.id}
          onSuccess={refetchList}
          render={({ onClick }) => (
            <Link component="button" startIcon={<TrashIcon />} onClick={onClick}>
              Удалить
            </Link>
          )}
        />
      </ListControlGroup>

      <EditStoreDialog
        isOpen={isEditOpen}
        initialValues={store}
        onClose={setIsEditOpen.off}
        onSuccess={onEditSuccess}
      />
    </div>
  )
}

export default Store
