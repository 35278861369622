import React from 'react'

import { Attribute as IAttribute } from 'types/types'
import { POSITION_INPUT_LIMIT, VENDOR_CODE_INPUT_LIMIT } from 'consts'
import SwitchInput from 'components/Inputs/SwitchInput'
import TextInput from 'components/Inputs/TextInput'
import IconButton from 'components/IconButton'
import TrashIcon from 'components/Icons/TrashIcon'
import useCurrentSeller from 'hooks/useCurrentSeller'

import ProductAttribute from './components/ProductAttribute'
import UploadPhotoInput from './components/UploadPhotoInput'
import StoresInputs from './components/StoresInputs'

import styles from './productVariant.module.scss'

type IProductVariantProps = {
  name: string
  hasRemove: boolean
  attributesHash: Record<string, IAttribute>
  onRemoveClick: () => void
}

const vendorCodeInputProps = { maxLength: VENDOR_CODE_INPUT_LIMIT }
const positionCodeInputProps = { maxLength: POSITION_INPUT_LIMIT }

const ProductVariant: React.FC<IProductVariantProps> = ({
  name,
  hasRemove,
  attributesHash,
  onRemoveClick,
}) => {
  const { stores_status } = useCurrentSeller()

  return (
    <tr className={styles.tableBodyRow}>
      <td>
        <UploadPhotoInput name={`${name}.images`} />
      </td>
      <td>
        <div className={styles.switch}>
          <SwitchInput name={`${name}.status`} />
        </div>
      </td>
      <td>
        <div className={styles.switch}>
          <SwitchInput name={`${name}.on_order`} />
        </div>
      </td>
      <td>
        <TextInput
          name={`${name}.vendor_code`}
          className={styles.mediumInput}
          placeholder="Артикул"
          inputProps={vendorCodeInputProps}
        />
      </td>
      <td>
        <TextInput
          name={`${name}.number`}
          className={styles.mediumInput}
          inputProps={positionCodeInputProps}
        />
      </td>
      {stores_status ? (
        <StoresInputs name={name} />
      ) : (
        <>
          <td>
            <TextInput name={`${name}.amount`} className={styles.mediumInput} placeholder="Общий остаток" />
          </td>
          <td>
            <TextInput
              name={`${name}.amount_wait`}
              className={styles.mediumInput}
              placeholder="Ожидается поступл."
            />
          </td>
          <td>
            <TextInput name={`${name}.multiply`} className={styles.mediumInput} placeholder="Кратность" />
          </td>
        </>
      )}

      <td>
        <TextInput name={`${name}.price`} className={styles.mediumInput} placeholder="Цена" />
      </td>
      <td>
        <TextInput name={`${name}.weight.value`} className={styles.smallInput} placeholder="Вес" />
      </td>
      <td>
        <TextInput name={`${name}.volume.value`} className={styles.smallInput} placeholder="Объем" />
      </td>
      <td>
        <TextInput name={`${name}.length.value`} className={styles.smallInput} placeholder="Длина" />
      </td>
      <td>
        <TextInput name={`${name}.width.value`} className={styles.smallInput} placeholder="Ширина" />
      </td>
      <td>
        <TextInput name={`${name}.height.value`} className={styles.smallInput} placeholder="Высота" />
      </td>

      <td>
        <ProductAttribute name={`${name}.product_attribute`} attributesHash={attributesHash} />
      </td>

      <td>
        {hasRemove ? (
          <IconButton onClick={onRemoveClick}>
            <TrashIcon color="action" />
          </IconButton>
        ) : null}
      </td>
    </tr>
  )
}

export default ProductVariant
