import React from 'react'
import clsx from 'clsx'

import { ReactComponent as Star } from 'assets/img/star.svg'

import styles from './UiLayout.module.scss'

type IUiLayoutProps = {
  layoutClassName?: string
  spanClassName?: string
  spanText: string
  isRequired?: boolean
}

// TODO: refactor existing use cases and remove this component
const UiLayout: React.FC<IUiLayoutProps> = ({
  children,
  spanText,
  layoutClassName = '',
  spanClassName = '',
  isRequired = false,
}) => (
  <div className={clsx(styles.container, layoutClassName)}>
    <p className={clsx(styles.label, spanClassName)}>
      {spanText}
      {isRequired && (
        <span className={styles.sup}>
          <Star />
        </span>
      )}
    </p>
    {children}
  </div>
)

export default UiLayout
