import React, { memo, useCallback, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'

import {
  IGetBaseProduct,
  IGetBaseProductParams,
  buildGetBaseProductQuery,
} from 'graphql/queries/getBaseProduct'
import Preloader from 'components/Preloader'
import GoBackLink from 'components/GoBackLink'
import ResultTitle from 'components/ResultTitle'
import PageContainer from 'components/PageContainer'
import Tabs from 'components/Tabs'
import Tab from 'components/Tab'
import ApiError from 'components/ApiError'
import withErrorBoundary from 'hocs/withErrorBoundary'
import useCmsParams from 'hooks/useCmsParams'
import { ReactComponent as Lock } from 'assets/img/lock_icon.svg' // NOTE

import BaseFields from './components/BaseFields'
import Variants from './components/Variants'
import Controls from './components/Controls'

import styles from './baseProductEdit.module.scss'

const variants = `
  product_variant_attributes {
    id 
    attribute { id name }
    attribute_values { id value attribute_id }
  }
`

const variantsProduct = `
  images { id name path }
  product_attributes {
    id
    attribute { id name }
    attribute_value { id value attribute_id }
  }
`

export const GET_BASE_PRODUCT = buildGetBaseProductQuery(`
  id
  name
  category_id
  status
  variants_status

  ${variants}

  images {
    id
    path
    name
  }
  products {
    id
    name
    vendor_code
    status
    price
    prices {
      id
      price
      name
      default_price
    }
    on_order
    amount
    amount_wait
    multiply
    description
    number
    volume { value type }
    weight { value type }
    length { value type }
    width { value type }
    height { value type }
    stores {
      id
      name
      amount
      amount_wait
      multiply
    }

    ${variantsProduct}
  }
  base_product_attributes {
    id
    attribute { id name }
    attribute_values { id value attribute_id }
  }
  related_products {
    id
    base_product_id
    name
    vendor_code
    amount
    images {
      id
      path
      name
    }
  }
  links {
    name
    url
  }
`)

const BaseProductEdit: React.FC = () => {
  const { sellerId, productId } = useCmsParams()

  const [isDisableVariant] = useState<boolean>(false)
  const [selectedTab, setSelectedTab] = useState<'baseFields' | 'variants'>('baseFields')

  const { data, loading, error, refetch } = useQuery<IGetBaseProduct, IGetBaseProductParams>(
    GET_BASE_PRODUCT,
    {
      variables: { base_id: productId },
      fetchPolicy: 'network-only',
    },
  )

  const baseProduct = useMemo(() => data?.getBaseProduct || null, [data])

  const onTabChange = useCallback(
    (_: any, newValue: 'baseFields' | 'variants') => setSelectedTab(newValue),
    [],
  )

  if (loading) {
    return (
      <PageContainer>
        <GoBackLink href={`/${sellerId}/catalog/products`} />
        <Preloader />
      </PageContainer>
    )
  }

  if (error) {
    return (
      <PageContainer>
        <GoBackLink href={`/${sellerId}/catalog/products`} />
        <ApiError error={error} />
      </PageContainer>
    )
  }

  if (!baseProduct) {
    return (
      <PageContainer>
        <GoBackLink href={`/${sellerId}/catalog/products`} />
        <ResultTitle>Товар не найден</ResultTitle>
      </PageContainer>
    )
  }

  return (
    <PageContainer>
      <GoBackLink href={`/${sellerId}/catalog/products`} />
      <ResultTitle>{baseProduct?.name}</ResultTitle>
      <div>
        <Tabs className={styles.tabs} value={selectedTab} onChange={onTabChange}>
          <Tab label="Общие настройки" value="baseFields" />
          <Tab
            label={
              <div className={styles.variantsTabContent}>
                <span>Варианты товара</span>
                {isDisableVariant && <Lock className={styles.lockIcon} />}
              </div>
            }
            value="variants"
            isAccent={!isDisableVariant}
            disabled={isDisableVariant}
          />
        </Tabs>
        {selectedTab === 'baseFields' ? (
          <BaseFields baseProduct={baseProduct} onSuccess={refetch} renderControls={() => <Controls />} />
        ) : (
          <Variants
            baseProduct={baseProduct}
            refetchBaseProduct={refetch}
            renderControls={() => <Controls />}
          />
        )}
      </div>
    </PageContainer>
  )
}

export default withErrorBoundary(memo(BaseProductEdit))
