// @ts-nocheck

const time = Number(Date.now())
const isProd = process.env.REACT_APP_ENV === 'prod'

// Регистрация, авторизация на сайте Sellty, изменение профиля, выход из профиля
export const userEvents = (action: string): void => {
  if (!isProd) return
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    userID: localStorage.getItem('profileID'),
    idhash: localStorage.getItem('profileEmail'),
    event_timestamp: time,
    event_action: action, // reg|auth|change_profile|logout
    event: 'userid_send',
  })
}

// Клик на ссылку регистрации на странице авторизации
export const clickOnSignUpLinkEvent = (): void => {
  if (!isProd) return
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event_timestamp: time,
    event_action: 'auth',
    event: 'reg_link_click',
  })
}

// Клик на ссылку авторизации на странице регистрации
export const clickOnSignInLinkEvent = (): void => {
  if (!isProd) return
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event_timestamp: time,
    event_action: 'reg',
    event: 'auth_link_click',
  })
}

// Клик на “Мои сайты” в шапке сайта
export const clickOnMySitesInHeaderEvent = (): void => {
  if (!isProd) return
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event_timestamp: time,
    event_action: 'mysites',
    event: 'bottom_menu_click',
  })
}

// Клик на “Профиль” в шапке сайта
export const clickOnProfileInHeaderEvent = (): void => {
  if (!isProd) return
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event_timestamp: time,
    event_action: 'profile',
    event: 'bottom_menu_click',
  })
}

// Клик на кнопку “создать сайт” на стартовом экране
export const clickOnCreateSiteOnStartPageEvent = (): void => {
  if (!isProd) return
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event_timestamp: time,
    event: 'start_page_create_site_click',
  })
}

// Submit создания сайта
export const siteCreationEvent = (
  siteName: string | null,
  companyName: string | null,
  otrasl: string | null,
  withTestData: boolean,
  withLogo: boolean,
  actionType: string,
): void => {
  if (!isProd) return
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    userID: localStorage.getItem('profileID'),
    idhash: localStorage.getItem('profileEmail'),
    site_name: siteName, // null, если нет данных
    site_company_name: companyName, // null, если нет данных
    site_otrasl: otrasl, // null, если нет данных
    fill_test_data: withTestData ? 'yes' : 'no',
    logo_loaded: withLogo ? 'yes' : 'no',
    event_timestamp: time,
    event_action: actionType === 'click' ? 'clck_btn' : 'form_submit', // click or submit
    event: 'start_page_site_create_success',
  })
}

// Нажатие на кнопку "панель управления" на главной странице
export const clickOnControlPanelEvent = (siteName: string, companyName: string, otrasl: string): void => {
  if (!isProd) return
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    site_name: siteName,
    site_company_name: companyName,
    site_otrasl: otrasl,
    event_timestamp: time, // UTC +3
    event: 'start_page_open_control_panel',
  })
}

// Нажатие на кнопку "открыть сайт" на главной странице
export const clickOnOpenSiteEvent = (
  siteName: string,
  domain: string,
  companyName: string,
  otrasl: string,
): void => {
  if (!isProd) return
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    site_name: siteName,
    site_domain: domain, // null если нет
    site_company_name: companyName,
    site_otrasl: otrasl,
    event_timestamp: time,
    event: 'start_page_open_public_v_site',
  })
}

// Event успешного создания товара
export const createProductEvent = (
  siteName: string | undefined | null,
  companyName: string | undefined | null,
  itemId: string,
  itemName: string | undefined | null,
  categoryId: string | undefined | null,
): void => {
  if (!isProd) return
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    userID: localStorage.getItem('profileID'),
    site_name: siteName,
    site_company_name: companyName,
    event_timestamp: time,
    item_id: itemId,
    item_name: itemName,
    category_id: categoryId,
    event: 'item_create_success',
  })
}

// Event успешного создания группы
export const createGroupEvent = (
  siteName: string | undefined | null,
  companyName: string | undefined | null,
  groupId: string | undefined | null,
  groupName: string | undefined | null,
): void => {
  if (!isProd) return
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    userID: localStorage.getItem('profileID'),
    site_name: siteName,
    site_company_name: companyName,
    event_timestamp: time,
    group_id: groupId,
    group_name: groupName,
    event: 'item_group_create_success',
  })
}

// Event успешного создания категории
export const createCategoryEvent = (
  siteName: string | undefined | null,
  companyName: string | undefined | null,
  categoryId: string | undefined | null,
  categoryName: string | undefined | null,
): void => {
  if (!isProd) return
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    userID: localStorage.getItem('profileID'),
    site_name: siteName,
    site_company_name: companyName,
    event_timestamp: time,
    category_name: categoryName,
    category_id: categoryId,
    event: 'item_category_create_success',
  })
}
