import { DocumentNode, gql } from '@apollo/client'

import type { Category, ICategoryInput } from 'types/types'

export type IAddCategory = {
  addCategory: Category
}

export type IAddCategoryParams = {
  seller_id: IPrimaryKey
  input: ICategoryInput
}

export const buildAddCategoryQuery = (fields: string): DocumentNode => gql`
  mutation addCategory($seller_id: ID!, $input: CategoryInput) {
    addCategory(seller_id: $seller_id, input: $input) {
      ${fields}
    }
  }
`
