import { Overrides } from '@material-ui/core/styles/overrides'

const typography: Overrides['MuiTypography'] = {
  h1: {
    lineHeight: '48px',
    letterSpacing: 0,
  },
  h2: {
    lineHeight: '38px',
    letterSpacing: 0,
  },
  h3: {
    lineHeight: '29px',
    letterSpacing: 0,
  },
  h4: {
    fontWeight: 'bold',
    lineHeight: '22px',
    letterSpacing: 0,
  },
  body1: {
    lineHeight: '22px',
    letterSpacing: 0,
  },
  colorTextSecondary: {
    color: '#999CA6',
  },
}

export default typography
