import React, { ChangeEvent, SyntheticEvent, useCallback, useMemo } from 'react'
import clsx from 'clsx'

import Link from 'components/Link'
import UploadIcon from 'components/Icons/UploadIcon'
import createUniqId from 'utils/createUniqId'

import styles from './dragAndDropField.module.scss'

type IValueItem = { path?: string; flag?: string } | File | null

export type IDragAndDropProps = {
  className?: string
  filesFormat?: string
  value: IValueItem
  onChange: (value: File) => void
  onDelete: () => void
  renderSelectedItem?: (props: { item: any; onDelete: () => void }) => React.ReactNode
}

const preventsDefaults = (e: SyntheticEvent) => {
  e.preventDefault()
  e.stopPropagation()
}

type IImageProps = {
  className?: string
  image: { path?: string; flag?: string } | File
  onDelete: () => void
}

const Image: React.FC<IImageProps> = ({ className, image, onDelete }) => {
  const src = image instanceof File ? URL.createObjectURL(image) : image.path

  return (
    <div className={className}>
      <div className={styles.imageGroup}>
        <img className={styles.image} src={src} alt="" />
      </div>
      <Link component="button" className={styles.imageButton} onClick={onDelete}>
        Удалить
      </Link>
    </div>
  )
}

const defaultRender = ({
  item,
  onDelete,
}: {
  item: { path?: string | undefined; flag?: string | undefined } | File
  onDelete: () => void
}) => <Image image={item} onDelete={onDelete} />

// TODO: Check how it renders in different places with no images
const DragAndDropField: React.FC<IDragAndDropProps> = ({
  className,
  filesFormat = '*',
  value,
  onChange,
  onDelete,
  renderSelectedItem = defaultRender,
}) => {
  const id = useMemo(() => createUniqId(), [])

  const handleFiles = useCallback(
    (files: FileList) => {
      const [image] = Object.values(files)
      onChange(image)
    },
    [onChange],
  )

  const onChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault()
      if (!event.currentTarget.files) return
      handleFiles(event.currentTarget.files)
      if (event.target.value) event.target.value = ''
    },
    [handleFiles],
  )

  const onDropHandler = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      handleFiles(event.dataTransfer.files)
    },
    [handleFiles],
  )

  const onDragLeaveHandler = useCallback((event: SyntheticEvent) => {
    preventsDefaults(event)
  }, [])

  return (
    <div
      className={clsx(styles.container, className)}
      onDragOver={preventsDefaults}
      onDragEnter={preventsDefaults}
      onDrop={onDropHandler}
      onDragLeave={onDragLeaveHandler}
    >
      {value instanceof File || !!value?.path ? (
        renderSelectedItem({ item: value, onDelete })
      ) : (
        <div className={styles.defaultContent}>
          <UploadIcon className={styles.loadIcon} fontSize="large" />
          <div>
            <label htmlFor={id} className={styles.textAccent}>
              <input
                id={id}
                type="file"
                name="file"
                accept={filesFormat}
                multiple={false}
                className={styles.hiddenInput}
                onChange={onChangeHandler}
              />
              Выберите файл
            </label>{' '}
            <span>или перетащите его в эту область</span>
          </div>
        </div>
      )}
    </div>
  )
}

export default DragAndDropField
