import React from 'react'
import { useMutation } from '@apollo/client'

import {
  buildSetSellerStatusIndexQuery,
  ISetSellerStatusIndex,
  ISetSellerStatusIndexParams,
} from 'graphql/mutations/setSellerStatusIndex'
import { Seller } from 'types/types'
import Switch from 'components/Switch'
import Text from 'components/Typography'
import useNotifyCms from 'hooks/useNotifyCms'
import useCmsParams from 'hooks/useCmsParams'

type IStatusIndexProps = {
  seller: Seller
}

const SET_SELLER_STATUS_INDEX = buildSetSellerStatusIndexQuery(`id status_index`)

const StatusIndex: React.FC<IStatusIndexProps> = ({ seller }) => {
  const { status_index = false } = seller

  const { sellerId } = useCmsParams()
  const addNotify = useNotifyCms()

  const [onSetSellerStatusIndex] = useMutation<ISetSellerStatusIndex, ISetSellerStatusIndexParams>(
    SET_SELLER_STATUS_INDEX,
    {
      variables: { id: sellerId, status_index: !status_index },
      onCompleted: () => addNotify('success'),
      onError: () => addNotify('error'),
      update(cache, { data: resData }) {
        if (!resData?.setSellerStatusIndex) throw new Error('Something went wrong')

        cache.modify({
          broadcast: false,
          fields: {
            // Вызывает обновление всех getSeller
            getSeller(currentSeller: Seller) {
              return {
                ...currentSeller,
                status_index: resData.setSellerStatusIndex.status_index,
              }
            },
          },
        })
      },
    },
  )

  return (
    <Switch
      checked={status_index}
      onChange={() => onSetSellerStatusIndex()}
      label={<Text>Разрешить индексацию</Text>}
      placement="right"
      gap="medium"
    />
  )
}

export default StatusIndex
