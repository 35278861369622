import React from 'react'
import MuiRadioGroup, { RadioGroupProps } from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormHelperText from '@material-ui/core/FormHelperText'

export type IRadioGroupProps = Omit<RadioGroupProps, 'onChange'> & {
  error?: boolean
  title?: string
  helperText?: string | null
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, value: any) => void
}

const RadioGroup: React.FC<IRadioGroupProps> = ({ error, className, title, helperText, ...props }) => (
  <FormControl error={error} className={className}>
    {title && <FormLabel error={error}>{title}</FormLabel>}
    <MuiRadioGroup {...props} />
    {helperText && <FormHelperText>{helperText}</FormHelperText>}
  </FormControl>
)

export default RadioGroup
