import React, { FC } from 'react'

import Text, { H1 } from 'components/Typography'
import useCmsLinks from 'hooks/useCmsLinks'
import Link from 'components/Link'
import Button from 'components/Button'

import styles from './empty.module.scss'

const Empty: FC = () => {
  const { priceRuleCreateLink } = useCmsLinks()
  return (
    <div className={styles.container}>
      <H1 className={styles.emptyTitle}>Здесь будут ценовые группы вашего магазина</H1>
      <Text className={styles.emptySubtitle}>У вас пока нет ни одной ценовой группы. Создайте первую!</Text>
      <Link href={priceRuleCreateLink}>
        <Button>Создать ценовую группу</Button>
      </Link>
    </div>
  )
}

export default Empty
