import React, { ChangeEvent, FC, SyntheticEvent, useState } from 'react'

import type { ImageInput } from 'types/types'
import { ReactComponent as LoadfileIcon } from 'assets/img/loadfileIcon.svg'
import createUrlFromUploadedFile from 'helpers/createUrlFromUploadedFile'
import block from 'utils/block'

import styles from './DragAndDrop.module.scss'

type IDragAndDropType = {
  helperText?: string
  className?: string
  title?: string
  filesFormat?: string
  zoneVariant?: 'big'
  zoneClassName?: string
  onChange?: (image: ImageInput | undefined) => void
  onChangeFile?: (file: Blob | null) => void
  image?: ImageInput | string
  simpleForm?: boolean
}

const b = block(styles)

const getPath = (image: IDragAndDropType['image']): string => {
  if (!image) {
    return ''
  }

  if (typeof image === 'string') {
    return image
  }

  if (!image.path) {
    return ''
  }

  return image.path
}

const DragAndDrop: FC<IDragAndDropType> = ({
  helperText,
  className,
  title,
  zoneVariant,
  zoneClassName,
  filesFormat = '.png, .jpg',
  simpleForm = false,
  image,
  onChangeFile,
  onChange = () => {},
}) => {
  const [isCvsUploaded, setIsCvsUploaded] = useState(false)
  const [fileName, setFileName] = useState('')

  const deleteLogo = () => {
    if (typeof image === 'string') {
      onChange(undefined)

      return
    }

    onChange({
      flag: 'delete',
    })
  }

  const preventsDefaults = (e: SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const onDragEnterHandler = (e: SyntheticEvent) => {
    preventsDefaults(e)
  }

  const onDargOverHandler = (e: SyntheticEvent) => {
    preventsDefaults(e)
  }

  const handleFiles = async (file: File) => {
    if (onChangeFile) {
      setIsCvsUploaded(true)

      setFileName(file.name)
      onChangeFile(file)
      return
    }

    const [img] = await createUrlFromUploadedFile([file], { type: 'images' })

    onChange(img)
  }

  const onChangeHandler = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()

    if (!e.currentTarget.files) {
      return
    }

    const { 0: file } = e.currentTarget.files

    handleFiles(file)
  }

  const onDropHandler = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()

    if (!e.dataTransfer.files) {
      return
    }

    const { 0: file } = e.dataTransfer.files

    handleFiles(file)
  }

  const onDragLeaveHandler = (e: SyntheticEvent) => {
    preventsDefaults(e)
  }

  const path = getPath(image)

  const deleteFile = () => {
    if (onChangeFile) onChangeFile(null)
    setIsCvsUploaded(false)
    setFileName('')
  }

  return (
    <div className={b('container', className)}>
      {title ? <p className={styles.title}>{title}</p> : null}
      {path || isCvsUploaded ? (
        isCvsUploaded ? (
          <div className={b('dropZone', { variant: zoneVariant }, zoneClassName)}>
            <p>{fileName}</p>
            <p onClick={deleteFile} className={styles.deleteButton} aria-hidden="true">
              Удалить
            </p>
          </div>
        ) : (
          <div className={b('dropZone', { variant: zoneVariant }, zoneClassName)}>
            <img src={path} alt="" className={styles.logoImage} />
            <p onClick={deleteLogo} className={styles.deleteButton} aria-hidden="true">
              Удалить
            </p>
          </div>
        )
      ) : (
        <div
          className={b('dropZone', { variant: zoneVariant }, zoneClassName)}
          onDragOver={onDargOverHandler}
          onDragEnter={onDragEnterHandler}
          onDrop={onDropHandler}
          onDragLeave={onDragLeaveHandler}
        >
          <LoadfileIcon className={styles.dropZoneIcon} />
          <div className={styles.dropZoneIconTextWrapper}>
            <label htmlFor="file-input" className={styles.DragAndDrop__dropZoneText_blue}>
              <input
                id="file-input"
                type="file"
                name="file"
                accept={filesFormat}
                className={styles.dropZoneInput}
                onChange={onChangeHandler}
              />
              {!simpleForm ? 'Выберите файл' : ''}
            </label>
            {!simpleForm && (
              <p className={styles.DragAndDrop__dropZoneText}>или перетащите его в эту область</p>
            )}
          </div>
        </div>
      )}
      {helperText ? <p className={styles.helperText}>{helperText}</p> : null}
    </div>
  )
}

export default DragAndDrop
