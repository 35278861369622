import { buildGetAttributesQuery } from 'graphql/queries/getAttributes'
import { buildGetCategoriesQuery } from 'graphql/queries/getCategories'
import { buildGetGroupsQuery } from 'graphql/queries/getGroups'
import { buildGetPriceListsQuery } from 'graphql/queries/getPriceLists'

export const GET_PRICE_LISTS = buildGetPriceListsQuery(`
  id
  name
  default_price
`)

export const GET_ATTRIBUTES = buildGetAttributesQuery(`
  id
  name
  attribute_values {
    id
    attribute_id
    value
  }
`)

export const GET_CATEGORIES = buildGetCategoriesQuery(`
  id
  name
`)

export const GET_GROUPS = buildGetGroupsQuery(`
  id
  name
`)
