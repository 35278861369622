import React, { ReactNode } from 'react'
import { Formik, FormikHelpers } from 'formik'

import DropMenu from 'components/DropMenu'
import TextInput from 'components/Inputs/TextInput'
import FormControlsPanel from 'components/FormControlsPanel'
import Button from 'components/Button'
import LeavePageDirtyFormHelper from 'components/LeavePageDirtyFormHelper'
import AddSubmitShortcutFormHelper from 'components/AddSubmitShortcutFormHelper'

import { ICompanyFormValues } from './types'
import validationSchema from './utils/validationSchema'
import ContractsInput from './components/ContractsInput'

import styles from './CompanyForm.module.scss'

type ICompanyFormProps = {
  submitLoading: boolean
  initialValue: ICompanyFormValues
  onSubmit: (values: ICompanyFormValues, helpers: FormikHelpers<ICompanyFormValues>) => void
  renderControls?: () => ReactNode
}

const CompanyForm: React.FC<ICompanyFormProps> = ({
  initialValue,
  submitLoading,
  onSubmit,
  renderControls = () => {},
}) => (
  <Formik
    enableReinitialize
    initialValues={initialValue}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    {({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <LeavePageDirtyFormHelper />
        <AddSubmitShortcutFormHelper />

        <DropMenu title="Данные о компании">
          <TextInput
            name="name"
            required
            label="Компания"
            placeholder="Введите наименование компании"
            margin="default"
          />
          <TextInput name="inn" label="ИНН" placeholder="Введите ИНН" />
        </DropMenu>

        <ContractsInput name="contracts" />

        <FormControlsPanel className={styles.controlsPanel}>
          <Button type="submit" disabled={submitLoading}>
            Сохранить
          </Button>

          {renderControls()}
        </FormControlsPanel>
      </form>
    )}
  </Formik>
)

export default CompanyForm
