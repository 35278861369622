import React from 'react'
import clsx from 'clsx'

import styles from './limitText.module.scss'

export type ILimitTextProps = {
  value: string | number
  limit: number
}

const LimitText: React.FC<ILimitTextProps> = ({ value, limit }) => (
  <span
    className={clsx(styles.counter, {
      [styles.max]: typeof value === 'string' ? value.length >= limit : false,
    })}
  >
    {`${typeof value === 'string' ? value.length : 0}/${limit} символов`}
  </span>
)

export default LimitText
