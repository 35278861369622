import { IClientFormValues } from 'components/forms/ClientForm'
import { ISetClientParams } from 'graphql/mutations/setClient'
import checkDestroyItems from 'utils/checkDestroyItems'
import phoneFormatSave from 'utils/phoneFormatSave'

const prepareCompanies = (
  initCompanies: IClientFormValues['companies'],
  formCompanies: IClientFormValues['companies'],
): ISetClientParams['input']['companies'] => {
  const ensureDeletedItems = checkDestroyItems(initCompanies, formCompanies)

  return ensureDeletedItems.map(({ _delete, ...record }) => ({
    ...record,
    flag: _delete ? 'delete' : undefined,
  }))
}

const prepareSubmitValues = (
  initialValues: IClientFormValues,
  payload: IClientFormValues,
): ISetClientParams['input'] => {
  const { phone, managerId, companies, ...rest } = payload

  return {
    ...rest,
    manager_id: managerId,
    phone: phoneFormatSave(phone),
    companies: prepareCompanies(initialValues.companies, companies),
  }
}

export default prepareSubmitValues
