import { useCallback, useState } from 'react'

const addItem = <T>(value: T[], item: T): T[] => (value.includes(item) ? value : [...value, item])
const removeItem = <T>(value: T[], item: T): T[] => value.filter((member) => member !== item)

export interface IUseChoicesActions<T = string | number> {
  add: (item: T) => void
  remove: (item: T) => void
  toggle: (item: T) => void
  removeAll: () => void
  replace: (items: T[]) => void
  isSelected: (value: T) => boolean
}

const useChoises = <T = string | number>(initialValue?: T[]): [T[], IUseChoicesActions<T>] => {
  const [value, setValue] = useState<T[]>(initialValue || [])

  const isSelected = useCallback((checkId: T): boolean => value.some((id) => id === checkId), [value])

  const add = useCallback(
    (item: T) => {
      setValue((val) => addItem<T>(val, item))
    },
    [setValue],
  )

  const remove = useCallback(
    (item: T) => {
      setValue((val) => removeItem<T>(val, item))
    },
    [setValue],
  )

  const toggle = useCallback(
    (item: T) => {
      setValue((val) => (val.includes(item) ? removeItem<T>(val, item) : addItem<T>(val, item)))
    },
    [setValue],
  )

  const removeAll = useCallback(() => setValue([]), [setValue])

  return [value, { add, remove, toggle, removeAll, replace: setValue, isSelected }]
}

export default useChoises
