import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'

import Text, { H1 } from 'components/Typography'
import Dialog from 'components/Dialog'
import DialogContent from 'components/DialogContent'
import DialogCloseButton from 'components/DialogCloseButton'
import Pagination from 'components/Pagination'
import hasPagination from 'utils/hasPagination'
import { IProduct } from 'types/types'
import { buildGetProductsQuery, IGetProducts, IGetProductsParams } from 'graphql/queries/getProducts'
import useCmsParams from 'hooks/useCmsParams'
import buildPaginateParams from 'utils/buildPaginateParams'
import Preloader from 'components/Preloader'
import ApiError from 'components/ApiError'
import extractId from 'utils/extractId'
import useDebounceValue from 'hooks/useDebounceValue'
import { SEARCH_DEBOUNCE_TIMEOUT } from 'consts'

import Filters from './components/Filters'
import ProductsList from './components/ProductsList'
import { IRelatedProductsFilters } from './types'

import styles from './relatedProductsDialog.module.scss'

type IRelatedProductsDialogProps = {
  isOpen: boolean
  currentProducts: IProduct[]
  onClose: () => void
  onSave: (payload: IProduct[]) => void
}

const initFilters: IRelatedProductsFilters = {
  search: '',
  page: 1,
  categoryId: null,
}

const GET_PRODUCTS = buildGetProductsQuery(`
  id
  base_product_id
  name
  vendor_code
  price
  images {
    id
    path
  }
`)

const RelatedProductsDialog: React.FC<IRelatedProductsDialogProps> = ({
  isOpen,
  currentProducts,
  onClose,
  onSave,
}) => {
  const { sellerId } = useCmsParams()

  const [filters, setFilters] = useState<IRelatedProductsFilters>(initFilters)
  const [selectedProducts, setSelectedProducts] = useState<IProduct[]>([])

  const debouncedSearch = useDebounceValue(filters.search, SEARCH_DEBOUNCE_TIMEOUT)

  const { data, loading, error } = useQuery<IGetProducts, IGetProductsParams>(GET_PRODUCTS, {
    variables: {
      seller_id: sellerId,
      search: debouncedSearch,
      category_id: filters.categoryId,
      sort_ids: currentProducts.map(extractId),
      paginate: buildPaginateParams(filters.page),
    },
    skip: !isOpen,
    fetchPolicy: 'network-only',
  })

  const { getProducts: { elements = 0, pages = 0 } = {} } = data || {}
  const products = useMemo(() => data?.getProducts.list || [], [data])

  const clearFilters = useCallback(() => setFilters(initFilters), [])

  const onPageChange = useCallback((nextPage: number) => {
    setFilters((prev) => ({ ...prev, page: nextPage }))
  }, [])

  const addProduct = useCallback((payload: IProduct) => {
    setSelectedProducts((prev) => prev.concat(payload))
  }, [])

  const removeProduct = useCallback((payload: IPrimaryKey) => {
    setSelectedProducts((prev) => prev.filter((item) => item.id !== payload))
  }, [])

  const save = useCallback(() => {
    onSave(selectedProducts)
    onClose()
    clearFilters()
  }, [onSave, selectedProducts, onClose])

  const onCloseHandler = useCallback(() => {
    onClose()
    clearFilters()
  }, [onClose, clearFilters])

  useEffect(() => {
    if (!isOpen) return
    setSelectedProducts(currentProducts)
  }, [isOpen, currentProducts])

  return (
    <Dialog isOpen={isOpen} onClose={onCloseHandler}>
      <DialogCloseButton onClick={onCloseHandler} />
      <DialogContent className={styles.content}>
        <H1 className={styles.title}>Сопутствующие товары</H1>
        <Text className={styles.subtitle}>
          Выберите до четырех товаров, которые будут отображаться в магазине в блоке{' '}
          <b>«с этим товаром покупают»</b>
        </Text>

        <Filters filters={filters} onFiltersChange={setFilters} onClose={onCloseHandler} onSave={save} />

        <ApiError error={error} />

        {loading ? (
          <div className={styles.preloaderWrapper}>
            <Preloader variant="relative" />
          </div>
        ) : (
          <>
            <ProductsList
              products={products}
              selectedProducts={selectedProducts}
              onAdd={addProduct}
              onRemove={removeProduct}
            />

            {hasPagination(elements) ? (
              <Pagination page={filters.page} count={pages} onChange={onPageChange} />
            ) : null}
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default RelatedProductsDialog
