import { TestFunction, object, string } from 'yup'
import validator from 'validator'

import { REQUIRED_MESSAGE } from 'consts/errorMessages'
import { AnyObject } from 'yup/lib/types'

const socialTest: TestFunction<string | undefined, AnyObject> = (value, { createError }) => {
  if (value) {
    const isValid = validator.isURL(value)
    if (!isValid) return createError({ message: 'Неверный формат URL' })
    return true
  }
  return true
}

const validationSchema = () =>
  object({
    name: string()
      .required(REQUIRED_MESSAGE)
      .max(100, 'Поле название не должно содержать больше 100 символов.'),
    company: string()
      .required(REQUIRED_MESSAGE)
      .max(100, 'Поле компания не должно содержать больше 100 символов.'),
    industry: string().max(200, 'Поле отрасль не должно содержать больше 200 символов.'),
    description: string().max(500, 'Поле отрасль не должно содержать больше 500 символов.'),
    address: string().max(200, 'Поле адрес не должно содержать больше 200 символов.'),
    settings: object({
      socials: object({
        vk: string().test('vk', socialTest),
        telegram: string().test('telegram', socialTest),
        youTube: string().test('youTube', socialTest),
        whatsApp: string().test('whatsApp', socialTest),
      }),
    }),
  })

export default validationSchema
