import { gql } from '@apollo/client'
import { Status } from 'types/types'

export type ISendEmailVerifyParams = {
  seller_id?: string
  second?: boolean
}

export type ISendEmailVerify = {
  sendEmailVerify: Status
}

export const SEND_EMAIL_VERIFY = gql`
  mutation sendEmailVerify($seller_id: ID, $second: Boolean) {
    sendEmailVerify(seller_id: $seller_id, second: $second) {
      status
      message
    }
  }
`
