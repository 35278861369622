import { IPriceRuleFormValues } from 'components/forms/PriceRuleForm'
import { Attribute, IPriceRule, IPriceRuleParameter } from 'types/types'
import nonNullable from 'utils/nonNullable'

import findAttributeValueAttribute from './findAttributeValueAttribute'

const defaultParameter: IPriceRuleFormValues['groupParameters'][0]['parameters'][0] = {
  discount_ratio: '',
  discount_type: 'margin',
  parameter_type: 'ALL',
  localGroupId: null,
  localValueIds: [],
}

const ensureCorrectDiscount = (payload: IPriceRuleParameter['discount_ratio']) => {
  if (!payload) return ''
  if (payload === '0') return ''
  return payload
}

const prepareParameter =
  (attributesList: Attribute[]) =>
  ({
    discount_ratio,
    discount_type,
    parameter_type,
    destination_ids,
  }: IPriceRuleParameter): IPriceRuleFormValues['groupParameters'][0]['parameters'][0] => {
    const commonFields: Pick<
      IPriceRuleFormValues['groupParameters'][0]['parameters'][0],
      'discount_ratio' | 'discount_type' | 'parameter_type'
    > = {
      discount_ratio: ensureCorrectDiscount(discount_ratio),
      discount_type: discount_type ? 'discount' : 'margin',
      parameter_type,
    }

    if (parameter_type === 'ALL') {
      return {
        ...commonFields,
        localGroupId: null,
        localValueIds: [],
      }
    }

    if (parameter_type === 'CATEGORY' || parameter_type === 'GROUP') {
      return {
        ...commonFields,
        localGroupId: destination_ids ? destination_ids[0] ?? null : null,
        localValueIds: [],
      }
    }

    if (parameter_type === 'ATTRIBUTE') {
      const localGroupId = findAttributeValueAttribute(destination_ids || [], attributesList)
      return {
        ...commonFields,
        localGroupId,
        localValueIds: destination_ids || [],
      }
    }

    if (parameter_type === 'PRODUCT') {
      return {
        ...commonFields,
        localGroupId: null,
        localValueIds: destination_ids || [],
      }
    }

    throw new Error('Unknown parameter type')
  }

const prepareGroupParameters = (
  payload: IPriceRule['parameters_groups'],
  attributesList: Attribute[],
): IPriceRuleFormValues['groupParameters'] => {
  if (!payload || !payload.length) {
    return [{ pricelistId: null, parameters: [defaultParameter] }]
  }

  return payload.map(({ price_list, parameters }) => ({
    pricelistId: price_list?.id ?? null,
    parameters: parameters ? parameters.map(prepareParameter(attributesList)) : [defaultParameter],
  }))
}

const prepareInitialValues = (payload: IPriceRule, attributesList: Attribute[]): IPriceRuleFormValues => {
  const { name, number: position, status, add_default, client_group, parameters_groups } = payload

  const clientsIds = client_group?.clients?.map(({ id }) => id).filter(nonNullable) || []

  const groupParameters = prepareGroupParameters(parameters_groups, attributesList)

  return {
    name,
    position: position.toString(),
    status,
    addDefault: add_default ?? false,
    groupParameters,
    clientsIds,
  }
}

export default prepareInitialValues
