import React, { useMemo, memo, useCallback } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Formik, FormikHelpers } from 'formik'

import AddSubmitShortcutFormHelper from 'components/AddSubmitShortcutFormHelper'
import LeavePageDirtyFormHelper from 'components/LeavePageDirtyFormHelper'
import Button from 'components/Button'
import ResultTitle from 'components/ResultTitle'
import PageContainer from 'components/PageContainer'
import FormControlsPanel from 'components/FormControlsPanel'
import Preloader from 'components/Preloader'
import useNotifyCms from 'hooks/useNotifyCms'
import useCmsParams from 'hooks/useCmsParams'
import {
  ISetSellerSettings,
  ISetSellerSettingsParams,
  SET_SELLER_SETTINGS,
} from 'graphql/mutations/setSellerSettings'
import { IGetSeller, IGetSellerParams } from 'graphql/queries/getSeller'
import { ISetSeller, ISetSellerParams } from 'graphql/mutations/setSeller'
import withErrorBoundary from 'hocs/withErrorBoundary'
import withLeaveThisPageModal from 'hocs/withLeaveThisPageModal'
import { ImageInput } from 'types/types'
import convertToFormErrors from 'utils/convertToFormErrors'
import convertUploadedImagesToImagesInput from 'utils/convertUploadedImagesToImagesInput'
import createUrlFromUploadedFile from 'helpers/createUrlFromUploadedFile'

import GeneralInfo from './components/GeneralInfo'
import ContactsInfo from './components/ContactsInfo'

import { IGeneralFormValues } from './types'
import defaultFormValues from './consts/defaultFormValues'
import validationSchema from './utils/validationSchema'
import prepareInitialValues from './utils/prepareInitialValues'
import prepareSellerSettingsSubmit from './utils/prepareSellerSettingsSubmit'
import prepareSellerSubmit from './utils/prepareSellerSubmit'
import { GET_SELLER, SET_SELLER } from './queries'

const General: React.FC = () => {
  const addNotify = useNotifyCms()
  const { sellerId } = useCmsParams()

  const {
    data: sellerData,
    loading: sellerLoading,
    refetch,
  } = useQuery<IGetSeller, IGetSellerParams>(GET_SELLER, {
    variables: { id: sellerId },
  })

  const seller = useMemo(() => sellerData?.getSeller || null, [sellerData])

  const initialValues = useMemo(() => {
    if (!seller) return defaultFormValues
    return prepareInitialValues(seller)
  }, [seller])

  const [onSetSeller, { loading: setSellerLoading }] = useMutation<ISetSeller, ISetSellerParams>(SET_SELLER)

  const [onSetSellerSettings, { loading: setSellerSettingsLoading }] = useMutation<
    ISetSellerSettings,
    ISetSellerSettingsParams
  >(SET_SELLER_SETTINGS)

  const submitLoading = setSellerLoading || setSellerSettingsLoading

  const loadLogo = useCallback(
    async ({ name, company, logo }: IGeneralFormValues) => {
      if (logo === initialValues.logo) return 'initial logo'
      if (!logo) return 'no logo'

      let newLogo: ImageInput | null = null

      if (logo instanceof File) {
        try {
          const uploadedImgs = await createUrlFromUploadedFile([logo], { type: 'images' })
          const [convertedImg] = convertUploadedImagesToImagesInput(uploadedImgs)
          newLogo = convertedImg
        } catch (error) {
          addNotify({ kind: 'error', message: 'Ошибка загрузки логотипа' })
          return 'error'
        }
      }

      return onSetSeller({
        variables: {
          id: sellerId,
          input: { name, company, logo: newLogo ?? logo },
        },
      })
    },
    [addNotify, onSetSeller, sellerId, initialValues],
  )

  const loadIcon = useCallback(
    async ({ name, company, icon }: IGeneralFormValues) => {
      if (icon === initialValues.icon) return 'initial icon'
      if (!icon) return 'no icon'

      let newIcon: ImageInput | null = null

      if (icon instanceof File) {
        try {
          const uploadedImgs = await createUrlFromUploadedFile([icon], { type: 'icon' })
          const [convertedImg] = convertUploadedImagesToImagesInput(uploadedImgs)
          newIcon = convertedImg
        } catch (error) {
          addNotify({ kind: 'error', message: 'Ошибка загрузки иконки' })
          return 'error'
        }
      }

      return onSetSeller({
        variables: {
          id: sellerId,
          input: { name, company, icon: newIcon ?? icon },
        },
      })
    },
    [addNotify, onSetSeller, sellerId, initialValues],
  )

  const loadImages = useCallback(
    async (values: IGeneralFormValues) => {
      const onSuccess = () => {
        refetch()
        addNotify('success')
      }

      Promise.all([loadLogo(values), loadIcon(values)])
        .then(onSuccess)
        .catch(() => addNotify('error'))
    },
    [refetch, addNotify, loadLogo, loadIcon],
  )

  const onSubmit = useCallback(
    (values: IGeneralFormValues, { setErrors }: FormikHelpers<IGeneralFormValues>) =>
      Promise.all([
        onSetSeller({
          variables: {
            id: sellerId,
            input: prepareSellerSubmit(values),
          },
        }),
        onSetSellerSettings({
          variables: {
            seller_id: sellerId,
            input: prepareSellerSettingsSubmit(values),
          },
        }),
      ])
        .then(() => {
          loadImages(values)
        })
        .catch((error) => {
          addNotify('error')
          const errors = convertToFormErrors(error)
          if (errors) setErrors(errors)
        }),
    [onSetSeller, onSetSellerSettings, addNotify, sellerId, loadImages],
  )

  if (sellerLoading) return <Preloader />

  return (
    <PageContainer>
      <ResultTitle>Главное</ResultTitle>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <AddSubmitShortcutFormHelper />
            <LeavePageDirtyFormHelper />

            <GeneralInfo values={values} />
            <ContactsInfo values={values} />

            <FormControlsPanel>
              <Button type="submit" disabled={submitLoading}>
                Сохранить
              </Button>
            </FormControlsPanel>
          </form>
        )}
      </Formik>
    </PageContainer>
  )
}

export default withErrorBoundary(withLeaveThisPageModal(memo(General)))
