import React from 'react'

import Text from 'components/Typography'

import styles from './titleCounter.module.scss'

type ITitleCounterProps = {
  counter: number
}
// rename to DropMenuTitleCounter
const TitleCounter: React.FC<ITitleCounterProps> = ({ counter, children }) => {
  if (!counter) {
    return <>{children}</>
  }

  return (
    <>
      {children}{' '}
      <Text className={styles.counter} color="textSecondary" variant="inherit" component="span">
        {counter}
      </Text>
    </>
  )
}

export default TitleCounter
