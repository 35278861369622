import { useCallback, useMemo } from 'react'

import useCmsParams from 'hooks/useCmsParams'

type ILink = {
  integrations: string
  importLinksIntegration: string
  oneSIntegration: string
  paymentMethods: string
  priceListsListLink: string
  priceListCreateLink: string
  priceListEditLink: (id: IPrimaryKey) => string
  priceRulesListLink: string
  priceRuleCreateLink: string
  priceRuleEditLink: (id: IPrimaryKey) => string
}

const useCmsLinks = () => {
  const { sellerId } = useCmsParams()

  const createLink = useCallback((path: string) => `/${sellerId}/${path}`, [sellerId])

  const links: ILink = useMemo(
    () => ({
      integrations: `/${sellerId}/integrations`,
      importLinksIntegration: `/${sellerId}/integrations/import-links`,
      oneSIntegration: `/${sellerId}/integrations/one-s`,
      paymentMethods: `/${sellerId}/integrations/payment-methods`,
      priceListsListLink: createLink('catalog/price-lists'),
      priceListCreateLink: createLink('catalog/price-lists/create'),
      priceListEditLink: (id: IPrimaryKey) => createLink(`catalog/price-lists/${id}`),
      priceRulesListLink: createLink('price-rules'),
      priceRuleCreateLink: createLink('price-rules/create'),
      priceRuleEditLink: (id: IPrimaryKey) => createLink(`price-rules/${id}`),
    }),
    [sellerId, createLink],
  )

  return links
}

export default useCmsLinks
