import { gql, MutationHookOptions, useMutation } from '@apollo/client'

import { Status } from 'types/types'

export type ITruncateBaseProducts = {
  truncateBaseProducts: Status
}

export type ITruncateBaseProductsParams = {
  seller_id: string
}

export const TRUNCATE_BASE_PRODUCTS = gql`
  mutation truncateBaseProducts($seller_id: ID!) {
    truncateBaseProducts(seller_id: $seller_id) {
      status
      message
    }
  }
`

export const useTruncateBaseProducts = <
  TData = ITruncateBaseProducts,
  TOptions = ITruncateBaseProductsParams,
>(
  options?: MutationHookOptions<TData, TOptions>,
) => useMutation<TData, TOptions>(TRUNCATE_BASE_PRODUCTS, options)
