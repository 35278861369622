import { buildGetPriceListQuery } from 'graphql/queries/getPriceList'
import { buildSetPriceListQuery } from 'graphql/mutations/setPriceList'
import { buildGetPriceListsQuery } from 'graphql/queries/getPriceLists'

const commonFields = `
  id
  name
  default_price
  show_prices
`

export const GET_PRICE_LIST = buildGetPriceListQuery(commonFields)

export const SET_PRICE_LIST = buildSetPriceListQuery(commonFields)

export const GET_PRICE_LISTS = buildGetPriceListsQuery(commonFields)
