import React from 'react'

import Header from 'components/Header'
import Container from 'components/Container'
import NotifyList from 'components/NotifyList'

const Layout: React.FC = ({ children }) => (
  <>
    <Header />
    <Container>{children}</Container>
    <NotifyList />
  </>
)

export default Layout
