import React from 'react'
import { NavLink } from 'react-router-dom'

import styles from './SubGroupLink.module.scss'

type ISubGroupLinkProps = {
  groupHref: string
  href: string
}

const SubGroupLink: React.FC<ISubGroupLinkProps> = ({ groupHref, href, children }) => (
  <NavLink
    isActive={() => Boolean(window.location.pathname.includes(groupHref))}
    to={href}
    className={styles.link}
    activeClassName={styles.active}
  >
    <span className={styles.inner}>
      <span className={styles.subgroupMdash} />
      {children}
    </span>
  </NavLink>
)

export default SubGroupLink
