import React, { memo, useMemo } from 'react'

import { IProduct } from 'types/types'

import Product from '../Product'
import { MAX_RELATED_PRODUCTS } from '../../consts'

type IProductsListProps = {
  products: IProduct[]
  selectedProducts: IProduct[]
  onAdd: (payload: IProduct) => void
  onRemove: (payload: IPrimaryKey) => void
}

const ProductsList: React.FC<IProductsListProps> = ({ products, selectedProducts, onAdd, onRemove }) => {
  const disabledAddMore = selectedProducts.length === MAX_RELATED_PRODUCTS

  const selectedProductsIdsHash = useMemo(() => {
    const idHash: Record<string, true> = {}

    selectedProducts.forEach((item) => {
      idHash[item.id] = true
    })

    return idHash
  }, [selectedProducts])

  return (
    <>
      {products.map((product) => (
        <Product
          key={product.id}
          disabledAddMore={!selectedProductsIdsHash[product.id] && disabledAddMore}
          isActive={selectedProductsIdsHash[product.id]}
          product={product}
          onAdd={onAdd}
          onRemove={onRemove}
        />
      ))}
    </>
  )
}

export default memo(ProductsList)
