import { DocumentNode, gql, useQuery, QueryHookOptions } from '@apollo/client'

import { IPriceList } from 'types/types'

export type IGetPriceList = {
  getPriceList: IPriceList
}

export type IGetPriceListParams = {
  id: IPrimaryKey
}

export const buildGetPriceListQuery = (fields: string): DocumentNode => gql`
  query getPriceList($id: ID!) {
    getPriceList(id: $id) {
      ${fields}
    }
  }
`

export const useGetPriceListQuery = <TData = IGetPriceList, TOptions = IGetPriceListParams>(
  fields: DocumentNode,
  options?: QueryHookOptions<TData, TOptions>,
) => useQuery<TData, TOptions>(fields, options)
