import React, { memo, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { FormikHelpers } from 'formik'

import withErrorBoundary from 'hocs/withErrorBoundary'
import withLeaveThisPageModal from 'hocs/withLeaveThisPageModal'
import useCmsParams from 'hooks/useCmsParams'
import GoBackLink from 'components/GoBackLink'
import ResultTitle from 'components/ResultTitle'
import PageContainer from 'components/PageContainer'
import PriceRuleForm, { IPriceRuleFormValues } from 'components/forms/PriceRuleForm'
import { IAddPriceRule, IAddPriceRuleParams, buildAddPriceRule } from 'graphql/mutations/addPriceRule'
import useNotifyCms from 'hooks/useNotifyCms'
import useCmsLinks from 'hooks/useCmsLinks'
import convertToFormErrors from 'utils/convertToFormErrors'

import prepareSubmitValues from './utils/prepareSubmitValues'

const defaultValues: IPriceRuleFormValues = {
  name: '',
  status: false,
  addDefault: false,
  position: '1',
  groupParameters: [
    {
      pricelistId: null,
      parameters: [],
    },
  ],
  clientsIds: [],
}

const ADD_PRICE_RULE = buildAddPriceRule(`
  id
  name
  number
  status
  add_default

  parameters_groups {
    price_list {
      id
    }
    parameters {
      discount_ratio
      discount_type
      parameter_type
      destination_ids
    }
  }

  client_group {
    clients {
      id
    }
  }
`)

const PriceRuleCreate: React.FC = () => {
  const { sellerId } = useCmsParams()
  const addNotify = useNotifyCms()
  const { priceRuleEditLink, priceRulesListLink } = useCmsLinks()
  const { replace } = useHistory()

  const [onAddPriceRule, { loading }] = useMutation<IAddPriceRule, IAddPriceRuleParams>(ADD_PRICE_RULE)

  const onSubmit = useCallback(
    (values: IPriceRuleFormValues, { setErrors }: FormikHelpers<IPriceRuleFormValues>) =>
      onAddPriceRule({
        variables: {
          seller_id: sellerId,
          input: prepareSubmitValues(values),
        },
      })
        .then(({ data }) => {
          addNotify('success')
          if (data) replace(priceRuleEditLink(data.addPriceRule.id))
        })
        .catch((error) => {
          addNotify('error')
          const errors = convertToFormErrors(error)
          if (errors) setErrors(errors)
        }),
    [onAddPriceRule, addNotify, replace, priceRuleEditLink, sellerId],
  )

  return (
    <PageContainer>
      <GoBackLink href={priceRulesListLink} />
      <ResultTitle>Добавление ценовой группы</ResultTitle>
      <PriceRuleForm submitLoading={loading} initialValues={defaultValues} onSubmit={onSubmit} />
    </PageContainer>
  )
}

export default withErrorBoundary(withLeaveThisPageModal(memo(PriceRuleCreate)))
