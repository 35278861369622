import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'

import { useSetSellerSettings, buildSetSellerSettingsQuery } from 'graphql/mutations/setSellerSettings'
import { ISettings, Seller } from 'types/types'
import Switch from 'components/Switch'
import Text from 'components/Typography'
import useCmsParams from 'hooks/useCmsParams'
import useNotifyCms from 'hooks/useNotifyCms'
import InfoIcon from 'components/Icons/InfoIcon'
import defaultFieldsHelper from 'utils/defaultFieldsHelper'
import sellerSettingsDefaultFields from 'consts/defaultFields/baseSellerSettings'

import styles from './EmailNotify.module.scss'

type IEmailNotifyProps = {
  settings: ISettings
}

const SET_SELLER_SETTINGS_QUERY = buildSetSellerSettingsQuery(`
  id
  sending_mail
`)

const EmailNotify: React.FC<IEmailNotifyProps> = ({ settings }) => {
  const { sending_mail } = defaultFieldsHelper(settings, sellerSettingsDefaultFields)

  const { sellerId } = useCmsParams()
  const addNotify = useNotifyCms()

  const [onSetSellerSettings] = useSetSellerSettings(SET_SELLER_SETTINGS_QUERY, {
    variables: {
      seller_id: sellerId,
      input: { sending_mail: !sending_mail },
    },
    onCompleted: () => addNotify('success'),
    onError: () => addNotify('error'),
    update(cache, { data: resData }) {
      if (!resData?.setSellerSettings) throw new Error('Something went wrong')

      cache.modify({
        fields: {
          getSeller(seller: Seller) {
            return {
              ...seller,
              settings: {
                ...seller.settings,
                ...resData.setSellerSettings,
              },
            }
          },
        },
      })
    },
  })

  return (
    <div className={styles.container}>
      <Switch
        checked={sending_mail}
        onChange={() => onSetSellerSettings()}
        label={<Text>Отправлять уведомления на почту</Text>}
        placement="right"
        gap="medium"
      />
      <Tooltip
        title={
          <Text size="small">
            Email уведомления, связанные <br /> с изменениями состояния заказов
          </Text>
        }
        arrow
        placement="top"
      >
        <InfoIcon className={styles.icon} />
      </Tooltip>
    </div>
  )
}

export default EmailNotify
