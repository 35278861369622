import { ISetProductVariantsParams } from 'graphql/mutations/setProductVariants'
import { IStoreInput } from 'types/types'

import { IVariantsFormValues } from '../components/VariantsForm'

const prepareStores = (stores: IVariantsFormValues['products'][0]['stores']): IStoreInput[] => {
  if (!stores.length) return []
  // omit name from stores
  return stores.map(({ id: storeId, amount, amount_wait, multiply }) => ({
    store_id: storeId,
    amount: Number.isNaN(Number(amount)) ? 0 : Number(amount),
    amount_wait: Number.isNaN(Number(amount_wait)) ? 0 : Number(amount_wait),
    multiply: Number.isNaN(Number(multiply)) ? 0 : Number(multiply),
  }))
}

const prepareSubmitValues = (payload: IVariantsFormValues): ISetProductVariantsParams['product_variants'] => {
  const { products, variantAttribute } = payload

  const prepareProducts = products.map(
    ({
      tempUniqId, // OMIT
      id,
      price,
      weight,
      length,
      height,
      volume,
      width,
      product_attribute,
      amount,
      amount_wait,
      multiply,
      stores,
      images,
      number,
      ...product
    }) => ({
      ...product,
      id: id || undefined,
      price: price || null,
      amount: Number(amount) || null,
      amount_wait: Number(amount_wait) || null,
      multiply: Number(multiply) || 1,
      weight: weight as any,
      length: length as any,
      height: height as any,
      volume: volume as any,
      width: width as any,
      stores: prepareStores(stores),
      images,
      number: number ? Number(number) : 9999,
      attributes: product_attribute
        ? [{ attribute_id: variantAttribute.attributeId, value_ids: [product_attribute] }]
        : [],
    }),
  )

  const prepareAttributes = [
    { attribute_id: variantAttribute.attributeId, value_ids: variantAttribute.valueIds },
  ].filter(({ attribute_id }) => Boolean(attribute_id))

  return {
    products: prepareProducts,
    variants_attributes: prepareAttributes,
  }
}

export default prepareSubmitValues
