import { DocumentNode, gql, useQuery, QueryHookOptions } from '@apollo/client'

import { PStores, Paginate } from 'types/types'

export type IGetStores = {
  getStores: PStores
}

export type IGetStoresParams = {
  seller_id: IPrimaryKey
  search?: string | null
  paginate?: Paginate
}

export const buildGetStoresQuery = (fields: string): DocumentNode => gql`
  query getStores($seller_id: ID!, $search: String, $paginate: Paginate) {
    getStores(seller_id: $seller_id, search: $search, paginate: $paginate) {
      elements
      page
      pages
      list { ${fields} }
    }
  }
`

export const useGetStoresQuery = <TData = IGetStores, TOptions = IGetStoresParams>(
  fields: DocumentNode,
  options?: QueryHookOptions<TData, TOptions>,
) => useQuery<TData, TOptions>(fields, options)
