import React, { useMemo } from 'react'

import { Attribute as IAttribute } from 'types/types'

import { IVariantsFormValues } from '../../../../types'

type IAttributeTableHeaderProps = {
  attributesHash: Record<string, IAttribute>
  variantAttribute: IVariantsFormValues['variantAttribute']
}

const AttributeTableHeader: React.FC<IAttributeTableHeaderProps> = ({ attributesHash, variantAttribute }) => {
  const attribute = useMemo(
    () => attributesHash[variantAttribute.attributeId],
    [attributesHash, variantAttribute],
  )

  if (!attribute) return null

  return <td>{attribute.name}</td>
}

export default AttributeTableHeader
