import React, { memo, useMemo } from 'react'
import { useApolloClient } from '@apollo/client'

import { useGetOrderQuery } from 'graphql/queries/getOrder'
import Preloader from 'components/Preloader'
import { H1 } from 'components/Typography'
import GoBackLink from 'components/GoBackLink'
import PageContainer from 'components/PageContainer'
import useCmsParams from 'hooks/useCmsParams'
import withErrorBoundary from 'hocs/withErrorBoundary'
import ApiError from 'components/ApiError'
import date from 'utils/date'
import { SIDEBAR_GET_ORDERS } from 'components/SideBar/components/InfoCounter'

import Sheeping from './components/Sheeping'
import Customer from './components/Customer'
import OrderDropMenu from './components/OrderDropMenu'
import CopyLink from './components/CopyLink'
import Documents from './components/Documents'
import OrderStatus from './components/OrderStatus'
import { GET_ORDER } from './queries'

import styles from './order.module.scss'

const Order: React.FC = () => {
  const { sellerId, orderId } = useCmsParams()
  const apolloClient = useApolloClient()

  const { data, loading, error, refetch } = useGetOrderQuery(GET_ORDER, {
    variables: {
      id: orderId,
    },
    onCompleted() {
      apolloClient.refetchQueries({
        include: [SIDEBAR_GET_ORDERS],
      })
    },
  })

  const order = useMemo(() => data?.getOrder, [data])

  if (loading) return <Preloader />

  if (error)
    return (
      <PageContainer>
        <ApiError error={error} />
      </PageContainer>
    )

  if (!order) return null

  return (
    <PageContainer>
      <GoBackLink href={`/${sellerId}/orders`} />
      <div className={styles.headerGroup}>
        <H1>
          <span>{`Заказ № ${order.number}`}</span>
          {order.created_at ? (
            <span className={styles.date}>{` от ${date(order.created_at).format('DD.MM.YYYY HH:mm')}`}</span>
          ) : null}
        </H1>
        <CopyLink client={order.client} />
      </div>
      {order.order_status ? <OrderStatus status={order.order_status} refetch={refetch} /> : null}
      <OrderDropMenu products={order.products} />
      <Sheeping order={order} />
      <Customer order={order} />
      <Documents orderId={orderId} files={order.files} />
    </PageContainer>
  )
}

export default withErrorBoundary(memo(Order))
