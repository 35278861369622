import React from 'react'
import { Switch, Route } from 'react-router-dom'

import ClientsList from 'views/Clients/ClientsList'
import ClientCreate from 'views/Clients/ClientCreate'
import ClientEdit from 'views/Clients/ClientEdit'
import CompanyEdit from 'views/Clients/CompanyEdit'

import PriceRulesList from 'views/PriceRules/PriceRulesList'
import PriceRuleCreate from 'views/PriceRules/PriceRuleCreate'
import PriceRuleEdit from 'views/PriceRules/PriceRuleEdit'

import BaseProductCreate from 'views/BaseProducts/BaseProductCreate'
import BaseProductEdit from 'views/BaseProducts/BaseProductEdit'
import BaseProductsList from 'views/BaseProducts/BaseProductsList'
import StoreList from 'views/BaseProducts/StoreList'

import CategoryCreate from 'views/Categories/CategoryCreate'
import CategoryEdit from 'views/Categories/CategoryEdit'
import CategoriesList from 'views/Categories/CategoriesList'

import ContactsList from 'views/Contacts/ContactsList'
import ContactCreate from 'views/Contacts/ContactCreate'
import ContactEdit from 'views/Contacts/ContactEdit'

import ManagersList from 'views/Managers/ManagersList'
import ManagerCreate from 'views/Managers/ManagerCreate'
import ManagerEdit from 'views/Managers/ManagerEdit'

import PriceListsList from 'views/PriceLists/PriceListsList'
import PriceListCreate from 'views/PriceLists/PriceListCreate'
import PriceListEdit from 'views/PriceLists/PriceListEdit'

import BannersList from 'views/Banners/BannersList'
import BannersCreate from 'views/Banners/BannersCreate'
import BannersEdit from 'views/Banners/BannersEdit'

import AttributeEdit from 'pages/AttributeEdit'
import AttributesList from 'pages/AttributesList'
import CommonOptions from 'pages/CommonOptions'
import CustomDomain from 'pages/CustomDomain'
import CustomScripts from 'pages/CustomScripts'
import Domain from 'pages/Domain'
import General from 'pages/General'
import Groups from 'pages/GroupsList'
import ImportLinks from 'pages/ImportLinks'
import Integrations from 'pages/Integrations'
import More from 'pages/More'
import NotFound from 'pages/NotFound'
import OneSIntegration from 'pages/OneSIntegration'
import PaymentMethods from 'pages/PaymentMethods'
import Order from 'pages/Order'
import OrdersList from 'pages/OrdersList'
import Delivery from 'pages/Delivery'

import CmsRoute from 'components/CmsRoute'
import SellerProvider from 'contexts/SellerProvider'

const CMSPage: React.FC = () => (
  <SellerProvider>
    <Switch>
      <CmsRoute exact path="/:sellerId/general" component={General} />
      <CmsRoute exact path="/:sellerId/integrations" component={Integrations} />
      <CmsRoute exact path="/:sellerId/integrations/import-links" component={ImportLinks} />
      <CmsRoute exact path="/:sellerId/integrations/one-s" component={OneSIntegration} />
      <CmsRoute exact path="/:sellerId/integrations/payment-methods" component={PaymentMethods} />

      <CmsRoute exact path="/:sellerId/domain" component={Domain} />
      <CmsRoute exact path="/:sellerId/custom-domain" component={CustomDomain} />
      <CmsRoute exact path="/:sellerId/more" component={More} />
      <CmsRoute exact path="/:sellerId/more/scripts" component={CustomScripts} />

      <CmsRoute exact path="/:sellerId/orders" component={OrdersList} />
      <CmsRoute exact path="/:sellerId/orders/:orderId" component={Order} />

      <CmsRoute exact path="/:sellerId/clients/list" component={ClientsList} />
      <CmsRoute exact path="/:sellerId/clients/create" component={ClientCreate} />
      <CmsRoute exact path="/:sellerId/clients/profile/:clientId" component={ClientEdit} />
      <CmsRoute exact path="/:sellerId/clients/:clientId/company/:companyId" component={CompanyEdit} />

      <CmsRoute exact path="/:sellerId/price-rules" component={PriceRulesList} />
      <CmsRoute exact path="/:sellerId/price-rules/create" component={PriceRuleCreate} />
      <CmsRoute exact path="/:sellerId/price-rules/:priceRuleId" component={PriceRuleEdit} />

      <CmsRoute exact path="/:sellerId/catalog/groups" component={Groups} />

      <CmsRoute exact path="/:sellerId/catalog/products" component={BaseProductsList} />
      <CmsRoute exact path="/:sellerId/catalog/products/stores" component={StoreList} />
      <CmsRoute exact path="/:sellerId/catalog/products/create" component={BaseProductCreate} />
      <CmsRoute exact path="/:sellerId/catalog/products/:productId" component={BaseProductEdit} />

      <CmsRoute exact path="/:sellerId/catalog/categories" component={CategoriesList} />
      <CmsRoute exact path="/:sellerId/catalog/categories/create" component={CategoryCreate} />
      <CmsRoute exact path="/:sellerId/catalog/categories/:categoryId" component={CategoryEdit} />

      <CmsRoute exact path="/:sellerId/catalog/attributes" component={AttributesList} />
      <CmsRoute exact path="/:sellerId/catalog/attributes/:attributeId" component={AttributeEdit} />

      <CmsRoute exact path="/:sellerId/catalog/price-lists/" component={PriceListsList} />
      <CmsRoute exact path="/:sellerId/catalog/price-lists/create" component={PriceListCreate} />
      <CmsRoute exact path="/:sellerId/catalog/price-lists/:priceListId" component={PriceListEdit} />

      <CmsRoute exact path="/:sellerId/contacts/" component={ContactsList} />
      <CmsRoute exact path="/:sellerId/contacts/create" component={ContactCreate} />
      <CmsRoute exact path="/:sellerId/contacts/:contactId" component={ContactEdit} />

      <CmsRoute exact path="/:sellerId/managers/" component={ManagersList} />
      <CmsRoute exact path="/:sellerId/managers/create" component={ManagerCreate} />
      <CmsRoute exact path="/:sellerId/managers/:managerId" component={ManagerEdit} />

      <CmsRoute exact path="/:sellerId/options/common" component={CommonOptions} />
      <CmsRoute exact path="/:sellerId/options/delivery" component={Delivery} />

      <CmsRoute exact path="/:sellerId/options/banners" component={BannersList} />
      <CmsRoute exact path="/:sellerId/options/banners/create" component={BannersCreate} />
      <CmsRoute exact path="/:sellerId/options/banners/:bannerId" component={BannersEdit} />

      <Route component={NotFound} />
    </Switch>
  </SellerProvider>
)

export default CMSPage
