import React from 'react'

import { IBaseProduct } from 'types/types'
import defaultFieldsHelper from 'utils/defaultFieldsHelper'
import baseProductDefaultFields from 'consts/defaultFields/baseProduct'

import VariantsSwitch from '../VariantsSwitch'

import styles from './switchPanel.module.scss'

type ISwitchPanelProps = {
  baseProduct: IBaseProduct
  refetchBaseProduct: () => void
}

const SwitchPanel: React.FC<ISwitchPanelProps> = ({ baseProduct, refetchBaseProduct }) => {
  const { variants_status } = defaultFieldsHelper(baseProduct, baseProductDefaultFields)

  return (
    <div className={styles.container}>
      <VariantsSwitch initStatus={variants_status} refetchBaseProduct={refetchBaseProduct} />
    </div>
  )
}

export default SwitchPanel
