import isBoolean from 'lodash/isBoolean'

import { IContact } from 'types/types'
import { NonNullableFields } from 'types/utils'
import phoneFormat from 'utils/phoneFormat'

type NullableFields = 'client_id' | 'clients' | 'created_at' | 'updated_at'

type IContactDefaultValues = NonNullableFields<Required<Omit<IContact, NullableFields>>> &
  Required<Pick<IContact, NullableFields>>

const contactDefaultValues = (payload: IContact): IContactDefaultValues => {
  const {
    id,
    seller_id,
    status,
    phone,
    email,
    email_verified_at,
    name,
    client_id = null,
    clients = null,
    created_at = null,
    updated_at = null,
  } = payload

  return {
    id,
    seller_id,
    name: name || '',
    phone: phone ? phoneFormat(phone) : '',
    email: email || '',
    email_verified_at: email_verified_at || '',
    status: isBoolean(status) ? status : false,
    client_id,
    clients,
    created_at,
    updated_at,
  }
}

export default contactDefaultValues
