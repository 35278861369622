import React, { memo } from 'react'

import SideBarButton from 'components/ui-kit/SideBarButton/SideBarButton'
import useCmsParams from 'hooks/useCmsParams'
import useCurrentSeller from 'hooks/useCurrentSeller'
import withErrorBoundary from 'hocs/withErrorBoundary'

import SwitchIcon from 'components/Icons/SwitchIcon'

import { ReactComponent as OrdersIcon } from 'assets/img/Orders.svg'
import { ReactComponent as MoreIcon } from 'assets/img/More.svg'
import { ReactComponent as MainIcon } from 'assets/img/Main.svg'
import { ReactComponent as DomainIcon } from 'assets/img/Domain.svg'
import { ReactComponent as CatalogIcon } from 'assets/img/CatalogIcon.svg'
import { ReactComponent as AgentsIcon } from 'assets/img/agents.svg'
import { ReactComponent as IntegrationIcon } from 'assets/img/integration.svg'

import SitesLinks from './components/SitesLinks'
import InfoCounter from './components/InfoCounter'
import SubGroupLink from './components/SubGroupLink'

import styles from './SideBar.module.scss'

const catalogLinks = (sellerId: string) => [
  `/${sellerId}/catalog/products`,
  `/${sellerId}/catalog/products/create`,
  `/${sellerId}/catalog/products/stores`,
  `/${sellerId}/catalog/attributes`,
  `/${sellerId}/catalog/groups`,
  `/${sellerId}/catalog/categories`,
  `/${sellerId}/catalog/categories/create`,
  `/${sellerId}/catalog/price-lists`,
  `/${sellerId}/catalog/price-lists/create`,
]

const clientsLinks = (sellerId: string) => [
  `/${sellerId}/clients/list`,
  `/${sellerId}/clients/groups`,
  `/${sellerId}/clients/create`,
  `/${sellerId}/clients/profile`,
  `/${sellerId}/contacts`,
  `/${sellerId}/contacts/create`,
  `/${sellerId}/managers`,
  `/${sellerId}/managers/create`,
  `/${sellerId}/price-rules`,
  `/${sellerId}/price-rules/create`,
  `/${sellerId}/price-rules/create-uniq`,
]

const SideBar: React.FC = () => {
  const { sellerId } = useCmsParams()
  const seller = useCurrentSeller()

  return (
    <aside className={styles.wrapper}>
      <div className={styles.stickyGroup}>
        <SitesLinks />

        <p className={styles.secondaryText}>Управление магазином</p>

        <SideBarButton
          title="Каталог"
          href={`/${sellerId}/catalog/products`}
          activeLinkList={catalogLinks(sellerId)}
        >
          <CatalogIcon />
        </SideBarButton>

        <section className={styles.subGroup}>
          <SubGroupLink href={`/${sellerId}/catalog/products`} groupHref={`/${sellerId}/catalog/products`}>
            Товары
          </SubGroupLink>
          <SubGroupLink
            href={`/${sellerId}/catalog/price-lists`}
            groupHref={`/${sellerId}/catalog/price-lists`}
          >
            Прайс-листы
          </SubGroupLink>
          <SubGroupLink
            href={`/${sellerId}/catalog/attributes`}
            groupHref={`/${sellerId}/catalog/attributes`}
          >
            Атрибуты
          </SubGroupLink>
          <SubGroupLink href={`/${sellerId}/catalog/groups`} groupHref={`/${sellerId}/catalog/groups`}>
            Группы
          </SubGroupLink>
          <SubGroupLink
            href={`/${sellerId}/catalog/categories`}
            groupHref={`/${sellerId}/catalog/categories`}
          >
            Категории
          </SubGroupLink>
        </section>

        <SideBarButton
          title="Заказы"
          href={`/${sellerId}/orders`}
          activeLinkList={[
            `/${sellerId}/orders`,
            `/${sellerId}/orders/${window.location.pathname.replace(`/${sellerId}/orders/`, '')}`,
          ]}
          asideRight={<InfoCounter />}
        >
          <OrdersIcon />
        </SideBarButton>

        <SideBarButton
          title="Контрагенты"
          href={`/${sellerId}/clients/list`}
          activeLinkList={clientsLinks(sellerId)}
        >
          <AgentsIcon />
        </SideBarButton>

        <section className={styles.subGroup}>
          <SubGroupLink href={`/${sellerId}/clients/list`} groupHref={`/${sellerId}/clients`}>
            Контрагенты
          </SubGroupLink>
          <SubGroupLink href={`/${sellerId}/contacts`} groupHref={`/${sellerId}/contacts`}>
            Контакты
          </SubGroupLink>
          <SubGroupLink href={`/${sellerId}/price-rules`} groupHref={`/${sellerId}/price-rules`}>
            Ценовые группы
          </SubGroupLink>
          <SubGroupLink href={`/${sellerId}/managers`} groupHref={`/${sellerId}/managers`}>
            Менеджеры
          </SubGroupLink>
        </section>

        <SideBarButton
          title="Опции"
          href={`/${sellerId}/options/common`}
          activeLinkList={[
            `/${sellerId}/options/common`,
            `/${sellerId}/options/banners`,
            `/${sellerId}/options/delivery`,
          ]}
        >
          <SwitchIcon />
        </SideBarButton>

        <section className={styles.subGroup}>
          <SubGroupLink href={`/${sellerId}/options/common`} groupHref={`/${sellerId}/options/common`}>
            Общие
          </SubGroupLink>
          <SubGroupLink href={`/${sellerId}/options/banners`} groupHref={`/${sellerId}/options/banners`}>
            Баннеры
          </SubGroupLink>
          <SubGroupLink href={`/${sellerId}/options/delivery`} groupHref={`/${sellerId}/options/delivery`}>
            Доставка
          </SubGroupLink>
        </section>

        <p className={styles.secondaryText}>Общие настройки</p>

        <SideBarButton
          title="Главное"
          href={`/${sellerId}/general`}
          activeLinkList={[`/${sellerId}/general`]}
        >
          <MainIcon />
        </SideBarButton>

        <SideBarButton
          title="Домен"
          href={`/${sellerId}/domain`}
          activeLinkList={[`/${sellerId}/domain`, `/${sellerId}/custom-domain`]}
        >
          <DomainIcon />
        </SideBarButton>

        <SideBarButton
          className={styles.moreBtn}
          title="Еще"
          href={`/${sellerId}/more`}
          activeLinkList={[`/${sellerId}/more`, `/${sellerId}/more/scripts`]}
        >
          <MoreIcon />
          {!seller?.email_verified_at && !!seller?.email && <div className={styles.redDot} />}
        </SideBarButton>

        <SideBarButton
          title="Интеграции"
          href={`/${sellerId}/integrations`}
          activeLinkList={[`/${sellerId}/integrations`, `/${sellerId}/integrations/payment-methods`]}
        >
          <IntegrationIcon />
        </SideBarButton>

        <section className={styles.subGroup}>
          <SubGroupLink
            href={`/${sellerId}/integrations/payment-methods`}
            groupHref={`/${sellerId}/integrations/payment-methods`}
          >
            Способы оплаты
          </SubGroupLink>
        </section>
      </div>
    </aside>
  )
}

export default withErrorBoundary(memo(SideBar))
