import { Redirect } from 'react-router-dom'

import useGetSearchParams from 'hooks/useGetSearchParams'

const NewIntegrationsRedirect = () => {
  const { code, state } = useGetSearchParams<{ code?: string; state?: string }>()

  if (!code || !state) return <Redirect to="/" />

  return <Redirect to={`/${state}/integrations/payment-methods?code=${code}&state=${state}`} />
}

export default NewIntegrationsRedirect
