import { Overrides } from '@material-ui/core/styles/overrides'

const link: Overrides['MuiLink'] = {
  root: {
    display: 'inline-flex',
    color: '#0089CC',
    verticalAlign: 'middle',

    '&:hover': {
      color: '#121239',
    },
  },
  button: {
    '&:disabled': {
      color: '#999CA6',
      cursor: 'initial',
    },
  },
}

export default link
