import React, { ReactElement, useEffect, useMemo, useState } from 'react'

import Text from 'components/Typography'
import SuccessIcon from 'components/Icons/SuccessIcon'
import AttentionFilledIcon from 'components/Icons/AttentionFilledIcon'
import CrossFilledIcon from 'components/Icons/CrossFilledIcon'
import { INotice } from 'contexts/NotifyProvider'

import styles from './notifyItem.module.scss'

interface IProps {
  notice: INotice
  remove: (id: string) => void
}

const iconMap: Record<INotice['kind'], ReactElement> = {
  error: <CrossFilledIcon className={styles.icon} />,
  success: <SuccessIcon className={styles.icon} />,
  warning: <AttentionFilledIcon className={styles.icon} />,
}

const NotifyItem: React.FC<IProps> = ({ notice, remove }) => {
  const { id: noticeId, kind, message } = notice

  const [visible, setVisible] = useState<boolean>(false)

  const icon = useMemo(() => iconMap[kind], [kind])

  useEffect(() => {
    setVisible(true)
    const timer = setTimeout(() => setVisible(false), 2000)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => remove(noticeId), 3000)
    return () => {
      clearTimeout(timer)
    }
  }, [remove, noticeId])

  if (!visible) return null

  return (
    <div className={styles.wrapper}>
      <div className={styles.alert}>
        {icon}
        <Text className={styles.message}>{message}</Text>
      </div>
    </div>
  )
}

export default NotifyItem
