import React, { useMemo } from 'react'

import Checkbox from 'components/Checkbox'
import { IUseChoicesActions } from 'hooks/useChoises'

import styles from './selectToggle.module.scss'

type IToggleActions = Pick<IUseChoicesActions<string>, 'toggle' | 'isSelected'>

type ISelectToggleProps = IToggleActions & {
  productId: string
}

const SelectToggle: React.FC<ISelectToggleProps> = ({ productId, isSelected, toggle }) => {
  const checked = useMemo(() => isSelected(productId), [productId, isSelected])

  const onChange = () => toggle(productId)

  return <Checkbox className={styles.checkbox} checked={checked} onChange={onChange} />
}

export default SelectToggle
