import { gql } from '@apollo/client'

import { Status } from 'types/types'

export type IDeleteBaseProductsListResponse = {
  delBaseProductsList: Status
}

export const DELETE_BASE_PRODUCTS_LIST = gql`
  mutation delBaseProductsList($ids: [ID]!) {
    delBaseProductsList(ids: $ids) {
      status
      message
    }
  }
`
