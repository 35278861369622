import React from 'react'
import { useMutation } from '@apollo/client'

import {
  ISetSellerOnlyAuthorisedOrder,
  ISetSellerOnlyAuthorisedOrderParams,
  buildSetSellerOnlyAuthorisedOrderQuery,
} from 'graphql/mutations/setSellerOnlyAuthorisedOrder'
import useNotifyCms from 'hooks/useNotifyCms'
import useCmsParams from 'hooks/useCmsParams'
import Switch from 'components/Switch'
import Text from 'components/Typography'
import { Seller } from 'types/types'
import defaultFieldsHelper from 'utils/defaultFieldsHelper'
import baseSellerDefaultFields from 'consts/defaultFields/baseSeller'

type IOnlyAuthorisedOrderProps = {
  seller: Seller
}

const SET_SELLER = buildSetSellerOnlyAuthorisedOrderQuery(`
  id
  only_authorised_order
`)

const OnlyAuthorisedOrder: React.FC<IOnlyAuthorisedOrderProps> = ({ seller }) => {
  const { closed, only_authorised_order } = defaultFieldsHelper(seller, baseSellerDefaultFields)

  const addNotify = useNotifyCms()
  const { sellerId } = useCmsParams()

  const [onSetSeller, { loading }] = useMutation<
    ISetSellerOnlyAuthorisedOrder,
    ISetSellerOnlyAuthorisedOrderParams
  >(SET_SELLER, {
    variables: {
      id: sellerId,
      only_authorised_order: !only_authorised_order,
    },
    onCompleted: () => addNotify('success'),
    onError: () => addNotify('error'),
    update(cache, { data: resData }) {
      if (!resData?.setSellerOnlyAuthorisedOrder) throw new Error('Something went wrong')

      cache.modify({
        fields: {
          // NOTE: НЕ Вызывает обновление всех getSeller (потому что нет поля only_authorised_order в других запросах?)
          getSeller(currentSeller: Seller) {
            return {
              ...currentSeller,
              only_authorised_order: resData.setSellerOnlyAuthorisedOrder.only_authorised_order,
            }
          },
        },
      })
    },
  })

  return (
    <Switch
      disabled={loading || closed}
      checked={only_authorised_order || false}
      placement="right"
      gap="medium"
      label={<Text>Запретить заказы</Text>}
      onChange={() => onSetSeller()}
    />
  )
}

export default OnlyAuthorisedOrder
