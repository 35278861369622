import { DocumentNode, gql } from '@apollo/client'
import type { Category, ICategoryInput } from 'types/types'

export type ISetCategory = {
  setCategory: Category
}

export type ISetCategoryParams = {
  id: string
  input: ICategoryInput
}

export const buildSetCategoryQuery = (fields: string): DocumentNode => gql`
  mutation setCategory($id: ID!, $input: CategoryInput) {
    setCategory(id: $id, input: $input) {
      ${fields}
    }
  }
`
