import phoneFormat from 'utils/phoneFormat'
import formikFieldEvent from './formikFieldEvent'

type IReturn = {
  target: {
    name: string
    value: string
  }
}

const phoneTransform = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): IReturn => {
  const { name, value } = event.target

  return formikFieldEvent(name, phoneFormat(value))
}

export default phoneTransform
