import { gql } from '@apollo/client'
import type { RegisterResponse } from 'types/types'

export type RegisterResponseType = {
  register: RegisterResponse
}

export const REGISTER_NEW_USER = gql`
  mutation RegisterNewUser($input: RegisterInput) {
    register(input: $input) {
      tokens {
        access_token
        expires_in
      }
      status
    }
  }
`
