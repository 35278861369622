import React, { memo, useCallback, useMemo } from 'react'
import { useQuery } from '@apollo/client'

import { IGetPriceRules, IGetPriceRulesParams } from 'graphql/queries/getPriceRules'
import Button from 'components/Button'
import Preloader from 'components/Preloader'
import ResultTitle from 'components/ResultTitle'
import Pagination from 'components/Pagination'
import PageContainer from 'components/PageContainer'
import Link from 'components/Link'
import ApiError from 'components/ApiError'
import withErrorBoundary from 'hocs/withErrorBoundary'
import useCmsParams from 'hooks/useCmsParams'
import useBoolean from 'hooks/useBoolean'
import hasPagination from 'utils/hasPagination'
import useSearchParams from 'hooks/useSearchParams'
import buildPaginateParams from 'utils/buildPaginateParams'
import useCmsLinks from 'hooks/useCmsLinks'

import Empty from './components/Empty'
import Result from './components/Result'
import { GET_PRICE_RULES } from './queries'

import styles from './PriceRulesList.module.scss'

type IPayload = {
  page: string
}

const initPayload: IPayload = {
  page: '1',
}

const PriceRulesList: React.FC = () => {
  const { sellerId } = useCmsParams()
  const { priceRuleCreateLink } = useCmsLinks()

  const [isInitialLoading, setIsInitialLoading] = useBoolean()
  const [searchParams, setSearchParams] = useSearchParams<IPayload>(initPayload)
  const { page } = searchParams

  const { data, loading, error, refetch } = useQuery<IGetPriceRules, IGetPriceRulesParams>(GET_PRICE_RULES, {
    fetchPolicy: 'network-only',
    variables: {
      seller_id: sellerId,
      paginate: buildPaginateParams(page),
    },
    onCompleted: () => {
      if (isInitialLoading) setIsInitialLoading.off()
    },
    onError: () => {
      if (isInitialLoading) setIsInitialLoading.off()
    },
  })

  const { getPriceRules: { elements = 0, pages = 0 } = {} } = data || {}
  const priceRules = useMemo(() => data?.getPriceRules?.list || [], [data])

  const onPageChange = useCallback(
    (nextPage: number) => {
      setSearchParams((prev) => ({ ...prev, page: nextPage.toString() }))
    },
    [setSearchParams],
  )

  const isEmpty = !loading && !priceRules.length

  if (isInitialLoading) return <Preloader />

  if (error) {
    return (
      <PageContainer>
        <ResultTitle>Ценовые группы</ResultTitle>
        <ApiError error={error} />
      </PageContainer>
    )
  }

  if (isEmpty) {
    return (
      <PageContainer>
        <Empty />
      </PageContainer>
    )
  }

  return (
    <PageContainer>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <ResultTitle>Ценовые группы</ResultTitle>

          <div className={styles.controlsGroup}>
            <Link href={priceRuleCreateLink}>
              <Button>Создать ценовую группу</Button>
            </Link>
          </div>

          <Result priceRules={priceRules} refetchPriceRules={refetch} />

          {hasPagination(elements) ? (
            <Pagination page={Number(page)} count={pages} onChange={onPageChange} />
          ) : null}
        </>
      )}
    </PageContainer>
  )
}

export default withErrorBoundary(memo(PriceRulesList))
