import { DocumentNode, gql, useMutation, MutationHookOptions } from '@apollo/client'

import { IPaymentSettings } from 'types/types'

export type ISetPaymentSettingsStatusParams = {
  seller_id: IPrimaryKey
  status: boolean
}

export type ISetPaymentSettingsStatus = {
  setPaymentSettingsStatus: IPaymentSettings
}

export const buildSetPaymentSettingsStatusQuery = (fields: string): DocumentNode => gql`
  mutation setPaymentSettingsStatus($seller_id: ID!, $status: Boolean!) {
    setPaymentSettingsStatus(seller_id: $seller_id, status: $status) {
      ${fields}
    }
  }
`

export const useSetPaymentSettingsStatus = <
  TData = ISetPaymentSettingsStatus,
  TOptions = ISetPaymentSettingsStatusParams,
>(
  fields: DocumentNode,
  options?: MutationHookOptions<TData, TOptions>,
) => useMutation<TData, TOptions>(fields, options)
