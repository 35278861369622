import { DocumentNode, gql } from '@apollo/client'

import { IImportScheme } from 'types/types'

export type IGetImportSchemes = {
  getImportSchemes: IImportScheme[]
}

export type IGetImportSchemesParams = {
  seller_id: IPrimaryKey
}

export const buildGetImportSchemesQuery = (fields: string): DocumentNode => gql`
  query getImportSchedules($seller_id: ID!) {
    getImportSchemes(seller_id: $seller_id) {
      ${fields}
    }
  }
`
