import React, { memo, useCallback, useMemo, useState, useEffect } from 'react'
import { FormikHelpers } from 'formik'
import { useMutation } from '@apollo/client'

import Preloader from 'components/Preloader'
import GoBackLink from 'components/GoBackLink'
import ResultTitle from 'components/ResultTitle'
import PageContainer from 'components/PageContainer'
import useCmsParams from 'hooks/useCmsParams'
import useNotifyCms from 'hooks/useNotifyCms'
import useCmsLinks from 'hooks/useCmsLinks'
import convertToFormErrors from 'utils/convertToFormErrors'
import withErrorBoundary from 'hocs/withErrorBoundary'
import withLeaveThisPageModal from 'hocs/withLeaveThisPageModal'
import ApiError from 'components/ApiError'
import PriceListForm, { IPriceListFormValues } from 'components/forms/PriceListForm'
import { IPriceList } from 'types/types'
import { useGetPriceListQuery } from 'graphql/queries/getPriceList'
import { useSetPriceListMutation } from 'graphql/mutations/setPriceList'
import {
  EXPORT_PRICE_LIST_TABLE,
  IExportPriceListTable,
  IExportPriceListTableParams,
} from 'graphql/mutations/exportPriceListTable'
import useBoolean from 'hooks/useBoolean'
import { maxPaginate } from 'consts'
import { CATALOG_PRICE_LISTS_LIMIT_ERROR } from 'consts/errorMessages'
import { useGetPriceListsLazyQuery } from 'graphql/queries/getPriceLists'

import { GET_PRICE_LIST, SET_PRICE_LIST, GET_PRICE_LISTS } from './queries'
import Controls from './components/Controls'

const defaultValues: IPriceListFormValues = {
  name: '',
  default_price: false,
  show_prices: false,
  downloadUrl: null,
  file: null,
}

const prepareInitialValues = ({
  name,
  default_price,
  show_prices,
  downloadUrl,
}: IPriceList & { downloadUrl: string | null }): IPriceListFormValues => ({
  name,
  default_price,
  show_prices: show_prices ?? false,
  downloadUrl,
  file: null,
})

const prepareSubmitValues = ({ name, default_price, show_prices, file }: IPriceListFormValues) => ({
  name,
  default_price,
  show_prices,
  file,
})

const ContactEdit: React.FC = () => {
  const { priceListId, sellerId } = useCmsParams()
  const addNotify = useNotifyCms()
  const { priceListsListLink } = useCmsLinks()

  const [downloadUrl, setDownloadUrl] = useState<string | null>(null)
  const [submitLoading, setSubmitLoading] = useBoolean(false)

  const [getPriceLists] = useGetPriceListsLazyQuery(GET_PRICE_LISTS, {
    variables: {
      seller_id: sellerId,
      paginate: maxPaginate,
    },
    fetchPolicy: 'network-only',
  })

  const { data, loading, error, refetch } = useGetPriceListQuery(GET_PRICE_LIST, {
    variables: { id: priceListId },
    fetchPolicy: 'network-only',
  })

  const priceList = useMemo(() => data?.getPriceList, [data])

  const initialFormValues: IPriceListFormValues = useMemo(() => {
    if (!priceList) return defaultValues
    return prepareInitialValues({ ...priceList, downloadUrl })
  }, [priceList, downloadUrl])

  const [onExportPriceListTable, { loading: loadingExportPriceListTable }] = useMutation<
    IExportPriceListTable,
    IExportPriceListTableParams
  >(EXPORT_PRICE_LIST_TABLE, {
    variables: {
      id: priceListId,
    },
    onCompleted: ({ exportPriceListTable }) => {
      const { message, status } = exportPriceListTable

      if (!status || !message) return

      setDownloadUrl(message)
    },
    onError: () => addNotify('error'),
  })

  const [setPriceList] = useSetPriceListMutation(SET_PRICE_LIST)

  const onSetPriceList = useCallback(
    (values: IPriceListFormValues, { setErrors }: FormikHelpers<IPriceListFormValues>) =>
      setPriceList({
        variables: {
          id: priceListId,
          ...prepareSubmitValues(values),
        },
      })
        .then(() => {
          addNotify('success')
          refetch()
          onExportPriceListTable()
        })
        .catch((errorRes) => {
          addNotify('error')
          const errors = convertToFormErrors(errorRes)
          if (errors) setErrors(errors)
        })
        .finally(() => {
          setSubmitLoading.off()
        }),
    [setSubmitLoading, setPriceList, addNotify, refetch, onExportPriceListTable, priceListId],
  )

  const onSubmit = useCallback(
    async (values: IPriceListFormValues, form: FormikHelpers<IPriceListFormValues>): Promise<void> => {
      setSubmitLoading.on()

      if (values.show_prices) {
        const { data: priceListsData, error: priceListsError } = await getPriceLists()
        const priceLists = priceListsData?.getPriceLists.list || []

        if (priceListsError) {
          addNotify({ message: priceListsError.message, kind: 'error' })
          setSubmitLoading.off()
          return undefined
        }

        const activeCatalogCount = priceLists
          // NOTE: Фильтруем текущий прайс-лист, что бы не ломать редактирование для текущего прайс листа, если их уже 3 (включая этот)
          .filter((record) => record.id !== priceListId)
          .filter((record) => record.show_prices).length

        if (activeCatalogCount >= 3) {
          addNotify({ message: CATALOG_PRICE_LISTS_LIMIT_ERROR, kind: 'error' })
          setSubmitLoading.off()
          return undefined
        }

        return onSetPriceList(values, form)
      }

      return onSetPriceList(values, form)
    },
    [setSubmitLoading, getPriceLists, onSetPriceList, addNotify, priceListId],
  )

  useEffect(() => {
    onExportPriceListTable()
  }, [onExportPriceListTable])

  if (loading || loadingExportPriceListTable) {
    return (
      <PageContainer>
        <GoBackLink href={priceListsListLink} />
        <Preloader />
      </PageContainer>
    )
  }

  if (error)
    return (
      <PageContainer>
        <GoBackLink href={priceListsListLink} />
        <ApiError error={error} />
      </PageContainer>
    )

  if (!priceList) return null

  return (
    <PageContainer>
      <GoBackLink href={priceListsListLink} />

      <ResultTitle>{priceList.name}</ResultTitle>

      <PriceListForm
        initialValue={initialFormValues}
        submitLoading={submitLoading}
        onSubmit={onSubmit}
        renderControls={() => <Controls />}
      />
    </PageContainer>
  )
}

export default withErrorBoundary(withLeaveThisPageModal(memo(ContactEdit)))
