import { DocumentNode, gql } from '@apollo/client'

import { Paginate, PPriceRule } from 'types/types'

export type IGetPriceRules = {
  getPriceRules: PPriceRule
}

export type IGetPriceRulesParams = {
  seller_id: IPrimaryKey
  paginate?: Paginate
}

export const buildGetPriceRulesQuery = (fields: string): DocumentNode => gql`
  query getPriceRules($seller_id: ID!, $paginate: Paginate) {
    getPriceRules(seller_id: $seller_id, paginate: $paginate) {
      page
      pages
      elements
      list { ${fields} }
    }
  }
`
