import React from 'react'
import { Helmet } from 'react-helmet'
import { ApolloProvider } from '@apollo/client'
import 'moment/locale/ru'

import AdminRoutes from 'routes/Admin'
import { AuthProvider } from 'contexts/AuthProvider'
import GlobalVariables from 'components/GlobalVariables'

import { ADMIN_CLIENT } from 'config'

const titleBuilder = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'local':
      return 'Sellty-local'
    case 'dev':
      return 'Sellty-dev'
    case 'stage':
      return 'Sellty-stage'
    case 'prod':
      return 'Sellty'
    default:
      return 'Sellty'
  }
}

const App: React.FC = () => {
  React.useEffect(() => {
    if (process.env.REACT_APP_ENV === 'prod') {
      const body: any = document.getElementsByTagName('body')[0]
      const head: any = document.getElementsByTagName('head')[0]
      const noscriptGA: any = document.createElement('noscript')
      const scriptGA: any = document.createElement('script')

      scriptGA.type = 'text/javascript'
      scriptGA.innerHTML =
        "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n" +
        "new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n" +
        "j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n" +
        "'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n" +
        "})(window,document,'script','dataLayer','GTM-T3PJC7N');"
      noscriptGA.innerHTML =
        '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T3PJC7N" height="0" width="0" style="display:none;visibility:hidden"></iframe>'

      body.prepend(noscriptGA)
      head.prepend(scriptGA)
    }
  }, [])

  // useEffect for chat enable
  React.useEffect(() => {
    const head = document.getElementsByTagName('head')[0]
    const container = document.getElementById('jivo-iframe-container')
    const custom_widget: any = document.getElementById('jivo_custom_widget')
    const widget: any = document.querySelectorAll('jdiv')[0]
    const tagScript = document.getElementById('chatScript')

    if (
      window.location.pathname !== '/auth/sign-in' &&
      window.location.pathname !== '/auth/sign-up' &&
      window.location.pathname !== '/auth/recovery' &&
      window.location.pathname !== '/auth/reset-password' &&
      !window.location.pathname.includes('/email-verify') &&
      window.location.host.includes('my.')
    ) {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = '//code-eu1.jivosite.com/widget/TScudPdnTm'
      script.id = 'chatScript'
      head.appendChild(script)
      if (container) {
        container.style.display = 'block'
        widget.style.display = 'block'
        custom_widget.style.display = 'block'
      }
    } else if (tagScript) {
      document?.getElementById('chatScript')?.remove()

      if (container) {
        container.style.display = 'none'
        widget.style.display = 'none'
        custom_widget.style.display = 'none'
      }
    }
  }, [window.location.pathname])

  return (
    <ApolloProvider client={ADMIN_CLIENT}>
      <GlobalVariables />
      <AuthProvider>
        <Helmet>
          <title>{titleBuilder()}</title>
        </Helmet>
        <AdminRoutes />
      </AuthProvider>
    </ApolloProvider>
  )
}

export default App
