import React from 'react'

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const AttentionIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <circle cx="12" cy="13" r="11" fill="#EF9B28" />
    <path d="M12 17.5V18" stroke="white" strokeWidth="3" strokeLinecap="round" />
    <path d="M12 7.38281L12.0083 12.3828" stroke="white" strokeWidth="3" strokeLinecap="round" />
  </SvgIcon>
)

export default AttentionIcon
