import React, { useEffect } from 'react'

const useOutsideAlerter = (
  popupRef: React.MutableRefObject<null | HTMLDivElement>,
  setOpenState: (arg: boolean) => void,
): void => {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (popupRef.current && !popupRef.current.contains(event.target as Element)) {
        setOpenState(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
}

export default useOutsideAlerter
