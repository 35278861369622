import React from 'react'
import ReactDOM from 'react-dom'
import { Route, Router, Switch } from 'react-router-dom'

import { HISTORY } from 'config'
import App from './App'
import reportWebVitals from './reportWebVitals'

import './styles/fonts.scss'

const $root: HTMLElement | null | undefined = document.getElementById('root')

if ($root) {
  ReactDOM.render(
    <Router history={HISTORY}>
      <Switch>
        <Route component={App} />
      </Switch>
    </Router>,
    $root,
  )
}

reportWebVitals()
