import React, { FC, useCallback } from 'react'
import { Formik, FormikHelpers } from 'formik'
import { object, string } from 'yup'

import Text, { H2 } from 'components/Typography'
import Button from 'components/Button'
import Dialog from 'components/Dialog'
import DialogContent from 'components/DialogContent'
import DialogCloseButton from 'components/DialogCloseButton'
import Link from 'components/Link'

import TextInput from 'components/Inputs/TextInput'
import DragAndDropInput from 'components/Inputs/DragAndDropInput'
import { REQUIRED_MESSAGE } from 'consts/errorMessages'
import { buildAddPriceListQuery, useAddPriceListMutation } from 'graphql/mutations/addPriceList'
import useCmsParams from 'hooks/useCmsParams'
import useAddNotify from 'hooks/useAddNotify'
import convertToFormErrors from 'utils/convertToFormErrors'
import { IPriceList } from 'types/types'

import styles from './loadPriceListDialog.module.scss'

type ILoadPriceListDialogProps = {
  isOpen: boolean
  onClose: () => void
  onSuccess: (priceList: IPriceList) => void
}

type IFileSelectedProps = {
  item: any
  onDelete: () => void
}

type ILoadPriceListDialogFormValues = {
  name: string
  file: File | null
}

const initialValues: ILoadPriceListDialogFormValues = {
  name: '',
  file: null,
}

const validationSchema = () =>
  object({
    name: string().required(REQUIRED_MESSAGE),
  })

const ADD_PRICE_LIST = buildAddPriceListQuery(`
  id
  name
  default_price
`)

const FileSelected: React.FC<IFileSelectedProps> = ({ item, onDelete }) => (
  <div className={styles.itemContainer}>
    <Text>{item instanceof File ? item.name : 'NO FILE NAME'}</Text>
    <Link component="button" onClick={onDelete}>
      Удалить
    </Link>
  </div>
)

const LoadPriceListDialog: FC<ILoadPriceListDialogProps> = ({ isOpen, onClose, onSuccess }) => {
  const { sellerId } = useCmsParams()
  const addNotify = useAddNotify()

  const [onAddPriceList, { loading }] = useAddPriceListMutation(ADD_PRICE_LIST)

  const onSubmit = useCallback(
    (
      values: ILoadPriceListDialogFormValues,
      { setErrors }: FormikHelpers<ILoadPriceListDialogFormValues>,
    ) => {
      const { name, file } = values

      return onAddPriceList({
        variables: {
          seller_id: sellerId,
          name,
          file,
          default_price: false,
        },
      })
        .then(({ data }) => {
          addNotify('success')
          if (data) onSuccess(data.addPriceList)
        })
        .catch((error) => {
          addNotify('error')
          const errors = convertToFormErrors(error)
          if (errors) setErrors(errors)
        })
    },
    [onAddPriceList, onSuccess, addNotify, sellerId],
  )

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <DialogCloseButton onClick={onClose} />

      <DialogContent className={styles.content}>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <H2 className={styles.title}>Создание прайс листа</H2>

              <TextInput name="name" margin="default" required label="Название" />

              <DragAndDropInput
                name="file"
                filesFormat=".csv, .xls, .xlsx, .xml, .yml"
                className={styles.dropZone}
                renderSelectedItem={(props) => <FileSelected {...props} />}
              />

              <div className={styles.controlsGroup}>
                <Button type="submit" disabled={loading}>
                  Сохранить
                </Button>
                <Button type="reset" variant="outlined" onClick={onClose}>
                  Отменить
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default LoadPriceListDialog
