import { DocumentNode, MutationHookOptions, gql, useMutation } from '@apollo/client'

import { Product } from 'types/types'

export type ISetOrderStatusParams = {
  id: IPrimaryKey
  order_status_id: IPrimaryKey
}

export type ISetOrderStatus = {
  setOrderStatus: Product
}

export const buildSetOrderStatusQuery = (fields: string): DocumentNode => gql`
  mutation setOrderStatus($id: ID!, $order_status_id:  ID!) {
    setOrderStatus(id: $id, order_status_id: $order_status_id) {
      ${fields}
    }
  }
`

export const useSetOrderStatusMutation = <TData = ISetOrderStatus, TOptions = ISetOrderStatusParams>(
  fields: DocumentNode,
  options?: MutationHookOptions<TData, TOptions>,
) => useMutation<TData, TOptions>(fields, options)
