import React, { memo, useCallback } from 'react'
import { FormikHelpers } from 'formik'
import { useHistory } from 'react-router-dom'
import omit from 'lodash/omit'

import { IAddBannerParams, useAddBannerMutation } from 'graphql/mutations/addBanner'
import { buildGetSellerQuery, useGetSellerQuery } from 'graphql/queries/getSeller'
import GoBackLink from 'components/GoBackLink'
import PageContainer from 'components/PageContainer'
import ResultTitle from 'components/ResultTitle'
import BannerForm, { IBannerFormValues } from 'components/forms/BannerForm'
import useCmsParams from 'hooks/useCmsParams'
import useNotifyCms from 'hooks/useNotifyCms'
import useBoolean from 'hooks/useBoolean'
import withLeaveThisPageModal from 'hocs/withLeaveThisPageModal'
import withErrorBoundary from 'hocs/withErrorBoundary'
import convertToFormErrors from 'utils/convertToFormErrors'
import createUrlFromUploadedFile from 'helpers/createUrlFromUploadedFile'
import convertUploadedImagesToImagesInput from 'utils/convertUploadedImagesToImagesInput'
import defaultFieldsHelper from 'utils/defaultFieldsHelper'
import { ImageInput } from 'types/types'
import baseSellerDefaultFields from 'consts/defaultFields/baseSeller'

import { ADD_BANNER } from './queries'

const defaultValues: IBannerFormValues = {
  name: '',
  url: '',
  text: '',
  button: '',
  status: false,
  desktopImage: null,
  mobileImage: null,
  tx_color: '#ffffff',
  show_title: true,
}

const prepareSubmitValues = (
  { name, url, status, button, text, tx_color, show_title }: IBannerFormValues,
  {
    uploadedDesktopImage,
    uploadedMobileImage,
  }: { uploadedDesktopImage: ImageInput | null; uploadedMobileImage: ImageInput | null },
): IAddBannerParams['input'] => ({
  name: name.length > 0 ? name : 'Баннер',
  url,
  status,
  text,
  button,
  tx_color,
  show_title,
  desktop_image: uploadedDesktopImage ? omit(uploadedDesktopImage, 'name') : null,
  mobile_image: uploadedMobileImage ? omit(uploadedMobileImage, 'name') : null,
})

const loadImage = async (file: File) => {
  const uploadedImgs = await createUrlFromUploadedFile([file], { type: 'banner' })
  const [convertedImg] = convertUploadedImagesToImagesInput(uploadedImgs)
  return convertedImg
}

const loadImages = (
  desktopImage: IBannerFormValues['desktopImage'],
  mobileImage: IBannerFormValues['mobileImage'],
) =>
  Promise.all([
    desktopImage instanceof File ? loadImage(desktopImage) : null,
    mobileImage instanceof File ? loadImage(mobileImage) : null,
  ])

const BannersCreate: React.FC<{ expanded_view?: boolean }> = ({ expanded_view = false }) => {
  const { sellerId } = useCmsParams()
  const addNotify = useNotifyCms()
  const { replace } = useHistory()

  const [loading, setLoading] = useBoolean()

  const [onAddBanner] = useAddBannerMutation(ADD_BANNER)

  const onSubmit = useCallback(
    async (values: IBannerFormValues, { setErrors }: FormikHelpers<IBannerFormValues>) => {
      const { desktopImage, mobileImage } = values

      setLoading.on()

      const uploadedImages = await loadImages(desktopImage, mobileImage)
      const [uploadedDesktopImage, uploadedMobileImage] = uploadedImages

      return onAddBanner({
        variables: {
          seller_id: sellerId,
          input: prepareSubmitValues(values, { uploadedDesktopImage, uploadedMobileImage }),
        },
      })
        .then(({ data }) => {
          addNotify('success')
          if (data) replace(`/${sellerId}/options/banners/${data.addBanner.id}`)
        })
        .catch((error) => {
          addNotify('error')
          const errors = convertToFormErrors(error)
          if (errors) setErrors(errors)
        })
        .finally(() => setLoading.off())
    },
    [onAddBanner, addNotify, replace, setLoading, sellerId],
  )

  return (
    <PageContainer>
      <GoBackLink href={`/${sellerId}/options/banners`} />
      <ResultTitle>Баннеры</ResultTitle>
      <BannerForm
        title="Создание баннера"
        isExtendedHomeView={expanded_view}
        initialValue={defaultValues}
        submitLoading={loading}
        onSubmit={onSubmit}
      />
    </PageContainer>
  )
}

const GET_SELLER = buildGetSellerQuery(`id expanded_view`)

const BannersEditWrapper: React.FC = () => {
  const { sellerId } = useCmsParams()

  const { data, loading } = useGetSellerQuery(GET_SELLER, {
    fetchPolicy: 'no-cache',
    variables: { id: sellerId },
  })

  const { expanded_view } = defaultFieldsHelper(data?.getSeller, baseSellerDefaultFields)

  return loading ? null : <BannersCreate expanded_view={expanded_view} />
}

export default withErrorBoundary(withLeaveThisPageModal(memo(BannersEditWrapper)))
