import React, { useRef, useEffect } from 'react'
import clsx from 'clsx'

import styles from './formControlsPanel.module.scss'

type IFormControlsPanelProps = {
  className?: string
}

const FormControlsPanel: React.FC<IFormControlsPanelProps> = ({ className, children }) => {
  const elementRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => entry.target.classList.toggle(styles.isSticky, entry.intersectionRatio < 1),
      { threshold: [1] },
    )

    if (elementRef.current) {
      observer.observe(elementRef.current)
    }

    return () => observer.disconnect()
  }, [])

  return (
    <div className={clsx(styles.container, className)} ref={elementRef}>
      {children}
    </div>
  )
}

export default FormControlsPanel
