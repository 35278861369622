import { DocumentNode, MutationHookOptions, gql, useMutation } from '@apollo/client'

import { Status } from 'types/types'

export type IDeleteCompany = {
  delCompany: Status
}

export type IDeleteCompanyParams = {
  id: IPrimaryKey
}

export const DELETE_COMPANY = gql`
  mutation delCompany($id: ID!) {
    delCompany(id: $id) {
      status
      message
    }
  }
`

export const useDeleteCompanyMutation = <TData = IDeleteCompany, TOptions = IDeleteCompanyParams>(
  fields: DocumentNode,
  options?: MutationHookOptions<TData, TOptions>,
) => useMutation<TData, TOptions>(fields, options)
