import React from 'react'

import CheckboxLabel from 'components/CheckboxLabel'

import styles from './option.module.scss'

interface IProps {
  option: IOption
  isSelected: boolean
  onSelect: (value: IOption) => void
}

const Option: React.FC<IProps> = ({ option, isSelected, onSelect }) => (
  <CheckboxLabel
    className={styles.item}
    checked={isSelected}
    label={option.name}
    onClick={() => onSelect(option)}
  />
)

export default Option
