import React, { createContext, useContext } from 'react'

import useHistoryBlock from 'hooks/useHistoryBlock'
import LeaveThisPageModal from 'components/ui-kit/LeaveThisPageModal'

const getDisplayName = <T,>(WrappedComponent: React.FC<T>): string =>
  WrappedComponent.displayName || WrappedComponent.name || 'Component'

type LeavePageContexType = {
  unblocking: () => void
  blocking: () => void
}

const LeavePageContext = createContext<LeavePageContexType>({
  unblocking: () => {},
  blocking: () => {},
})

const withLeaveThisPageModal = <T,>(WrappedComponent: React.FC<T>): React.FC<T> => {
  const WithLeaveThisPageModal: React.FC<T> = (props) => {
    const { isDialogOpen, unblocking, blocking, closeModal, onExit } = useHistoryBlock()

    return (
      <LeavePageContext.Provider value={{ unblocking, blocking }}>
        <WrappedComponent {...props} />
        {isDialogOpen && <LeaveThisPageModal onClose={closeModal} onExit={onExit} />}
      </LeavePageContext.Provider>
    )
  }

  WithLeaveThisPageModal.displayName = `WithLeaveThisPageModal(${getDisplayName(WrappedComponent)})`

  return WithLeaveThisPageModal
}

export default withLeaveThisPageModal

export const useLeavePage = (): LeavePageContexType => useContext(LeavePageContext)
