import React, { ReactNode, memo, useMemo } from 'react'
import { Formik, FormikHelpers } from 'formik'

import Button from 'components/Button'
import FormControlsPanel from 'components/FormControlsPanel'
import LeavePageDirtyFormHelper from 'components/LeavePageDirtyFormHelper'
import AddSubmitShortcutFormHelper from 'components/AddSubmitShortcutFormHelper'
import withErrorBoundary from 'hocs/withErrorBoundary'
import { useGetClientsQuery, buildGetClientsQuery } from 'graphql/queries/getClients'
import useCmsParams from 'hooks/useCmsParams'
import { maxPaginate } from 'consts'
import DropMenu from 'components/DropMenu'
import LimitText from 'components/LimitText'
import Text from 'components/Typography'
import TextInput from 'components/Inputs/TextInput'
import SwitchInput from 'components/Inputs/SwitchInput'
import MultiSelectInput from 'components/Inputs/MultiSelectInput'
import phoneTransform from 'utils/formInputs/phoneTransform'

import validationSchema from './utils/validationSchema'
import { IContactFormValues } from './types'

import styles from './contactForm.module.scss'

type IContactFormProps = {
  submitLoading: boolean
  initialValue: IContactFormValues
  onSubmit: (values: IContactFormValues, helpers: FormikHelpers<IContactFormValues>) => void
  createdAt?: ReactNode
  renderControls?: () => ReactNode
}

const GET_CLIENTS = buildGetClientsQuery(`
  id
  name
`)

const Row: React.FC = ({ children }) => <div className={styles.row}>{children}</div>

const ContactForm: React.FC<IContactFormProps> = ({
  submitLoading,
  initialValue,
  onSubmit,
  createdAt,
  renderControls = () => {},
}) => {
  const { sellerId } = useCmsParams()

  const { data: clientsData, loading: clientsLoading } = useGetClientsQuery(GET_CLIENTS, {
    variables: {
      seller_id: sellerId,
      paginate: maxPaginate,
    },
    fetchPolicy: 'network-only',
  })

  const clientsOptions: IOption[] = useMemo(() => {
    if (!clientsData) return []
    return clientsData.getClients.list.map(({ id, name = 'NO_NAME' }) => ({ id, name }))
  }, [clientsData])

  return (
    <Formik
      enableReinitialize
      initialValues={initialValue}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <LeavePageDirtyFormHelper />
          <AddSubmitShortcutFormHelper />
          <DropMenu title="Данные о партнере">
            <Row>
              <TextInput
                name="name"
                required
                label="Фамилия, имя и отчество"
                placeholder="Введите фамилию, имя и отчество"
                inputProps={{ maxLength: 100 }}
                labelAsideRight={<LimitText limit={100} value={values.name} />}
              />
              <SwitchInput
                name="status"
                className={styles.switchInput}
                placement="right"
                gap="medium"
                label={
                  <Text size="small" color="textSecondary">
                    {values.status ? 'активен' : 'неактивен'}
                  </Text>
                }
              />
            </Row>
            <Row>
              <TextInput
                name="phone"
                label="Телефон"
                placeholder="Введите номер телефона"
                inputProps={{ maxLength: 30 }}
                labelAsideRight={<LimitText limit={30} value={values.phone} />}
                transform={phoneTransform}
              />

              <TextInput
                required
                name="email"
                label="E-mail"
                placeholder="example@mail.ru"
                inputProps={{ maxLength: 50 }}
                labelAsideRight={<LimitText limit={50} value={values.email} />}
              />
            </Row>
            <Row>
              <MultiSelectInput
                name="client_ids"
                fullWidth
                hasClear
                loading={clientsLoading}
                label="Контрагенты"
                placeholder="Выберите"
                options={clientsOptions}
              />
            </Row>
            {createdAt}
          </DropMenu>

          <FormControlsPanel className={styles.controlsPanel}>
            <Button type="submit" disabled={submitLoading}>
              Сохранить
            </Button>

            {renderControls()}
          </FormControlsPanel>
        </form>
      )}
    </Formik>
  )
}

export default withErrorBoundary(memo(ContactForm))
