import { DocumentNode, MutationHookOptions, gql, useMutation } from '@apollo/client'

import { SettingInput, ISettings } from 'types/types'

export type ISetSellerSettingsParams = {
  seller_id: IPrimaryKey
  input: SettingInput
}

export type ISetSellerSettings = {
  setSellerSettings: ISettings
}

export const SET_SELLER_SETTINGS = gql`
  mutation setSellerSettings($seller_id: ID!, $input: SettingInput) {
    setSellerSettings(seller_id: $seller_id, input: $input) {
      seller_id
      hd_color
      tx_color
      lk_color
      bg_color
      fill_color
      main_color
      second_color
      socials {
        name
        link
      }
      time_works {
        name
        start
        end
      }
    }
  }
`

export const buildSetSellerSettingsQuery = (fields: string): DocumentNode => gql`
  mutation setSellerSettings($seller_id: ID!, $input: SettingInput) {
    setSellerSettings(seller_id: $seller_id, input: $input) {
      ${fields}
    }
  }
`

export const useSetSellerSettings = <TData = ISetSellerSettings, TOptions = ISetSellerSettingsParams>(
  fields: DocumentNode,
  options?: MutationHookOptions<TData, TOptions>,
) => useMutation<TData, TOptions>(fields, options)
