import React, { useEffect } from 'react'

import { H2 } from 'components/Typography'
import ErrorIcon from 'components/Icons/ErrorIcon'
import Dialog from 'components/Dialog'
import DialogContent from 'components/DialogContent'
import DialogCloseButton from 'components/DialogCloseButton'

import styles from './ErrorModal.module.scss'

interface IErrorModalProps {
  isOpen: boolean
  errorText?: string
  onClose: () => void
}

const ErrorModal: React.FC<IErrorModalProps> = ({ isOpen, errorText, onClose }) => {
  useEffect(() => {
    if (!isOpen) return
    const timer = setTimeout(() => onClose(), 2000)
    return () => clearTimeout(timer)
  }, [isOpen])

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <DialogCloseButton onClick={onClose} />
      <DialogContent className={styles.content}>
        <ErrorIcon className={styles.icon} color="error" fontSize="large" />
        <H2 className={styles.title}>Произошла ошибка</H2>
        <p className={styles.subtitle}>{errorText || 'Попробуйте еще раз'}</p>
      </DialogContent>
    </Dialog>
  )
}

export default ErrorModal
