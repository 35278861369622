import { DocumentNode, MutationHookOptions, gql, useMutation } from '@apollo/client'

import { IStore } from 'types/types'

export type IAddStore = {
  addStore: IStore
}

export type IAddStoreParams = {
  seller_id: IPrimaryKey
  name: string
}

export const buildAddStoreQuery = (fields: string): DocumentNode => gql`
  mutation addStore($seller_id: ID!, $name: String!) {
    addStore(seller_id: $seller_id, name: $name) {
      ${fields}
    }
  }
`

export const useAddStoreMutation = <TData = IAddStore, TOptions = IAddStoreParams>(
  fields: DocumentNode,
  options?: MutationHookOptions<TData, TOptions>,
) => useMutation<TData, TOptions>(fields, options)
