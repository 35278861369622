import { useMemo, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'

import { maxPaginate } from 'consts'
import { IGetAttributes, IGetAttributesParams, buildGetAttributesQuery } from 'graphql/queries/getAttributes'
import useCmsParams from 'hooks/useCmsParams'
import convertAttributesToHash from 'utils/convertAttributesToHash'

const GET_ATTRIBUTES = buildGetAttributesQuery(`
  id
  name

  attribute_values {
    id
    value
  }
`)

const useAttributesQuery = (variantsEnabled: boolean) => {
  const { sellerId } = useCmsParams()

  const [loadAttributes, { data, loading, error }] = useLazyQuery<IGetAttributes, IGetAttributesParams>(
    GET_ATTRIBUTES,
    {
      variables: {
        seller_id: sellerId,
        paginate: maxPaginate,
      },
      fetchPolicy: 'network-only',
    },
  )

  const attributesList = useMemo(() => {
    if (!data) return []
    return data.getAttributes.list
  }, [data])

  const attributesHash = useMemo(() => {
    if (!data) return {}
    return convertAttributesToHash(data.getAttributes.list)
  }, [data])

  useEffect(() => {
    if (!variantsEnabled) return
    loadAttributes()
  }, [loadAttributes, variantsEnabled])

  return {
    attributesLoading: loading,
    attributesError: error,
    attributesList,
    attributesHash,
  }
}

export default useAttributesQuery
