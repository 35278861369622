import React, { ReactElement, useMemo } from 'react'
import FormControl, { FormControlProps } from '@material-ui/core/FormControl'
import InputLabel, { InputLabelProps } from '@material-ui/core/InputLabel'
import FormHelperText, { FormHelperTextProps } from '@material-ui/core/FormHelperText'
import FilledInput, { FilledInputProps } from '@material-ui/core/FilledInput'

import createUniqId from 'utils/createUniqId'
import AttentionIcon from 'components/Icons/AttentionIcon'
import StarIcon from 'components/Icons/StarIcon'
import block from 'utils/block'

import styles from './textField.module.scss'

export type ITextFieldProps = Omit<FilledInputProps, 'margin'> & {
  disableNumberControls?: boolean
  labelAsideRight?: ReactElement | null
  label?: string
  helperText?: string | null
  focused?: FormControlProps['focused']
  offset?: 'default'
  margin?: 'default'

  formControlProps?: FormControlProps
  helperTextProps?: FormHelperTextProps
  labelProps?: InputLabelProps
}

const b = block(styles)

const TextField: React.FC<ITextFieldProps> = ({
  disableNumberControls = false,
  error = false,
  required,
  fullWidth = true,
  autoComplete = 'off',
  inputProps: inputPropsProp = {},
  endAdornment,
  labelAsideRight,
  label,
  helperText,
  focused,
  className,
  offset,
  margin,

  formControlProps,
  helperTextProps,
  labelProps,

  ...props
}) => {
  const uniqId = useMemo(() => createUniqId(), [])

  const inputProps: FilledInputProps['inputProps'] = useMemo(() => {
    const { className: inputPropsClassName, ...inputPropsParams } = inputPropsProp

    return {
      className: b('input', { disableNumberControls }, inputPropsClassName),
      ...inputPropsParams,
    }
  }, [inputPropsProp, disableNumberControls])

  return (
    <FormControl
      className={b('formControl', { offset, margin }, className)}
      error={error}
      fullWidth={fullWidth}
      focused={focused}
      {...formControlProps}
    >
      {label ? (
        <InputLabel htmlFor={uniqId} {...labelProps}>
          <span className={styles.labelGroup}>
            {label}
            {required ? <StarIcon /> : null}
          </span>
          {labelAsideRight}
        </InputLabel>
      ) : null}
      <FilledInput
        id={uniqId}
        autoComplete={autoComplete}
        endAdornment={error ? <AttentionIcon color="error" /> : endAdornment}
        inputProps={inputProps}
        {...props}
      />
      {helperText ? <FormHelperText {...helperTextProps}>{helperText}</FormHelperText> : null}
    </FormControl>
  )
}

export default TextField
