import React, { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import Collapse from '@material-ui/core/Collapse'

import { BaseProducts, PBaseProducts, Product as IProduct } from 'types/types'
import SuccessItemDialog from 'components/Dialogs/SuccessItemDialog'
import Link from 'components/Link'
import Switch from 'components/Switch'
import Text from 'components/Typography'
import TrashIcon from 'components/Icons/TrashIcon'
import CopyIcon from 'components/Icons/CopyIcon'
import EditIcon from 'components/Icons/EditIcon'
import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import { IUseChoicesActions } from 'hooks/useChoises'
import block from 'utils/block'
import renderCurrencySymbol from 'utils/renderCurrencySymbol'
import { DELETE_BASE_PRODUCT } from 'graphql/mutations/deleteProduct'
import { COPY_BASE_PRODUCT } from 'graphql/mutations/copyProduct'
import { SET_BASE_PRODUCT_STATUS } from 'graphql/mutations/setBaseProductStatus'
import {
  ISetProductStatusResponse,
  ISetProductStatusParams,
  buildSetProductStatusQuery,
} from 'graphql/mutations/setProductStatus'
import useCmsParams from 'hooks/useCmsParams'
import useNotifyCms from 'hooks/useNotifyCms'
import useCurrentSeller from 'hooks/useCurrentSeller'
import ListControlGroup from 'components/ListControlGroup'
import { GET_BASE_PRODUCTS } from 'views/BaseProducts/BaseProductsList/queries'

import Wrapper from './components/Wrapper'
import SelectToggle from './components/SelectToggle'
import ToggleVariantsButton from './components/ToggleVariantsButton'
import Variant from './components/Variant'
import Image from './components/Image'

import styles from './Product.module.scss'

type IToggleActions = Pick<IUseChoicesActions<string>, 'toggle' | 'isSelected'>

type IProductProps = IToggleActions & {
  baseProduct: BaseProducts
}

export const SET_PRODUCT_STATUS = buildSetProductStatusQuery(`
  id
  base_product_id
  status
`)

const numberWithSpaces = (currentAmount: number | null): string => {
  if (!currentAmount) return '–'
  return `${currentAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} шт.`
}

const b = block(styles)

const updateBaseProductsListStatus = (apiList: PBaseProducts, item: BaseProducts): PBaseProducts => ({
  ...apiList,
  list: apiList.list.map((baseProduct) => {
    if (baseProduct.id === item.id) {
      return {
        ...baseProduct,
        status: item.status,
      }
    }
    return baseProduct
  }),
})

const updateProduct = (apiList: PBaseProducts, item: IProduct | null): PBaseProducts => {
  if (!item) return apiList

  return {
    ...apiList,
    list: apiList.list.map((baseProduct) => {
      if (baseProduct.id === item.base_product_id) {
        return {
          ...baseProduct,
          products: baseProduct.products.map((product) => {
            if (product.id === item.id) {
              return {
                ...product,
                status: item.status,
              }
            }
            return product
          }),
        }
      }
      return baseProduct
    }),
  }
}

const Product: React.FC<IProductProps> = ({ baseProduct, isSelected, toggle }) => {
  const { id: baseProductId, variants_status: variantsStatus, name, status, images, products } = baseProduct

  const product = products[0]
  const { vendor_code: codeProduct = '–', amount, price, stores } = product

  const { push } = useHistory()
  const { sellerId } = useCmsParams()
  const addNotify = useNotifyCms()
  const { stores_status: storesStatus = false, settings } = useCurrentSeller()

  const [confirmDialog, setConfirmDialog] = useState<'delete' | 'copy' | null>(null)
  const [isOpenVariants, setIsOpenVariants] = useState<boolean>(false)
  const [isComplete, setCompleteState] = useState<boolean>(false)

  const totalVariants = products.length

  const totalAmount = useMemo(() => {
    if (storesStatus) {
      if (!stores) return null

      if (variantsStatus) {
        return products.reduce((acc, productItem) => {
          const storesAmount = productItem.stores?.reduce(
            (storesAcc, store) => storesAcc + (store.amount || 0),
            0,
          )
          return storesAmount ? acc + storesAmount : acc
        }, 0)
      }

      return stores.reduce((acc, item) => acc + (item.amount || 0), 0)
    }

    if (!amount) return null

    if (variantsStatus) return products.reduce((acc, item) => acc + (item.amount || 0), 0)

    return amount
  }, [amount, variantsStatus, products, storesStatus, stores])

  const [onDeleteProduct] = useMutation(DELETE_BASE_PRODUCT, {
    refetchQueries: [GET_BASE_PRODUCTS],
    onCompleted: () => {
      setConfirmDialog(null)
      addNotify('success')
    },
    onError: () => addNotify('error'),
  })

  const [onCopyProduct, { data: copyData }] = useMutation(COPY_BASE_PRODUCT, {
    refetchQueries: [GET_BASE_PRODUCTS],
    onCompleted: () => {
      setConfirmDialog(null)
      setCompleteState(true)
    },
  })

  const [onSetBaseProductStatus] = useMutation(SET_BASE_PRODUCT_STATUS, {
    onError: () => addNotify('error'),
    update(cache, response) {
      cache.modify({
        fields: {
          getBaseProducts(apiProducts: PBaseProducts): PBaseProducts {
            return updateBaseProductsListStatus(apiProducts, response.data.setBaseProductStatus)
          },
        },
      })
    },
  })

  const [setProductStatus] = useMutation<ISetProductStatusResponse, ISetProductStatusParams>(
    SET_PRODUCT_STATUS,
    {
      onError: () => addNotify('error'),
      update(cache, response) {
        cache.modify({
          fields: {
            getBaseProducts(apiProducts: PBaseProducts): PBaseProducts {
              return updateProduct(apiProducts, response.data?.setProductStatus || null)
            },
          },
        })
      },
    },
  )

  const handlerDelete = (id: number) => onDeleteProduct({ variables: { id } })

  const handlerCopy = (id: number) => onCopyProduct({ variables: { id } })

  const onBaseProductStatusChange = (event: InputChangeEvent) => {
    const currentStatus = event.target.checked

    onSetBaseProductStatus({
      variables: {
        id: baseProductId,
        status: currentStatus,
      },
    })
  }

  const onCopyClick = () => {
    setConfirmDialog('copy')
  }

  const onDeleteClick = () => {
    setConfirmDialog('delete')
  }

  const onSetProductVariantsStatus = useCallback(
    (payload: IProduct) => {
      setProductStatus({
        variables: {
          id: payload.id,
          status: !payload.status,
        },
      })
    },
    [setProductStatus],
  )

  const onConfirmDialogSubmit = () => {
    if (confirmDialog === 'delete') {
      handlerDelete(Number(baseProduct.id))
    } else {
      handlerCopy(Number(baseProduct.id))
    }
  }

  return (
    <div className={styles.outerWrapper}>
      <Wrapper>
        <SuccessItemDialog
          isOpen={isComplete}
          title="Товар успешно скопирован"
          toItemText="К товару"
          onClose={() => setCompleteState(false)}
          onSubmitBtn={() => setCompleteState(false)}
          onToItem={() => push(`/${sellerId}/catalog/products/${copyData?.copyBaseProduct?.message}`)}
        />

        <ConfirmDialog
          isOpen={!!confirmDialog}
          title={
            confirmDialog === 'delete'
              ? 'Вы уверены, что хотите удалить товар?'
              : 'Вы действительно хотите создать копию товара?'
          }
          submitBtnText={confirmDialog === 'delete' ? 'Да, удалить' : 'Да, создать'}
          onSubmit={onConfirmDialogSubmit}
          onClose={() => setConfirmDialog(null)}
        />

        <SelectToggle productId={baseProductId} isSelected={isSelected} toggle={toggle} />

        <Link href={`/${sellerId}/catalog/products/${baseProduct.id}`} className={styles.link}>
          <Image images={images} />
          <div className={styles.infoGroup}>
            <p className={b('name', { hasVariants: variantsStatus })}>{name}</p>
            {!variantsStatus && <p className={styles.code}>Арт. {codeProduct}</p>}
            <div className={styles.amountGroup}>
              <p className={styles.amount}>{numberWithSpaces(totalAmount)}</p>

              {variantsStatus && (
                <ToggleVariantsButton
                  isOpen={isOpenVariants}
                  counter={totalVariants}
                  onClick={() => setIsOpenVariants((prevState) => !prevState)}
                />
              )}
            </div>
          </div>
          {!variantsStatus && (
            <Text className={styles.price} color="textPrimary">
              {price}&nbsp;{renderCurrencySymbol(settings?.currency)}
            </Text>
          )}
        </Link>

        <Switch
          className={styles.statusGroup}
          checked={status}
          gap="small"
          label={
            <Text size="small" color="textSecondary">
              {status ? 'доступен' : 'недоступен'}
            </Text>
          }
          onChange={onBaseProductStatusChange}
        />

        <ListControlGroup>
          <Link href={`/${sellerId}/catalog/products/${baseProductId}`} startIcon={<EditIcon />}>
            Редактировать
          </Link>
          <Link component="button" startIcon={<CopyIcon />} onClick={onCopyClick}>
            Создать копию
          </Link>
          <Link component="button" startIcon={<TrashIcon />} onClick={onDeleteClick}>
            Удалить
          </Link>
        </ListControlGroup>
      </Wrapper>

      {variantsStatus && (
        <Collapse in={isOpenVariants}>
          <div className={styles.variantsWrapper}>
            {products.map((variant) => (
              <Variant key={variant.id} product={variant} onStatusToggle={onSetProductVariantsStatus} />
            ))}
          </div>
        </Collapse>
      )}
    </div>
  )
}

export default Product
