import { DocumentNode, gql } from '@apollo/client'

import { IPriceRule, IPriceRuleInput } from 'types/types'

export type IAddPriceRuleParams = {
  seller_id: IPrimaryKey
  input: IPriceRuleInput
}

export type IAddPriceRule = {
  addPriceRule: IPriceRule
}

export const ADD_PRICE_RULE = gql`
  mutation addPriceRule($seller_id: ID!, $input: PriceRuleInput) {
    addPriceRule(seller_id: $seller_id, input: $input) {
      id
    }
  }
`

export const buildAddPriceRule = (fields: string): DocumentNode => gql`
  mutation addPriceRule($seller_id: ID!, $input: PriceRuleInput) {
    addPriceRule(seller_id: $seller_id, input: $input) {
      ${fields}
    }
  }
`
