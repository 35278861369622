import React from 'react'

import Text from 'components/Typography'

import styles from './tableHeader.module.scss'

const TableHeader: React.FC = () => (
  <div className={styles.container}>
    <Text bold className={styles.name} size="small">
      Наименование
    </Text>
    <Text bold className={styles.ordersCount} size="small">
      Заказы
    </Text>
  </div>
)

export default TableHeader
