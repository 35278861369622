import React, { ReactNode, useMemo } from 'react'

import DropMenu from 'components/DropMenu'
import LimitText from 'components/LimitText'
import Text from 'components/Typography'
import ApiError from 'components/ApiError'
import TextInput from 'components/Inputs/TextInput'
import SwitchInput from 'components/Inputs/SwitchInput'
import phoneTransform from 'utils/formInputs/phoneTransform'
import SelectInput from 'components/Inputs/SelectInput'
import useCmsParams from 'hooks/useCmsParams'
import { maxPaginate } from 'consts'
import { buildGetManagersQuery, useGetManagersQuery } from 'graphql/queries/getManagers'

import styles from './mainData.module.scss'

import { IClientFormValues } from '../../types'

type IMainDataProps = {
  values: IClientFormValues
  deliveryAddresses?: ReactNode
  createdAt?: ReactNode
}

const GET_MANAGERS = buildGetManagersQuery(`
  id 
  name
`)

const Row: React.FC = ({ children }) => <div className={styles.row}>{children}</div>

const MainData: React.FC<IMainDataProps> = ({ values, deliveryAddresses, createdAt }) => {
  const { sellerId } = useCmsParams()

  const { data, loading, error } = useGetManagersQuery(GET_MANAGERS, {
    variables: {
      seller_id: sellerId,
      paginate: maxPaginate,
    },
    fetchPolicy: 'network-only',
  })

  const managersOptions = useMemo(() => {
    if (!data) return []
    return data.getManagers.list
  }, [data])

  return (
    <DropMenu title="Данные о партнере">
      <Row>
        <TextInput
          name="name"
          label="Наименование"
          placeholder="Введите наименование партнера"
          inputProps={{ maxLength: 100 }}
          labelAsideRight={<LimitText limit={100} value={values.name} />}
        />
        <SwitchInput
          name="status"
          className={styles.switchInput}
          placement="right"
          gap="medium"
          label={
            <Text size="small" color="textSecondary">
              {values.status ? 'активен' : 'неактивен'}
            </Text>
          }
        />
      </Row>
      <Row>
        <TextInput
          name="phone"
          label="Телефон"
          placeholder="Введите номер телефона"
          inputProps={{ maxLength: 30 }}
          labelAsideRight={<LimitText limit={30} value={values.phone} />}
          transform={phoneTransform}
        />

        <TextInput
          required
          name="email"
          label="E-mail"
          placeholder="example@mail.ru"
          inputProps={{ maxLength: 50 }}
          labelAsideRight={<LimitText limit={50} value={values.email} />}
        />
      </Row>

      {deliveryAddresses ? <Row>{deliveryAddresses}</Row> : null}

      <Row>
        {error ? (
          <ApiError error={error} />
        ) : (
          <SelectInput
            name="managerId"
            hasClear
            label="Менеджер"
            placeholder="Выберите менеджера"
            loading={loading}
            options={managersOptions}
          />
        )}
      </Row>

      {createdAt}
    </DropMenu>
  )
}

export default MainData
