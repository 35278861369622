import { Overrides } from '@material-ui/core/styles/overrides'

const formHelperText: Overrides['MuiFormHelperText'] = {
  root: {
    marginTop: '4px',
    fontSize: '14px',
    lineHeight: '20px',
    color: 'initial',
  },
}

export default formHelperText
