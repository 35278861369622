import React from 'react'
import clsx from 'clsx'

import styles from './row.module.scss'

type IRowProps = {
  className?: string
}

const Row: React.FC<IRowProps> = ({ className, children }) => (
  <div className={clsx(styles.container, className)}>{children}</div>
)

export default Row
