import React, { memo, ReactNode } from 'react'

import { H2 } from 'components/Typography'
import TimeIcon from 'components/Icons/TimeIcon'
import Dialog from 'components/Dialog'
import DialogContent from 'components/DialogContent'
import DialogCloseButton from 'components/DialogCloseButton'

import styles from './loadingDialog.module.scss'

type ILoadingDialogProps = {
  isOpen: boolean
  title: string
  content?: ReactNode
  onClose: () => void
}

const LoadingDialog: React.FC<ILoadingDialogProps> = ({ isOpen, title, content = null, onClose }) => (
  <Dialog isOpen={isOpen} onClose={onClose}>
    <DialogCloseButton onClick={onClose} />
    <DialogContent className={styles.content}>
      <TimeIcon className={styles.icon} />
      <H2 className={styles.text}>{title}</H2>
      {content}
    </DialogContent>
  </Dialog>
)

export default memo(LoadingDialog)
