import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import TrashIcon from 'components/Icons/TrashIcon'
import CopyIcon from 'components/Icons/CopyIcon'
import Link from 'components/Link'
import useBoolean from 'hooks/useBoolean'
import { COPY_PRICE_RULE, ICopyPriceRule, ICopyPriceRuleParams } from 'graphql/mutations/copyPriceRule'
import useNotifyCms from 'hooks/useNotifyCms'
import useCmsParams from 'hooks/useCmsParams'
import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import SuccessItemDialog from 'components/Dialogs/SuccessItemDialog'
import { DELETE_PRICE_RULE } from 'graphql/mutations/deletePriceRule'

import styles from './controls.module.scss'

const Controls: React.FC = () => {
  const { sellerId, priceRuleId } = useCmsParams()
  const addNotify = useNotifyCms()
  const { push } = useHistory()

  const [isDeleteDialogOpen, setIsDeleteDialog] = useBoolean(false)
  const [isCopyDialogOpen, setIsCopyDialog] = useBoolean(false)
  const [isCompleteCopyDialogOpen, setIsCompleteCopyDialog] = useBoolean()

  const [onSendPriceRuleClone, { data: copyData }] = useMutation<ICopyPriceRule, ICopyPriceRuleParams>(
    COPY_PRICE_RULE,
    {
      onCompleted: ({ copyPriceRule }) => {
        if (copyPriceRule.status) {
          setIsCompleteCopyDialog.on()
        } else {
          addNotify('warning')
        }
      },
      onError: () => addNotify('error'),
    },
  )

  const onCloneSubmit = useCallback(() => {
    onSendPriceRuleClone({ variables: { id: priceRuleId } })
    setIsCopyDialog.off()
  }, [onSendPriceRuleClone, priceRuleId, setIsCopyDialog])

  const onToClonedItem = useCallback(() => {
    if (!copyData) return

    setTimeout(() => {
      push(`/${sellerId}/price-rules/${copyData.copyPriceRule.message}`)
    }, 1000)

    setIsCompleteCopyDialog.off()
  }, [push, sellerId, copyData, setIsCompleteCopyDialog])

  const [onDeletePriceRule] = useMutation(DELETE_PRICE_RULE, {
    variables: { id: priceRuleId },
    onCompleted: () => {
      push(`/${sellerId}/price-rules`)
      addNotify({ kind: 'success', message: 'Ценовая группа удалена' })
    },
    onError: () => addNotify('error'),
  })

  const handlerDelete = () => onDeletePriceRule()

  return (
    <>
      <Link component="button" onClick={setIsCopyDialog.on} startIcon={<CopyIcon />}>
        Создать копию
      </Link>
      <Link component="button" onClick={setIsDeleteDialog.on} startIcon={<TrashIcon />}>
        Удалить ценовую группу
      </Link>

      <ConfirmDialog
        isOpen={isDeleteDialogOpen}
        title="Вы уверены, что хотите удалить ценовую группу?"
        submitBtnText="Да, удалить"
        onClose={setIsDeleteDialog.off}
        onSubmit={handlerDelete}
      />

      <ConfirmDialog
        isOpen={isCopyDialogOpen}
        title="Вы уверены, что хотите копировать ценовую группу?"
        submitBtnText="Да, копировать"
        onClose={setIsCopyDialog.off}
        onSubmit={onCloneSubmit}
      />

      <SuccessItemDialog
        className={styles.successItemDialog}
        isOpen={isCompleteCopyDialogOpen}
        title="Ценовая группа успешно скопирована"
        onClose={setIsCompleteCopyDialog.off}
        toItemText="К ценовой группе"
        onToItem={onToClonedItem}
      />
    </>
  )
}

export default Controls
