import { gql } from '@apollo/client'

import { Status } from 'types/types'

export type copyAttributeTypeResponse = {
  copyAttribute: Status
}

export const COPY_ATTRIBUTE = gql`
  mutation copyAttribute($id: ID!) {
    copyAttribute(id: $id) {
      status
      message
    }
  }
`
