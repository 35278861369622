import React, { memo, useCallback, useMemo, useState } from 'react'
import isEqual from 'lodash/isEqual'
import debounce from 'lodash/debounce'

import { SEARCH_INPUT_LIMIT, SEARCH_DEBOUNCE_TIMEOUT } from 'consts'
import Button from 'components/Button'
import Preloader from 'components/Preloader'
import PageContainer from 'components/PageContainer'
import ResultTitle from 'components/ResultTitle'
import ResultSearchFilter from 'components/ResultSearchFilter'
import hasPagination from 'utils/hasPagination'
import useCmsParams from 'hooks/useCmsParams'
import Pagination from 'components/Pagination'
import Link from 'components/Link'
import withErrorBoundary from 'hocs/withErrorBoundary'
import NoSearchResult from 'components/NoSearchResult'
import ApiError from 'components/ApiError'
import { buildGetContactsQuery, useGetContactsQuery } from 'graphql/queries/getContacts'
import buildPaginateParams from 'utils/buildPaginateParams'
import useSearchParams from 'hooks/useSearchParams'

import Result from './components/Result'
import Empty from './components/Empty'

import styles from './contactsList.module.scss'

type IPayload = {
  search: string
  page: string
}

const initPayload: IPayload = {
  search: '',
  page: '',
}

const GET_CONTACTS = buildGetContactsQuery(`
  id 
  name
  status
  clients {
    id
    name
  }
`)

const ContactsList: React.FC = () => {
  const { sellerId } = useCmsParams()

  const [searchParams, setSearchParams] = useSearchParams<IPayload>(initPayload)
  const { page, search } = searchParams
  const [searchInput, setSearchInput] = useState<string>(search)

  const onSearchDebounce = useMemo(
    () =>
      debounce((payload: string) => {
        setSearchParams({ ...searchParams, page: '1', search: payload })
      }, SEARCH_DEBOUNCE_TIMEOUT),
    [setSearchParams, searchParams],
  )

  const hasFilters = useMemo(() => !isEqual(initPayload, searchParams), [searchParams])

  const { data, loading, error, refetch } = useGetContactsQuery(GET_CONTACTS, {
    variables: {
      seller_id: sellerId,
      search,
      paginate: buildPaginateParams(page),
    },
    fetchPolicy: 'network-only',
  })

  const { getContacts: { list: contacts = [], elements = 0, pages = 0 } = {} } = data || {}

  const onSearchChange = useCallback(
    (event: InputChangeEvent) => {
      const { value } = event.target
      if (value.length > SEARCH_INPUT_LIMIT) return
      setSearchInput(value)
      onSearchDebounce(value)
    },
    [onSearchDebounce],
  )

  const onSearchClear = useCallback(() => {
    setSearchInput('')
    setSearchParams((prev) => ({ ...prev, page: '1', search: '' }))
  }, [setSearchParams])

  const onPageChange = useCallback(
    (nextPage: number) => {
      setSearchParams((prev) => ({ ...prev, page: nextPage.toString() }))
    },
    [setSearchParams],
  )

  const initialLoading = loading && !hasFilters
  const isEmpty = !loading && !hasFilters && !contacts.length

  if (initialLoading) return <Preloader />

  if (error) {
    return (
      <PageContainer>
        <ResultTitle>Контакты</ResultTitle>
        <div className={styles.filtersGroup}>
          <Link href={`/${sellerId}/contacts/create`}>
            <Button>Создать контакт</Button>
          </Link>
        </div>
        <ApiError error={error} />
      </PageContainer>
    )
  }

  if (isEmpty) {
    return (
      <PageContainer>
        <Empty />
      </PageContainer>
    )
  }

  return (
    <PageContainer>
      <ResultTitle>Контакты</ResultTitle>
      <div className={styles.filtersGroup}>
        <Link href={`/${sellerId}/contacts/create`}>
          <Button>Создать контакт</Button>
        </Link>

        <ResultSearchFilter
          value={searchInput}
          placeholder="Поиск по контактам"
          onChange={onSearchChange}
          onClear={onSearchClear}
        />
      </div>

      {loading ? (
        <Preloader />
      ) : (
        <>
          {contacts.length ? <Result contacts={contacts} refetchList={refetch} /> : null}

          {hasPagination(elements) && (
            <Pagination page={Number(page)} count={pages} onChange={onPageChange} />
          )}

          {contacts.length ? null : <NoSearchResult />}
        </>
      )}
    </PageContainer>
  )
}

export default withErrorBoundary(memo(ContactsList))
