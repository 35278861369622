import React from 'react'
import MuiTab, { TabProps } from '@material-ui/core/Tab'

import block from 'utils/block'

import styles from './tab.module.scss'

type ITabProps = TabProps & {
  isAccent?: boolean
}

const b = block(styles)

const Tab: React.FC<ITabProps> = ({ isAccent = false, className, ...props }) => (
  <MuiTab className={b('tab', { accent: isAccent }, className)} {...props} />
)

export default Tab
