import { buildSetAttributeQuery } from 'graphql/mutations/setAttribute'
import { buildGetAttributeQuery } from 'graphql/queries/getAttribute'

const commonFields = `
  id
  name
  description
  filters
  number
`

export const SET_ATTRIBUTE = buildSetAttributeQuery(commonFields)
export const GET_ATTRIBUTE = buildGetAttributeQuery(commonFields)
