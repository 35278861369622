import React, { memo } from 'react'
import { useMutation } from '@apollo/client'

import {
  ISetSellerHideAmount,
  ISetSellerHideAmountParams,
  buildSetSellerHideAmountQuery,
} from 'graphql/mutations/setSellerHideAmount'
import useNotifyCms from 'hooks/useNotifyCms'
import useCmsParams from 'hooks/useCmsParams'
import Switch from 'components/Switch'
import Text from 'components/Typography'
import { Seller } from 'types/types'
import defaultFieldsHelper from 'utils/defaultFieldsHelper'
import baseSellerDefaultFields from 'consts/defaultFields/baseSeller'

type IStatusIndexProps = {
  seller: Seller
}

const SET_SELLER = buildSetSellerHideAmountQuery(`
  id
  hide_amount
`)

const HideStoreAmount: React.FC<IStatusIndexProps> = ({ seller }) => {
  const { hide_amount } = defaultFieldsHelper(seller, baseSellerDefaultFields)

  const addNotify = useNotifyCms()
  const { sellerId } = useCmsParams()

  const [onSetSellerHideAmount, { loading }] = useMutation<ISetSellerHideAmount, ISetSellerHideAmountParams>(
    SET_SELLER,
    {
      variables: {
        id: sellerId,
        hide_amount: !hide_amount,
      },
      onCompleted: () => addNotify('success'),
      onError: () => addNotify('error'),
      update(cache, { data: resData }) {
        if (!resData?.setSellerHideAmount) throw new Error('Something went wrong')

        cache.modify({
          fields: {
            // NOTE: НЕ Вызывает обновление всех getSeller (потому что нет поля hide_amount в других запросах?)
            getSeller(currentSeller: Seller) {
              return {
                ...currentSeller,
                hide_amount: resData.setSellerHideAmount.hide_amount,
              }
            },
          },
        })
      },
    },
  )

  return (
    <Switch
      disabled={loading}
      checked={hide_amount}
      onChange={() => onSetSellerHideAmount()}
      label={<Text>Скрывать точные значения остатков в магазине</Text>}
      placement="right"
      gap="medium"
    />
  )
}

export default memo(HideStoreAmount)
