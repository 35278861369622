import React from 'react'
import { useMutation } from '@apollo/client'

import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import { DELETE_MANAGER } from 'graphql/mutations/deleteManager'
import useBoolean from 'hooks/useBoolean'
import useNotifyCms from 'hooks/useNotifyCms'

type IRenderParams = {
  onClick: () => void
}

type IDeleteManagerProps = {
  managerId: IPrimaryKey
  onSuccess: () => void
  render: (params: IRenderParams) => void
}

const DeleteManager: React.FC<IDeleteManagerProps> = ({ managerId, onSuccess, render }) => {
  const addNotify = useNotifyCms()

  const [iseOpen, setIsOpen] = useBoolean(false)

  const [onDeleteManager] = useMutation(DELETE_MANAGER, {
    variables: {
      id: managerId,
    },
    onCompleted: () => {
      setIsOpen.off()
      addNotify({ kind: 'success', message: 'Менеджер удален' })
      onSuccess()
    },
    onError: () => {
      addNotify('error')
      setIsOpen.off()
    },
  })

  return (
    <>
      <ConfirmDialog
        isOpen={iseOpen}
        title="Вы уверены, что хотите удалить менеджера?"
        submitBtnText="Да, удалить"
        onSubmit={() => onDeleteManager()}
        onClose={setIsOpen.off}
      />

      {render({ onClick: setIsOpen.on })}
    </>
  )
}

export default DeleteManager
