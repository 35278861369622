import { buildSetClientQuery } from 'graphql/mutations/setClient'
import { buildGetClientQuery } from 'graphql/queries/getClient'

const clientCommonFields = `
  id
  status
  phone
  email
  name
  delivery_addresses {
    id
    address
  }
`

const companies = `
  id
  name
  inn
`

const contacts = `
  id
  name
`

const manager = `
  manager {
    id
    name
  }
`

export const GET_CLIENT = buildGetClientQuery(`
  ${clientCommonFields}

  created_at

  orders {
    id
    number
    created_at
    customer {
      company {
        name
      }
    }
    products {
      id
      price
      amount
    }
  }
  price_rules {
    id
    name
  }
  ${manager}
  companies { ${companies} }
  contacts { ${contacts} }
`)

export const SET_CLIENT = buildSetClientQuery(`
  ${clientCommonFields}

  ${manager}
  companies { ${companies} }
  contacts { ${contacts} }
`)
