import { useParams } from 'react-router-dom'

type IUseParamsObject = {
  sellerId: string
  orderId: string
  productId: string
  categoryId: string
  attributeId: string
  priceRuleId: string
  clientId: string
  contactId: string
  managerId: string
  priceListId: string
  companyId: string
  bannerId: string
}

const useCmsParams = (): IUseParamsObject => useParams<IUseParamsObject>()

export default useCmsParams
