import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import Link from 'components/Link'
import useCmsParams from 'hooks/useCmsParams'
import TrashIcon from 'components/Icons/TrashIcon'
import DeleteBanner from 'components/ModelControls/DeleteBanner'

const Controls: React.FC = () => {
  const { sellerId, bannerId } = useCmsParams()
  const { push } = useHistory()

  const onDeleteSuccess = useCallback(() => {
    push(`/${sellerId}/options/banners/`)
  }, [push, sellerId])

  return (
    <DeleteBanner
      bannerId={bannerId}
      onSuccess={onDeleteSuccess}
      render={(params) => (
        <Link component="button" startIcon={<TrashIcon />} onClick={params.onClick}>
          Удалить баннер
        </Link>
      )}
    />
  )
}

export default Controls
