import React, { FC, useCallback, useRef } from 'react'
import { useField } from 'formik'

import Button from 'components/Button'
import IconButton from 'components/IconButton'
import Link from 'components/Link'
import Text from 'components/Typography'
import formikFieldEvent from 'utils/formInputs/formikFieldEvent'
import DownloadIcon from 'components/Icons/DownloadIcon'
import TrashIcon from 'components/Icons/TrashIcon'

import { IPriceListFormValues } from '../../types'

import styles from './fileInput.module.scss'

type IFileInputProps = {
  downloadLink: string | null
}

const name = 'file'

const FileInput: FC<IFileInputProps> = ({ downloadLink }) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const [{ value: file, onChange }] = useField<IPriceListFormValues['file']>(name)

  const onLoadClick = useCallback(() => {
    inputRef.current?.click()
  }, [])

  const onChangeHandler = useCallback(
    (event: InputChangeEvent) => {
      event.preventDefault()
      const newFiles = event.currentTarget.files
      if (!newFiles) return
      const [nextFile] = Object.values(newFiles)
      onChange(formikFieldEvent(name, nextFile))
      // eslint-disable-next-line no-param-reassign
      if (event.target.value) event.target.value = ''
    },
    [onChange],
  )

  const clearFile = useCallback(() => {
    onChange(formikFieldEvent(name, null))
  }, [onChange])

  return (
    <div className={styles.container}>
      <Button variant="outlined" onClick={onLoadClick}>
        Загрузить файл с ценами
      </Button>

      <input
        ref={inputRef}
        type="file"
        accept=".xls, .xlsx, .csv"
        multiple={false}
        className={styles.hiddenInput}
        onChange={onChangeHandler}
      />

      {file ? (
        <div className={styles.fileNameGroup}>
          <Text className={styles.fileName}>{file.name}</Text>
          <IconButton onClick={clearFile}>
            <TrashIcon color="action" />
          </IconButton>
        </div>
      ) : null}

      {!file && downloadLink ? (
        <Link external download href={downloadLink} startIcon={<DownloadIcon />}>
          Скачать файл
        </Link>
      ) : null}
    </div>
  )
}

export default FileInput
