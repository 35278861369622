import React, { useCallback } from 'react'
import { useField } from 'formik'

import TimePicker from 'components/ui-kit/TimePicker'
import Text from 'components/Typography'
import { ITimeWorks } from 'types/types'
import { NonNullableFields } from 'types/utils'

import styles from './timePickerInput.module.scss'

type ITimePickerInputProps = {
  name: string
  label?: string
}

const TimePickerInput: React.FC<ITimePickerInputProps> = ({ name: nameProp, label }) => {
  const [{ value, onChange }] = useField<NonNullableFields<ITimeWorks>>(nameProp)
  const { start, end } = value

  const onChangeHelper = useCallback(
    (type: 'start' | 'end') =>
      ({ hours, minutes }: { hours: string; minutes: string }) => {
        const nextEvent = {
          target: {
            name: nameProp,
            value: {
              ...value,
              [type]: `${hours}:${minutes}`,
            } as NonNullableFields<ITimeWorks>,
          },
        }

        onChange(nextEvent)
      },
    [onChange, nameProp, value],
  )

  return (
    <div className={styles.container}>
      <Text className={styles.label}>{label}</Text>
      <TimePicker
        startHours={start.split(':')[0]}
        startMinutes={start.split(':')[1]}
        onChange={onChangeHelper('start')}
      />
      <span className={styles.separator} />
      <TimePicker
        startHours={end.split(':')[0]}
        startMinutes={end.split(':')[1]}
        onChange={onChangeHelper('end')}
      />
    </div>
  )
}

export default TimePickerInput
