import { buildSetPriceRuleQuery } from 'graphql/mutations/setPriceRule'
import { buildGetAttributesQuery } from 'graphql/queries/getAttributes'
import { buildGetPriceRuleQuery } from 'graphql/queries/getPriceRule'

const commonFields = `
  id
  name
  number
  status
  add_default

  parameters_groups {
    price_list {
      id
    }
    parameters {
      discount_ratio
      discount_type
      parameter_type
      destination_ids
    }
  }

  client_group {
    clients {
      id
    }
  }
`

export const GET_PRICE_RULE = buildGetPriceRuleQuery(commonFields)

export const SET_PRICE_RULE = buildSetPriceRuleQuery(commonFields)

export const GET_ATTRIBUTES = buildGetAttributesQuery(`
  id
  name
  attribute_values {
    id
    attribute_id
    value
  }
`)
