import React, { ChangeEvent, ReactNode } from 'react'
import { ApolloError } from '@apollo/client'

import Switch from 'components/Switch'
import Text from 'components/Typography'
import ApiError from 'components/ApiError'

import styles from './rowItem.module.scss'

type IRowItemProps = {
  status?: boolean
  title: string
  icon?: ReactNode
  error?: ApolloError | undefined
  onSettingClick: () => void
  onStatusChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

const RowItem: React.FC<IRowItemProps> = ({
  status = false,
  title,
  icon,
  error,
  onSettingClick,
  onStatusChange,
}) => (
  <div className={styles.item}>
    <button type="button" className={styles.left} onClick={onSettingClick}>
      {icon || <div className={styles.dummy} />}
      <div className={styles.textBlock}>
        <p className={styles.textBlock_title}>{title}</p>
        <p className={styles.textBlock_settings}>Настройки</p>
      </div>
    </button>

    <ApiError error={error} />

    {!!onStatusChange && (
      <Switch
        className={styles.switch}
        isChecked={status}
        gap="small"
        label={
          <Text size="small" color="textSecondary">
            {status ? 'активна' : 'неактивна'}
          </Text>
        }
        onChange={onStatusChange}
      />
    )}
  </div>
)

export default RowItem
