import React from 'react'

import Text from 'components/Typography'
import block from 'utils/block'

import styles from './optionItem.module.scss'

export type IOptionItemProps = {
  value: IPrimaryKey | null
  option: IOption<IPrimaryKey>
  onClick: (value: IPrimaryKey) => void
}

const b = block(styles)

const OptionItem: React.FC<IOptionItemProps> = ({ value, option, onClick }) => (
  <button
    className={b('container', { active: value === option.id })}
    type="button"
    onClick={() => onClick(option.id)}
  >
    <span className={styles.colorBox} style={{ backgroundColor: option.id }} />
    <Text>{option.name}</Text>
  </button>
)

export default OptionItem
