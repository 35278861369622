import React, { FC, SyntheticEvent, useState, useEffect } from 'react'
import { RecoveryResponseType } from 'graphql/mutations/recovery'

import Input from 'components/ui-kit/input'
import Link from 'components/Link'
import UiLayout from 'components/ui-kit/UiLayout'
import Button from 'components/Button'

import styles from './Recovery.module.scss'

type RecoveryFormPropsTypes = {
  errors?: string
  data: RecoveryResponseType | undefined
  value: string
  onChange: (value: string) => void
  isValidEmail: boolean
  isOnSubmit: boolean
  handleSubmit: (e: SyntheticEvent) => void
}

const Recovery: FC<RecoveryFormPropsTypes> = ({
  errors,
  data,
  value,
  onChange,
  isValidEmail,
  handleSubmit,
  isOnSubmit,
}) => {
  const [isError, setIsError] = useState<boolean>(!!errors)

  useEffect(() => {
    setIsError(!!errors)
  }, [errors])

  const onChangeEmail = (newEmail: string) => {
    onChange(newEmail)
    setIsError(false)
  }

  return (
    <form className={styles.RecoveryForm} onSubmit={handleSubmit}>
      {isOnSubmit && data?.forgotPassword.status ? (
        <span className={styles.RecoveryForm__status}>
          Мы отправили письмо на электронную почту <b>{value}</b>. Для восстановления пароля перейдите по
          ссылке в письме и задайте новый пароль.
        </span>
      ) : (
        <>
          <UiLayout spanText="E-mail" layoutClassName={styles.RecoveryForm__container}>
            <Input
              className={styles.RecoveryForm__input}
              classNameRoot={styles.RecoveryForm__inputRoot}
              name="email"
              type="email"
              value={value}
              onChangeHandler={(v) => onChangeEmail(v)}
              isError={isError || (isOnSubmit && !isValidEmail)}
              errorHandler={(isDirt) => isDirt && !value}
              error={isError ? errors : !value ? 'Поле обязательное для заполнения' : 'Неверный формат'}
              placeholder="E-mail"
            />
          </UiLayout>
          <Button type="submit" className={styles.RecoveryForm__Btn}>
            Отправить
          </Button>
          <Link href="/auth/sign-in" className={styles.RecoveryForm__backButton}>
            Назад
          </Link>
        </>
      )}
    </form>
  )
}

export default Recovery
