import React from 'react'

import Text from 'components/Typography'
import styles from './dropZoneDescription.module.scss'

const DropZoneDescription: React.FC = () => (
  <div>
    <Text>Фото товара</Text>
    <ul className={styles.list}>
      <li className={styles.listITem}>
        <Text>Расширение файла – jpeg или png</Text>
      </li>
      <li className={styles.listITem}>
        <Text>Размер фото – 1000 х 750 px, вес – не более 500 кб</Text>
      </li>
      <li className={styles.listITem}>
        <Text>Рекомендуемое количество – 10 шт.</Text>
      </li>
    </ul>
  </div>
)

export default DropZoneDescription
