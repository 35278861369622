import React, { memo, useCallback, useMemo } from 'react'
import { FormikHelpers } from 'formik'
import moment from 'moment'
import isBoolean from 'lodash/isBoolean'

import Preloader from 'components/Preloader'
import GoBackLink from 'components/GoBackLink'
import ResultTitle from 'components/ResultTitle'
import PageContainer from 'components/PageContainer'
import Text from 'components/Typography'
import useCmsParams from 'hooks/useCmsParams'
import useNotifyCms from 'hooks/useNotifyCms'
import convertToFormErrors from 'utils/convertToFormErrors'
import phoneFormat from 'utils/phoneFormat'
import phoneFormatSave from 'utils/phoneFormatSave'
import withErrorBoundary from 'hocs/withErrorBoundary'
import withLeaveThisPageModal from 'hocs/withLeaveThisPageModal'
import ContactForm, { IContactFormValues } from 'components/forms/ContactForm'
import { useGetContactQuery } from 'graphql/queries/getContact'
import { useSetContactMutation } from 'graphql/mutations/setContact'
import ApiError from 'components/ApiError'
import { IContact, IContactInput } from 'types/types'

import Controls from './components/Controls'
import { GET_CONTACT, SET_CONTACT } from './queries'

import styles from './contactEdit.module.scss'

const defaultValues: IContactFormValues = {
  status: true,
  phone: '',
  email: '',
  name: '',
  client_ids: [],
}

const prepareInitialValues = (payload: IContact): IContactFormValues => {
  const { status, phone, email, name, clients } = payload
  return {
    name: name || '',
    phone: phone ? phoneFormat(phone) : '',
    email: email || '',
    status: isBoolean(status) ? status : false,
    client_ids: clients ? clients.map(({ id }) => id) : [],
  }
}

const prepareSubmitValues = (payload: IContactFormValues): IContactInput => {
  const { name, phone, email, status, client_ids } = payload
  return {
    name,
    phone: phoneFormatSave(phone),
    email,
    status,
    client_ids,
  }
}

const ContactEdit: React.FC = () => {
  const { sellerId, contactId } = useCmsParams()
  const addNotify = useNotifyCms()

  const {
    data,
    loading,
    error: contactError,
  } = useGetContactQuery(GET_CONTACT, {
    variables: { id: contactId },
    fetchPolicy: 'network-only',
  })

  const contact = useMemo(() => data?.getContact, [data])

  const initialFormValues: IContactFormValues = useMemo(() => {
    if (!contact) return defaultValues
    return prepareInitialValues(contact)
  }, [contact])

  const [setClientInformation, { loading: setClientLoading }] = useSetContactMutation(SET_CONTACT)

  const onSubmit = useCallback(
    (values: IContactFormValues, { setErrors, resetForm }: FormikHelpers<IContactFormValues>) =>
      setClientInformation({
        variables: {
          id: contactId,
          input: prepareSubmitValues(values),
        },
      })
        .then((response) => {
          const nextData = response.data?.setContact
          if (!nextData) throw new Error('No setContact data')
          addNotify('success')
          resetForm({ values: prepareInitialValues(nextData) })
        })
        .catch((error) => {
          addNotify('error')
          const errors = convertToFormErrors(error)
          if (errors) setErrors(errors)
        }),
    [setClientInformation, addNotify, contactId],
  )

  if (loading) {
    return (
      <PageContainer>
        <GoBackLink href={`/${sellerId}/clients/list`} />
        <Preloader />
      </PageContainer>
    )
  }

  if (contactError) return <ApiError error={contactError} />

  if (!contact) return null

  return (
    <PageContainer>
      <GoBackLink href={`/${sellerId}/clients/list`} />

      <ResultTitle>{contact.name}</ResultTitle>

      <ContactForm
        initialValue={initialFormValues}
        submitLoading={setClientLoading}
        onSubmit={onSubmit}
        createdAt={
          <>
            <Text className={styles.registrationDateTitle} color="textSecondary">
              Дата регистрации
            </Text>
            <Text>{moment(contact.created_at).format('DD.MM.YYYY')}</Text>
          </>
        }
        renderControls={() => <Controls />}
      />
    </PageContainer>
  )
}

export default withErrorBoundary(withLeaveThisPageModal(memo(ContactEdit)))
