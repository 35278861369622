import { buildSetCompanyQuery } from 'graphql/mutations/setCompany'
import { buildGetClientQuery } from 'graphql/queries/getClient'
import { buildGetCompanyQuery } from 'graphql/queries/getCompany'

const commonFields = `
  id 
  name 
  inn
  contracts {
    id
    name
    limit
    debt
  }
`

export const GET_COMPANY = buildGetCompanyQuery(commonFields)
export const SET_COMPANY = buildSetCompanyQuery(commonFields)

export const GET_CLIENT = buildGetClientQuery(`
  id
  companies { id name }
`)
