import React, { memo, useRef } from 'react'

import Link from 'components/Link'
import Text from 'components/Typography'
import Preloader from 'components/Preloader'
import ListControlGroup, { IListControlGroupPropsHandle } from 'components/ListControlGroup'
import TrashIcon from 'components/Icons/TrashIcon'
import DownloadIcon from 'components/Icons/DownloadIcon'
import { IPriceList } from 'types/types'
import withErrorBoundary from 'hocs/withErrorBoundary'
import ExportPriceListTable from 'components/ExportControls/ExportPriceListTable'
import DeletePriceList from 'components/ModelControls/DeletePriceList'
import useCmsLinks from 'hooks/useCmsLinks'

import styles from './priceList.module.scss'

type IPriceListProps = {
  priceList: IPriceList
  refetchList: () => void
}

const PriceList: React.FC<IPriceListProps> = ({ priceList, refetchList }) => {
  const { id: priceListId, name, default_price } = priceList

  const listControlGroupRef = useRef<IListControlGroupPropsHandle>(null)

  const { priceListEditLink } = useCmsLinks()

  const onExportPriceListTableSuccess = () => {
    listControlGroupRef.current?.close()
  }

  return (
    <div className={styles.container}>
      <Text className={styles.name}>
        <Link href={priceListEditLink(priceListId)}>{name}</Link>
      </Text>

      <Text color="textSecondary">{default_price ? 'По умолчанию' : ''}</Text>

      <ListControlGroup ref={listControlGroupRef} className={styles.controlsGroup}>
        <ExportPriceListTable
          priceListId={priceListId}
          onSuccess={onExportPriceListTableSuccess}
          render={({ loading, onClick }) => (
            <Link
              component="button"
              startIcon={loading ? <Preloader size="small" variant="relative" /> : <DownloadIcon />}
              onClick={onClick}
            >
              Скачать файл с ценами
            </Link>
          )}
        />

        <DeletePriceList
          priceListId={priceListId}
          onSuccess={refetchList}
          render={({ onClick }) => (
            <Link component="button" startIcon={<TrashIcon />} onClick={onClick}>
              Удалить
            </Link>
          )}
        />
      </ListControlGroup>
    </div>
  )
}

export default withErrorBoundary(memo(PriceList))
