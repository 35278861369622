import { object, string, boolean, mixed } from 'yup'
import isEqual from 'lodash/isEqual'

import { BANNER_ERROR_MESSAGE, REQUIRED_MESSAGE, WRONG_URL_FORMAT } from 'consts/errorMessages'

const FILE_ERROR = 'Недопустимый размер или тип файла'

const availableFormats = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp', 'image/gif']

const bytesToMegaBytes = (bytes: number) => {
  const converted = bytes / (1024 * 1024)
  return Number(converted.toFixed(2))
}

type IImageInput = File | string | null | { flag: 'delete' }

const validationSchema = object({
  name: string().required(REQUIRED_MESSAGE).default(''),
  url: string().required(REQUIRED_MESSAGE).url(WRONG_URL_FORMAT).default(''),
  status: boolean().default(false),
  show_title: boolean().default(false),
  text: string().default(''),
  button: string().default(''),
  tx_color: string().default('#ffffff'),
  desktopImage: mixed<IImageInput>()
    .default(null)
    .test('desktopImage', (value, { parent, createError }) => {
      if (!parent.mobileImage || isEqual(parent.mobileImage, { flag: 'delete' })) {
        if (!value || isEqual(value, { flag: 'delete' })) {
          return createError({ message: BANNER_ERROR_MESSAGE })
        }
      }

      if (value instanceof File) {
        const mbSize = bytesToMegaBytes(value.size)
        if (mbSize > 5) return createError({ message: FILE_ERROR })
        if (!availableFormats.includes(value.type)) return createError({ message: FILE_ERROR })
      }

      return true
    }),
  mobileImage: mixed<IImageInput>()
    .default(null)
    .test('mobileImage', (value, { parent, createError }) => {
      if (!parent.desktopImage || isEqual(parent.desktopImage, { flag: 'delete' })) {
        if (!value || isEqual(value, { flag: 'delete' })) {
          return createError({ message: BANNER_ERROR_MESSAGE })
        }
      }

      if (value instanceof File) {
        const mbSize = bytesToMegaBytes(value.size)
        if (mbSize > 5) return createError({ message: FILE_ERROR })
        if (!availableFormats.includes(value.type)) return createError({ message: FILE_ERROR })
      }

      return true
    }),
})

export default validationSchema
