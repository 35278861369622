import React, { useState } from 'react'
import { useMutation } from '@apollo/client'

import { H2 } from 'components/Typography'
import UiLayout from 'components/ui-kit/UiLayout'
import Button from 'components/Button'
import TextField from 'components/ui-kit/form/TextField'
import DragAndDrop from 'components/ui-kit/dragAndDrop'
import { addGroupRequestType, ADD_GROUP } from 'graphql/mutations/addGroup'
import { GET_GROUPS } from 'graphql/queries/getGroups'
import type { ImageInput } from 'types/types'
import useLogo from 'hooks/useLogo'
import useSortingInput from 'hooks/useSortingInput'
import { createGroupEvent } from 'utils/gtmHelper'
import Dialog from 'components/Dialog'
import DialogContent from 'components/DialogContent'
import DialogCloseButton from 'components/DialogCloseButton'
import useCmsParams from 'hooks/useCmsParams'
import useNotifyCms from 'hooks/useNotifyCms'
import useCurrentSeller from 'hooks/useCurrentSeller'

import styles from './ModalCreate.module.scss'

type IModalCreateProps = {
  isOpen: boolean
  numberList: number[]
  onClose: () => void
}

const ModalCreate: React.FC<IModalCreateProps> = ({ isOpen, numberList, onClose }) => {
  const { sellerId } = useCmsParams()
  const addNotify = useNotifyCms()

  const seller = useCurrentSeller()

  const [name, setName] = useState('')
  const [image, setImage] = useState<ImageInput | undefined>()

  const { number, onChange: setNumber } = useSortingInput({
    numberList,
  })

  const logo = useLogo(image)

  const [addGroup] = useMutation<addGroupRequestType>(ADD_GROUP, {
    variables: { seller_id: Number(sellerId), input: { name, number, logo } },
    refetchQueries: [GET_GROUPS],
    onCompleted: (d) => {
      createGroupEvent(seller.name, seller.company, d?.addGroup?.id, d?.addGroup?.name)
      addNotify('success')
    },
    onError: () => addNotify('error'),
  })

  const reset = () => {
    setName('')
    setNumber()
    setImage(undefined)
  }

  const handleOnClose = () => {
    reset()
    onClose()
  }

  const isNameError = name.length === 0

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!number || isNameError) {
      return
    }

    await addGroup()

    handleOnClose()
  }

  return (
    <Dialog isOpen={isOpen} onClose={handleOnClose}>
      <DialogCloseButton onClick={handleOnClose} />
      <DialogContent className={styles.content}>
        <form onSubmit={handleSubmit}>
          <H2 className={styles.ModalCreate__Title}>Создание группы</H2>
          <UiLayout layoutClassName={styles.ModalCreate__Field} spanText="Название группы" isRequired>
            <TextField
              inputProps={{
                name: 'name',
                value: name,
                maxLength: 49,
                onChange: (e) => setName(e.target.value),
              }}
              errorHandler={(isDirt) => isDirt && isNameError}
              messageError="Заполните поле"
            />
          </UiLayout>
          <UiLayout layoutClassName={styles.ModalCreate__Field} spanText="Сортировка" isRequired>
            <TextField
              inputProps={{
                name: 'number',
                type: 'number',
                value: number,
                maxLength: 5,
                onChange: setNumber,
              }}
              isError={!number}
              messageError="Заполните поле"
            />
          </UiLayout>
          <DragAndDrop title="Фото" zoneVariant="big" image={image} onChange={setImage} />
          <div className={styles.ModalCreate__WrapBtn}>
            <Button type="submit">Сохранить</Button>
            <Button type="reset" variant="outlined" onClick={handleOnClose}>
              Отменить
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default ModalCreate
