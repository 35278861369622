import { array, object, string } from 'yup'

import { REQUIRED_MESSAGE } from 'consts/errorMessages'

import { IPriceRuleFormValues } from '../types'

type IParameter = IPriceRuleFormValues['groupParameters'][0]['parameters'][0]

const validationSchema = () =>
  object({
    name: string().required(REQUIRED_MESSAGE).max(200, 'Длина не может превышать более 200 символов'),
    position: string().required(REQUIRED_MESSAGE),
    groupParameters: array(
      object({
        pricelistId: string().nullable().required(REQUIRED_MESSAGE),
        parameters: array(
          object({
            localGroupId: string()
              .nullable()
              .test('localGroupId', (value, { parent, createError }) => {
                const { parameter_type } = parent as IParameter
                if (parameter_type === 'ALL' || parameter_type === 'PRODUCT') {
                  return true
                }

                return value ? true : createError({ message: REQUIRED_MESSAGE })
              }),
            localValueIds: array(string()).test('value_ids', (value, { parent, createError }) => {
              const { parameter_type } = parent as IParameter

              if (parameter_type === 'ATTRIBUTE') {
                if (value?.length === 0) {
                  return createError({ message: REQUIRED_MESSAGE })
                }
              }

              return true
            }),
          }),
        ),
      }),
    ),
  })

export default validationSchema
