import React from 'react'
import MuiCheckbox, { CheckboxProps } from '@material-ui/core/Checkbox'

import { ReactComponent as CheckedIcon } from 'assets/img/checked.svg'

import styles from './checkbox.module.scss'

export type ICheckboxProps = CheckboxProps

const Checkbox: React.FC<ICheckboxProps> = (props) => (
  <MuiCheckbox
    color="default"
    icon={<span className={styles.icon} />}
    checkedIcon={
      <span className={styles.iconChecked}>
        <CheckedIcon />
      </span>
    }
    {...props}
  />
)

export default Checkbox
