import React, { memo } from 'react'
import Popper from '@material-ui/core/Popper'

import OptionItem, { IOptionItemProps } from '../OptionItem'

import styles from './optionsList.module.scss'

type IOptionsListProps = {
  isOpen: boolean
  showOptionId?: boolean
  portal?: boolean
  value: IPrimaryKey | null
  options: IOption<IPrimaryKey>[]
  containerRef: React.RefObject<HTMLDivElement>
  onClick: (value: IPrimaryKey) => void
  renderOption?: (params: IOptionItemProps) => void
  children: React.ReactNode
}

const popperModifiers = {
  flip: {
    enabled: false,
  },
  preventOverflow: {
    enabled: false,
    boundariesElement: 'scrollParent',
  },
  offset: {
    enabled: true,
    offset: '1px, 4px',
  },
}

const popperStyle = { zIndex: 9999 }

const renderItem = ({ option, showOptionId, value, onClick }: IOptionItemProps) => (
  <OptionItem key={option.id} option={option} showOptionId={showOptionId} value={value} onClick={onClick} />
)

const OptionsList: React.FC<IOptionsListProps> = ({
  isOpen,
  showOptionId,
  portal = false,
  value,
  options,
  containerRef,
  onClick,
  renderOption = renderItem,
  children,
}) => (
  <Popper
    open={isOpen}
    disablePortal={!portal}
    placement="bottom"
    anchorEl={containerRef.current}
    modifiers={popperModifiers}
    style={popperStyle}
  >
    <div style={{ width: containerRef.current?.clientWidth }} className={styles.container}>
      {children}
      <div className={styles.optionsGroup}>
        {options.map((option) => renderOption({ option, value, showOptionId, onClick }))}
      </div>
    </div>
  </Popper>
)

export default memo(OptionsList)
