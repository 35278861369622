import React, { forwardRef } from 'react'
import MuiIconButton, { IconButtonProps } from '@material-ui/core/IconButton'

export type IIconButtonProps = IconButtonProps

const IconButton: React.FC<IIconButtonProps> = forwardRef<HTMLButtonElement, IIconButtonProps>(
  ({ className, ...rest }, ref) => <MuiIconButton className={className} ref={ref} {...rest} />,
)

export default IconButton
