import React from 'react'

import DropMenu from 'components/DropMenu'
import Text from 'components/Typography'
import LimitText from 'components/LimitText'
import TextInput from 'components/Inputs/TextInput'
import DragAndDropInput from 'components/Inputs/DragAndDropInput'
import { IGeneralFormValues } from 'pages/General/types'

import styles from './generalInfo.module.scss'

type IGeneralInfoProps = {
  values: IGeneralFormValues
}

const commonInputProps = { maxLength: 100 }
const descriptionInputProps = { maxLength: 500 }

const GeneralInfo: React.FC<IGeneralInfoProps> = ({ values }) => (
  <DropMenu title="Общая информация">
    <div className={styles.row}>
      <div className={styles.inputsGroup}>
        <TextInput
          name="name"
          label="Название сайта"
          placeholder="Введите название сайта"
          margin="default"
          inputProps={commonInputProps}
          labelAsideRight={<LimitText value={values.name} limit={100} />}
        />
        <TextInput
          name="company"
          label="Компания"
          placeholder="Введите название компании"
          margin="default"
          inputProps={commonInputProps}
          labelAsideRight={<LimitText value={values.company} limit={100} />}
        />
        <TextInput
          name="industry"
          label="Отрасль"
          placeholder="Введите название отрасли"
          margin="default"
          inputProps={commonInputProps}
          labelAsideRight={<LimitText value={values.industry} limit={100} />}
        />
        <TextInput
          name="description"
          multiline
          minRows={6}
          label="Описание"
          placeholder="Введите описание"
          inputProps={descriptionInputProps}
          labelAsideRight={<LimitText value={values.description} limit={500} />}
        />
      </div>
      <div className={styles.imagesGroup}>
        <div>
          <Text>Логотип</Text>
          <DragAndDropInput name="logo" filesFormat=".png, .jpg, .webp" className={styles.dropZone} />
          <Text size="small">
            Будет отображаться в шапке <br /> вашего магазина
          </Text>
        </div>
        <div>
          <Text>Значок сайта</Text>
          <DragAndDropInput name="icon" filesFormat=".png, .jpg, .webp, .ico" className={styles.dropZone} />
          <Text size="small">
            Будет отображаться во вкладке <br /> браузера в магазине
          </Text>
        </div>
      </div>
    </div>
  </DropMenu>
)

export default GeneralInfo
