import { useEffect, useState, useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import cloneDeep from 'lodash/cloneDeep'
import queryString from 'query-string'

type SearchParams = {
  [key: string]: string | boolean | null
}

type IUseSearchParams<TParams> = [TParams, (params: TParams | ((prevState: TParams) => TParams)) => void]

const parseSearchParams = (payload: string) =>
  queryString.parse(payload, {
    arrayFormat: 'bracket',
    parseBooleans: true,
  })

const stringifySearchParams = (payload: Record<string, any>) =>
  queryString.stringify(payload, {
    arrayFormat: 'bracket',
    skipNull: true,
    skipEmptyString: true,
  })

const useSearchParams = <T extends SearchParams>(initialValues: T): IUseSearchParams<T> => {
  const history = useHistory()
  const location = useLocation()

  const [searchParams, setSearchParams] = useState<T>(() => {
    const params = parseSearchParams(location.search)
    return { ...cloneDeep(initialValues), ...params }
  })

  // function ((prevState: T) => T) not working with debounce
  const updateSearchParams = useCallback(
    (params: T | ((prevState: T) => T)) => {
      const newParams = parseSearchParams(location.search)
      const updatedParams = typeof params === 'function' ? params(searchParams) : params

      const nextParams = { ...newParams, ...updatedParams }

      setSearchParams(nextParams)
      history.push({ search: stringifySearchParams(nextParams) })
    },
    [history, location, searchParams],
  )

  useEffect(() => {
    const handleLocationChange = (newLocation: any) => {
      const params = parseSearchParams(newLocation.search)
      setSearchParams({ ...cloneDeep(initialValues), ...params })
    }

    const unlisten = history.listen(handleLocationChange)
    handleLocationChange(location)

    return () => {
      unlisten()
    }
  }, [initialValues, history, location])

  return [searchParams, updateSearchParams]
}

export default useSearchParams
