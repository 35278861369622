import { array, object, string, boolean } from 'yup'

import { REQUIRED_MESSAGE, WRONG_EMAIL_FORMAT } from 'consts/errorMessages'

const validationSchema = object({
  name: string().required(REQUIRED_MESSAGE),
  status: boolean(),
  phone: string(),
  email: string().required(REQUIRED_MESSAGE).email(WRONG_EMAIL_FORMAT),
  client_ids: array(string()),
})

export default validationSchema
