import React from 'react'
import moment from 'moment'

import { IOrder } from 'types/types'
import Link from 'components/Link'
import DropMenu from 'components/DropMenu'
import TitleCounter from 'components/DropMenu/TitleCounter'
import Text from 'components/Typography'
import useCmsParams from 'hooks/useCmsParams'
import createOrderLink from 'utils/links/createOrderLink'
import useCurrentSeller from 'hooks/useCurrentSeller'
import renderCurrencySymbol from 'utils/renderCurrencySymbol'

import styles from './orders.module.scss'

type IOrdersProps = {
  orders?: IOrder[]
}

// NOTE: format Price
const formatPrice = (price: number) => {
  const formatter = new Intl.NumberFormat('ru-RU', { style: 'decimal', currency: 'RUB' })
  return formatter.format(price)
}

const Orders: React.FC<IOrdersProps> = ({ orders = [] }) => {
  const { sellerId } = useCmsParams()
  const { settings } = useCurrentSeller()

  if (!orders.length) return null

  return (
    <DropMenu
      resetPadding
      initOpen={false}
      title={<TitleCounter counter={orders.length}>Заказы</TitleCounter>}
    >
      <div className={styles.header}>
        <Text bold size="small">
          Номер заказа
        </Text>
        <Text bold size="small">
          Дата заказа
        </Text>
        <Text bold size="small">
          Компания
        </Text>
        <Text bold size="small" align="right" className={styles.price}>
          Сумма заказа
        </Text>
      </div>

      {orders.map((order) => {
        const price = order.products.reduce(
          (acc, orderProduct) => orderProduct.price * orderProduct.amount + acc,
          0,
        )

        return (
          <Link href={`/${sellerId}/${createOrderLink(order.id)}`} key={order.id} className={styles.item}>
            <Text>{order.number}</Text>
            <Text color="primary">{moment(order.created_at).format('DD.MM.YYYY')}</Text>
            <Text color="primary">{order.customer?.company?.name}</Text>
            <Text color="primary" align="right" className={styles.price}>
              {formatPrice(price)} {renderCurrencySymbol(settings?.currency)}
            </Text>
          </Link>
        )
      })}
    </DropMenu>
  )
}

export default Orders
