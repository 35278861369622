import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'

import { useAuth } from 'contexts/AuthProvider'
import Text, { H3, H2 } from 'components/Typography'
import Preloader from 'components/Preloader'
import Button from 'components/Button'
import { ReactComponent as Clock } from 'assets/img/clockIcon_warning.svg'
import { ReactComponent as Success } from 'assets/img/successfully.svg'
import { VERIFY_EMAIL } from 'graphql/mutations/verifyEmail'
import { GET_SELLERS } from 'graphql/queries/getSellers'
import AuthHeader from 'components/AuthHeader'

import styles from './verifyEmail.module.scss'

const VerifyEmailPage: React.FC = () => {
  const { verifyToken, verifyTokenData, verifyTokenLoading, isAuth } = useAuth()
  const { push } = useHistory()
  const { data } = useQuery(GET_SELLERS)

  const [onVerify, { data: onVeriFyData, loading: onVerifyEmailLoading }] = useMutation(VERIFY_EMAIL)

  const onSendEmailVerifyHandler = () => {
    const sellerID = data?.getSellers[0]?.id || null
    if (isAuth) {
      if (window.location.pathname.includes('user')) {
        push('/profile')
      } else {
        push(`/${sellerID}/more`)
      }
    } else {
      push('/auth/sign-in')
    }
  }

  const onLeaveHandler = () => push('/')

  useEffect(() => {
    verifyToken(window.location.search.replace('?token=', ''))
  }, [])

  useEffect(() => {
    if (verifyTokenData?.verifyToken?.status) {
      onVerify({ variables: { token: window.location.search.replace('?token=', '') } })
    }
  }, [verifyTokenData])

  if (verifyTokenLoading || onVerifyEmailLoading) return <Preloader />

  return (
    <div className={styles.Root}>
      <AuthHeader />
      {onVeriFyData?.verifyEmail?.status ? (
        <div className={styles.ErrorRoot}>
          <Success className={styles.Clock} />
          <H2>Вы успешно подтвердили e-mail</H2>
          <Button variant="outlined" onClick={() => onLeaveHandler()} className={styles.Submit}>
            Перейти к сайтам
          </Button>
        </div>
      ) : verifyTokenData?.verifyToken?.status ? (
        <div className={styles.Container}>
          <H3 className={styles.ContainerSubTitle}>Изменение пароля</H3>
        </div>
      ) : (
        <div className={styles.ErrorRoot}>
          <Clock className={styles.Clock} />
          <H2>Срок действия ссылки истёк</H2>
          <Text className={styles.Text}>Повторите процесс подтверждения e-mail</Text>
          <Button variant="outlined" className={styles.Submit} onClick={onSendEmailVerifyHandler}>
            Повторить
          </Button>
        </div>
      )}
    </div>
  )
}

export default VerifyEmailPage
