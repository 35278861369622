import React from 'react'

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const ReloadIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M21 10.9995C20.7348 10.9995 20.4804 11.1049 20.2929 11.2924C20.1054 11.4799 20 11.7343 20 11.9995C20.0119 13.8725 19.3703 15.6911 18.1857 17.142C17.001 18.5928 15.3475 19.5852 13.51 19.9481C11.6724 20.3111 9.76578 20.0218 8.11851 19.1303C6.47123 18.2387 5.18641 16.8007 4.48533 15.0638C3.78425 13.3269 3.71079 11.3998 4.27762 9.61459C4.84445 7.82935 6.01607 6.29765 7.5907 5.28329C9.16533 4.26894 11.0444 3.83542 12.9043 4.05742C14.7641 4.27942 16.4884 5.14303 17.78 6.49951H15.38C15.1148 6.49951 14.8604 6.60487 14.6729 6.7924C14.4854 6.97994 14.38 7.23429 14.38 7.49951C14.38 7.76473 14.4854 8.01908 14.6729 8.20662C14.8604 8.39415 15.1148 8.49951 15.38 8.49951H19.91C20.1752 8.49951 20.4296 8.39415 20.6171 8.20662C20.8046 8.01908 20.91 7.76473 20.91 7.49951V2.99951C20.91 2.7343 20.8046 2.47994 20.6171 2.2924C20.4296 2.10487 20.1752 1.99951 19.91 1.99951C19.6448 1.99951 19.3904 2.10487 19.2029 2.2924C19.0154 2.47994 18.91 2.7343 18.91 2.99951V4.76951C17.2447 3.17757 15.0808 2.20946 12.784 2.02884C10.4873 1.84822 8.19863 2.46616 6.30492 3.7782C4.41121 5.09025 3.02861 7.01592 2.39083 9.2297C1.75305 11.4435 1.89921 13.8096 2.80461 15.928C3.71001 18.0465 5.31911 19.7874 7.3599 20.8564C9.40069 21.9254 11.748 22.2569 14.005 21.795C16.2621 21.3331 18.2904 20.106 19.7471 18.3212C21.2039 16.5364 21.9997 14.3033 22 11.9995C22 11.7343 21.8946 11.4799 21.7071 11.2924C21.5196 11.1049 21.2652 10.9995 21 10.9995Z" />
  </SvgIcon>
)

export default ReloadIcon
