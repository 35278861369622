import React from 'react'
import { useMutation } from '@apollo/client'

import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import { DELETE_CONTACT } from 'graphql/mutations/deleteContact'
import useBoolean from 'hooks/useBoolean'
import useNotifyCms from 'hooks/useNotifyCms'

type IRenderParams = {
  onClick: () => void
}

type IDeleteContactProps = {
  contactId: IPrimaryKey
  onSuccess: () => void
  render: (params: IRenderParams) => void
}

const DeleteContact: React.FC<IDeleteContactProps> = ({ contactId, onSuccess, render }) => {
  const addNotify = useNotifyCms()

  const [iseOpen, setIsOpen] = useBoolean(false)

  const [onDeleteContact] = useMutation(DELETE_CONTACT, {
    variables: {
      id: contactId,
    },
    onCompleted: () => {
      setIsOpen.off()
      addNotify({ kind: 'success', message: 'Контакт удален' })
      onSuccess()
    },
    onError: () => addNotify('error'),
  })

  return (
    <>
      <ConfirmDialog
        isOpen={iseOpen}
        title="Вы уверены, что хотите удалить контакт?"
        submitBtnText="Да, удалить"
        onSubmit={() => onDeleteContact()}
        onClose={setIsOpen.off}
      />

      {render({ onClick: setIsOpen.on })}
    </>
  )
}

export default DeleteContact
