import { object, string, array } from 'yup'

import { VENDOR_CODE_INPUT_LIMIT } from 'consts'
import { REQUIRED_MESSAGE } from 'consts/errorMessages'

const validationSchema = () =>
  object({
    products: array(
      object({
        vendor_code: string().required(REQUIRED_MESSAGE).max(VENDOR_CODE_INPUT_LIMIT, ''),
      }),
    ),
  })

export default validationSchema
