import React, { FC } from 'react'

import { H3 } from 'components/Typography'
import Button from 'components/Button'

import Parameters from './components/Parameters'
import PriceList from './components/PriceList'

import styles from './parameterGroup.module.scss'

type IParameterGroupProps = {
  name: string
  index: number
  hasDelete: boolean
  onRemove: (index: number) => () => void
}

const ParameterGroup: FC<IParameterGroupProps> = ({ name, index, hasDelete, onRemove }) => (
  <div className={styles.container}>
    <div className={styles.hedingGroup}>
      <H3 color="textSecondary">Группа условий {index + 1}</H3>
      {hasDelete ? (
        <Button variant="outlined" onClick={onRemove(index)}>
          Удалить группу
        </Button>
      ) : null}
    </div>
    <PriceList name={name} />
    <Parameters name={name} />
  </div>
)

export default ParameterGroup
