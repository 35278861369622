import { useCallback, useContext } from 'react'

import { INotifyContext, NotifyContext, IINoticeKind, IAddNotice } from 'contexts/NotifyProvider'

const useNotifyCms = (): INotifyContext['addNotify'] => {
  const { addNotify } = useContext(NotifyContext)

  const onAddNotifyCms = useCallback(
    (notice: IINoticeKind | IAddNotice) => {
      addNotify(notice, 'cms')
    },
    [addNotify],
  )

  return onAddNotifyCms
}

export default useNotifyCms
