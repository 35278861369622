import { Overrides } from '@material-ui/core/styles/overrides'

const iconButton: Overrides['MuiIconButton'] = {
  root: {
    color: '#121239',

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  colorPrimary: {
    transition: 'color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

    '&:hover': {
      color: '#0089CC',
    },
  },
  sizeSmall: {
    padding: '4px',
  },
}

export default iconButton
