import React from 'react'

import Radio, { IRadioProps } from 'components/Radio'
import FormControlLabel, { IFormControlLabelProps } from 'components/FormControlLabel'

type IProps = Omit<IFormControlLabelProps, 'control'> & {
  radio?: IRadioProps
}

const RadioLabel: React.FC<IProps> = ({ className, radio, ...props }) => (
  <FormControlLabel className={className} control={<Radio color="primary" {...radio} />} {...props} />
)

export default RadioLabel
