import React from 'react'
import { useMutation } from '@apollo/client'

import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import useBoolean from 'hooks/useBoolean'
import useNotifyCms from 'hooks/useNotifyCms'
import {
  DELETE_PRICE_RULE,
  IDeletePriceRule,
  IDeletePriceRuleParams,
} from 'graphql/mutations/deletePriceRule'

type IRenderParams = {
  onClick: () => void
}

type IDeletePriceRuleProps = {
  priceRuleId: IPrimaryKey
  onSuccess: () => void
  render: (params: IRenderParams) => void
}

const DeletePriceRule: React.FC<IDeletePriceRuleProps> = ({ priceRuleId, onSuccess, render }) => {
  const addNotify = useNotifyCms()

  const [iseOpen, setIsOpen] = useBoolean(false)

  const [onDeletePriceRule] = useMutation<IDeletePriceRule, IDeletePriceRuleParams>(DELETE_PRICE_RULE, {
    variables: {
      id: priceRuleId,
    },
    onCompleted: () => {
      setIsOpen.off()
      addNotify({ kind: 'success', message: 'Ценовая группа удалена' })
      onSuccess()
    },
    onError: () => addNotify('error'),
  })

  return (
    <>
      <ConfirmDialog
        isOpen={iseOpen}
        title="Вы уверены, что хотите удалить ценовую группу?"
        submitBtnText="Да, удалить"
        onSubmit={() => onDeletePriceRule()}
        onClose={setIsOpen.off}
      />

      {render({ onClick: setIsOpen.on })}
    </>
  )
}

export default DeletePriceRule
