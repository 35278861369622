import { object, string } from 'yup'

import { REQUIRED_MESSAGE, WRONG_INN_FORMAT } from 'consts/errorMessages'

const validationSchema = object({
  name: string().required(REQUIRED_MESSAGE),
  inn: string()
    .test('inn', (inn, { createError }) => {
      if (!inn) return true
      if (inn.length === 9 || inn.length === 10 || inn.length === 12) return true
      return createError({ message: WRONG_INN_FORMAT })
    })
    .default(''),
  // contracts: array(
  //   object({
  //     id: string().optional().default(undefined),
  //     _delete: boolean().optional(),
  //     name: string().default(''),
  //     limit: string().default(''),
  //     debt: string().default(''),
  //   }),
  // ).required(),
})

export default validationSchema
