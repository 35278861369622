import React, { useCallback } from 'react'
import { Formik, FormikHelpers } from 'formik'
import { object, string } from 'yup'

import { H2 } from 'components/Typography'
import Button from 'components/Button'
import Dialog from 'components/Dialog'
import DialogContent from 'components/DialogContent'
import DialogCloseButton from 'components/DialogCloseButton'
import AddSubmitShortcutFormHelper from 'components/AddSubmitShortcutFormHelper'
import TextInput from 'components/Inputs/TextInput'
import { REQUIRED_MESSAGE } from 'consts/errorMessages'
import { useAddStoreMutation, buildAddStoreQuery } from 'graphql/mutations/addStore'
import useCmsParams from 'hooks/useCmsParams'
import useNotifyCms from 'hooks/useNotifyCms'
import convertToFormErrors from 'utils/convertToFormErrors'

import styles from './createStoreDialog.module.scss'

type ICreateStoreDialogProps = {
  isOpen: boolean
  onClose: () => void
  onSuccess: () => void
}

type ICreateStoreFormValues = {
  name: string
}

const defaultValue: ICreateStoreFormValues = {
  name: '',
}

const validationSchema = object({
  name: string().required(REQUIRED_MESSAGE),
})

const ADD_STORE = buildAddStoreQuery(`name`)

const CreateStoreDialog: React.FC<ICreateStoreDialogProps> = ({ isOpen, onClose, onSuccess }) => {
  const { sellerId } = useCmsParams()
  const addNotify = useNotifyCms()

  const [addStore, { loading }] = useAddStoreMutation(ADD_STORE)

  const onSubmit = useCallback(
    ({ name }: ICreateStoreFormValues, { setErrors }: FormikHelpers<ICreateStoreFormValues>) =>
      addStore({
        variables: {
          seller_id: sellerId,
          name,
        },
      })
        .then(() => {
          addNotify('success')
          onSuccess()
        })
        .catch((error) => {
          addNotify('error')
          const errors = convertToFormErrors(error)
          if (errors) setErrors(errors)
        }),
    [addStore, addNotify, onSuccess, sellerId],
  )

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <DialogCloseButton onClick={onClose} />

      <DialogContent className={styles.content}>
        <H2 className={styles.title}>Создание склада</H2>

        <Formik initialValues={defaultValue} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <AddSubmitShortcutFormHelper />

              <TextInput
                name="name"
                required
                label="Название склада"
                placeholder="Введите название склада"
                margin="default"
              />

              <div className={styles.controlsGroup}>
                <Button type="submit" disabled={loading}>
                  Сохранить
                </Button>
                <Button variant="outlined" onClick={onClose}>
                  Отменить
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default CreateStoreDialog
