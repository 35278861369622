import React from 'react'

import DropMenu from 'components/DropMenu'
import { IOrder } from 'types/types'

import styles from './_sheeping.module.scss'

type ISheepingProps = {
  order: IOrder
}

const Sheeping: React.FC<ISheepingProps> = ({ order }) => {
  const { delivery, address, comment } = order
  return (
    <DropMenu title="Способ получения">
      <p className={styles.label}>Способ получения</p>
      {delivery ? (
        <>
          <p className={styles.field}>Доставка</p>
          <p className={styles.label}>Адрес</p>
          <p className={styles.field}>{address}</p>
        </>
      ) : (
        <p className={styles.field}>Самовывоз</p>
      )}
      {!!comment && (
        <>
          <p className={styles.label}>Комментарий к заказу</p>
          <p className={styles.field}>{comment}</p>
        </>
      )}
    </DropMenu>
  )
}

export default Sheeping
