import { buildGetContactQuery } from 'graphql/queries/getContact'
import { buildSetContactQuery } from 'graphql/mutations/setContact'

const commonFields = `
  id
  name
  status
  phone
  email
  clients {
    id
    name
  }

  created_at
`

export const GET_CONTACT = buildGetContactQuery(commonFields)

export const SET_CONTACT = buildSetContactQuery(commonFields)
