import { gql } from '@apollo/client'

import { Status } from 'types/types'

export type IDeleteManager = {
  delManager: Status
}

export type IDeleteManagerParams = {
  id: IPrimaryKey
}

export const DELETE_MANAGER = gql`
  mutation delManager($id: ID!) {
    delManager(id: $id) {
      status
      message
    }
  }
`
