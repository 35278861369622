import React, { memo, useCallback, useMemo } from 'react'
import { useField } from 'formik'
import FormHelperText from '@material-ui/core/FormHelperText'

import { AttributeValue, Attribute as IAttribute } from 'types/types'
import SelectField from 'components/SelectField'
import UiLayout from 'components/ui-kit/UiLayout'
import CustomMultiSelect from 'components/ui-kit/form/CustomMultiSelect'

import { IVariantsFormValues } from '../../types'

import styles from './attribute.module.scss'

type IAttributeProps = {
  name: string
  attributesList: IAttribute[]
  attributesHash: Record<string, IAttribute>
}

type IField = IVariantsFormValues['variantAttribute']

const trasformAttributeValueToOption = ({ id, value }: AttributeValue) => ({ id, name: value })
const extractId = ({ id }: { id: IPrimaryKey }) => id

const Attribute: React.FC<IAttributeProps> = ({ name, attributesList, attributesHash }) => {
  const [input, { error, touched, initialTouched }, { setValue, setTouched }] = useField<IField>(name)

  const { value } = input

  const hasValueIdsError =
    (touched || initialTouched) && Boolean((error as unknown as IField | undefined)?.valueIds)

  const valueOptions = useMemo(() => {
    // may be empty string
    if (!value.attributeId) return []
    const values = attributesHash[value.attributeId]?.attribute_values
    if (!values) return []
    return values.map(trasformAttributeValueToOption)
  }, [attributesHash, value])

  const selectedOptions = useMemo(
    () => valueOptions.filter((option) => value.valueIds.includes(option.id)),
    [value.valueIds, valueOptions],
  )

  const onAttributeChange = useCallback(
    (payload: IPrimaryKey) => {
      setTouched(true)
      setValue({ attributeId: payload, valueIds: [] })
    },
    [setTouched, setValue],
  )

  const onAttributeClear = useCallback(() => {
    setTouched(true)
    setValue({ attributeId: '', valueIds: [] })
  }, [setTouched, setValue])

  const onSelectedOptionsChange = useCallback(
    (payload: IOption<IPrimaryKey>[]) => {
      setTouched(true)
      const nextSelectedIds = payload.map(extractId)
      setValue({ attributeId: value.attributeId, valueIds: nextSelectedIds })
    },
    [setValue, setTouched, value.attributeId],
  )

  return (
    <div className={styles.group}>
      <SelectField
        className={styles.name}
        label="Атрибут"
        placeholder="Выберите атрибут"
        value={value.attributeId}
        options={attributesList}
        onChange={onAttributeChange}
        onClear={onAttributeClear}
      />
      <UiLayout spanText="Значение атрибута" layoutClassName={styles.value}>
        <CustomMultiSelect
          selectedOptions={selectedOptions}
          setSelectedOption={onSelectedOptionsChange}
          options={valueOptions}
        />
        {hasValueIdsError ? (
          <FormHelperText error>{(error as unknown as IField | undefined)?.valueIds}</FormHelperText>
        ) : null}
      </UiLayout>
    </div>
  )
}

export default memo(Attribute)
