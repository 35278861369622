import React, { memo, ReactNode, useCallback, useMemo } from 'react'
import { useMutation } from '@apollo/client'
import { FormikHelpers } from 'formik'

import { IBaseProduct } from 'types/types'
import useCmsParams from 'hooks/useCmsParams'
import withLeaveThisPageModal from 'hocs/withLeaveThisPageModal'
import {
  ISetProductVariants,
  ISetProductVariantsParams,
  buildSetProductVariantsQuery,
} from 'graphql/mutations/setProductVariants'
import Preloader from 'components/Preloader'
import withErrorBoundary from 'hocs/withErrorBoundary'
import convertToFormErrors from 'utils/convertToFormErrors'
import useAddNotify from 'hooks/useAddNotify'

import SwitchPanel from './components/SwitchPanel'
import VariantsForm, { IVariantsFormValues } from './components/VariantsForm'

import useAttributesQuery from './useAttributesQuery'
import prepareInitialValues from './utils/prepareInitialValues'
import prepareSubmitValues from './utils/prepareSubmitValues'

type IVariantsProps = {
  baseProduct: IBaseProduct
  refetchBaseProduct: () => void
  renderControls: () => ReactNode
}

const SET_PRODUCT_VARIANTS = buildSetProductVariantsQuery(`
  id
  name
`)

const Variants: React.FC<IVariantsProps> = ({ baseProduct, refetchBaseProduct, renderControls }) => {
  const { variants_status } = baseProduct

  const { productId } = useCmsParams()
  const addNotify = useAddNotify()

  // NOTE: context probably would be a better way
  const attributtes = useAttributesQuery(variants_status)

  const initialValues = useMemo(() => prepareInitialValues(baseProduct), [baseProduct])

  const [setBaseProduct, { loading }] = useMutation<ISetProductVariants, ISetProductVariantsParams>(
    SET_PRODUCT_VARIANTS,
  )

  const onSubmit = useCallback(
    async (values: IVariantsFormValues, { setErrors }: FormikHelpers<IVariantsFormValues>) => {
      setBaseProduct({
        variables: {
          id: productId,
          product_variants: prepareSubmitValues(values),
        },
      })
        .then(() => {
          addNotify('success')
          refetchBaseProduct()
        })
        .catch((error) => {
          addNotify('error')
          const errors = convertToFormErrors(error)
          if (errors) setErrors(errors)
        })
    },
    [setBaseProduct, addNotify, refetchBaseProduct, productId],
  )

  return (
    <div>
      <SwitchPanel baseProduct={baseProduct} refetchBaseProduct={refetchBaseProduct} />

      {attributtes.attributesLoading ? (
        <Preloader />
      ) : (
        <>
          {variants_status ? (
            <VariantsForm
              initialValues={initialValues}
              submitLoading={loading}
              onSubmit={onSubmit}
              renderControls={renderControls}
              {...attributtes}
            />
          ) : null}
        </>
      )}
    </div>
  )
}

export default withErrorBoundary(withLeaveThisPageModal(memo(Variants)))
