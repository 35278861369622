import { DocumentNode, MutationHookOptions, gql, useMutation } from '@apollo/client'

import { Seller } from 'types/types'

export type ISetSellerSettingsParams = {
  id: IPrimaryKey
  individual_order: boolean
}

export type ISetSellerSettings = {
  setSellerIndividualOrderStatus: Seller
}

export const buildSetSellerIndividualOrderStatusQuery = (fields: string): DocumentNode => gql`
  mutation setSellerIndividualOrderStatus($id: ID!, $individual_order: Boolean!) {
    setSellerIndividualOrderStatus(id: $id, individual_order: $individual_order) {
      ${fields}
    }
  }
`

export const useSetSellerIndividualOrderStatus = <
  TData = ISetSellerSettings,
  TOptions = ISetSellerSettingsParams,
>(
  fields: DocumentNode,
  options?: MutationHookOptions<TData, TOptions>,
) => useMutation<TData, TOptions>(fields, options)
