import { DocumentNode, gql, QueryHookOptions, useQuery } from '@apollo/client'

import { IExchange1CAccess } from 'types/types'

export type IGet1CAccesses = {
  get1CAccesses: IExchange1CAccess[]
}

export type IGet1CAccessesParams = {
  seller_id: IPrimaryKey
}

export const buildGet1CAccessesQuery = (fields: string): DocumentNode => gql`
  query get1CAccesses($seller_id: ID!) {
    get1CAccesses(seller_id: $seller_id) {
      ${fields}
    }
  }
`

export const useGet1CAccessesQuery = <TData = IGet1CAccesses, TOptions = IGet1CAccessesParams>(
  fields: DocumentNode,
  options?: QueryHookOptions<TData, TOptions>,
) => useQuery<TData, TOptions>(fields, options)
