import React, { createContext, useContext, useMemo } from 'react'
import { ApolloError } from '@apollo/client'

import useCmsParams from 'hooks/useCmsParams'
import { useGetPriceListsQuery } from 'graphql/queries/getPriceLists'
import { useGetAttributesQuery } from 'graphql/queries/getAttributes'
import { maxPaginate } from 'consts'
import { Attribute, Category, Group, IPriceList } from 'types/types'
import convertAttributesToHash from 'utils/convertAttributesToHash'

import { useGetCategoriesQuery } from 'graphql/queries/getCategories'
import { useGetGroupsQuery } from 'graphql/queries/getGroups'
import { GET_PRICE_LISTS, GET_ATTRIBUTES, GET_CATEGORIES, GET_GROUPS } from './queries'

type IPriceRuleFormContext = {
  priceLists: IPriceList[]
  priceListLoading: boolean
  refetchPriceLists: () => void
  attributesList: Attribute[]
  attributesLoading: boolean
  attributesError: ApolloError | undefined
  attributesHash: Record<string, Attribute>
  categories: Category[]
  categoriesLoading: boolean
  categoriesError: ApolloError | undefined
  groups: Group[]
  groupsLoading: boolean
  groupsError: ApolloError | undefined
}

export const PriceRuleFormContext = createContext<IPriceRuleFormContext>({} as IPriceRuleFormContext)

export const usePriceRuleForm = () => useContext(PriceRuleFormContext)

const PriceRuleFormProvider: React.FC = ({ children }) => {
  const { sellerId } = useCmsParams()

  const {
    data: priceListData,
    loading: priceListLoading,
    refetch: refetchPriceLists,
  } = useGetPriceListsQuery(GET_PRICE_LISTS, {
    variables: { seller_id: sellerId, paginate: maxPaginate },
    fetchPolicy: 'network-only',
  })

  const priceLists = useMemo(() => priceListData?.getPriceLists.list || [], [priceListData])

  const {
    data: attributesData,
    loading: attributesLoading,
    error: attributesError,
  } = useGetAttributesQuery(GET_ATTRIBUTES, {
    variables: { seller_id: sellerId, paginate: maxPaginate },
    fetchPolicy: 'network-only',
  })

  const attributesList = useMemo(() => attributesData?.getAttributes.list || [], [attributesData])
  const attributesHash = useMemo(() => convertAttributesToHash(attributesList), [attributesList])

  const {
    data: categoriesData,
    loading: categoriesLoading,
    error: categoriesError,
  } = useGetCategoriesQuery(GET_CATEGORIES, {
    variables: { seller_id: sellerId, paginate: maxPaginate },
    fetchPolicy: 'network-only',
  })

  const categories = useMemo(() => categoriesData?.getCategories.list || [], [categoriesData])

  const {
    data: groupsData,
    loading: groupsLoading,
    error: groupsError,
  } = useGetGroupsQuery(GET_GROUPS, {
    variables: { seller_id: sellerId, paginate: maxPaginate },
    fetchPolicy: 'network-only',
  })

  const groups = useMemo(() => groupsData?.getGroups.list || [], [groupsData])

  const value: IPriceRuleFormContext = useMemo(
    () => ({
      priceLists,
      priceListLoading,
      refetchPriceLists,
      attributesList,
      attributesLoading,
      attributesError,
      attributesHash,
      categories,
      categoriesError,
      categoriesLoading,
      groups,
      groupsError,
      groupsLoading,
    }),
    [
      priceLists,
      priceListLoading,
      refetchPriceLists,
      attributesList,
      attributesLoading,
      attributesError,
      attributesHash,
      categories,
      categoriesError,
      categoriesLoading,
      groups,
      groupsError,
      groupsLoading,
    ],
  )

  return <PriceRuleFormContext.Provider value={value}>{children}</PriceRuleFormContext.Provider>
}

export default PriceRuleFormProvider
