import React, { memo, useCallback, useMemo, useState } from 'react'
import { FormikHelpers } from 'formik'

import { useSetCompanyMutation } from 'graphql/mutations/setCompany'
import { useGetCompanyQuery } from 'graphql/queries/getCompany'
import { useGetClientQuery } from 'graphql/queries/getClient'
import PageContainer from 'components/PageContainer'
import GoBackLink from 'components/GoBackLink'
import ResultTitle from 'components/ResultTitle'
import useCmsParams from 'hooks/useCmsParams'
import CompanyForm from 'components/forms/CompanyForm'
import withErrorBoundary from 'hocs/withErrorBoundary'
import { ICompanyFormValues } from 'components/forms/CompanyForm/types'
import useNotifyCms from 'hooks/useNotifyCms'
import convertToFormErrors from 'utils/convertToFormErrors'

import Preloader from 'components/Preloader'
import ApiError from 'components/ApiError'
import prepareInitialValues from './utils/prepareInitialValues'
import prepareSubmitValues from './utils/prepareSubmitValues'
import Controls from './components/Controls'
import { GET_CLIENT, GET_COMPANY, SET_COMPANY } from './queries'

const defaultValues: ICompanyFormValues = {
  name: '',
  inn: '',
  contracts: [],
}

const CompanyEdit: React.FC = () => {
  const { sellerId, companyId, clientId } = useCmsParams()
  const addNotify = useNotifyCms()

  const [initialFormValues, setInitialFormValues] = useState<ICompanyFormValues>(defaultValues)

  const { data, loading, error } = useGetCompanyQuery(GET_COMPANY, {
    variables: { id: companyId },
    fetchPolicy: 'network-only',
    onCompleted: (res) => setInitialFormValues(prepareInitialValues(res.getCompany)),
  })

  const company = useMemo(() => data?.getCompany, [data])

  const {
    data: clientData,
    loading: clientLoading,
    error: clientError,
  } = useGetClientQuery(GET_CLIENT, {
    variables: { id: clientId },
    fetchPolicy: 'network-only',
  })

  const canDeleteCompany = clientData?.getClient ? clientData?.getClient.companies.length > 1 : false

  const [onAddCompany, { loading: submitLoading }] = useSetCompanyMutation(SET_COMPANY)

  const onSubmit = useCallback(
    async (values: ICompanyFormValues, { setErrors, resetForm }: FormikHelpers<ICompanyFormValues>) =>
      onAddCompany({
        variables: {
          id: companyId,
          input: prepareSubmitValues(initialFormValues, values),
        },
      })
        .then((response) => {
          const nextData = response.data?.setCompany
          if (!nextData) throw new Error('No setCompany data')

          const initValues = prepareInitialValues(nextData)
          resetForm({ values: initValues })
          setInitialFormValues(initValues)
          addNotify('success')
        })
        .catch((errorRes) => {
          addNotify('error')
          const errors = convertToFormErrors(errorRes)
          if (errors) setErrors(errors)
        }),
    [onAddCompany, companyId, addNotify, initialFormValues],
  )

  if (loading || clientLoading) return <Preloader />

  if (error || clientError) {
    return (
      <PageContainer>
        <ApiError error={error || clientError} />
      </PageContainer>
    )
  }

  if (!company) return <p>Ничего не найдено</p>

  return (
    <PageContainer>
      <GoBackLink href={`/${sellerId}/clients/list`} />
      <ResultTitle>{company.name}</ResultTitle>
      <CompanyForm
        initialValue={initialFormValues}
        submitLoading={submitLoading}
        onSubmit={onSubmit}
        renderControls={() => <Controls canDeleteCompany={canDeleteCompany} />}
      />
    </PageContainer>
  )
}

export default withErrorBoundary(memo(CompanyEdit))
