import React, { useCallback } from 'react'

import Text, { H1 } from 'components/Typography'
import Button from 'components/Button'
import CreateStoreDialog from 'components/Dialogs/CreateStoreDialog'
import useBoolean from 'hooks/useBoolean'

import styles from './empty.module.scss'

type IEmptyProps = {
  refetchList: () => void
}

const Empty: React.FC<IEmptyProps> = ({ refetchList }) => {
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useBoolean()

  const onCreateSuccess = useCallback(() => {
    setIsCreateDialogOpen.off()
    refetchList()
  }, [setIsCreateDialogOpen, refetchList])

  return (
    <div className={styles.container}>
      <H1 className={styles.title}>Здесь будут склады</H1>
      <Text className={styles.subtitle}>У вас пока нет ни одного склада. Создайте первый!</Text>
      <Button onClick={setIsCreateDialogOpen.on}>Создать склад</Button>

      <CreateStoreDialog
        isOpen={isCreateDialogOpen}
        onClose={setIsCreateDialogOpen.off}
        onSuccess={onCreateSuccess}
      />
    </div>
  )
}

export default Empty
