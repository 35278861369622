import React, { memo, ReactNode } from 'react'
import { Formik, FormikHelpers } from 'formik'

import withErrorBoundary from 'hocs/withErrorBoundary'
import LeavePageDirtyFormHelper from 'components/LeavePageDirtyFormHelper'
import AddSubmitShortcutFormHelper from 'components/AddSubmitShortcutFormHelper'
import FormControlsPanel from 'components/FormControlsPanel'
import Button from 'components/Button'

import GeneralSettings from './components/GeneralSettings'
import StoreAmount from './components/StoreAmount'
import Decoration from './components/Decoration'
import Specifications from './components/Specifications'
import RelatedProducts from './components/RelatedProducts'
import Documents from './components/Documents'

import { IBaseProductFormValues } from './types'
import validationSchema from './utils/validationSchema'

import styles from './baseProductForm.module.scss'

type IBaseProductFormProps = {
  isDisabledVariantInputs?: boolean
  initialValues: IBaseProductFormValues
  submitLoading: boolean
  onSubmit: (values: IBaseProductFormValues, helpers: FormikHelpers<IBaseProductFormValues>) => Promise<void>
  renderControls?: () => ReactNode
}

const BaseProductForm: React.FC<IBaseProductFormProps> = ({
  isDisabledVariantInputs = false,
  initialValues,
  submitLoading,
  onSubmit,
  renderControls = () => {},
}) => (
  <Formik
    enableReinitialize
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    {({ values, handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <LeavePageDirtyFormHelper />
        <AddSubmitShortcutFormHelper />

        <GeneralSettings isDisabledVariantInputs={isDisabledVariantInputs} values={values} />
        <StoreAmount isDisabledVariantInputs={isDisabledVariantInputs} />
        <Decoration />
        <Specifications isDisabledVariantInputs={isDisabledVariantInputs} values={values} />

        <RelatedProducts />
        <Documents />

        <FormControlsPanel className={styles.controls}>
          <Button type="submit" disabled={submitLoading} className={styles.saveBtn}>
            Сохранить
          </Button>

          {renderControls()}
        </FormControlsPanel>
      </form>
    )}
  </Formik>
)

export default withErrorBoundary(memo(BaseProductForm))
