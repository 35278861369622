import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { buildGet1CAccessesQuery, useGet1CAccessesQuery } from 'graphql/queries/get1CAccesses'
import { buildSet1CAccessQuery, useSet1CAccessMutation } from 'graphql/mutations/set1CAccess'
import useCmsParams from 'hooks/useCmsParams'
import useCmsLinks from 'hooks/useCmsLinks'
import useNotifyCms from 'hooks/useNotifyCms'

import RowItem from '../RowItem'
import oneSLogo1x from './images/1s_logo@1x.png'
import oneSLogo2x from './images/1s_logo@2x.png'

type IOneSProps = {
  onLoadingChange: (value: boolean) => void
}

type IFormFalues = {
  id: string
  login: string
  password: string
  status: boolean
}

const fields = `
  id
  login
  password
  status
`

const GET_1C_ACCESSES_FIELDS = buildGet1CAccessesQuery(fields)
const ADD_1C_ACCESSES_FIELDS = buildSet1CAccessQuery(fields)

const OneS: React.FC<IOneSProps> = ({ onLoadingChange }) => {
  const { sellerId } = useCmsParams()
  const { oneSIntegration } = useCmsLinks()
  const { push } = useHistory()
  const addNotify = useNotifyCms()

  const [formData, setFormData] = useState<IFormFalues | null>(null)

  const redirect = () => push(oneSIntegration)

  const { error, refetch } = useGet1CAccessesQuery(GET_1C_ACCESSES_FIELDS, {
    fetchPolicy: 'network-only',
    variables: { seller_id: sellerId },
    onCompleted({ get1CAccesses }) {
      onLoadingChange(false)

      if (get1CAccesses.length > 0) {
        const { id, login, password, status } = get1CAccesses[0]
        setFormData({ id, login: login || '', password: password || '', status: status || false })
      }
    },
    onError() {
      onLoadingChange(false)
    },
  })

  const [set1CAccess] = useSet1CAccessMutation(ADD_1C_ACCESSES_FIELDS, {
    onCompleted: () => {
      addNotify('success')
      refetch()
    },
    onError: () => addNotify('error'),
  })

  const changeActiveService = () => {
    if (!formData) {
      redirect()
      return
    }

    const { id, login, password, status } = formData

    set1CAccess({
      variables: {
        id,
        input: { login, password, status: !status },
      },
    })
  }

  return (
    <RowItem
      title="1С: ERP, УТ, КА, УНФ"
      status={formData?.status}
      icon={<img src={oneSLogo1x} srcSet={`${oneSLogo2x} 2x`} alt="" />}
      error={error}
      onSettingClick={redirect}
      onStatusChange={changeActiveService}
    />
  )
}

export default OneS
