import React from 'react'

import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import { useDeleteCompanyMutation, DELETE_COMPANY } from 'graphql/mutations/deleteCompany'
import useBoolean from 'hooks/useBoolean'
import useNotifyCms from 'hooks/useNotifyCms'

type IRenderParams = {
  onClick: () => void
}

type IDeleteClientProps = {
  companyId: IPrimaryKey
  onSuccess: () => void
  render: (params: IRenderParams) => void
}

const DeleteClient: React.FC<IDeleteClientProps> = ({ companyId, onSuccess, render }) => {
  const addNotify = useNotifyCms()

  const [isOpen, setIsOpen] = useBoolean()

  const [onDeleteCompany] = useDeleteCompanyMutation(DELETE_COMPANY, {
    variables: {
      id: companyId,
    },
    onCompleted: (response) => {
      if (response.delCompany.status) {
        setIsOpen.off()
        addNotify({ kind: 'success', message: 'Компания удалена' })
        onSuccess()
      } else {
        setIsOpen.off()
        addNotify({ kind: 'error', message: 'Компания не удалена' })
      }
    },
    onError: () => addNotify('error'),
  })

  return (
    <>
      <ConfirmDialog
        isOpen={isOpen}
        title="Вы уверены, что хотите удалить компанию?"
        submitBtnText="Да, удалить"
        onSubmit={() => onDeleteCompany()}
        onClose={setIsOpen.off}
      />

      {render({ onClick: setIsOpen.on })}
    </>
  )
}

export default DeleteClient
