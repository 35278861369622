import React, { FC } from 'react'

const GlobalVariables: FC = () => (
  <style>
    {`
        html {
          --color-white: #FFFFFF;
          --color-black: #000000;
          --color-primary: #002FA7;
          --color-primary-active: #7949F4;
          --color-active: #121239;
          --color-grey-20: #DCDEE4;
          --color-grey-40: #999CA6;
          --color-grey-60: #5C5F69;
          --color-grey-back: #F4F5F8;
          --color-link: #0089CC;
          --color-success: #47A347;
          --color-warning: #EF9B28;
          --color-error: #F9423A;
        }
      `}
  </style>
)

export default GlobalVariables
