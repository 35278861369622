import { gql, MutationHookOptions, useMutation } from '@apollo/client'

import { Status } from 'types/types'

export type ITruncateAttributes = {
  truncateAttributes: Status
}

export type ITruncateAttributesParams = {
  seller_id: string
}

export const TRUNCATE_ATTRIBUTES = gql`
  mutation truncateAttributes($seller_id: ID!) {
    truncateAttributes(seller_id: $seller_id) {
      status
      message
    }
  }
`

export const useTruncateAttributes = <TData = ITruncateAttributes, TOptions = ITruncateAttributesParams>(
  options?: MutationHookOptions<TData, TOptions>,
) => useMutation<TData, TOptions>(TRUNCATE_ATTRIBUTES, options)
