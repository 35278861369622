import { DocumentNode, MutationHookOptions, gql, useMutation } from '@apollo/client'

import { ICompanyInput, ICompany } from 'types/types'

export type ISetCompany = {
  setCompany: ICompany
}

export type ISetCompanyParams = {
  id: IPrimaryKey
  input: ICompanyInput
}

export const buildSetCompanyQuery = (fields: string): DocumentNode => gql`
  mutation setCompany($id: ID!, $input: CompanyInput) {
    setCompany(id: $id, input: $input) {
      ${fields}
    }
  }
`

export const useSetCompanyMutation = <TData = ISetCompany, TOptions = ISetCompanyParams>(
  fields: DocumentNode,
  options?: MutationHookOptions<TData, TOptions>,
) => useMutation<TData, TOptions>(fields, options)
