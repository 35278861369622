import React, { useState, useMemo } from 'react'

export const DEFAULT_NUMBER = 9999

const getNumber = (value?: string | number): number => {
  if (value === undefined) {
    return DEFAULT_NUMBER
  }
  const digitalValue = Number(value)

  if (Number.isNaN(digitalValue)) {
    return 0
  }

  if (!digitalValue) {
    return 0
  }

  return digitalValue
}

export type onChangeType = React.ChangeEvent<HTMLInputElement> | number

interface IParams {
  defaultValue?: string | number
  numberList?: number[]
}

interface IReturn {
  number: number | string
  onChange: (e?: onChangeType) => void
  isExist: boolean
}

const useSortingInput = ({ defaultValue, numberList = [] }: IParams): IReturn => {
  const [number, setNumber] = useState<number>(() => getNumber(defaultValue))

  const onChange = (e?: onChangeType) => {
    if (e === undefined) {
      setNumber(getNumber())

      return
    }

    if (typeof e === 'number') {
      setNumber(e)

      return
    }

    const { value, maxLength } = e.target

    if (value.length > maxLength) {
      return
    }

    setNumber(getNumber(value.replace(/\D+/g, '')))
  }

  const isExist = useMemo(
    () => numberList.filter((el) => el !== defaultValue).indexOf(number) !== -1,
    [number, numberList, defaultValue],
  )

  return { number, onChange, isExist }
}

export default useSortingInput
