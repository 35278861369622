import React, { memo } from 'react'

import { IOrder } from 'types/types'
import Text from 'components/Typography'
import Paper from 'components/Paper'

import OrderCard from '../OrderCard'
import Row from '../Row'

type IResultProps = {
  orders: IOrder[]
}

const Result: React.FC<IResultProps> = ({ orders }) => (
  <Paper>
    <Row>
      <Text bold size="small">
        № заказа
      </Text>
      <Text bold size="small">
        Статус
      </Text>
      <Text bold size="small">
        Компания
      </Text>
      <Text bold size="small">
        Покупатель
      </Text>
      <Text bold size="small">
        Дата и время
      </Text>
      <Text bold size="small" align="right">
        Сумма заказа
      </Text>
    </Row>
    {orders.map((order) => (
      <OrderCard key={order.id} order={order} />
    ))}
  </Paper>
)

export default memo(Result)
