import React from 'react'

import {
  useSetSellerShowVariants,
  buildSetSellerShowVariantsMutation,
} from 'graphql/mutations/setSellerShowVariants'
import Switch from 'components/Switch'
import Text from 'components/Typography'
import useCmsParams from 'hooks/useCmsParams'
import defaultFieldsHelper from 'utils/defaultFieldsHelper'
import baseSellerDefaultFields from 'consts/defaultFields/baseSeller'
import useNotifyCms from 'hooks/useNotifyCms'
import { Seller } from 'types/types'

type IShowVariantsSwitchProps = {
  seller: Seller
}

const SET_SELLER_SHOW_VARIANTS = buildSetSellerShowVariantsMutation('id show_variants')

const ShowVariantsSwitch: React.FC<IShowVariantsSwitchProps> = ({ seller }) => {
  const { show_variants } = defaultFieldsHelper(seller, baseSellerDefaultFields)

  const { sellerId } = useCmsParams()
  const addNotify = useNotifyCms()

  const [setSellerShowVariants, { loading }] = useSetSellerShowVariants(SET_SELLER_SHOW_VARIANTS, {
    variables: {
      id: sellerId,
      show_variants: !show_variants,
    },
    onCompleted: () => addNotify('success'),
    onError: () => addNotify('error'),
    update(cache, { data: resData }) {
      if (!resData?.setSellerShowVariants) throw new Error('Something went wrong')

      cache.modify({
        fields: {
          // NOTE: НЕ Вызывает обновление всех getSeller (потому что нет поля show_variants в других запросах?)
          getSeller(currentSeller: Seller) {
            return {
              ...currentSeller,
              show_variants: resData.setSellerShowVariants.show_variants,
            }
          },
        },
      })
    },
  })

  return (
    <Switch
      checked={show_variants}
      disabled={loading}
      placement="right"
      gap="medium"
      label={<Text>Отображать варианты товаров в расширенном виде</Text>}
      onChange={() => setSellerShowVariants()}
    />
  )
}

export default ShowVariantsSwitch
