import { DocumentNode, QueryHookOptions, gql, useQuery } from '@apollo/client'

import { IUser } from 'types/types'

export type IGetUser = {
  getUser: IUser
}

export const GET_USER = gql`
  query {
    getUser {
      id
      name
      second_name
      email_verified_at
      email
      phone
    }
  }
`

export const useGetUserQuery = <TData = IGetUser, TOptions = any>(
  fields: DocumentNode,
  options?: QueryHookOptions<TData, TOptions>,
) => useQuery<TData, TOptions>(fields, options)
