import { DocumentNode, gql, useMutation, MutationHookOptions } from '@apollo/client'

import { IPriceList, Scalars, Maybe } from 'types/types'

export type IAddPriceList = {
  addPriceList: IPriceList
}

export type IAddPriceListParams = {
  seller_id: Scalars['ID']
  name: Scalars['String']
  default_price: Scalars['Boolean']
  show_prices?: Maybe<Scalars['Boolean']>
  file?: Maybe<File>
}

export const buildAddPriceListQuery = (fields: string): DocumentNode => gql`
  mutation addPriceList(
    $seller_id: ID!,
    $name: String!, 
    $default_price: Boolean!, 
    $show_prices: Boolean, 
    $file: Upload
  ) {
    addPriceList(
      seller_id: $seller_id, 
      name: $name, 
      default_price: $default_price, 
      show_prices: $show_prices, 
      file: $file
    ) {
      ${fields}
    }
  }
`

export const useAddPriceListMutation = <TData = IAddPriceList, TOptions = IAddPriceListParams>(
  fields: DocumentNode,
  options?: MutationHookOptions<TData, TOptions>,
) => useMutation<TData, TOptions>(fields, options)
