import React, { useState, FC, SyntheticEvent, useEffect } from 'react'
import validator from 'validator'

import Link from 'components/Link'
import Button from 'components/Button'
import Input from 'components/ui-kit/input'
import UiLayout from 'components/ui-kit/UiLayout'

import { clickOnSignInLinkEvent } from 'utils/gtmHelper'

import styles from './SignUpForm.module.scss'

export type SignUpParams = {
  email: string
  password: string
  name: string
  second_name: string
  phone: string
  password_confirmation: string
}

type SignUpFormPropTypes = {
  onSubmit: (params: SignUpParams) => void
  errors?: string
  setErrors?: (val?: string) => void
}

const validateEmail = (email: string): boolean => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return re.test(String(email).toLowerCase())
}

const validate = ({ name, email, password, password_confirmation }: SignUpParams): boolean => {
  if (!name) {
    return false
  }

  if (!validateEmail(email)) {
    return false
  }

  if (!password || !password_confirmation || password !== password_confirmation) {
    return false
  }

  if (password.length < 8) {
    return false
  }

  return true
}

const SignUpForm: FC<SignUpFormPropTypes> = ({ onSubmit, errors, setErrors }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [name, setName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNumber, setPhone] = useState('')
  const [confirmedPassword, setConfirm] = useState('')
  const [isVisited, setVisitedState] = useState({
    email: false,
    password: false,
    name: false,
    second_name: false,
    phone: false,
    password_confirmation: false,
  })

  useEffect(
    () => () => {
      if (setErrors) setErrors()
    },
    [],
  )

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault()

    const params: SignUpParams = {
      name,
      second_name: lastName,
      email,
      phone: phoneNumber,
      password,
      password_confirmation: confirmedPassword,
    }

    const isValid = validate(params)

    if (!isValid) {
      setVisitedState({
        email: true,
        password: true,
        name: true,
        second_name: true,
        phone: true,
        password_confirmation: true,
      })

      return
    }

    onSubmit(params)
  }

  const onChangeConfirmation = (newConfirmation: string) => {
    setConfirm(newConfirmation)
    if (password !== newConfirmation) {
      setVisitedState((prev) => ({ ...prev, password_confirmation: true }))
    } else {
      setVisitedState((prev) => ({ ...prev, password_confirmation: false }))
    }
  }

  const onChangePassword = (newPassword: string) => {
    setPassword(newPassword)

    if (confirmedPassword !== newPassword) {
      setVisitedState((prev) => ({ ...prev, password: true }))
    } else {
      setVisitedState((prev) => ({ ...prev, password: false }))
    }
  }

  return (
    <form onSubmit={handleSubmit} className={styles.SignUpForm}>
      <UiLayout spanText="Имя" isRequired layoutClassName={styles.SignUpForm__layout}>
        <Input
          className={styles.SignUpForm__input}
          name="name"
          type="name"
          value={name}
          onChange={(e) => setName(e.target.value.replace(/[^А-Яа-яЁёA-Za-z['-] ]/g, ''))}
          onBlur={() => setVisitedState((prev) => ({ ...prev, name: true }))}
          isError={isVisited.name && !name}
          error={!name.length && isVisited.name ? 'Поле обязательное для заполнения' : null}
        />
      </UiLayout>

      <UiLayout spanText="Фамилия" layoutClassName={styles.SignUpForm__layout}>
        <Input
          className={styles.SignUpForm__input}
          name="lastName"
          type="lastName"
          value={lastName}
          onChange={(e) => setLastName(e.target.value.replace(/[^А-Яа-яЁёA-Za-z['-] ]/g, ''))}
          onBlur={() => setVisitedState((prev) => ({ ...prev, lastName: true }))}
        />
      </UiLayout>

      <UiLayout spanText="E-mail" isRequired layoutClassName={styles.SignUpForm__layout}>
        <Input
          className={styles.SignUpForm__input}
          name="email"
          type="email"
          value={email}
          onChange={(e) => {
            if (!!errors && setErrors) {
              setErrors()
            }

            setEmail(e.target.value)
          }}
          isError={!!errors || (isVisited.email && !email) || (!validator.isEmail(email) && !!email)}
          onBlur={() => setVisitedState((prev) => ({ ...prev, email: true }))}
          error={
            !email.length && isVisited.email
              ? 'Поле обязательное для заполнения'
              : !validator.isEmail(email) && isVisited.email
              ? 'Неверный формат'
              : errors
          }
        />
      </UiLayout>

      <UiLayout spanText="Телефон" layoutClassName={styles.SignUpForm__layout}>
        <Input
          className={styles.SignUpForm__input}
          name="phone"
          type="phone"
          mask="+7 (999) 999-99-99"
          value={phoneNumber}
          onChange={(e) => setPhone(e.target.value)}
          onBlur={() => setVisitedState((prev) => ({ ...prev, phone: true }))}
        />
      </UiLayout>

      <div className={styles.SignUpForm__passwordContainer}>
        <UiLayout
          isRequired
          spanText="Придумайте пароль"
          layoutClassName={styles.SignUpForm__passwordWrapper}
        >
          <Input
            className={styles.SignUpForm__input}
            name="password"
            type="password"
            value={password}
            isError={(isVisited.password && !password) || (isVisited.password && password.length < 8)}
            onChange={(e) => onChangePassword(e.target.value)}
            onBlur={() => setVisitedState((prev) => ({ ...prev, password: true }))}
            error={
              !password.length && isVisited.password
                ? 'Поле обязательное для заполнения'
                : password.length < 8 && isVisited.password
                ? 'Пароль должен содержать минимум 8 символов'
                : !isVisited.password && errors
                ? errors
                : null
            }
          />
        </UiLayout>

        <UiLayout isRequired spanText="Повторите пароль" layoutClassName={styles.SignUpForm__passwordWrapper}>
          <Input
            className={styles.SignUpForm__input}
            name="confirmPassword"
            type="password"
            value={confirmedPassword}
            isError={
              (!confirmedPassword && isVisited.password_confirmation) || password !== confirmedPassword
            }
            onChange={(e) => onChangeConfirmation(e.target.value)}
            onBlur={() => setVisitedState((prev) => ({ ...prev, password_confirmation: true }))}
            error={
              !confirmedPassword.length && isVisited.password_confirmation
                ? 'Поле обязательное для заполнения'
                : password !== confirmedPassword
                ? 'Пароли не совпадают'
                : null
            }
          />
        </UiLayout>
      </div>

      <Button className={styles.SignUpForm__button} type="submit">
        Зарегистрироваться
      </Button>
      <div className={styles.SignUpForm__linkContainer}>
        <p className={styles.SignUpForm__linkLabel}>Уже есть аккаунт?</p>
        <Link href="/auth/sign-in" onClick={clickOnSignInLinkEvent}>
          Войти
        </Link>
      </div>
    </form>
  )
}

export default SignUpForm
