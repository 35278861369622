import React, { SyntheticEvent, useState, memo, useCallback } from 'react'

import { siteCreationEvent } from 'utils/gtmHelper'
import DragAndDrop from 'components/ui-kit/dragAndDrop'
import TextArea from 'components/ui-kit/TextArea'
import Button from 'components/Button'
import Dialog from 'components/Dialog'
import DialogContent from 'components/DialogContent'
import DialogCloseButton from 'components/DialogCloseButton'
import Text from 'components/Typography'
import Switch from 'components/Switch'
import TextField from 'components/TextField'
import useLogo from 'hooks/useLogo'
import { ImageInput } from 'types/types'
import { IAddSellerParams } from 'graphql/mutations/addSeller'

import styles from './AddForm.module.scss'

type IAddFormProps = {
  isOpen: boolean
  onClose: () => void
  onSubmit: (payload: IAddSellerParams) => void
}

type IFormState = {
  name: string
  company: string
  industry: string
  description: string
  image?: ImageInput
  isAddTestData: boolean
  isDefaultClosed: boolean
}

const initFormState: IFormState = {
  name: '',
  company: '',
  industry: '',
  description: '',
  image: undefined,
  isAddTestData: true,
  isDefaultClosed: true,
}

const initVisited = {
  name: false,
  company: false,
}

// TODO: сделать DragAndDrop инпут (там сейчас каша какая-то)

const AddForm: React.FC<IAddFormProps> = ({ isOpen, onClose, onSubmit }) => {
  const [formState, setFormState] = useState<IFormState>(initFormState)
  const [isVisited, setVisitedState] = useState(initVisited)

  const logo = useLogo(formState.image) // TODO: add utils helper to add it on change

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault()

    if (!formState.name || !formState.company) {
      setVisitedState({
        company: true,
        name: true,
      })

      siteCreationEvent(
        formState.name || null,
        formState.company || null,
        formState.industry || null,
        formState.isAddTestData,
        !!logo,
        'click',
      )

      return
    }

    onSubmit({
      test: formState.isAddTestData,
      closed: formState.isDefaultClosed,
      input: {
        name: formState.name,
        company: formState.company,
        industry: formState.industry,
        logo,
        description: formState.description,
      },
    })

    onClose()
  }

  const onFieldChange = useCallback((event: InputChangeEvent) => {
    const { name, value } = event.target
    setFormState((prevState) => ({ ...prevState, [name]: value }))
  }, [])

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <DialogCloseButton onClick={onClose} />
      <DialogContent className={styles.content}>
        <h1 className={styles.title}>Создание сайта</h1>
        <form onSubmit={handleSubmit}>
          <div className={styles.form}>
            <div className={styles.left}>
              <TextField
                name="name"
                required
                className={styles.textField}
                label="Название сайта"
                error={isVisited.name && !formState.name}
                helperText={
                  !formState.name.length && isVisited.name ? 'Поле обязательное для заполнения' : null
                }
                value={formState.name}
                onChange={onFieldChange}
                onBlur={() => setVisitedState((prev) => ({ ...prev, name: true }))}
              />
              <TextField
                name="company"
                required
                className={styles.textField}
                label="Компания"
                error={isVisited.company && !formState.company}
                helperText={
                  !formState.company.length && isVisited.company ? 'Поле обязательное для заполнения' : null
                }
                value={formState.company}
                onChange={onFieldChange}
                onBlur={() => setVisitedState((prev) => ({ ...prev, company: true }))}
              />
              <TextField
                name="industry"
                className={styles.textField}
                label="Отрасль"
                value={formState.industry}
                onChange={onFieldChange}
                onBlur={() => setVisitedState((prev) => ({ ...prev, industry: true }))}
              />
              <label className={styles.AddForm__label}>Описание</label>
              <TextArea
                className={styles.AddForm__textArea}
                maxLength={249}
                onChangeHandler={(value) =>
                  setFormState((prevState) => ({ ...prevState, description: value }))
                }
              />
            </div>
            <div>
              <DragAndDrop
                className={styles.dragAndDrop}
                title="Иконка или логотип"
                helperText="Будет отображаться на главной в списке проектов"
                image={formState.image}
                onChange={(payload) => setFormState((prevState) => ({ ...prevState, image: payload }))}
              />
              <Text className={styles.additionalOptionsTitle}>Дополнительные опции</Text>
              <Switch
                className={styles.switchWrapper}
                isChecked={formState.isDefaultClosed}
                placement="right"
                gap="medium"
                label={<Text size="small">Закрытый магазин</Text>}
                onChange={(event) =>
                  setFormState((prevState) => ({ ...prevState, isDefaultClosed: event.target.checked }))
                }
              />
              <Switch
                className={styles.switchWrapper}
                isChecked={formState.isAddTestData}
                placement="right"
                gap="medium"
                label={<Text size="small">Заполнить тестовыми данными</Text>}
                onChange={(event) =>
                  setFormState((prevState) => ({ ...prevState, isAddTestData: event.target.checked }))
                }
              />
            </div>
          </div>
          <div className={styles.controlsGroup}>
            <Button type="submit">Создать сайт</Button>
            <Button variant="outlined" onClick={onClose}>
              Отменить
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default memo(AddForm)
