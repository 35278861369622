import React, { ReactNode } from 'react'

import { H2 } from 'components/Typography'
import Button from 'components/Button'
import Dialog from 'components/Dialog'
import DialogContent from 'components/DialogContent'
import DialogCloseButton from 'components/DialogCloseButton'

import styles from './confirmDialog.module.scss'

interface IConfirmDialogProps {
  isOpen: boolean
  title: string
  submitBtnText?: string
  content?: ReactNode
  onClose: () => void
  onSubmit: () => void
}

const ConfirmDialog: React.FC<IConfirmDialogProps> = ({
  isOpen,
  title,
  submitBtnText = 'Да',
  content,
  onClose,
  onSubmit,
}) => (
  <Dialog isOpen={isOpen} onClose={onClose}>
    <DialogCloseButton onClick={onClose} />

    <DialogContent className={styles.content}>
      <H2>{title}</H2>

      {content ? <div>{content}</div> : null}

      <div className={styles.controlsGroup}>
        <Button onClick={onSubmit}>{submitBtnText}</Button>
        <Button variant="outlined" onClick={onClose}>
          Отменить
        </Button>
      </div>
    </DialogContent>
  </Dialog>
)

export default ConfirmDialog
