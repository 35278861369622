import React from 'react'

import SelectInput from 'components/Inputs/SelectInput'
import ApiError from 'components/ApiError'
import { usePriceRuleForm } from 'components/forms/PriceRuleForm/contexts'

import DiscountInputs from '../DiscountInputs'

import styles from './category.module.scss'

type ICategoryProps = {
  name: string
}

const Category: React.FC<ICategoryProps> = ({ name }) => {
  const { categories, categoriesLoading, categoriesError } = usePriceRuleForm()

  return (
    <div className={styles.container}>
      {categoriesError ? (
        <ApiError error={categoriesError} />
      ) : (
        <SelectInput
          name={`${name}.localGroupId`}
          required
          loading={categoriesLoading}
          label="Категория"
          placeholder="Выберите категорию"
          options={categories}
        />
      )}

      <DiscountInputs name={name} />
    </div>
  )
}

export default Category
