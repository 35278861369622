import { DocumentNode, gql } from '@apollo/client'

import type { Attribute } from 'types/types'

export type IGetAttribute = {
  getAttribute: Attribute
}

// TODO: create full query for tests
export const GET_ATTRIBUTE = gql`
  query getAttribute($id: ID!) {
    getAttribute(id: $id) {
      id
    }
  }
`

export const buildGetAttributeQuery = (fields: string): DocumentNode => gql`
  query getAttribute($id: ID!) {
    getAttribute(id: $id) {
      ${fields}
    }
  }
`
