import React from 'react'

import TextInput from 'components/Inputs/TextInput'
import SelectInput from 'components/Inputs/SelectInput'
import priceFormat from 'utils/formInputs/priceFormat'

import styles from './discountInputs.module.scss'

type IDiscountInputsProps = {
  name: string
}

const discountTypeOptions: IOption[] = [
  { id: 'discount', name: 'Скидка' },
  { id: 'margin', name: 'Наценка' },
]

const DiscountInputs: React.FC<IDiscountInputsProps> = ({ name }) => (
  <div className={styles.container}>
    <TextInput
      className={styles.input}
      name={`${name}.discount_ratio`}
      label="Дисконт"
      endAdornment="%"
      placeholder="Например 10"
      transform={priceFormat}
    />
    <SelectInput
      className={styles.input}
      name={`${name}.discount_type`}
      label="Вид дисконта"
      options={discountTypeOptions}
    />
  </div>
)

export default DiscountInputs
