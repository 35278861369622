import { object, string } from 'yup'

import { REQUIRED_MESSAGE, WRONG_EMAIL_FORMAT } from 'consts/errorMessages'

const validationSchema = () =>
  object({
    email: string().required(REQUIRED_MESSAGE).email(WRONG_EMAIL_FORMAT),
    name: string().required(REQUIRED_MESSAGE),
  })

export default validationSchema
