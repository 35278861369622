import { DocumentNode, gql, useQuery, useLazyQuery, QueryHookOptions } from '@apollo/client'

import { PPriceList, Paginate } from 'types/types'

export type IGetPriceLists = {
  getPriceLists: PPriceList
}

export type IGetPriceListsParams = {
  seller_id: IPrimaryKey
  search?: string | null
  paginate?: Paginate
}

export const buildGetPriceListsQuery = (fields: string): DocumentNode => gql`
  query getPriceLists($seller_id: ID!, $search: String, $paginate: Paginate) {
    getPriceLists(seller_id: $seller_id, search: $search, paginate: $paginate) {
      elements
      page
      pages
      list { ${fields} }
    }
  }
`

export const useGetPriceListsQuery = (
  fields: DocumentNode,
  options?: QueryHookOptions<IGetPriceLists, IGetPriceListsParams>,
) => useQuery<IGetPriceLists, IGetPriceListsParams>(fields, options)

export const useGetPriceListsLazyQuery = (
  fields: DocumentNode,
  options?: QueryHookOptions<IGetPriceLists, IGetPriceListsParams>,
) => useLazyQuery<IGetPriceLists, IGetPriceListsParams>(fields, options)
