import React, { InputHTMLAttributes, ReactElement, useMemo } from 'react'
import clsx from 'clsx'

import block from 'utils/block'
import createUniqId from 'utils/createUniqId'

import styles from './Switch.module.scss'

export type ISwitchProps = InputHTMLAttributes<HTMLInputElement> & {
  isChecked?: boolean
  label?: string | ReactElement
  placement?: 'default' | 'right' | 'left'
  gap?: 'small' | 'medium' | 'large'
}

const b = block(styles)

// TODO: добавить обертку, что бы вел себя нормально как flex element (не растягивался) (form control ?)
// add helper text, isError
const Switch: React.FC<ISwitchProps> = ({
  className,
  isChecked = false,
  placement = 'default',
  label = null,
  gap,
  disabled,
  ...rest
}) => {
  const id = useMemo(() => createUniqId(), [])
  return (
    <div className={clsx(styles.container, className)}>
      <label htmlFor={id} className={b('label', { placement, gap, disabled })}>
        <input
          id={id}
          className={styles.input}
          type="checkbox"
          checked={isChecked}
          disabled={disabled}
          {...rest}
        />
        <span className={clsx(styles.slider, { [styles.sliderDisabled]: disabled })} />
        {label}
      </label>
    </div>
  )
}

export default Switch
