import React from 'react'
import { Link } from 'react-router-dom'

import TrashIcon from 'components/Icons/TrashIcon'
import IconButton from 'components/IconButton'
import { IClient } from 'types/types'

import styles from './priceRuleClient.module.scss'

interface IPriceRuleClientProps {
  sellerId: string
  client: IClient
  onDelete: (value: IPrimaryKey) => void
}

const PriceRuleClient: React.FC<IPriceRuleClientProps> = ({ sellerId, client, onDelete }) => {
  const { id: clientId, name = 'EMPTY_NAME' } = client

  return (
    <div className={styles.container}>
      <Link to={`/${sellerId}/clients/profile/${clientId}`} className={styles.link}>
        {name}
      </Link>
      <IconButton onClick={() => onDelete(clientId)}>
        <TrashIcon color="action" />
      </IconButton>
    </div>
  )
}

export default PriceRuleClient
