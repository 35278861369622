import React, { ReactNode, memo } from 'react'
import { Formik, FormikHelpers } from 'formik'

import Button from 'components/Button'
import FormControlsPanel from 'components/FormControlsPanel'
import LeavePageDirtyFormHelper from 'components/LeavePageDirtyFormHelper'
import AddSubmitShortcutFormHelper from 'components/AddSubmitShortcutFormHelper'
import withErrorBoundary from 'hocs/withErrorBoundary'
import DropMenu, { TitleCounter } from 'components/DropMenu'
import LimitText from 'components/LimitText'
import Text from 'components/Typography'
import TextInput from 'components/Inputs/TextInput'
import SwitchInput from 'components/Inputs/SwitchInput'
import phoneTransform from 'utils/formInputs/phoneTransform'

import validationSchema from './utils/validationSchema'
import { IManagerFormValues } from './types'
import ClientsInput from './components/ClientsInput'

import styles from './managerForm.module.scss'

type IManagerFormProps = {
  submitLoading: boolean
  initialValue: IManagerFormValues
  onSubmit: (values: IManagerFormValues, helpers: FormikHelpers<IManagerFormValues>) => void
  renderControls?: () => ReactNode
}

const Row: React.FC = ({ children }) => <div className={styles.row}>{children}</div>

const ManagerForm: React.FC<IManagerFormProps> = ({
  submitLoading,
  initialValue,
  onSubmit,
  renderControls = () => {},
}) => (
  <Formik
    enableReinitialize
    initialValues={initialValue}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    {({ values, handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <LeavePageDirtyFormHelper />
        <AddSubmitShortcutFormHelper />
        <DropMenu title="Данные о менеджере">
          <Row>
            <TextInput
              name="name"
              required
              label="Фамилия, имя и отчество"
              placeholder="Введите фамилию, имя и отчество"
              inputProps={{ maxLength: 100 }}
              labelAsideRight={<LimitText limit={100} value={values.name} />}
            />
          </Row>
          <Row>
            <TextInput
              name="phone"
              label="Телефон"
              placeholder="Введите номер телефона"
              inputProps={{ maxLength: 30 }}
              labelAsideRight={<LimitText limit={30} value={values.phone} />}
              transform={phoneTransform}
            />

            <TextInput
              required
              name="email"
              label="E-mail"
              placeholder="example@mail.ru"
              inputProps={{ maxLength: 50 }}
              labelAsideRight={<LimitText limit={50} value={values.email} />}
            />
          </Row>

          <Row>
            <SwitchInput
              name="to_new_clients"
              placement="right"
              gap="large"
              label={<Text>Менеджер для новых контрагентов</Text>}
            />
          </Row>
        </DropMenu>

        <DropMenu
          resetPadding
          title={<TitleCounter counter={values.clientsIds.length}>Контрагенты</TitleCounter>}
        >
          <ClientsInput name="clientsIds" />
        </DropMenu>

        <FormControlsPanel className={styles.controlsPanel}>
          <Button type="submit" disabled={submitLoading}>
            Сохранить
          </Button>

          {renderControls()}
        </FormControlsPanel>
      </form>
    )}
  </Formik>
)

export default withErrorBoundary(memo(ManagerForm))
