import React from 'react'
import { useField } from 'formik'

import TextField, { ITextFieldProps } from 'components/TextField'

export type ITextInputProps = ITextFieldProps & {
  name: string
  transform?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

const defaultTransform = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => event

const TextInput: React.FC<ITextInputProps> = ({
  name,
  autoComplete = 'off',
  labelAsideRight: labelAsideRightProp,
  transform = defaultTransform,
  ...props
}) => {
  const [input, { error, touched, initialTouched }] = useField(name)

  const { value, onChange, ...restInput } = input

  const hasError = (touched || initialTouched) && !!error

  return (
    <TextField
      autoComplete={autoComplete}
      error={hasError}
      helperText={hasError ? error : null}
      value={value}
      labelAsideRight={labelAsideRightProp}
      {...props}
      {...restInput}
      onChange={(event) => onChange(transform(event))}
    />
  )
}

export default TextInput
