import { gql } from '@apollo/client'

import { Status } from 'types/types'

export type IDeleteCategory = {
  deleteCategory: Status
}

export type IDeleteCategoryParams = {
  id: IPrimaryKey
}

export const DELETE_CATEGORY = gql`
  mutation deleteCategory($id: ID!) {
    delCategory(id: $id) {
      status
      message
    }
  }
`
