import React, { useCallback } from 'react'
import { useField } from 'formik'

import Button from 'components/Button'
import ProductStoreAmountDialog from 'components/Dialogs/ProductStoreAmountDialog'
import useBoolean from 'hooks/useBoolean'
import { IStores } from 'types/types'
import formikFieldEvent from 'utils/formInputs/formikFieldEvent'

import { IVariantsFormValues } from '../../../../../../types'

import styles from './storesInputs.module.scss'

type IStoresInputsProps = {
  name: string
}

const StoresInputs: React.FC<IStoresInputsProps> = ({ name }) => {
  const [isOpen, setIsOpen] = useBoolean()
  const [{ value: stores, onChange }] = useField<IVariantsFormValues['products'][0]['stores']>(
    `${name}.stores`,
  )

  const onSubmit = useCallback(
    (payload: IStores[]) => {
      onChange(formikFieldEvent(`${name}.stores`, payload))
      setIsOpen.off()
    },
    [onChange, setIsOpen, name],
  )

  return (
    <td>
      <Button className={styles.button} variant="outlined" onClick={setIsOpen.on}>
        Редактировать
      </Button>

      <ProductStoreAmountDialog
        isOpen={isOpen}
        initialStores={stores}
        onClose={setIsOpen.off}
        onSubmit={onSubmit}
      />
    </td>
  )
}

export default StoresInputs
