import { ComponentsProps } from '@material-ui/core/styles/props'

const props: ComponentsProps = {
  MuiButton: {
    disableRipple: true,
    disableFocusRipple: true,
    disableTouchRipple: true,
    variant: 'contained',
    color: 'primary',
    type: 'button',
  },
  MuiCheckbox: {
    disableRipple: true,
  },
  MuiDialog: {
    maxWidth: false,
    PaperProps: {
      square: true,
      elevation: 0,
    },
  },
  MuiIconButton: {
    size: 'small',
  },
  MuiInputLabel: {
    disableAnimation: true,
    shrink: true,
  },
  MuiFilledInput: {
    disableUnderline: true,
  },
  MuiTextField: {
    fullWidth: true,
  },
  MuiTab: {
    disableRipple: true,
  },
  MuiRadio: {
    disableRipple: true,
  },
  MuiLink: {
    color: 'initial',
    underline: 'none',
  },
  MuiLinearProgress: {
    variant: 'buffer',
  },
  MuiPaper: {
    variant: 'elevation',
    elevation: 0,
  },
  MuiList: {
    disablePadding: true,
  },
  MuiListItem: {
    disableRipple: true,
  } as ComponentsProps['MuiListItem'],
}

export default props
