import { DocumentNode, QueryHookOptions, gql, useQuery } from '@apollo/client'

import { IPaymentSettings } from 'types/types'

export type IGetPaymentSettingsParams = {
  seller_id: IPrimaryKey
}

export type IGetPaymentSettings = {
  getPaymentSettings: IPaymentSettings
}

export const buildGetPaymentSettingsQuery = (fields: string): DocumentNode => gql`
  query getPaymentSettings($seller_id: ID!) {
    getPaymentSettings(seller_id: $seller_id) {
      ${fields}
    }
  }
`

export const useGetPaymentSettings = <TData = IGetPaymentSettings, TOptions = IGetPaymentSettingsParams>(
  fields: DocumentNode,
  options?: QueryHookOptions<TData, TOptions>,
) => useQuery<TData, TOptions>(fields, options)
