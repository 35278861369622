import React, { ReactNode } from 'react'
import { Formik, FormikHelpers } from 'formik'

import DropMenu from 'components/DropMenu'
import FormControlsPanel from 'components/FormControlsPanel'
import Button from 'components/Button'
import LeavePageDirtyFormHelper from 'components/LeavePageDirtyFormHelper'
import AddSubmitShortcutFormHelper from 'components/AddSubmitShortcutFormHelper'
import SwitchInput from 'components/Inputs/SwitchInput'
import TextInput from 'components/Inputs/TextInput'

import { ICommonFormValues } from './types'
import validationSchema from './utils/validationSchema'

import styles from './CommonForm.module.scss'

type ICommonFormProps = {
  submitLoading: boolean
  initialValue: ICommonFormValues
  onSubmit: (values: ICommonFormValues, helpers: FormikHelpers<ICommonFormValues>) => void
  renderControls?: () => ReactNode
}

const CommonForm: React.FC<ICommonFormProps> = ({
  submitLoading,
  initialValue,
  onSubmit,
  renderControls = () => {},
  children,
}) => (
  <Formik
    enableReinitialize
    initialValues={initialValue}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    {({ values, handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <LeavePageDirtyFormHelper />
        <AddSubmitShortcutFormHelper />

        <DropMenu title="Уведомления">
          <div className={styles.notifyContainer}>
            <SwitchInput
              name="notification.status"
              label="Показывать уведомления"
              placement="right"
              gap="medium"
            />
            {values.notification.status && (
              <>
                <TextInput
                  className={styles.notificationTitle}
                  name="notification.title"
                  label="Заголовок"
                  placeholder="Введите заголовок"
                  margin="default"
                />
                <TextInput
                  name="notification.text"
                  required
                  label="Текст уведомления"
                  placeholder="Введите текст уведомления"
                  multiline
                  rows={4}
                />
              </>
            )}
          </div>
        </DropMenu>

        {children}

        <FormControlsPanel className={styles.controlsPanel}>
          <Button type="submit" disabled={submitLoading}>
            Сохранить
          </Button>

          {renderControls()}
        </FormControlsPanel>
      </form>
    )}
  </Formik>
)

export default CommonForm
