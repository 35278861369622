import { DocumentNode, MutationHookOptions, gql, useMutation } from '@apollo/client'

import { IBanner, IBannerNumberInput } from 'types/types'

export type ISetBannersNumbers = {
  setBannersNumbers: IBanner[]
}

export type ISetBannersNumbersParams = {
  seller_id: IPrimaryKey
  list: IBannerNumberInput[]
}

export const buildSetBannersNumbersQuery = (fields: string): DocumentNode => gql`
  mutation setBannersNumbers($seller_id: ID!, $list: [BannerNumberInput]!) {
    setBannersNumbers(seller_id: $seller_id, list: $list) {
      ${fields}
    }
  }
`

export const useSetBannersNumbersMutation = <TData = ISetBannersNumbers, TOptions = ISetBannersNumbersParams>(
  fields: DocumentNode,
  options?: MutationHookOptions<TData, TOptions>,
) => useMutation<TData, TOptions>(fields, options)
