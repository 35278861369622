import React from 'react'
import clsx from 'clsx'

import useNotify from 'hooks/useNotify'

import NotifyItem from './components/NotifyItem'

import styles from './notifyList.module.scss'

type INotifyListProps = {
  className?: string
}

const NotifyList: React.FC<INotifyListProps> = ({ className }) => {
  const { notifyQueue, removeNotify } = useNotify()

  return (
    <div className={clsx(styles.wrapper, className)}>
      {notifyQueue.map((notify) => (
        <NotifyItem key={notify.id} notice={notify} remove={removeNotify} />
      ))}
    </div>
  )
}

export default NotifyList
