import React from 'react'

import { IProduct } from 'types/types'
import Button from 'components/Button'
import Link from 'components/Link'
import Text from 'components/Typography'
import useCmsParams from 'hooks/useCmsParams'
import noProductImage from 'assets/img/no-img.svg'

import styles from './product.module.scss'

type IProductProps = {
  product: IProduct
  onDelete: (value: IPrimaryKey) => void
}

const Product: React.FC<IProductProps> = ({ product, onDelete }) => {
  const { id: productId, base_product_id, name, vendor_code, amount, images } = product

  const { sellerId } = useCmsParams()

  const onDeleteClick = () => onDelete(productId)

  return (
    <div className={styles.container}>
      <Link className={styles.link} href={`/${sellerId}/catalog/products/${base_product_id}`}>
        <img className={styles.image} src={images[0]?.path || noProductImage} alt={name} />
        <div>
          <Text className={styles.name}>{name}</Text>
          <Text className={styles.vendorCode} color="textSecondary" size="small">
            {`Арт.\u00A0${vendor_code ?? '-'}`}
          </Text>
          <Text className={styles.amount} color="textPrimary" size="small">
            {`${amount || 0} шт.`}
          </Text>
        </div>
      </Link>
      <Button className={styles.deleteButton} variant="outlined" onClick={onDeleteClick}>
        Удалить
      </Button>
    </div>
  )
}

export default Product
