import { DocumentNode, gql } from '@apollo/client'

import { Seller, ISellerInput } from 'types/types'

export type ISetSellerParams = {
  id: IPrimaryKey
  input: ISellerInput
}

export type ISetSeller = {
  setSeller: Seller
}

export const buildSetSetSellerQuery = (fields: string): DocumentNode => gql`
  mutation setSeller($id: ID!, $input: SellerInput) {
    setSeller(id: $id, input: $input) {
      ${fields}
    }
  }
`
