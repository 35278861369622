import React from 'react'

import SideBar from 'components/SideBar'
import Container from 'components/Container'
import NotifyList from 'components/NotifyList'

import Header from './components/Header'

import styles from './cmsLayout.module.scss'

const CmsLayout: React.FC = ({ children }) => (
  <Container>
    <div className={styles.layout}>
      <SideBar />
      <div className={styles.content}>
        <Header />
        {children}
        <NotifyList className={styles.notifyList} />
      </div>
    </div>
  </Container>
)

export default CmsLayout
