import React, { memo, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { FormikHelpers } from 'formik'

import ResultTitle from 'components/ResultTitle'
import GoBackLink from 'components/GoBackLink'
import PageContainer from 'components/PageContainer'
import useCmsParams from 'hooks/useCmsParams'
import useNotifyCms from 'hooks/useNotifyCms'
import convertToFormErrors from 'utils/convertToFormErrors'
import phoneFormatSave from 'utils/phoneFormatSave'
import withLeaveThisPageModal from 'hocs/withLeaveThisPageModal'
import withErrorBoundary from 'hocs/withErrorBoundary'
import ManagerForm, { IManagerFormValues } from 'components/forms/ManagerForm'
import { IManagerInput } from 'types/types'
import { useAddManagerMutation, buildAddManagerQuery } from 'graphql/mutations/addManager'

const initialValue: IManagerFormValues = {
  phone: '',
  email: '',
  name: '',
  to_new_clients: false,
  clientsIds: [],
}

const ADD_MANAGER = buildAddManagerQuery(`
  id
  name
  email
`)

const prepareSubmitValues = ({ phone, clientsIds, ...rest }: IManagerFormValues): IManagerInput => ({
  ...rest,
  phone: phoneFormatSave(phone),
  client_ids: clientsIds,
})

const ContactCreate: React.FC = () => {
  const { sellerId } = useCmsParams()
  const { replace } = useHistory()
  const addNotify = useNotifyCms()

  const [onAddManager, { loading }] = useAddManagerMutation(ADD_MANAGER)

  const onSubmit = useCallback(
    (values: IManagerFormValues, { setErrors }: FormikHelpers<IManagerFormValues>) =>
      onAddManager({
        variables: {
          seller_id: sellerId,
          input: prepareSubmitValues(values),
        },
      })
        .then(({ data }) => {
          addNotify('success')
          if (data) replace(`/${sellerId}/managers/${data.addManager.id}`)
        })
        .catch((error) => {
          addNotify('error')
          const errors = convertToFormErrors(error)
          if (errors) setErrors(errors)
        }),
    [onAddManager, addNotify, replace, sellerId],
  )

  return (
    <PageContainer>
      <GoBackLink href={`/${sellerId}/managers/`} />
      <ResultTitle>Создание менеджера</ResultTitle>
      <ManagerForm initialValue={initialValue} submitLoading={loading} onSubmit={onSubmit} />
    </PageContainer>
  )
}

export default withErrorBoundary(withLeaveThisPageModal(memo(ContactCreate)))
