import React from 'react'

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const ArrowRightSmallIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M14.8302 11.2897L10.5902 7.0497C10.4972 6.95598 10.3866 6.88158 10.2648 6.83081C10.1429 6.78004 10.0122 6.75391 9.88019 6.75391C9.74818 6.75391 9.61747 6.78004 9.49561 6.83081C9.37375 6.88158 9.26315 6.95598 9.17019 7.0497C8.98394 7.23707 8.87939 7.49052 8.87939 7.7547C8.87939 8.01889 8.98394 8.27234 9.17019 8.4597L12.7102 11.9997L9.17019 15.5397C8.98394 15.7271 8.87939 15.9805 8.87939 16.2447C8.87939 16.5089 8.98394 16.7623 9.17019 16.9497C9.26363 17.0424 9.37444 17.1157 9.49628 17.1655C9.61812 17.2152 9.74858 17.2405 9.88019 17.2397C10.0118 17.2405 10.1423 17.2152 10.2641 17.1655C10.3859 17.1157 10.4967 17.0424 10.5902 16.9497L14.8302 12.7097C14.9239 12.6167 14.9983 12.5061 15.0491 12.3843C15.0998 12.2624 15.126 12.1317 15.126 11.9997C15.126 11.8677 15.0998 11.737 15.0491 11.6151C14.9983 11.4933 14.9239 11.3827 14.8302 11.2897Z" />
  </SvgIcon>
)

export default ArrowRightSmallIcon
