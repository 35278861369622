import React, { Fragment } from 'react'
import { useMutation, useQuery } from '@apollo/client'

import { H1, H3 } from 'components/Typography'
import { ReactComponent as PlusIcon } from 'assets/img/PlusIcon.svg'
import Button from 'components/Button'
import Preloader from 'components/Preloader'
import Layout from 'components/Layout'
import { GET_SELLERS, getSellarsType } from 'graphql/queries/getSellers'
import { clickOnCreateSiteOnStartPageEvent, siteCreationEvent } from 'utils/gtmHelper'
import useBoolean from 'hooks/useBoolean'
import {
  SET_SELLER_SETTINGS,
  ISetSellerSettings,
  ISetSellerSettingsParams,
} from 'graphql/mutations/setSellerSettings'
import { ADD_SELLER, IAddSeller, IAddSellerParams } from 'graphql/mutations/addSeller'

import AddForm from './components/AddForm'
import MySitesList from './components/MySitesList'

import styles from './main.module.scss'

const Main: React.FC = () => {
  const [isLoading, setIsLoading] = useBoolean()
  const [isAddFormOpen, setIsAddFormOpen] = useBoolean()

  const { data, loading, refetch } = useQuery<getSellarsType>(GET_SELLERS, {
    fetchPolicy: 'no-cache',
  })

  const [onAddSeller] = useMutation<IAddSeller, IAddSellerParams>(ADD_SELLER)

  const [onSetSellerColors] = useMutation<ISetSellerSettings, ISetSellerSettingsParams>(SET_SELLER_SETTINGS)

  const { getSellers: list = [] } = data || {}

  const onAddSellerSubmit = (params: IAddSellerParams) => {
    setIsLoading.on()

    onAddSeller({ variables: params })
      .then((res) => {
        if (res.data?.addSeller) {
          onSetSellerColors({
            variables: {
              seller_id: res.data.addSeller.id,
              input: {
                hd_color: '#000000',
                tx_color: '#000000',
                lk_color: '#0fa0da',
                bg_color: '#ffffff',
                bt_color: '#ffcb00',
                main_color: '#ffffff',
                second_color: '#2d3f4f',
              },
            },
          })

          siteCreationEvent(
            res.data.addSeller.name,
            res.data.addSeller.company || null,
            res.data.addSeller.industry || null,
            params.test,
            !!res.data.addSeller.logo,
            'submit',
          )
        }
      })
      .then(() => refetch())
      .then(() => setIsLoading.off())
  }

  if (loading || isLoading) return <Preloader />

  return (
    <Layout>
      <div className={styles.Main__Wrapper}>
        <div className={styles.Main__Container}>
          <H1 className={styles.Main__Title}>Мои сайты</H1>
          {list.length ? (
            list.map((seller) => (
              <Fragment key={seller.id}>
                <MySitesList seller={seller} />
                <p className={styles.Main__separator} />
              </Fragment>
            ))
          ) : (
            <>
              <H3 className={styles.Main__SubTitle}>Создайте свой первый сайт!</H3>
              <Button
                className={styles.Main__Button}
                startIcon={<PlusIcon className={styles.Main__PlusIcon} />}
                onClick={() => {
                  clickOnCreateSiteOnStartPageEvent()
                  setIsAddFormOpen.on()
                }}
              >
                Создать сайт
              </Button>
            </>
          )}
          <AddForm isOpen={isAddFormOpen} onClose={setIsAddFormOpen.off} onSubmit={onAddSellerSubmit} />
        </div>
      </div>
    </Layout>
  )
}

export default Main
