import React, { SyntheticEvent, useEffect, useState } from 'react'
import _isEqual from 'lodash.isequal'
import { useMutation } from '@apollo/client'

import Button from 'components/Button'
import Input from 'components/ui-kit/input'
import Link from 'components/Link'
import PageContainer from 'components/PageContainer'
import ResultTitle from 'components/ResultTitle'
import withLeaveThisPageModal, { useLeavePage } from 'hocs/withLeaveThisPageModal'
import useCmsParams from 'hooks/useCmsParams'
import useNotifyCms from 'hooks/useNotifyCms'
import {
  SET_SELLER_DOMAIN,
  ISetSellerDomain,
  ISetSellerDomainParams,
} from 'graphql/mutations/setSellerDomain'
import useCurrentSeller from 'hooks/useCurrentSeller'
import { useSeller } from 'contexts/SellerProvider'

import styles from './Domain.module.scss'

const Domain: React.FC = () => {
  const { sellerId } = useCmsParams()
  const addNotify = useNotifyCms()
  const { unblocking, blocking } = useLeavePage()

  const { refetch } = useSeller()
  const seller = useCurrentSeller()
  const [domain, setDomain] = useState('')

  const [onSetSellerDomain] = useMutation<ISetSellerDomain, ISetSellerDomainParams>(SET_SELLER_DOMAIN, {
    onCompleted: ({ setSellerDomain }) => {
      refetch()
      if (setSellerDomain.status) {
        addNotify('success')
      } else {
        addNotify('warning')
      }
    },
    onError: () => addNotify('error'),
  })

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault()

    unblocking()
    onSetSellerDomain({ variables: { id: sellerId, domain } })
  }

  useEffect(() => {
    if (seller) setDomain(seller.domain || '')
  }, [seller])

  useEffect(() => {
    if (seller) _isEqual(domain, seller.domain) ? unblocking() : blocking()
    if (!seller) _isEqual(domain, '') ? unblocking() : blocking()
  }, [seller, domain])

  return (
    <PageContainer>
      <ResultTitle>Домен</ResultTitle>
      <form onSubmit={handleSubmit}>
        <div className={styles.contentGroup}>
          <span>http://</span>
          <Input
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDomain(e.target.value)}
            value={domain}
            classNameRoot={styles.input}
          />
          <span>.sellty.ru</span>
        </div>
        <div className={styles.buttonsGroup}>
          <Button type="submit">Сохранить</Button>
          <Link href={`/${sellerId}/custom-domain`}>
            <Button variant="outlined">Подключить свой домен</Button>
          </Link>
        </div>
      </form>
    </PageContainer>
  )
}

export default withLeaveThisPageModal(Domain)
