import React from 'react'
import CodeMirror from '@uiw/react-codemirror'
import { javascript } from '@codemirror/lang-javascript'

import './codeEditor.css'

const extensions = [javascript({ jsx: true })]

interface ICodeEditorProps {
  value?: string
  height?: string
  onChange?: (value: string) => void
}

const CodeEditor: React.FC<ICodeEditorProps> = ({ height = '500px', value, onChange }) => (
  <CodeMirror value={value} height={height} extensions={extensions} onChange={onChange} />
)

export default CodeEditor
