import { Overrides } from '@material-ui/core/styles/overrides'

const formGroup: Overrides['MuiFormGroup'] = {
  root: {
    '& > .MuiFormControlLabel-root': {
      '&:not(:first-child)': {
        marginTop: '16px',
      },
    },
  },
  row: {
    '& > .MuiFormControlLabel-root': {
      '&:not(:first-child)': {
        marginLeft: '32px',
        marginTop: '0px',
      },
    },
  },
}

export default formGroup
