import React, { useCallback } from 'react'
import { useField } from 'formik'

import Wysiwyg, { IWysiwygProps } from 'components/Wysiwyg'

type IWysiwygInputProps = Pick<IWysiwygProps, 'limit'> & {
  name: string
}

const WysiwygInput: React.FC<IWysiwygInputProps> = ({ name, ...props }) => {
  const [input, { error, touched, initialTouched }] = useField(name)

  const { value, onChange, ...restInput } = input

  const hasError = (touched || initialTouched) && !!error

  const setText = useCallback(
    (payload: string) => {
      const nextEvent = {
        target: {
          name,
          value: payload,
        },
      }

      onChange(nextEvent)
    },
    [onChange, name],
  )

  return (
    <div>
      <Wysiwyg text={value} setText={setText} {...restInput} {...props} />
      {hasError ? error : null}
    </div>
  )
}

export default WysiwygInput
