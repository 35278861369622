import React, { memo, ReactNode } from 'react'
import { ApolloError } from '@apollo/client'
import { Formik, FormikHelpers } from 'formik'

import DropMenu from 'components/DropMenu'
import Button from 'components/Button'
import { Attribute as IAttribute } from 'types/types'
import FormControlsPanel from 'components/FormControlsPanel'
import LeavePageDirtyFormHelper from 'components/LeavePageDirtyFormHelper'
import AddSubmitShortcutFormHelper from 'components/AddSubmitShortcutFormHelper'
import withErrorBoundary from 'hocs/withErrorBoundary'

import Attributes from './components/Attributes'
import CreateVariant from './components/CreateVariant'
import ProductVariants from './components/ProductVariants'

import { IVariantsFormValues } from './types'
import validationSchema from './utils/validationSchema'

import styles from './variantsForm.module.scss'

type IVariantsFormProps = {
  initialValues: IVariantsFormValues
  submitLoading: boolean
  onSubmit: (values: IVariantsFormValues, form: FormikHelpers<IVariantsFormValues>) => Promise<void>
  renderControls: () => ReactNode

  attributesLoading: boolean
  attributesError: ApolloError | undefined
  attributesList: IAttribute[]
  attributesHash: Record<string, IAttribute>
}

const VariantsForm: React.FC<IVariantsFormProps> = ({
  initialValues,
  submitLoading,
  onSubmit,
  renderControls,
  attributesLoading,
  attributesError,
  attributesList,
  attributesHash,
}) => (
  <Formik
    enableReinitialize
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    {({ values, handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <LeavePageDirtyFormHelper />
        <AddSubmitShortcutFormHelper />

        <Attributes
          name="variantAttribute"
          attributesHash={attributesHash}
          attributesList={attributesList}
          attributesError={attributesError}
          attributesLoading={attributesLoading}
        />

        <DropMenu title="Варианты товара">
          <CreateVariant values={values} attributesHash={attributesHash} />
          <ProductVariants name="products" values={values} attributesHash={attributesHash} />
        </DropMenu>

        <FormControlsPanel className={styles.controls}>
          <Button type="submit" disabled={submitLoading} className={styles.saveBtn}>
            Сохранить
          </Button>

          {renderControls()}
        </FormControlsPanel>
      </form>
    )}
  </Formik>
)

export default withErrorBoundary(memo(VariantsForm))
