import { CSSProperties } from 'react'
import { Overrides } from '@material-ui/core/styles/overrides'

import button from './button'
import checkbox from './checkbox'
import circularProgress from './circularProgress'
import filledInput from './filledInput'
import formControl from './formControl'
import formControlLabel from './formControlLabel'
import formGroup from './formGroup'
import formHelperText from './formHelperText'
import formLabel from './formLabel'
import iconButton from './iconButton'
import inputLabel from './inputLabel'
import linearProgress from './linearProgress'
import link from './link'
import radio from './radio'
import svgIcon from './svgIcon'
import tab from './tab'
import tabs from './tabs'
import typography from './typography'

const overrides: Overrides = {
  MuiButton: button,
  MuiCheckbox: checkbox,
  MuiCircularProgress: circularProgress,
  MuiFilledInput: filledInput,
  MuiFormControl: formControl,
  MuiFormControlLabel: formControlLabel,
  MuiFormGroup: formGroup,
  MuiFormHelperText: formHelperText,
  MuiFormLabel: formLabel,
  MuiIconButton: iconButton,
  MuiInputLabel: inputLabel,
  MuiInputBase: {
    input: {
      height: '22px',
    },
  } as Overrides['MuiInputBase'],
  MuiLink: link,
  MuiRadio: radio,
  MuiSvgIcon: svgIcon,
  MuiTab: tab,
  MuiTabs: tabs,
  MuiTypography: typography,
  MuiLinearProgress: linearProgress,
  // Overrides не знает о MuiTabScrollButton
  MuiTabScrollButton: {
    root: {
      '&$disabled': {
        color: '#999CA6',
        opacity: 1,
      },
    },
  },
  MuiTooltip: {
    tooltip: {
      padding: '16px 20px',
      fontSize: 'inherit',
      color: '#121239',
      backgroundColor: 'white', // NOTE: color
      boxShadow: '0px 16px 40px rgba(0, 0, 0, 0.10)',
    } as CSSProperties,
    arrow: {
      color: 'white', // NOTE: color
    } as CSSProperties,
  },
  // NOTE: not works correctly, check tooltip classes
} as any as Overrides

export default overrides
