import { buildAddBannerQuery } from 'graphql/mutations/addBanner'

export const ADD_BANNER = buildAddBannerQuery(`
  id
  name
  status
  url
  text
  button
  tx_color
  show_title
  desktop_image {
    id
    path 
  }
  mobile_image {
    id
    path 
  }
`)
