import { Overrides } from '@material-ui/core/styles/overrides'

const checkbox: Overrides['MuiCheckbox'] = {
  root: {
    padding: '0px',
    color: '#DCDEE4',
    '&:hover': {
      color: '#121239',
    },
  },
}

export default checkbox
