import axios from 'axios'
import Cookie from 'js-cookie'

export const api = axios.create({
  baseURL: process.env.REACT_APP_STORAGE_API_URI,
})

export const fileUrl = process.env.REACT_APP_STORAGE_URI

export type IUploadedData = {
  mime: string
  name: string
  size: number
  status: boolean
  path: string
  base_path: string
}

interface TResponse {
  [x: number]: IUploadedData
  count: number
}

type IOptions = {
  type: keyof typeof convertUrl
}

const convertUrl = {
  images: 'api/upload-images',
  banner: 'api/upload-images/banner',
  icon: 'api/upload-images/icon',
  files: 'api/upload-files',
}

const createUrlFromUploadedFile = async (files: File[], { type }: IOptions): Promise<IUploadedData[]> => {
  const formData = new FormData()

  files.forEach((file) => {
    formData.append('file[]', file)
  })

  formData.append('token', Cookie.get('token') || '')

  try {
    const { data } = await api.post<TResponse>(convertUrl[type], formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    const { count, ...imagesData } = data

    const images: IUploadedData[] = Object.values(imagesData)

    return images.filter(({ status }) => status)
  } catch (err: any) {
    console.error(err)
    return []
  }
}

export default createUrlFromUploadedFile
