import React, { memo, useCallback, useMemo, useState } from 'react'
import { usePagination, PaginationProps } from '@material-ui/lab/Pagination'

import IconButton from 'components/IconButton'
import Text from 'components/Typography'
import ArrowLeftSmallIcon from 'components/Icons/ArrowLeftSmallIcon'
import ArrowRightSmallIcon from 'components/Icons/ArrowRightSmallIcon'
import block from 'utils/block'

import { ReactComponent as SeparatorIcon } from './icons/separator.svg'

import styles from './pagination.module.scss'

type IPaginationProps = Omit<PaginationProps, 'onChange'> & {
  variant?: 'list' | 'none'
  onChange: (value: number) => void
}

const MIN_VALUE = 1
const b = block(styles)

const Pagination: React.FC<IPaginationProps> = ({
  page,
  count = 1,
  variant = 'list',
  onChange,
  ...props
}) => {
  const [value, setValue] = useState<string>(() => {
    if (!page) return '1'
    return `${page}`
  })

  const onValueChange = useCallback(
    (event: InputChangeEvent) => {
      const { value: nextValue } = event.target

      if (Number.isNaN(nextValue)) {
        throw new Error('WRONG PAGINATION VALUE')
      }

      if (!nextValue.length) {
        setValue(nextValue)
      } else {
        const ensureMinMaxValue = Math.min(Math.max(Number(nextValue), MIN_VALUE), count)
        setValue(`${ensureMinMaxValue}`)
      }
    },
    [count],
  )

  const onValueBlur = useCallback(() => {
    if (!value.length) return
    onChange(Number(value))
  }, [onChange, value])

  const onChangeHelper = useCallback(
    (event: React.ChangeEvent<unknown>, newPage: number) => {
      if (!onChange) return
      onChange(newPage)
    },
    [onChange],
  )

  const { items } = usePagination({ page, count, onChange: onChangeHelper, ...props })

  const previousButton = useMemo(() => {
    const button = items[0].type === 'previous' ? items[0] : items.find((item) => item.type === 'previous')

    if (!button) {
      throw new Error('NO PREVIOUS BUTTON')
    }

    return button
  }, [items])

  const nextButton = useMemo(() => {
    const button =
      items[items.length - 1].type === 'next'
        ? items[items.length - 1]
        : items.find((item) => item.type === 'next')

    if (!button) {
      throw new Error('NO NEXT BUTTON')
    }

    return button
  }, [items])

  const maxPage = useMemo(() => items.find((paginationItem) => paginationItem.page === count), [items, count])

  const onMaxPageClick = useCallback(() => {
    if (!maxPage) return
    if (onChange) onChange(maxPage.page)
  }, [maxPage, onChange])

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        if (!value.length) return
        onChange(Number(value))
      }
    },
    [value, onChange],
  )

  return (
    <div className={b('container', { variant })}>
      <IconButton
        className={styles.prevButton}
        aria-label="Go to previous page"
        disabled={previousButton.disabled}
        onClick={previousButton.onClick}
      >
        <ArrowLeftSmallIcon />
      </IconButton>
      <input
        className={styles.input}
        value={value}
        onChange={onValueChange}
        onBlur={onValueBlur}
        onKeyDown={onKeyDown}
      />
      <SeparatorIcon className={styles.separator} />
      {maxPage ? (
        <IconButton className={styles.maxPageBtn} aria-label="Go to last page" onClick={onMaxPageClick}>
          <Text className={styles.maxPage}>{maxPage.page} </Text>
        </IconButton>
      ) : null}
      <IconButton
        className={styles.nextButton}
        aria-label="Go to next page"
        disabled={nextButton.disabled}
        onClick={nextButton.onClick}
      >
        <ArrowRightSmallIcon />
      </IconButton>
    </div>
  )
}

export default memo(Pagination)
