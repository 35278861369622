import { gql } from '@apollo/client'

export type ISetBaseProductStatusParams = {
  id: string
  status: boolean
}

export const SET_BASE_PRODUCT_STATUS = gql`
  mutation setBaseProductStatus($id: ID!, $status: Boolean!) {
    setBaseProductStatus(id: $id, status: $status) {
      id
      status
    }
  }
`
