import React from 'react'

import Text, { H1 } from 'components/Typography'
import Button from 'components/Button'
import Link from 'components/Link'
import useCmsLinks from 'hooks/useCmsLinks'

import styles from './empty.module.scss'

const Empty: React.FC = () => {
  const { priceListCreateLink } = useCmsLinks()

  return (
    <div className={styles.container}>
      <H1 className={styles.title}>Здесь будут прайс листы</H1>
      <Text className={styles.subtitle}>У вас пока нет ни одного прайс листа. Создайте первый!</Text>
      <Link href={priceListCreateLink}>
        <Button>Создать прайс лист</Button>
      </Link>
    </div>
  )
}

export default Empty
