import React from 'react'
import { Redirect } from 'react-router-dom'

import { useAuth } from 'contexts/AuthProvider'
import { H3 } from 'components/Typography'
import Preloader from 'components/Preloader'
import TimeIcon from 'components/Icons/TimeIcon'
import { TRegisterInput } from 'types/authTypes'

import AuthHeader from 'components/AuthHeader'
import SignUpForm from './SignUpForm'

import styles from './signUp.module.scss'

const SignUp: React.FC = () => {
  const { token, registerNewUser, registerLoading, errors, setErrors } = useAuth()

  const onSubmit = (input: TRegisterInput) => {
    registerNewUser(input)
  }

  if (registerLoading) return <Preloader />
  if (token) return <Redirect to="/" />

  return (
    <div className={styles.Root}>
      <AuthHeader />
      <div className={styles.Container}>
        <H3 className={styles.ContainerSubtitle}>Регистрация</H3>
        <div className={styles.NotificationBlock}>
          <TimeIcon className={styles.NotificationBlock__icon} />
          <div>
            <p className={styles.NotificationBlock__title}>3 дня бесплатно</p>
            <p>
              Заполните свои данные и получите <br />
              полный доступ к продукту
            </p>
          </div>
        </div>
        <SignUpForm onSubmit={onSubmit} errors={errors} setErrors={setErrors} />
      </div>
    </div>
  )
}

export default SignUp
