import React, { ReactNode } from 'react'

import { H2 } from 'components/Typography'
import ErrorIcon from 'components/Icons/ErrorIcon'
import Dialog from 'components/Dialog'
import DialogContent from 'components/DialogContent'
import DialogCloseButton from 'components/DialogCloseButton'

import styles from './errorDialog.module.scss'

interface IErrorDialogProps {
  isOpen: boolean
  content?: ReactNode
  onClose: () => void
}

const ErrorDialog: React.FC<IErrorDialogProps> = ({ isOpen, content, onClose }) => (
  <Dialog isOpen={isOpen} onClose={onClose}>
    <DialogCloseButton onClick={onClose} />
    <DialogContent className={styles.content}>
      <ErrorIcon className={styles.icon} color="error" fontSize="large" />
      <H2 className={styles.title}>Произошла ошибка</H2>
      {content}
    </DialogContent>
  </Dialog>
)

export default ErrorDialog
