import React, { ReactNode, memo } from 'react'
import { Formik, FormikHelpers } from 'formik'

import Button from 'components/Button'
import LeavePageDirtyFormHelper from 'components/LeavePageDirtyFormHelper'
import AddSubmitShortcutFormHelper from 'components/AddSubmitShortcutFormHelper'
import withErrorBoundary from 'hocs/withErrorBoundary'
import TextInput from 'components/Inputs/TextInput'
import phoneTransform from 'utils/formInputs/phoneTransform'
import { ReactComponent as Clock } from 'assets/img/clockIcon_warning.svg' // TODO: replace with icon

import validationSchema from './utils/validationSchema'
import { IProfileFormValues } from './types'

import styles from './ProfileForm.module.scss'

type IProfileFormProps = {
  submitLoading: boolean
  initialValue: IProfileFormValues
  onSubmit: (values: IProfileFormValues, helpers: FormikHelpers<IProfileFormValues>) => void
  renderControls?: () => ReactNode
  onResendVerify: () => void
}

const Row: React.FC = ({ children }) => <div className={styles.row}>{children}</div>

const ProfileForm: React.FC<IProfileFormProps> = ({
  submitLoading,
  initialValue,
  onSubmit,
  renderControls = () => {},
  onResendVerify,
}) => (
  <Formik
    enableReinitialize
    initialValues={initialValue}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    {({ values, handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <LeavePageDirtyFormHelper />
        <AddSubmitShortcutFormHelper />

        <Row>
          <TextInput required name="name" label="Имя" placeholder="Введите имя" />
          <TextInput name="second_name" label="Фамилия" placeholder="Введите фамилию" />
        </Row>
        <Row>
          <TextInput
            name="email"
            label="E-mail"
            placeholder="example@mail.ru"
            labelAsideRight={!values.email_verified_at ? <Clock /> : null}
            labelProps={{ className: styles.emailLabel }}
          />

          <TextInput
            name="phone"
            label="Телефон"
            placeholder="Введите номер телефона"
            transform={phoneTransform}
          />
        </Row>

        {!values.email_verified_at && (
          <div className={styles.EmailWarning}>
            <p className={styles.EmailWarning__title}>E-mail не был подтвержден</p>
            <p>Чтобы получить доступ ко всем возможностям платформы Sellty, подтвердите ваш e-mail.</p>
            <button className={styles.EmailWarning__btn} type="button" onClick={onResendVerify}>
              Отправить повторно
            </button>
          </div>
        )}

        <div className={styles.controlsPanel}>
          <Button type="submit" disabled={submitLoading}>
            Сохранить
          </Button>

          {renderControls()}
        </div>
      </form>
    )}
  </Formik>
)

export default withErrorBoundary(memo(ProfileForm))
