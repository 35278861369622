import { gql } from '@apollo/client'

export const UPDATE_PASSWORD = gql`
  mutation updateNewPassword($old_password: String!, $password: String!, $password_confirmation: String!) {
    updatePassword(
      input: {
        old_password: $old_password
        password: $password
        password_confirmation: $password_confirmation
      }
    ) {
      status
    }
  }
`
