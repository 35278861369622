import { DocumentNode, gql, QueryHookOptions, useQuery } from '@apollo/client'

import { Paginate, PBaseProducts } from 'types/types'

export type IGetBaseProducts = {
  getBaseProducts: PBaseProducts
}

export type IGetBaseProductsParams = {
  search: string
  seller_id: IPrimaryKey
  paginate: Paginate
}

export const buildGetBaseProductsQuery = (fields: string): DocumentNode => gql`
  query getBaseProducts($search: String, $seller_id: ID!, $paginate: Paginate) {
    getBaseProducts(seller_id: $seller_id, search: $search, paginate: $paginate) {
      page
      pages
      elements
      list {
        ${fields}
      }
    }
  }
`

export const useGetBaseProductsQuery = <TData = IGetBaseProducts, TOptions = IGetBaseProductsParams>(
  fields: DocumentNode,
  options?: QueryHookOptions<TData, TOptions>,
) => useQuery<TData, TOptions>(fields, options)
