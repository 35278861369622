import React, { useCallback } from 'react'
import { useField } from 'formik'

import SelectField, { ISelectFieldProps } from 'components/SelectField'
import formikFieldEvent from 'utils/formInputs/formikFieldEvent'

export type ISelectInputProps = Omit<ISelectFieldProps, 'value' | 'onChange' | 'onClear' | 'onBlur'> & {
  name: string
  hasClear?: boolean
}

const SelectInput: React.FC<ISelectInputProps> = ({ name, hasClear = false, ...props }) => {
  const [{ value, onChange, onBlur }, { error, touched, initialTouched }] = useField<string | null>(name)

  const hasError = (touched || initialTouched) && !!error

  const onChangeHandler = useCallback(
    (payload: string) => {
      onChange(formikFieldEvent(name, payload))
    },
    [onChange, name],
  )

  const onClear = useCallback(() => {
    onChange(formikFieldEvent(name, null))
  }, [onChange, name])

  return (
    <SelectField
      name={name}
      error={hasError}
      helperText={hasError ? error : null}
      value={value}
      onChange={onChangeHandler}
      onBlur={onBlur}
      onClear={hasClear ? onClear : undefined}
      {...props}
    />
  )
}

export default SelectInput
