import { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

interface IState {
  isBlock: boolean
  location?: string
  isDialogOpen: boolean
}

const initialState: IState = {
  isBlock: false,
  isDialogOpen: false,
}

const useHistoryBlock = () => {
  const history = useHistory()

  const [{ isBlock, isDialogOpen, location }, setState] = useState<IState>(initialState)

  useEffect(() => {
    if (!isBlock && location !== undefined) {
      history.push(location)
    }

    const unblock = history.block(({ pathname }) => {
      if (isBlock) {
        setState((prevState) => ({ ...prevState, location: pathname, isDialogOpen: true }))
        return false
      }
    })

    return () => {
      unblock()
    }
  }, [isBlock, location])

  const unblocking = useCallback(() => {
    setState(initialState)
  }, [])

  const blocking = useCallback(() => {
    setState((prevState) => ({ ...prevState, isBlock: true }))
  }, [])

  const closeModal = useCallback(() => {
    setState((prevState) => ({ ...prevState, isDialogOpen: false }))
  }, [])

  const onExit = useCallback(() => {
    setState((prevState) => ({ ...prevState, isDialogOpen: false, isBlock: false }))
  }, [])

  return {
    isDialogOpen,
    unblocking,
    blocking,
    closeModal,
    onExit,
  }
}

export default useHistoryBlock
