import { Overrides } from '@material-ui/core/styles/overrides'

const filledInput: Overrides['MuiFilledInput'] = {
  root: {
    borderRadius: '4px',
    backgroundColor: '#fff',
    transition: 'none',
    border: '1px solid #DCDEE4',

    '&:hover': {
      backgroundColor: '#fff',
      borderColor: '#999CA6',
    },
    '&$focused': {
      backgroundColor: '#fff',
      borderColor: '#999CA6',
    },
    '&$disabled': {
      backgroundColor: '#F4F5F8',
      borderColor: '#DCDEE4',
      color: '#999CA6',
      WebkitTextFillColor: '#b3b3b3',
    },
    '&$error': {
      borderColor: '#F9423A',
    },
  },

  input: {
    padding: '8px 16px',
    borderRadius: '4px',

    '&$disabled': {
      color: '#999CA6',
      WebkitTextFillColor: '#b3b3b3',
    },
    '&::-webkit-input-placeholder': {
      color: '#999CA6',
      opacity: 1,
    },
  },
  inputAdornedEnd: {
    paddingRight: '10px',
  },
  adornedEnd: {
    paddingRight: '16px',
  },
  multiline: {
    padding: '14px 16px',
  },
}

export default filledInput
