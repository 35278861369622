import { DocumentNode, gql, useMutation, MutationHookOptions } from '@apollo/client'

import { IPaymentSettings, IPaymentSettingsInput } from 'types/types'

export type ISetPaymentSettingsParams = {
  seller_id: IPrimaryKey
  input: IPaymentSettingsInput
}

export type ISetPaymentSettings = {
  setPaymentSettings: IPaymentSettings
}

export const buildSetPaymentSettingsQuery = (fields: string): DocumentNode => gql`
  mutation setPaymentSettings($seller_id: ID!, $input: PaymentSettingsInput) {
    setPaymentSettings(seller_id: $seller_id, input: $input) {
      ${fields}
    }
  }
`

export const useSetPaymentSettings = <TData = ISetPaymentSettings, TOptions = ISetPaymentSettingsParams>(
  fields: DocumentNode,
  options?: MutationHookOptions<TData, TOptions>,
) => useMutation<TData, TOptions>(fields, options)
