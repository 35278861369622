import React, { memo, useCallback, useMemo, useState } from 'react'
import isEqual from 'lodash/isEqual'
import debounce from 'lodash/debounce'

import { SEARCH_INPUT_LIMIT, SEARCH_DEBOUNCE_TIMEOUT } from 'consts'
import { GET_ATTRIBUTES, useGetAttributesQuery } from 'graphql/queries/getAttributes'
import Button from 'components/Button'
import Text, { H1 } from 'components/Typography'
import Preloader from 'components/Preloader'
import ResultTitle from 'components/ResultTitle'
import PageContainer from 'components/PageContainer'
import ResultSearchFilter from 'components/ResultSearchFilter'
import hasPagination from 'utils/hasPagination'
import useCmsParams from 'hooks/useCmsParams'
import Pagination from 'components/Pagination'
import withErrorBoundary from 'hocs/withErrorBoundary'
import NoSearchResult from 'components/NoSearchResult'
import ApiError from 'components/ApiError'
import useSearchParams from 'hooks/useSearchParams'
import buildPaginateParams from 'utils/buildPaginateParams'

import CreateDialog from './components/CreateDialog'
import Attribute from './components/Attribute'

import styles from './attributesList.module.scss'

type IPayload = {
  search: string
  page: string
}

const initPayload: IPayload = {
  search: '',
  page: '1',
}

const AttributesList: React.FC = () => {
  const { sellerId } = useCmsParams()

  const [searchParams, setSearchParams] = useSearchParams<IPayload>(initPayload)
  const { page, search } = searchParams

  const [isOpen, setOpen] = useState<boolean>(false)
  const [searchInput, setSearchInput] = useState<string>(search || initPayload.search)

  const onSearchDebounce = useMemo(
    () =>
      debounce((payload: string) => {
        setSearchParams({ ...searchParams, page: '1', search: payload })
      }, SEARCH_DEBOUNCE_TIMEOUT),
    [setSearchParams, searchParams],
  )

  const hasFilters = useMemo(() => !isEqual(searchParams, initPayload), [searchParams])

  const { data, loading, error, refetch } = useGetAttributesQuery(GET_ATTRIBUTES, {
    variables: {
      seller_id: sellerId,
      search,
      paginate: buildPaginateParams(page),
    },
    fetchPolicy: 'network-only',
  })

  const { getAttributes: { list: attributes = [], elements = 0, pages = 0 } = {} } = data || {}

  const onSearchChange = useCallback(
    (event: InputChangeEvent) => {
      const { value } = event.target
      if (value.length > SEARCH_INPUT_LIMIT) return
      setSearchInput(value)
      onSearchDebounce(value)
    },
    [onSearchDebounce],
  )

  const onSearchClear = useCallback(() => {
    setSearchInput('')
    setSearchParams((prev) => ({ ...prev, page: '1', search: '' }))
  }, [setSearchParams])

  const onPageChange = useCallback(
    (nextPage: number) => {
      setSearchParams((prev) => ({ ...prev, page: nextPage.toString() }))
    },
    [setSearchParams],
  )

  const onCreateSuccess = useCallback(() => {
    refetch()
    setOpen(false)
  }, [refetch])

  const initialLoading = loading && !hasFilters
  const isEmpty = !loading && !hasFilters && !attributes.length

  if (initialLoading) return <Preloader />

  if (error) {
    return (
      <PageContainer>
        <ResultTitle>Контрагенты</ResultTitle>
        <div className={styles.filtersGroup}>
          <Button onClick={() => setOpen(true)}>Создать атрибут</Button>
        </div>
        <ApiError error={error} />
        <CreateDialog isOpen={isOpen} onClose={() => setOpen(false)} onSuccess={onCreateSuccess} />
      </PageContainer>
    )
  }

  if (isEmpty) {
    return (
      <PageContainer>
        <div className={styles.emptyGroup}>
          <H1 className={styles.emptyTitle}>Здесь будут атрибуты товаров</H1>
          <Text className={styles.emptySubtitle}>
            У вас пока нет ни одного атрибута. Создайте первый атрибут!
          </Text>
          <Button onClick={() => setOpen(true)}>Создать атрибут</Button>
        </div>
        <CreateDialog isOpen={isOpen} onClose={() => setOpen(false)} onSuccess={onCreateSuccess} />
      </PageContainer>
    )
  }

  return (
    <PageContainer>
      <ResultTitle>Атрибуты</ResultTitle>

      <div className={styles.filtersWrapper}>
        <Button onClick={() => setOpen(true)}>Создать атрибут</Button>
        <ResultSearchFilter
          value={searchInput}
          placeholder="Поиск атрибутов по ID"
          onChange={onSearchChange}
          onClear={onSearchClear}
        />
      </div>

      {loading ? (
        <Preloader />
      ) : (
        <>
          {attributes.map((attribute) => (
            <Attribute attribute={attribute} key={attribute.id} />
          ))}

          {hasPagination(elements) && (
            <Pagination page={Number(page)} count={pages} onChange={onPageChange} />
          )}

          {!attributes.length && <NoSearchResult />}
        </>
      )}

      <CreateDialog isOpen={isOpen} onClose={() => setOpen(false)} onSuccess={onCreateSuccess} />
    </PageContainer>
  )
}

export default withErrorBoundary(memo(AttributesList))
