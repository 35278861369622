import { DocumentNode, gql, useMutation, MutationHookOptions } from '@apollo/client'

import { IPaymentSettings, IPaymentMethodTypes } from 'types/types'

export type ISetPaymentMethodStatusParams = {
  seller_id: IPrimaryKey
  method: IPaymentMethodTypes
  status: boolean
}

export type ISetPaymentMethodStatus = {
  setPaymentMethodStatus: IPaymentSettings
}

export const buildSetPaymentMethodStatusQuery = (fields: string): DocumentNode => gql`
  mutation setPaymentMethodStatus($seller_id: ID!, $method: PaymentMethodTypes!, $status: Boolean!) {
    setPaymentMethodStatus(seller_id: $seller_id, method: $method, status: $status) {
      ${fields}
    }
  }
`

export const useSetPaymentMethodStatus = <
  TData = ISetPaymentMethodStatus,
  TOptions = ISetPaymentMethodStatusParams,
>(
  fields: DocumentNode,
  options?: MutationHookOptions<TData, TOptions>,
) => useMutation<TData, TOptions>(fields, options)
