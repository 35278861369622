import React, { useMemo, useCallback, memo } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Formik, FormikHelpers } from 'formik'
import { boolean, object, string, InferType } from 'yup'

import withLeaveThisPageModal from 'hocs/withLeaveThisPageModal'
import { IGetAttribute } from 'graphql/queries/getAttribute'
import { ISetAttribute, ISetAttributeParams } from 'graphql/mutations/setAttribute'
import GoBackLink from 'components/GoBackLink'
import ResultTitle from 'components/ResultTitle'
import PageContainer from 'components/PageContainer'
import useCmsParams from 'hooks/useCmsParams'
import Preloader from 'components/Preloader'
import Text from 'components/Typography'
import Button from 'components/Button'
import LimitText from 'components/LimitText'
import withErrorBoundary from 'hocs/withErrorBoundary'
import useNotifyCms from 'hooks/useNotifyCms'
import LeavePageDirtyFormHelper from 'components/LeavePageDirtyFormHelper'
import AddSubmitShortcutFormHelper from 'components/AddSubmitShortcutFormHelper'
import FormControlsPanel from 'components/FormControlsPanel'
import TextInput from 'components/Inputs/TextInput'
import SwitchInput from 'components/Inputs/SwitchInput'
import convertToFormErrors from 'utils/convertToFormErrors'
import { Attribute } from 'types/types'
import numberFormat from 'utils/formInputs/numberFormat'

import Controls from './components/Controls'
import { GET_ATTRIBUTE, SET_ATTRIBUTE } from './queries'

import styles from './attributeEdit.module.scss'

type IFormValues = InferType<typeof validationSchema>

const defaultValues: IFormValues = {
  name: '',
  description: '',
  filters: true,
  position: '9999',
}

const validationSchema = object({
  name: string().required('Обязательное поле').max(200, 'Длина не может превышать более 200 символов'),
  description: string().required('Обязательное поле').default(''),
  filters: boolean().default(true),
  position: string().default('9999'),
})

const prepareInitialValues = ({ name, description, number: position, filters }: Attribute): IFormValues => ({
  name,
  description: description || '',
  filters: filters || false,
  position: position?.toString() || '9999',
})

const prepareSubmitValues = ({
  name,
  description,
  position,
  filters,
}: IFormValues): ISetAttributeParams['input'] => ({
  name,
  description,
  filters,
  number: Number(position),
})

const AttributeEdit: React.FC = () => {
  const { sellerId, attributeId } = useCmsParams()
  const addNotify = useNotifyCms()

  const { data, loading } = useQuery<IGetAttribute>(GET_ATTRIBUTE, {
    fetchPolicy: 'network-only',
    variables: {
      id: attributeId,
    },
  })

  const initialFormValues = useMemo(() => {
    if (!data) return defaultValues
    return prepareInitialValues(data.getAttribute)
  }, [data])

  const [onSetAttribute, { loading: setLoading }] = useMutation<ISetAttribute, ISetAttributeParams>(
    SET_ATTRIBUTE,
  )

  const onSubmit = useCallback(
    (values: IFormValues, { setErrors, resetForm }: FormikHelpers<IFormValues>) =>
      onSetAttribute({ variables: { id: attributeId, input: prepareSubmitValues(values) } })
        .then((response) => {
          const nextData = response.data?.setAttribute
          if (!nextData) throw new Error('No setAttribute data')

          addNotify('success')
          resetForm({ values: prepareInitialValues(nextData) })
        })
        .catch((error) => {
          addNotify('error')
          const errors = convertToFormErrors(error)
          if (errors) setErrors(errors)
        }),
    [attributeId, onSetAttribute, addNotify],
  )

  if (loading) {
    return (
      <PageContainer>
        <GoBackLink href={`/${sellerId}/catalog/attributes`} />
        <Preloader />
      </PageContainer>
    )
  }

  return (
    <PageContainer>
      <GoBackLink href={`/${sellerId}/catalog/attributes`} />

      <ResultTitle>
        {data?.getAttribute.name || ''}
        <Text variant="inherit" color="textSecondary">{` ID ${attributeId}`}</Text>
      </ResultTitle>
      <Formik
        enableReinitialize
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className={styles.content}>
              <LeavePageDirtyFormHelper />
              <AddSubmitShortcutFormHelper />
              <TextInput
                name="name"
                required
                label="Название"
                offset="default"
                inputProps={{ maxLength: 200 }}
                labelAsideRight={<LimitText limit={200} value={values.name} />}
              />
              <TextInput name="description" required label="Значения атрибута" offset="default" />
              <TextInput
                name="position"
                className={styles.positionInput}
                label="Сортировка"
                placeholder="Введите значение от 1 до 9999"
                offset="default"
                inputProps={{ maxLength: 4 }}
                transform={numberFormat}
              />
              <SwitchInput
                name="filters"
                placement="right"
                gap="large"
                label={<Text>Показывать в фильтре</Text>}
              />
            </div>
            <FormControlsPanel className={styles.contolsGroup}>
              <Button type="submit" className={styles.submitBtn} disabled={setLoading}>
                Сохранить
              </Button>

              <Controls />
            </FormControlsPanel>
          </form>
        )}
      </Formik>
    </PageContainer>
  )
}

export default withErrorBoundary(withLeaveThisPageModal(memo(AttributeEdit)))
