import React from 'react'
import { useHistory } from 'react-router-dom'

import useCmsLinks from 'hooks/useCmsLinks'

import { ReactComponent as CopyIcon } from './icons/copy_icon.svg'
import RowItem from '../RowItem'

const LinksImport: React.FC = () => {
  const { push } = useHistory()
  const { importLinksIntegration } = useCmsLinks()

  const onSettingsClick = () => push(importLinksIntegration)

  return <RowItem title="Импорт товаров по ссылке" icon={<CopyIcon />} onSettingClick={onSettingsClick} />
}

export default LinksImport
