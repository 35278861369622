import React, { SyntheticEvent, useState } from 'react'
import { Redirect } from 'react-router-dom'
import validator from 'validator'

import { useAuth } from 'contexts/AuthProvider'
import Preloader from 'components/Preloader'
import { H3 } from 'components/Typography'

import AuthHeader from 'components/AuthHeader'
import Recovery from './Recovery/Recovery'

import styles from './recoveryPassword.module.scss'

const RecoveryPassword: React.FC = () => {
  const { recovery, recoveryLoading, recoveryData, token, errors } = useAuth()
  const [email, setEmail] = useState<string>('')
  const isValidEmail = validator.isEmail(email)
  const [isOnSubmit, setIsOnSubmit] = useState(false)

  const onSubmit = () => recovery(email)

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault()
    setIsOnSubmit(true)

    if (isValidEmail) {
      onSubmit()
    }
  }

  if (recoveryLoading) return <Preloader />

  return (
    <div className={styles.Root}>
      <AuthHeader />
      <div className={styles.Container}>
        <H3 className={styles.ContainerSubTitle}>Восстановление пароля</H3>
        <Recovery
          value={email}
          onChange={(v) => setEmail(v)}
          isValidEmail={isValidEmail}
          handleSubmit={(e) => handleSubmit(e)}
          data={recoveryData}
          errors={errors}
          isOnSubmit={isOnSubmit}
        />
      </div>
      {token && <Redirect to="/" />}
    </div>
  )
}

export default RecoveryPassword
