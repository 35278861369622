import React, { FC, memo, useMemo } from 'react'

import Text from 'components/Typography'
import Button from 'components/Button'
import Link from 'components/Link'
import { IPriceType } from 'types/types'
import ProductPriceListsDialog from 'components/Dialogs/ProductPriceListsDialog'
import useBoolean from 'hooks/useBoolean'
import useCmsLinks from 'hooks/useCmsLinks'

import styles from './priceLists.module.scss'

type IPriceListsProps = {
  prices: IPriceType[]
}

const PriceLists: FC<IPriceListsProps> = ({ prices }) => {
  const [isOpen, setIsOpen] = useBoolean()
  const { priceListEditLink } = useCmsLinks()

  const defaultPrice = useMemo(() => prices.find(({ default_price }) => default_price), [prices])

  if (prices.length === 0) return null

  return (
    <div className={styles.container}>
      <div className={styles.nameGroup}>
        {defaultPrice ? (
          <>
            <Text className={styles.title}>Прайс лист по умолчанию</Text>
            {defaultPrice.id ? (
              <Link className={styles.name} href={priceListEditLink(defaultPrice.id)}>
                {defaultPrice.name}
              </Link>
            ) : (
              <Text className={styles.name}>{defaultPrice.name} </Text>
            )}
          </>
        ) : null}
      </div>
      <Button className={styles.showMoreButton} variant="outlined" onClick={setIsOpen.on}>
        Просмотреть все цены
      </Button>

      <ProductPriceListsDialog isOpen={isOpen} prices={prices} onClose={setIsOpen.off} />
    </div>
  )
}

export default memo(PriceLists)
