import { DocumentNode, gql } from '@apollo/client'

import { IPriceRule } from 'types/types'

export type IGetPriceRuleParams = {
  id: IPrimaryKey
}

export type IGetPriceRule = {
  getPriceRule: IPriceRule
}

export const buildGetPriceRuleQuery = (fields: string): DocumentNode => gql`
  query getPriceRule($id: ID!) {
    getPriceRule(id: $id) {
      ${fields}
    }
  }
`
