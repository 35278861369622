import { DocumentNode, gql, useMutation, MutationHookOptions } from '@apollo/client'

import { IContact, IContactInput } from 'types/types'

export type ISetContact = {
  setContact: IContact
}

export type ISetContactParams = {
  id: IPrimaryKey
  input: IContactInput
}

export const buildSetContactQuery = (fields: string): DocumentNode => gql`
  mutation setContact($id: ID!, $input: ContactInput) {
    setContact(id: $id, input: $input) {
      ${fields}
    }
  }
`

export const useSetContactMutation = <TData = ISetContact, TOptions = ISetContactParams>(
  fields: DocumentNode,
  options?: MutationHookOptions<TData, TOptions>,
) => useMutation<TData, TOptions>(fields, options)
