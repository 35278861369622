import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

type SearchParams = {
  [key: string]: string | boolean
}

const parseSearchParams = (payload: string) =>
  queryString.parse(payload, {
    arrayFormat: 'bracket',
    parseBooleans: true,
  })

const useGetSearchParams = <T extends SearchParams>(): T => {
  const location = useLocation()

  const params = useMemo(() => parseSearchParams(location.search) as T, [location.search])

  return params
}

export default useGetSearchParams
