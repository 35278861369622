import React from 'react'

import { IProduct } from 'types/types'
import Button from 'components/Button'
import Link from 'components/Link'
import Text from 'components/Typography'
import useCmsParams from 'hooks/useCmsParams'
import useCurrentSeller from 'hooks/useCurrentSeller'
import renderCurrencySymbol from 'utils/renderCurrencySymbol'

import noProductImage from 'assets/img/no-img.svg'

import styles from './product.module.scss'

type IProductProps = {
  isActive?: boolean
  disabledAddMore: boolean
  product: IProduct
  onAdd: (payload: IProduct) => void
  onRemove: (payload: IPrimaryKey) => void
}

const formatter = new Intl.NumberFormat('ru-RU', { style: 'decimal', currency: 'RUB' })
const formatPrice = (price: number) => formatter.format(price)

const Product: React.FC<IProductProps> = ({
  disabledAddMore,
  isActive = false,
  product,
  onAdd,
  onRemove,
}) => {
  const { id: productId, base_product_id, name, vendor_code, images, price } = product

  const { sellerId } = useCmsParams()
  const { settings } = useCurrentSeller()

  return (
    <div className={styles.container}>
      <Link className={styles.link} href={`/${sellerId}/catalog/products/${base_product_id}`}>
        <img className={styles.image} src={images[0]?.path || noProductImage} alt={name} />
        <div>
          <Text className={styles.name}>{name}</Text>
          <Text className={styles.vendorCode} color="textSecondary" size="small">
            {`Арт.\u00A0${vendor_code ?? '-'}`}
          </Text>
        </div>
      </Link>
      <div className={styles.priceGroup}>
        <Text className={styles.priceLabel} size="small" align="right">
          Цена
        </Text>
        <Text className={styles.price} bold align="right">
          {price ? `${formatPrice(Number(price))} ${renderCurrencySymbol(settings?.currency)}` : '-'}
        </Text>
      </div>
      <Button
        className={styles.controlButton}
        disabled={disabledAddMore}
        color={isActive ? 'secondary' : 'primary'}
        variant={isActive ? 'contained' : 'outlined'}
        onClick={() => (isActive ? onRemove(productId) : onAdd(product))}
      >
        {isActive ? 'Удалить' : 'Добавить'}
      </Button>
    </div>
  )
}

export default Product
