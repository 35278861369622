import React, { createContext, useCallback, useEffect, useState, useMemo } from 'react'

type IShortcut = {
  ctrlS?: (() => void) | null
}

export type IShortcutsContext = {
  registerShortcut: (name: keyof IShortcut, fn: (() => void) | null) => void
}

const initial = {
  registerShortcut: () => {},
}

export const ShortcutsContext = createContext<IShortcutsContext>(initial)
// event.keyCode  event.metaKey // check on mac
const ShortcutsProvider: React.FC = ({ children }) => {
  const [shortcuts, setShortcuts] = useState<IShortcut>({})
  const { ctrlS } = shortcuts

  const registerShortcut = useCallback((name: keyof IShortcut, fn: (() => void) | null) => {
    setShortcuts((prevState) => ({ ...prevState, [name]: fn }))
  }, [])

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      const { key, ctrlKey, metaKey } = event
      const lowerKey = key?.toLowerCase()

      if ((ctrlKey && lowerKey === 's') || (metaKey && lowerKey === 's')) {
        if (!ctrlS) return
        event.preventDefault()
        ctrlS()
      }
    },
    [ctrlS],
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress)

    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [handleKeyPress])

  const value: IShortcutsContext = useMemo(() => ({ registerShortcut }), [registerShortcut])

  return <ShortcutsContext.Provider value={value}>{children}</ShortcutsContext.Provider>
}

export default ShortcutsProvider
