import { gql } from '@apollo/client'

import { IUser } from 'types/types'

export type ISetUserParams = {
  name: string
  email?: string
  second_name?: string
  phone?: string
}

export type ISetUser = {
  setUser: IUser
}

export const SET_USER_INFO = gql`
  mutation setUser($name: String!, $email: String, $second_name: String, $phone: String) {
    setUser(input: { name: $name, email: $email, second_name: $second_name, phone: $phone }) {
      name
      second_name
      phone
      email
    }
  }
`
