import { IClient } from 'types/types'

type IReturn = Record<string, IClient>

const convertClientsToHash = (payload: IClient[]): IReturn => {
  const response: IReturn = {}

  for (let index = 0; index < payload.length; index++) {
    const element = payload[index]
    response[element.id] = element
  }

  return response
}

export default convertClientsToHash
