import React, { useCallback, useState } from 'react'

import RadioGroup from 'components/RadioGroup'
import RadioLabel from 'components/RadioLabel'
import { ICurrency, ISettings, Seller } from 'types/types'
import { CURRENCY, CURRENCY_SYMBOL } from 'consts/currency'
import defaultFieldsHelper from 'utils/defaultFieldsHelper'
import sellerSettingsDefaultFields from 'consts/defaultFields/baseSellerSettings'
import { buildSetSellerSettingsQuery, useSetSellerSettings } from 'graphql/mutations/setSellerSettings'
import useCmsParams from 'hooks/useCmsParams'
import useNotifyCms from 'hooks/useNotifyCms'

type ICurrenciesProps = {
  settings: ISettings
}

const SET_SELLER_SETTINGS_QUERY = buildSetSellerSettingsQuery(`
  id
  currency
`)

const Currencies: React.FC<ICurrenciesProps> = ({ settings }) => {
  const { currency: defaultCurrency } = defaultFieldsHelper(settings, sellerSettingsDefaultFields)

  const { sellerId } = useCmsParams()
  const addNotify = useNotifyCms()

  const [currency, setCurrency] = useState<ICurrency>(defaultCurrency)

  const [onSetSellerSettings] = useSetSellerSettings(SET_SELLER_SETTINGS_QUERY, {
    onCompleted: () => addNotify('success'),
    onError: () => addNotify('error'),
    update(cache, { data: resData }) {
      if (!resData?.setSellerSettings) throw new Error('Something went wrong')

      cache.modify({
        fields: {
          getSeller(seller: Seller) {
            return {
              ...seller,
              settings: {
                ...seller.settings,
                ...resData.setSellerSettings,
              },
            }
          },
        },
      })
    },
  })

  const onChange = useCallback(
    (_: any, newValue: ICurrency) => {
      setCurrency(newValue)
      onSetSellerSettings({
        variables: {
          seller_id: sellerId,
          input: { currency: newValue },
        },
      })
    },
    [onSetSellerSettings, sellerId],
  )

  return (
    <RadioGroup row value={currency} onChange={onChange}>
      <RadioLabel value={CURRENCY.RUB} label={`Рубли, ${CURRENCY_SYMBOL.RUB}`} />
      <RadioLabel value={CURRENCY.KZT} label={`Тенге, ${CURRENCY_SYMBOL.KZT}`} />
    </RadioGroup>
  )
}

export default Currencies
