import { gql } from '@apollo/client'

type IStatus = {
  status: boolean
  message?: string
}

export type ISetSellerExtDomainResponse = {
  setSellerExtDomain: IStatus
}

export type ISetSellerExtDomainParams = {
  id: IPrimaryKey
  ext_domain: string
}

export const SET_SELLER_EXT_DOMAIN = gql`
  mutation setSellerExtDomain($id: ID!, $ext_domain: String!) {
    setSellerExtDomain(id: $id, ext_domain: $ext_domain) {
      status
      message
    }
  }
`
