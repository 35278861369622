import React, { memo } from 'react'
import { Formik } from 'formik'
import noop from 'lodash/noop'

import LeavePageDirtyFormHelper from 'components/LeavePageDirtyFormHelper'
import AddSubmitShortcutFormHelper from 'components/AddSubmitShortcutFormHelper'
import FormControlsPanel from 'components/FormControlsPanel'
import Button from 'components/Button'
import { IFormikSubmit } from 'types/form'
import withErrorBoundary from 'hocs/withErrorBoundary'

import ClientsInput from './components/ClientsInput'
import Parameters from './components/Parameters'
import Settings from './components/Settings'
import PriceRuleFormProvider from './contexts/PriceRuleFormContext'
import { IPriceRuleFormValues } from './types'
import validationSchema from './utils/validationSchema'

import styles from './priceRuleForm.module.scss'

type IPriceRuleFormProps = {
  submitLoading: boolean
  initialValues: IPriceRuleFormValues
  onSubmit: IFormikSubmit<IPriceRuleFormValues>
  renderControls?: () => void
}

const PriceRuleForm: React.FC<IPriceRuleFormProps> = ({
  submitLoading,
  initialValues,
  onSubmit,
  renderControls = noop,
}) => (
  <Formik
    enableReinitialize
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    {({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <LeavePageDirtyFormHelper />
        <AddSubmitShortcutFormHelper />

        <Settings />

        <Parameters />

        <ClientsInput name="clientsIds" />

        <FormControlsPanel className={styles.contolsGroup}>
          <Button type="submit" className={styles.submitBtn} disabled={submitLoading}>
            Сохранить
          </Button>

          {renderControls()}
        </FormControlsPanel>
      </form>
    )}
  </Formik>
)

const ConnectedPriceRuleForm: React.FC<IPriceRuleFormProps> = (props) => (
  <PriceRuleFormProvider>
    <PriceRuleForm {...props} />
  </PriceRuleFormProvider>
)

export default withErrorBoundary(memo(ConnectedPriceRuleForm))
