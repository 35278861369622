import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import isEqual from 'lodash/isEqual'
import debounce from 'lodash/debounce'

import { IGetGroups, GET_GROUPS } from 'graphql/queries/getGroups'
import { IGetCategoriesParams, IGetCategories, GET_CATEGORIES } from 'graphql/queries/getCategories'
import { SEARCH_DEBOUNCE_TIMEOUT, SEARCH_INPUT_LIMIT } from 'consts'
import Button from 'components/Button'
import Preloader from 'components/Preloader'
import ResultTitle from 'components/ResultTitle'
import CheckboxLabel from 'components/CheckboxLabel'
import ResultSearchFilter from 'components/ResultSearchFilter'
import PageContainer from 'components/PageContainer'
import Link from 'components/Link'
import hasPagination from 'utils/hasPagination'
import convertToOption from 'utils/convertToOption'
import Pagination from 'components/Pagination'
import useCmsParams from 'hooks/useCmsParams'
import NoSearchResult from 'components/NoSearchResult'
import Select from 'components/Select'
import ApiError from 'components/ApiError'
import buildPaginateParams from 'utils/buildPaginateParams'

import useSearchParams from 'hooks/useSearchParams'
import CardCategory from './components/CardCategory'
import Empty from './components/Empty'

import styles from './categoriesList.module.scss'

type IPayload = {
  page: string
  search: string
  groupId: string | null
  isDisabled: boolean
}

const initPayload: IPayload = {
  search: '',
  page: '1',
  groupId: null,
  isDisabled: false,
}

const Categories: React.FC = () => {
  const { sellerId } = useCmsParams()

  const [searchParams, setSearchParams] = useSearchParams<IPayload>(initPayload)
  const { page, search, groupId, isDisabled } = searchParams

  const [searchInput, setSearchInput] = useState<string>(search)

  const onSearchDebounce = useMemo(
    () =>
      debounce((payload: string) => {
        setSearchParams({ ...searchParams, page: '1', search: payload })
      }, SEARCH_DEBOUNCE_TIMEOUT),
    [setSearchParams, searchParams],
  )

  const hasFilters = useMemo(() => !isEqual(initPayload, searchParams), [searchParams])

  const {
    data: categoriesData,
    loading: categoriesLoading,
    error: categoriesError,
    refetch,
  } = useQuery<IGetCategories, IGetCategoriesParams>(GET_CATEGORIES, {
    variables: {
      seller_id: sellerId,
      search,
      group_id: groupId ?? undefined,
      status: isDisabled ? false : null,
      paginate: buildPaginateParams(page),
    },
    fetchPolicy: 'network-only',
  })

  const { getCategories: { list: categories = [], elements = 0, pages = 0 } = {} } = categoriesData || {}

  const { data: groupsData, loading: groupsLoading } = useQuery<IGetGroups>(GET_GROUPS, {
    variables: { seller_id: sellerId },
    fetchPolicy: 'network-only',
  })

  const { getGroups: { list: groups = [] } = {} } = groupsData || {}

  const groupOptions = useMemo(() => groups.map(convertToOption), [groups])

  const onSearchChange = useCallback(
    (event: InputChangeEvent) => {
      const { value } = event.target
      if (value.length > SEARCH_INPUT_LIMIT) return
      setSearchInput(value)
      onSearchDebounce(value)
    },
    [onSearchDebounce],
  )

  const onSearchClear = useCallback(() => {
    setSearchParams((prevState) => ({ ...prevState, page: '1', search: '' }))
    setSearchInput('')
  }, [setSearchParams])

  const onPageChange = useCallback(
    (payload: number) => {
      setSearchParams((prevState) => ({ ...prevState, page: payload.toString() }))
    },
    [setSearchParams],
  )

  const onSortGroupChange = useCallback(
    (payload: IPrimaryKey) => {
      setSearchParams((prevState) => ({ ...prevState, page: '1', groupId: payload }))
    },
    [setSearchParams],
  )

  const onSortGroupClear = useCallback(() => {
    setSearchParams((prevState) => ({ ...prevState, page: '1', groupId: null }))
  }, [setSearchParams])

  const onTogleIsDisabled = useCallback(() => {
    setSearchParams((prevState) => ({ ...prevState, page: '1', isDisabled: !prevState.isDisabled }))
  }, [setSearchParams])

  useEffect(() => {
    // updates input then url changes
    setSearchInput(search)
  }, [search])

  const initialLoading = categoriesLoading && !hasFilters
  const isEmpty = !categoriesLoading && !hasFilters && !categories.length

  if (groupsLoading || initialLoading) return <Preloader />

  if (categoriesError) {
    return (
      <PageContainer>
        <ResultTitle>Категории товаров</ResultTitle>
        <ApiError error={categoriesError} />
      </PageContainer>
    )
  }

  if (isEmpty) {
    return (
      <PageContainer>
        <Empty />
      </PageContainer>
    )
  }

  return (
    <PageContainer>
      <ResultTitle>Категории товаров</ResultTitle>
      <div className={styles.filtersGroup}>
        <Link className={styles.createLink} href={`/${sellerId}/catalog/categories/create`}>
          <Button>Создать категорию</Button>
        </Link>
        <ResultSearchFilter
          value={searchInput}
          placeholder="Поиск по группе или категориям"
          onChange={onSearchChange}
          onClear={onSearchClear}
        />
        <Select
          className={styles.categoriesSelect}
          showOptionId
          placeholder="Выберите группу"
          value={groupId}
          options={groupOptions}
          onChange={onSortGroupChange}
          onClear={onSortGroupClear}
        />
        <CheckboxLabel label="Только неактивные" checked={isDisabled} onChange={onTogleIsDisabled} />
      </div>
      {categoriesLoading ? (
        <Preloader />
      ) : (
        <>
          {categories.map((category) => (
            <CardCategory key={category.id} category={category} groups={groups} refetchList={refetch} />
          ))}

          {hasPagination(elements) && (
            <Pagination page={Number(page)} count={pages} onChange={onPageChange} />
          )}

          {!categories.length && <NoSearchResult />}
        </>
      )}
    </PageContainer>
  )
}

export default Categories
