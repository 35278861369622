import React, { useCallback, useState } from 'react'
import clsx from 'clsx'

import { EditorState, ContentState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import htmlToDraft from 'html-to-draftjs'
import draftToHtml from 'draftjs-to-html'

import './react-draft-wysiwyg.css'
import styles from './Wysiwyg.module.scss'

export type IWysiwygProps = {
  text: string
  limit?: number
  setText: (value: string) => void
}

const editorLabels = {
  'generic.add': 'Добавить',
  'generic.cancel': 'Отменить',
  'components.controls.link.linkTitle': 'Текст ссылки',
  'components.controls.link.linkTarget': 'Ссылка',
  'components.controls.link.linkTargetOption': 'Открывать в новом окне',
  'components.controls.link.link': 'Link',
  'components.controls.link.unlink': 'Unlink',
}

const localization = {
  locale: 'en',
  translations: editorLabels,
}

const toolbar = {
  blockType: {
    inDropdown: true,
    options: ['Normal', 'H2', 'H3', 'H4', 'H5', 'H6'],
  },
  inline: {
    options: ['bold', 'underline', 'italic'],
  },
  list: {
    options: ['unordered', 'ordered'],
  },
  options: ['blockType', 'inline', 'remove', 'list', 'link'],
}

const Wysiwyg: React.FC<IWysiwygProps> = ({ text, setText, limit }) => {
  const [editorState, setEditorState] = useState(() => {
    const { contentBlocks, entityMap } = htmlToDraft(text)
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)

    return EditorState.createWithContent(contentState)
  })

  const handlePastedText = useCallback(
    (pastedText: string) => {
      const currentContent = editorState.getCurrentContent()
      const currentContentLength = currentContent.getPlainText('').length

      if (!limit) return false

      if (currentContentLength + pastedText.length > limit) {
        console.log(`you can type max ${limit} characters`)
        return true
      }

      return false
    },
    [editorState, limit],
  )

  const handleBeforeInput = useCallback(() => {
    const currentContent = editorState.getCurrentContent()
    const currentContentLength = currentContent.getPlainText('').length

    if (!limit) return undefined

    if (currentContentLength > limit - 1) {
      console.log(`you can type max ${limit} characters`)
      return 'handled'
    }

    return undefined
  }, [editorState, limit])

  const onEditorStateChange = useCallback(
    (payload: EditorState) => {
      setEditorState(payload)
      setText(draftToHtml(convertToRaw(payload.getCurrentContent())))
    },
    [setText],
  )

  const onBlur = useCallback(() => {
    const hasText = editorState.getCurrentContent().hasText()
    if (hasText) return
    setText('')
  }, [editorState, setText])

  return (
    <div className={styles.root}>
      {limit && (
        <span
          className={clsx(styles.counter, { [styles.max]: text.length === limit })}
        >{`${text.length}/${limit} символов`}</span>
      )}
      <Editor
        // @ts-ignore
        handleBeforeInput={handleBeforeInput}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        handlePastedText={handlePastedText}
        onBlur={onBlur}
        toolbarClassName={styles.toolbar}
        wrapperClassName={styles.wrapper}
        editorClassName={styles.editor}
        placeholder="Введите описание товара"
        localization={localization}
        toolbar={toolbar}
      />
    </div>
  )
}

export default Wysiwyg
