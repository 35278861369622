import { DocumentNode, gql } from '@apollo/client'

import { IPriceRule, IPriceRuleInput } from 'types/types'

export type ISetPriceRuleParams = {
  id: IPrimaryKey
  input: IPriceRuleInput
}

export type ISetPriceRule = {
  setPriceRule: IPriceRule
}

export const buildSetPriceRuleQuery = (fields: string): DocumentNode => gql`
  mutation setPriceRule($id: ID!, $input: PriceRuleInput) {
    setPriceRule(id: $id, input: $input) {
      ${fields}
    }
  }
`
