import { gql } from '@apollo/client'
import type { Seller } from 'types/types'

export type getSellarsType = { getSellers: Seller[] }

export const GET_SELLERS = gql`
  query getSellers {
    getSellers {
      id
      industry
      name
      domain
      logo
      email
      status
      company
      ext_domain
      ext_domain_status
    }
  }
`
