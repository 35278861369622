import React, { useCallback, useMemo, useState } from 'react'
import { useField } from 'formik'

import { buildGetClientsQuery, useGetClientsQuery } from 'graphql/queries/getClients'
import useCmsParams from 'hooks/useCmsParams'
import Button from 'components/Button'
import UiLayout from 'components/ui-kit/UiLayout'
import CustomMultiSelect from 'components/ui-kit/form/CustomMultiSelect'
import { IClient } from 'types/types'
import PriceRuleClient from 'components/PriceRuleClient/PriceRuleClient'
import extractId from 'utils/extractId'
import Preloader from 'components/Preloader'
import ErrorText from 'components/ErrorText'
import convertClientsToHash from 'utils/convertClientsToHash'
import { maxPaginate } from 'consts'

import styles from './clientsInput.module.scss'

type IClientsMenuInputProps = {
  name: string
}

const GET_CLIENTS = buildGetClientsQuery(`
  id
  name
`)

const transformClientToOption = ({ id, name = 'EMPTY_NAME' }: IClient): IOption => ({ id, name })

const filterByIds =
  (ids: IPrimaryKey[]) =>
  ({ id }: { id: IPrimaryKey }) =>
    !ids.includes(id)

const sortPriceRuleClients = (a: IPrimaryKey, b: IPrimaryKey) => (Number(a) < Number(b) ? 1 : -1)

const ClientsMenuInput: React.FC<IClientsMenuInputProps> = ({ name }) => {
  const { sellerId } = useCmsParams()

  const [{ value: clientsIds, onChange }] = useField<IPrimaryKey[]>(name)
  const [selectedClients, setSelectedClients] = useState<IOption[]>([])

  const {
    data: clientsData,
    loading,
    error,
  } = useGetClientsQuery(GET_CLIENTS, {
    variables: { seller_id: sellerId, paginate: maxPaginate },
    fetchPolicy: 'network-only',
  })

  const allClientsHash = useMemo(() => {
    if (!clientsData) return {}
    return convertClientsToHash(clientsData.getClients.list)
  }, [clientsData])

  const availableClientsOptions = useMemo(() => {
    if (!clientsData) return []
    return clientsData.getClients.list.filter(filterByIds(clientsIds)).map(transformClientToOption)
  }, [clientsData, clientsIds])

  const onClientsChange = useCallback((payload: IOption[]) => {
    setSelectedClients(payload)
  }, [])

  const onAddClientsClick = useCallback(() => {
    const nextEvent = {
      target: {
        name,
        value: clientsIds.concat(selectedClients.map(extractId)),
      },
    }

    onChange(nextEvent)
    setSelectedClients([])
  }, [onChange, name, clientsIds, selectedClients])

  const removeClient = useCallback(
    (removeId: IPrimaryKey) => {
      const nextEvent = {
        target: {
          name,
          value: clientsIds.filter((record) => removeId !== record),
        },
      }

      onChange(nextEvent)
    },
    [onChange, name, clientsIds],
  )

  if (loading) {
    return (
      <div className={styles.loaderWrapper}>
        <Preloader variant="relative" />
      </div>
    )
  }

  if (error) {
    return <ErrorText>{error.message}</ErrorText>
  }

  return (
    <>
      <div className={styles.addClientsGroup}>
        <UiLayout spanText="Контрагенты">
          <CustomMultiSelect
            options={availableClientsOptions}
            selectedOptions={selectedClients}
            setSelectedOption={onClientsChange}
          />
        </UiLayout>

        <Button
          className={styles.addClientsButton}
          disabled={!selectedClients.length}
          onClick={onAddClientsClick}
        >
          Добавить
        </Button>
      </div>

      {clientsIds.length ? (
        <div className={styles.selectedClientsList}>
          {clientsIds.sort(sortPriceRuleClients).map((activeClientId) => {
            const client = allClientsHash[activeClientId]
            if (!client) return null
            return (
              <PriceRuleClient
                key={client.id}
                client={client}
                sellerId={sellerId}
                onDelete={() => removeClient(activeClientId)}
              />
            )
          })}
        </div>
      ) : null}
    </>
  )
}

export default ClientsMenuInput
