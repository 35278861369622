import React, { useMemo, useCallback, memo } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import { IOrderStatus } from 'types/types'
import { buildGetSellerQuery, useGetSellerQuery } from 'graphql/queries/getSeller'
import { buildSetOrderStatusQuery, useSetOrderStatusMutation } from 'graphql/mutations/setOrderStatus'
import useCmsParams from 'hooks/useCmsParams'
import useBoolean from 'hooks/useBoolean'
import ApiError from 'components/ApiError'
import Text from 'components/Typography'
import Link from 'components/Link'
import Button from 'components/Button'
import ArrowDownSmallIcon from 'components/Icons/ArrowDownSmallIcon'
import withErrorBoundary from 'hocs/withErrorBoundary'
import { DEFAULT_ORDER_STATUS_COLOR } from 'consts/defaultValues'

import styles from './orderStatus.module.scss'

type IOrderStatusProps = {
  status: IOrderStatus
  refetch: () => void
}

const GET_SELLER = buildGetSellerQuery(`
  id
  settings {
    order_statuses {
      id
      title
      color
    }
  }
`)

const SET_ORDER_STATUS = buildSetOrderStatusQuery(`
  id
  title
  color
`)

const OrderStatus: React.FC<IOrderStatusProps> = ({ status, refetch }) => {
  const { title, color } = status

  const { sellerId, orderId } = useCmsParams()

  const [isOpen, setIsOpen] = useBoolean(false)

  const { data, error } = useGetSellerQuery(GET_SELLER, {
    variables: { id: sellerId },
    fetchPolicy: 'network-only',
  })

  const [onSetOrderStatus] = useSetOrderStatusMutation(SET_ORDER_STATUS, {
    onCompleted: refetch,
  })

  const options = useMemo(() => data?.getSeller?.settings?.order_statuses || [], [data])

  const onOptionClick = useCallback(
    (option: IOrderStatus) => {
      onSetOrderStatus({
        variables: { id: orderId, order_status_id: option.id },
      })

      setIsOpen.off()
    },
    [setIsOpen, onSetOrderStatus, orderId],
  )

  if (error) return <ApiError error={error} />

  return (
    <ClickAwayListener onClickAway={setIsOpen.off}>
      <div className={styles.container}>
        <Button
          className={styles.button}
          style={{ backgroundColor: color || DEFAULT_ORDER_STATUS_COLOR }}
          onClick={setIsOpen.toggle}
        >
          {title ?? 'Статус заказа'}
          <ArrowDownSmallIcon className={styles.arrowIcon} />
        </Button>

        {isOpen ? (
          <div className={styles.optionsList}>
            {options.length ? (
              <>
                {options.map((option) => (
                  <List key={option.id} onClick={() => onOptionClick(option)}>
                    <ListItem button>
                      <Text>{option.title}</Text>
                    </ListItem>
                  </List>
                ))}
              </>
            ) : (
              <Text className={styles.noStatusesGroup}>
                Нет доступных статусов,
                <Link href={`/${sellerId}/more`}>добавить статусы</Link>
              </Text>
            )}
          </div>
        ) : null}
      </div>
    </ClickAwayListener>
  )
}

export default withErrorBoundary(memo(OrderStatus))
