import React from 'react'

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const TimeIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M12.8791 7.6769C12.8791 7.12461 12.4315 6.67683 11.8792 6.67676C11.3269 6.67669 10.8791 7.12434 10.8791 7.67662L10.8784 12.181C10.8784 12.4337 10.974 12.677 11.1461 12.8621L13.7668 15.6809C14.1429 16.0854 14.7756 16.1084 15.1801 15.7324C15.5846 15.3563 15.6076 14.7236 15.2316 14.3191L12.8785 11.7882L12.8791 7.6769Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
    />
  </SvgIcon>
)

export default TimeIcon
