import differenceBy from 'lodash/differenceBy'

const checkDestroyItems = <T extends { id?: string }>(
  initialValues: T[],
  currentValues: T[],
): Array<T & { _delete?: boolean }> => {
  if (!initialValues.length) return currentValues

  const recordsToDestroy = differenceBy(initialValues, currentValues, 'id').map((record) => ({
    ...record,
    _delete: true,
  }))

  return currentValues.concat(recordsToDestroy)
}

export default checkDestroyItems
