import { buildAdd1CAccessQuery } from 'graphql/mutations/add1CAccess'
import { buildSet1CAccessQuery } from 'graphql/mutations/set1CAccess'
import { buildGet1CAccessesQuery } from 'graphql/queries/get1CAccesses'

const commonFields = `
  id
  login
  password
  status
  mode
  status_products
`
export const GET_1C_ACCESSES_FIELDS = buildGet1CAccessesQuery(commonFields)
export const ADD_1C_ACCESSES_FIELDS = buildAdd1CAccessQuery(commonFields)
export const SET_1C_ACCESSES_FIELDS = buildSet1CAccessQuery(commonFields)
