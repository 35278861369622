import React, { ReactNode } from 'react'
import { Formik, FormikHelpers, useField } from 'formik'

import DropMenu from 'components/DropMenu'
import FormControlsPanel from 'components/FormControlsPanel'
import Button from 'components/Button'
import Text from 'components/Typography'
import LeavePageDirtyFormHelper from 'components/LeavePageDirtyFormHelper'
import AddSubmitShortcutFormHelper from 'components/AddSubmitShortcutFormHelper'
import BooleanLikeInput from 'components/BooleanLikeInput'
import LimitText from 'components/LimitText'
import TextInput from 'components/Inputs/TextInput'
import SwitchInput from 'components/Inputs/SwitchInput'
import UiLayout from 'components/ui-kit/UiLayout'
import InputColor from 'pages/More/components/InputColor'
import formikFieldEvent from 'utils/formInputs/formikFieldEvent'

import { IBannerFormValues } from './types'
import validationSchema from './utils/validationSchema'
import ImageGroup from './components/ImageGroup'
import MobileDnDInput from './components/MobileDnDInput'
import DesktopDnDInput from './components/DesktopDnDInput'
import {
  desktopDescription,
  mobileDescription,
  extendedViewDesktopDescription,
  extendedViewMobileDescription,
} from './consts'

import styles from './BannerForm.module.scss'

type IBannerFormProps = {
  isExtendedHomeView: boolean
  submitLoading: boolean
  title: string
  initialValue: IBannerFormValues
  onSubmit: (values: IBannerFormValues, helpers: FormikHelpers<IBannerFormValues>) => void
  renderControls?: () => ReactNode
}

const ColorInput = ({ name }: { name: string }) => {
  const [{ value, onChange }] = useField(name)

  const onColorChange = (color: string) => {
    onChange(formikFieldEvent(name, color))
  }

  return (
    <UiLayout spanText="Цвет текста" layoutClassName={styles.colorGroup}>
      <InputColor color={value} setColor={onColorChange} />
    </UiLayout>
  )
}

const BannerForm: React.FC<IBannerFormProps> = ({
  isExtendedHomeView,
  submitLoading,
  title,
  initialValue,
  onSubmit,
  renderControls = () => {},
}) => (
  <Formik
    enableReinitialize
    initialValues={initialValue}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    {({ values, handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <DropMenu title={title}>
          <LeavePageDirtyFormHelper />
          <AddSubmitShortcutFormHelper />

          <div className={styles.rowSecondary}>
            {isExtendedHomeView ? (
              <TextInput
                name="name"
                required
                label="Название"
                placeholder="Введите заголовок для баннера"
                margin="default"
                inputProps={{ maxLength: 30 }}
                labelAsideRight={<LimitText limit={30} value={values.name} />}
              />
            ) : (
              <TextInput
                name="name"
                required
                label="Название"
                placeholder="Введите название баннера"
                margin="default"
              />
            )}

            <BooleanLikeInput title="Активность" className={styles.status}>
              <SwitchInput
                name="status"
                placement="right"
                gap="medium"
                label={<Text>Отображать в магазине</Text>}
              />
            </BooleanLikeInput>
          </div>

          <SwitchInput
            className={styles.showTitle}
            name="show_title"
            placement="right"
            gap="medium"
            label={<Text>Отображать заголовок</Text>}
          />

          {isExtendedHomeView ? (
            <>
              <div className={styles.rowPrimary}>
                <TextInput
                  name="text"
                  label="Описание"
                  multiline
                  minRows={12}
                  maxRows={12}
                  placeholder="Введите описание для баннера"
                  margin="default"
                  inputProps={{ maxLength: 420 }}
                  labelAsideRight={<LimitText limit={420} value={values.text} />}
                />
              </div>

              <div className={styles.rowSecondary}>
                <TextInput
                  name="button"
                  label="Текст для кнопки"
                  placeholder="Введите текст для кнопки баннера"
                  margin="default"
                  inputProps={{ maxLength: 30 }}
                  labelAsideRight={<LimitText limit={30} value={values.button} />}
                />
                <ColorInput name="tx_color" />
              </div>
            </>
          ) : null}

          <div className={styles.rowPrimary}>
            <TextInput
              name="url"
              required
              label="Ссылка на страницу"
              placeholder="Вставьте ссылку"
              margin="default"
            />
          </div>

          <ImageGroup
            title="Для десктопа"
            description={isExtendedHomeView ? extendedViewDesktopDescription : desktopDescription}
          >
            <DesktopDnDInput isExtendedHomeView={isExtendedHomeView} />
          </ImageGroup>

          <ImageGroup
            title="Для мобильной версии"
            description={isExtendedHomeView ? extendedViewMobileDescription : mobileDescription}
          >
            <MobileDnDInput isExtendedHomeView={isExtendedHomeView} />
          </ImageGroup>
        </DropMenu>
        <FormControlsPanel className={styles.controlsPanel}>
          <Button type="submit" disabled={submitLoading}>
            Сохранить
          </Button>

          {renderControls()}
        </FormControlsPanel>
      </form>
    )}
  </Formik>
)

export default BannerForm
