import React from 'react'
import clsx from 'clsx'

import styles from './container.module.scss'

type IContainerProps = {
  className?: string
}

const Container: React.FC<IContainerProps> = ({ children, className }) => (
  <div className={clsx(styles.container, className)}>{children}</div>
)

export default Container
