import React, { useCallback, useMemo, useState } from 'react'
import { useMutation } from '@apollo/client'
import { FormikHelpers } from 'formik'

import { userEvents } from 'utils/gtmHelper'
import ResultTitle from 'components/ResultTitle'
import Button from 'components/Button'
import SuccessDialog from 'components/Dialogs/SuccessDialog'
import Preloader from 'components/Preloader'
import { GET_USER, useGetUserQuery } from 'graphql/queries/getUser'
import Layout from 'components/Layout'
import { ISetUser, ISetUserParams, SET_USER_INFO } from 'graphql/mutations/setUser'
import { SEND_EMAIL_VERIFY } from 'graphql/mutations/sendEmailVerify'
import useAddNotify from 'hooks/useAddNotify'
import LockIcon from 'components/Icons/LockIcon'
import { IUser } from 'types/types'
import ProfileForm, { IProfileFormValues } from 'components/forms/ProfileForm'
import convertToFormErrors from 'utils/convertToFormErrors'

import PasswordFormDialog from './components/ProfileForm'

import styles from './Profile.module.scss'

const defaultValues: IProfileFormValues = {
  email: '',
  email_verified_at: null,
  name: '',
  second_name: '',
  phone: '',
}

const prepareSubmitValues = ({ email, name, phone, second_name }: IProfileFormValues): ISetUserParams => ({
  email,
  name,
  phone,
  second_name,
})

const prepareInitialValues = (user: IUser): IProfileFormValues => {
  const { name, second_name, phone, email, email_verified_at = null } = user
  return {
    name,
    second_name: second_name || '',
    phone: phone || '',
    email: email || '',
    // NOTE: any, в схеме Scalars['DateTime'] равен строке, поэтому из за Date получаю ошибку
    email_verified_at: email_verified_at as any,
  }
}

const Profile: React.FC = () => {
  const addNotify = useAddNotify()

  const [modalState, setModalState] = useState<boolean>(false)
  const [isSubmitNotification, setIsSubmitNotification] = useState<boolean>(false)

  const [setUserInfo, { loading: submitLoading }] = useMutation<ISetUser, ISetUserParams>(SET_USER_INFO)

  const [onSendEmailVerify, { loading: verifyLoading }] = useMutation(SEND_EMAIL_VERIFY)

  const { data: userData, loading } = useGetUserQuery(GET_USER)

  const initialValues = useMemo(() => {
    if (!userData) return defaultValues
    return prepareInitialValues(userData.getUser)
  }, [userData])

  const toggleModal = () => setModalState((prev) => !prev)

  const changePasswordSubmit = () => {
    setModalState(false)
    setIsSubmitNotification(true)
  }

  const onSubmit = useCallback(
    (values: IProfileFormValues, { setErrors, resetForm }: FormikHelpers<IProfileFormValues>) =>
      setUserInfo({
        variables: prepareSubmitValues(values),
      })
        .then((response) => {
          const nextData = response.data?.setUser
          if (!nextData) throw new Error('No setUser data')
          userEvents('change_profile')
          addNotify('success')
          resetForm({ values: prepareInitialValues(nextData) })
        })
        .catch((error) => {
          addNotify('error')
          const errors = convertToFormErrors(error)
          if (errors) setErrors(errors)
        }),
    [setUserInfo, addNotify],
  )

  if (loading || verifyLoading) return <Preloader />

  return (
    <Layout>
      <div className={styles.container}>
        <ResultTitle>Профиль</ResultTitle>

        <ProfileForm
          initialValue={initialValues}
          submitLoading={submitLoading}
          onSubmit={onSubmit}
          onResendVerify={onSendEmailVerify}
          renderControls={() => (
            <Button variant="outlined" startIcon={<LockIcon />} onClick={toggleModal}>
              Изменить пароль
            </Button>
          )}
        />
      </div>

      <PasswordFormDialog
        isOpen={modalState}
        onClose={toggleModal}
        changePasswordSubmit={changePasswordSubmit}
      />

      <SuccessDialog
        isOpen={isSubmitNotification}
        text="Пароль изменен"
        onClose={() => setIsSubmitNotification(false)}
      />
    </Layout>
  )
}

export default Profile
