import React, { useCallback } from 'react'
import { useField } from 'formik'

import Link from 'components/Link'
import DropMenu from 'components/DropMenu'
import TextInput from 'components/Inputs/TextInput'
import IconButton from 'components/IconButton'
import PlusIcon from 'components/Icons/PlusIcon'
import TrashIcon from 'components/Icons/TrashIcon'
import { IBaseProductFormValues } from 'components/forms/BaseProductForm/types'
import formikFieldEvent from 'utils/formInputs/formikFieldEvent'

import styles from './Documents.module.scss'

const name = 'links'

const Documents: React.FC = () => {
  const [{ value: links, onChange: setLinks }] = useField<IBaseProductFormValues['links']>(name)

  const onAddEmptyLink = useCallback(() => {
    const emptyLink = { name: '', url: '' }
    setLinks(formikFieldEvent(name, links.concat(emptyLink)))
  }, [setLinks, links])

  const onDeleteLink = useCallback(
    (removeIndex: number) => {
      const newLinks = links.filter((_, index) => index !== removeIndex)
      setLinks(formikFieldEvent(name, newLinks))
    },
    [setLinks, links],
  )

  return (
    <DropMenu initOpen={false} title="Документы">
      {links.map((_, index) => (
        <div key={index} className={styles.document}>
          <TextInput
            name={`${name}.[${index}].name`}
            required
            placeholder="Введите"
            label="Название документа"
          />
          <TextInput
            name={`${name}.[${index}].url`}
            required
            placeholder="Вставьте ссылку"
            label="Ссылка на документ"
          />
          <IconButton className={styles.deleteIcon} onClick={() => onDeleteLink(index)}>
            <TrashIcon color="action" />
          </IconButton>
        </div>
      ))}

      <Link component="button" onClick={onAddEmptyLink} startIcon={<PlusIcon />}>
        Добавить новый документ
      </Link>
    </DropMenu>
  )
}

export default Documents
