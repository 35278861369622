import React, { memo, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { FormikHelpers } from 'formik'

import GoBackLink from 'components/GoBackLink'
import PageContainer from 'components/PageContainer'
import ResultTitle from 'components/ResultTitle'
import useCmsParams from 'hooks/useCmsParams'
import useNotifyCms from 'hooks/useNotifyCms'
import {
  IAddBaseProduct,
  IAddBaseProductParams,
  buildAddBaseProductQuery,
} from 'graphql/mutations/addBaseProduct'
import withErrorBoundary from 'hocs/withErrorBoundary'
import withLeaveThisPageModal from 'hocs/withLeaveThisPageModal'
import BaseProductForm, { IBaseProductFormValues } from 'components/forms/BaseProductForm'
import createUrlFromUploadedFile, { IUploadedData } from 'helpers/createUrlFromUploadedFile'
import convertToFormErrors from 'utils/convertToFormErrors'
import { IStore, Image } from 'types/types'
import { useGetStoresQuery, buildGetStoresQuery, IGetStores } from 'graphql/queries/getStores'
import { maxPaginate } from 'consts'

import defaultValues from './consts/defaultValues'
import prepareSubmitValues from './utils/prepareSubmitValues'

const keepFile = (value: Image | File): value is File => value instanceof File

const GET_STORES = buildGetStoresQuery(`
  id
  name
`)

const ADD_BASE_PRODUCT = buildAddBaseProductQuery(`id`)

const prepareStores = (stores: IStore[]): IBaseProductFormValues['stores'] =>
  stores.map((record) => ({ ...record, amount: '', amount_wait: '', multiply: '1' }))

const prepareInitialValues = (payload: IGetStores): IBaseProductFormValues => {
  const stores = payload.getStores.list
  return {
    ...defaultValues,
    stores: prepareStores(stores),
  }
}

const BaseProductCreate: React.FC = () => {
  const { sellerId } = useCmsParams()
  const addNotify = useNotifyCms()
  const { replace } = useHistory()

  const { data: storesData } = useGetStoresQuery(GET_STORES, {
    variables: {
      seller_id: sellerId,
      paginate: maxPaginate,
    },
    fetchPolicy: 'network-only',
  })

  const initialValues = useMemo(() => {
    if (!storesData) return defaultValues
    return prepareInitialValues(storesData)
  }, [storesData])

  const [onAddProduct, { loading }] = useMutation<IAddBaseProduct, IAddBaseProductParams>(ADD_BASE_PRODUCT)

  const onSubmit = useCallback(
    async (values: IBaseProductFormValues, { setErrors }: FormikHelpers<IBaseProductFormValues>) => {
      const { images, ...restValues } = values

      // TODO: onCreate there might be only files
      const imagesToLoad = images.filter(keepFile)

      let uploadedImages: IUploadedData[] = []

      if (imagesToLoad.length > 0) {
        try {
          uploadedImages = await createUrlFromUploadedFile(imagesToLoad, { type: 'images' })
        } catch (error) {
          addNotify({ kind: 'error', message: 'Ошибка сохранения изображений' })
        }
      }

      await onAddProduct({
        variables: {
          seller_id: sellerId,
          input: prepareSubmitValues({ ...restValues, uploadedImages }),
        },
      })
        .then(({ data }) => {
          addNotify('success')
          if (data) replace(`/${sellerId}/catalog/products/${data.addBaseProduct.id}`)
        })
        .catch((error) => {
          addNotify('error')
          const errors = convertToFormErrors(error)
          if (errors) setErrors(errors)
        })
    },
    [onAddProduct, addNotify, replace, sellerId],
  )

  return (
    <PageContainer>
      <GoBackLink href={`/${sellerId}/catalog/products`} />
      <ResultTitle>Создание товара</ResultTitle>
      <BaseProductForm initialValues={initialValues} submitLoading={loading} onSubmit={onSubmit} />
    </PageContainer>
  )
}

export default withErrorBoundary(withLeaveThisPageModal(memo(BaseProductCreate)))
