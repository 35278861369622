import React, { useMemo, useState, useCallback, memo } from 'react'
import debounce from 'lodash/debounce'

import Button from 'components/Button'
import ResultSearchFilter from 'components/ResultSearchFilter'
import CreateStoreDialog from 'components/Dialogs/CreateStoreDialog'
import useBoolean from 'hooks/useBoolean'
import { SEARCH_DEBOUNCE_TIMEOUT } from 'consts'

import { ISearchParams } from 'views/BaseProducts/StoreList/types'

import DistributeStores from './components/DistributeStores'

import styles from './filters.module.scss'

type IFiltersProps = {
  searchParams: ISearchParams
  setSearchParams: (params: ISearchParams | ((prevState: ISearchParams) => ISearchParams)) => void
  refetchList: () => void
}

const Filters: React.FC<IFiltersProps> = ({ searchParams, setSearchParams, refetchList }) => {
  const { search } = searchParams

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useBoolean()
  const [searchInput, setSearchInput] = useState<string>(search)

  const onSearchDebounce = useMemo(
    () =>
      debounce((payload: string) => {
        setSearchParams({ ...searchParams, page: '1', search: payload })
      }, SEARCH_DEBOUNCE_TIMEOUT),
    [setSearchParams, searchParams],
  )

  const onSearchChange = useCallback(
    (event: InputChangeEvent) => {
      const { value } = event.target
      setSearchInput(value)
      onSearchDebounce(value)
    },
    [onSearchDebounce],
  )

  const onSearchClear = useCallback(() => {
    setSearchInput('')
    setSearchParams((prev) => ({ ...prev, page: '1', search: '' }))
  }, [setSearchParams])

  const onCreateSuccess = useCallback(() => {
    setIsCreateDialogOpen.off()
    refetchList()
  }, [setIsCreateDialogOpen, refetchList])

  return (
    <div className={styles.container}>
      <Button onClick={setIsCreateDialogOpen.on}>Создать склад</Button>

      <ResultSearchFilter
        value={searchInput}
        placeholder="Поиск по складам"
        onChange={onSearchChange}
        onClear={onSearchClear}
      />

      <DistributeStores />

      <CreateStoreDialog
        isOpen={isCreateDialogOpen}
        onClose={setIsCreateDialogOpen.off}
        onSuccess={onCreateSuccess}
      />
    </div>
  )
}

export default memo(Filters)
