import React from 'react'

import { IDeliveryAddress } from 'types/types'
import Text from 'components/Typography'

import styles from './DeliveryAddresses.module.scss'

type IDeliveryAddressesProps = {
  deliveryAddresses: IDeliveryAddress[]
}

const DeliveryAddresses: React.FC<IDeliveryAddressesProps> = ({ deliveryAddresses }) => (
  <div className={styles.container}>
    {deliveryAddresses.map(({ id, address }, index) => (
      <div key={id} className={styles.item}>
        <Text className={styles.title} color="textSecondary">{`Адрес ${index + 1}`}</Text>
        <Text>{address}</Text>
      </div>
    ))}
  </div>
)

export default DeliveryAddresses
