import { gql } from '@apollo/client'

import { Status } from 'types/types'

export type ICopyCategory = {
  copyCategory: Status
}

export type ICopyCategoryParams = {
  id: IPrimaryKey
}

export const COPY_CATEGORY = gql`
  mutation CopyCategory($id: ID!) {
    copyCategory(id: $id) {
      status
      message
    }
  }
`
