import { DocumentNode, gql, useQuery, QueryHookOptions } from '@apollo/client'

import { IManager } from 'types/types'

export type IGetManager = {
  getManager: IManager
}

export type IGetManagerParams = {
  id: IPrimaryKey
}

export const buildGetManagerQuery = (fields: string): DocumentNode => gql`
  query getManager($id: ID!) {
    getManager(id: $id) {
      ${fields}
    }
  }
`

export const useGetManagerQuery = <TData = IGetManager, TOptions = IGetManagerParams>(
  fields: DocumentNode,
  options?: QueryHookOptions<TData, TOptions>,
) => useQuery<TData, TOptions>(fields, options)
