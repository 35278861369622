import React from 'react'
import clsx from 'clsx'

import pluralize from 'utils/pluralize'
import ArrowSmall from 'components/Icons/ArrowDownSmallIcon'

import styles from './toggleVariantsButton.module.scss'

interface IToggleVariantsButtonProps extends ButtonElement {
  isOpen: boolean
  counter: number
}

const ToggleVariantsButton: React.FC<IToggleVariantsButtonProps> = ({
  isOpen,
  counter,
  onClick,
  children,
  ...props
}) => {
  const handleClick = (event: ButtonClickEvent) => {
    event.preventDefault()
    if (onClick) {
      onClick(event)
    }
  }

  return (
    <button className={styles.button} type="button" onClick={handleClick} {...props}>
      {`${counter} ${pluralize(counter, ['вариант', 'варианта', 'вариантов'])}`}
      <ArrowSmall className={clsx({ [styles.open]: isOpen })} />
    </button>
  )
}

export default ToggleVariantsButton
