import { buildGetManagerQuery } from 'graphql/queries/getManager'
import { buildSetManagerQuery } from 'graphql/mutations/setManager'

const commonFields = `
  id
  name
  phone
  email
  to_new_clients

  clients {
    id
  }
`

export const GET_MANAGER = buildGetManagerQuery(`
  ${commonFields}
`)

export const SET_MANAGER = buildSetManagerQuery(`
  ${commonFields}
`)
