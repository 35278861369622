import { buildSetBannerQuery } from 'graphql/mutations/setBanner'
import { buildGetBannerQuery } from 'graphql/queries/getBanner'

const commonFields = `
  id
  name
  status
  url
  text
  button
  tx_color
  show_title
  desktop_image {
    id
    path 
  }
  mobile_image {
    id
    path 
  }
`

export const GET_BANNER = buildGetBannerQuery(commonFields)
export const ADD_BANNER = buildSetBannerQuery(commonFields)
