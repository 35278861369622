import React, { memo } from 'react'
import { ApolloError } from '@apollo/client'

import DropMenu from 'components/DropMenu'
import ApiError from 'components/ApiError'
import Preloader from 'components/Preloader'
import { Attribute as IAttribute } from 'types/types'

import Attribute from '../Attribute'

import styles from './attributes.module.scss'

type IAttributesProps = {
  name: string
  attributesLoading: boolean
  attributesError: ApolloError | undefined
  attributesList: IAttribute[]
  attributesHash: Record<string, IAttribute>
}

const Attributes: React.FC<IAttributesProps> = ({
  name,
  attributesError,
  attributesLoading,
  attributesList,
  attributesHash,
}) => {
  if (attributesError) {
    return (
      <DropMenu title="Атрибуты">
        <ApiError error={attributesError} />
      </DropMenu>
    )
  }

  return (
    <DropMenu title="Атрибуты">
      {attributesLoading ? (
        <div className={styles.loaderGroup}>
          <Preloader variant="relative" />
        </div>
      ) : (
        <Attribute name={`${name}`} attributesList={attributesList} attributesHash={attributesHash} />
      )}
    </DropMenu>
  )
}

export default memo(Attributes)
