import { DocumentNode, MutationHookOptions, gql, useMutation } from '@apollo/client'

import { IExchange1CAccess, IExchange1CAccessInput } from 'types/types'

export type ISet1CAccess = {
  set1CAccess: IExchange1CAccess
}

export type ISet1CAccessParams = {
  id: IPrimaryKey
  input?: IExchange1CAccessInput
}

export const buildSet1CAccessQuery = (fields: string): DocumentNode => gql`
  mutation set1CAccess($id: ID!, $input: Exchange1CAccessInput) {
    set1CAccess(id: $id, input: $input) {
      ${fields}
    }
  }
`

export const useSet1CAccessMutation = <TData = ISet1CAccess, TOptions = ISet1CAccessParams>(
  fields: DocumentNode,
  options?: MutationHookOptions<TData, TOptions>,
) => useMutation<TData, TOptions>(fields, options)
