import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'

import { useLeavePage } from 'hocs/withLeaveThisPageModal'

const LeavePageDirtyFormHelper: React.FC = () => {
  const { unblocking, blocking } = useLeavePage()
  const { dirty, isSubmitting } = useFormikContext()

  useEffect(() => {
    if (dirty && !isSubmitting) {
      blocking()
    } else {
      unblocking()
    }
  }, [dirty, isSubmitting])

  return null
}

export default LeavePageDirtyFormHelper
