import React, { useState, useCallback, useMemo, memo } from 'react'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'

import { useGetBaseProductsQuery } from 'graphql/queries/getBaseProducts'
import { SEARCH_INPUT_LIMIT, SEARCH_DEBOUNCE_TIMEOUT } from 'consts'
import Text, { H3 } from 'components/Typography'
import Button from 'components/Button'
import Preloader from 'components/Preloader'
import ResultTitle from 'components/ResultTitle'
import ResultSearchFilter from 'components/ResultSearchFilter'
import PageContainer from 'components/PageContainer'
import ApiError from 'components/ApiError'
import Link from 'components/Link'
import ImportModal from 'components/ImportModal'
import hasPagination from 'utils/hasPagination'
import useChoises from 'hooks/useChoises'
import useCmsParams from 'hooks/useCmsParams'
import useBoolean from 'hooks/useBoolean'
import withErrorBoundary from 'hocs/withErrorBoundary'
import Pagination from 'components/Pagination'
import NoSearchResult from 'components/NoSearchResult'
import useSearchParams from 'hooks/useSearchParams'
import buildPaginateParams from 'utils/buildPaginateParams'

import Product from './components/Product'
import MultiActions from './components/MultiActions'
import ExportCatalog from './components/ExportCatalog'
import { GET_BASE_PRODUCTS } from './queries'

import styles from './baseProductsList.module.scss'

type IPayload = {
  search: string
  page: string
}

const initPayload: IPayload = {
  search: '',
  page: '1',
}

const NoProducts = () => (
  <>
    <H3 className={styles.noResultTitle}>У вас пока нет товаров</H3>
    <Text>Для загрузки товаров нажмите «импорт товаров» или создайте товар вручную.</Text>
  </>
)

const BaseProductsList: React.FC = () => {
  const { sellerId } = useCmsParams()

  const [searchParams, setSearchParams] = useSearchParams<IPayload>(initPayload)
  const [isImportModalOpen, setIsImportModalOpen] = useBoolean()
  const [selectedProductsIds, { toggle, isSelected, removeAll, replace }] = useChoises<string>()
  const [searchInput, setSearchInput] = useState<string>(searchParams.search ?? initPayload.search)

  const { page, search } = searchParams

  const hasFilters = useMemo(() => !isEqual(searchParams, initPayload), [searchParams])

  const onSearchDebounce = useMemo(
    () =>
      debounce((payload: string) => {
        setSearchParams({ ...searchParams, page: '1', search: payload })
      }, SEARCH_DEBOUNCE_TIMEOUT),
    [setSearchParams, searchParams],
  )

  const { data, loading, error } = useGetBaseProductsQuery(GET_BASE_PRODUCTS, {
    variables: {
      search,
      seller_id: sellerId,
      paginate: buildPaginateParams(page),
    },
    fetchPolicy: 'network-only',
  })

  const { getBaseProducts: { elements = 0, pages = 0 } = {} } = data || {}
  const baseProducts = useMemo(() => data?.getBaseProducts.list || [], [data])

  const allIds = useMemo(
    () => baseProducts.map(({ products }) => products[0].base_product_id),
    [baseProducts],
  )

  const isAllSelected = selectedProductsIds.length === allIds.length

  const onSearchClear = useCallback(() => {
    removeAll()
    setSearchInput('')
    setSearchParams((prev) => ({ ...prev, page: '1', search: '' }))
  }, [setSearchParams, removeAll])

  const onSearchChange = useCallback(
    (event: InputChangeEvent) => {
      const { value } = event.target
      if (value.length > SEARCH_INPUT_LIMIT) return
      setSearchInput(value)
      onSearchDebounce(value)
      removeAll()
    },
    [removeAll, onSearchDebounce],
  )

  const onPageChange = useCallback(
    (nextPage: number) => {
      setSearchParams((prev) => ({ ...prev, page: nextPage.toString() }))
      removeAll()
    },
    [setSearchParams, removeAll],
  )

  const onSelectAllToggle = useCallback(
    (_: InputChangeEvent, newChecked: boolean) => {
      if (newChecked) {
        replace(allIds)
      } else {
        removeAll()
      }
    },
    [allIds, replace, removeAll],
  )

  const initialLoading = loading && !hasFilters
  const isEmpty = !loading && !hasFilters && !baseProducts.length

  if (initialLoading) return <Preloader />

  if (error) {
    return (
      <PageContainer>
        <ResultTitle>Товары</ResultTitle>

        <div className={styles.controlsWrapper}>
          <Button onClick={setIsImportModalOpen.on}>Импорт товаров</Button>
          <Link href={`/${sellerId}/catalog/products/create`}>
            <Button variant="outlined">Создать товар</Button>
          </Link>
          <ResultSearchFilter
            value={searchInput}
            placeholder="Поиск по названию или артикулу"
            onChange={onSearchChange}
            onClear={onSearchClear}
          />
          <Link href={`/${sellerId}/catalog/products/stores`}>
            <Button variant="outlined">Склады</Button>
          </Link>
          <ExportCatalog />
        </div>

        <ApiError error={error} />

        <ImportModal isOpen={isImportModalOpen} onClose={setIsImportModalOpen.off} />
      </PageContainer>
    )
  }

  if (isEmpty) {
    return (
      <PageContainer>
        <ResultTitle>Товары</ResultTitle>

        <div className={styles.controlsWrapper}>
          <Button onClick={setIsImportModalOpen.on}>Импорт товаров</Button>
          <Link href={`/${sellerId}/catalog/products/create`}>
            <Button variant="outlined">Создать товар</Button>
          </Link>
          <ResultSearchFilter
            value={searchInput}
            placeholder="Поиск по названию или артикулу"
            onChange={onSearchChange}
            onClear={onSearchClear}
          />
          <Link href={`/${sellerId}/catalog/products/stores`}>
            <Button variant="outlined">Склады</Button>
          </Link>
          <ExportCatalog />
        </div>

        <NoProducts />

        <ImportModal isOpen={isImportModalOpen} onClose={setIsImportModalOpen.off} />
      </PageContainer>
    )
  }

  return (
    <PageContainer>
      <ResultTitle>Товары</ResultTitle>

      <div className={styles.controlsWrapper}>
        <Button onClick={setIsImportModalOpen.on}>Импорт товаров</Button>
        <Link href={`/${sellerId}/catalog/products/create`}>
          <Button variant="outlined">Создать товар</Button>
        </Link>
        <ResultSearchFilter
          value={searchInput}
          placeholder="Поиск по названию или артикулу"
          onChange={onSearchChange}
          onClear={onSearchClear}
        />
        <Link href={`/${sellerId}/catalog/products/stores`}>
          <Button variant="outlined">Склады</Button>
        </Link>
        <ExportCatalog />
      </div>

      {loading ? (
        <Preloader />
      ) : (
        <>
          {baseProducts.length ? (
            <>
              <MultiActions
                isAllSelected={isAllSelected}
                selectedIds={selectedProductsIds}
                onSelectAllToggle={onSelectAllToggle}
                removeAllSelected={removeAll}
              />
              {baseProducts.map((baseProduct) => (
                <Product
                  key={baseProduct.id}
                  baseProduct={baseProduct}
                  isSelected={isSelected}
                  toggle={toggle}
                />
              ))}
              {hasPagination(elements) && (
                <Pagination page={Number(page)} count={pages} onChange={onPageChange} />
              )}
            </>
          ) : null}

          {!baseProducts.length ? <NoSearchResult /> : null}
        </>
      )}

      <ImportModal isOpen={isImportModalOpen} onClose={setIsImportModalOpen.off} />
    </PageContainer>
  )
}

export default withErrorBoundary(memo(BaseProductsList))
