import React from 'react'

import DropMenu from 'components/DropMenu'
import TitleCounter from 'components/DropMenu/TitleCounter'
import Text from 'components/Typography'
import Link from 'components/Link'
import { IPriceRule } from 'types/types'
import useCmsLinks from 'hooks/useCmsLinks'

import styles from './priceRules.module.scss'

type IPriceRulesProps = {
  price_rules?: IPriceRule[]
}

const PriceRules: React.FC<IPriceRulesProps> = ({ price_rules = [] }) => {
  const { priceRuleEditLink } = useCmsLinks()
  return (
    <DropMenu
      resetPadding
      initOpen={false}
      title={<TitleCounter counter={price_rules.length}>Ценовые группы</TitleCounter>}
    >
      <div className={styles.header}>
        <Text bold size="small">
          Номер заказа
        </Text>
      </div>

      {price_rules.map((priceRule) => (
        <Link key={priceRule.id} className={styles.item} href={priceRuleEditLink(priceRule.id)}>
          <Text>{priceRule.name}</Text>
        </Link>
      ))}
    </DropMenu>
  )
}

export default PriceRules
