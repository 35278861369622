import { ICurrency } from 'types/types'
// &#8381; |  \u20BD
export const CURRENCY_SYMBOL: Record<ICurrency, string> = {
  RUB: '₽',
  KZT: '₸',
}

export const CURRENCY: Record<ICurrency, ICurrency> = {
  RUB: 'RUB',
  KZT: 'KZT',
}
