import { DocumentNode, gql } from '@apollo/client'

import { Seller } from 'types/types'

export type ISetSellerClosedParams = {
  id: IPrimaryKey
  closed: boolean
}

export type ISetSellerClosed = {
  setSellerClosed: Seller
}

export const buildSetSellerClosedQuery = (fields: string): DocumentNode => gql`
  mutation setSellerClosed($id: ID!, $closed: Boolean!) {
    setSellerClosed(id: $id, closed: $closed) {
      ${fields}
    }
  }
`
