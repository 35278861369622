import React, { ReactNode, memo } from 'react'
import { Formik, FormikHelpers } from 'formik'

import Button from 'components/Button'
import FormControlsPanel from 'components/FormControlsPanel'
import LeavePageDirtyFormHelper from 'components/LeavePageDirtyFormHelper'
import AddSubmitShortcutFormHelper from 'components/AddSubmitShortcutFormHelper'
import withErrorBoundary from 'hocs/withErrorBoundary'

import validationSchema from './utils/validationSchema'
import { IClientFormValues } from './types'
import MainData from './components/MainData'

import styles from './clientForm.module.scss'

type IClientFormProps = {
  submitLoading: boolean
  initialValue: IClientFormValues
  onSubmit: (values: IClientFormValues, helpers: FormikHelpers<IClientFormValues>) => void
  deliveryAddresses?: ReactNode
  createdAt?: ReactNode
  renderControls?: () => ReactNode
}

const ClientForm: React.FC<IClientFormProps> = ({
  submitLoading,
  initialValue,
  onSubmit,
  deliveryAddresses,
  createdAt,
  renderControls = () => {},
  children,
}) => (
  <Formik
    enableReinitialize
    initialValues={initialValue}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    {({ values, handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <LeavePageDirtyFormHelper />
        <AddSubmitShortcutFormHelper />

        <MainData values={values} deliveryAddresses={deliveryAddresses} createdAt={createdAt} />

        {children}

        <FormControlsPanel className={styles.controlsPanel}>
          <Button type="submit" disabled={submitLoading}>
            Сохранить
          </Button>

          {renderControls()}
        </FormControlsPanel>
      </form>
    )}
  </Formik>
)

export default withErrorBoundary(memo(ClientForm))
