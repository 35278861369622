import React from 'react'

import { IManager } from 'types/types'
import Text from 'components/Typography'
import Paper from 'components/Paper'

import Contact from '../Manager'
import Row from '../Row'

type IResultProps = {
  managers: IManager[]
  refetchList: () => void
}

const Result: React.FC<IResultProps> = ({ managers, refetchList }) => (
  <Paper>
    <Row>
      <Text bold size="small">
        ФИО
      </Text>
      <Text bold size="small" align="center">
        Контрагенты
      </Text>
    </Row>
    {managers.map((manager) => (
      <Contact key={manager.id} manager={manager} refetchList={refetchList} />
    ))}
  </Paper>
)

export default Result
