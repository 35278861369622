import React from 'react'
import clsx from 'clsx'

import Button, { IButtonProps } from 'components/Button'
import { DEFAULT_ORDER_STATUS_COLOR } from 'consts/defaultValues'

import styles from './orderColorButton.module.scss'

type IOrderColorButtonProps = Omit<IButtonProps, 'color'> & {
  color?: string | null
}

const OrderColorButton: React.FC<IOrderColorButtonProps> = ({ className, color, ...props }) => (
  <Button
    className={clsx(styles.button, className)}
    classes={{ label: styles.label }}
    style={{ backgroundColor: color || DEFAULT_ORDER_STATUS_COLOR }}
    {...props}
  />
)

export default OrderColorButton
