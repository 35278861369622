import React, { memo, useCallback } from 'react'
import { useMutation } from '@apollo/client'

import Link from 'components/Link'
import Text from 'components/Typography'
import Switch from 'components/Switch'
import { Category, Group } from 'types/types'
import useCmsParams from 'hooks/useCmsParams'
import {
  ISetCategoryStatusParams,
  ISetCategoryStatus,
  buildSetCategoryStatusQuery,
} from 'graphql/mutations/setCategoryStatus'
import useNotifyCms from 'hooks/useNotifyCms'

import Controls from './components/Controls'

import style from './CardCategory.module.scss'

type ICardCategoryProps = {
  category: Category
  groups: Group[]
  refetchList: () => void
}

const SET_CATEGORY_STATUS = buildSetCategoryStatusQuery(`id status`)

const CardCategory: React.FC<ICardCategoryProps> = ({ category, groups, refetchList }) => {
  const { id: categoryId, status, name, number: position, group_id } = category

  const { sellerId } = useCmsParams()
  const addNotify = useNotifyCms()

  const categoryGroup = groups.find((group) => group.id === group_id)

  const [onSetCategoryStatus] = useMutation<ISetCategoryStatus, ISetCategoryStatusParams>(
    SET_CATEGORY_STATUS,
    {
      onCompleted: refetchList,
      onError: () => addNotify('error'),
    },
  )

  const onChangeStatus = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target
      onSetCategoryStatus({
        variables: { id: categoryId, status: checked },
      })
    },
    [categoryId, onSetCategoryStatus],
  )

  return (
    <div className={style.CardCategory}>
      <Link className={style.CardCategory__link} href={`/${sellerId}/catalog/categories/${categoryId}`}>
        <Text className={style.CardCategory__name}>
          {name} <span className={style.ID}>{` ID ${categoryId}`}</span>
        </Text>
        <Text className={style.CardCategory__GroupContainer}>{categoryGroup ? categoryGroup.name : '-'}</Text>
        <Text className={style.CardCategory__SortContainer}>
          {position !== undefined ? `Сортировка: ${position}` : ''}
        </Text>
      </Link>
      <div className={style.constrolsGroup}>
        <Switch
          className={style.switch}
          checked={status}
          placement="right"
          gap="medium"
          label={
            <Text size="small" color="textSecondary">
              {status ? 'активна' : 'неактивна'}
            </Text>
          }
          onChange={onChangeStatus}
        />
        <Controls id={categoryId} />
      </div>
    </div>
  )
}

export default memo(CardCategory)
