import { useContext } from 'react'

import { INotifyContext, NotifyContext } from 'contexts/NotifyProvider'

const useAddNotify = (): INotifyContext['addNotify'] => {
  const { addNotify } = useContext(NotifyContext)
  return addNotify
}

export default useAddNotify
