import { DocumentNode, gql, QueryHookOptions, useQuery } from '@apollo/client'

import { Paginate, PGroups } from 'types/types'

export type IGetGroups = {
  getGroups: PGroups
}

export type IGetGroupsParams = {
  seller_id: IPrimaryKey
  paginate?: Paginate
}

export const GET_GROUPS = gql`
  query getGroups($seller_id: ID!, $paginate: Paginate) {
    getGroups(seller_id: $seller_id, paginate: $paginate) {
      page
      pages
      elements
      list {
        id
        seller_id
        name
        number
        logo
      }
    }
  }
`

export const buildGetGroupsQuery = (fields: string): DocumentNode => gql`
  query getGroups($seller_id: ID!, $paginate: Paginate) {
    getGroups(seller_id: $seller_id, paginate: $paginate) {
      page
      pages
      elements
      list { ${fields} }
    }
  }
`

export const useGetGroupsQuery = <TData = IGetGroups, TOptions = IGetGroupsParams>(
  fields: DocumentNode,
  options?: QueryHookOptions<TData, TOptions>,
) => useQuery<TData, TOptions>(fields, options)
