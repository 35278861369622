import React, { ChangeEvent, useCallback, useMemo, useRef } from 'react'
import { useField } from 'formik'
import FormHelperText from '@material-ui/core/FormHelperText'
import clsx from 'clsx'

import Link from 'components/Link'
import createUniqId from 'utils/createUniqId'
import formikFieldEvent from 'utils/formInputs/formikFieldEvent'

import DropZone from '../DropZone'

import styles from './MobileDnDInput.module.scss'

const Empty: React.FC<{ inputRef: React.RefObject<HTMLInputElement>; onChange: (value: File) => void }> = ({
  inputRef,
  onChange,
}) => {
  const id = useMemo(() => createUniqId(), [])

  const handleFiles = useCallback(
    (files: FileList) => {
      const [image] = Object.values(files)
      onChange(image)
    },
    [onChange],
  )

  const onChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault()
      if (!event.currentTarget.files) return
      handleFiles(event.currentTarget.files)
      // eslint-disable-next-line no-param-reassign
      if (event.target.value) event.target.value = ''
    },
    [handleFiles],
  )

  return (
    <div className={styles.defaultContent}>
      <label htmlFor={id} className={styles.label} onClick={(event) => event.stopPropagation()}>
        <input
          id={id}
          ref={inputRef}
          type="file"
          name="file"
          accept=".jpeg, .jpg, .png, .gif, .webp"
          multiple={false}
          className={styles.hiddenInput}
          onChange={onChangeHandler}
        />
        <span className={styles.accent}>Выберите файл</span>
        <br />
        <span>
          или перетащите его <br /> в эту область.
        </span>
      </label>
    </div>
  )
}

type IImageProps = {
  image: string | File
  onDelete: () => void
}

const Image: React.FC<IImageProps> = ({ image, onDelete }) => {
  const src = image instanceof File ? URL.createObjectURL(image) : image

  return (
    <div className={styles.imageGroup}>
      <img className={styles.image} src={src} alt="" />
      <Link component="button" className={styles.imageButton} onClick={onDelete}>
        Удалить
      </Link>
    </div>
  )
}

const NAME = 'mobileImage'

type IMobileDnDInputProps = {
  isExtendedHomeView: boolean
}

const MobileDnDInput: React.FC<IMobileDnDInputProps> = ({ isExtendedHomeView }) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const [{ value, onChange }, { error, touched, initialTouched }] = useField<File | string | null>(NAME)

  const hasError = (touched || initialTouched) && !!error
  const hasValue = typeof value === 'string' || value instanceof File
  const hideBorder = hasValue && !hasError

  const onAddFile = useCallback(
    (paylaod: File) => {
      onChange(formikFieldEvent(NAME, paylaod))
    },
    [onChange],
  )

  const onClick = useCallback(() => {
    if (value) return
    inputRef.current?.click()
  }, [value])

  const onDelete = useCallback(() => {
    const newValue = value instanceof File ? null : { flag: 'delete' }
    onChange(formikFieldEvent(NAME, newValue))
  }, [value, onChange])

  return (
    <div className={styles.container}>
      <DropZone
        className={clsx(styles.dropZone, { [styles.dropZoneExtended]: isExtendedHomeView })}
        error={hasError}
        hideBorder={hideBorder}
        onChange={onAddFile}
        onClick={onClick}
      >
        {hasValue ? (
          <Image image={value} onDelete={onDelete} />
        ) : (
          <Empty inputRef={inputRef} onChange={onAddFile} />
        )}
      </DropZone>
      {hasError ? (
        <FormHelperText className={styles.helperText} error={hasError}>
          {error}
        </FormHelperText>
      ) : null}
    </div>
  )
}

export default MobileDnDInput
