import { gql } from '@apollo/client'

import { IPriceRule } from 'types/types'

export type ISetPriceRuleStatusParams = {
  id: IPrimaryKey
  status: boolean
}

export type ISetPriceRuleStatus = {
  setPriceRuleStatus: IPriceRule
}

export const SET_PRICE_RULE_STATUS = gql`
  mutation setPriceRuleStatus($id: ID!, $status: Boolean!) {
    setPriceRuleStatus(id: $id, status: $status) {
      id
      name
      status
    }
  }
`
