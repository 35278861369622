import React, { memo } from 'react'
import { useMutation } from '@apollo/client'

import TrashIcon from 'components/Icons/TrashIcon'
import CopyIcon from 'components/Icons/CopyIcon'
import EditIcon from 'components/Icons/EditIcon'
import Switch from 'components/Switch'
import Link from 'components/Link'
import Text from 'components/Typography'
import ListControlGroup from 'components/ListControlGroup'
import CopyPriceRule from 'components/ModelControls/CopyPriceRule'
import DeletePriceRule from 'components/ModelControls/DeletePriceRule'
import withErrorBoundary from 'hocs/withErrorBoundary'
import { IPriceRule, PPriceRule } from 'types/types'
import useNotifyCms from 'hooks/useNotifyCms'
import useCmsLinks from 'hooks/useCmsLinks'
import {
  ISetPriceRuleStatus,
  ISetPriceRuleStatusParams,
  SET_PRICE_RULE_STATUS,
} from 'graphql/mutations/setPriceRuleStatus'

import Row from '../Row'

import styles from './_priceRulesList.module.scss'

type IPriceRuleProps = {
  priceRule: IPriceRule
  refetchPriceRules: () => void
}

const PriceRule: React.FC<IPriceRuleProps> = ({ priceRule, refetchPriceRules }) => {
  const { id: priceRuleId, name, number: position, status, client_group } = priceRule

  const { priceRuleEditLink } = useCmsLinks()
  const addNotify = useNotifyCms()

  const [setPriceRuleStatus] = useMutation<ISetPriceRuleStatus, ISetPriceRuleStatusParams>(
    SET_PRICE_RULE_STATUS,
    {
      variables: {
        id: priceRuleId,
        status: !status,
      },
      onError: () => addNotify('error'),
      update: (cache, { data: resData }) => {
        if (!resData) return

        cache.modify({
          fields: {
            getPriceRules: (priceRules: PPriceRule) => ({
              ...priceRules,
              list: priceRules.list.map((record) => {
                if (record.id === resData.setPriceRuleStatus.id) {
                  return { ...record, status: !record.status }
                }
                return record
              }),
            }),
          },
        })
      },
    },
  )

  return (
    <Row>
      <Link href={priceRuleEditLink(priceRuleId)}>{name}</Link>

      <Text align="center">{client_group?.clients?.length}</Text>

      <Text align="center">{position}</Text>

      <div className={styles.actionsWrapper}>
        <Switch
          className={styles.statusSwitch}
          isChecked={status}
          gap="small"
          label={
            <Text size="small" color="textSecondary">
              {status ? 'активна' : 'неактивна'}
            </Text>
          }
          onChange={() => setPriceRuleStatus()}
        />

        <ListControlGroup>
          <Link href={priceRuleEditLink(priceRuleId)} startIcon={<EditIcon />}>
            Редактировать
          </Link>

          <CopyPriceRule
            priceRuleId={priceRuleId}
            onSuccess={refetchPriceRules}
            render={({ onClick }) => (
              <Link component="button" startIcon={<CopyIcon />} onClick={onClick}>
                Создать копию
              </Link>
            )}
          />

          <DeletePriceRule
            priceRuleId={priceRuleId}
            onSuccess={refetchPriceRules}
            render={({ onClick }) => (
              <Link component="button" startIcon={<TrashIcon />} onClick={onClick}>
                Удалить
              </Link>
            )}
          />
        </ListControlGroup>
      </div>
    </Row>
  )
}

export default withErrorBoundary(memo(PriceRule))
