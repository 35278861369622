import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import useBoolean from 'hooks/useBoolean'
import useNotifyCms from 'hooks/useNotifyCms'
import SuccessItemDialog from 'components/Dialogs/SuccessItemDialog'
import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import Link from 'components/Link'
import TrashIcon from 'components/Icons/TrashIcon'
import CopyIcon from 'components/Icons/CopyIcon'
import { DELETE_ATTRIBUTE } from 'graphql/mutations/deleteAttribute'
import { GET_ATTRIBUTES } from 'graphql/queries/getAttributes'
import { COPY_ATTRIBUTE } from 'graphql/mutations/copyAttribute'
import useCmsParams from 'hooks/useCmsParams'

const Controls: React.FC = () => {
  const { push } = useHistory()
  const addNotify = useNotifyCms()
  const { sellerId, attributeId } = useCmsParams()

  const [confirmDialog, setConfirmDialog] = useState<'delete' | 'copy' | null>(null)
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useBoolean(false)

  const [onDeleteAttribute] = useMutation(DELETE_ATTRIBUTE, {
    refetchQueries: [GET_ATTRIBUTES],
    onCompleted: () => {
      push(`/${sellerId}/catalog/attributes`)
      addNotify({ kind: 'success', message: 'Атрибут удален' })
    },
    onError: () => addNotify('error'),
  })

  const [onCopyAttribute, { data: copyData }] = useMutation(COPY_ATTRIBUTE, {
    refetchQueries: [GET_ATTRIBUTES],
    onCompleted: () => {
      setConfirmDialog(null)
      setIsSuccessDialogOpen.on()
    },
    onError: () => addNotify('error'),
  })

  const handlerDelete = (id: number) => onDeleteAttribute({ variables: { id } })
  const handlerCopy = (id: number) => onCopyAttribute({ variables: { id } })

  const redirectOnCopy = () => {
    push(`/${sellerId}/catalog/attributes/${copyData.copyAttribute.message}`)
    setIsSuccessDialogOpen.off()
  }

  const onConfirmDialogSubmit = () => {
    if (confirmDialog === 'delete') {
      handlerDelete(Number(attributeId))
    } else {
      handlerCopy(Number(attributeId))
    }
  }

  return (
    <>
      <SuccessItemDialog
        isOpen={isSuccessDialogOpen}
        title="Атирбут успешно скопирован"
        toItemText="К атрибуту"
        onToItem={redirectOnCopy}
        onClose={setIsSuccessDialogOpen.off}
      />

      <ConfirmDialog
        isOpen={!!confirmDialog}
        title={
          confirmDialog === 'delete'
            ? 'Вы уверены, что хотите удалить атрибут?'
            : 'Вы действительно хотите создать копию атрибута?'
        }
        submitBtnText={confirmDialog === 'delete' ? 'Да, удалить' : 'Да, создать'}
        onSubmit={onConfirmDialogSubmit}
        onClose={() => setConfirmDialog(null)}
      />

      <Link component="button" startIcon={<CopyIcon />} onClick={() => setConfirmDialog('copy')}>
        Создать копию
      </Link>
      <Link component="button" startIcon={<TrashIcon />} onClick={() => setConfirmDialog('delete')}>
        Удалить атрибут
      </Link>
    </>
  )
}

export default Controls
