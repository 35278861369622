import React, { useCallback, useState } from 'react'

import Preloader from 'components/Preloader'
import ResultTitle from 'components/ResultTitle'
import PageContainer from 'components/PageContainer'

import OneS from './components/OneS'
import ClientApi from './components/ClientApi'
import LinksImport from './components/LinksImport'
import PaymentMethods from './components/PaymentMethods'

import styles from './integrations.module.scss'

type ILoaders = {
  oneS: boolean
  clientApi: boolean
  paymentMethods: boolean
}

const initLoaders: ILoaders = {
  oneS: true,
  clientApi: true,
  paymentMethods: true,
}

const Integrations: React.FC = () => {
  const [{ clientApi, oneS, paymentMethods }, setLoaders] = useState(initLoaders)

  const isLoading = oneS || clientApi || paymentMethods

  const onOneSLoadingChange = useCallback((value: boolean) => {
    setLoaders((prevState) => ({ ...prevState, oneS: value }))
  }, [])

  const onClientApiLoadingChange = useCallback((value: boolean) => {
    setLoaders((prevState) => ({ ...prevState, clientApi: value }))
  }, [])

  const onPaymentMethodsChange = useCallback((value: boolean) => {
    setLoaders((prevState) => ({ ...prevState, paymentMethods: value }))
  }, [])

  return (
    <PageContainer>
      <ResultTitle>Интеграции</ResultTitle>
      {isLoading && <Preloader />}
      <div style={{ display: isLoading ? 'none' : 'block' }} className={styles.content}>
        <OneS onLoadingChange={onOneSLoadingChange} />
        <PaymentMethods onLoadingChange={onPaymentMethodsChange} />
        <ClientApi onLoadingChange={onClientApiLoadingChange} />
        <LinksImport />
      </div>
    </PageContainer>
  )
}

export default Integrations
