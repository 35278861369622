import React, { createContext, useContext, useState, useEffect, useMemo } from 'react'
import Cookies from 'js-cookie'
import { sha256 } from 'js-sha256'
import { useMutation, useApolloClient, ApolloError, useQuery } from '@apollo/client'
import { useHistory } from 'react-router-dom'

import { GET_USER, IGetUser } from 'graphql/queries/getUser'
import { LOGIN, ILogin, ILoginParams } from 'graphql/mutations/login'
import { RECOVERY, RecoveryResponseType } from 'graphql/mutations/recovery'
import { REFRESH_TOKEN, RefreshTokenResponseType } from 'graphql/mutations/refreshToken'
import { REGISTER_NEW_USER, RegisterResponseType } from 'graphql/mutations/register'
import { VERIFY_TOKEN, VerifyTokenResponseType } from 'graphql/mutations/verifyToken'
import { RESET_PASSWORD, ResetResponseType } from 'graphql/mutations/resetPassword'
import { userEvents } from 'utils/gtmHelper'
import { ILoginInput, IUser } from 'types/types'
import { TRegisterInput, TResetPassInput } from 'types/authTypes'

const cookieToken = Cookies.get('token')
const cookieTokenExpires = Number(Cookies.get('token-expires'))

type TAuthContext = {
  user?: IUser
  token?: string
  isAuth: boolean
  registerToken?: string
  loginLoading?: boolean
  registerLoading?: boolean
  logout: () => void
  login: (input: ILoginInput) => void
  verifyToken: (token: string) => void
  verifyTokenData?: VerifyTokenResponseType
  verifyTokenLoading?: boolean
  resetPassword: (input: TResetPassInput) => void
  resetPasswordData?: ResetResponseType
  resetPasswordLoading?: boolean
  resetPasswordError?: ApolloError
  recovery: (email: string) => void
  recoveryLoading?: boolean
  recoveryData?: RecoveryResponseType
  registerNewUser: (input: TRegisterInput) => void
  errors?: string
  setErrors: (val?: string) => void
}

const initial = {
  isAuth: false,
  login: () => {},
  verifyToken: () => {},
  resetPassword: () => {},
  recovery: () => {},
  logout: () => {},
  setErrors: () => {},
  registerNewUser: () => {},
}

let timerRefresh: number
const defaultExpiresIn = 3600

const setTokenCookie = (token: string, expiresIn: number): number => {
  const time = new Date().getTime() + expiresIn * 1000
  const expires = new Date(time)

  Cookies.set('token', token, { expires })
  Cookies.set('token-expires', `${time}`, { expires })

  return time
}

const regUserEvent = (action: string) => {
  setTimeout(() => {
    userEvents(action)
  }, 3000)
}

const AuthContext = createContext<TAuthContext>(initial)

export const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory()
  const client = useApolloClient()

  const [token, setToken] = useState(cookieToken)
  const [errors, setErrors] = useState<string>()
  const [tokenExpires, setTokenExpires] = useState(() =>
    Number.isNaN(cookieTokenExpires) ? null : cookieTokenExpires,
  )

  const isAuth = useMemo(() => Boolean(token), [token])

  const updateToken = (newToken: string, exp: number) => {
    const time = setTokenCookie(newToken, exp)

    setToken(newToken)
    setTokenExpires(time)
  }

  const { data: user } = useQuery<IGetUser>(GET_USER, {
    skip: !token,
    onCompleted: (data) => {
      localStorage.setItem('profileID', data.getUser.id)
      localStorage.setItem('profileEmail', sha256(data.getUser.email))
    },
  })

  // const [onRefresh] = useMutation<RefreshTokenResponseType>(REFRESH_TOKEN, {
  //   onCompleted(data) {
  //     if (!data || !data.refreshToken || !data.refreshToken.access_token) {
  //       return;
  //     }

  //     updateToken(data.refreshToken.access_token, data.refreshToken.expires_in || defaultExpiresIn);
  //   },
  // });

  const [onLogin, { loading: loginLoading }] = useMutation<ILogin, ILoginParams>(LOGIN, {
    onCompleted: ({ login }) => {
      const { access_token, expires_in } = login
      if (!access_token) throw new Error('NO CREDENTIALS')

      setErrors(undefined)
      regUserEvent('auth')
      updateToken(access_token, expires_in || defaultExpiresIn)
    },
    onError: () => {
      setErrors('Введены некорректные данные!')
    },
  })

  const [onRecovery, { data: recoveryData, loading: recoveryLoading }] = useMutation(RECOVERY)

  const [onVerifyToken, { data: verifyTokenData, loading: verifyTokenLoading }] = useMutation(VERIFY_TOKEN)

  const [
    onResetPassword,
    { data: resetPasswordData, loading: resetPasswordLoading, error: resetPasswordError },
  ] = useMutation(RESET_PASSWORD)

  const [onRegisterNewUser, { loading: registerLoading }] = useMutation<RegisterResponseType>(
    REGISTER_NEW_USER,
    {
      errorPolicy: 'ignore',
      onCompleted(data) {
        if (!data || !data.register || !data.register.tokens || !data.register.tokens.access_token) {
          setErrors('Этот e-mail уже занят!')
          return
        }
        regUserEvent('reg')
        updateToken(data.register.tokens.access_token, data.register.tokens.expires_in || 3600)
      },
    },
  )

  const login = (input: ILoginInput) => onLogin({ variables: { input } })
  const recovery = (email: string) => onRecovery({ variables: { email } })
  const verifyToken = (t: string) => onVerifyToken({ variables: { token: t } })
  const resetPassword = (input: TResetPassInput) => onResetPassword({ variables: { input } })
  const registerNewUser = (input: TRegisterInput) => onRegisterNewUser({ variables: { input } })

  const logout = () => {
    Cookies.remove('token')
    Cookies.remove('token-expires')
    localStorage.removeItem('profileID')
    localStorage.removeItem('profileEmail')

    setToken('')
    setTokenExpires(null)

    client.cache.reset()
    client.clearStore()

    // withLeaveThisPageModal logout fix
    const ensureUnblock = history.block()
    ensureUnblock()
  }

  // useEffect(() => {
  //   if (!isAuth || !tokenExpires) {
  //     clearTimeout(timerRefresh);
  //     return () => {};
  //   }

  //   const expiresCookieTime = new Date(tokenExpires).getTime();
  //   // substruct 20 minutes from expiresCookieTime
  //   const availableExpiresCookieTime = expiresCookieTime - 20 * 60 * 1000;
  //   // getSeconds from testTimeout to make setTimeout timeout
  //   const currentTimeoutTime = new Date(availableExpiresCookieTime).getTime() - new Date().getTime();

  //   const timeout = currentTimeoutTime < 0 ? 0 : currentTimeoutTime;

  //   timerRefresh = setTimeout(onRefresh, timeout);

  //   return () => clearTimeout(timerRefresh);
  // }, [isAuth, tokenExpires]);

  const value: TAuthContext = {
    isAuth,
    token,
    user: user?.getUser,
    loginLoading,
    registerLoading,
    login,
    verifyToken,
    verifyTokenData,
    verifyTokenLoading,
    resetPassword,
    resetPasswordData,
    resetPasswordLoading,
    resetPasswordError,
    recovery,
    recoveryLoading,
    recoveryData,
    logout,
    registerNewUser,
    setErrors,
    errors,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = (): TAuthContext => useContext(AuthContext)
