import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'

import useShortcuts from 'hooks/useShortcuts'

const AddSubmitShortcutFormHelper: React.FC = () => {
  const { submitForm } = useFormikContext()
  const { registerShortcut } = useShortcuts()

  useEffect(() => {
    registerShortcut('ctrlS', submitForm)

    return () => {
      registerShortcut('ctrlS', null)
    }
  }, [registerShortcut, submitForm])

  return null
}

export default AddSubmitShortcutFormHelper
