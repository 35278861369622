import React, { useEffect, useMemo, useRef, useState } from 'react'
import clsx from 'clsx'

import useOutsideAlerter from 'hooks/useOutsideAlerter'
import Input from 'components/ui-kit/input'
import { ReactComponent as ArrowUp } from 'assets/img/arrowTop.svg'
import { ReactComponent as ArrowDown } from 'assets/img/userArrow.svg'
import TimeIcon from 'components/Icons/TimeIcon'

import styles from './TimePicker.module.scss'

type ITimePickerProps = {
  className?: string
  startHours?: string
  startMinutes?: string
  onChange: (payload: { hours: string; minutes: string }) => void
}

const TimePicker: React.FC<ITimePickerProps> = ({ className, startHours, startMinutes, onChange }) => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [hours, setHours] = useState(startHours || '00')
  const [minutes, setMinutes] = useState(startMinutes || '00')

  const inputVal = useMemo(() => `${hours}:${minutes}`, [hours, minutes])

  const applyInputChanges = (value: string) => {
    const hours = value.split(':')[0]
    const minutes = value.split(':')[1]

    const payload = {
      hours,
      minutes,
    }

    onChange(payload)
  }

  const handleInputEdit = (value: string) => {
    const time = value.replace('_', '0')
    const hours = time.split(':')[0]
    const minutes = time.split(':')[1]

    if (time.length < 6) {
      const hh = Number(hours.split('')[0]) > 2 || Number(hours) > 24 ? '00' : hours
      const mm = Number(minutes.split('')[0]) > 5 ? '00' : minutes

      setHours(hh)
      setMinutes(mm)
    }
  }

  useEffect(() => {
    onChange({ hours, minutes })
  }, [hours, minutes])

  const ref = useRef(null)
  useOutsideAlerter(ref, setIsOpenModal)

  const hoursUp = () => {
    const numberHours = Number(hours)
    const newNumber = numberHours + 1

    if (newNumber === 24) return setHours('00')
    if (newNumber < 10) return setHours(`0${newNumber}`)

    setHours(String(newNumber))
  }

  const hoursDown = () => {
    const numberHours = Number(hours)
    const newNumber = numberHours - 1

    if (newNumber === -1) return setHours('23')
    if (newNumber === 0) return setHours('00')
    if (newNumber < 10) return setHours(`0${newNumber}`)

    setHours(String(newNumber))
  }

  const minutesUp = () => {
    const numberMinutes = Number(minutes)
    const newNumber = numberMinutes + 10

    if (newNumber >= 60) return setMinutes('00')

    setMinutes(String(newNumber))
  }

  const minutesDown = () => {
    const numberMinutes = Number(minutes)
    const newNumber = numberMinutes - 10

    if (newNumber < 0) return setMinutes('50')
    if (newNumber === 0) return setMinutes('00')

    setMinutes(String(newNumber))
  }

  useEffect(() => {
    setHours(startHours || '00')
  }, [startHours])

  useEffect(() => {
    setMinutes(startMinutes || '00')
  }, [startMinutes])

  return (
    <div className={clsx(styles.container, className)} ref={ref}>
      <div className={styles.TimePicker__input_group} onClick={() => setIsOpenModal((prev) => !prev)}>
        <span className={styles.TimePicker__input_group_text}>
          <TimeIcon />
        </span>
        <Input
          value={inputVal}
          onBlur={() => applyInputChanges(inputVal)}
          mask="99:99"
          onChange={(e) => handleInputEdit(e.target.value)}
          type="text"
          className={styles.TimePicker__form_control}
        />
      </div>
      {isOpenModal && (
        <div className={styles.TimePicker__modal}>
          <div className={styles.row}>
            <div className={styles.cell} onClick={hoursUp}>
              <ArrowUp />
            </div>
            <div className={styles.cell} onClick={minutesUp}>
              <ArrowUp />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.numbersCell}>{hours}</div>
            <div className={styles.numbersSeparator}>:</div>
            <div className={styles.numbersCell}>{minutes}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.cell} onClick={hoursDown}>
              <ArrowDown />
            </div>
            <div className={styles.cell} onClick={minutesDown}>
              <ArrowDown />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default TimePicker
