import { object, string } from 'yup'

import { REQUIRED_MESSAGE } from 'consts/errorMessages'

const validationSchema = () =>
  object({
    name: string().required(REQUIRED_MESSAGE),
  })

export default validationSchema
