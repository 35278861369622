import { DocumentNode, gql, QueryHookOptions, useQuery } from '@apollo/client'

import { Paginate, PProducts } from 'types/types'

export type IGetProducts = {
  getProducts: PProducts
}

export type IGetProductsParams = {
  seller_id: IPrimaryKey
  search?: string | null
  category_id?: IPrimaryKey | null
  price_list_id?: IPrimaryKey | null
  sort_ids?: IPrimaryKey[] | null
  paginate?: Paginate
}

export const buildGetProductsQuery = (fields: string): DocumentNode => gql`
  query getProducts(
    $seller_id: ID!, 
    $search: String, 
    $category_id: ID, 
    $price_list_id: ID, 
    $sort_ids: [ID], 
    $paginate: Paginate
  ) {
    getProducts(
      seller_id: $seller_id,
      search: $search,
      category_id: $category_id,
      price_list_id: $price_list_id,
      sort_ids: $sort_ids,
      paginate: $paginate
    ) {
      page
      pages
      elements
      list { ${fields} }
    }
  }
`

export const useGetProductsQuery = <TData = IGetProducts, TOptions = IGetProductsParams>(
  fields: DocumentNode,
  options?: QueryHookOptions<TData, TOptions>,
) => useQuery<TData, TOptions>(fields, options)
