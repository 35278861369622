import { buildGetOrderQuery } from 'graphql/queries/getOrder'

export const GET_ORDER = buildGetOrderQuery(`
  id
  number
  created_at
  new
  comment
  address
  delivery
  client {
    id
  }

  order_status {
    id
    title
    color
  }

  products {
    id
    amount
    price
    name
    vendor_code
    on_order
  }
  contact {
    id
    name
    email
    phone
  }
  customer {
    contact {
      name
      email
    }
    company {
      name
      inn
    }
  }

  files {
    id
    name
    path
  }
`)
