import React, { Fragment, memo, useCallback } from 'react'

import Switch from 'components/Switch'
import Link from 'components/Link'
import Text from 'components/Typography'
import ListControlGroup from 'components/ListControlGroup'
import EditIcon from 'components/Icons/EditIcon'
import TrashIcon from 'components/Icons/TrashIcon'
import useCmsParams from 'hooks/useCmsParams'
import useNotifyCms from 'hooks/useNotifyCms'
import useCurrentSeller from 'hooks/useCurrentSeller'
import { IClient, IContact, Maybe } from 'types/types'
import withErrorBoundary from 'hocs/withErrorBoundary'
import { buildSetContactQuery, useSetContactStatusMutation } from 'graphql/mutations/setContactStatus'
import DeleteContact from 'components/ModelControls/DeleteContact'
import contactDefaultValues from 'utils/defaultValues/contactDefaultValues'

import Row from '../Row'

import styles from './contact.module.scss'

type IContactProps = {
  contact: IContact
  refetchList: () => void
}

const SET_CONTACT_STATUS = buildSetContactQuery(`id name status`)

const Clients: React.FC<{ clients: Maybe<IClient[]> }> = ({ clients }) => {
  const { id: sellerId } = useCurrentSeller()

  if (!clients || !clients.length) {
    return <Text color="textSecondary">Физ. лицо</Text>
  }

  return (
    <Text>
      {clients.map(({ id, name }, index) => (
        <Fragment key={id}>
          <Link href={`/${sellerId}/clients/profile/${id}/`}>{name}</Link>
          {index < clients.length - 1 && ', '}
        </Fragment>
      ))}
    </Text>
  )
}

const Contact: React.FC<IContactProps> = ({ contact, refetchList }) => {
  const { id: contactId, name, status, clients } = contactDefaultValues(contact)

  const { sellerId } = useCmsParams()
  const addNotify = useNotifyCms()

  const [setStatus, { loading }] = useSetContactStatusMutation(SET_CONTACT_STATUS, {
    onCompleted: refetchList,
    onError: () => addNotify('error'),
  })

  const onStatusChange = useCallback(
    (event: InputChangeEvent) => {
      const { checked } = event.target

      setStatus({
        variables: {
          id: contact.id,
          status: checked,
        },
      })
    },
    [setStatus, contact],
  )

  return (
    <Row>
      <Text>
        <Link href={`/${sellerId}/contacts/${contactId}/`}>{name}</Link>
      </Text>

      <Clients clients={clients} />

      <div className={styles.controlsGroup}>
        <Switch
          className={styles.statusSwitch}
          disabled={loading}
          isChecked={status}
          gap="small"
          label={
            <Text size="small" color="textSecondary">
              {status ? 'активен' : 'неактивен'}
            </Text>
          }
          onChange={onStatusChange}
        />

        <ListControlGroup>
          <Link href={`/${sellerId}/contacts/${contactId}`} startIcon={<EditIcon />}>
            Редактировать
          </Link>
          <DeleteContact
            contactId={contactId}
            onSuccess={refetchList}
            render={({ onClick }) => (
              <Link component="button" startIcon={<TrashIcon />} onClick={onClick}>
                Удалить
              </Link>
            )}
          />
        </ListControlGroup>
      </div>
    </Row>
  )
}

export default withErrorBoundary(memo(Contact))
