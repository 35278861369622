import { IBaseProductFormValues } from 'components/forms/BaseProductForm'

const defaultValues: IBaseProductFormValues = {
  name: '',
  vendor_code: '',
  price: '',
  status: true,
  on_order: false,
  amount: '',
  amount_wait: '',
  multiply: '1',
  weight: { value: '', type: 'кг' },
  volume: { value: '', type: 'л' },
  length: { value: '', type: 'см' },
  width: '',
  height: '',
  description: '',
  number: 9999,
  stores: [],
  images: [],
  prices: [],
  links: [],

  category_id: null,
  attributes: {},
  related_products: [],
}

export default defaultValues
