import { gql } from '@apollo/client'

import { Status } from 'types/types'

export type IDeleteAttribute = {
  delAttribute: Status
}

export const DELETE_ATTRIBUTE = gql`
  mutation delAttribute($id: ID!) {
    delAttribute(id: $id) {
      status
      message
    }
  }
`
