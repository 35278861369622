import { DocumentNode, gql } from '@apollo/client'

import { IImportSchedule } from 'types/types'

export type IGetImportSchedule = {
  getImportSchedule: IImportSchedule
}

export type IGetImportScheduleParams = {
  id: number
}

export const GET_IMPORT_SCHEDULE = gql`
  query getImportSchedule($id: ID!) {
    getImportSchedule(id: $id) {
      id
      seller_id
      mode
      ext_url
      auto_update
      period_update
      percent_update
      status_update
      last_update
      created_at
      updated_at
    }
  }
`

export const buildGetImportScheduleQuery = (fields: string): DocumentNode => gql`
  query getImportSchedule($id: ID!) {
    getImportSchedule(id: $id) {
      ${fields}
    }
  }
`
