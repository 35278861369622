import React from 'react'
import { useMutation } from '@apollo/client'

import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import { DELETE_STORE } from 'graphql/mutations/deleteStore'
import useBoolean from 'hooks/useBoolean'
import useNotifyCms from 'hooks/useNotifyCms'

type IRenderParams = {
  onClick: () => void
}

type IDeleteStoreProps = {
  storeId: IPrimaryKey
  onSuccess: () => void
  render: (params: IRenderParams) => void
}

const DeleteStore: React.FC<IDeleteStoreProps> = ({ storeId, onSuccess, render }) => {
  const addNotify = useNotifyCms()

  const [iseOpen, setIsOpen] = useBoolean(false)

  const [onDeleteStore] = useMutation(DELETE_STORE, {
    variables: {
      id: storeId,
    },
    onCompleted: () => {
      setIsOpen.off()
      addNotify({ kind: 'success', message: 'Склад удален' })
      onSuccess()
    },
    onError: () => {
      addNotify('error')
      setIsOpen.off()
    },
  })

  return (
    <>
      <ConfirmDialog
        isOpen={iseOpen}
        title="Вы уверены, что хотите удалить этот склад?"
        submitBtnText="Да, удалить"
        onSubmit={() => onDeleteStore()}
        onClose={setIsOpen.off}
      />

      {render({ onClick: setIsOpen.on })}
    </>
  )
}

export default DeleteStore
