import React from 'react'

import Text from 'components/Typography'

import styles from './booleanLikeInput.module.scss'

type IBooleanLikeInputProps = {
  className?: string
  title: string
}

const BooleanLikeInput: React.FC<IBooleanLikeInputProps> = ({ className, title, children }) => (
  <div className={className}>
    <Text className={styles.title}>{title} </Text>
    {children}
  </div>
)

export default BooleanLikeInput
