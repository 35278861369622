import React, { useCallback, useMemo, useState, FC } from 'react'
import { useField } from 'formik'
import { useQuery } from '@apollo/client'

import { buildGetClientsQuery, IGetClients, IGetClientsParams } from 'graphql/queries/getClients'
import useCmsParams from 'hooks/useCmsParams'
import Button from 'components/Button'
import UiLayout from 'components/ui-kit/UiLayout'
import CustomMultiSelect from 'components/ui-kit/form/CustomMultiSelect'
import { IClient } from 'types/types'
import PriceRuleClient from 'components/PriceRuleClient/PriceRuleClient'
import extractId from 'utils/extractId'
import Preloader from 'components/Preloader'
import ErrorText from 'components/ErrorText'
import convertClientsToHash from 'utils/convertClientsToHash'
import { maxPaginate } from 'consts'
import DropMenu, { TitleCounter } from 'components/DropMenu'
import Text from 'components/Typography'
import SwitchInput from 'components/Inputs/SwitchInput'
import formikFieldEvent from 'utils/formInputs/formikFieldEvent'

import Box from '../Box'

import { IPriceRuleFormValues } from '../../types'

import styles from './clientsInput.module.scss'

type IClientsInputProps = {
  name: string
}

const GET_CLIENTS = buildGetClientsQuery(`
  id
  name
`)

const transformClientToOption = ({ id, name = 'EMPTY_NAME' }: IClient): IOption => ({ id, name })

const filterByIds =
  (ids: IPrimaryKey[]) =>
  ({ id }: { id: IPrimaryKey }) =>
    !ids.includes(id)

const sortPriceRuleClients = (a: IPrimaryKey, b: IPrimaryKey) => (Number(a) < Number(b) ? 1 : -1)

const ClientsInput: FC<IClientsInputProps> = ({ name }) => {
  const { sellerId } = useCmsParams()

  const [{ value: clientsIds, onChange }] = useField<IPriceRuleFormValues['clientsIds']>(name)

  const [selectedClients, setSelectedClients] = useState<IOption[]>([])

  const { data, loading, error } = useQuery<IGetClients, IGetClientsParams>(GET_CLIENTS, {
    variables: { seller_id: sellerId, paginate: maxPaginate },
  })

  const allClientsHash = useMemo(() => {
    if (!data) return {}
    return convertClientsToHash(data.getClients.list)
  }, [data])

  const availableClientsOptions = useMemo(() => {
    if (!data) return []
    return data.getClients.list.filter(filterByIds(clientsIds)).map(transformClientToOption)
  }, [data, clientsIds])

  const onClientsChange = useCallback((payload: IOption[]) => {
    setSelectedClients(payload)
  }, [])

  const onAddClientsClick = useCallback(() => {
    const nextValue = clientsIds.concat(selectedClients.map(extractId))

    onChange(formikFieldEvent(name, nextValue))
    setSelectedClients([])
  }, [onChange, name, clientsIds, selectedClients])

  const removeClient = useCallback(
    (removeId: IPrimaryKey) => {
      const nextValue = clientsIds.filter((record) => removeId !== record)
      onChange(formikFieldEvent(name, nextValue))
    },
    [onChange, name, clientsIds],
  )

  if (loading) {
    return <Preloader variant="relative" />
  }

  if (error) {
    return <ErrorText>{error.message}</ErrorText>
  }

  return (
    <DropMenu title={<TitleCounter counter={clientsIds.length}>Участники группы</TitleCounter>} resetPadding>
      <Box>
        <SwitchInput
          name="addDefault"
          placement="right"
          gap="large"
          label={<Text>Добавлять по умолчанию новых пользователей</Text>}
        />
        <div className={styles.addClientsGroup}>
          <UiLayout spanText="Контрагенты">
            <CustomMultiSelect
              options={availableClientsOptions}
              selectedOptions={selectedClients}
              setSelectedOption={onClientsChange}
            />
          </UiLayout>

          <Button
            className={styles.addClientsButton}
            disabled={!selectedClients.length}
            onClick={onAddClientsClick}
          >
            Добавить
          </Button>
        </div>
      </Box>

      {clientsIds.length ? (
        <div className={styles.selectedClientsList}>
          {clientsIds.sort(sortPriceRuleClients).map((activeClientId) => {
            const client = allClientsHash[activeClientId]
            if (!client) return null
            return (
              <PriceRuleClient
                key={client.id}
                client={client}
                sellerId={sellerId}
                onDelete={() => removeClient(activeClientId)}
              />
            )
          })}
        </div>
      ) : null}
    </DropMenu>
  )
}

export default ClientsInput
