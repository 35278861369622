import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { useField } from 'formik'

import Button from 'components/Button'
import Text, { H2 } from 'components/Typography'
import Dialog from 'components/Dialog'
import DialogContent from 'components/DialogContent'
import DialogCloseButton from 'components/DialogCloseButton'
import MultiDragAndDropField from 'components/MultiDragAndDropField'
import UploadIcon from 'components/Icons/UploadIcon'
import useBoolean from 'hooks/useBoolean'
import { Image, ImageInput } from 'types/types'
import nonNullable from 'utils/nonNullable'
import createUrlFromUploadedFile, { IUploadedData } from 'helpers/createUrlFromUploadedFile'
import useNotifyCms from 'hooks/useNotifyCms'

import { IVariantsFormValues } from '../../../../../../types'

import styles from './uploadPhotoInput.module.scss'

type IConfirmInputProps = {
  name: string
}

type IValueItem = (Image & { flag?: string }) | File

const removeDeletedFile = (payload: IValueItem) => {
  if (payload instanceof File) return true
  return payload.flag !== 'delete'
}

const hasImages = (images: Array<{ path?: string }>) => Boolean(images.length && images[0].path)

const keepFile = (value: Image | File): value is File => value instanceof File
const keepNotFile = (value: Image | File): value is Image => !(value instanceof File)

const convertUploadedImagesToImagesInput = (payload: IUploadedData[]): ImageInput[] =>
  payload.map(({ size, status, ...rest }) => rest)

// TODO: картинки загружаются по кнопке "Сохранить", затем их пути загружаются в форму
const UploadPhotoInput: React.FC<IConfirmInputProps> = ({ name }) => {
  const [{ value }, meta, { setValue, setTouched }] =
    useField<IVariantsFormValues['products'][0]['images']>(name)

  const addNotify = useNotifyCms()

  const [isOpen, setIsOpen] = useBoolean()
  const [images, setImages] = useState<Array<Image | File>>([])

  const files = useMemo(() => {
    if (!images) return []
    return images.filter(removeDeletedFile)
  }, [images])

  const onClose = useCallback(() => setIsOpen.off(), [setIsOpen])

  const onSubmit = useCallback(async () => {
    const filesToLoad = images.filter(keepFile)
    const currentImages = images.filter(keepNotFile) as any[]

    let uploadedFiles: IUploadedData[] = []

    if (filesToLoad.length > 0) {
      try {
        uploadedFiles = await createUrlFromUploadedFile(filesToLoad, { type: 'images' })
      } catch (error) {
        addNotify({ kind: 'error', message: 'Ошибка сохранения изображений' })
      }
    }

    const uploadedImages = convertUploadedImagesToImagesInput(uploadedFiles)

    setValue(currentImages.concat(uploadedImages))

    onClose()
  }, [onClose, addNotify, setValue, images])

  const onFilesChange = useCallback(
    (payload: File[]) => {
      setTouched(true)
      setImages((prevState) => prevState.concat(payload))
    },
    [setTouched],
  )

  const onFilesDelete = useCallback(
    (removeIndex: number) => {
      setTouched(true)

      const nextValue = images
        .map((record, index) => {
          if (record instanceof File && index === removeIndex) return null
          if (!(record instanceof File) && index === removeIndex) return { ...record, flag: 'delete' }
          return record
        })
        .filter(nonNullable)

      setImages(nextValue)
    },
    [setTouched, images],
  )

  useEffect(() => {
    if (isOpen) {
      setImages(value as Image[])
      return
    }
    setImages([])
  }, [isOpen, value])

  return (
    <>
      <button type="button" className={styles.imageBtn} onClick={setIsOpen.on}>
        {hasImages(value) ? <img src={value[0].path} alt="" /> : <UploadIcon fontSize="large" />}
      </button>

      <Dialog isOpen={isOpen} onClose={onClose}>
        <DialogCloseButton onClick={onClose} />
        <DialogContent className={styles.content}>
          <H2 className={styles.title}>Добавление фото</H2>
          <ul className={styles.list}>
            <li className={styles.listItem}>
              <Text>Расширение файла – jpg или png</Text>
            </li>
            <li className={styles.listItem}>
              <Text>Размер фото – 1000 х 750 px, вес – не более 500 кб</Text>
            </li>
          </ul>
          <MultiDragAndDropField
            filesFormat=".png, .jpg"
            values={files}
            onChange={onFilesChange}
            onDelete={onFilesDelete}
          />
          <div className={styles.controlsGroup}>
            <Button onClick={onSubmit}>Сохранить</Button>
            <Button variant="outlined" onClick={onClose}>
              Отменить
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default UploadPhotoInput
