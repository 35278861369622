import React, { useCallback } from 'react'
import { useMutation } from '@apollo/client'

import useBoolean from 'hooks/useBoolean'
import { SET_PRODUCT_VARIANTS_STATUS } from 'graphql/mutations/setProductVariantsStatus'
import Switch from 'components/Switch'
import Text from 'components/Typography'
import useCmsParams from 'hooks/useCmsParams'
import useCatchNotify from 'hooks/useCatchNotify'

type IVariantsSwitchProps = {
  initStatus: boolean
  refetchBaseProduct: () => void
}

const VariantsSwitch: React.FC<IVariantsSwitchProps> = ({ initStatus, refetchBaseProduct }) => {
  const { productId } = useCmsParams()
  const addCatchNotify = useCatchNotify()

  const [isVariantsEnable, setIsVariantsEnable] = useBoolean(initStatus)

  const [setProductVariantsStatus, { loading }] = useMutation(SET_PRODUCT_VARIANTS_STATUS, {
    onCompleted: refetchBaseProduct,
    onError: addCatchNotify,
  })

  const onChange = useCallback(
    (event: InputChangeEvent) => {
      if (loading) return
      const nextChecked = event.target.checked
      setIsVariantsEnable.setValue(nextChecked)

      setProductVariantsStatus({
        variables: {
          id: productId,
          variants_status: nextChecked,
        },
      })
    },
    [loading, setIsVariantsEnable, productId, setProductVariantsStatus],
  )

  return (
    <Switch
      checked={isVariantsEnable}
      disabled={loading}
      placement="right"
      gap="large"
      label={<Text>Использовать варианты</Text>}
      onChange={onChange}
    />
  )
}

export default VariantsSwitch
