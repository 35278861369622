import { IBaseProductFormValues } from 'components/forms/BaseProductForm'
import { BaseProductAttribute, IBaseProduct, IProduct, IValueType } from 'types/types'

const initValueType: IValueType = { value: '', type: '' }

const ensureDefaultValueType = ({ value, type }: IValueType, defaultType: string): Required<IValueType> => {
  if (value) return type ? { value, type } : { value, type: defaultType }
  return { value: '', type: defaultType }
}

const prepareAttributes = (payload: BaseProductAttribute[]): Record<string, string> => {
  const response = payload.reduce((acc, { attribute_values }) => {
    const { attribute_id, id } = attribute_values[0]
    return {
      ...acc,
      [attribute_id]: id,
    }
  }, {})

  return response
}

const prepateStores = (stores: IProduct['stores']): IBaseProductFormValues['stores'] => {
  if (!stores || !stores.length) return []
  return stores.map(({ amount, amount_wait, multiply, ...rest }) => ({
    amount: amount.toString(),
    amount_wait: amount_wait.toString(),
    multiply: multiply.toString(),
    ...rest,
  }))
}

const prepareDescription = (description: IBaseProduct['description']) => {
  if (!description) return ''
  return description
}

const prepareInitialValues = (baseProduct: IBaseProduct): IBaseProductFormValues => {
  const {
    status = true,
    name = '',
    category_id = null,
    products = [],
    images = [],
    base_product_attributes,
    related_products,
    links = null,
  } = baseProduct

  const product = products[0]

  if (!product) throw new Error('NO BASE PRODUCT')

  const {
    vendor_code = '',
    price = '',
    on_order = false,
    amount,
    amount_wait,
    multiply,
    volume = initValueType,
    weight = initValueType,
    length = initValueType,
    width = initValueType,
    height = initValueType,
    stores,
    description = null,
    number = null,
    prices = null,
  } = product

  const attributes = prepareAttributes(base_product_attributes)

  return {
    name,
    vendor_code,
    status,
    price,
    category_id,
    on_order,
    amount: amount ? amount.toString() : '',
    amount_wait: amount_wait ? amount_wait.toString() : '',
    multiply: multiply ? multiply.toString() : '',
    volume: ensureDefaultValueType(volume, 'л'),
    weight: ensureDefaultValueType(weight, 'кг'),
    length: ensureDefaultValueType(length, 'см'),
    width: width.value ?? '',
    height: height.value ?? '',
    description: prepareDescription(description),
    number: number ?? 9999,
    stores: prepateStores(stores),
    images,
    attributes,
    related_products: related_products ?? [],
    prices,
    links: links ?? [],
  }
}

export default prepareInitialValues
