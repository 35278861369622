import { Overrides } from '@material-ui/core/styles/overrides'

const svgIcon: Overrides['MuiSvgIcon'] = {
  colorAction: {
    color: '#0089CC',
    transition: 'color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  fontSizeLarge: {
    fontSize: '2rem',
  },
}

export default svgIcon
