import { DocumentNode, MutationHookOptions, gql, useMutation } from '@apollo/client'

import { IBanner } from 'types/types'

export type IDeleteBanner = {
  delBanner: IBanner
}

export type IDeleteBannerParams = {
  id: IPrimaryKey
}

export const DELETE_BANNER = gql`
  mutation delBanner($id: ID!) {
    delBanner(id: $id) {
      status
      message
      data
    }
  }
`

export const useDeleteBannerMutation = <TData = IDeleteBanner, TOptions = IDeleteBannerParams>(
  fields: DocumentNode,
  options?: MutationHookOptions<TData, TOptions>,
) => useMutation<TData, TOptions>(fields, options)
