import { Overrides } from '@material-ui/core/styles/overrides'

const linearProgress: Overrides['MuiLinearProgress'] = {
  root: {
    height: '8px',
    borderRadius: '8px',
  },
  colorPrimary: {
    backgroundColor: '#F4F5F8',
  },
  bar: {
    borderRadius: '8px',
  },
  barColorPrimary: {
    backgroundColor: '#002FA7',
  },
}

export default linearProgress
