import React from 'react'

import Link from 'components/Link'
import ListControlGroup from 'components/ListControlGroup'
import TrashIcon from 'components/Icons/TrashIcon'
import EditIcon from 'components/Icons/EditIcon'
import useCmsParams from 'hooks/useCmsParams'
import DeleteBanner from 'components/ModelControls/DeleteBanner'

type IControlsProps = {
  bannerId: string
  refetch: () => void
}

const Controls: React.FC<IControlsProps> = ({ bannerId, refetch }) => {
  const { sellerId } = useCmsParams()

  return (
    <>
      <ListControlGroup>
        <Link href={`/${sellerId}/options/banners/${bannerId}`} startIcon={<EditIcon />}>
          Редактировать
        </Link>

        <DeleteBanner
          bannerId={bannerId}
          onSuccess={refetch}
          render={(params) => (
            <Link component="button" startIcon={<TrashIcon />} onClick={params.onClick}>
              Удалить
            </Link>
          )}
        />
      </ListControlGroup>
    </>
  )
}

export default Controls
