import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useMutation } from '@apollo/client'
import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'
import mergeWith from 'lodash/mergeWith'

import { IOrderStatus, IOrderStatusInput, ISettings } from 'types/types'
import Button from 'components/Button'
import DropMenu from 'components/DropMenu'
import PageContainer from 'components/PageContainer'
import ResultTitle from 'components/ResultTitle'
import Link from 'components/Link'
import Preloader from 'components/Preloader'
import FormControlsPanel from 'components/FormControlsPanel'
import useNotifyCms from 'hooks/useNotifyCms'
import useCmsParams from 'hooks/useCmsParams'
import useShortcuts from 'hooks/useShortcuts'
import withLeaveThisPageModal, { useLeavePage } from 'hocs/withLeaveThisPageModal'
import withErrorBoundary from 'hocs/withErrorBoundary'
import { buildGetSellerQuery, useGetSellerQuery } from 'graphql/queries/getSeller'
import {
  ISendEmailVerify,
  ISendEmailVerifyParams,
  SEND_EMAIL_VERIFY,
} from 'graphql/mutations/sendEmailVerify'
import {
  ISetSellerSettings,
  ISetSellerSettingsParams,
  SET_SELLER_SETTINGS,
} from 'graphql/mutations/setSellerSettings'
import { ISetSeller, ISetSellerParams, buildSetSetSellerQuery } from 'graphql/mutations/setSeller'
import nonNullable from 'utils/nonNullable'
import { DEFAULT_ORDER_STATUS_COLOR } from 'consts/defaultValues'

import checkDestroyItems from 'utils/checkDestroyItems'
import Colors from './components/Colors'
import OrdersEmail from './components/OrdersEmail'
import OrderStatusColor from './components/OrderStatusColor'
import AdditionalEmail from './components/AdditionalEmail'
import { IOrdersColorStatusFormValues } from './types'

type IColors = Required<
  Pick<
    ISettings,
    | 'bg_color'
    | 'bt_color'
    | 'hd_color'
    | 'lk_color'
    | 'main_color'
    | 'second_color'
    | 'tx_color'
    | 'fill_color'
  >
>

const defaultColors: IColors = {
  hd_color: '#000000', // headColor -- "Цвет заголовков"
  tx_color: '#000000', // textColor -- "Цвет текста"
  lk_color: '#0fa0da', // linkColor -- "Цвет ссылок"
  bg_color: '#ffffff', // bgColor -- "Цвет фона"
  bt_color: '#ffcb00', // btnColor -- "Цвет кнопок"
  main_color: '#ffffff', // headerColor -- "Цвет хедера"
  second_color: '#2d3f4f', // footerColor -- "Цвет футера"
  fill_color: '#000000', // fillColor -- "Цвет текста в кнопке с заливкой"
} as const

const defaultOrderColors: IOrdersColorStatusFormValues = [
  { title: '', color: DEFAULT_ORDER_STATUS_COLOR, out_of_limit: false },
]

const dummyPromise = Promise.resolve()

const SET_SELLER = buildSetSetSellerQuery(`
  id
  email
  email_verified_at
`)

const GET_SELLER = buildGetSellerQuery(`
  id
  name
  company
  email
  email_verified_at
  second_email
  second_email_verified_at
  settings {
    bg_color
    bt_color
    hd_color
    lk_color
    main_color
    second_color
    tx_color
    fill_color
    order_statuses {
      id
      title
      color
      out_of_limit
    }
  }
`)

const prepareInitialOrderStatuses = (order_statuses: IOrderStatus[]): IOrdersColorStatusFormValues =>
  order_statuses.map(({ id, color, title, out_of_limit }) => ({
    id,
    color: color ?? DEFAULT_ORDER_STATUS_COLOR,
    title: title ?? '',
    out_of_limit: out_of_limit ?? false,
  }))

const extractSettingsColors = (settings: ISettings) =>
  // bg_color, bt_color, hd_color, lk_color, main_color, second_color, tx_color, fill_color
  pick(settings, [
    'bg_color',
    'bt_color',
    'hd_color',
    'lk_color',
    'main_color',
    'second_color',
    'tx_color',
    'fill_color',
  ])

const colorsMergeHelper = (defaultValue: IColors, nullableValue: IColors) => {
  if (!nonNullable(nullableValue)) return defaultValue
  return undefined
}

// NOTE: any
const prepareSubmitOrderStatuses = (
  initOrderStatuses: any[],
  formOrderStatuses: IOrdersColorStatusFormValues,
): IOrderStatusInput[] => {
  const withDestroyedItems = checkDestroyItems(initOrderStatuses, formOrderStatuses).map(
    ({ _delete, ...record }) => ({
      ...record,
      flag: _delete ? 'delete' : undefined,
    }),
  )

  const noTitleOrders = withDestroyedItems.filter(({ title }) => title)

  return noTitleOrders
}

const More: React.FC = () => {
  const { sellerId } = useCmsParams()
  const addNotify = useNotifyCms()
  const { blocking, unblocking } = useLeavePage()
  const { registerShortcut } = useShortcuts()

  const [onSendEmailVerify, { loading: verifyLoading }] = useMutation<
    ISendEmailVerify,
    ISendEmailVerifyParams
  >(SEND_EMAIL_VERIFY)

  const onResendMailClick = useCallback(
    () => onSendEmailVerify({ variables: { seller_id: sellerId } }),
    [onSendEmailVerify, sellerId],
  )

  const onResendSecondEmailClick = useCallback(
    () => onSendEmailVerify({ variables: { seller_id: sellerId, second: true } }),
    [onSendEmailVerify, sellerId],
  )

  const {
    data: sellerData,
    loading,
    refetch,
  } = useGetSellerQuery(GET_SELLER, {
    variables: { id: sellerId },
    fetchPolicy: 'network-only',
  })

  const seller = useMemo(() => sellerData?.getSeller, [sellerData])

  const oldEmail = useMemo(() => seller?.email || '', [seller])
  const secondEmailOld = useMemo(() => seller?.second_email || '', [seller])

  const initColors = useMemo(() => {
    if (!seller?.settings) return {}
    return extractSettingsColors(seller.settings)
  }, [seller])

  const initOrderColorsStatuses = useMemo(() => {
    if (!seller?.settings?.order_statuses?.length) {
      return defaultOrderColors
    }

    return seller.settings.order_statuses
  }, [seller])

  const [email, setEmail] = useState<string>(oldEmail)
  const [verifiedAt, setVerifiedAt] = useState<string | null>(null)

  const [secondEmail, setSecondEmail] = useState<string>(oldEmail)
  const [secondEmailVerifiedAt, setSecondEmailVerifiedAt] = useState<string | null>(null)

  const [colors, setColors] = useState<IColors>(defaultColors)
  const [orderColorsStatuses, setOrderColorsStatuses] =
    useState<IOrdersColorStatusFormValues>(defaultOrderColors)

  const initData = useMemo(() => {
    const initDataRes = {
      email: oldEmail,
      secondEmail: secondEmailOld,
      colors: initColors,
      orderColorsStatuses: initOrderColorsStatuses,
    }
    console.log('initDataRes :>> ', initDataRes)
    return initDataRes
  }, [oldEmail, secondEmailOld, initColors, initOrderColorsStatuses])

  const currentState = useMemo(
    () => ({ email, secondEmail, colors, orderColorsStatuses }),
    [email, secondEmail, colors, orderColorsStatuses],
  )

  const isEqualData = useMemo(() => isEqual(initData, currentState), [initData, currentState])

  const [onSetSeller, { loading: setSellerLoading }] = useMutation<ISetSeller, ISetSellerParams>(SET_SELLER, {
    onCompleted(data) {
      setEmail(data.setSeller.email || '')
    },
  })

  const [onSetSellerSettings, { loading: setSellerSettingsLoading }] = useMutation<
    ISetSellerSettings,
    ISetSellerSettingsParams
  >(SET_SELLER_SETTINGS)

  const handleSubmit = useCallback(() => {
    const trySetSeller = () => {
      if (!seller || (email === oldEmail && secondEmail === secondEmailOld)) return dummyPromise

      return onSetSeller({
        variables: {
          id: sellerId,
          input: {
            name: seller.name,
            company: seller.company,
            email,
            second_email: secondEmail,
          },
        },
      })
    }

    Promise.all([
      trySetSeller(),
      onSetSellerSettings({
        variables: {
          seller_id: sellerId,
          input: {
            ...colors,
            order_statuses: prepareSubmitOrderStatuses(initData.orderColorsStatuses, orderColorsStatuses),
          },
        },
      }),
    ])
      .then(() => {
        addNotify('success')
        refetch()
      })
      .catch(() => addNotify('error'))
  }, [
    onSetSeller,
    onSetSellerSettings,
    sellerId,
    email,
    oldEmail,
    secondEmail,
    secondEmailOld,
    seller,
    colors,
    orderColorsStatuses,
    initData.orderColorsStatuses,
    addNotify,
    refetch,
  ])

  useEffect(() => {
    if (!sellerData?.getSeller?.settings) return
    const { order_statuses = [] } = sellerData.getSeller.settings

    const sellerColorSettings = extractSettingsColors(sellerData.getSeller.settings)

    const ensureDefaultColors = mergeWith(defaultColors, sellerColorSettings, colorsMergeHelper)

    setColors(ensureDefaultColors)
    setOrderColorsStatuses(
      order_statuses.length ? prepareInitialOrderStatuses(order_statuses) : defaultOrderColors,
    )
  }, [sellerData])

  useEffect(() => {
    if (!loading && sellerData) {
      setVerifiedAt(sellerData.getSeller?.email_verified_at || null)
      setSecondEmailVerifiedAt(sellerData.getSeller?.second_email_verified_at || null)
    }
  }, [sellerData, loading])

  useEffect(() => {
    if (!loading && sellerData) {
      setEmail(sellerData.getSeller?.email || '')
      setSecondEmail(sellerData.getSeller?.second_email || '')
    }
  }, [sellerData, loading])

  useEffect(() => {
    if (isEqualData) {
      unblocking()
    } else blocking()
  }, [isEqualData, unblocking, blocking])

  useEffect(() => {
    registerShortcut('ctrlS', handleSubmit)
  }, [registerShortcut, handleSubmit])

  if (loading || verifyLoading) return <Preloader />

  return (
    <PageContainer>
      <ResultTitle>Еще</ResultTitle>

      <DropMenu title="Формы">
        <OrdersEmail
          email={email}
          verifiedAt={verifiedAt}
          oldEmail={oldEmail}
          setEmail={setEmail}
          onResendMailClick={onResendMailClick}
        />
        <AdditionalEmail
          secondEmail={secondEmail}
          secondEmailVerifiedAt={secondEmailVerifiedAt}
          secondEmailOld={secondEmailOld}
          setSecondEmail={setSecondEmail}
          onResendSecondEmailClick={onResendSecondEmailClick}
        />
      </DropMenu>

      <OrderStatusColor statuses={orderColorsStatuses} setStatuses={setOrderColorsStatuses} />
      <Colors colors={colors} setColors={setColors} />

      <DropMenu title="HTML код внутри HEAD">
        <Link href={`/${sellerId}/more/scripts`}>
          <Button variant="outlined">Добавить код</Button>
        </Link>
      </DropMenu>

      <FormControlsPanel>
        <Button disabled={setSellerLoading || setSellerSettingsLoading} onClick={handleSubmit}>
          Сохранить
        </Button>
      </FormControlsPanel>
    </PageContainer>
  )
}

export default withErrorBoundary(withLeaveThisPageModal(memo(More)))
