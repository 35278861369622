import { DocumentNode, MutationHookOptions, gql, useMutation } from '@apollo/client'

import { IStore } from 'types/types'

export type ISetStore = {
  setStore: IStore
}

export type ISetStoreParams = {
  id: IPrimaryKey
  name: string
}

export const buildSetStoreQuery = (fields: string): DocumentNode => gql`
  mutation setStore($id: ID!, $name: String!) {
    setStore(id: $id, name: $name) {
      ${fields}
    }
  }
`

export const useSetStoreMutation = <TData = ISetStore, TOptions = ISetStoreParams>(
  fields: DocumentNode,
  options?: MutationHookOptions<TData, TOptions>,
) => useMutation<TData, TOptions>(fields, options)
