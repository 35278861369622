import React from 'react'

import {
  useSetSellerExpandedViewStatus,
  buildSetSellerExpandedViewStatusQuery,
} from 'graphql/mutations/setSellerExpandedViewStatus'
import { Seller } from 'types/types'
import Switch from 'components/Switch'
import Text from 'components/Typography'
import useCmsParams from 'hooks/useCmsParams'
import useNotifyCms from 'hooks/useNotifyCms'

type IExpandedBannerViewProps = {
  expanded_view: Seller['expanded_view']
}

const SET_SELLER_EXPANDED_VIEW_QUERY = buildSetSellerExpandedViewStatusQuery(`
  id
  expanded_view
`)

const ExpandedBannerView: React.FC<IExpandedBannerViewProps> = ({ expanded_view = false }) => {
  const { sellerId } = useCmsParams()
  const addNotify = useNotifyCms()

  const [onSetSellerExpandedView] = useSetSellerExpandedViewStatus(SET_SELLER_EXPANDED_VIEW_QUERY, {
    variables: {
      id: sellerId,
      expanded_view: !expanded_view,
    },
    onCompleted: () => addNotify('success'),
    onError: () => addNotify('error'),
    update(cache, { data: resData }) {
      console.log('resData :>> ', resData)
      if (!resData?.setSellerExpandedViewStatus) throw new Error('Something went wrong')

      cache.modify({
        fields: {
          getSeller(seller: Seller) {
            return {
              ...seller,
              ...resData.setSellerExpandedViewStatus,
            }
          },
        },
      })
    },
  })

  return (
    <Switch
      checked={expanded_view}
      onChange={() => onSetSellerExpandedView()}
      label={<Text>Расширенный вид главной</Text>}
      placement="right"
      gap="medium"
    />
  )
}

export default ExpandedBannerView
