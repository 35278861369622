import { gql, DocumentNode } from '@apollo/client'

import { IImportScheduleInput } from 'types/types'

type IStatus = {
  status: boolean
  message?: string
  data?: string
}

export type IAddImportSchedulesParams = {
  seller_id: IPrimaryKey
  imports: IImportScheduleInput[]
}

export type IAddImportSchedules = {
  addImportSchedules: IStatus
}

export const ADD_IMPORT_SCHEDULES = gql`
  mutation addImportSchedules($seller_id: ID!, $imports: [ImportScheduleInput]) {
    addImportSchedules(seller_id: $seller_id, imports: $imports) {
      status
      message
      data
    }
  }
`

export const buildAddImportSchedulesQuery = (fields: string): DocumentNode => gql`
  mutation addImportSchedules($seller_id: ID!, $imports: [ImportScheduleInput]) {
    addImportSchedules(seller_id: $seller_id, imports: $imports) {
      ${fields}
    }
  }
`
