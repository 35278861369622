import React, { useCallback, useState } from 'react'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'

import SuccessItemDialog from 'components/Dialogs/SuccessItemDialog'
import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import Link from 'components/Link'
import CopyIcon from 'components/Icons/CopyIcon'
import TrashIcon from 'components/Icons/TrashIcon'
import useBoolean from 'hooks/useBoolean'
import useNotifyCms from 'hooks/useNotifyCms'
import { useLeavePage } from 'hocs/withLeaveThisPageModal'
import { COPY_BASE_PRODUCT, ICopyBaseProduct, ICopyBaseProductParams } from 'graphql/mutations/copyProduct'
import {
  DELETE_BASE_PRODUCT,
  IDeleteBaseProduct,
  IDeleteBaseProductParams,
} from 'graphql/mutations/deleteProduct'
import useCmsParams from 'hooks/useCmsParams'

const title = {
  delete: 'Вы уверены, что хотите удалить товар?',
  copy: 'Вы действительно хотите создать копию товара?',
}

const Controls: React.FC = () => {
  const { sellerId, productId } = useCmsParams()
  const { push } = useHistory()
  const addNotify = useNotifyCms()
  const { unblocking } = useLeavePage()

  const [isCopySuccess, setIsCopySuccess] = useBoolean()
  const [confirmDialog, setConfirmDialog] = useState<'delete' | 'copy' | null>(null)

  const [onCopyProduct, { data: copyData }] = useMutation<ICopyBaseProduct, ICopyBaseProductParams>(
    COPY_BASE_PRODUCT,
    {
      variables: {
        id: productId,
      },
      onCompleted: () => {
        setConfirmDialog(null)
        setIsCopySuccess.on()
      },
      onError: () => addNotify('error'),
    },
  )

  const [onDeleteProduct] = useMutation<IDeleteBaseProduct, IDeleteBaseProductParams>(DELETE_BASE_PRODUCT, {
    variables: {
      id: productId,
    },
    onCompleted: () => {
      push(`/${sellerId}/catalog/products/`)
      addNotify('success')
    },
    onError: () => addNotify('error'),
  })

  const onConfirmDialogSubmit = useCallback(() => {
    unblocking()

    if (confirmDialog === 'copy') onCopyProduct()
    if (confirmDialog === 'delete') onDeleteProduct()
  }, [unblocking, onCopyProduct, onDeleteProduct, confirmDialog])

  const onToItemClick = useCallback(() => {
    push(`/${sellerId}/catalog/products/${copyData?.copyBaseProduct?.message}`)
  }, [push, sellerId, copyData])

  return (
    <>
      <SuccessItemDialog
        isOpen={isCopySuccess}
        onClose={setIsCopySuccess.off}
        title="Товар успешно скопирован"
        toItemText="К товару"
        onToItem={onToItemClick}
      />

      <ConfirmDialog
        isOpen={!!confirmDialog}
        title={confirmDialog === 'delete' ? title.delete : title.copy}
        submitBtnText={confirmDialog === 'delete' ? 'Да, удалить' : 'Да, создать'}
        onSubmit={onConfirmDialogSubmit}
        onClose={() => setConfirmDialog(null)}
      />

      <Link component="button" startIcon={<CopyIcon />} onClick={() => setConfirmDialog('copy')}>
        Создать копию
      </Link>
      <Link component="button" startIcon={<TrashIcon />} onClick={() => setConfirmDialog('delete')}>
        Удалить товар
      </Link>
    </>
  )
}

export default Controls
