import formikFieldEvent from './formikFieldEvent'

type IReturn = {
  target: {
    name: string
    value: string
  }
}

const floatFormat = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): IReturn => {
  const { value, name } = event.target
  // NOTE: не работает если точек больше одной (но не подряд)
  const nextValue = value.replace(/\.{2}/g, '').replace(/[^0-9.]/g, '')

  return formikFieldEvent(name, nextValue)
}

export default floatFormat
