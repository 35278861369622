import React, { memo } from 'react'

import { IStore } from 'types/types'

import Store from '../Store'

type IResultProps = {
  stores: IStore[]
  refetchList: () => void
}

const Result: React.FC<IResultProps> = ({ stores, refetchList }) => (
  <>
    {stores.map((store: IStore) => (
      <Store key={store.id} store={store} refetchList={refetchList} />
    ))}
  </>
)

export default memo(Result)
