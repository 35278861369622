export type IUpdateImportTime = '3' | '6' | '12' | '24' | '168'

export const updateImportTimeOptions: IOption<IUpdateImportTime>[] = [
  { id: '3', name: 'Каждые 3 ч.' },
  { id: '6', name: 'Каждые 6 ч.' },
  { id: '12', name: 'Каждые 12 ч.' },
  { id: '24', name: 'Каждый день' },
  { id: '168', name: 'Каждую неделю' },
]

export const initOptionId = 'default'

export const defaultImportSchemeOption: IOption = { id: initOptionId, name: 'По умолчанию' }
