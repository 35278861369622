import React from 'react'

import Text from 'components/Typography'
import TextInput from 'components/Inputs/TextInput'
import numberFormat from 'utils/formInputs/numberFormat'

import styles from './storesInput.module.scss'

type IStoresInputProps = {
  isDisabledVariantInputs: boolean
  name: string
  label: string
}

const StoresInput: React.FC<IStoresInputProps> = ({ isDisabledVariantInputs, name, label }) => (
  <div className={styles.container}>
    <Text className={styles.label} bold>
      {label}
    </Text>
    <TextInput
      name={`${name}.amount`}
      className={styles.input}
      disabled={isDisabledVariantInputs}
      label="Остаток на складе"
      placeholder="Введите количество"
      transform={numberFormat}
    />
    <TextInput
      name={`${name}.amount_wait`}
      className={styles.input}
      disabled={isDisabledVariantInputs}
      label="Ожидается поступление"
      placeholder="Введите количество"
      transform={numberFormat}
    />
    <TextInput
      name={`${name}.multiply`}
      className={styles.input}
      disabled={isDisabledVariantInputs}
      label="Кратность"
      placeholder="Введите значение"
      transform={numberFormat}
    />
  </div>
)

export default StoresInput
