import { DocumentNode, MutationHookOptions, gql, useMutation } from '@apollo/client'

import { IBanner, IBannerInput } from 'types/types'

export type IAddBanner = {
  addBanner: IBanner
}

export type IAddBannerParams = {
  seller_id: IPrimaryKey
  input?: IBannerInput
}

export const buildAddBannerQuery = (fields: string): DocumentNode => gql`
  mutation addBanner($seller_id: ID!, $input: BannerInput) {
    addBanner(seller_id: $seller_id, input: $input) {
      ${fields}
    }
  }
`

export const useAddBannerMutation = <TData = IAddBanner, TOptions = IAddBannerParams>(
  fields: DocumentNode,
  options?: MutationHookOptions<TData, TOptions>,
) => useMutation<TData, TOptions>(fields, options)
