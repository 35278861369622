import React from 'react'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import block from 'utils/block'

import styles from './popupList.module.scss'

type IPopupListProps = {
  className?: string
  isOpen: boolean
  element: React.ReactElement
  onClose: () => void
}

const b = block(styles)

const PopupList: React.FC<IPopupListProps> = ({ isOpen, className, element, onClose, children }) => (
  <ClickAwayListener onClickAway={onClose}>
    <div className={b('container', className)}>
      {element}
      <div className={b('list', { open: isOpen })}>{children}</div>
    </div>
  </ClickAwayListener>
)

export default PopupList
