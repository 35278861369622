import { Attribute as IAttribute } from 'types/types'

const convertAttributesToHash = (payload: IAttribute[]): Record<string, IAttribute> => {
  const response: Record<string, IAttribute> = {}

  for (let index = 0; index < payload.length; index++) {
    const attribute = payload[index]
    response[attribute.id] = attribute
  }

  return response
}

export default convertAttributesToHash
