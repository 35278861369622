import React, { FC, ReactNode, memo } from 'react'
import { Formik, FormikHelpers } from 'formik'

import Button from 'components/Button'
import FormControlsPanel from 'components/FormControlsPanel'
import LeavePageDirtyFormHelper from 'components/LeavePageDirtyFormHelper'
import AddSubmitShortcutFormHelper from 'components/AddSubmitShortcutFormHelper'
import withErrorBoundary from 'hocs/withErrorBoundary'
import DropMenu from 'components/DropMenu'
import Text from 'components/Typography'
import TextInput from 'components/Inputs/TextInput'
import CheckboxInput from 'components/Inputs/CheckboxInput'
import SwitchInput from 'components/Inputs/SwitchInput'

import FileInput from './components/FileInput'
import validationSchema from './utils/validationSchema'
import { IPriceListFormValues } from './types'

import styles from './priceListForm.module.scss'

type IPriceListFormProps = {
  submitLoading: boolean
  initialValue: IPriceListFormValues
  onSubmit: (values: IPriceListFormValues, helpers: FormikHelpers<IPriceListFormValues>) => void
  renderControls?: () => ReactNode
}

type IRow = {
  asideRight?: ReactNode
  children: ReactNode
}

const Row: FC<IRow> = ({ asideRight, children }) => (
  <div className={styles.row}>
    {children}
    <div className={styles.rowAsideRight}>{asideRight}</div>
  </div>
)

const PriceListForm: FC<IPriceListFormProps> = ({
  submitLoading,
  initialValue,
  onSubmit,
  renderControls = () => {},
}) => (
  <Formik
    enableReinitialize
    initialValues={initialValue}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    {({ values, handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <LeavePageDirtyFormHelper />
        <AddSubmitShortcutFormHelper />
        <DropMenu title="Настройки">
          <Row asideRight={<CheckboxInput name="default_price" label="Прайс по умолчанию" />}>
            <TextInput
              name="name"
              className={styles.input}
              required
              label="Название"
              placeholder="Введите название"
            />
          </Row>

          <Text className={styles.description} color="textSecondary" size="small">
            При выборе «прайс по умолчанию» цены из этого прайса будут базовыми для всех пользователей, <br />
            если не указано иное в разделе «ценовые группы»
          </Text>

          <SwitchInput
            name="show_prices"
            className={styles.switchInput}
            placement="right"
            gap="medium"
            label={<Text>Показывать в каталоге</Text>}
          />

          <FileInput downloadLink={values.downloadUrl} />
        </DropMenu>

        <FormControlsPanel className={styles.controlsPanel}>
          <Button type="submit" disabled={submitLoading}>
            Сохранить
          </Button>

          {renderControls()}
        </FormControlsPanel>
      </form>
    )}
  </Formik>
)

export default withErrorBoundary(memo(PriceListForm))
