import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import useCurrentSeller from 'hooks/useCurrentSeller'
import useLinkPublic from 'hooks/useLinkPublic'
// import Link from 'components/Link'

import styles from './sitesLinks.module.scss'

const SitesLinks = () => {
  const seller = useCurrentSeller()
  const { link } = useLinkPublic(seller)

  return (
    <div className={styles.container}>
      {seller.status ? (
        <a href={`http://${link}`} target="_blank" rel="noreferrer">
          <button type="button" className={styles.primaryBtn}>
            Перейти на сайт
          </button>
        </a>
      ) : (
        <RouterLink to={{ pathname: `/${seller.id}/general`, hash: 'status' }}>
          <button type="button" className={styles.primaryBtn}>
            Перейти на сайт
          </button>
        </RouterLink>
      )}

      <RouterLink to="/">
        <button type="button" className={styles.secondaryBtn}>
          Назад к сайтам
        </button>
      </RouterLink>
    </div>
  )
}

export default SitesLinks
