import React, { useCallback } from 'react'
import { Formik } from 'formik'
import { object, string } from 'yup'

import Button from 'components/Button'
import TextInput from 'components/Inputs/TextInput'
import { useAuth } from 'contexts/AuthProvider'
import ApiError from 'components/ApiError'
import Preloader from 'components/Preloader'

type IResetProps = {
  token?: string
}

type IFormValues = {
  password: string
  password2: string
}

const initValues: IFormValues = {
  password: '',
  password2: '',
}

const validationSchema = () =>
  object({
    password: string()
      .required('Обязательное поле')
      .min(8, 'Пароль должен содержать минимум 8 символов')
      .max(50, 'Пароль может содержать максимум 50 символов'),
    password2: string()
      .required('Обязательное поле')
      .min(8, 'Пароль должен содержать минимум 8 символов')
      .max(50, 'Пароль может содержать максимум 50 символов')
      .test('password', (password2, { createError, parent }) => {
        if (password2 !== parent.password) {
          return createError({ message: 'Пароли не совпадают' })
        }
        return true
      }),
  })

const Reset: React.FC<IResetProps> = ({ token }) => {
  const { resetPasswordError, resetPasswordLoading, resetPassword } = useAuth()

  const onSubmit = useCallback(
    (values: IFormValues) => {
      if (!token) {
        console.error('NO TOKEN')
        return
      }

      resetPassword({
        password: values.password,
        password_confirmation: values.password2,
        token,
      })
    },
    [token],
  )

  return (
    <Formik initialValues={initValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <TextInput
            name="password"
            label="Придумайте новый пароль"
            placeholder="Введите новый пароль"
            offset="default"
          />
          <TextInput
            name="password2"
            label="Повторите новый пароль"
            placeholder="Введите пароль еще раз"
            offset="default"
          />
          {resetPasswordLoading && <Preloader />}
          <ApiError error={resetPasswordError} />
          <Button type="submit" fullWidth>
            Сохранить
          </Button>
        </form>
      )}
    </Formik>
  )
}

export default Reset
