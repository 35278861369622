import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'

import { useAuth } from 'contexts/AuthProvider'
import Preloader from 'components/Preloader'

const Logout: React.FC = () => {
  const { logout, token } = useAuth()

  useEffect(() => {
    logout()
  }, [logout])

  if (!token) return <Redirect to="/auth/sign-in" />

  return <Preloader />
}

export default Logout
