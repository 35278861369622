import React from 'react'
import { NavLink } from 'react-router-dom'

import styles from './Item.module.scss'

type IItemProps = {
  title: string
  link: string
  verify: boolean
  onClick: () => void
}

const Item: React.FC<IItemProps> = ({ title, link, verify, onClick }) => (
  <li className={styles.Item}>
    <NavLink
      exact
      to={link}
      className={styles.Item__link}
      activeClassName={styles.Item__active}
      onClick={() => onClick()}
    >
      {title}
    </NavLink>
    {title === 'Профиль' && !verify && <div className={styles.RedDot} />}
  </li>
)

export default Item
