import { DocumentNode, gql, MutationHookOptions, useMutation } from '@apollo/client'

import { IApiAccess, IApiAccessInput } from 'types/types'

export type IAddApiAccesses = {
  addApiAccess: IApiAccess[]
}

export type IAddApiAccessesParams = {
  seller_id: IPrimaryKey
  input?: IApiAccessInput
}

export const buildAddApiAccessesQuery = (fields: string): DocumentNode => gql`
  mutation addApiAccess($seller_id: ID!, $input: ApiAccessInput) {
    addApiAccess(seller_id: $seller_id, input: $input) {
      ${fields}
    }
  }
`

export const useAddApiAccessMutation = <TData = IAddApiAccesses, TOptions = IAddApiAccessesParams>(
  fields: DocumentNode,
  options?: MutationHookOptions<TData, TOptions>,
) => useMutation<TData, TOptions>(fields, options)
