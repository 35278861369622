import { DocumentNode, gql, QueryHookOptions, useQuery } from '@apollo/client'

import type { Paginate, PCategories } from 'types/types'

export type IGetCategories = {
  getCategories: PCategories
}

export type IGetCategoriesParams = {
  seller_id: IPrimaryKey
  search?: string
  paginate?: Paginate
  group_id?: IPrimaryKey | null
  status?: boolean | null
}

export const GET_CATEGORIES = gql`
  query getCategories(
    $seller_id: ID!
    $group_id: ID
    $search: String
    $status: Boolean
    $paginate: Paginate
  ) {
    getCategories(
      seller_id: $seller_id
      group_id: $group_id
      search: $search
      status: $status
      paginate: $paginate
    ) {
      list {
        id
        name
        number
        group_id
        status
      }
      page
      pages
      elements
    }
  }
`

export const GET_CATEGORIES_NUMBERS = gql`
  query getCategoriesNumbers($seller_id: ID!) {
    getCategories(seller_id: $seller_id) {
      list {
        id
        number
      }
    }
  }
`

export const buildGetCategoriesQuery = (fields: string): DocumentNode => gql`
  query getCategories(
    $seller_id: ID!
    $group_id: ID
    $search: String
    $status: Boolean
    $paginate: Paginate
  ) {
    getCategories(
      seller_id: $seller_id
      group_id: $group_id
      search: $search
      status: $status
      paginate: $paginate
    ) {
      page
      pages
      elements
      list { ${fields} }
    }
  }
`

export const useGetCategoriesQuery = <TData = IGetCategories, TOptions = IGetCategoriesParams>(
  fields: DocumentNode,
  options?: QueryHookOptions<TData, TOptions>,
) => useQuery<TData, TOptions>(fields, options)
