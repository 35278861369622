import { gql } from '@apollo/client'

import { Status } from 'types/types'

export type IExportOrdersTable = {
  exportOrdersTable: Status
}

export type IExportOrdersTableParams = {
  seller_id: IPrimaryKey
  start: any
  end: any
}

export const EXPORT_ORDERS_TABLE = gql`
  mutation exportOrdersTable($seller_id: ID!, $start: Date, $end: Date) {
    exportOrdersTable(seller_id: $seller_id, start: $start, end: $end) {
      status
      message
    }
  }
`
