import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import Link from 'components/Link'
import useCmsParams from 'hooks/useCmsParams'
import TrashIcon from 'components/Icons/TrashIcon'
import DeleteClient from 'components/ModelControls/DeleteClient'

import styles from './controls.module.scss'

const Controls: React.FC = () => {
  const { sellerId, clientId } = useCmsParams()
  const { push } = useHistory()

  const onDeleteSuccess = useCallback(() => {
    push(`/${sellerId}/clients/list/`)
  }, [push, sellerId])

  return (
    <DeleteClient
      clientId={clientId}
      onSuccess={onDeleteSuccess}
      render={({ onClick }) => (
        <Link component="button" className={styles.deleteButton} startIcon={<TrashIcon />} onClick={onClick}>
          Удалить контрагента
        </Link>
      )}
    />
  )
}

export default Controls
