import { useEffect, useRef } from 'react'

export default function useInterval(callback: any, delay: number): void {
  const savedCallback = useRef<any>()

  useEffect(() => {
    if (!savedCallback) return
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current()
      }
    }
    if (delay !== null) {
      const timer = setInterval(tick, delay)
      return () => clearInterval(timer)
    }
  }, [delay])
}
