import {
  BaseProductAttribute,
  IBaseProduct,
  IProduct,
  ProductAttribute as IProductAttribute,
} from 'types/types'
import createUniqId from 'utils/createUniqId'
import extractId from 'utils/extractId'
import { defaultValueType } from 'consts/defaultValues'

import { IVariantsFormValues } from '../components/VariantsForm'

const defaultBaseVariantAttribute = { attributeId: '', valueIds: [] }
const prepareAttribute = (payload: BaseProductAttribute[]): IVariantsFormValues['variantAttribute'] => {
  const item = payload[0]

  if (!item) return defaultBaseVariantAttribute

  const { attribute, attribute_values } = item

  const response: IVariantsFormValues['variantAttribute'] = {
    attributeId: attribute.id,
    valueIds: attribute_values.map(extractId),
  }

  return response
}

const defaultProductAttribute = { attributeId: '', valueId: '' }
const prepareProductAttribute = (product_attributes: IProductAttribute[]) => {
  if (!product_attributes.length) return defaultProductAttribute.valueId

  const { attribute_value } = product_attributes[0]

  return attribute_value.id
}

const prepareProducts = (payload: IProduct[]): IVariantsFormValues['products'] => {
  const response = payload.map(
    ({
      id,
      height = defaultValueType,
      length = defaultValueType,
      volume = defaultValueType,
      weight = defaultValueType,
      width = defaultValueType,
      status = false,
      on_order = false,
      vendor_code = '',
      price = '',
      amount,
      amount_wait,
      multiply,
      number,
      stores,
      images = [],
      product_attributes,
    }) => ({
      id,
      tempUniqId: createUniqId(),
      height,
      length,
      volume,
      weight,
      width,
      status,
      on_order,
      vendor_code,
      price,
      number: number || 9999,
      amount: (amount ? amount.toString() : '') as any as number, // NOTE: any
      amount_wait: (amount_wait ? amount_wait.toString() : '') as any as number,
      multiply: (multiply ? multiply.toString() : '1') as any as number,
      stores: stores || [],
      images,
      product_attribute: prepareProductAttribute(product_attributes),
    }),
  )

  return response
}

const prepareInitialValues = (payload: IBaseProduct): IVariantsFormValues => {
  const { product_variant_attributes, products = [] } = payload

  const attribute = prepareAttribute(product_variant_attributes)
  const preparedProducts = prepareProducts(products)

  return {
    variantAttribute: attribute,
    products: preparedProducts,
  }
}

export default prepareInitialValues
