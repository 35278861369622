import React, { ReactNode, useMemo } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import clsx from 'clsx'

import styles from './SideBarButton.module.scss'

type SideBarButtonProps = {
  className?: string
  href: string
  title: string
  activeLinkList?: string[]
  asideRight?: ReactNode
  onClick?: () => void
}

const SideBarButton: React.FC<SideBarButtonProps> = ({
  className,
  href,
  title,
  activeLinkList,
  asideRight,
  onClick,
  children,
}) => {
  const { pathname } = useLocation()

  const activeLinksHash = useMemo(() => {
    if (!activeLinkList) return {}
    return activeLinkList.reduce<Record<string, boolean>>((acc, link) => {
      acc[link] = true
      return acc
    }, {})
  }, [activeLinkList])

  return (
    <NavLink
      to={href}
      className={clsx(styles.reference, className)}
      activeClassName={styles.active}
      isActive={() => activeLinksHash[pathname]}
      onClick={onClick}
    >
      <span className={styles.iconWrapper}>{children}</span>
      {title}
      <span>{asideRight}</span>
    </NavLink>
  )
}

export default SideBarButton
