import { DocumentNode, MutationHookOptions, gql, useMutation } from '@apollo/client'

import { Seller } from 'types/types'

export type ISetSellerSettingsParams = {
  id: IPrimaryKey
  expanded_view: boolean
}

export type ISetSellerSettings = {
  setSellerExpandedViewStatus: Seller
}

export const buildSetSellerExpandedViewStatusQuery = (fields: string): DocumentNode => gql`
  mutation setSellerExpandedViewStatus($id: ID!, $expanded_view: Boolean!) {
    setSellerExpandedViewStatus(id: $id, expanded_view: $expanded_view) {
      ${fields}
    }
  }
`

export const useSetSellerExpandedViewStatus = <
  TData = ISetSellerSettings,
  TOptions = ISetSellerSettingsParams,
>(
  fields: DocumentNode,
  options?: MutationHookOptions<TData, TOptions>,
) => useMutation<TData, TOptions>(fields, options)
