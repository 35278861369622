import React, { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'

import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import SuccessItemDialog from 'components/Dialogs/SuccessItemDialog'
import useBoolean from 'hooks/useBoolean'
import useNotifyCms from 'hooks/useNotifyCms'
import useCmsLinks from 'hooks/useCmsLinks'
import { COPY_PRICE_RULE, ICopyPriceRuleParams, ICopyPriceRule } from 'graphql/mutations/copyPriceRule'

import styles from './copyPriceRule.module.scss'

type IRenderParams = {
  onClick: () => void
}

type ICopyPriceRuleProps = {
  priceRuleId: IPrimaryKey
  onSuccess: () => void
  render: (params: IRenderParams) => void
}

const CopyPriceRule: React.FC<ICopyPriceRuleProps> = ({ priceRuleId, onSuccess, render }) => {
  const addNotify = useNotifyCms()
  const { priceRuleEditLink } = useCmsLinks()
  const { push } = useHistory()

  const [isOpen, setIsOpen] = useBoolean(false)
  const [isOpenSuccess, setIsOpenSuccess] = useBoolean(false)

  const [onCopyPriceRule, { data: copyPriceRuleData }] = useMutation<ICopyPriceRule, ICopyPriceRuleParams>(
    COPY_PRICE_RULE,
    {
      variables: {
        id: priceRuleId,
      },
      onCompleted: () => {
        setIsOpen.off()
        setIsOpenSuccess.on()
        onSuccess()
      },
      onError: () => addNotify('error'),
    },
  )

  const onToCopiedItemClick = useCallback(() => {
    const id = copyPriceRuleData?.copyPriceRule.message
    if (!id) return
    push(priceRuleEditLink(id))
  }, [push, priceRuleEditLink, copyPriceRuleData])

  return (
    <>
      <SuccessItemDialog
        isOpen={isOpenSuccess}
        className={styles.successItemDialog}
        title="Ценовая группа успешно скопирована"
        toItemText="К ценовой группе"
        onClose={setIsOpenSuccess.off}
        onToItem={onToCopiedItemClick}
      />

      <ConfirmDialog
        isOpen={isOpen}
        title="Вы действительно хотите создать копию ценовой группы?"
        submitBtnText="Да, создать"
        onSubmit={() => onCopyPriceRule()}
        onClose={setIsOpen.off}
      />

      {render({ onClick: setIsOpen.on })}
    </>
  )
}

export default CopyPriceRule
