import React, { FC, useCallback, useMemo } from 'react'
import { useField } from 'formik'

import useBoolean from 'hooks/useBoolean'
import { H4 } from 'components/Typography'
import Link from 'components/Link'
import SelectInput from 'components/Inputs/SelectInput'
import { IPriceList } from 'types/types'
import UploadIcon from 'components/Icons/UploadIcon'
import StarIcon from 'components/Icons/StarIcon'
import LoadPriceListDialog from 'components/Dialogs/LoadPriceListDialog'
import formikFieldEvent from 'utils/formInputs/formikFieldEvent'
import { usePriceRuleForm } from 'components/forms/PriceRuleForm/contexts'

import styles from './priceList.module.scss'

type IPriceListProps = {
  name: string
}

const PriceList: FC<IPriceListProps> = ({ name }) => {
  const { priceLists, priceListLoading, refetchPriceLists } = usePriceRuleForm()

  const [isOpen, setIsOpen] = useBoolean()

  const priceListIdName = useMemo(() => `${name}.pricelistId`, [name])

  const [{ onChange }] = useField<IPrimaryKey>(priceListIdName)

  const onSuccess = useCallback(
    (newPriceList: IPriceList) => {
      setIsOpen.off()
      refetchPriceLists()
      onChange(formikFieldEvent(priceListIdName, newPriceList.id))
    },
    [setIsOpen, refetchPriceLists, onChange, priceListIdName],
  )

  return (
    <div className={styles.container}>
      <div className={styles.priceListGroup}>
        <H4 className={styles.label}>
          Прайс лист <StarIcon />
        </H4>
        <SelectInput
          name={priceListIdName}
          placeholder="Выберите прайс лист"
          loading={priceListLoading}
          options={priceLists}
        />
      </div>
      <Link
        className={styles.uploadButton}
        component="button"
        startIcon={<UploadIcon />}
        onClick={setIsOpen.on}
      >
        Загрузить прайс лист из файла
      </Link>

      <LoadPriceListDialog isOpen={isOpen} onClose={setIsOpen.off} onSuccess={onSuccess} />
    </div>
  )
}

export default PriceList
