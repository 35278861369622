import React, { createContext, useContext, useMemo } from 'react'
import { ApolloError } from '@apollo/client'

import { buildGetSellerQuery, useGetSellerQuery } from 'graphql/queries/getSeller'
import useCmsParams from 'hooks/useCmsParams'
import { Seller } from 'types/types'

type ISellerSettingsCMS = Pick<Required<Seller>['settings'], 'currency'>

export type ISellerCMS = Pick<
  Seller,
  | 'id'
  | 'status'
  | 'status_index'
  | 'stores_status'
  | 'name'
  | 'company'
  | 'email'
  | 'email_verified_at'
  | 'domain'
  | 'ext_domain'
  | 'ext_domain_status'
  | 'head_scripts'
> & {
  settings?: ISellerSettingsCMS
}

type ISellerContext = {
  loading: boolean
  error: ApolloError | undefined
  seller: ISellerCMS | null
  refetch: () => void
}

const initial = {
  loading: false,
  error: undefined,
  seller: null,
  refetch: () => {},
}

const GET_SELLER = buildGetSellerQuery(`
  id
  status
  status_index
  stores_status
  name
  company
  email
  email_verified_at
  domain 
  ext_domain
  ext_domain_status 
  head_scripts
  settings {
    currency
  }
`)

export const SellersContext = createContext<ISellerContext>(initial)

export const useSeller = () => useContext(SellersContext)

const SellerProvider: React.FC = ({ children }) => {
  const { sellerId } = useCmsParams()

  const { data, loading, error, refetch } = useGetSellerQuery<{ getSeller: ISellerCMS }>(GET_SELLER, {
    variables: { id: sellerId },
    fetchPolicy: 'network-only',
  })

  const value: ISellerContext = useMemo(
    () => ({
      seller: data ? data.getSeller : null,
      loading,
      error,
      refetch,
    }),
    [data, loading, error, refetch],
  )

  return <SellersContext.Provider value={value}>{children}</SellersContext.Provider>
}

export default SellerProvider
