import { DocumentNode, gql, useQuery, QueryHookOptions } from '@apollo/client'

import { ICompany } from 'types/types'

export type IGetCompany = {
  getCompany: ICompany
}

export type IGetCompanyParams = {
  id: IPrimaryKey
}

export const buildGetCompanyQuery = (fields: string): DocumentNode => gql`
  query getCompany($id: ID!) {
    getCompany(id: $id) {
      ${fields}
    }
  }
`

export const useGetCompanyQuery = <TData = IGetCompany, TOptions = IGetCompanyParams>(
  fields: DocumentNode,
  options?: QueryHookOptions<TData, TOptions>,
) => useQuery<TData, TOptions>(fields, options)
