import { gql } from '@apollo/client'

import { Status } from 'types/types'

export type IDeleteClient = {
  delClient: Status
}

export type IDeleteClientParams = {
  id: IPrimaryKey
}

export const DELETE_CLIENT = gql`
  mutation delClient($id: ID!) {
    delClient(id: $id) {
      status
      message
    }
  }
`
