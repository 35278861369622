import { Overrides } from '@material-ui/core/styles/overrides'

import { active, grey20, greyBack, primary, primaryActive, white } from '../colors'

const button: Overrides['MuiButton'] = {
  root: {
    flexShrink: 0,
    textTransform: 'none',
    fontSize: '1rem',
    fontWeight: 'bold',
    lineHeight: '22px',
    letterSpacing: 'unset',

    // '&.Mui-focusVisible': {
    //   color: 'orange',
    // },
  },

  startIcon: {
    marginLeft: '0px',
  },
  endIcon: {
    marginRight: '0px',
  },

  contained: {
    padding: '9px 24px',
    boxShadow: 'none',

    '&:hover, &:focus': {
      boxShadow: 'none',
    },

    '&.withIcon': {
      padding: '9px 18px',
    },

    '&$disabled': {
      color: 'var(--color-grey-40)',
      backgroundColor: grey20,
    },
  },

  containedPrimary: {
    backgroundColor: primary,

    '&:hover, &.withHover': {
      backgroundColor: primaryActive,
    },
  },

  containedSecondary: {
    backgroundColor: active,

    '&:hover, &.withHover': {
      backgroundColor: active,
    },
  },

  outlined: {
    padding: '8px 24px',

    '&.withIcon': {
      padding: '8px 18px',
    },

    '&$disabled': {
      color: '#999CA6',
      backgroundColor: grey20,
      border: `1px solid ${grey20}`,
    },
  },

  outlinedPrimary: {
    backgroundColor: white,
    border: `1px solid ${grey20}`,

    '&:hover, &.withHover': {
      border: `1px solid ${grey20}`,
      backgroundColor: greyBack,
    },
  },
}

export default button
