import React from 'react'

import { ReactComponent as Union } from 'assets/img/Union.svg'
import { H1 } from 'components/Typography'
import Link from 'components/Link'

import styles from './authHeader.module.scss'

const AuthHeader: React.FC = () => (
  <Link external className={styles.container} href="https://sellty.ru">
    <div className={styles.content}>
      <Union className={styles.image} />
      <H1 className={styles.title}>Sellty</H1>
    </div>
  </Link>
)

export default AuthHeader
