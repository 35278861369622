import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import Link from 'components/Link'
import useCmsParams from 'hooks/useCmsParams'
import TrashIcon from 'components/Icons/TrashIcon'
import DeletePriceList from 'components/ModelControls/DeletePriceList'
import useCmsLinks from 'hooks/useCmsLinks'
import { useLeavePage } from 'hocs/withLeaveThisPageModal'

const Controls: React.FC = () => {
  const { priceListId } = useCmsParams()
  const { push } = useHistory()
  const { priceListsListLink } = useCmsLinks()
  const { unblocking } = useLeavePage()

  const onDeleteSuccess = useCallback(() => {
    unblocking()
    push(priceListsListLink)
  }, [unblocking, push, priceListsListLink])

  return (
    <DeletePriceList
      priceListId={priceListId}
      onSuccess={onDeleteSuccess}
      render={({ onClick }) => (
        <Link component="button" startIcon={<TrashIcon />} onClick={onClick}>
          Удалить прайс лист
        </Link>
      )}
    />
  )
}

export default Controls
