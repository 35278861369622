import React, { useMemo } from 'react'
import { useQuery } from '@apollo/client'

import TextField from 'components/TextField'
import SelectField from 'components/SelectField'
import Button from 'components/Button'
import SearchIcon from 'components/Icons/SearchIcon'
import { buildGetCategoriesQuery, IGetCategories, IGetCategoriesParams } from 'graphql/queries/getCategories'
import useCmsParams from 'hooks/useCmsParams'

import { IRelatedProductsFilters } from '../../types'

import styles from './filters.module.scss'

type IFiltersProps = {
  filters: IRelatedProductsFilters
  onFiltersChange: (filters: IRelatedProductsFilters) => void
  onClose: () => void
  onSave: () => void
}

const GET_CATEGORIES = buildGetCategoriesQuery(`
  id
  name
`)

const Filters: React.FC<IFiltersProps> = ({ filters, onFiltersChange, onClose, onSave }) => {
  const { search, categoryId } = filters

  const { sellerId } = useCmsParams()

  const { data, loading } = useQuery<IGetCategories, IGetCategoriesParams>(GET_CATEGORIES, {
    variables: {
      seller_id: sellerId,
    },
    fetchPolicy: 'network-only',
  })

  const categoryOptions = useMemo(() => {
    if (!data) return []
    return data.getCategories.list
  }, [data])

  const onSearchChange = (event: InputChangeEvent) => {
    onFiltersChange({ ...filters, search: event.target.value })
  }

  const onCategoryChange = (payload: string) => onFiltersChange({ ...filters, categoryId: payload })
  const onCategoryClear = () => onFiltersChange({ ...filters, categoryId: null })

  return (
    <div className={styles.container}>
      <TextField
        placeholder="Введите название или артикул"
        value={search}
        onChange={onSearchChange}
        endAdornment={<SearchIcon />}
      />
      <SelectField
        placeholder="Выберите категорию"
        loading={loading}
        value={categoryId}
        options={categoryOptions}
        onChange={onCategoryChange}
        onClear={onCategoryClear}
      />
      <Button variant="outlined" onClick={onClose}>
        Отменить
      </Button>
      <Button className={styles.saveButton} onClick={onSave}>
        Сохранить
      </Button>
    </div>
  )
}

export default Filters
