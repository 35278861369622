import React from 'react'
import { useField } from 'formik'

import { IStores } from 'types/types'

import StoresInput from '../StoresInput'

import styles from './storesInputs.module.scss'

type IStoresInputsProps = {
  isDisabledVariantInputs: boolean
}

const name = 'stores'

const StoresInputs: React.FC<IStoresInputsProps> = ({ isDisabledVariantInputs }) => {
  const [{ value: stores }] = useField<IStores[]>(name)

  return (
    <div className={styles.container}>
      {stores.map((store, index) => (
        <StoresInput
          key={store.id}
          isDisabledVariantInputs={isDisabledVariantInputs}
          name={`${name}[${index}]`}
          label={store.name}
        />
      ))}
    </div>
  )
}

export default StoresInputs
