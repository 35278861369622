import React from 'react'

import DropMenu from 'components/DropMenu'
import TextInput from 'components/Inputs/TextInput'
import LimitText from 'components/LimitText'
import Text from 'components/Typography'

import { IGeneralFormValues } from 'pages/General/types'

import TimePickerInput from './components/TimePickerInput'

import styles from './contactsInfo.module.scss'

type IContactsInfoProps = {
  values: IGeneralFormValues
}

export type SetTimesWorksPropsType = {
  timesName: string
  time: string[]
}

const addressInputProps = { maxLength: 200 }
const phoneInputProps = { maxLength: 50 }

const ContactsInfo: React.FC<IContactsInfoProps> = ({ values }) => (
  <DropMenu title="Контактная информация">
    <div className={styles.contentGroup}>
      <TextInput
        name="address"
        label="Адрес магазина или склада"
        placeholder="Введите адрес"
        margin="default"
        inputProps={addressInputProps}
        labelAsideRight={<LimitText value={values.address} limit={200} />}
      />
      <TextInput
        name="phone"
        label="Телефон"
        placeholder="Введите адрес"
        margin="default"
        inputProps={phoneInputProps}
      />

      <div className={styles.workHoursGroup}>
        <Text className={styles.subtitle}>Часы работы</Text>
        <TimePickerInput name="settings.time_works.workDays" label="Пн - Пт" />
        <TimePickerInput name="settings.time_works.weekend" label="Сб - Вс" />
      </div>

      <Text className={styles.subtitle}>Ссылки на соц. сети</Text>
      <TextInput name="settings.socials.vk" label="ВКонтакте" margin="default" />
      <TextInput name="settings.socials.telegram" label="Telegram" margin="default" />
      <TextInput name="settings.socials.youTube" label="YouTube" margin="default" />
      <TextInput name="settings.socials.whatsApp" label="WhatsApp" />
    </div>
  </DropMenu>
)

export default ContactsInfo
