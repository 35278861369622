import { object, string, array } from 'yup'

import { REQUIRED_MESSAGE, WRONG_EMAIL_FORMAT, WRONG_INN_FORMAT } from 'consts/errorMessages'

const validationSchema = object({
  email: string().required(REQUIRED_MESSAGE).email(WRONG_EMAIL_FORMAT),
  companies: array()
    .of(
      object({
        name: string().required(REQUIRED_MESSAGE),
        inn: string().test('inn', (inn, { createError }) => {
          if (!inn) return true
          if (inn.length === 9 || inn.length === 10 || inn.length === 12) return true
          return createError({ message: WRONG_INN_FORMAT })
        }),
      }),
    )
    .test('companies', 'Необходимо добавить хотя бы одну компанию', (companies) => {
      if (!companies?.length) return false
      return true
    }),
})

export default validationSchema
