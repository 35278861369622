import { DocumentNode, QueryHookOptions, gql, useQuery } from '@apollo/client'

import { IOrder } from 'types/types'

export type IGetOrder = {
  getOrder: IOrder
}

export type IGetOrderParams = {
  id: IPrimaryKey
}

export const buildGetOrderQuery = (fields: string): DocumentNode => gql`
  query getOrder($id: ID!) {
    getOrder(id: $id) {
      ${fields}
    }
  }
`

export const useGetOrderQuery = <TData = IGetOrder, TOptions = IGetOrderParams>(
  fields: DocumentNode,
  options?: QueryHookOptions<TData, TOptions>,
) => useQuery<TData, TOptions>(fields, options)
