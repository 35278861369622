import React, { useCallback } from 'react'
import { useField } from 'formik'

import Button from 'components/Button'
import Text from 'components/Typography'
import RelatadProductsDialog from 'components/Dialogs/RelatadProductsDialog'
import useBoolean from 'hooks/useBoolean'
import formikFieldEvent from 'utils/formInputs/formikFieldEvent'

import DiscountInputs from '../DiscountInputs'

import styles from './product.module.scss'

type IProductProps = {
  name: string
  index: number
}

const Product: React.FC<IProductProps> = ({ name, index }) => {
  const [isOpen, setIsOpen] = useBoolean()

  const [{ value: localValueIds, onChange }] = useField<IPrimaryKey[]>(
    `${name}.parameters[${index}].localValueIds`,
  )
  const [{ value: priceListId }] = useField<IPrimaryKey>(`${name}.pricelistId`)

  const onSubmit = useCallback(
    (data: IPrimaryKey[]) => {
      onChange(formikFieldEvent(`${name}.parameters[${index}].localValueIds`, data))
      setIsOpen.off()
    },
    [name, index, setIsOpen, onChange],
  )

  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <Text className={styles.label}>Выбранные товары</Text>
        <div className={styles.contentGroup}>
          <Button variant="outlined" onClick={setIsOpen.on}>
            Выбранные товары {localValueIds.length > 0 ? `(${localValueIds.length})` : null}
          </Button>
          <Text color="textSecondary" size="small">
            К выбранным товарам будут применены <br /> особые условия
          </Text>
        </div>
      </div>

      <DiscountInputs name={`${name}.parameters[${index}]`} />

      <RelatadProductsDialog
        isOpen={isOpen}
        priceListId={priceListId}
        initialProductIds={localValueIds}
        onClose={setIsOpen.off}
        onSubmit={onSubmit}
      />
    </div>
  )
}

export default Product
