import { DocumentNode, gql, useQuery, QueryHookOptions } from '@apollo/client'

import { PContact, Paginate } from 'types/types'

export type IGetContacts = {
  getContacts: PContact
}

export type IGetContactsParams = {
  seller_id: IPrimaryKey
  search?: string | null
  paginate?: Paginate
}

export const buildGetContactsQuery = (fields: string): DocumentNode => gql`
  query getContacts($seller_id: ID!, $search: String, $paginate: Paginate) {
    getContacts(seller_id: $seller_id, search: $search, paginate: $paginate) {
      elements
      list { ${fields} }
      page
      pages
    }
  }
`

export const useGetContactsQuery = (
  fields: DocumentNode,
  options?: QueryHookOptions<IGetContacts, IGetContactsParams>,
) => useQuery<IGetContacts, IGetContactsParams>(fields, options)
