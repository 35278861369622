import { IGeneralFormValues } from '../types'

const defaultFormValues: IGeneralFormValues = {
  name: '',
  company: '',
  industry: '',
  description: '',
  logo: {},
  icon: {},
  address: '',
  phone: '',
  settings: {
    time_works: {
      workDays: { name: 'workDays', start: '00:00', end: '00:00' },
      weekend: { name: 'weekend', start: '00:00', end: '00:00' },
    },
    socials: {
      vk: '',
      telegram: '',
      youTube: '',
      whatsApp: '',
    },
  },
}

export default defaultFormValues
