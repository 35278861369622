import { DocumentNode, gql } from '@apollo/client'

import { IBaseProductInput, IBaseProduct } from 'types/types'

export type IAddBaseProduct = {
  addBaseProduct: IBaseProduct
}

export type IAddBaseProductParams = {
  seller_id: IPrimaryKey
  input: IBaseProductInput
}

export const buildAddBaseProductQuery = (fields: string): DocumentNode => gql`
  mutation addBaseProduct($seller_id: ID!, $input: BaseProductInput) {
    addBaseProduct(seller_id: $seller_id, input: $input) {
      ${fields}
    }
  }
`
