import { gql } from '@apollo/client'

import { Status } from 'types/types'

export type ISetSellerDomain = {
  setSellerDomain: Status
}

export type ISetSellerDomainParams = {
  id: IPrimaryKey
  domain: string
}

// NOTE: В отличае от setSellerStatus и setSellerStatusIndex возвращает статус, по хорошему стоит привести к одному виду
export const SET_SELLER_DOMAIN = gql`
  mutation setSellerDomain($id: ID!, $domain: String!) {
    setSellerDomain(id: $id, domain: $domain) {
      data
      message
      status
    }
  }
`
