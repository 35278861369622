import { DocumentNode, gql } from '@apollo/client'

import type { Attribute, AttributeInput } from 'types/types'

export type ISetAttribute = {
  setAttribute: Attribute
}

export type ISetAttributeParams = {
  id: IPrimaryKey
  input?: AttributeInput
}

export const SET_ATTRIBUTE = gql`
  mutation setAttribute($id: ID!, $input: AttributeInput) {
    setAttribute(id: $id, input: $input) {
      id
      seller_id
      name
      description
      filters
      attribute_values {
        id
        attribute_id
        value
        select
      }
      number
      created_at
      updated_at
    }
  }
`

export const buildSetAttributeQuery = (fields: string): DocumentNode => gql`
  mutation setAttribute($id: ID!, $input: AttributeInput) {
    setAttribute(id: $id, input: $input) {
      ${fields}
    }
  }
`
