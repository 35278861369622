import React, { useCallback, useState } from 'react'
import { FormikHelpers } from 'formik'

import { useGetSellerQuery } from 'graphql/queries/getSeller'
import { useSetSellerSettings } from 'graphql/mutations/setSellerSettings'
import DropMenu from 'components/DropMenu'
import Preloader from 'components/Preloader'
import ApiError from 'components/ApiError'
import ResultTitle from 'components/ResultTitle'
import PageContainer from 'components/PageContainer'
import ErrorText from 'components/ErrorText'
import CommonForm, { ICommonFormValues } from 'components/forms/CommonForm'
import useCmsParams from 'hooks/useCmsParams'
import useNotifyCms from 'hooks/useNotifyCms'
import { ISettings, Seller } from 'types/types'
import convertToFormErrors from 'utils/convertToFormErrors'

import ItemGroup from './components/ItemGroup'
import EmailNotify from './components/EmailNotify'
import PublishStore from './components/PublishStore'
import StatusIndex from './components/StatusIndex'
import HideStoreAmount from './components/HideStoreAmount'
import ShowVariantsSwitch from './components/ShowVariantsSwitch'
import AuthAvailability from './components/AuthAvailability'
import OnlyAuthorisedOrder from './components/OnlyAuthorisedOrder'
import Currencies from './components/Currencies'
import ClearCatalog from './components/ClearCatalog'
import ExpandedBannerView from './components/ExpandedBannerView'
import IndividualOrder from './components/IndividualOrder'
import { GET_SELLER_QUERY, SET_SELLER_SETTINGS_QUERY } from './queries'
import prepareSubmitValues from './utils/prepareSubmitValues'

const defaultValues: ICommonFormValues = {
  notification: {
    status: false,
    title: '',
    text: '',
  },
}

const prepareInitialValues = (seller: Seller): ICommonFormValues => {
  const notification = seller.settings?.notification
  if (!notification) return defaultValues
  // todo: add default values
  const { status, text, title } = notification
  return {
    notification: {
      status: Boolean(status),
      title: title || '',
      text: text || '',
    },
  }
}

const prepareSettingsInitialValues = (settings: ISettings): ICommonFormValues => {
  const notification = settings?.notification
  if (!notification) return defaultValues
  // todo: add default values
  const { status, text, title } = notification
  return {
    notification: {
      status: Boolean(status),
      title: title || '',
      text: text || '',
    },
  }
}

const CommonOptions: React.FC = () => {
  const { sellerId } = useCmsParams()
  const addNotify = useNotifyCms()

  const [initialFormValues, setInitialFormValues] = useState<ICommonFormValues>(defaultValues)

  const { data, loading, error } = useGetSellerQuery(GET_SELLER_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      id: sellerId,
    },
    onCompleted: (res) => setInitialFormValues(prepareInitialValues(res.getSeller)),
  })

  const [onSetSellerSettings, { loading: settingLoading }] = useSetSellerSettings(SET_SELLER_SETTINGS_QUERY, {
    update(cache, { data: resData }) {
      if (!resData?.setSellerSettings) throw new Error('Something went wrong')

      cache.modify({
        fields: {
          getSeller(seller: Seller) {
            return {
              ...seller,
              settings: {
                ...seller.settings,
                ...resData.setSellerSettings,
              },
            }
          },
        },
      })
    },
  })

  const onSubmit = useCallback(
    (values: ICommonFormValues, { setErrors, resetForm }: FormikHelpers<ICommonFormValues>) =>
      onSetSellerSettings({
        variables: {
          seller_id: sellerId,
          input: prepareSubmitValues(values),
        },
      })
        .then((response) => {
          const nextData = response.data?.setSellerSettings
          if (!nextData) throw new Error('No setClient data')

          const initValues = prepareSettingsInitialValues(nextData)
          resetForm({ values: initValues })
          setInitialFormValues(initValues)
          addNotify('success')
        })
        .catch((resError) => {
          addNotify('error')
          const errors = convertToFormErrors(resError)
          if (errors) setErrors(errors)
        }),
    [onSetSellerSettings, addNotify, sellerId],
  )

  const seller = data?.getSeller

  if (loading) {
    return (
      <PageContainer>
        <ResultTitle>Общие</ResultTitle>
        <Preloader />
      </PageContainer>
    )
  }

  if (error) {
    return (
      <PageContainer>
        <ResultTitle>Общие</ResultTitle>
        <ApiError error={error} />
      </PageContainer>
    )
  }

  if (!seller) {
    return <ErrorText>Такого магазина не существует</ErrorText>
  }

  if (!seller.settings) {
    return <ErrorText>Нет настроек</ErrorText>
  }

  return (
    <PageContainer>
      <ResultTitle>Общие</ResultTitle>

      <DropMenu title="Настройки магазина">
        <ItemGroup title="Валюта">
          <Currencies settings={seller.settings} />
        </ItemGroup>
        <ItemGroup title="Общие настройки">
          <PublishStore seller={seller} />
          <StatusIndex seller={seller} />
          <EmailNotify settings={seller.settings} />
          <ExpandedBannerView expanded_view={seller.expanded_view} />
          <IndividualOrder individual_order={seller.individual_order} />
        </ItemGroup>
        <ItemGroup title="Для неавторизованных пользователей">
          <AuthAvailability seller={seller} />
          <OnlyAuthorisedOrder seller={seller} />
        </ItemGroup>
        <ItemGroup title="Настройки товаров">
          <HideStoreAmount seller={seller} />
          <ShowVariantsSwitch seller={seller} />
        </ItemGroup>
      </DropMenu>

      <CommonForm initialValue={initialFormValues} submitLoading={settingLoading} onSubmit={onSubmit}>
        <ClearCatalog />
      </CommonForm>
    </PageContainer>
  )
}

export default CommonOptions
