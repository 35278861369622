import React, { memo, useMemo } from 'react'
import map from 'lodash/map'

import { IOrderProduct } from 'types/types'
import DropMenu from 'components/DropMenu'
import Text from 'components/Typography'
import withErrorBoundary from 'hocs/withErrorBoundary'
import createUniqId from 'utils/createUniqId'
import useCurrentSeller from 'hooks/useCurrentSeller'
import renderCurrencySymbol from 'utils/renderCurrencySymbol'

import ProductItem from './components/ProductItem'

import style from './OrderDropMenu.module.scss'

interface IOrderDropMenuProps {
  products: IOrderProduct[]
}

const OrderDropMenu: React.FC<IOrderDropMenuProps> = ({ products }) => {
  const { settings } = useCurrentSeller()

  const totalPrice = useMemo(
    () => products.reduce((acc, item) => Number(item.price) * 100 * item.amount + acc, 0),
    [products],
  )

  const groupedProducts = useMemo(
    () =>
      products.reduce((acc, item) => {
        if (!item.vendor_code) {
          acc[createUniqId()] = [item]
          return acc
        }
        const currentArr = acc[item.vendor_code]
        if (currentArr) {
          acc[item.vendor_code] = currentArr.concat([item])
          return acc
        }

        acc[item.vendor_code] = [item]
        return acc
      }, {} as Record<string, IOrderProduct[]>),
    [products],
  )

  return (
    <DropMenu title="Состав заказа" resetPadding>
      <div className={style.headContainer}>
        <Text className={style.OrderComposition__article}>Название, артикул</Text>
        <Text className={style.OrderComposition__onOrder}>Статус</Text>
        <Text className={style.OrderComposition__price}>Цена</Text>
        <Text className={style.OrderComposition__amount}>Количество</Text>
        <Text className={style.OrderComposition__totalPrice}>Стоимость</Text>
      </div>

      {map(groupedProducts, (productGroup) => (
        <ProductItem key={productGroup[0].id} products={productGroup} />
      ))}

      <div className={style.totalGroup}>
        <Text className={style.totalPrice}>Итого:</Text>
        <Text className={style.totalPrice}>
          {(totalPrice / 100).toFixed(2).replace('.00', '')} {renderCurrencySymbol(settings?.currency)}
        </Text>
      </div>
    </DropMenu>
  )
}

export default withErrorBoundary(memo(OrderDropMenu))
