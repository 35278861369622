import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import Preloader from 'components/Preloader'
import CmsLayout from 'components/CmsLayout'

import { useSeller } from 'contexts/SellerProvider'
import { useAuth } from 'contexts/AuthProvider'

const CmsRoute: React.FC<any> = ({ component: Component, ...rest }) => {
  const { loading, seller, error } = useSeller()
  const { logout } = useAuth()

  if (error) {
    logout()
    return <Redirect to="/" />
  }

  if (!loading && !seller) return <Redirect to="/404" />

  return (
    <Route
      {...rest}
      render={(props) => {
        if (loading) return <Preloader />

        return (
          <CmsLayout>
            <Component {...props} />
          </CmsLayout>
        )
      }}
    />
  )
}

export default CmsRoute
