import { DocumentNode, QueryHookOptions, gql, useQuery } from '@apollo/client'

import { PClients, Paginate } from 'types/types'

export type IGetClients = {
  getClients: PClients
}

export type IGetClientsParams = {
  seller_id: IPrimaryKey
  search?: string | null
  paginate?: Paginate
}

export const buildGetClientsQuery = (fields: string): DocumentNode => gql`
  query getClients($seller_id: ID!, $search: String, $paginate: Paginate) {
    getClients(seller_id: $seller_id, search: $search, paginate: $paginate) {
      elements
      list { ${fields} }
      page
      pages
    }
  }
`

export const useGetClientsQuery = <TData = IGetClients, TOptions = IGetClientsParams>(
  fields: DocumentNode,
  options?: QueryHookOptions<TData, TOptions>,
) => useQuery<TData, TOptions>(fields, options)
