import React, { useEffect, ReactNode } from 'react'

import { H2 } from 'components/Typography'
import Dialog from 'components/Dialog'
import DialogContent from 'components/DialogContent'
import DialogCloseButton from 'components/DialogCloseButton'

import { ReactComponent as SuccessfulIcon } from 'assets/img/successfully.svg'

import styles from './ResponseModal.module.scss'

type IResponseModalProps = {
  isOpen: boolean
  responseType: 'add' | 'update'
  amount?: string
  footer?: ReactNode
  onClose: () => void
}

const ResponseModal: React.FC<IResponseModalProps> = ({ isOpen, responseType, amount, footer, onClose }) => {
  useEffect(() => {
    if (!isOpen) return
    const timer = setTimeout(() => onClose(), 3000)
    return () => {
      clearTimeout(timer)
    }
  }, [isOpen])

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <DialogCloseButton onClick={onClose} />
      <DialogContent className={styles.content}>
        <SuccessfulIcon className={styles.icon} />
        <H2 className={styles.title}>{responseType === 'add' ? 'Товары добавлены' : 'Остатки обновлены'}</H2>
        {amount && <span className={styles.amount}>{amount}</span>}
        {footer}
      </DialogContent>
    </Dialog>
  )
}

export default ResponseModal
