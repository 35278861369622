import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useMutation } from '@apollo/client'

import DropMenu from 'components/DropMenu'
import Button from 'components/Button'
import {
  IAddOrderFilesRequest,
  IAddOrderFilesResponse,
  buildAddOrderFilesQuery,
} from 'graphql/mutations/addOrderFiles'
import createUrlFromUploadedFile, { IUploadedData } from 'helpers/createUrlFromUploadedFile'
import { FileInput, IOrderFile, IOrder } from 'types/types'
import useBoolean from 'hooks/useBoolean'

import useNotifyCms from 'hooks/useNotifyCms'
import DownloadLink from './components/DownloadLink'
import LoadFileItem from './components/LoadFileItem'

import styles from './documents.module.scss'

interface IDocumentsProps {
  orderId: string
  files: IOrderFile[]
}

const ADD_ORDER_FILES = buildAddOrderFilesQuery(`
  id
  files {
    id
    name
    path
  }
`)

const ERROR_MESSAGE = 'Ошибка загрузки'

const transformFiles = (files: IUploadedData[]): FileInput[] =>
  files.map(({ name, mime, base_path }) => ({ name, mime, base_path }))

const Documents: React.FC<IDocumentsProps> = ({ orderId, files }) => {
  const addNotify = useNotifyCms()

  const inputRef = useRef<HTMLInputElement | null>(null)

  const [uploadLoading, setUploadLoading] = useBoolean()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [filesToLoad, setFilesToLoad] = useState<File[]>([])

  const hasFiles = files.length > 0 || filesToLoad.length > 0

  const [uploadFiles] = useMutation<IAddOrderFilesResponse, IAddOrderFilesRequest>(ADD_ORDER_FILES, {
    fetchPolicy: 'no-cache',
    update(cache, { data }) {
      const newFiles = data?.addOrderFiles?.files
      cache.modify({
        fields: {
          getOrder(order: IOrder): IOrder {
            return {
              ...order,
              files: newFiles ?? order.files,
            }
          },
        },
      })
    },
  })

  const onClick = () => inputRef.current?.click()

  const handleChange = useCallback(async (event: ChangeEvent<HTMLInputElement>) => {
    const nextFiles = event.target.files

    if (!nextFiles) return

    setFilesToLoad(Object.values(nextFiles))

    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }, [])

  const onFileToLoadDelete = useCallback((fileIndex: number) => {
    setFilesToLoad((prevState) => prevState.filter((_, index) => index !== fileIndex))
  }, [])

  const loadFiles = useCallback(
    async (payloadFiles: File[]) => {
      setUploadLoading.on()

      const filesUpload = await createUrlFromUploadedFile(payloadFiles, { type: 'files' })

      if (!filesUpload.length) {
        setUploadLoading.off()
        setErrorMessage(ERROR_MESSAGE)
        return
      }

      uploadFiles({
        variables: {
          order_id: orderId,
          files: transformFiles(filesUpload),
        },
      })
        .then((res) => {
          setFilesToLoad([])
          addNotify('success')
          return res
        })
        .catch((err) => {
          setErrorMessage(ERROR_MESSAGE)
          return err
        })
        .finally(() => setUploadLoading.off())
    },
    [uploadFiles, orderId, setUploadLoading],
  )

  useEffect(() => {
    if (!filesToLoad.length) return
    loadFiles(filesToLoad)
  }, [filesToLoad])

  return (
    <DropMenu title="Документы">
      {hasFiles && (
        <div className={styles.filesGroup}>
          {files.map((file) => (
            <DownloadLink key={file.id} file={file} />
          ))}
          {filesToLoad.map((item, index) => (
            <LoadFileItem
              key={index.toString()}
              file={item}
              loading={uploadLoading}
              error={errorMessage}
              onDelete={() => onFileToLoadDelete(index)}
            />
          ))}
        </div>
      )}
      <div>
        <Button disabled={uploadLoading} onClick={onClick}>
          Загрузить документ
        </Button>
      </div>
      <input
        type="file"
        multiple
        accept=".txt, .csv, .xls, .xlsx, .xlsm, .zip, .rar, .xml, .docx, .doc, .pdf"
        ref={inputRef}
        disabled={uploadLoading}
        className={styles.fileInput}
        onChange={handleChange}
      />
    </DropMenu>
  )
}

export default Documents
