import React, { memo, useCallback, useMemo } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { FormikHelpers } from 'formik'

import withErrorBoundary from 'hocs/withErrorBoundary'
import withLeaveThisPageModal from 'hocs/withLeaveThisPageModal'
import useCmsParams from 'hooks/useCmsParams'
import GoBackLink from 'components/GoBackLink'
import ResultTitle from 'components/ResultTitle'
import PageContainer from 'components/PageContainer'
import Preloader from 'components/Preloader'
import PriceRuleForm, { IPriceRuleFormValues } from 'components/forms/PriceRuleForm'
import useNotifyCms from 'hooks/useNotifyCms'
import useCmsLinks from 'hooks/useCmsLinks'
import convertToFormErrors from 'utils/convertToFormErrors'
import { ISetPriceRule, ISetPriceRuleParams } from 'graphql/mutations/setPriceRule'
import { IGetPriceRule, IGetPriceRuleParams } from 'graphql/queries/getPriceRule'
import { useGetAttributesQuery } from 'graphql/queries/getAttributes'

import { maxPaginate } from 'consts'
import ApiError from 'components/ApiError/ApiError'
import { GET_PRICE_RULE, SET_PRICE_RULE, GET_ATTRIBUTES } from './queries'
import prepareInitialValues from './utils/prepareInitialValues'
import prepareSubmitValues from './utils/prepareSubmitValues'
import Controls from './components/Controls'

const defaultValues: IPriceRuleFormValues = {
  name: '',
  status: false,
  addDefault: false,
  position: '1',
  groupParameters: [
    {
      pricelistId: null,
      parameters: [],
    },
  ],
  clientsIds: [],
}

const PriceRuleEdit: React.FC = () => {
  const { sellerId, priceRuleId } = useCmsParams()
  const addNotify = useNotifyCms()
  const { priceRulesListLink } = useCmsLinks()

  const {
    data: attributesData,
    loading: attributesLoading,
    error: attributesError,
  } = useGetAttributesQuery(GET_ATTRIBUTES, {
    variables: { seller_id: sellerId, paginate: maxPaginate },
    fetchPolicy: 'network-only',
  })

  const attributesList = useMemo(() => attributesData?.getAttributes.list || [], [attributesData])

  const {
    data: priceRuleData,
    loading,
    error,
    refetch,
  } = useQuery<IGetPriceRule, IGetPriceRuleParams>(GET_PRICE_RULE, {
    variables: { id: priceRuleId },
    fetchPolicy: 'network-only',
  })

  const initialValues = useMemo(() => {
    if (!priceRuleData) return defaultValues
    return prepareInitialValues(priceRuleData.getPriceRule, attributesList)
  }, [priceRuleData, attributesList])

  const [onSetPriceRule, { loading: submitLoading }] = useMutation<ISetPriceRule, ISetPriceRuleParams>(
    SET_PRICE_RULE,
  )

  const onSubmit = useCallback(
    (values: IPriceRuleFormValues, { setErrors }: FormikHelpers<IPriceRuleFormValues>) =>
      onSetPriceRule({
        variables: {
          id: priceRuleId,
          input: prepareSubmitValues(values),
        },
      })
        .then(() => {
          addNotify('success')
          refetch()
        })
        .catch((errorRes) => {
          addNotify('error')
          const errors = convertToFormErrors(errorRes)
          if (errors) setErrors(errors)
        }),
    [onSetPriceRule, addNotify, refetch, priceRuleId],
  )

  if (loading || attributesLoading) {
    return (
      <PageContainer>
        <GoBackLink href={priceRulesListLink} />
        <Preloader />
      </PageContainer>
    )
  }

  if (error || attributesError) {
    return (
      <PageContainer>
        <GoBackLink href={priceRulesListLink} />
        <ApiError error={error || attributesError} />
      </PageContainer>
    )
  }

  return (
    <PageContainer>
      <GoBackLink href={priceRulesListLink} />
      <ResultTitle>{initialValues.name}</ResultTitle>
      <PriceRuleForm
        submitLoading={submitLoading}
        initialValues={initialValues}
        onSubmit={onSubmit}
        renderControls={() => <Controls />}
      />
    </PageContainer>
  )
}

export default withErrorBoundary(withLeaveThisPageModal(memo(PriceRuleEdit)))
