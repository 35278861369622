import { ISocials, ITimeWorks, Seller } from 'types/types'
import { NonNullableFields } from 'types/utils'

import { IGeneralFormValues } from '../types'

const translateSocialsToInput = {
  0: 'vk',
  1: 'instagram',
  2: 'fb',
  3: 'twitter',
} as const

const prepareSocials = (payload: ISocials[]): IGeneralFormValues['settings']['socials'] => {
  const currentSocials = payload
    .filter((item) => !!item.name)
    .reduce(
      (acc, { link, name }) => ({ ...acc, [name!]: link }),
      {} as IGeneralFormValues['settings']['socials'],
    )

  const currentKeys = Object.keys(currentSocials)
  const allkeys = Object.values(translateSocialsToInput)
  const availableKeysArray = allkeys.filter((item) => !currentKeys.includes(item))
  const availableFields = availableKeysArray.reduce((acc, item) => ({ ...acc, [item]: '' }), {})

  return {
    ...currentSocials,
    ...availableFields,
  }
}

const prepareTimeWorks = (payload: ITimeWorks[]): IGeneralFormValues['settings']['time_works'] => {
  const workDays = payload.find(({ name }) => name === 'workDays') as NonNullableFields<ITimeWorks>
  const weekend = payload.find(({ name }) => name === 'weekend') as NonNullableFields<ITimeWorks>

  return {
    workDays: workDays ?? { name: 'workDays', start: '00:00', end: '00:00' },
    weekend: weekend ?? { name: 'weekend', start: '00:00', end: '00:00' },
  }
}

const prepareInitialValues = (payload: Seller): IGeneralFormValues => {
  const { name, company, industry, description, logo = '', icon, address, phone, settings } = payload

  const { socials = [], time_works = [] } = settings || {}

  return {
    name,
    company,
    industry: industry || '',
    description: description || '',
    logo: logo ? { path: logo } : null,
    icon: icon ? { path: icon } : null,
    address: address || '',
    phone: phone || '',
    settings: {
      time_works: prepareTimeWorks(time_works),
      socials: prepareSocials(socials),
    },
  }
}

export default prepareInitialValues
