import { DocumentNode, gql, useQuery, QueryHookOptions } from '@apollo/client'

import { IContact } from 'types/types'

export type IGetContact = {
  getContact: IContact
}

export type IGetContactParams = {
  id: IPrimaryKey
}

export const buildGetContactQuery = (fields: string): DocumentNode => gql`
  query getContact($id: ID!) {
    getContact(id: $id) {
      ${fields}
    }
  }
`

export const useGetContactQuery = <TData = IGetContact, TOptions = IGetContactParams>(
  fields: DocumentNode,
  options?: QueryHookOptions<TData, TOptions>,
) => useQuery<TData, TOptions>(fields, options)
