import React from 'react'

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const DenyIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM7.75714 7.75772C8.22577 7.28909 8.98556 7.28909 9.45419 7.75772L12 10.3035L14.5458 7.75772C15.0144 7.28909 15.7742 7.28909 16.2428 7.75772C16.7114 8.22635 16.7114 8.98615 16.2428 9.45478L13.697 12.0006L16.2424 14.5459C16.711 15.0146 16.711 15.7744 16.2424 16.243C15.7738 16.7116 15.014 16.7116 14.5454 16.243L12 13.6976L9.45459 16.243C8.98596 16.7116 8.22617 16.7116 7.75754 16.243C7.28891 15.7744 7.28891 15.0146 7.75754 14.5459L10.3029 12.0006L7.75714 9.45478C7.28851 8.98615 7.28851 8.22635 7.75714 7.75772Z"
      fill="#F9423A"
    />
  </SvgIcon>
)

export default DenyIcon
