import React, { useEffect, useMemo, useRef, useState } from 'react'

import useOutsideAlerter from 'hooks/useOutsideAlerter'
import block from 'utils/block'
import TextField from 'components/TextField'
import IconButton from 'components/IconButton'
import Text from 'components/Typography'
import SearchIcon from 'components/Icons/SearchIcon'
import CrossSmallIcon from 'components/Icons/CrossSmallIcon'
import AttentionIcon from 'components/Icons/AttentionIcon'
import ArrowSmallDownSmallIcon from 'components/Icons/ArrowDownSmallIcon'

import Preloader from 'components/Preloader'
import Chip from './components/Chip'
import Option from './components/Option'

import styles from './CustomMultiSelect.module.scss'

export type ICustomMultiSelectProps = {
  disabled?: boolean
  error?: boolean
  loading?: boolean
  placeholder?: string
  options?: IOption[]
  selectedOptions: IOption[]
  setSelectedOption: (value: IOption[]) => void
  onClear?: () => void
}

const b = block(styles)

const allOption: IOption = { id: '-1', name: 'Выбрать все' }

function isEqualArrays<A1 = any[], A2 = any[]>(arrayA: A1, arrayB: A2) {
  if (!Array.isArray(arrayA) || !Array.isArray(arrayB)) return false
  if (!arrayA.length && !arrayB.length) return false
  return arrayA.length === arrayB.length
}

const CustomMultiSelect: React.FC<ICustomMultiSelectProps> = ({
  options = [],
  disabled = false,
  error = false,
  loading = false,
  placeholder = '',
  selectedOptions,
  setSelectedOption,
  onClear,
}) => {
  const [isDrop, setDrop] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')

  const isAllSelected = useMemo(() => isEqualArrays(options, selectedOptions), [options, selectedOptions])

  const ref = useRef(null)

  useOutsideAlerter(ref, () => {
    setDrop(false)
  })

  const endAdornment = useMemo(() => {
    if (selectedOptions.length > 0 && onClear) {
      return (
        <IconButton
          className={styles.clearSearchButton}
          onClick={(event) => {
            event.stopPropagation()
            onClear()
          }}
        >
          <CrossSmallIcon />
        </IconButton>
      )
    }

    return error ? (
      <AttentionIcon color="error" />
    ) : (
      <ArrowSmallDownSmallIcon className={b('arrow', { open: isDrop })} />
    )
  }, [onClear, error, isDrop, selectedOptions])

  const handlerClick = ({ name, id }: IOption) => {
    const include = selectedOptions.find((item) => item.id === id)
    if (include) {
      setSelectedOption(selectedOptions.filter((item) => item.id !== id))
    } else {
      setSelectedOption([...selectedOptions, { name, id }])
    }
  }

  const onToggleAll = () => {
    if (isAllSelected) {
      setSelectedOption([])
    } else {
      setSelectedOption(options)
    }
  }

  const checkSelectedOption = (id: IPrimaryKey) => selectedOptions.some((item) => item.id === id)

  const renderOptions = (val: string) => {
    const searchedOptions =
      val.toLowerCase() && options.filter((option) => option.name.toLowerCase().includes(val.toLowerCase()))

    const mappedOptions = (searchedOptions || options).map((option) => (
      <Option
        key={option.id}
        option={option}
        isSelected={checkSelectedOption(option.id)}
        onSelect={() => handlerClick(option)}
      />
    ))

    return <>{mappedOptions}</>
  }

  const deleteSelectedOption = (item: any) => {
    setSelectedOption(selectedOptions.filter((el) => el.id !== item.id))
  }

  const renderChip = (item: IOption) => <Chip key={item.id} item={item} onRemove={deleteSelectedOption} />

  const renderInput = (
    <input placeholder={placeholder || 'Выберите варианты'} className={styles.input} readOnly />
  )

  useEffect(() => {
    if (isDrop) {
      setSearchValue('')
    }
  }, [isDrop])

  return (
    <div ref={ref} className={b('container', { disabled })} role="tree">
      <div
        className={b('inputGroup', { error })}
        onClick={() => (disabled ? undefined : setDrop(!isDrop))}
        role="presentation"
      >
        {selectedOptions.length === 0 ? renderInput : selectedOptions.map((item) => renderChip(item))}

        <div className={styles.endAdornment}>
          {loading ? <Preloader variant="relative" size="small" /> : endAdornment}
        </div>
      </div>

      {isDrop && (
        <div className={styles.listContainer}>
          {options.length > 5 ? (
            <TextField
              className={styles.searchInput}
              placeholder="Поиск"
              value={searchValue}
              onChange={(event: InputChangeEvent) => setSearchValue(event.target.value)}
              endAdornment={
                <div className={styles.searchEndAdornment}>
                  {searchValue ? (
                    <IconButton
                      className={styles.clearSearchButton}
                      size="small"
                      onClick={() => setSearchValue('')}
                    >
                      <CrossSmallIcon />
                    </IconButton>
                  ) : null}
                  <SearchIcon />
                </div>
              }
            />
          ) : null}

          <div className={styles.optionsList}>
            {options.length > 0 ? (
              <Option option={allOption} isSelected={isAllSelected} onSelect={onToggleAll} />
            ) : (
              <div className={styles.noOptionsWrapper}>
                <Text>Вариантов нет</Text>
              </div>
            )}

            {renderOptions(searchValue)}
          </div>
        </div>
      )}
    </div>
  )
}

export default CustomMultiSelect
