import { array, object, string } from 'yup'

import { REQUIRED_MESSAGE, WRONG_URL_FORMAT } from 'consts/errorMessages'

const validationSchema = () =>
  object({
    name: string()
      .required(REQUIRED_MESSAGE)
      .max(200, 'Поле Название не должно содержать больше 200 символов.'),
    vendor_code: string().max(30, `Поле Артикул не должен содержать больше 30 символов.`),
    links: array(
      object({
        name: string().required(REQUIRED_MESSAGE),
        url: string().required(REQUIRED_MESSAGE).url(WRONG_URL_FORMAT),
      }),
    ),
  })

export default validationSchema
