import React, { memo, useCallback } from 'react'

import DropMenu from 'components/DropMenu'
import withErrorBoundary from 'hocs/withErrorBoundary'
import Link from 'components/Link/Link'
import PlusIcon from 'components/Icons/PlusIcon'
import { IOrdersColorStatusFormValues } from 'pages/More/types'
import { DEFAULT_ORDER_STATUS_COLOR } from 'consts/defaultValues'

import ColorInput from './components/ColorInput'

import styles from './orderStatusColor.module.scss'

type IOrderStatusColorProps = {
  statuses: IOrdersColorStatusFormValues
  setStatuses: React.Dispatch<React.SetStateAction<IOrdersColorStatusFormValues>>
}

const OrderStatusColor: React.FC<IOrderStatusColorProps> = ({ statuses, setStatuses }) => {
  const addStatus = useCallback(() => {
    setStatuses((prev) =>
      prev.concat([{ title: '', color: DEFAULT_ORDER_STATUS_COLOR, out_of_limit: false }]),
    )
  }, [setStatuses])

  return (
    <DropMenu title="Статусы заказа">
      {statuses.map((status, index) => (
        <ColorInput key={index} index={index} status={status} setStatuses={setStatuses} />
      ))}

      <Link className={styles.addButton} component="button" startIcon={<PlusIcon />} onClick={addStatus}>
        Добавить новый статус
      </Link>
    </DropMenu>
  )
}

export default withErrorBoundary(memo(OrderStatusColor))
