import React from 'react'

type IStarIconProps = React.SVGProps<SVGSVGElement>

const StarIcon: React.FC<IStarIconProps> = (props) => (
  <svg width="7" height="6" viewBox="0 0 7 6" fill="none" {...props}>
    <path
      d="M1.27412 5.30159L2.6392 3.33333L0.480469 2.63492L0.829675 1.49207L3.02015 2.31746L2.95666 0H4.25825L4.19476 2.31746L6.38523 1.49207L6.73444 2.63492L4.57571 3.33333L5.97253 5.30159L4.95666 6L3.62333 3.93651L2.25825 6L1.27412 5.30159Z"
      fill="#F9423A"
    />
  </svg>
)

export default StarIcon
