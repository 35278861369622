import React, { useEffect, useState, FC } from 'react'
import { useQuery, useMutation } from '@apollo/client'

import { H2 } from 'components/Typography'
import Link from 'components/Link'
import UiLayout from 'components/ui-kit/UiLayout'
import Button from 'components/Button'
import TextField from 'components/ui-kit/form/TextField'
import DragAndDrop from 'components/ui-kit/dragAndDrop'
import type { Group, ImageInput } from 'types/types'
import useLogo from 'hooks/useLogo'
import useSortingInput from 'hooks/useSortingInput'
import useCmsParams from 'hooks/useCmsParams'
import Dialog from 'components/Dialog'
import DialogContent from 'components/DialogContent'
import DialogCloseButton from 'components/DialogCloseButton'
import TrashIcon from 'components/Icons/TrashIcon'
import useNotifyCms from 'hooks/useNotifyCms'
import { GET_GROUPS, IGetGroups, IGetGroupsParams } from 'graphql/queries/getGroups'
import { SET_GROUP } from 'graphql/mutations/setGroup'
import buildPaginateParams from 'utils/buildPaginateParams'

import styles from './ModalEdit.module.scss'

type IModalEditProps = {
  group: Group
  isOpen: boolean
  onClose: () => void
  onDelete: () => void
  numberList: number[]
  currentPage: string
}

const ModalEdit: FC<IModalEditProps> = ({ isOpen, onClose, onDelete, group, numberList, currentPage }) => {
  const { sellerId } = useCmsParams()
  const addNotify = useNotifyCms()

  const [name, setName] = useState<string>('')

  const { number, onChange: setNumber } = useSortingInput({
    defaultValue: '',
    numberList,
  })

  const [image, setImage] = useState<ImageInput | string | undefined>('')

  const logo = useLogo(image, Boolean(group.logo)) // TODO: DELETE useLogo

  const isDisabled = group.name === name && group.number === number && group.logo === image

  const { data, loading } = useQuery<IGetGroups, IGetGroupsParams>(GET_GROUPS, {
    variables: {
      seller_id: sellerId,
      paginate: buildPaginateParams(currentPage),
    },
    fetchPolicy: 'no-cache',
  })

  const [setGroup] = useMutation(SET_GROUP, {
    variables: { id: group.id, input: { name, number, logo } },
    refetchQueries: [GET_GROUPS],
    onCompleted: () => addNotify('success'),
    onError: () => addNotify('error'),
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (loading || !data) return

    const item = data.getGroups.list.find((record) => record.id === group.id)

    if (!item) return

    setName(item.name)
    setNumber(item.number)
    setImage(item.logo)
  }, [data, loading])

  const handleOnClose = () => {
    onClose()
  }

  const isNameError = name.length === 0

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!number || isNameError) {
      return
    }

    await setGroup()

    handleOnClose()
  }

  return (
    <Dialog isOpen={isOpen} onClose={handleOnClose}>
      <DialogCloseButton onClick={handleOnClose} />
      <DialogContent className={styles.content}>
        <form onSubmit={handleSubmit}>
          <H2 className={styles.ModalEdit__Title}>Редактирование группы</H2>
          <UiLayout spanText="Название группы" isRequired layoutClassName={styles.ModalEdit__Field}>
            <TextField
              inputProps={{
                name: 'name',
                value: name,
                maxLength: 49,
                onChange: (e) => setName(e.target.value),
              }}
              errorHandler={(isDirt) => isDirt && isNameError}
              messageError="Заполните поле"
            />
          </UiLayout>
          <UiLayout spanText="Сортировка" isRequired layoutClassName={styles.ModalEdit__Field}>
            <TextField
              inputProps={{
                name: 'number',
                type: 'number',
                value: number,
                maxLength: 5,
                onChange: setNumber,
              }}
              isError={!number}
              messageError="Заполните поле"
            />
          </UiLayout>
          <DragAndDrop title="Фото" zoneVariant="big" image={image} onChange={setImage} />
          <Link component="button" startIcon={<TrashIcon />} onClick={onDelete}>
            Удалить группу
          </Link>
          <div className={styles.ModalEdit__WrapBtn}>
            <Button type="submit" disabled={isDisabled}>
              Сохранить
            </Button>
            <Button type="reset" variant="outlined" onClick={handleOnClose}>
              Отменить
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default ModalEdit
