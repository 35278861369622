import React, { useMemo, useCallback, memo } from 'react'
import isEqual from 'lodash/isEqual'

import PageContainer from 'components/PageContainer'
import ResultTitle from 'components/ResultTitle'
import GoBackLink from 'components/GoBackLink'
import Preloader from 'components/Preloader'
import ApiError from 'components/ApiError'
import NoSearchResult from 'components/NoSearchResult'
import Pagination from 'components/Pagination'
import useCmsParams from 'hooks/useCmsParams'
import useSearchParams from 'hooks/useSearchParams'
import useBoolean from 'hooks/useBoolean'
import { useGetStoresQuery } from 'graphql/queries/getStores'
import hasPagination from 'utils/hasPagination'
import buildPaginateParams from 'utils/buildPaginateParams'
import withErrorBoundary from 'hocs/withErrorBoundary'

import Empty from './components/Empty'
import Filters from './components/Filters'
import Result from './components/Result'
import { GET_STORES } from './queries'
import { ISearchParams } from './types'

const initFilters: ISearchParams = {
  search: '',
  page: '1',
}

const StoreList: React.FC = () => {
  const { sellerId } = useCmsParams()
  const [searchParams, setSearchParams] = useSearchParams<ISearchParams>(initFilters)
  const { page, search } = searchParams

  const [firstLoading, setFirstLoading] = useBoolean(true)

  const hasFilters = useMemo(() => !isEqual(searchParams, initFilters), [searchParams])

  const { data, loading, error, refetch } = useGetStoresQuery(GET_STORES, {
    variables: {
      seller_id: sellerId,
      search,
      paginate: buildPaginateParams(page),
    },
    onCompleted: () => {
      if (firstLoading) setFirstLoading.off()
    },
    onError: () => {
      if (firstLoading) setFirstLoading.off()
    },
    fetchPolicy: 'network-only',
  })

  const { getStores: { elements = 0, pages = 0 } = {} } = data || {}
  const stores = useMemo(() => data?.getStores.list || [], [data])

  const onPageChange = useCallback(
    (nextPage: number) => {
      setSearchParams((prev) => ({ ...prev, page: nextPage.toString() }))
    },
    [setSearchParams],
  )

  const isEmpty = !loading && !hasFilters && !stores.length

  if (firstLoading) {
    return (
      <PageContainer>
        <GoBackLink href={`/${sellerId}/catalog/products`} />
        <Preloader />
      </PageContainer>
    )
  }

  if (error) {
    return (
      <PageContainer>
        <GoBackLink href={`/${sellerId}/catalog/products`} />
        <ResultTitle>Склады</ResultTitle>
        <Filters searchParams={searchParams} setSearchParams={setSearchParams} refetchList={refetch} />
        <ApiError error={error} />
      </PageContainer>
    )
  }

  if (isEmpty) {
    return (
      <PageContainer>
        <GoBackLink href={`/${sellerId}/catalog/products`} />
        <Empty refetchList={refetch} />
      </PageContainer>
    )
  }

  return (
    <PageContainer>
      <GoBackLink href={`/${sellerId}/catalog/products`} />
      <ResultTitle>Склады</ResultTitle>
      <Filters searchParams={searchParams} setSearchParams={setSearchParams} refetchList={refetch} />
      {loading ? (
        <Preloader />
      ) : (
        <>
          <Result stores={stores} refetchList={refetch} />
          {hasPagination(elements) ? (
            <Pagination page={Number(page)} count={pages} onChange={onPageChange} />
          ) : null}
          {!stores.length ? <NoSearchResult /> : null}
        </>
      )}
    </PageContainer>
  )
}

export default withErrorBoundary(memo(StoreList))
