import { Overrides } from '@material-ui/core/styles/overrides'

const tab: Overrides['MuiTab'] = {
  root: {
    '@media (min-width: 360px)': {
      minWidth: 'auto',
    },
    maxWidth: 'none',
    minWidth: 'auto',
    minHeight: '64px',
    padding: 0,
    textTransform: 'none',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: 'none',
    overflow: 'unset',

    // backgroundColor: '#DCDEE4',
    // transition: 'backgroundColor 0.16s ease-in-out',
  },
  textColorInherit: {
    opacity: 1,
  },
}

export default tab
