import React, { FC, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { FormikHelpers } from 'formik'

import PageContainer from 'components/PageContainer/PageContainer'
import GoBackLink from 'components/GoBackLink/GoBackLink'
import ResultTitle from 'components/ResultTitle/ResultTitle'
import PriceListForm, { IPriceListFormValues } from 'components/forms/PriceListForm'
import useCmsParams from 'hooks/useCmsParams'
import useNotifyCms from 'hooks/useNotifyCms'
import convertToFormErrors from 'utils/convertToFormErrors'
import { buildAddPriceListQuery, useAddPriceListMutation } from 'graphql/mutations/addPriceList'
import useCmsLinks from 'hooks/useCmsLinks'
import useBoolean from 'hooks/useBoolean'
import { buildGetPriceListsQuery, useGetPriceListsLazyQuery } from 'graphql/queries/getPriceLists'
import { maxPaginate } from 'consts'
import { CATALOG_PRICE_LISTS_LIMIT_ERROR } from 'consts/errorMessages'

const initialValue: IPriceListFormValues = {
  name: '',
  default_price: false,
  show_prices: false,
  file: null,
  downloadUrl: null,
}

const ADD_PRICE_LIST = buildAddPriceListQuery(`
  id
  name
  default_price
`)

export const GET_PRICE_LISTS = buildGetPriceListsQuery(`
  id 
  name
  show_prices
`)

const prepareSubmitValues = ({ name, default_price, show_prices, file }: IPriceListFormValues) => ({
  name,
  default_price,
  show_prices,
  file,
})

const PriceListCreate: FC = () => {
  const { sellerId } = useCmsParams()
  const { replace } = useHistory()
  const addNotify = useNotifyCms()
  const { priceListsListLink, priceListEditLink } = useCmsLinks()

  const [loading, setLoading] = useBoolean()

  const [getPriceLists] = useGetPriceListsLazyQuery(GET_PRICE_LISTS, {
    variables: {
      seller_id: sellerId,
      paginate: maxPaginate,
    },
    fetchPolicy: 'network-only',
  })

  const [addPriceList] = useAddPriceListMutation(ADD_PRICE_LIST)

  const onAddPriceList = useCallback(
    (formValues: IPriceListFormValues, { setErrors }: FormikHelpers<IPriceListFormValues>) =>
      addPriceList({
        variables: {
          seller_id: sellerId,
          ...prepareSubmitValues(formValues),
        },
      })
        .then(({ data }) => {
          addNotify('success')
          if (data) replace(priceListEditLink(data.addPriceList.id))
        })
        .catch((error) => {
          addNotify('error')
          const errors = convertToFormErrors(error)
          if (errors) setErrors(errors)
        })
        .finally(() => {
          setLoading.off()
        }),
    [addPriceList, addNotify, replace, priceListEditLink, sellerId, setLoading],
  )

  const onSubmit = useCallback(
    async (formValues: IPriceListFormValues, form: FormikHelpers<IPriceListFormValues>) => {
      setLoading.on()

      if (formValues.show_prices) {
        const { data: priceListsData, error: priceListsError } = await getPriceLists()
        const priceLists = priceListsData?.getPriceLists.list || []

        if (priceListsError) {
          addNotify({ message: priceListsError.message, kind: 'error' })
          setLoading.off()
          return undefined
        }

        const activeCatalogCount = priceLists.filter((record) => record.show_prices).length

        if (activeCatalogCount >= 3) {
          addNotify({ message: CATALOG_PRICE_LISTS_LIMIT_ERROR, kind: 'error' })
          setLoading.off()
          return undefined
        }

        return onAddPriceList(formValues, form)
      }

      return onAddPriceList(formValues, form)
    },
    [setLoading, addNotify, onAddPriceList, getPriceLists],
  )

  return (
    <PageContainer>
      <GoBackLink href={priceListsListLink} />
      <ResultTitle>Создание прайс листа</ResultTitle>
      <PriceListForm initialValue={initialValue} submitLoading={loading} onSubmit={onSubmit} />
    </PageContainer>
  )
}

export default PriceListCreate
