import omit from 'lodash/omit'

import { ISetBannerParams } from 'graphql/mutations/setBanner'
import { IBannerFormValues } from 'components/forms/BannerForm'
import { IBanner, IBannerImage, IBannerImageInput, ImageInput } from 'types/types'

type IImageInput = File | string | null | { flag: 'delete' }

const prepareImage = (
  initImage: IBannerImage,
  imageInput: IImageInput,
  uploadedImage: ImageInput | null,
): IBannerImageInput | null => {
  if (typeof imageInput === 'string') {
    return initImage
  }

  if (imageInput instanceof File) {
    if (!uploadedImage) throw new Error('No uploaded image')
    return omit(uploadedImage, 'name')
  }

  if (imageInput?.flag === 'delete') {
    return { flag: 'delete' }
  }

  return null
}

const prepareSubmitValues = (
  initBanner: IBanner,
  values: IBannerFormValues,
  uploadedImages: [ImageInput | null, ImageInput | null],
): ISetBannerParams['input'] => {
  const { name, url, status, text, button, tx_color, show_title } = values

  const [uploadedDesktopImage, uploadedMobileImage] = uploadedImages

  return {
    name,
    url,
    status,
    text,
    button,
    tx_color,
    show_title,
    desktop_image: prepareImage(initBanner.desktop_image, values.desktopImage, uploadedDesktopImage),
    mobile_image: prepareImage(initBanner.mobile_image, values.mobileImage, uploadedMobileImage),
  }
}

export default prepareSubmitValues
