type IFormikFieldEvent<T> = {
  target: {
    name: string
    value: T
  }
}

const formikFieldEvent = <T>(name: string, value: T): IFormikFieldEvent<T> => ({
  target: { name, value },
})

export default formikFieldEvent
