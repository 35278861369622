import React from 'react'
import { useMutation } from '@apollo/client'

import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import {
  DELETE_PRICE_LIST,
  IDeletePriceList,
  IDeletePriceListParams,
} from 'graphql/mutations/deletePriceList'
import useBoolean from 'hooks/useBoolean'
import useNotifyCms from 'hooks/useNotifyCms'

type IRenderParams = {
  onClick: () => void
}

type IDeletePriceListProps = {
  priceListId: IPrimaryKey
  onSuccess: () => void
  render: (params: IRenderParams) => void
}

const DeletePriceList: React.FC<IDeletePriceListProps> = ({ priceListId, onSuccess, render }) => {
  const addNotify = useNotifyCms()

  const [iseOpen, setIsOpen] = useBoolean(false)

  const [onDelete] = useMutation<IDeletePriceList, IDeletePriceListParams>(DELETE_PRICE_LIST, {
    variables: {
      id: priceListId,
    },
    onCompleted: () => {
      setIsOpen.off()
      addNotify({ kind: 'success', message: 'Прайс лист удален' })
      onSuccess()
    },
    onError: () => {
      addNotify('error')
      setIsOpen.off()
    },
  })

  return (
    <>
      <ConfirmDialog
        isOpen={iseOpen}
        title="Вы уверены, что хотите удалить прайс лист?"
        submitBtnText="Да, удалить"
        onSubmit={() => onDelete()}
        onClose={setIsOpen.off}
      />

      {render({ onClick: setIsOpen.on })}
    </>
  )
}

export default DeletePriceList
