import React, { FC } from 'react'
import { useField } from 'formik'

import CheckboxLabel, { ICheckboxLabelProps } from 'components/CheckboxLabel'

export type ICheckboxInputProps = ICheckboxLabelProps & {
  name: string
}

const CheckboxInput: FC<ICheckboxInputProps> = ({ name, ...props }) => {
  const [input] = useField(name)

  const { value, ...restInput } = input

  return <CheckboxLabel {...props} {...restInput} checked={value} />
}

export default CheckboxInput
