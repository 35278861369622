import React from 'react'

import Text from 'components/Typography'

import styles from './ItemGroup.module.scss'

type IItemGroupProps = {
  title: string
}

const ItemGroup: React.FC<IItemGroupProps> = ({ title, children }) => (
  <div className={styles.container}>
    <Text className={styles.title} bold color="textSecondary">
      {title}
    </Text>

    <div className={styles.items}>{children}</div>
  </div>
)

export default ItemGroup
