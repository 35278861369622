export const white = '#FFFFFF'
export const black = '#000000'
export const primary = '#002FA7'
export const primaryActive = '#7949F4'
export const active = '#121239'
export const grey20 = '#DCDEE4'
export const grey60 = '#5C5F69'
export const greyBack = '#F4F5F8'
export const link = '#0089CC'
export const success = '#47A347'
export const warning = '#EF9B28'
export const error = '#F9423A'
