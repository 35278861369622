import React, { useState } from 'react'
import { useQuery } from '@apollo/client'

import Container from 'components/Container'
import Link from 'components/Link'
import { useAuth } from 'contexts/AuthProvider'
import { ReactComponent as Union } from 'assets/img/Union.svg'
import { GET_USER, IGetUser } from 'graphql/queries/getUser'

import { userEvents, clickOnMySitesInHeaderEvent, clickOnProfileInHeaderEvent } from 'utils/gtmHelper'
import Item from './Item'

import styles from './Header.module.scss'

const SIGN_MENU = {
  signed: [
    {
      title: 'Мои сайты',
      link: '/',
    },
    {
      title: 'Профиль',
      link: '/profile',
    },
  ],
}

const Header: React.FC = () => {
  const { logout } = useAuth()

  const [verify, setVerifyState] = useState<boolean>(true)

  useQuery<IGetUser>(GET_USER, {
    fetchPolicy: 'network-only',
    onCompleted({ getUser }) {
      setVerifyState(!!getUser.email_verified_at)
    },
  })

  const logOutHandler = () => {
    userEvents('logout')
    logout()
  }

  return (
    <div className={styles.wrapper}>
      <Container>
        <header className={styles.header}>
          <ul className={styles.list}>
            <Union className={styles.logo} />
            {SIGN_MENU.signed.map((el) => (
              <Item
                title={el.title}
                key={el.link}
                link={el.link}
                verify={verify}
                onClick={() => {
                  if (el.title === 'Профиль') {
                    clickOnProfileInHeaderEvent()
                  } else {
                    clickOnMySitesInHeaderEvent()
                  }
                }}
              />
            ))}
          </ul>

          <Link className={styles.logOutLink} component="button" onClick={() => logOutHandler()}>
            Выйти
          </Link>
        </header>
      </Container>
    </div>
  )
}

export default Header
