import React, { Fragment, useCallback } from 'react'
import { Formik } from 'formik'
import { array, object, string, InferType } from 'yup'

import { H2 } from 'components/Typography'
import Button from 'components/Button'
import Dialog from 'components/Dialog'
import DialogContent from 'components/DialogContent'
import DialogCloseButton from 'components/DialogCloseButton'
import Link from 'components/Link'
import PlusIcon from 'components/Icons/PlusIcon'
import TextInput from 'components/Inputs/TextInput'
import { REQUIRED_MESSAGE, WRONG_INN_FORMAT } from 'consts/errorMessages'

import styles from './CreateCompanyDialog.module.scss'

type ICreateCompanyDialogProps = {
  isOpen: boolean
  onClose: () => void
  onSuccess: (payload: ICreateCompanyFormValues) => void
}

type ICreateCompanyFormValues = InferType<typeof validationSchema>

const defaultValue: ICreateCompanyFormValues = [
  {
    name: '',
    inn: '',
  },
]

const validationSchema = array()
  .of(
    object({
      name: string().default('').required(REQUIRED_MESSAGE),
      inn: string()
        .test('inn', (inn, { createError }) => {
          if (!inn) return true
          if (inn.length === 9 || inn.length === 10 || inn.length === 12) return true
          return createError({ message: WRONG_INN_FORMAT })
        })
        .default(''),
    }),
  )
  .defined()

const CreateCompanyDialog: React.FC<ICreateCompanyDialogProps> = ({ isOpen, onClose, onSuccess }) => {
  const onSubmit = useCallback(
    (payload: ICreateCompanyFormValues) => {
      onSuccess(payload)
      onClose()
    },
    [onClose, onSuccess],
  )

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <DialogCloseButton onClick={onClose} />

      <DialogContent className={styles.content}>
        <H2 className={styles.title}>Создание компании</H2>

        <Formik initialValues={defaultValue} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({ values, handleSubmit, setValues }) => (
            <form onSubmit={handleSubmit}>
              {values.map((_, index) => (
                <Fragment key={index}>
                  <TextInput
                    name={`[${index}]name`}
                    required
                    label="Компания"
                    placeholder="Введите название компании"
                    margin="default"
                  />
                  <TextInput name={`[${index}]inn`} label="ИНН" placeholder="Введите ИНН" margin="default" />
                </Fragment>
              ))}

              <Link
                component="button"
                startIcon={<PlusIcon />}
                onClick={() => setValues(values.concat(defaultValue))}
              >
                Добавить компанию
              </Link>

              <div className={styles.controlsGroup}>
                <Button type="submit">Сохранить</Button>
                <Button variant="outlined" onClick={onClose}>
                  Отменить
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default CreateCompanyDialog
