import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useField } from 'formik'

import Button from 'components/Button'
import Dialog from 'components/Dialog'
import DialogContent from 'components/DialogContent'
import DialogCloseButton from 'components/DialogCloseButton'
import IconButton from 'components/IconButton'
import Text from 'components/Typography'
import PopupList from 'components/PopupList'
import Link from 'components/Link'
import SelectField from 'components/SelectField'
import TrashIcon from 'components/Icons/TrashIcon'
import PlusIcon from 'components/Icons/PlusIcon'
import ArrowSmallDownSmallIcon from 'components/Icons/ArrowDownSmallIcon'
import useBoolean from 'hooks/useBoolean'
import block from 'utils/block'
import createUniqId from 'utils/createUniqId'
import convertAttributeToOption from 'utils/convertAttributeToOption'
import { Attribute as IAttribute } from 'types/types'

import { IVariantsFormValues } from '../../types'

import styles from './createVariant.module.scss'

const b = block(styles)

type ICreateVariantProps = {
  values: IVariantsFormValues
  attributesHash: Record<string, IAttribute>
}

type INextAttribute = {
  tempId: string
  valueId: string
}

const CreateVariant: React.FC<ICreateVariantProps> = ({ values, attributesHash }) => {
  const [{ value: products }, meta, { setValue }] = useField<IVariantsFormValues['products']>('products')

  const [isDialogOpen, setIsDialogOpen] = useBoolean()
  const [isAddVariantOpen, setIsAddVariantOpen] = useBoolean()

  const [nextAttributes, setNextAttributes] = useState<INextAttribute[]>([
    { tempId: createUniqId(), valueId: '' },
  ])

  const attribute = useMemo(() => {
    const foundAttribute = attributesHash[values.variantAttribute.attributeId]
    if (!foundAttribute) return null
    return foundAttribute
  }, [attributesHash, values])

  const options = useMemo(() => {
    if (!attribute || !attribute.attribute_values) return []
    const availableOptions = attribute.attribute_values.filter((record) =>
      values.variantAttribute.valueIds.includes(record.id),
    )
    return availableOptions.map(convertAttributeToOption)
  }, [attribute, values.variantAttribute.valueIds])

  const onSetProductsAttributes = useCallback(() => {
    const defaultProduct = products[0]

    const nextProducts: IVariantsFormValues['products'] = nextAttributes.map(({ tempId, valueId }) => ({
      tempUniqId: tempId,
      id: '',
      amount: '' as any, // because on form type as number
      amount_wait: '' as any, // because on form type as number
      multiply: defaultProduct.multiply ?? (1 as any),
      price: '',
      vendor_code: '',
      status: false,
      on_order: defaultProduct.on_order ?? false,
      weight: { value: '', type: defaultProduct.weight.type },
      volume: { value: '', type: defaultProduct.volume.type },
      length: { value: '', type: defaultProduct.length.type },
      height: { value: '', type: defaultProduct.length.type },
      width: { value: '', type: defaultProduct.length.type },
      stores: defaultProduct.stores,
      images: [],
      number: 9999,
      product_attribute: valueId,
    }))

    setValue(products.concat(nextProducts))
  }, [setValue, products, nextAttributes])

  const attributeSlotsLength = values.variantAttribute.valueIds.length
  const currentProducts = 1
  const hasMoreAvailableAttributes = nextAttributes.length !== attributeSlotsLength - currentProducts

  const disabledAddMoreBtn = values.variantAttribute.valueIds.length <= products.length

  const onAddEmptyAttribute = useCallback(() => {
    setNextAttributes((prevState) => prevState.concat([{ tempId: createUniqId(), valueId: '' }]))
  }, [])

  const onAttributeChange = useCallback((changeId: IPrimaryKey, valueId: string) => {
    setNextAttributes((prevState) =>
      prevState.map((record) => (changeId === record.tempId ? { ...record, valueId } : record)),
    )
  }, [])

  const onAttributeClear = useCallback((removeId: IPrimaryKey) => {
    setNextAttributes((prevState) =>
      prevState.map((record) => (removeId === record.tempId ? { ...record, valueId: '' } : record)),
    )
  }, [])

  const onRemoveAttribute = useCallback((removeId: IPrimaryKey) => {
    setNextAttributes((prevState) => prevState.filter(({ tempId }) => tempId !== removeId))
  }, [])

  const onCloseHandler = useCallback(() => {
    setIsDialogOpen.off()
  }, [setIsDialogOpen])

  const onSave = useCallback(() => {
    onCloseHandler()
    onSetProductsAttributes()
  }, [onCloseHandler, onSetProductsAttributes])

  useEffect(() => {
    if (isDialogOpen) {
      setNextAttributes([{ tempId: createUniqId(), valueId: '' }])
    }
  }, [isDialogOpen])

  return (
    <>
      <PopupList
        className={styles.addVariantsGroup}
        isOpen={isAddVariantOpen}
        element={
          <Button
            disabled={disabledAddMoreBtn}
            endIcon={<ArrowSmallDownSmallIcon className={b('addIcon', { open: isAddVariantOpen })} />}
            onClick={setIsAddVariantOpen.toggle}
          >
            Добавить варианты
          </Button>
        }
        onClose={setIsAddVariantOpen.off}
      >
        <Link component="button" className={styles.addOption} onClick={setIsDialogOpen.on}>
          Создать новый вариант
        </Link>
      </PopupList>

      <Dialog isOpen={isDialogOpen} onClose={onCloseHandler}>
        <DialogCloseButton onClick={onCloseHandler} />
        <DialogContent className={styles.content}>
          <Text className={styles.title}>Создание вариантов товара</Text>

          <div className={styles.variantsList}>
            {nextAttributes.map(({ tempId, valueId }, index) => (
              <div key={tempId} className={styles.row}>
                <SelectField
                  className={styles.select}
                  portal
                  label={attribute?.name}
                  placeholder="Выберите атрибут"
                  value={valueId}
                  options={options}
                  onChange={(payload) => onAttributeChange(tempId, payload)}
                  onClear={() => onAttributeClear(tempId)}
                />
                {index > 0 ? (
                  <IconButton className={styles.deleteButton} onClick={() => onRemoveAttribute(tempId)}>
                    <TrashIcon color="action" />
                  </IconButton>
                ) : null}
              </div>
            ))}
          </div>

          {hasMoreAvailableAttributes ? (
            <Link component="button" startIcon={<PlusIcon />} onClick={onAddEmptyAttribute}>
              Добавить вариант
            </Link>
          ) : null}

          <div className={styles.controlsGroup}>
            <Button onClick={onSave}>Сохранить</Button>
            <Button variant="outlined" onClick={onCloseHandler}>
              Отменить
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default memo(CreateVariant)
