import React, { useCallback } from 'react'
import { useField } from 'formik'

import Button, { IButtonProps } from 'components/Button'
import { IPriceRuleParameterType } from 'types/types'

import styles from './type.module.scss'

type ITypeProps = {
  name: string
  onTypeChange: (type: IPriceRuleParameterType) => void
}

const labels: Record<IPriceRuleParameterType, string> = {
  CATEGORY: 'Категория',
  GROUP: 'Группа',
  ATTRIBUTE: 'Атрибут',
  PRODUCT: 'Выбранные товары',
  ALL: 'Все товары',
}

const types: IPriceRuleParameterType[] = ['ALL', 'GROUP', 'CATEGORY', 'ATTRIBUTE', 'PRODUCT']

const TabButton: React.FC<IButtonProps & { isActive: boolean }> = ({ isActive, ...props }) => (
  <Button className={styles.tabButton} variant={isActive ? 'contained' : 'outlined'} {...props} />
)

const Type: React.FC<ITypeProps> = ({ name, onTypeChange }) => {
  const [{ value }] = useField<IPriceRuleParameterType>(name)

  const onButtonClick = useCallback(
    (type: IPriceRuleParameterType) => () => {
      onTypeChange(type)
    },
    [onTypeChange],
  )

  return (
    <div className={styles.container}>
      {types.map((type) => (
        <TabButton key={type} isActive={type === value} onClick={onButtonClick(type)}>
          {labels[type]}
        </TabButton>
      ))}
    </div>
  )
}

export default Type
