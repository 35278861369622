import { object, string, boolean } from 'yup'

import { REQUIRED_MESSAGE } from 'consts/errorMessages'

const validationSchema = object({
  notification: object({
    status: boolean().default(false),
    title: string().default(''),
    text: string()
      .default('')
      .test('text', (text, { parent, createError }) => {
        const { status } = parent as { status: boolean }
        if (status && !text.length) return createError({ message: REQUIRED_MESSAGE })
        return true
      }),
  }),
})

export default validationSchema
