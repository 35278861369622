import mergeWith from 'lodash/mergeWith'

const defaultCustomizer = (defaultValue: any, apiValue: any) => (apiValue === null ? defaultValue : apiValue)

const defaultFieldsHelper = <TSource1, TSource2>(
  apiModel: TSource1,
  defaultModel: TSource2,
): TSource1 & TSource2 => mergeWith({}, defaultModel, apiModel, defaultCustomizer)

export default defaultFieldsHelper
