import { DocumentNode, gql } from '@apollo/client'

import { IBaseProduct } from 'types/types'

export type IGetBaseProduct = {
  getBaseProduct: IBaseProduct
}

export type IGetBaseProductParams = {
  base_id: IPrimaryKey
}

export const buildGetBaseProductQuery = (fields: string): DocumentNode => gql`
  query getBaseProduct($base_id: ID!) {
    getBaseProduct(base_id: $base_id) {
      ${fields}
    }
  }
`
