import { ApolloError } from '@apollo/client'

const serverErrors: Record<string, string> = {
  'The email has already been taken.': 'Пользователь с таким E-mail уже существует.',
  'The name must not be greater than 250 characters.':
    'Поле Название не должно содержать больше 250 символов.',
}

export default function convertToFormErrors({ graphQLErrors }: ApolloError): null | Record<string, any> {
  if (!graphQLErrors.length) return null

  const errors = graphQLErrors
    .map((errorItem) => errorItem.extensions?.validation ?? null)
    .filter(Boolean)
    .map((item: { [key: string]: string[] }) =>
      Object.entries(item).map(([key, value]) => {
        const translatedErrors = value.map((error) => serverErrors[error] ?? error)
        return { [key]: translatedErrors.join(', ') }
      }),
    )
    .flat()

  const response = errors.reduce((acc, item) => ({ ...acc, ...item }), {})

  return response
}
