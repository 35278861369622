import React from 'react'

import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import useBoolean from 'hooks/useBoolean'
import useNotifyCms from 'hooks/useNotifyCms'
import { DELETE_BANNER, useDeleteBannerMutation } from 'graphql/mutations/deleteBanner'

type IRenderParams = {
  onClick: () => void
}

type IDeleteBannerProps = {
  bannerId: IPrimaryKey
  onSuccess: () => void
  render: (params: IRenderParams) => void
}

const DeleteBanner: React.FC<IDeleteBannerProps> = ({ bannerId, onSuccess, render }) => {
  const addNotify = useNotifyCms()

  const [iseOpen, setIsOpen] = useBoolean(false)

  const [onDeleteBanner] = useDeleteBannerMutation(DELETE_BANNER, {
    variables: {
      id: bannerId,
    },
    onCompleted: () => {
      setIsOpen.off()
      addNotify({ kind: 'success', message: 'Баннер удалён' })
      onSuccess()
    },
    onError: () => addNotify('error'),
  })

  return (
    <>
      <ConfirmDialog
        isOpen={iseOpen}
        title="Вы уверены, что хотите удалить баннер?"
        submitBtnText="Да, удалить"
        onSubmit={() => onDeleteBanner()}
        onClose={setIsOpen.off}
      />

      {render({ onClick: setIsOpen.on })}
    </>
  )
}

export default DeleteBanner
