import React from 'react'
import { Formik } from 'formik'
import { object, string } from 'yup'

import { REQUIRED_MESSAGE, WRONG_EMAIL_FORMAT } from 'consts/errorMessages'
import Link from 'components/Link'
import Button from 'components/Button'
import Text from 'components/Typography'
import AddSubmitShortcutFormHelper from 'components/AddSubmitShortcutFormHelper'
import TextInput from 'components/Inputs/TextInput'
import { clickOnSignUpLinkEvent } from 'utils/gtmHelper'

import { ILoginFormValues } from './types'

import styles from './loginForm.module.scss'

type ILoginFormProps = {
  submitLoading?: boolean
  onSubmit: (params: ILoginFormValues) => void
}

const defaultValues: ILoginFormValues = {
  email: '',
  password: '',
}

const PASSWORD_MIN_LENGTH = 8

const validationSchema = () =>
  object({
    email: string().required(REQUIRED_MESSAGE).email(WRONG_EMAIL_FORMAT),
    password: string()
      .required(REQUIRED_MESSAGE)
      .min(PASSWORD_MIN_LENGTH, `Минимальная длина пароля ${PASSWORD_MIN_LENGTH} символов`),
  })

const LoginForm: React.FC<ILoginFormProps> = ({ submitLoading, children, onSubmit }) => (
  <Formik
    enableReinitialize
    initialValues={defaultValues}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    {({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <AddSubmitShortcutFormHelper />
        <TextInput name="email" label="E-mail" offset="default" data-testid="email" />
        <TextInput
          name="password"
          type="password"
          label="Пароль"
          offset="default"
          data-testid="password"
          labelAsideRight={<Link href="/auth/recovery">Забыли пароль?</Link>}
        />
        {children}
        <Button type="submit" disabled={submitLoading} className={styles.submitButton}>
          Войти
        </Button>
        <Text align="center">
          Нет аккаунта?{' '}
          <Link href="/auth/sign-up" onClick={clickOnSignUpLinkEvent}>
            Зарегистрироваться
          </Link>
        </Text>
      </form>
    )}
  </Formik>
)

export default LoginForm
