import React from 'react'

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const SuccessIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <circle cx="12" cy="12" r="12" fill="#47A347" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3059 8.8558C16.7594 9.30938 16.7594 10.0448 16.3059 10.4983L11.6601 15.1441C11.2065 15.5977 10.4711 15.5977 10.0176 15.1441L7.69467 12.8212C7.2411 12.3676 7.2411 11.6323 7.69467 11.1787C8.14824 10.7251 8.88363 10.7251 9.3372 11.1787L10.8388 12.6803L14.6633 8.8558C15.1169 8.40223 15.8523 8.40223 16.3059 8.8558Z"
      fill="white"
    />
  </SvgIcon>
)

export default SuccessIcon
