import React, { useMemo } from 'react'

import { Product as IProduct } from 'types/types'
import renderCurrencySymbol from 'utils/renderCurrencySymbol'
import Switch from 'components/Switch'
import Text from 'components/Typography'
import useCurrentSeller from 'hooks/useCurrentSeller'

import Image from '../Image'

import styles from './variant.module.scss'

interface IVariantProps {
  product: IProduct
  onStatusToggle: (value: IProduct) => void
}

const Variant: React.FC<IVariantProps> = ({ product, onStatusToggle }) => {
  const { images = [], vendor_code: vendorCode = '', amount, status = false, price, stores } = product

  const { stores_status: storesStatus = false, settings } = useCurrentSeller()

  const totalAmount = useMemo(() => {
    if (storesStatus) {
      if (!stores) return null
      return stores.reduce((acc, item) => acc + (item.amount || 0), 0)
    }

    if (!amount) return null

    return amount
  }, [amount, storesStatus, stores])

  return (
    <div className={styles.wrapper}>
      <Image images={images} />
      <div className={styles.info}>
        <Text className={styles.vendorCode} size="small" color="textSecondary">{`Арт. ${vendorCode}`}</Text>
        {totalAmount ? <Text size="small">{`${totalAmount} шт.`}</Text> : '-'}
      </div>
      <Text className={styles.price}>
        {price}&nbsp;{renderCurrencySymbol(settings?.currency)}
      </Text>
      <Switch
        className={styles.switch}
        checked={status}
        gap="small"
        label={
          <Text size="small" color="textSecondary">
            {status ? 'доступен' : 'недоступен'}
          </Text>
        }
        onChange={() => onStatusToggle(product)}
      />
    </div>
  )
}

export default Variant
