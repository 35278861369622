import React, { TextareaHTMLAttributes } from 'react'
import clsx from 'clsx'

import styles from './TextArea.module.scss'

interface TextAreaType extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  className?: string
  onChangeHandler?: (value: string) => void
  name?: string
}

const TextArea: React.FC<TextAreaType> = ({ className, onChangeHandler, onChange, name, ...other }) => (
  <textarea
    className={clsx(styles.textArea, className)}
    onChange={onChangeHandler ? (e) => onChangeHandler(e.target.value) : onChange}
    {...other}
  />
)

export default TextArea
