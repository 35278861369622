import React from 'react'

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const SwitchIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M15.6364 6.54545C15.6364 5.54129 16.4504 4.72727 17.4545 4.72727C18.4587 4.72727 19.2727 5.54129 19.2727 6.54545C19.2727 7.54962 18.4587 8.36364 17.4545 8.36364C16.4504 8.36364 15.6364 7.54962 15.6364 6.54545Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.54545 2C4.03507 2 2 4.03507 2 6.54545C2 9.05584 4.03507 11.0909 6.54545 11.0909H17.4545C19.9649 11.0909 22 9.05584 22 6.54545C22 4.03507 19.9649 2 17.4545 2H6.54545ZM3.81818 6.54545C3.81818 5.03922 5.03922 3.81818 6.54545 3.81818H17.4545C18.9608 3.81818 20.1818 5.03922 20.1818 6.54545C20.1818 8.05169 18.9608 9.27273 17.4545 9.27273H6.54545C5.03922 9.27273 3.81818 8.05169 3.81818 6.54545Z"
    />
    <path d="M6.54545 15.6364C5.54129 15.6364 4.72727 16.4504 4.72727 17.4545C4.72727 18.4587 5.54129 19.2727 6.54545 19.2727C7.54962 19.2727 8.36364 18.4587 8.36364 17.4545C8.36364 16.4504 7.54962 15.6364 6.54545 15.6364Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.54545 12.9091C4.03507 12.9091 2 14.9442 2 17.4545C2 19.9649 4.03507 22 6.54545 22H17.4545C19.9649 22 22 19.9649 22 17.4545C22 14.9442 19.9649 12.9091 17.4545 12.9091H6.54545ZM3.81818 17.4545C3.81818 15.9483 5.03922 14.7273 6.54545 14.7273H17.4545C18.9608 14.7273 20.1818 15.9483 20.1818 17.4545C20.1818 18.9608 18.9608 20.1818 17.4545 20.1818H6.54545C5.03922 20.1818 3.81818 18.9608 3.81818 17.4545Z"
    />
  </SvgIcon>
)

export default SwitchIcon
