import { DocumentNode, gql, useMutation, MutationHookOptions } from '@apollo/client'

import { IManager, IManagerInput } from 'types/types'

export type IAddManager = {
  addManager: IManager
}

export type IAddManagerParams = {
  seller_id: IPrimaryKey
  input: IManagerInput
}

export const buildAddManagerQuery = (fields: string): DocumentNode => gql`
  mutation addManager($seller_id: ID!, $input: ManagerInput) {
    addManager(seller_id: $seller_id, input: $input) {
      ${fields}
    }
  }
`

export const useAddManagerMutation = <TData = IAddManager, TOptions = IAddManagerParams>(
  fields: DocumentNode,
  options?: MutationHookOptions<TData, TOptions>,
) => useMutation<TData, TOptions>(fields, options)
