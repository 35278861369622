import { useMemo } from 'react'

import { ISellerCMS } from 'contexts/SellerProvider'

type IUseLinkPublic = {
  link: string
}

const useLinkPublic = (seller: ISellerCMS): IUseLinkPublic => {
  const { domain = '', ext_domain = '', ext_domain_status } = seller

  const data = useMemo(() => {
    const publicLinkSplit = window.location.host.split('.')
    publicLinkSplit[0] = domain
    const link = publicLinkSplit.join('.')

    if (Number(ext_domain_status) > 1) {
      return {
        link: ext_domain,
      }
    }

    return {
      link,
    }
  }, [domain, ext_domain, ext_domain_status])

  return data
}

export default useLinkPublic
