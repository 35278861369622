import React, { memo, useCallback } from 'react'
import { useMutation } from '@apollo/client'

import TrashIcon from 'components/Icons/TrashIcon'
import EditIcon from 'components/Icons/EditIcon'
import Switch from 'components/Switch'
import Link from 'components/Link'
import Text from 'components/Typography'
import ListControlGroup from 'components/ListControlGroup'
import useCmsParams from 'hooks/useCmsParams'
import useNotifyCms from 'hooks/useNotifyCms'
import { IClient } from 'types/types'
import withErrorBoundary from 'hocs/withErrorBoundary'
import { SET_CLIENT_STATUS } from 'graphql/mutations/setClientStatus'
import DeleteClient from 'components/ModelControls/DeleteClient'

import styles from './_client.module.scss'

type IClientProps = {
  client: IClient
  refetchList: () => void
}

const Client: React.FC<IClientProps> = ({ client, refetchList }) => {
  const { id: clientId, name, status = false, orders = [] } = client

  const { sellerId } = useCmsParams()
  const addNotify = useNotifyCms()

  const [setClientStatus] = useMutation(SET_CLIENT_STATUS, {
    onCompleted: refetchList,
    onError: () => addNotify('error'),
  })

  const handleChange = useCallback(
    (event: InputChangeEvent) => {
      const { checked } = event.target

      setClientStatus({
        variables: {
          id: clientId,
          status: checked,
        },
      })
    },
    [setClientStatus, clientId],
  )

  return (
    <div className={styles.container}>
      <Link href={`/${sellerId}/clients/profile/${clientId}`} className={styles.nameLink}>
        {name}
      </Link>

      <Text className={styles.orders}>{orders.length}</Text>

      <div className={styles.actionsGroup}>
        <Switch
          className={styles.statusSwitch}
          isChecked={status}
          gap="small"
          label={
            <Text size="small" color="textSecondary">
              {status ? 'активен' : 'неактивен'}
            </Text>
          }
          onChange={handleChange}
        />

        <ListControlGroup>
          <Link href={`/${sellerId}/clients/profile/${clientId}`} startIcon={<EditIcon />}>
            Редактировать
          </Link>
          <DeleteClient
            clientId={clientId}
            onSuccess={refetchList}
            render={({ onClick }) => (
              <Link component="button" startIcon={<TrashIcon />} onClick={onClick}>
                Удалить
              </Link>
            )}
          />
        </ListControlGroup>
      </div>
    </div>
  )
}

export default withErrorBoundary(memo(Client))
