import { ISetPriceRuleParams } from 'graphql/mutations/setPriceRule'
import { IPriceRuleFormValues } from 'components/forms/PriceRuleForm'
import { IPriceRuleParameterGroupInput, IPriceRuleParameterInput } from 'types/types'

const convertParameter = ({
  discount_ratio,
  discount_type,
  parameter_type,
  localGroupId,
  localValueIds,
}: IPriceRuleFormValues['groupParameters'][0]['parameters'][0]): IPriceRuleParameterInput => {
  if (parameter_type === 'ALL') {
    return {
      discount_ratio: discount_ratio || '0',
      discount_type: discount_type === 'discount',
      parameter_type,
      destination_ids: [],
    }
  }

  if (parameter_type === 'CATEGORY' || parameter_type === 'GROUP') {
    return {
      discount_ratio: discount_ratio || '0',
      discount_type: discount_type === 'discount',
      parameter_type,
      destination_ids: [localGroupId!], // NOTE: !,
    }
  }

  if (parameter_type === 'ATTRIBUTE' || parameter_type === 'PRODUCT') {
    return {
      discount_ratio: discount_ratio || '0',
      discount_type: discount_type === 'discount',
      parameter_type,
      destination_ids: localValueIds,
    }
  }

  throw new Error('Unknown parameter type')
}

const prepareParametersGroups = (
  payload: IPriceRuleFormValues['groupParameters'],
): IPriceRuleParameterGroupInput[] =>
  payload.map(({ pricelistId, parameters }) => ({
    price_list_id: Number(pricelistId)!, // NOTE: !
    parameters: parameters.map(convertParameter),
  }))

const prepareSubmitValues = (payload: IPriceRuleFormValues): ISetPriceRuleParams['input'] => {
  const { name, status, position, clientsIds, addDefault, groupParameters } = payload

  const parametersGroups = prepareParametersGroups(groupParameters)

  return {
    name,
    number: Number(position),
    status,
    add_default: addDefault,
    client_ids: clientsIds,
    parameters_groups: parametersGroups,
  }
}

export default prepareSubmitValues
