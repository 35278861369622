import { Attribute as IAttribute } from 'types/types'

// NOTE: костыль по поиску атрибута, значения которого пришли с сервера. (атрибут значения которого приxодят нигде не получить.)
const findAttributeValueAttribute = (attributeValueIds: IPrimaryKey[], attributesList: IAttribute[]) => {
  const foundAttribute = attributesList.find((attribute) =>
    attribute.attribute_values?.find((attributeValue) => attributeValueIds.includes(attributeValue.id)),
  )

  if (!foundAttribute || !foundAttribute.attribute_values) return null

  return foundAttribute.attribute_values[0].attribute_id
}

export default findAttributeValueAttribute
