import React, { Component, ErrorInfo, ReactNode } from 'react'

import ErrorText from 'components/ErrorText'

type IErrorBoundaryProps = {
  onError?: () => void
  children: ReactNode
}

type IErrorBoundaryState = {
  error: Error | null
  errorInfo: ErrorInfo | null
}

class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
  constructor(props: IErrorBoundaryProps) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (this.props.onError) this.props.onError()
    this.setState({ error, errorInfo })
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div style={{ padding: 20 }}>
          <h3>Something went wrong.</h3>
          <ErrorText>{this.state.error && this.state.error.toString()}</ErrorText>
          <details style={{ whiteSpace: 'pre-wrap' }}>{this.state.errorInfo.componentStack}</details>
        </div>
      )
    }

    return <>{this.props.children}</>
  }
}

export default ErrorBoundary
