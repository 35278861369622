import React from 'react'

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const CopyIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5 5.5V3.90625C5.5 2.57733 6.57728 1.5 7.90625 1.5H20.0938C21.4227 1.5 22.5 2.57733 22.5 3.90625V16.0938C22.5 17.4227 21.4227 18.5 20.0938 18.5H18.5V20.0938C18.5 21.4227 17.4227 22.5 16.0938 22.5H3.90625C2.57731 22.5 1.5 21.4227 1.5 20.0938V7.90625C1.5 6.57731 2.57731 5.5 3.90625 5.5H5.5ZM7.5 3.90625C7.5 3.68188 7.68187 3.5 7.90625 3.5H20.0938C20.3181 3.5 20.5 3.68188 20.5 3.90625V16.0938C20.5 16.3181 20.3181 16.5 20.0938 16.5H18.5V7.90625C18.5 6.57731 17.4227 5.5 16.0938 5.5H7.5V3.90625ZM3.5 7.90625C3.5 7.68188 3.68188 7.5 3.90625 7.5H16.0938C16.3181 7.5 16.5 7.68188 16.5 7.90625V20.0938C16.5 20.3181 16.3181 20.5 16.0938 20.5H3.90625C3.68188 20.5 3.5 20.3181 3.5 20.0938V7.90625Z"
    />
  </SvgIcon>
)

export default CopyIcon
