import React, { memo, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { FormikHelpers } from 'formik'

import ResultTitle from 'components/ResultTitle'
import GoBackLink from 'components/GoBackLink'
import PageContainer from 'components/PageContainer'
import useCmsParams from 'hooks/useCmsParams'
import useNotifyCms from 'hooks/useNotifyCms'
import convertToFormErrors from 'utils/convertToFormErrors'
import phoneFormatSave from 'utils/phoneFormatSave'
import withLeaveThisPageModal from 'hocs/withLeaveThisPageModal'
import withErrorBoundary from 'hocs/withErrorBoundary'
import ContactForm, { IContactFormValues } from 'components/forms/ContactForm'
import { buildAddContactQuery, useAddContactMutation } from 'graphql/mutations/addContact'
import { IContactInput } from 'types/types'

const initialValue: IContactFormValues = {
  status: true,
  phone: '',
  email: '',
  name: '',
  client_ids: [],
}

const ADD_CONTACT = buildAddContactQuery(`
  id
  name
`)

const prepareSubmitValues = ({ phone, ...rest }: IContactFormValues): IContactInput => ({
  ...rest,
  phone: phoneFormatSave(phone),
})

const ContactCreate: React.FC = () => {
  const { sellerId } = useCmsParams()
  const { replace } = useHistory()
  const addNotify = useNotifyCms()

  const [onAddContact, { loading }] = useAddContactMutation(ADD_CONTACT)

  const onSubmit = useCallback(
    (values: IContactFormValues, { setErrors }: FormikHelpers<IContactFormValues>) =>
      onAddContact({
        variables: {
          seller_id: sellerId,
          input: prepareSubmitValues(values),
        },
      })
        .then(({ data }) => {
          addNotify('success')
          if (data) replace(`/${sellerId}/contacts/${data.addContact.id}`)
        })
        .catch((error) => {
          addNotify('error')
          const errors = convertToFormErrors(error)
          if (errors) setErrors(errors)
        }),
    [onAddContact, addNotify, replace, sellerId],
  )

  return (
    <PageContainer>
      <GoBackLink href={`/${sellerId}/contacts/`} />
      <ResultTitle>Создание контакта</ResultTitle>
      <ContactForm initialValue={initialValue} submitLoading={loading} onSubmit={onSubmit} />
    </PageContainer>
  )
}

export default withErrorBoundary(withLeaveThisPageModal(memo(ContactCreate)))
