import React, { memo, ReactNode, useCallback, useMemo } from 'react'
import { useMutation } from '@apollo/client'
import { FormikHelpers } from 'formik'

import useCmsParams from 'hooks/useCmsParams'
import useNotifyCms from 'hooks/useNotifyCms'
import {
  buildSetBaseProductQuery,
  ISetBaseProduct,
  ISetBaseProductParams,
} from 'graphql/mutations/setBaseProduct'
import withErrorBoundary from 'hocs/withErrorBoundary'
import withLeaveThisPageModal from 'hocs/withLeaveThisPageModal'
import BaseProductForm, { IBaseProductFormValues } from 'components/forms/BaseProductForm'
import createUrlFromUploadedFile, { IUploadedData } from 'helpers/createUrlFromUploadedFile'
import convertToFormErrors from 'utils/convertToFormErrors'
import { Image, IBaseProduct } from 'types/types'

import prepareInitialValues from './utils/prepareInitialValues'
import prepareSubmitValues from './utils/prepareSubmitValues'

type IBaseFieldsProps = {
  baseProduct: IBaseProduct
  onSuccess: () => void
  renderControls?: () => ReactNode
}

const SET_BASE_PRODUCT = buildSetBaseProductQuery(`
  id
  name
  category_id
  status
  variants_status

  products {
    id
    number
  }
`)

const keepFile = (value: Image | File): value is File => value instanceof File
const keepNotFile = (value: Image | File): value is Image => !(value instanceof File)

const BaseFields: React.FC<IBaseFieldsProps> = ({ baseProduct, onSuccess, renderControls }) => {
  const { variants_status } = baseProduct

  const addNotify = useNotifyCms()
  const { productId } = useCmsParams()

  const initialValues = useMemo(() => prepareInitialValues(baseProduct), [baseProduct])

  const [onSetProduct, { loading }] = useMutation<ISetBaseProduct, ISetBaseProductParams>(SET_BASE_PRODUCT)

  const onSubmit = useCallback(
    async (values: IBaseProductFormValues, { setErrors }: FormikHelpers<IBaseProductFormValues>) => {
      const { images, ...restValues } = values

      const imagesToLoad = images.filter(keepFile)
      const currentImages = images.filter(keepNotFile)

      let uploadedImages: IUploadedData[] = []

      if (imagesToLoad.length > 0) {
        try {
          uploadedImages = await createUrlFromUploadedFile(imagesToLoad, { type: 'images' })
        } catch (error) {
          addNotify({ kind: 'error', message: 'Ошибка сохранения изображений' })
        }
      }

      await onSetProduct({
        variables: {
          id: productId,
          input: prepareSubmitValues({ ...restValues, currentImages, uploadedImages }),
        },
      })
        .then(() => {
          addNotify('success')
          onSuccess()
        })
        .catch((error) => {
          addNotify('error')
          const errors = convertToFormErrors(error)
          if (errors) setErrors(errors)
        })
    },
    [onSetProduct, addNotify, onSuccess, productId],
  )

  return (
    <BaseProductForm
      isDisabledVariantInputs={variants_status}
      submitLoading={loading}
      initialValues={initialValues}
      onSubmit={onSubmit}
      renderControls={renderControls}
    />
  )
}

export default withErrorBoundary(withLeaveThisPageModal(memo(BaseFields)))
