import React, { memo, useCallback } from 'react'

import { DEFAULT_PAGES_LIMIT } from 'consts'
import { useGetOrdersQuery } from 'graphql/queries/getOrders'
import Text, { H1 } from 'components/Typography'
import Preloader from 'components/Preloader'
import ResultTitle from 'components/ResultTitle'
import PageContainer from 'components/PageContainer'
import DownloadIcon from 'components/Icons/DownloadIcon'
import hasPagination from 'utils/hasPagination'
import withErrorBoundary from 'hocs/withErrorBoundary'
import useBoolean from 'hooks/useBoolean'
import useCmsParams from 'hooks/useCmsParams'
import Pagination from 'components/Pagination'
import ApiError from 'components/ApiError'
import Link from 'components/Link'
import useSearchParams from 'hooks/useSearchParams'

import ImportDialog from './components/ImportDialog'
import Result from './components/Result'
import { GET_ORDERS } from './queries'

import styles from './ordersList.module.scss'

type IPayload = {
  page: string
}

const initPayload: IPayload = {
  page: '1',
}

const EmptyOrders: React.FC = () => (
  <div className={styles.emptyContianer}>
    <H1 className={styles.emptyTitle}>Здесь будут заказы вашего магазина</H1>
    <Text>У вас пока нет ни одного заказа. Он отобразится здесь, как только поступит.</Text>
    <Text>Мы пришлем вам уведомление на электронную почту.</Text>
  </div>
)

const OrdersList: React.FC = () => {
  const { sellerId } = useCmsParams()

  const [isImportOpen, setIsImportOpen] = useBoolean(false)
  const [{ page }, setSearchParams] = useSearchParams<IPayload>(initPayload)

  const { data, loading, error } = useGetOrdersQuery(GET_ORDERS, {
    variables: {
      seller_id: sellerId,
      paginate: {
        page: page ? Number(page) : 1,
        limit: DEFAULT_PAGES_LIMIT,
      },
    },
    fetchPolicy: 'network-only',
  })

  const { getOrders: { list: orders = [], elements = 0, pages = 0 } = {} } = data || {}

  const onPageChange = useCallback(
    (nextPage: number) => {
      setSearchParams((prev) => ({ ...prev, page: nextPage.toString() }))
    },
    [setSearchParams],
  )

  const isEmpty = !loading && !orders.length

  if (error) {
    return (
      <PageContainer>
        <ResultTitle>Заказы</ResultTitle>
        <ApiError error={error} />
      </PageContainer>
    )
  }

  if (isEmpty) return <EmptyOrders />

  return (
    <PageContainer>
      <div className={styles.headerGroup}>
        <ResultTitle>Заказы</ResultTitle>
        <Link component="button" onClick={setIsImportOpen.on} startIcon={<DownloadIcon />}>
          Выгрузить заказы
        </Link>
      </div>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {orders.length ? <Result orders={orders} /> : null}

          {hasPagination(elements) && (
            <Pagination page={Number(page)} count={pages} onChange={onPageChange} />
          )}
        </>
      )}
      <ImportDialog isOpen={isImportOpen} onClose={setIsImportOpen.off} />
    </PageContainer>
  )
}

export default withErrorBoundary(memo(OrdersList))
