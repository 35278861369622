import React, { memo, useMemo } from 'react'
import { useQuery } from '@apollo/client'

import ResultTitle from 'components/ResultTitle'
import PageContainer from 'components/PageContainer'
import GoBackLink from 'components/GoBackLink'
import Preloader from 'components/Preloader'
import ImportModal from 'components/ImportModal'
import ApiError from 'components/ApiError'
import useCmsParams from 'hooks/useCmsParams'
import {
  buildGetImportSchedulesQuery,
  IGetImportSchedules,
  IGetImportSchedulesParams,
} from 'graphql/queries/getImportSchedules'
import {
  buildGetImportSchemesQuery,
  IGetImportSchemes,
  IGetImportSchemesParams,
} from 'graphql/queries/getImportSchemes'
import withErrorBoundary from 'hocs/withErrorBoundary'
import useBoolean from 'hooks/useBoolean'
import useCmsLinks from 'hooks/useCmsLinks'
import { defaultImportSchemeOption } from 'consts/options'

import ImportLink from './components/ImportLink'
import NoItems from './components/NoItems'

const GET_IMPORT_SCHEDULES = buildGetImportSchedulesQuery(`
  id
  mode
  ext_url
  auto_update
  period_update
  last_update
  import_products_scheme_id
`)

const GET_IMPORT_SCHEMES = buildGetImportSchemesQuery(`
  id
  name
`)

const extractOption = ({ id, name = 'NO_NAME' }: { id: IPrimaryKey; name?: string }) => ({ id, name })

const ImportLinks: React.FC = () => {
  const { sellerId } = useCmsParams()
  const { integrations } = useCmsLinks()

  const [isImportDialogOpen, setImportDialog] = useBoolean()

  const { data, loading, error } = useQuery<IGetImportSchedules, IGetImportSchedulesParams>(
    GET_IMPORT_SCHEDULES,
    {
      variables: {
        seller_id: sellerId,
      },
      fetchPolicy: 'network-only',
    },
  )

  const importSchedules = data?.getImportSchedules || []

  const { data: importSchemesData } = useQuery<IGetImportSchemes, IGetImportSchemesParams>(
    GET_IMPORT_SCHEMES,
    {
      fetchPolicy: 'network-only',
      variables: {
        seller_id: sellerId,
      },
    },
  )

  const importSchemes = useMemo(() => importSchemesData?.getImportSchemes || [], [importSchemesData])

  const importSchemesOptions = useMemo(
    () => [defaultImportSchemeOption].concat(importSchemes.map(extractOption)),
    [importSchemes],
  )

  const noItems = !loading && !importSchedules.length

  return (
    <PageContainer>
      <GoBackLink href={integrations} />

      {noItems ? (
        <NoItems onClick={setImportDialog.on} />
      ) : (
        <>
          <ResultTitle>Импорт товаров по ссылке</ResultTitle>

          {loading ? (
            <Preloader />
          ) : (
            <>
              {importSchedules.map((schedule) => (
                <ImportLink
                  key={schedule.id}
                  schedule={schedule}
                  importSchemesOptions={importSchemesOptions}
                />
              ))}
            </>
          )}
        </>
      )}

      <ApiError error={error} />

      <ImportModal isOpen={isImportDialogOpen} initUpdateFrom="link" onClose={setImportDialog.off} />
    </PageContainer>
  )
}

export default withErrorBoundary(memo(ImportLinks))
