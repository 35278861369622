import { DocumentNode, gql, QueryHookOptions, useQuery } from '@apollo/client'

import { IBanner } from 'types/types'

export type IGetBanner = {
  getBanner: IBanner
}

export type IGetBannerParams = {
  id: IPrimaryKey
}

export const buildGetBannerQuery = (fields: string): DocumentNode => gql`
  query getBanner($id: ID!) {
    getBanner(id: $id) {
      ${fields}
    }
  }
`

export const useGetBannerQuery = <TData = IGetBanner, TOptions = IGetBannerParams>(
  fields: DocumentNode,
  options?: QueryHookOptions<TData, TOptions>,
) => useQuery<TData, TOptions>(fields, options)
