import React, { useState } from 'react'
import { useMutation } from '@apollo/client'

import CheckboxLabel from 'components/CheckboxLabel'
import CopyIcon from 'components/Icons/CopyIcon'
import TrashIcon from 'components/Icons/TrashIcon'
import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import { COPY_BASE_PRODUCTS_LIST } from 'graphql/mutations/copyBaseProductsList'
import { DELETE_BASE_PRODUCTS_LIST } from 'graphql/mutations/delBaseProductsList'
import { SET_BASE_PRODUCTS_STATUS_LIST } from 'graphql/mutations/setBaseProductsStatusList'
import useBoolean from 'hooks/useBoolean'
import useNotifyCms from 'hooks/useNotifyCms'
import Select from 'components/Select'
import { GET_BASE_PRODUCTS } from 'views/BaseProducts/BaseProductsList/queries'

import Button from './components/Button'
import Wrapper from './components/Wrapper'

interface IMultiActionsProps {
  isAllSelected: boolean
  selectedIds: string[]
  onSelectAllToggle: (event: InputChangeEvent, checked: boolean) => void
  removeAllSelected: () => void
}

const statusOptions = [
  { id: 'on', name: 'Доступен' },
  { id: 'off', name: 'Недоступен' },
]

const MultiActions: React.FC<IMultiActionsProps> = ({
  isAllSelected,
  selectedIds,
  onSelectAllToggle,
  removeAllSelected,
}) => {
  const addNotify = useNotifyCms()

  const [isOpenDialog, setIsOpenDialog] = useBoolean()
  const [status, setStatus] = useState<string | null>(null)

  const hasAnySelected = selectedIds.length > 0

  const [copyAll] = useMutation(COPY_BASE_PRODUCTS_LIST, {
    refetchQueries: [GET_BASE_PRODUCTS],
    onCompleted: () => {
      addNotify('success')
      removeAllSelected()
    },
    onError: () => addNotify('error'),
  })

  const [toggleStatus] = useMutation(SET_BASE_PRODUCTS_STATUS_LIST, {
    refetchQueries: [GET_BASE_PRODUCTS],
    onCompleted: () => {
      addNotify('success')
      removeAllSelected()
      setStatus(null)
    },
    onError: () => addNotify('error'),
  })

  const [deleteAll] = useMutation(DELETE_BASE_PRODUCTS_LIST, {
    refetchQueries: [GET_BASE_PRODUCTS],
    onCompleted: () => {
      addNotify({ kind: 'success', message: 'Товары удалены' })
      removeAllSelected()
      setIsOpenDialog.off()
    },
    onError: () => addNotify('error'),
  })

  const onCopyClick = () => copyAll({ variables: { ids: selectedIds } })

  const onSubmitDelete = () => deleteAll({ variables: { ids: selectedIds } })

  const onStatusChange = (newStatus: IPrimaryKey | null) => {
    setStatus(newStatus)
    toggleStatus({ variables: { ids: selectedIds, status: newStatus === 'on' } })
  }

  return (
    <Wrapper>
      <CheckboxLabel label="Выбрать все" checked={isAllSelected} onChange={onSelectAllToggle} />
      <Button disabled={!hasAnySelected} icon={<CopyIcon />} onClick={onCopyClick}>
        Копировать
      </Button>
      <Button disabled={!hasAnySelected} icon={<TrashIcon />} onClick={setIsOpenDialog.on}>
        Удалить
      </Button>
      <Select
        disabled={!hasAnySelected}
        value={status}
        placeholder="Изменить активность"
        options={statusOptions}
        onChange={onStatusChange}
      />

      <ConfirmDialog
        isOpen={isOpenDialog}
        title="Вы уверены, что хотите удалить товары?"
        submitBtnText="Да, удалить"
        onClose={setIsOpenDialog.off}
        onSubmit={onSubmitDelete}
      />
    </Wrapper>
  )
}

export default MultiActions
