import { gql } from '@apollo/client'

import { Status } from 'types/types'

export type ICopyBaseProduct = {
  copyBaseProduct: Status
}

export type ICopyBaseProductParams = {
  id: IPrimaryKey
}

export const COPY_BASE_PRODUCT = gql`
  mutation copyBaseProduct($id: ID!) {
    copyBaseProduct(id: $id) {
      status
      message
    }
  }
`
