import React from 'react'

import Text from 'components/Typography'

import styles from './booleanLikeInput.module.scss'

type IBooleanLikeInputProps = {
  title: string
}

const BooleanLikeInput: React.FC<IBooleanLikeInputProps> = ({ title, children }) => (
  <div>
    <Text className={styles.title}>{title} </Text>
    {children}
  </div>
)

export default BooleanLikeInput
