import React, { memo } from 'react'
import { useField } from 'formik'

import useUpdateEffect from 'hooks/useUpdateEffect'

import { ICategoryFormValues } from '../../types'

type IAttributeIdListenerProps = {
  name: string
}

type IAttribute = ICategoryFormValues['attributes'][0]

const AttributeIdListener: React.FC<IAttributeIdListenerProps> = ({ name }) => {
  const [{ value: attributeId }] = useField<IAttribute['attribute_id']>(`${name}.attribute_id`)
  const [input, meta, { setValue }] = useField<IAttribute['value_ids']>(`${name}.value_ids`)

  useUpdateEffect(() => {
    setValue([])
  }, [attributeId])

  return null
}

export default memo(AttributeIdListener)
