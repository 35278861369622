import React from 'react'

import Text, { H3 } from 'components/Typography'

import styles from './noSearchResult.module.scss'

const NoSearchResult: React.FC = () => (
  <div>
    <H3 className={styles.title}>По вашему запросу ничего не найдено</H3>
    <Text>Проверьте, нет ли опечатки или попробуйте изменить ваш запрос.</Text>
  </div>
)

export default NoSearchResult
