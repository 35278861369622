import React, { FC } from 'react'

import DropMenu from 'components/DropMenu'
import Text from 'components/Typography'
import TextInput from 'components/Inputs/TextInput'
import SwitchInput from 'components/Inputs/SwitchInput'
import numberFormat from 'utils/formInputs/numberFormat'

import Box from '../Box'

import styles from './settings.module.scss'

const Settings: FC = () => (
  <DropMenu title="Настройки" resetPadding>
    <Box>
      <div className={styles.nameStatusGroup}>
        <TextInput name="name" required label="Наименование" placeholder="Введите наименование" />
        <SwitchInput
          name="status"
          className={styles.activeSwitch}
          placement="right"
          gap="medium"
          label={
            <Text size="small" color="textSecondary">
              активна
            </Text>
          }
        />
      </div>
      <TextInput
        name="position"
        className={styles.defaultInput}
        required
        label="Сортировка"
        placeholder="Введите сортировку"
        transform={numberFormat}
      />
    </Box>
  </DropMenu>
)

export default Settings
