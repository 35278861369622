import React from 'react'

import { H2 } from 'components/Typography'
import Button from 'components/Button'
import Dialog from 'components/Dialog'
import DialogContent from 'components/DialogContent'

import styles from './LeaveThisPageModal.module.scss'

type ILeaveThisPageModalProps = {
  onClose: () => void
  onExit: () => void
}

const LeaveThisPageModal: React.FC<ILeaveThisPageModalProps> = ({ onClose, onExit }) => (
  <Dialog isOpen onClose={onClose}>
    <DialogContent className={styles.content}>
      <H2 className={styles.title}>На странице есть несохраненные изменения</H2>
      <p>Вы действительно хотите покинуть эту страницу?</p>
      <div className={styles.actionsGroup}>
        <Button onClick={onClose}>Остаться на странице</Button>
        <Button variant="outlined" onClick={onExit}>
          Покинуть страницу
        </Button>
      </div>
    </DialogContent>
  </Dialog>
)

export default LeaveThisPageModal
