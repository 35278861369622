import { CURRENCY_SYMBOL } from 'consts/currency'
import { ICurrency } from 'types/types'

const renderCurrencySymbol = (currency?: ICurrency) => {
  if (!currency) {
    console.error('Currency not found')
    return ''
  }
  return CURRENCY_SYMBOL[currency]
}

export default renderCurrencySymbol
