import React, { FC, memo, useMemo, useState, useCallback } from 'react'
import { debounce, isEqual } from 'lodash'

import withErrorBoundary from 'hocs/withErrorBoundary'

import Preloader from 'components/Preloader'
import PageContainer from 'components/PageContainer'
import ResultTitle from 'components/ResultTitle'
import Link from 'components/Link'
import Button from 'components/Button'
import ApiError from 'components/ApiError'
import ResultSearchFilter from 'components/ResultSearchFilter'
import Pagination from 'components/Pagination'
import NoSearchResult from 'components/NoSearchResult'
import { SEARCH_DEBOUNCE_TIMEOUT, SEARCH_INPUT_LIMIT } from 'consts'
import useCmsParams from 'hooks/useCmsParams'
import useSearchParams from 'hooks/useSearchParams'
import useBoolean from 'hooks/useBoolean'
import useCmsLinks from 'hooks/useCmsLinks'
import hasPagination from 'utils/hasPagination'
import buildPaginateParams from 'utils/buildPaginateParams'
import { buildGetPriceListsQuery, useGetPriceListsQuery } from 'graphql/queries/getPriceLists'

import Result from './components/Result'
import Empty from './components/Empty'

import styles from './priceListsList.module.scss'

type IPayload = {
  search: string
  page: string
}

const initPayload: IPayload = {
  search: '',
  page: '',
}

const GET_PRICE_LISTS = buildGetPriceListsQuery(`
  id 
  name
  default_price
`)

const PriceListsList: FC = () => {
  const { sellerId } = useCmsParams()
  const { priceListCreateLink } = useCmsLinks()

  const [searchParams, setSearchParams] = useSearchParams<IPayload>(initPayload)
  const { page, search } = searchParams
  const [searchInput, setSearchInput] = useState<string>(search)
  const [initialLoading, { off: loaded }] = useBoolean(true)

  const hasFilters = useMemo(() => !isEqual(initPayload, searchParams), [searchParams])

  const onSearchDebounce = useMemo(
    () =>
      debounce((payload: string) => {
        setSearchParams({ ...searchParams, page: '1', search: payload })
      }, SEARCH_DEBOUNCE_TIMEOUT),
    [setSearchParams, searchParams],
  )

  const { data, loading, error, refetch } = useGetPriceListsQuery(GET_PRICE_LISTS, {
    variables: {
      seller_id: sellerId,
      search,
      paginate: buildPaginateParams(page),
    },
    onCompleted: () => {
      if (initialLoading) loaded()
    },
    onError: () => {
      if (initialLoading) loaded()
    },
    fetchPolicy: 'network-only',
  })

  const { getPriceLists: { list: priceLists = [], elements = 0, pages = 0 } = {} } = data || {}

  const isEmpty = !loading && !hasFilters && !priceLists.length

  const onSearchChange = useCallback(
    (event: InputChangeEvent) => {
      const { value } = event.target
      if (value.length > SEARCH_INPUT_LIMIT) return
      setSearchInput(value)
      onSearchDebounce(value)
    },
    [onSearchDebounce],
  )

  const onSearchClear = useCallback(() => {
    setSearchInput('')
    setSearchParams((prev) => ({ ...prev, page: '1', search: '' }))
  }, [setSearchParams])

  const onPageChange = useCallback(
    (nextPage: number) => {
      setSearchParams((prev) => ({ ...prev, page: nextPage.toString() }))
    },
    [setSearchParams],
  )

  if (initialLoading) {
    return <Preloader />
  }

  if (isEmpty) {
    return <Empty />
  }

  if (error) {
    return (
      <PageContainer>
        <ResultTitle>Прайс листы</ResultTitle>
        <div className={styles.filtersGroup}>
          <Link href={priceListCreateLink}>
            <Button>Создать прайс лист</Button>
          </Link>
        </div>
        <ApiError error={error} />
      </PageContainer>
    )
  }

  return (
    <PageContainer>
      <ResultTitle>Прайс листы</ResultTitle>
      <div className={styles.filtersGroup}>
        <Link href={priceListCreateLink}>
          <Button>Создать прайс лист</Button>
        </Link>

        <ResultSearchFilter
          value={searchInput}
          placeholder="Поиск по прайс листам"
          onChange={onSearchChange}
          onClear={onSearchClear}
        />
      </div>

      {loading ? (
        <Preloader />
      ) : (
        <>
          {priceLists.length ? <Result priceLists={priceLists} refetchList={refetch} /> : null}

          {hasPagination(elements) && (
            <Pagination page={page ? Number(page) : 1} count={pages} onChange={onPageChange} />
          )}

          {priceLists.length ? null : <NoSearchResult />}
        </>
      )}
    </PageContainer>
  )
}

export default withErrorBoundary(memo(PriceListsList))
