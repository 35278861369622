import { Dispatch, SetStateAction, useCallback, useState } from 'react'

interface IReturnType {
  setValue: Dispatch<SetStateAction<boolean>>
  on: () => void
  off: () => void
  toggle: () => void
}

type IReturn = [boolean, IReturnType]

const useBoolean = (defaultValue?: boolean): IReturn => {
  const [value, setValue] = useState(!!defaultValue)

  const on = useCallback(() => setValue(true), [])
  const off = useCallback(() => setValue(false), [])
  const toggle = useCallback(() => setValue((x) => !x), [])

  return [value, { setValue, on, off, toggle }]
}

export default useBoolean
