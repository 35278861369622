import React, { useCallback } from 'react'
import { useField } from 'formik'

import MultiDragAndDropField, { IMultiDragAndDropFieldProps } from 'components/MultiDragAndDropField'
import nonNullable from 'utils/nonNullable'

type IMultiDragAndDropInputProps = Omit<IMultiDragAndDropFieldProps, 'values' | 'onChange' | 'onDelete'> & {
  name: string
}

type IValueItem = { id?: IPrimaryKey; path?: string; flag?: string } | File

const formikEvent = <T,>(name: string, value: T) => ({ target: { name, value } })

const MultiDragAndDropInput: React.FC<IMultiDragAndDropInputProps> = ({ name, ...props }) => {
  const [{ value, onChange }] = useField<IValueItem[]>(name)

  const onChangeHelper = useCallback(
    (payload: Array<File>) => {
      const nextValue = value.concat(payload)

      onChange(formikEvent(name, nextValue))
    },
    [onChange, name, value],
  )

  const onDelete = useCallback(
    (removeIndex: number) => {
      const nextValue = value
        .map((record, index) => {
          if (record instanceof File && index === removeIndex) return null
          if (!(record instanceof File) && index === removeIndex) return { ...record, flag: 'delete' }
          return record
        })
        .filter(nonNullable)

      onChange(formikEvent(name, nextValue))
    },
    [onChange, name, value],
  )

  return <MultiDragAndDropField values={value} onChange={onChangeHelper} onDelete={onDelete} {...props} />
}

export default MultiDragAndDropInput
