import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client'

import {
  IAddImportSchedules,
  IAddImportSchedulesParams,
  ADD_IMPORT_SCHEDULES,
} from 'graphql/mutations/addImportSchedules'
import {
  buildGetImportScheduleQuery,
  IGetImportSchedule,
  IGetImportScheduleParams,
} from 'graphql/queries/getImportSchedule'
import {
  buildGetImportSchemesQuery,
  IGetImportSchemes,
  IGetImportSchemesParams,
} from 'graphql/queries/getImportSchemes'
import Text, { H2 } from 'components/Typography'
import Tabs from 'components/Tabs'
import Tab from 'components/Tab'
import RadioGroup from 'components/RadioGroup'
import RadioLabel from 'components/RadioLabel'
import CheckboxLabel from 'components/CheckboxLabel'
import TextField from 'components/TextField'
import Link from 'components/Link'
import Button from 'components/Button'
import DragAndDrop from 'components/ui-kit/dragAndDrop'
import useBoolean from 'hooks/useBoolean'
import useCmsParams from 'hooks/useCmsParams'
import Switch from 'components/Switch'
import SelectField from 'components/SelectField'
import PlusIcon from 'components/Icons/PlusIcon'
import Dialog from 'components/Dialog'
import DialogContent from 'components/DialogContent'
import DialogCloseButton from 'components/DialogCloseButton'
import ProgressBar from 'components/ProgressBar'
import LoadingDialog from 'components/Dialogs/LoadingDialog'
import { defaultImportSchemeOption, IUpdateImportTime, updateImportTimeOptions } from 'consts/options'
import withErrorBoundary from 'hocs/withErrorBoundary'
import { IImportScheduleInput } from 'types/types'
import createUrlFromUploadedFile from 'helpers/createUrlFromUploadedFile'
import useInterval from 'hooks/useInterval'

import { GET_BASE_PRODUCTS } from 'views/BaseProducts/BaseProductsList/queries' // NOTE: whrong

import ErrorModal from './components/ErrorModal'
import ResponseModal from './components/ResponseModal'

import styles from './ImportModal.module.scss'

type IImportState = 'add' | 'update'
type IUpdateFrom = 'file' | 'link'

interface IImportModalProps {
  isOpen: boolean
  initUpdateFrom?: IUpdateFrom
  onClose: () => void
}

const extractOption = ({ id, name = 'NO_NAME' }: { id: IPrimaryKey; name?: string }) => ({ id, name })

const GET_IMPORT_SCHEDULE = buildGetImportScheduleQuery(`
  id
  percent_update
`)

const GET_IMPORT_SCHEMES = buildGetImportSchemesQuery(`
  id
  name
`)

const INIT_UPDATE_PERCENT = 0

// TODO: move to components/Dialogs
const ImportModal: React.FC<IImportModalProps> = ({ isOpen, initUpdateFrom = 'file', onClose }) => {
  const { sellerId } = useCmsParams()
  const apolloClient = useApolloClient()

  const [updatePercent, setUpdatePercent] = useState<number>(INIT_UPDATE_PERCENT)
  const [tab, setTab] = useState<IImportState>('add')
  const [updateFrom, setUpdateFrom] = useState<IUpdateFrom>(initUpdateFrom)
  const [importSchemeId, setImportSchemeId] = useState<string>(defaultImportSchemeOption.id)
  const [responseMessage, setResponseMessage] = useState<string | undefined>('')
  const [updateUrls, setUpdateUrls] = useState<string[]>([''])
  const [updatePeriod, setUpdatePeriod] = useState<IUpdateImportTime>('3')
  const [file, setFile] = useState<File | null>(null)
  const [checkImportId, setCheckImportId] = useState<{ id: number } | null>(null)

  const [isUploadLoading, setIsUploadLoading] = useBoolean()
  const [isResponseModalOpen, setIsResponseModalOpen] = useBoolean()
  const [isErrorModalOpen, setIsErrorModalOpen] = useBoolean()
  const [isAutoUpdate, setIsAutoUpdate] = useBoolean()
  const [isAllDataUpdate, setIsAllDataAutoUpdate] = useBoolean()

  const isUploading = !!checkImportId

  const isDisabledUpload = useMemo(() => {
    if (updateFrom === 'file') return !file
    return false
  }, [updateFrom, file])

  const [onAddImportSchedules] = useMutation<IAddImportSchedules, IAddImportSchedulesParams>(
    ADD_IMPORT_SCHEDULES,
    {
      onCompleted(data) {
        if (data.addImportSchedules.status) {
          setCheckImportId(data.addImportSchedules.data ? JSON.parse(data.addImportSchedules.data) : null)
        } else {
          setIsErrorModalOpen.on()
          setResponseMessage('Ошибка загрузки')
        }
      },
      onError() {
        setIsUploadLoading.off()
        setIsErrorModalOpen.on()
        setResponseMessage('Ошибка загрузки')
      },
    },
  )

  const onTabChange = useCallback(
    (_: any, newValue: IImportState) => {
      setTab(newValue)
      if (newValue === 'update') {
        setIsAllDataAutoUpdate.off()
      }
    },
    [setIsAllDataAutoUpdate],
  )

  const onUpdateFromChange = useCallback((_: any, newValue: IUpdateFrom) => setUpdateFrom(newValue), [])

  const onUpdateUrlChange = useCallback(
    (updateIndex: number) => (event: InputChangeEvent) => {
      const nextValue = event.target.value
      setUpdateUrls((prevState) =>
        prevState.map((value, index) => (index === updateIndex ? nextValue : value)),
      )
    },
    [],
  )

  const onUpdateUrlAdd = useCallback(() => setUpdateUrls((prevState) => prevState.concat('')), [])

  const onAutoUpdateChange = useCallback(
    (event: InputChangeEvent) => {
      const nextAutoUpdateValue = event.target.checked
      setIsAutoUpdate.setValue(nextAutoUpdateValue)
    },
    [setIsAutoUpdate],
  )

  const onUpload = useCallback(async () => {
    onClose()

    setIsUploadLoading.on()

    const ensureCorrectSchemeId = importSchemeId === defaultImportSchemeOption.id ? undefined : importSchemeId

    if (updateFrom === 'file') {
      if (!file) {
        setIsErrorModalOpen.on()
        setResponseMessage('Нет файла')
        return
      }

      const [uploadedFile] = await createUrlFromUploadedFile([file], { type: 'files' })

      onAddImportSchedules({
        variables: {
          seller_id: sellerId,
          imports: [
            {
              base_path: uploadedFile.base_path,
              mode: tab === 'add' ? (isAllDataUpdate ? 0 : 1) : 2,
              import_products_scheme_id: ensureCorrectSchemeId,
            },
          ],
        },
      })
    }

    if (updateFrom === 'link') {
      const imports: IImportScheduleInput[] = updateUrls.filter(Boolean).map((url) => ({
        ext_url: url,
        auto_update: isAutoUpdate,
        mode: tab === 'add' ? (isAllDataUpdate ? 0 : 1) : 2,
        import_products_scheme_id: ensureCorrectSchemeId,
        ...(isAutoUpdate && { period_update: Number(updatePeriod) }),
      }))

      onAddImportSchedules({
        variables: {
          seller_id: sellerId,
          imports,
        },
      })
    }
  }, [
    updateFrom,
    file,
    setIsErrorModalOpen,
    setIsUploadLoading,
    onAddImportSchedules,
    sellerId,
    tab,
    isAllDataUpdate,
    importSchemeId,
  ])

  const [onGetImportSchedule] = useLazyQuery<IGetImportSchedule, IGetImportScheduleParams>(
    GET_IMPORT_SCHEDULE,
    {
      fetchPolicy: 'network-only',
      onCompleted(data) {
        const { percent_update = 0 } = data.getImportSchedule

        setUpdatePercent(percent_update)

        if (percent_update === 100) {
          setTimeout(() => {
            setCheckImportId(null)
            setIsUploadLoading.off()
            setIsResponseModalOpen.on()
          }, 1000)

          setTimeout(() => {
            setUpdatePercent(INIT_UPDATE_PERCENT)
            apolloClient.refetchQueries({
              include: [GET_BASE_PRODUCTS],
            })
          }, 2000)
        }
      },
      onError() {
        setIsUploadLoading.off()
        setIsErrorModalOpen.on()
        setResponseMessage('Ошибка загрузки')
        setCheckImportId(null)
      },
    },
  )

  const closeHandler = () => {
    onClose()
    setFile(null)
    setResponseMessage(undefined)
    setUpdateUrls([''])
    setIsAutoUpdate.off()
    setImportSchemeId(defaultImportSchemeOption.id)

    if (!isUploading) {
      setTab('add')
      setUpdateFrom(initUpdateFrom)
    }
  }

  const [onGetImportSchemes, { data: importSchemesData }] = useLazyQuery<
    IGetImportSchemes,
    IGetImportSchemesParams
  >(GET_IMPORT_SCHEMES, {
    fetchPolicy: 'network-only',
    variables: {
      seller_id: sellerId,
    },
  })

  const importSchemes = useMemo(() => importSchemesData?.getImportSchemes || [], [importSchemesData])

  const importSchemesOptions = useMemo(
    () => [defaultImportSchemeOption].concat(importSchemes.map(extractOption)),
    [importSchemes],
  )

  const hasImportSchemes = !!importSchemes.length

  useEffect(() => {
    if (!isOpen) return
    onGetImportSchemes()
  }, [isOpen, onGetImportSchemes])

  useInterval(() => {
    if (!checkImportId) return
    onGetImportSchedule({
      variables: {
        id: checkImportId.id,
      },
    })
  }, 10000)

  return (
    <>
      <Dialog isOpen={isOpen} onClose={closeHandler}>
        <DialogCloseButton onClick={closeHandler} />
        <DialogContent className={styles.content}>
          <H2 className={styles.title}>Импорт товаров</H2>

          <Tabs className={styles.tabs} value={tab} onChange={onTabChange}>
            <Tab label="Добавление товаров" value="add" />
            <Tab label="Обновление остатков" value="update" />
          </Tabs>

          <RadioGroup className={styles.updateFrom} row value={updateFrom} onChange={onUpdateFromChange}>
            <RadioLabel value="file" label="Из файла" />
            <RadioLabel value="link" label="По ссылке" />
          </RadioGroup>

          {updateFrom === 'file' ? (
            <DragAndDrop
              onChangeFile={(payload) => setFile(payload as File)}
              className={styles.dragAndDrop}
              zoneClassName={styles.dragAndDropZone}
              filesFormat=".csv, .xls, .xlsx, .xml, .yml"
            />
          ) : (
            <div className={styles.updateUrlsContent}>
              <div>
                {updateUrls.map((value, index) => {
                  const isLastItem = updateUrls.length === index + 1
                  return (
                    <div key={index.toString()} className={styles.updateUrlInputGroup}>
                      <TextField value={value} label="URL файла" onChange={onUpdateUrlChange(index)} />
                      {isLastItem && (
                        <button type="button" className={styles.addUrlButton} onClick={onUpdateUrlAdd}>
                          <PlusIcon className={styles.plusIcon} />
                          Добавить ещё
                        </button>
                      )}
                    </div>
                  )
                })}
              </div>
              <Switch
                className={styles.autoUpdateGroup}
                placement="left"
                gap="large"
                checked={isAutoUpdate}
                onChange={onAutoUpdateChange}
                label={<Text>Автоматическое обновление данных</Text>}
              />
              <SelectField
                disabled={!isAutoUpdate}
                portal
                label="Частота обновления информации"
                value={updatePeriod}
                options={updateImportTimeOptions}
                onChange={(payload) => setUpdatePeriod(payload as IUpdateImportTime)}
              />
            </div>
          )}

          {hasImportSchemes && (
            <SelectField
              portal
              className={styles.importSchemeSelect}
              label="Конфигурация полей импорта"
              value={importSchemeId}
              options={importSchemesOptions}
              onChange={setImportSchemeId}
            />
          )}

          {tab === 'add' && (
            <CheckboxLabel
              label="Обновить все данные товаров"
              className={styles.updateAllData}
              value={isAllDataUpdate}
              onChange={setIsAllDataAutoUpdate.toggle}
            />
          )}

          <div className={styles.footer}>
            <div className={styles.actionsWrapper}>
              <Button disabled={isDisabledUpload} onClick={onUpload}>
                Загрузить
              </Button>
              <Button variant="outlined" onClick={closeHandler}>
                Отменить
              </Button>
            </div>

            {updateFrom === 'file' ? (
              <Link
                external
                href="https://sellty.ru/database/zagruzka-tovara-csv"
                className={styles.helperLink}
              >
                Как сформировать файл импорта?
              </Link>
            ) : (
              <Link external href="https://sellty.ru/database/link" className={styles.helperLink}>
                Как импортировать по ссылке?
              </Link>
            )}
          </div>
        </DialogContent>
      </Dialog>

      <LoadingDialog
        isOpen={isUploadLoading}
        title="Идет обработка файла"
        content={
          updateFrom === 'file' ? (
            <div className={styles.progressBarGroup}>
              <ProgressBar className={styles.progressBar} value={updatePercent} />
              <Text className={styles.progressBarText}>{`${updatePercent}%`}</Text>
            </div>
          ) : (
            <Text className={styles.linkUpdatingText}>
              Импорт запущен и выполняется в фоновом режиме. <br /> Вы можете закрыть данное окно и продолжать
              пользоваться продуктом.
            </Text>
          )
        }
        onClose={setIsUploadLoading.off}
      />

      <ResponseModal
        isOpen={isResponseModalOpen}
        responseType={tab}
        amount={responseMessage}
        footer={
          updateFrom === 'link' && (
            <Text className={styles.linkInfo}>
              Изменить настройки обмена можно на странице{' '}
              <Link href={`/${sellerId}/integrations/import-links`}>Интеграции</Link>{' '}
            </Text>
          )
        }
        onClose={setIsResponseModalOpen.off}
      />

      <ErrorModal isOpen={isErrorModalOpen} errorText={responseMessage} onClose={setIsErrorModalOpen.off} />
    </>
  )
}

export default withErrorBoundary(memo(ImportModal))
