import { DocumentNode, gql } from '@apollo/client'

import { Seller } from 'types/types'

export type ISetSellerHideAmountParams = {
  id: IPrimaryKey
  hide_amount: boolean
}

export type ISetSellerHideAmount = {
  setSellerHideAmount: Seller
}

export const buildSetSellerHideAmountQuery = (fields: string): DocumentNode => gql`
  mutation setSellerHideAmount($id: ID!, $hide_amount: Boolean!) {
    setSellerHideAmount(id: $id, hide_amount: $hide_amount) {
      ${fields}
    }
  }
`
