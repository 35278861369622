import { gql } from '@apollo/client'

export type VerifyTokenResponseType = {
  verifyToken: {
    message: string
    status: string
  }
}

export const VERIFY_TOKEN = gql`
  mutation verifyToken($token: String!) {
    verifyToken(token: $token) {
      message
      status
    }
  }
`
