import React, { Fragment, useCallback } from 'react'
import { Formik, FormikHelpers, useField } from 'formik'
import { array, object, string, boolean } from 'yup'

import { REQUIRED_MESSAGE } from 'consts/errorMessages'
import Text, { H2 } from 'components/Typography'
import Button from 'components/Button'
import Dialog from 'components/Dialog'
import DialogCloseButton from 'components/DialogCloseButton'
import DialogContent from 'components/DialogContent'
import Link from 'components/Link'
import AddSubmitShortcutFormHelper from 'components/AddSubmitShortcutFormHelper'
import PlusIcon from 'components/Icons/PlusIcon'
import TrashIcon from 'components/Icons/TrashIcon'
import TextInput from 'components/Inputs/TextInput'
import SwitchInput from 'components/Inputs/SwitchInput'
import useNotifyCms from 'hooks/useNotifyCms'
import useCmsParams from 'hooks/useCmsParams'
import {
  IAddAttributeParams,
  buildAddAttributesQuery,
  useAddAttributesMutation,
} from 'graphql/mutations/addAttributes'
import convertToFormErrors from 'utils/convertToFormErrors'
import formikFieldEvent from 'utils/formInputs/formikFieldEvent'
import numberFormat from 'utils/formInputs/numberFormat'

import styles from './createDialog.module.scss'

type ICreateDialogProps = {
  isOpen: boolean
  onClose: () => void
  onSuccess: () => void
}

type ICreateAttributesDialogForm = {
  attributes: Array<{ name: string; description: string; filters: boolean; number: string }>
}

const ADD_ATTRIBUTES = buildAddAttributesQuery(`
  id
  name
  description
  filters
  number
`)

const defaultAttribute = { name: '', description: '', filters: false, number: '9999' }

const initialValues: ICreateAttributesDialogForm = {
  attributes: [defaultAttribute],
}

const validationSchema = object({
  attributes: array(
    object({
      name: string().required(REQUIRED_MESSAGE).max(50, 'Максимальное количество символов - 50'),
      description: string().required(REQUIRED_MESSAGE).max(200, 'Максимальное количество символов - 200'),
      filters: boolean(),
    }),
  ),
})

const prepareSubmitValues = ({
  attributes,
}: ICreateAttributesDialogForm): IAddAttributeParams['attributes'] =>
  attributes.map(({ name, description, filters, number }) => ({
    name,
    description,
    filters,
    number: Number(number),
  }))

const AddAttribute = () => {
  const [{ value, onChange }] = useField<ICreateAttributesDialogForm['attributes']>('attributes')

  const onClick = () => {
    const nextValue = value.concat(defaultAttribute)
    onChange(formikFieldEvent('attributes', nextValue))
  }

  return (
    <Link className={styles.button} component="button" onClick={onClick} startIcon={<PlusIcon />}>
      Добавить атрибут
    </Link>
  )
}

const RemoveAttribute = ({ index }: { index: number }) => {
  const [{ value, onChange }] = useField<ICreateAttributesDialogForm['attributes']>('attributes')

  const onClick = () => {
    const nextValue = value.filter((_, itemIndex) => itemIndex !== index)
    onChange(formikFieldEvent('attributes', nextValue))
  }

  return (
    <Link className={styles.button} component="button" onClick={onClick} startIcon={<TrashIcon />}>
      Удалить атрибут
    </Link>
  )
}

const CreateDialog: React.FC<ICreateDialogProps> = ({ isOpen, onClose, onSuccess }) => {
  const { sellerId } = useCmsParams()
  const addNotify = useNotifyCms()

  const [addAttributes, { loading }] = useAddAttributesMutation(ADD_ATTRIBUTES)

  const onSubmit = useCallback(
    (paylaod: ICreateAttributesDialogForm, { setErrors }: FormikHelpers<ICreateAttributesDialogForm>) =>
      addAttributes({
        variables: {
          seller_id: sellerId,
          attributes: prepareSubmitValues(paylaod),
        },
      })
        .then(() => {
          addNotify('success')
          onSuccess()
        })
        .catch((error) => {
          addNotify('error')
          const errors = convertToFormErrors(error)
          if (errors) setErrors(errors)
        }),
    [addAttributes, addNotify, onSuccess, sellerId],
  )

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <DialogCloseButton onClick={onClose} />
      <DialogContent className={styles.dialogContent}>
        <H2 className={styles.title}>Создание атрибутов</H2>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <AddSubmitShortcutFormHelper />

              {values.attributes.map((_, index) => (
                <Fragment key={index}>
                  <TextInput
                    required
                    label="Название"
                    name={`attributes[${index}].name`}
                    placeholder="Введите название атрибута"
                    margin="default"
                  />

                  <TextInput
                    required
                    label="Значения атрибута"
                    name={`attributes[${index}].description`}
                    placeholder="Введите значения атрибута"
                    margin="default"
                  />

                  <TextInput
                    name={`attributes[${index}].number`}
                    className={styles.positionInput}
                    label="Сортировка"
                    placeholder="Введите значение от 1 до 9999"
                    offset="default"
                    inputProps={{ maxLength: 4 }}
                    transform={numberFormat}
                  />

                  <SwitchInput
                    name={`attributes[${index}].filters`}
                    className={styles.filtersToggleGroup}
                    placement="right"
                    gap="large"
                    label={<Text>Показывать в фильтре</Text>}
                  />

                  {index === values.attributes.length - 1 ? (
                    <AddAttribute />
                  ) : (
                    <RemoveAttribute index={index} />
                  )}
                </Fragment>
              ))}

              <div className={styles.controlsGroup}>
                <Button type="submit" disabled={loading}>
                  Сохранить
                </Button>
                <Button variant="outlined" type="reset" onClick={onClose}>
                  Отменить
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default CreateDialog
