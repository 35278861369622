import { buildSetSellerSettingsQuery } from 'graphql/mutations/setSellerSettings'
import { buildGetSellerQuery } from 'graphql/queries/getSeller'

export const GET_SELLER_QUERY = buildGetSellerQuery(`
  id
  name
  status
  status_index
  hide_amount
  show_variants
  closed
  only_authorised_order
  expanded_view
  individual_order
  
  settings {
    id
    sending_mail
    currency
    notification {
      status
      title
      text
    }
  }
`)

export const SET_SELLER_SETTINGS_QUERY = buildSetSellerSettingsQuery(`
  id
  notification {
    status
    title
    text
  }
`)
