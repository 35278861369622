import { gql } from '@apollo/client'

import type { Seller, ISellerInput } from 'types/types'

export type IAddSellerParams = {
  test: boolean
  closed: boolean
  input: ISellerInput
}

export type IAddSeller = {
  addSeller?: Seller
}

export const ADD_SELLER = gql`
  mutation addSeller($test: Boolean, $closed: Boolean, $input: SellerInput) {
    addSeller(test: $test, closed: $closed, input: $input) {
      id
      name
      company
      industry
      logo
    }
  }
`
