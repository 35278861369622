import React from 'react'

import { ReactComponent as CloseIcon } from 'assets/img/close.svg' // NOTE
import Text from 'components/Typography'

import styles from './chip.module.scss'

interface IProps {
  item: IOption
  onRemove: (value: IOption) => void
}

type SVGClickEvent = React.MouseEvent<SVGSVGElement, MouseEvent>

const Chip: React.FC<IProps> = ({ item, onRemove }) => {
  const onClick = (event: SVGClickEvent) => {
    event.stopPropagation()
    onRemove(item)
  }

  return (
    <div className={styles.chip}>
      <Text className={styles.name}>{item.name}</Text>
      <CloseIcon className={styles.chip__icon} onClick={onClick} tabIndex={0} />
    </div>
  )
}

export default Chip
// TODO: rename to Chip.tsx and create index.ts
