import { gql } from '@apollo/client'

export const SET_CLIENT_STATUS = gql`
  mutation setClientStatus($id: ID!, $status: Boolean!) {
    setClientStatus(id: $id, status: $status) {
      id
      name
    }
  }
`
