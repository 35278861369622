import { gql } from '@apollo/client'

import { Status } from 'types/types'

export type ISetBaseProductsStatusListResponse = {
  setBaseProductsStatusList: Status
}

export const SET_BASE_PRODUCTS_STATUS_LIST = gql`
  mutation setBaseProductsStatusList($ids: [ID]!, $status: Boolean!) {
    setBaseProductsStatusList(ids: $ids, status: $status) {
      status
      message
    }
  }
`
