import React, { useState } from 'react'
import set from 'lodash/set'
import size from 'lodash/size'
import { useMutation } from '@apollo/client'

import { UPDATE_PASSWORD } from 'graphql/mutations/updatePassword'
import { H2 } from 'components/Typography'
import Input from 'components/ui-kit/input'
import Dialog from 'components/Dialog'
import DialogContent from 'components/DialogContent'
import DialogCloseButton from 'components/DialogCloseButton'

import styles from './ProfileForm.module.scss'

export type ResetPassParams = {
  old_password: string
  password: string
  password_confirmation: string
}

const PASS_MIN_LENGTH = 8

const ERROR_PASS_INCORRECT = 'Неверный пароль'
const ERROR_REQUIRED_FIELD = 'Поле обязательное для заполнения'
const ERROR_PASS_NOT_EQUAL = 'Пароль не совпадает'
const ERROR_MIN_LENGTH = `Не менее ${PASS_MIN_LENGTH} символов`

const validate = (values: ResetPassParams): any => {
  const { old_password, password, password_confirmation } = values
  const errors = {}

  if (!old_password) {
    set(errors, 'old_password', ERROR_REQUIRED_FIELD)
  }

  if (!password) {
    set(errors, 'password', ERROR_REQUIRED_FIELD)
  } else if (password.length < PASS_MIN_LENGTH) {
    set(errors, 'password', ERROR_MIN_LENGTH)
  }

  if (!password_confirmation) {
    set(errors, 'password_confirmation', ERROR_REQUIRED_FIELD)
  } else if (password_confirmation.length < PASS_MIN_LENGTH) {
    set(errors, 'password_confirmation', ERROR_MIN_LENGTH)
  }

  if (password !== password_confirmation) {
    set(errors, 'password_confirmation', ERROR_PASS_NOT_EQUAL)
  }

  return errors
}

type IPasswordFormProps = {
  className?: string
  onClose: () => void
  isOpen: boolean
  changePasswordSubmit: () => void
}

// TODO: rename Password Form
const ProfileForm = ({ onClose, isOpen, changePasswordSubmit }: IPasswordFormProps) => {
  const [errors, setErrors] = useState<any>()

  const [values, setValues] = useState<any>({
    old_password: '',
    password: '',
    password_confirmation: '',
  })

  const [isVisited, setVisitedState] = useState({
    old_password: false,
    password: false,
    password_confirmation: false,
  })

  const [updateNewPassword] = useMutation(UPDATE_PASSWORD, {
    variables: {
      old_password: values.old_password,
      password: values.password,
      password_confirmation: values.password_confirmation,
    },
    onError: () => {
      setErrors({ old_password: ERROR_PASS_INCORRECT })
    },
    onCompleted: () => {
      changePasswordSubmit()
    },
  })

  const handleSubmit = (e: any) => {
    e.preventDefault()
    const validationErrors = validate(values)

    if (size(validationErrors) > 0) {
      setVisitedState({
        old_password: true,
        password: true,
        password_confirmation: true,
      })
      setErrors(validationErrors)
    } else {
      updateNewPassword({ variables: values })
    }
  }

  const onChangeOldPassword = (value: string) => {
    setValues({ ...values, old_password: value })
  }

  const onChangePassword = (value: string) => {
    setValues({ ...values, password: value })
  }

  const onChangePasswordConfirmation = (value: string) => {
    setValues({ ...values, password_confirmation: value })
  }

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <DialogCloseButton onClick={onClose} />
      <DialogContent className={styles.content}>
        <H2 className={styles.Modal__title}>Изменить пароль</H2>
        <form onSubmit={handleSubmit} className={styles.Modal__form}>
          <div className={styles.Modal__form_fields}>
            <div className={styles.Modal__form_field}>
              <span className={styles.Modal__form_label}>Старый пароль</span>
              <Input
                className={styles.Modal__form_input}
                name="old_password"
                maxLength={49}
                type="password"
                value={values.old_password}
                onBlur={() => setVisitedState((prev) => ({ ...prev, old_password: true }))}
                onChangeHandler={onChangeOldPassword}
                error={isVisited?.old_password && errors?.old_password}
              />
            </div>
            <div className={styles.Modal__form_field}>
              <span className={styles.Modal__form_label}>Введите новый пароль</span>
              <Input
                className={styles.Modal__form_input}
                name="newPassword"
                maxLength={49}
                minLength={8}
                type="password"
                value={values.password}
                onBlur={() => setVisitedState((prev) => ({ ...prev, newPassword: true }))}
                onChangeHandler={onChangePassword}
                error={isVisited?.password && errors?.password}
              />
            </div>
            <div className={styles.Modal__form_field}>
              <span className={styles.Modal__form_label}>Повторите новый пароль</span>
              <Input
                className={styles.Modal__form_input}
                name="password_confirmation"
                maxLength={49}
                type="password"
                value={values.password_confirmation}
                onBlur={() => setVisitedState((prev) => ({ ...prev, password_confirmation: true }))}
                onChangeHandler={onChangePasswordConfirmation}
                error={isVisited?.password_confirmation && errors?.password_confirmation}
              />
            </div>
          </div>
          <div className={styles.Modal__button}>
            <button
              className={styles.Modal__button_save}
              type="submit"
              disabled={!values?.old_password || !values?.password || !values?.password_confirmation}
            >
              Сохранить
            </button>
            <button type="button" className={styles.Modal__button_cancel} onClick={onClose}>
              Отменить
            </button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default ProfileForm
