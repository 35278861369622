import React from 'react'

import Text, { H3 } from 'components/Typography'

import styles from './ImageGroup.module.scss'

type IImageGroupProps = {
  title: string
  description: string[]
}

const ImageGroup: React.FC<IImageGroupProps> = ({ title, description, children }) => (
  <div className={styles.container}>
    <H3 color="textSecondary" className={styles.title}>
      {title}
    </H3>
    <div className={styles.contentGroup}>
      {children}
      <div>
        <Text className={styles.descriptionTitle} size="small" bold>
          Какие файлы использовать:
        </Text>
        {description.map((text) => (
          <Text key={text} color="textSecondary" size="small" className={styles.descriptionItem}>
            {text}
          </Text>
        ))}
      </div>
    </div>
  </div>
)

export default ImageGroup
