import { IClientFormValues } from 'components/forms/ClientForm'
import { IClient, ICompany } from 'types/types'
import phoneFormat from 'utils/phoneFormat'

const defaultCompany = { name: '', inn: '' }

const transformCompany = ({ id, name = '', inn = '' }: ICompany) => ({ id, name, inn })

const prepareInitialValues = (client: IClient): IClientFormValues => {
  const { status = false, phone = null, email = '', name = '', manager, companies = [] } = client

  return {
    status,
    phone: phone ? phoneFormat(phone) : '',
    email,
    name,
    managerId: manager ? manager.id : null,
    companies: companies.length ? companies.map(transformCompany) : [defaultCompany],
  }
}

export default prepareInitialValues
