import React from 'react'

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const DownloadIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M19 20H5C4.73478 20 4.48043 20.1054 4.29289 20.2929C4.10536 20.4804 4 20.7348 4 21C4 21.2652 4.10536 21.5196 4.29289 21.7071C4.48043 21.8946 4.73478 22 5 22H19C19.2652 22 19.5196 21.8946 19.7071 21.7071C19.8946 21.5196 20 21.2652 20 21C20 20.7348 19.8946 20.4804 19.7071 20.2929C19.5196 20.1054 19.2652 20 19 20ZM11.29 17.71C11.3851 17.801 11.4972 17.8724 11.62 17.92C11.7397 17.9729 11.8691 18.0002 12 18.0002C12.1309 18.0002 12.2603 17.9729 12.38 17.92C12.5028 17.8724 12.6149 17.801 12.71 17.71L16.71 13.71C16.8983 13.5217 17.0041 13.2663 17.0041 13C17.0041 12.7337 16.8983 12.4783 16.71 12.29C16.5217 12.1017 16.2663 11.9959 16 11.9959C15.7337 11.9959 15.4783 12.1017 15.29 12.29L13 14.59V3C13 2.73478 12.8946 2.48043 12.7071 2.29289C12.5196 2.10536 12.2652 2 12 2C11.7348 2 11.4804 2.10536 11.2929 2.29289C11.1054 2.48043 11 2.73478 11 3V14.59L8.71 12.29C8.61676 12.1968 8.50607 12.1228 8.38425 12.0723C8.26243 12.0219 8.13186 11.9959 8 11.9959C7.86814 11.9959 7.73757 12.0219 7.61575 12.0723C7.49393 12.1228 7.38324 12.1968 7.29 12.29C7.19676 12.3832 7.1228 12.4939 7.07234 12.6158C7.02188 12.7376 6.99591 12.8681 6.99591 13C6.99591 13.1319 7.02188 13.2624 7.07234 13.3842C7.1228 13.5061 7.19676 13.6168 7.29 13.71L11.29 17.71Z" />
  </SvgIcon>
)

export default DownloadIcon
