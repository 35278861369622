import React from 'react'

import DropMenu from 'components/DropMenu'
import Text from 'components/Typography'
import Link from 'components/Link'
import useCmsParams from 'hooks/useCmsParams'
import { IOrder } from 'types/types'
import phoneFormat from 'utils/phoneFormat'
import createContactEditLink from 'utils/links/createContactEditLink'

import styles from './Customer.module.scss'

type ICustomerProps = {
  order: IOrder
}

const Customer: React.FC<ICustomerProps> = ({ order }) => {
  const { customer, contact } = order

  const { sellerId } = useCmsParams()

  return (
    <DropMenu title="Информация о заказчике">
      {contact ? (
        <>
          <Link href={`/${sellerId}/${createContactEditLink(contact.id)}`} className={styles.link}>
            {contact.name}
          </Link>
          {contact.phone ? <Text className={styles.field}>{phoneFormat(contact.phone)}</Text> : null}

          {contact.email ? <Text>{contact.email}</Text> : null}
        </>
      ) : (
        <>
          {customer?.contact?.name ? <Text className={styles.field}>{customer.contact.name}</Text> : null}
          {customer?.contact?.email ? <Text className={styles.field}>{customer.contact.email}</Text> : null}
        </>
      )}

      {customer?.company?.name || customer?.company?.inn ? <div className={styles.separator} /> : null}

      {customer?.company?.name ? <Text className={styles.field}>{customer.company.name}</Text> : null}
      {customer?.company?.inn ? <Text className={styles.field}>{`ИНН ${customer.company.inn}`}</Text> : null}
    </DropMenu>
  )
}

export default Customer
