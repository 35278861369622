import { DocumentNode, gql } from '@apollo/client'

import { Seller } from 'types/types'

export type ISetSellerOnlyAuthorisedOrderParams = {
  id: IPrimaryKey
  only_authorised_order: boolean
}

export type ISetSellerOnlyAuthorisedOrder = {
  setSellerOnlyAuthorisedOrder: Seller
}

export const buildSetSellerOnlyAuthorisedOrderQuery = (fields: string): DocumentNode => gql`
  mutation setSellerOnlyAuthorisedOrder($id: ID!, $only_authorised_order: Boolean!) {
    setSellerOnlyAuthorisedOrder(id: $id, only_authorised_order: $only_authorised_order) {
      ${fields}
    }
  }
`
