import React, { useCallback, useEffect } from 'react'
import { useField } from 'formik'

import { H4 } from 'components/Typography'
import Link from 'components/Link'
import PlusIcon from 'components/Icons/PlusIcon'
import TrashIcon from 'components/Icons/TrashIcon'
import { IPriceRuleFormValues } from 'components/forms/PriceRuleForm/types'
import formikFieldEvent from 'utils/formInputs/formikFieldEvent'
import { IPriceRuleParameterType } from 'types/types'

import All from './components/All'
import Attribute from './components/Attribute'
import Type from './components/Type'
import Category from './components/Category'
import Group from './components/Group'
import Product from './components/Product'

import styles from './parameters.module.scss'

type IParametersProps = {
  name: string
}

type IField = IPriceRuleFormValues['groupParameters'][0]['parameters']

const emptyParameter: IPriceRuleFormValues['groupParameters'][0]['parameters'][0] = {
  discount_ratio: '',
  discount_type: 'discount',
  parameter_type: 'ALL',
  localGroupId: null,
  localValueIds: [],
}

const removeByIndex =
  <T,>(removeIndex: number) =>
  (_: T, index: number) =>
    index !== removeIndex

const Parameters: React.FC<IParametersProps> = ({ name }) => {
  const [{ value: parameters, onChange }] = useField<IField>(`${name}.parameters`)

  const addParameter = useCallback(() => {
    const nextValue = parameters.concat(emptyParameter)
    onChange(formikFieldEvent(`${name}.parameters`, nextValue))
  }, [onChange, parameters, name])

  const onTypeChange = useCallback(
    (changedIndex: number) => (type: IPriceRuleParameterType) => {
      const resetParametersParameters = parameters.map((item, index) => {
        if (index === changedIndex) {
          return { ...emptyParameter, parameter_type: type }
        }
        return item
      })
      onChange(formikFieldEvent(`${name}.parameters`, resetParametersParameters))
    },
    [onChange, parameters, name],
  )

  const onRemove = useCallback(
    (removeIndex: number) => () => {
      onChange(formikFieldEvent(`${name}.parameters`, parameters.filter(removeByIndex(removeIndex))))
    },
    [onChange, name, parameters],
  )

  useEffect(() => {
    if (parameters.length) return
    onChange(formikFieldEvent(`${name}.parameters`, [emptyParameter]))
  }, [onChange, name, parameters])

  return (
    <div>
      <H4 className={styles.title}>Уточнение параметров</H4>

      {parameters.map((item, index) => (
        <div key={index} className={styles.parameter}>
          <div className={styles.topWrapper}>
            <Type name={`${name}.parameters[${index}].parameter_type`} onTypeChange={onTypeChange(index)} />
            {parameters.length > 0 ? (
              <Link
                className={styles.removeButton}
                component="button"
                startIcon={<TrashIcon />}
                onClick={onRemove(index)}
              >
                Удалить параметр
              </Link>
            ) : null}
          </div>

          {item.parameter_type === 'ALL' ? <All name={`${name}.parameters[${index}]`} /> : null}
          {item.parameter_type === 'GROUP' ? <Group name={`${name}.parameters[${index}]`} /> : null}
          {item.parameter_type === 'CATEGORY' ? <Category name={`${name}.parameters[${index}]`} /> : null}
          {item.parameter_type === 'ATTRIBUTE' ? <Attribute name={`${name}.parameters[${index}]`} /> : null}
          {item.parameter_type === 'PRODUCT' ? <Product name={name} index={index} /> : null}
        </div>
      ))}

      <Link component="button" startIcon={<PlusIcon />} onClick={addParameter}>
        Добавить еще параметры
      </Link>
    </div>
  )
}

export default Parameters
