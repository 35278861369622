const pluralize = (count: number, words: string[]): string => {
  const fractionReg = /(?:[1-9][0-9]*)\/[1-9][0-9]*/g
  const floatReg = /\d+[,.]+\d+/g
  if (fractionReg.test(count.toString(10)) || floatReg.test(count.toString(10))) {
    return words[1] === null ? words[0] : words[1]
  }
  const cases = [2, 0, 1, 1, 1, 2]
  const pluralWord = words[count % 100 > 4 && count % 100 < 20 ? 2 : cases[Math.min(count % 10, 5)]]
  return pluralWord === null ? words[0] : pluralWord
}

export default pluralize
