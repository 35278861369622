import React from 'react'

import Input from 'components/ui-kit/input'
import Text from 'components/Typography'
import { ReactComponent as Clock } from 'assets/img/clockIcon_warning.svg' // TODO: replace with icon

import styles from './AdditionalEmail.module.scss'

type IAdditionalEmailProps = {
  secondEmail: string
  secondEmailVerifiedAt: string | null
  secondEmailOld: string | undefined
  setSecondEmail: (value: string) => void
  onResendSecondEmailClick: () => void
}

const AdditionalEmail: React.FC<IAdditionalEmailProps> = ({
  secondEmail,
  secondEmailVerifiedAt,
  secondEmailOld,
  setSecondEmail,
  onResendSecondEmailClick,
}) => (
  <>
    <Text className={styles.inputLabel}>
      Дополнительная почта
      {!secondEmailVerifiedAt && !!secondEmailOld && <Clock className={styles.labelIcon} />}
    </Text>
    <div className={styles.inputWrapper}>
      <Input className={styles.input} value={secondEmail} onChangeHandler={setSecondEmail} />
      <Text color="textSecondary">E-mail для уведомлений о запросе документов от контрагентов</Text>
    </div>

    {!secondEmailVerifiedAt && !!secondEmailOld && (
      <div className={styles.verifyGroup}>
        <p className={styles.verifyTitle}>E-mail не был подтвержден</p>
        <p>Чтобы получить доступ ко всем возможностям платформы Sellty, подтвердите ваш e-mail.</p>
        <button className={styles.verifyButton} type="button" onClick={onResendSecondEmailClick}>
          Отправить повторно
        </button>
      </div>
    )}
  </>
)

export default AdditionalEmail
