import { buildGetPriceRulesQuery } from 'graphql/queries/getPriceRules'

export const GET_PRICE_RULES = buildGetPriceRulesQuery(`
  id 
  name
  number
  status

  client_group {
    id
    clients {
      id
    }
  }
`)
