import React from 'react'
import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@material-ui/core/TextField'

import AttentionIcon from 'components/Icons/AttentionIcon'
import block from 'utils/block'

import styles from './textField.module.scss'

export type ITextFieldProps = MuiTextFieldProps & {
  disableNumberControls?: boolean
}

const b = block(styles)

const TextField: React.FC<ITextFieldProps> = ({
  disableNumberControls = false,
  error = false,
  value = '',
  autoComplete = 'off',
  variant = 'filled',
  InputProps = {},
  inputProps = {},
  // required,
  ...props
}) => {
  const { endAdornment, ...restInputProps } = InputProps
  const { className: inputPropsClassName, ...inputPropsParams } = inputProps

  return (
    <MuiTextField
      error={error}
      autoComplete={autoComplete}
      variant={variant}
      InputProps={{
        endAdornment: error ? <AttentionIcon color="error" /> : endAdornment,
        ...restInputProps,
      }}
      inputProps={{
        className: b('input', { disableNumberControls }, inputPropsClassName),
        ...inputPropsParams,
      }}
      {...props}
      value={value}
      // onWheel={(event) => event.currentTarget.blur()}
      // onWheel={event => (event.target as HTMLInputElement).blur()}
    />
  )
}

export default TextField
