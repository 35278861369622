import { Overrides } from '@material-ui/core/styles/overrides'

const tabs: Overrides['MuiTabs'] = {
  flexContainer: {
    gap: '24px',
  },
  indicator: {
    height: '3px',
  },
}

export default tabs
