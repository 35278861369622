import React from 'react'

import DropMenu from 'components/DropMenu'
import Text from 'components/Typography'
import SwitchInput from 'components/Inputs/SwitchInput'
import TextInput from 'components/Inputs/TextInput'
import numberFormat from 'utils/formInputs/numberFormat'
import useCurrentSeller from 'hooks/useCurrentSeller'

import Row from '../Row'
import BooleanLikeInput from '../BooleanLikeInput'
import StoresInputs from '../StoresInputs'

import styles from './storeAmount.module.scss'

type IStoreAmountProps = {
  isDisabledVariantInputs: boolean
}

const StoreAmount: React.FC<IStoreAmountProps> = ({ isDisabledVariantInputs }) => {
  const { stores_status } = useCurrentSeller()

  if (stores_status) {
    return (
      <DropMenu title="Количество и хранение">
        <Row>
          <BooleanLikeInput title="Количество">
            <SwitchInput
              name="on_order"
              placement="right"
              gap="large"
              label={<Text>Оформление под заказ</Text>}
            />
          </BooleanLikeInput>
        </Row>
        <StoresInputs isDisabledVariantInputs={isDisabledVariantInputs} />
      </DropMenu>
    )
  }

  return (
    <DropMenu title="Количество и хранение">
      <Row>
        <BooleanLikeInput title="Количество">
          <SwitchInput
            name="on_order"
            placement="right"
            gap="large"
            label={<Text>Оформление под заказ</Text>}
          />
        </BooleanLikeInput>
      </Row>
      <div className={styles.storageRow}>
        <TextInput
          name="amount"
          className={styles.input}
          disabled={isDisabledVariantInputs}
          label="Остаток на складе"
          placeholder="Введите количество"
          transform={numberFormat}
        />
        <TextInput
          name="amount_wait"
          className={styles.input}
          disabled={isDisabledVariantInputs}
          label="Ожидается поступление"
          placeholder="Введите количество"
          transform={numberFormat}
        />
        <TextInput
          name="multiply"
          className={styles.input}
          disabled={isDisabledVariantInputs}
          label="Кратность"
          placeholder="Введите значение"
          transform={numberFormat}
        />
      </div>
    </DropMenu>
  )
}

export default StoreAmount
