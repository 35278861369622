import React, { useRef, useState } from 'react'
import { HexColorPicker } from 'react-colorful'

import useOutsideAlerter from 'hooks/useOutsideAlerter'

import styles from './InputColor.module.scss'

type IInputColorProps = {
  color: string
  setColor: (color: string) => void
}

const InputColor: React.FC<IInputColorProps> = ({ color, setColor }) => {
  const [isOpenPicker, setIsOpenPicker] = useState(false)

  const ref = useRef(null)
  useOutsideAlerter(ref, setIsOpenPicker)

  return (
    <div className={styles.InputColor}>
      <input className={styles.InputColor__input} value={color} onChange={(e) => setColor(e.target.value)} />
      <div className={styles.InputColor__color} ref={ref}>
        {isOpenPicker && (
          <HexColorPicker color={color} onChange={setColor} className={styles.InputColor__react_colorful} />
        )}
        <div
          onClick={() => setIsOpenPicker((prev) => !prev)}
          style={{
            backgroundColor: color,
          }}
          className={styles.InputColor__block}
        />
      </div>
    </div>
  )
}

export default InputColor
