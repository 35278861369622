import React, { useImperativeHandle, useRef, forwardRef, ReactNode } from 'react'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Popper from '@material-ui/core/Popper'
import clsx from 'clsx'

import IconButton from 'components/IconButton'
import MoreIcon from 'components/Icons/MoreIcon'
import Paper from 'components/Paper'
import useBoolean from 'hooks/useBoolean'

import styles from './listControlGroup.module.scss'

export type IListControlGroupPropsHandle = {
  close: () => void
}

type IListControlGroupProps = {
  className?: string
  children: ReactNode
}

const popperModifiers = {
  flip: {
    enabled: false,
  },
  offset: {
    enabled: true,
    offset: '0px, 2px',
  },
}

const ListControlGroup = forwardRef<IListControlGroupPropsHandle, IListControlGroupProps>(
  ({ className, children }, ref) => {
    const anchorEl = useRef<HTMLButtonElement>(null)

    const [isOpen, setIsOpen] = useBoolean(false)

    useImperativeHandle(ref, () => ({
      close: () => setIsOpen.off(),
    }))

    return (
      <ClickAwayListener onClickAway={setIsOpen.off}>
        <div className={clsx(styles.container, className)}>
          <IconButton ref={anchorEl} onClick={setIsOpen.toggle}>
            <MoreIcon color={isOpen ? 'action' : undefined} />
          </IconButton>
          <Popper
            open={isOpen}
            placement="bottom-end"
            anchorEl={anchorEl.current}
            modifiers={popperModifiers}
            className={styles.popper}
          >
            <Paper className={styles.list}>{children}</Paper>
          </Popper>
        </div>
      </ClickAwayListener>
    )
  },
)

export default ListControlGroup
