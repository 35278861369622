import { DocumentNode, gql } from '@apollo/client'

import { IClient, IClientInput } from 'types/types'

export type ISetClient = {
  setClient: IClient
}

export type ISetClientParams = {
  id: IPrimaryKey
  input: IClientInput
}

export const buildSetClientQuery = (fields: string): DocumentNode => gql`
  mutation setClient($id: ID!, $input: ClientInput) {
    setClient(id: $id, input: $input) {
      ${fields}
    }
  }
`
