import { DocumentNode, MutationHookOptions, gql, useMutation } from '@apollo/client'

import { IClient, IClientInput } from 'types/types'

export type IAddClient = {
  addClient: IClient
}

export type IAddClientParams = {
  seller_id: IPrimaryKey
  input: IClientInput
}

export const buildAddClientQuery = (fields: string): DocumentNode => gql`
  mutation addClient($seller_id: ID!, $input: ClientInput) {
    addClient(seller_id: $seller_id, input: $input) {
      ${fields}
    }
  }
`

export const useAddClientMutation = <TData = IAddClient, TOptions = IAddClientParams>(
  fields: DocumentNode,
  options?: MutationHookOptions<TData, TOptions>,
) => useMutation<TData, TOptions>(fields, options)
