import React, { useCallback, useMemo } from 'react'
import { useField } from 'formik'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'

import CustomMultiSelect, { ICustomMultiSelectProps } from 'components/ui-kit/form/CustomMultiSelect'
import StarIcon from 'components/Icons/StarIcon'

export type IMultiSelectInputProps = Omit<
  ICustomMultiSelectProps,
  'selectedOptions' | 'setSelectedOption'
> & {
  name: string
  required?: boolean
  hasClear?: boolean
  fullWidth?: boolean
  label?: string
  className?: string
}

const labelStyle = { display: 'flex', justifyContent: 'flex-start' }

const MultiSelectInput: React.FC<IMultiSelectInputProps> = ({
  name,
  required = false,
  hasClear = false,
  fullWidth = false,
  label,
  className,
  options,
  ...props
}) => {
  const [{ value, onChange }, { error, touched, initialTouched }] = useField<string[]>(name)

  const hasError = (touched || initialTouched) && !!error

  const selectedOptions = useMemo(() => {
    if (!options) return []
    return options.filter((option) => value.includes(option.id))
  }, [options, value])

  const setSelectedOption = useCallback(
    (payload: IOption<string>[]) => {
      const event = {
        target: {
          name,
          value: payload.map(({ id }) => id),
        },
      }

      onChange(event)
    },
    [onChange, name],
  )

  return (
    <FormControl error={hasError} fullWidth={fullWidth} className={className}>
      {label ? (
        <InputLabel style={labelStyle}>
          {label}
          {required ? <StarIcon /> : null}
        </InputLabel>
      ) : null}
      <CustomMultiSelect
        {...props}
        error={hasError}
        selectedOptions={selectedOptions}
        options={options}
        setSelectedOption={setSelectedOption}
        onClear={hasClear ? () => setSelectedOption([]) : undefined}
      />
      {hasError ? <FormHelperText error>{error}</FormHelperText> : null}
    </FormControl>
  )
}

export default MultiSelectInput
