import React, { memo, useCallback } from 'react'
import { FormikHelpers } from 'formik'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'

import GoBackLink from 'components/GoBackLink'
import PageContainer from 'components/PageContainer'
import ResultTitle from 'components/ResultTitle'
import CategoryForm, { ICategoryFormValues } from 'components/forms/CategoryForm'
import useCmsParams from 'hooks/useCmsParams'
import { IAddCategory, IAddCategoryParams, buildAddCategoryQuery } from 'graphql/mutations/addCategory'
import useNotifyCms from 'hooks/useNotifyCms'
import withLeaveThisPageModal from 'hocs/withLeaveThisPageModal'
import withErrorBoundary from 'hocs/withErrorBoundary'
import convertToFormErrors from 'utils/convertToFormErrors'

const initValues: ICategoryFormValues = {
  name: '',
  group_id: null,
  number: '9999',
  status: true,
  attributes: [
    {
      attribute_id: '',
      value_ids: [],
    },
  ],
}

const ADD_CATEGORY = buildAddCategoryQuery(`
  id
  name
`)

const prepareSubmitValues = ({
  group_id,
  number,
  attributes,
  ...payload
}: ICategoryFormValues): IAddCategoryParams['input'] => ({
  ...payload,
  group_id: group_id as string,
  number: Number(number),
  attributes: attributes.filter(({ attribute_id }) => Boolean(attribute_id)),
})

const CategoryCreate: React.FC = () => {
  const { sellerId } = useCmsParams()
  const addNotify = useNotifyCms()
  const { replace } = useHistory()

  const [onAddCategory, { loading }] = useMutation<IAddCategory, IAddCategoryParams>(ADD_CATEGORY)

  const onSubmit = useCallback(
    (values: ICategoryFormValues, { setErrors }: FormikHelpers<ICategoryFormValues>) =>
      onAddCategory({
        variables: {
          seller_id: sellerId,
          input: prepareSubmitValues(values),
        },
      })
        .then(({ data }) => {
          addNotify('success')
          if (data) replace(`/${sellerId}/catalog/categories/${data.addCategory.id}`)
        })
        .catch((error) => {
          addNotify('error')
          const errors = convertToFormErrors(error)
          if (errors) setErrors(errors)
        }),
    [onAddCategory, addNotify, replace, sellerId],
  )

  return (
    <PageContainer>
      <GoBackLink href={`/${sellerId}/catalog/categories`} />
      <ResultTitle>Создание категории</ResultTitle>
      <CategoryForm submitLoading={loading} initialValues={initValues} onSubmit={onSubmit} />
    </PageContainer>
  )
}

export default withErrorBoundary(withLeaveThisPageModal(memo(CategoryCreate)))
