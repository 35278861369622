import React, { ReactNode } from 'react'

import { H3 } from 'components/Typography'
import useBoolean from 'hooks/useBoolean'
import block from 'utils/block'
import ArrowDownSmallIcon from 'components/Icons/ArrowDownSmallIcon'

import styles from './dropMenu.module.scss'

interface IDropMenuProps {
  title: string | ReactNode
  titleChildren?: ReactNode
  margin?: 'none' | 'default'
  className?: string
  resetPadding?: boolean
  // rename initClosed
  initOpen?: boolean
  id?: string
}

const b = block(styles)

const DropMenu: React.FC<IDropMenuProps> = ({
  title,
  titleChildren,
  margin = 'default',
  className,
  resetPadding = false,
  // rename initClosed = false
  initOpen = true,
  id,
  children,
}) => {
  // todo: !initClosed
  const [isOpen, setIsOpen] = useBoolean(initOpen)

  return (
    <div id={id} className={b('container', { margin }, className)}>
      <div
        className={b('toggleContainer', { open: isOpen })}
        role="button"
        tabIndex={0}
        onClick={setIsOpen.toggle}
      >
        <H3>{title}</H3>
        {titleChildren}
        <ArrowDownSmallIcon style={{ transform: `rotate(${isOpen ? '180deg' : '0deg'})`, flexShrink: 0 }} />
      </div>
      {isOpen ? <div className={b('contentWrapper', { reset: resetPadding })}>{children}</div> : null}
    </div>
  )
}

export default DropMenu
