import React, { memo, useCallback } from 'react'

import { Group } from 'types/types'
import Link from 'components/Link'
import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import useBoolean from 'hooks/useBoolean'

import ModalEdit from './components/ModalEdit'

import styles from './CardGroup.module.scss'

type ICardGroupProps = {
  group: Group
  numberList: number[]
  currentPage: string
  onDelete: (payload: IPrimaryKey) => void
}

const CardGroup: React.FC<ICardGroupProps> = ({ group, numberList, currentPage, onDelete }) => {
  const { id: groupId, name } = group

  const [isEditDialogOpen, setEditDialog] = useBoolean()
  const [isConfirmationDialogOpen, setConfirmationDialog] = useBoolean()

  const onSuggestDelete = useCallback(() => {
    setEditDialog.off()
    setConfirmationDialog.on()
  }, [setEditDialog, setConfirmationDialog])

  const onDeleteSubmit = useCallback(() => {
    onDelete(groupId)
    setConfirmationDialog.off()
  }, [groupId, onDelete, setConfirmationDialog])

  return (
    <div className={styles.container}>
      <p className={styles.name}>{name}</p>
      <p className={styles.sort}>Сортировка: {group.number}</p>
      <Link component="button" onClick={setEditDialog.on}>
        Редактировать
      </Link>

      <ModalEdit
        group={group}
        isOpen={isEditDialogOpen}
        numberList={numberList}
        onClose={setEditDialog.off}
        onDelete={onSuggestDelete}
        currentPage={currentPage}
      />

      <ConfirmDialog
        isOpen={isConfirmationDialogOpen}
        title="Вы уверены, что хотите удалить группу?"
        submitBtnText="Да, удалить"
        content={
          <p className={styles.conformationDialogContent}>
            Категории и товары этой группы будут неактивны на сайте.
          </p>
        }
        onSubmit={onDeleteSubmit}
        onClose={setConfirmationDialog.off}
      />
    </div>
  )
}

export default memo(CardGroup)
