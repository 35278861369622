import React from 'react'
import MuiTabs, { TabsProps } from '@material-ui/core/Tabs'

type ITabsProps = Omit<TabsProps, 'onChange'> & {
  onChange: (event: React.ChangeEvent<any>, value: any) => void
}

const Tabs: React.FC<ITabsProps> = (props) => <MuiTabs {...props} />

export default Tabs
