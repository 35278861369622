import { gql } from '@apollo/client'

import { Status } from 'types/types'

export type IDeleteStore = {
  delStore: Status
}

export type IDeleteStoreParams = {
  id: IPrimaryKey
}

export const DELETE_STORE = gql`
  mutation delStore($id: ID!) {
    delStore(id: $id) {
      status
      message
    }
  }
`
