import React from 'react'

import DropMenu from 'components/DropMenu'
import Text from 'components/Typography'
import TextInput from 'components/Inputs/TextInput'
import SwitchInput from 'components/Inputs/SwitchInput'
import LimitText from 'components/LimitText'
import priceFormat from 'utils/formInputs/priceFormat'
import renderCurrencySymbol from 'utils/renderCurrencySymbol'
import numberFormat from 'utils/formInputs/numberFormat'
import useCurrentSeller from 'hooks/useCurrentSeller'

import BooleanLikeInput from '../BooleanLikeInput'
import Row from '../Row'
import PriceLists from '../PriceLists'
import { IBaseProductFormValues } from '../../types'

import styles from './generalSettings.module.scss'

type IGeneralSettingsProps = {
  isDisabledVariantInputs: boolean
  values: IBaseProductFormValues
}

const NAME_TEXT_LIMIT = 250
const VENDOR_CODE_TEXT_LIMIT = 30
const POSITION_TEXT_LIMIT = 4

const nameInputProps = { maxLength: NAME_TEXT_LIMIT }
const vendorCodeInputProps = { maxLength: VENDOR_CODE_TEXT_LIMIT }
const positionInputProps = { maxLength: POSITION_TEXT_LIMIT }

const GeneralSettings: React.FC<IGeneralSettingsProps> = ({ isDisabledVariantInputs, values }) => {
  const { settings } = useCurrentSeller()

  return (
    <DropMenu title="Общие настройки">
      <Row>
        <TextInput
          name="name"
          required
          className={styles.name}
          label="Название"
          placeholder="Введите название товара"
          inputProps={nameInputProps}
          labelAsideRight={<LimitText limit={NAME_TEXT_LIMIT} value={values.name} />}
        />
        <TextInput
          name="vendor_code"
          className={styles.vendorCode}
          disabled={isDisabledVariantInputs}
          label="Артикул"
          placeholder="Введите артикул товара"
          inputProps={vendorCodeInputProps}
          labelAsideRight={<LimitText limit={VENDOR_CODE_TEXT_LIMIT} value={values.vendor_code} />}
        />
      </Row>
      <Row>
        <TextInput
          name="price"
          className={styles.price}
          disabled={isDisabledVariantInputs}
          label={`Цена, ${renderCurrencySymbol(settings?.currency)}`}
          placeholder="0"
          transform={priceFormat}
        />
        {values.prices ? <PriceLists prices={values.prices} /> : null}
      </Row>
      <Row>
        <TextInput
          name="number"
          className={styles.price}
          disabled={isDisabledVariantInputs}
          label="Сортировка"
          inputProps={positionInputProps}
          transform={numberFormat}
        />
        <BooleanLikeInput title="Активность">
          <SwitchInput
            name="status"
            className={styles.statusInput}
            placement="right"
            gap="medium"
            label={
              <Text size="small" color="textSecondary">
                {values.status ? 'активен' : 'неактивен'}
              </Text>
            }
          />
        </BooleanLikeInput>
      </Row>
    </DropMenu>
  )
}

export default GeneralSettings
