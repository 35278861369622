import React from 'react'

import { IPriceRule } from 'types/types'
import Text from 'components/Typography'

import PriceRule from '../PriceRule'
import Row from '../Row'

import styles from './result.module.scss'

type IResultProps = {
  priceRules: IPriceRule[]
  refetchPriceRules: () => void
}

const Result: React.FC<IResultProps> = ({ priceRules, refetchPriceRules }) => (
  <div className={styles.container}>
    <Row>
      <Text bold size="small">
        Наименование
      </Text>
      <Text bold size="small" align="center">
        Контрагенты
      </Text>
      <Text bold size="small" align="center">
        Сортировка
      </Text>
    </Row>
    {priceRules.map((priceRule) => (
      <PriceRule key={priceRule.id} priceRule={priceRule} refetchPriceRules={refetchPriceRules} />
    ))}
  </div>
)

export default Result
