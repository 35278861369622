import { gql, MutationHookOptions, useMutation } from '@apollo/client'

import { Status } from 'types/types'

export type ITruncateGroups = {
  truncateGroups: Status
}

export type ITruncateGroupsParams = {
  seller_id: string
}

export const TRUNCATE_GROUPS = gql`
  mutation truncateGroups($seller_id: ID!) {
    truncateGroups(seller_id: $seller_id) {
      status
      message
    }
  }
`

export const useTruncateGroups = <TData = ITruncateGroups, TOptions = ITruncateGroupsParams>(
  options?: MutationHookOptions<TData, TOptions>,
) => useMutation<TData, TOptions>(TRUNCATE_GROUPS, options)
