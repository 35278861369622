import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import TrashIcon from 'components/Icons/TrashIcon'
import CopyIcon from 'components/Icons/CopyIcon'
import EditIcon from 'components/Icons/EditIcon'
import Link from 'components/Link'
import SuccessItemDialog from 'components/Dialogs/SuccessItemDialog'
import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import { Attribute as IAttribute } from 'types/types'
import useCmsParams from 'hooks/useCmsParams'
import useNotifyCms from 'hooks/useNotifyCms'
import ListControlGroup from 'components/ListControlGroup'
import Text from 'components/Typography'

import { COPY_ATTRIBUTE } from 'graphql/mutations/copyAttribute'
import { DELETE_ATTRIBUTE, IDeleteAttribute } from 'graphql/mutations/deleteAttribute'
import { GET_ATTRIBUTES } from 'graphql/queries/getAttributes'

import styles from './attribute.module.scss'

type IAttributeProps = {
  attribute: IAttribute
}

type IActionModalType = 'delete' | 'copy'

const Attribute: React.FC<IAttributeProps> = ({ attribute }) => {
  const { id, name, number: position } = attribute

  const { sellerId } = useCmsParams()
  const { push } = useHistory()
  const addNotify = useNotifyCms()

  const [actionModalTypeActive, setActionModalTypeActive] = useState<IActionModalType | null>(null)
  const [isComplete, setCompleteState] = useState<boolean>(false)

  const [onDeleteAttribute] = useMutation<IDeleteAttribute>(DELETE_ATTRIBUTE, {
    refetchQueries: [GET_ATTRIBUTES],
    onCompleted: ({ delAttribute }) => {
      setActionModalTypeActive(null)
      if (delAttribute.status) {
        addNotify({ kind: 'success', message: 'Атрибут удален' })
      } else {
        addNotify('warning')
      }
    },
  })

  const [onCopyAttribute, { data }] = useMutation(COPY_ATTRIBUTE, {
    refetchQueries: [GET_ATTRIBUTES],
    onCompleted: () => {
      setActionModalTypeActive(null)
      setCompleteState(true)
    },
  })

  const handlerDelete = (value: number) => onDeleteAttribute({ variables: { id: value } })

  const handlerCopy = (value: number) => onCopyAttribute({ variables: { id: value } })

  const onCopyClick = () => {
    setActionModalTypeActive('copy')
  }

  const onDeleteClick = () => {
    setActionModalTypeActive('delete')
  }

  const onSubmitDeleteClick = () => {
    if (actionModalTypeActive === 'delete') {
      handlerDelete(Number(id))
    } else {
      handlerCopy(Number(id))
    }
  }

  return (
    <div className={styles.container}>
      <SuccessItemDialog
        isOpen={isComplete}
        title="Атрибут успешно скопирован"
        toItemText="К атрибуту"
        onClose={() => setCompleteState(false)}
        onSubmitBtn={() => setCompleteState(false)}
        onToItem={() => push(`/${sellerId}/catalog/attributes/${data.copyAttribute.message}`)}
      />

      <ConfirmDialog
        isOpen={!!actionModalTypeActive}
        title={
          actionModalTypeActive === 'delete'
            ? 'Вы уверены, что хотите удалить атрибут?'
            : 'Вы действительно хотите создать копию атрибута?'
        }
        submitBtnText={actionModalTypeActive === 'delete' ? 'Да, удалить' : 'Да, создать'}
        onSubmit={onSubmitDeleteClick}
        onClose={() => setActionModalTypeActive(null)}
      />

      <Link href={`/${sellerId}/catalog/attributes/${id}`}>
        <span>
          {name} <Text component="span" variant="inherit" color="textSecondary">{` ID ${id}`}</Text>
        </span>
      </Link>

      <Text>Сортировка: {position}</Text>

      <ListControlGroup>
        <Link href={`/${sellerId}/catalog/attributes/${id}`} startIcon={<EditIcon />}>
          Редактировать
        </Link>
        <Link component="button" startIcon={<CopyIcon />} onClick={onCopyClick}>
          Создать копию
        </Link>
        <Link component="button" startIcon={<TrashIcon />} onClick={onDeleteClick}>
          Удалить
        </Link>
      </ListControlGroup>
    </div>
  )
}

export default Attribute
