import React, { useCallback, useState } from 'react'

import { useTruncateBaseProducts } from 'graphql/mutations/truncateBaseProducts'
import { useTruncateAttributes } from 'graphql/mutations/truncateAttributes'
import { useTruncateGroups } from 'graphql/mutations/truncateGroups'
import { useTruncateCategories } from 'graphql/mutations/truncateCategories'
import DropMenu from 'components/DropMenu'
import Button from 'components/Button'
import Dialog from 'components/Dialog'
import DialogCloseButton from 'components/DialogCloseButton'
import DialogContent from 'components/DialogContent'
import { H2 } from 'components/Typography'
import Preloader from 'components/Preloader'
import useBoolean from 'hooks/useBoolean'
import useCmsParams from 'hooks/useCmsParams'

import { ReactComponent as SuccessfullyIcon } from 'assets/img/successfully.svg'

import SelectState from './components/SelectState'
import ConfirmState from './components/ConfirmState'
import { IDeleteCatalogItem } from './types'

import styles from './ClearCatalog.module.scss'

const ClearCatalog: React.FC = () => {
  const { sellerId } = useCmsParams()

  const [isOpenDeletion, setIsOpenDeletion] = useBoolean(false)
  const [dislogState, setDislogState] = useState<'select' | 'confirm' | 'loading' | 'result'>('select')
  const [deleteCategories, setDeleteCategories] = useState<IDeleteCatalogItem[]>([])

  const [onTruncateBaseProducts] = useTruncateBaseProducts({ variables: { seller_id: sellerId } })
  const [onTruncateAttributes] = useTruncateAttributes({ variables: { seller_id: sellerId } })
  const [onTruncateGroups] = useTruncateGroups({ variables: { seller_id: sellerId } })
  const [onTruncateCategories] = useTruncateCategories({ variables: { seller_id: sellerId } })

  const onCloseHelper = useCallback(() => {
    setIsOpenDeletion.off()

    setTimeout(() => {
      setDislogState('select')
      setDeleteCategories([])
    }, 400)
  }, [setIsOpenDeletion])

  const setSelectedDeleteItems = useCallback((payload: IDeleteCatalogItem[]) => {
    setDeleteCategories(payload)
    setDislogState('confirm')
  }, [])

  const onDeleteSuccess = useCallback(() => {
    setDislogState('result')
    setTimeout(() => onCloseHelper(), 2000)
  }, [onCloseHelper])

  const onConfirmDelete = useCallback(async () => {
    setDislogState('loading')

    const deletePromises = deleteCategories.map((item) => {
      if (item === 'products') return onTruncateBaseProducts()
      if (item === 'attributes') return onTruncateAttributes()
      if (item === 'groups') return onTruncateGroups()
      if (item === 'categories') return onTruncateCategories()
      throw new Error('Something went wrong')
    })

    await Promise.allSettled(deletePromises)
      .then(onDeleteSuccess)
      .catch((err) => {
        console.error(err)
        onCloseHelper()
      })
  }, [
    deleteCategories,
    onCloseHelper,
    onDeleteSuccess,
    onTruncateAttributes,
    onTruncateBaseProducts,
    onTruncateCategories,
    onTruncateGroups,
  ])

  const onCancelDelete = useCallback(() => {
    setDislogState('select')
    setDeleteCategories([])
  }, [])

  return (
    <DropMenu title="Каталог">
      <Button variant="outlined" onClick={setIsOpenDeletion.on}>
        Очистить каталог
      </Button>

      <Dialog isOpen={isOpenDeletion} onClose={onCloseHelper}>
        <DialogCloseButton onClick={onCloseHelper} />
        {dislogState === 'select' && (
          <SelectState onSubmit={setSelectedDeleteItems} onCancel={onCloseHelper} />
        )}

        {dislogState === 'confirm' && (
          <DialogContent className={styles.content}>
            <ConfirmState
              deleteCategories={deleteCategories}
              onSubmit={onConfirmDelete}
              onCancel={onCancelDelete}
            />
          </DialogContent>
        )}

        {dislogState === 'loading' && (
          <DialogContent className={styles.content}>
            <div className={styles.preloaderGroup}>
              <Preloader variant="relative" />
            </div>
          </DialogContent>
        )}

        {dislogState === 'result' && (
          <DialogContent className={styles.content}>
            <div className={styles.result}>
              <SuccessfullyIcon className={styles.icon} />
              <H2>Данные успешно удалены</H2>
            </div>
          </DialogContent>
        )}
      </Dialog>
    </DropMenu>
  )
}

export default ClearCatalog
