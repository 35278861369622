import { DocumentNode, gql, useQuery, QueryHookOptions } from '@apollo/client'

import { PManager, Paginate } from 'types/types'

export type IGetManagers = {
  getManagers: PManager
}

export type IGetManagersParams = {
  seller_id: IPrimaryKey
  search?: string | null
  paginate?: Paginate
}

export const buildGetManagersQuery = (fields: string): DocumentNode => gql`
  query getManagers($seller_id: ID!, $search: String, $paginate: Paginate) {
    getManagers(seller_id: $seller_id, search: $search, paginate: $paginate) {
      elements
      list { ${fields} }
      page
      pages
    }
  }
`

export const useGetManagersQuery = (
  fields: DocumentNode,
  options?: QueryHookOptions<IGetManagers, IGetManagersParams>,
) => useQuery<IGetManagers, IGetManagersParams>(fields, options)
