import React from 'react'

import { Image as IImage } from 'types/types'
import NoImg from 'assets/img/no-img.svg'

import styles from './image.module.scss'

interface IImageProps {
  images: IImage[]
}

const Image: React.FC<IImageProps> = ({ images }) => (
  <div className={styles.wrapper}>
    <img src={`${images[0]?.path || NoImg}`} alt="product" />
  </div>
)

export default Image
