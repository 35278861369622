import { DocumentNode, gql, QueryHookOptions, useQuery } from '@apollo/client'

import { Paginate, POrders } from 'types/types'

export type IGetOrders = {
  getOrders: POrders
}

export type IGetOrdersParams = {
  seller_id: string
  paginate: Paginate
}

export const buildGetOrdersQuery = (fields: string): DocumentNode => gql`
  query getOrders($seller_id: ID!, $paginate: Paginate) {
    getOrders(seller_id: $seller_id, paginate: $paginate) {
      page
      pages
      elements
      news
      list {
        ${fields}
      }
    }
  }
`

export const useGetOrdersQuery = <TData = IGetOrders, TOptions = IGetOrdersParams>(
  fields: DocumentNode,
  options?: QueryHookOptions<TData, TOptions>,
) => useQuery<TData, TOptions>(fields, options)
