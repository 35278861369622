import { DocumentNode, MutationHookOptions, gql, useMutation } from '@apollo/client'

import { IBanner, IBannerInput } from 'types/types'

export type ISetBanner = {
  setBanner: IBanner
}

export type ISetBannerParams = {
  id: IPrimaryKey
  input?: IBannerInput
}

export const buildSetBannerQuery = (fields: string): DocumentNode => gql`
  mutation setBanner($id: ID!, $input: BannerInput) {
    setBanner(id: $id, input: $input) {
      ${fields}
    }
  }
`

export const useSetBannerMutation = <TData = ISetBanner, TOptions = ISetBannerParams>(
  fields: DocumentNode,
  options?: MutationHookOptions<TData, TOptions>,
) => useMutation<TData, TOptions>(fields, options)
