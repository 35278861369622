import React, { useCallback, useState } from 'react'

import DropMenu from 'components/DropMenu'
import Text from 'components/Typography'
import MultiDragAndDropInput from 'components/Inputs/MultiDragAndDropInput'
import WysiwygInput from 'components/Inputs/WysiwygInput'
import TextInput from 'components/Inputs/TextInput'
import ErrorBoundary from 'components/ErrorBoundary'

import DropZoneDescription from '../DropZoneDescription'
import Row from '../Row'

import styles from './decoration.module.scss'

const Description: React.FC = ({ children }) => (
  <DropMenu title="Оформление" initOpen={false}>
    <DropZoneDescription />
    <Row>
      <MultiDragAndDropInput name="images" className={styles.field} filesFormat=".png, .jpg" />
    </Row>

    {children}
  </DropMenu>
)

// NOTE: Костыль какой то
const Wrapper = () => {
  const [isErrored, setIsErrored] = useState(false)

  const onError = useCallback(() => setIsErrored(true), [])

  if (isErrored) {
    return (
      <Description>
        <TextInput name="description" className={styles.field} multiline minRows={10} maxRows={24} />
      </Description>
    )
  }

  return (
    <Description>
      <ErrorBoundary onError={onError}>
        <Text>Описание</Text>
        <WysiwygInput name="description" />
      </ErrorBoundary>
    </Description>
  )
}

export default Wrapper
