import React, { memo, useCallback, useMemo } from 'react'
import { FormikHelpers } from 'formik'

import Preloader from 'components/Preloader'
import GoBackLink from 'components/GoBackLink'
import ResultTitle from 'components/ResultTitle'
import PageContainer from 'components/PageContainer'
import useCmsParams from 'hooks/useCmsParams'
import useNotifyCms from 'hooks/useNotifyCms'
import convertToFormErrors from 'utils/convertToFormErrors'
import phoneFormat from 'utils/phoneFormat'
import phoneFormatSave from 'utils/phoneFormatSave'
import withErrorBoundary from 'hocs/withErrorBoundary'
import withLeaveThisPageModal from 'hocs/withLeaveThisPageModal'
import ManagerForm, { IManagerFormValues } from 'components/forms/ManagerForm'
import ApiError from 'components/ApiError'
import extractId from 'utils/extractId'
import { useGetManagerQuery } from 'graphql/queries/getManager'

import { IManager, IManagerInput } from 'types/types'
import { useSetManagerMutation } from 'graphql/mutations/setManager'
import Controls from './components/Controls'
import { GET_MANAGER, SET_MANAGER } from './queries'

const defaultValues: IManagerFormValues = {
  phone: '',
  email: '',
  name: '',
  to_new_clients: false,
  clientsIds: [],
}

const prepareInitialValues = (payload: IManager): IManagerFormValues => {
  const { to_new_clients = false, phone = '', email = '', name = '', clients = [] } = payload
  return {
    name,
    phone: phoneFormat(phone),
    email,
    to_new_clients,
    clientsIds: clients.map(extractId),
  }
}

const prepareSubmitValues = (payload: IManagerFormValues): IManagerInput => {
  const { name, phone, email, clientsIds, to_new_clients } = payload
  return {
    name,
    phone: phoneFormatSave(phone),
    email,
    to_new_clients,
    client_ids: clientsIds,
  }
}

const ContactEdit: React.FC = () => {
  const { sellerId, managerId } = useCmsParams()
  const addNotify = useNotifyCms()

  const { data, loading, error } = useGetManagerQuery(GET_MANAGER, {
    variables: { id: managerId },
    fetchPolicy: 'network-only',
  })

  const manager = useMemo(() => data?.getManager, [data])

  const initialFormValues: IManagerFormValues = useMemo(() => {
    if (!manager) return defaultValues
    return prepareInitialValues(manager)
  }, [manager])

  const [onSetManager, { loading: setLoading }] = useSetManagerMutation(SET_MANAGER)

  const onSubmit = useCallback(
    (values: IManagerFormValues, { setErrors, resetForm }: FormikHelpers<IManagerFormValues>) =>
      onSetManager({
        variables: {
          id: managerId,
          input: prepareSubmitValues(values),
        },
      })
        .then((response) => {
          const nextData = response.data?.setManager
          if (!nextData) throw new Error('No setManager data')
          addNotify('success')
          resetForm({ values: prepareInitialValues(nextData) })
        })
        .catch((errorRes) => {
          addNotify('error')
          const errors = convertToFormErrors(errorRes)
          if (errors) setErrors(errors)
        }),
    [onSetManager, addNotify, managerId],
  )

  if (loading) {
    return (
      <PageContainer>
        <GoBackLink href={`/${sellerId}/clients/list`} />
        <Preloader />
      </PageContainer>
    )
  }

  if (error) return <ApiError error={error} />

  if (!manager) return null

  return (
    <PageContainer>
      <GoBackLink href={`/${sellerId}/clients/list`} />

      <ResultTitle>{manager.name}</ResultTitle>

      <ManagerForm
        initialValue={initialFormValues}
        submitLoading={setLoading}
        onSubmit={onSubmit}
        renderControls={() => <Controls />}
      />
    </PageContainer>
  )
}

export default withErrorBoundary(withLeaveThisPageModal(memo(ContactEdit)))
