export const desktopDescription = [
  'формат JPEG, JPG, PNG, GIF, WEBP;',
  'размер: не более 5 МБ;',
  'разрешение: не менее 1210 на 302 px;',
  'соотношение сторон 4:1',
]

export const extendedViewDesktopDescription = [
  'формат JPEG, JPG, PNG, GIF, WEBP;',
  'размер: не более 5 МБ;',
  'разрешение: не менее 1600 на 800 px;',
  'охранное поле слева и справа 128 px;',
  'охранное поле сверху и снизу 32 px;',
  'соотношение сторон 2:1',
]

export const mobileDescription = [
  'формат JPEG, JPG, PNG, GIF, WEBP;',
  'размер: не более 5 МБ;',
  'разрешение: не менее 296 на 148 px;',
  'соотношение сторон 2:1',
]

export const extendedViewMobileDescription = [
  'формат JPEG, JPG, PNG, GIF, WEBP;',
  'размер: не более 5 МБ;',
  'разрешение не менее 320 на 512 px;',
  'соотношение сторон 5:8',
]
