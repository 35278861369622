import React, { useCallback, useMemo, useRef, useState } from 'react'
import FilledInput, { FilledInputProps } from '@material-ui/core/FilledInput'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import clsx from 'clsx'

import TextField from 'components/TextField'
import AttentionIcon from 'components/Icons/AttentionIcon'
import ArrowSmallDownSmallIcon from 'components/Icons/ArrowDownSmallIcon'
import SearchIcon from 'components/Icons/SearchIcon'
import IconButton from 'components/IconButton'
import Preloader from 'components/Preloader'
import CrossSmallIcon from 'components/Icons/CrossSmallIcon'

import OptionsList from './components/OptionsList'
import { IOptionItemProps } from './components/OptionItem'

import styles from './select.module.scss'

export type ISelectProps = Omit<
  FilledInputProps,
  'readOnly' | 'endAdornment' | 'onClick' | 'onChange' | 'value'
> & {
  value: string | null
  showOptionId?: boolean
  showActiveId?: boolean
  hideSearch?: boolean
  portal?: boolean
  loading?: boolean
  options: IOption<string>[]
  onChange: (payload: string) => void
  onClear?: () => void
  renderOption?: (params: IOptionItemProps) => JSX.Element
}

type ISelectState = {
  isOpen: boolean
  searchValue: string
}

const initValues: ISelectState = {
  isOpen: false,
  searchValue: '',
}

const convertOptionsToHash = (payload: IOption[]): Record<string, IOption> => {
  const response: Record<string, IOption> = {}

  for (let index = 0; index < payload.length; index++) {
    const option = payload[index]
    response[option.id] = option
  }

  return response
}

const prepareSearchOption = ({ id, name, ...rest }: IOption): IOption & { search: string } => ({
  id,
  name,
  search: `${id} ${name}`.toLowerCase(),
  ...rest,
})

const Select: React.FC<ISelectProps> = ({
  value: valueProp,
  error,
  showOptionId,
  showActiveId,
  portal = false,
  loading = false,
  disabled = false,
  hideSearch = false,
  className,
  autoComplete = 'off',
  options,
  inputProps = {},
  onChange,
  onClear,
  renderOption,
  ...props
}) => {
  const containerRef = useRef<HTMLDivElement>(null)

  const [{ isOpen, searchValue }, setState] = useState<ISelectState>(initValues)

  const optionsHash = useMemo(() => convertOptionsToHash(options), [options])

  const hasSearch = useMemo(() => (hideSearch ? false : options.length > 5), [options, hideSearch])

  const withSearchOptions = useMemo(() => options.map(prepareSearchOption), [options])

  const searchedOptions = useMemo(() => {
    if (!searchValue) return withSearchOptions

    const loweredSearch = searchValue.toLowerCase()
    const isStartId = loweredSearch.startsWith('id')
    const searchId = loweredSearch.replace(/[^0-9]/g, '')

    if (isStartId) return withSearchOptions.filter((record) => record.id.includes(searchId))
    return withSearchOptions.filter((record) => record.search.includes(loweredSearch))
  }, [withSearchOptions, searchValue])

  const activeName = useMemo(() => {
    if (!valueProp) return ''
    const activeOption = optionsHash[valueProp]
    if (!activeOption) {
      // console.error('NO OPTION');
      return ''
    }
    return showActiveId ? `${activeOption.name} (ID ${activeOption.id})` : activeOption.name
  }, [valueProp, optionsHash, showActiveId])

  const nextInputProps = useMemo(() => {
    const { className: inputClassName, ...rest } = inputProps
    return {
      ...rest,
      className: clsx(styles.input, inputClassName),
    }
  }, [inputProps])

  const endAdornment = useMemo(() => {
    if (loading) {
      return <Preloader variant="relative" size="small" />
    }

    if (valueProp && onClear) {
      return (
        <IconButton
          className={styles.closeButton}
          onClick={(event) => {
            event.stopPropagation()
            onClear()
          }}
        >
          <CrossSmallIcon />
        </IconButton>
      )
    }

    return error ? (
      <AttentionIcon color="error" />
    ) : (
      <ArrowSmallDownSmallIcon className={clsx(styles.arrow, { [styles.arrowOpen]: isOpen })} />
    )
  }, [onClear, error, isOpen, valueProp, loading])

  const onToggleOptionsList = useCallback(() => {
    if (disabled) return
    setState((prevState) => ({ ...prevState, isOpen: !prevState.isOpen }))
  }, [disabled])

  const onCloseOptionsList = useCallback(() => {
    setState((prevState) => ({ ...prevState, isOpen: false, searchValue: '' }))
  }, [])

  const onOptionClick = useCallback(
    (payload: IPrimaryKey) => {
      onChange(payload)
      onCloseOptionsList()
    },
    [onChange, onCloseOptionsList],
  )

  const onSearchChange = useCallback((event: InputChangeEvent) => {
    setState((prevState) => ({ ...prevState, searchValue: event.target.value }))
  }, [])

  return (
    <ClickAwayListener onClickAway={onCloseOptionsList}>
      <div className={clsx(styles.container, className)} ref={containerRef}>
        <FilledInput
          fullWidth // should be default prop
          readOnly
          error={error}
          disabled={disabled}
          className={styles.inputBase}
          autoComplete={autoComplete}
          value={activeName}
          endAdornment={endAdornment}
          inputProps={nextInputProps}
          onClick={onToggleOptionsList}
          {...props}
        />
        <OptionsList
          isOpen={isOpen}
          options={searchedOptions}
          portal={portal}
          showOptionId={showOptionId}
          value={valueProp}
          containerRef={containerRef}
          onClick={onOptionClick}
          renderOption={renderOption}
        >
          {hasSearch ? (
            <TextField // Не фокусируется поиск если portal = true в диалоге
              className={styles.searchInput}
              placeholder="Поиск"
              value={searchValue}
              endAdornment={<SearchIcon />}
              onChange={onSearchChange}
            />
          ) : null}
        </OptionsList>
      </div>
    </ClickAwayListener>
  )
}

export default Select
