import React from 'react'

import DropMenu from 'components/DropMenu'
import Text, { H4 } from 'components/Typography'
import TextInput from 'components/Inputs/TextInput'
import ValueTypeInput from 'components/Inputs/ValueTypeInput'
import floatFormat from 'utils/formInputs/floatFormat'

import Row from '../Row'
import CategoryGroupInput from '../CategoryGroupInput'
import { IBaseProductFormValues } from '../../types'

import styles from './specifications.module.scss'

type ISpecificationsProps = {
  isDisabledVariantInputs: boolean
  values: IBaseProductFormValues
}

const weightOptions: string[] = ['г', 'кг', 'т']
const volumeOptions: string[] = ['л', 'м3']
const lengthOptions: string[] = ['мм', 'см', 'м']

const Specifications: React.FC<ISpecificationsProps> = ({ isDisabledVariantInputs, values }) => (
  <DropMenu title="Характеристики" initOpen={false}>
    <H4 className={styles.title} color="textSecondary">
      Весогабаритные характеристики
    </H4>
    <Row>
      <ValueTypeInput
        name="weight"
        className={styles.sizeInput}
        disabled={isDisabledVariantInputs}
        label="Вес"
        placeholder="Введите число"
        typeOptions={weightOptions}
        transform={floatFormat}
      />
      <ValueTypeInput
        name="volume"
        className={styles.sizeInput}
        disabled={isDisabledVariantInputs}
        label="Объем"
        placeholder="Введите число"
        typeOptions={volumeOptions}
        transform={floatFormat}
      />
    </Row>
    <Row>
      <ValueTypeInput
        name="length"
        className={styles.sizeInput}
        disabled={isDisabledVariantInputs}
        label="Длина"
        placeholder="Введите число"
        typeOptions={lengthOptions}
        transform={floatFormat}
      />
      <div className={styles.inputInfoGroup}>
        <TextInput
          name="width"
          className={styles.sizeInput}
          disabled={isDisabledVariantInputs}
          label="Ширина"
          placeholder="Введите число"
          transform={floatFormat}
        />
        <Text className={styles.typeInfo}>{values.length.type}</Text>
      </div>
      <div className={styles.inputInfoGroup}>
        <TextInput
          name="height"
          className={styles.sizeInput}
          disabled={isDisabledVariantInputs}
          label="Высота"
          placeholder="Введите число"
          transform={floatFormat}
        />
        <Text className={styles.typeInfo}>{values.length.type}</Text>
      </div>
    </Row>
    <CategoryGroupInput name="attributes" category_id={values.category_id} />
  </DropMenu>
)

export default Specifications
