import React, { ElementType, ReactElement, forwardRef } from 'react'
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'
import MuiLink, { LinkProps as MuiLinkProps } from '@material-ui/core/Link'

import styles from './link.module.scss'

type IRouterLinkProps = Omit<RouterLinkProps, 'to'>

export type ILinkProps = MuiLinkProps & {
  external?: boolean
  disabled?: boolean
  component?: ElementType<any>
  startIcon?: ReactElement
  endIcon?: ReactElement
}

const LinkComponent: React.FC<IRouterLinkProps> = forwardRef<HTMLAnchorElement, IRouterLinkProps>(
  ({ href = '', ...props }, ref) => <RouterLink to={href} ref={ref} {...props} />,
)

const Link: React.FC<ILinkProps> = ({
  external,
  href,
  component = LinkComponent,
  startIcon,
  endIcon,
  type = 'button',
  children,
  ...props
}) => (
  <MuiLink
    href={href}
    target={external ? '_blank' : undefined}
    rel={external ? 'noopener noreferrer' : undefined}
    component={external ? 'a' : component}
    type={component === 'button' ? type : undefined}
    {...props}
  >
    {startIcon ? <span className={styles.startIconWrapper}>{startIcon}</span> : null}
    {children}
    {endIcon ? <span className={styles.endIconWrapper}>{endIcon}</span> : null}
  </MuiLink>
)

export default Link
