import React, { ChangeEvent, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { buildGetPaymentSettingsQuery, useGetPaymentSettings } from 'graphql/queries/getPaymentSettings'
import {
  buildSetPaymentSettingsStatusQuery,
  useSetPaymentSettingsStatus,
} from 'graphql/mutations/setPaymentSettingsStatus'
import useCmsLinks from 'hooks/useCmsLinks'
import useCmsParams from 'hooks/useCmsParams'
import useBoolean from 'hooks/useBoolean'
import useNotifyCms from 'hooks/useNotifyCms'

import RowItem from '../RowItem'
import PaymentMethodIcon from './icons/PaymentMethodIcon'

import styles from './PaymentMethods.module.scss'

type IPaymentMethodsProps = {
  onLoadingChange: (value: boolean) => void
}

const GET_PAYMENT_SETTINGS = buildGetPaymentSettingsQuery(`status`)
const SET_PAYMENT_SETTINGS_STATUS = buildSetPaymentSettingsStatusQuery(`status`)

const PaymentMethods: React.FC<IPaymentMethodsProps> = ({ onLoadingChange }) => {
  const { push } = useHistory()
  const { paymentMethods } = useCmsLinks()
  const { sellerId } = useCmsParams()
  const addNotify = useNotifyCms()

  const [status, setStatus] = useBoolean()

  const redirect = () => push(paymentMethods)

  const { error } = useGetPaymentSettings(GET_PAYMENT_SETTINGS, {
    variables: { seller_id: sellerId },
    fetchPolicy: 'network-only',
    onCompleted({ getPaymentSettings }) {
      onLoadingChange(false)
      setStatus.setValue(getPaymentSettings.status || false)
    },
    onError() {
      onLoadingChange(false)
    },
  })

  const [setPaymentSettingsStatus] = useSetPaymentSettingsStatus(SET_PAYMENT_SETTINGS_STATUS, {
    onError: () => addNotify('error'),
  })

  const onStatusChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target
      setStatus.setValue(checked)
      setPaymentSettingsStatus({ variables: { seller_id: sellerId, status: checked } })
    },
    [setStatus, sellerId],
  )

  return (
    <RowItem
      title="Способы оплаты"
      status={status}
      error={error}
      icon={
        <div className={styles.iconWrapper}>
          <PaymentMethodIcon />
        </div>
      }
      onSettingClick={redirect}
      onStatusChange={onStatusChange}
    />
  )
}

export default PaymentMethods
