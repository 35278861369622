import React, { useState, useCallback } from 'react'

import { buildGetApiAccessesQuery, useGetApiAccessesQuery } from 'graphql/queries/getApiAccesses'
import { buildAddApiAccessesQuery, useAddApiAccessMutation } from 'graphql/mutations/addApiAccess'
import { buildSetApiAccessQuery, useSetApiAccessMutation } from 'graphql/mutations/setApiAccess'
import { H2 } from 'components/Typography'
import Button from 'components/Button'
import TextField from 'components/TextField'
import getAppUrl from 'utils/getAppUrl'
import Dialog from 'components/Dialog'
import DialogContent from 'components/DialogContent'
import DialogCloseButton from 'components/DialogCloseButton'
import useCmsParams from 'hooks/useCmsParams'
import useNotifyCms from 'hooks/useNotifyCms'
import useBoolean from 'hooks/useBoolean'
import { IApiAccess } from 'types/types'

import { ReactComponent as ApiIcon } from './icons/api.svg'
import RowItem from '../RowItem'

import styles from './clientApi.module.scss'

type IClientApiProps = {
  onLoadingChange: (value: boolean) => void
}

const defaultFormValues = {
  id: '',
  login: '',
  password: '',
  status: false,
}

const initFormTouched = {
  login: false,
  password: false,
}

const FIELDS = `
  id
  login
  password
  status
`

const GET_API_ACCESS = buildGetApiAccessesQuery(FIELDS)
const ADD_API_ACCESS = buildAddApiAccessesQuery(FIELDS)
const SET_API_ACCESS = buildSetApiAccessQuery(FIELDS)

const ClientApi: React.FC<IClientApiProps> = ({ onLoadingChange }) => {
  const appUrl = getAppUrl()
  const addNotify = useNotifyCms()

  const { sellerId } = useCmsParams()

  const [isOpenDialog, setIsOpenDialog] = useBoolean()
  const [checkVisited, setCheckVisited] = useState(false)
  const [accesses, setAccesses] = useState<IApiAccess[]>([])
  const [formData, setFormData] = useState(defaultFormValues)
  const [isTouched, setIsTouched] = useState(initFormTouched)

  const [addAccess] = useAddApiAccessMutation(ADD_API_ACCESS, {
    onCompleted: () => addNotify('success'),
    onError: () => addNotify('error'),
  })

  const [setAccess] = useSetApiAccessMutation(SET_API_ACCESS, {
    onCompleted: () => addNotify('success'),
    onError: () => addNotify('error'),
  })

  const { error } = useGetApiAccessesQuery(GET_API_ACCESS, {
    fetchPolicy: 'network-only',
    variables: { seller_id: sellerId },
    onCompleted({ getApiAccesses }) {
      onLoadingChange(false)
      setAccesses(getApiAccesses)

      if (getApiAccesses.length > 0) {
        const { id, login, password, status } = getApiAccesses[0]
        setFormData({ id, login: login || '', password: password || '', status: status || false })
      }
    },
    onError() {
      onLoadingChange(false)
    },
  })

  const onCloseDialog = useCallback(() => {
    setIsTouched(initFormTouched)
    setIsOpenDialog.off()
  }, [setIsOpenDialog])

  const saveHandler = () => {
    const { id, login, password, status } = formData

    if (accesses.length === 0) {
      addAccess({
        variables: {
          seller_id: sellerId,
          input: { login, password, status: true },
        },
      })
    } else {
      setAccess({
        variables: {
          id,
          input: {
            login,
            password,
            status: checkVisited ? true : status,
          },
        },
      })
      setCheckVisited(false)
    }

    onCloseDialog()
  }

  const changeActiveService = () => {
    if (accesses.length === 0) {
      setIsOpenDialog.on()
      setCheckVisited(true)
    } else {
      setFormData({ ...formData, status: !formData.status })
      setAccess({
        variables: {
          id: formData.id,
          input: { login: formData.login, password: formData.password, status: !formData.status },
        },
      })
    }
  }

  return (
    <>
      <Dialog isOpen={isOpenDialog} onClose={onCloseDialog}>
        <DialogCloseButton onClick={onCloseDialog} />
        <DialogContent className={styles.content}>
          <H2>Параметры подключения</H2>
          <TextField
            name="login"
            label="Логин"
            value={formData.login}
            placeholder="Введите логин"
            offset="default"
            helperText={
              isTouched.login && formData.login.length < 4
                ? 'Поле логин должен содержать минимум 4 символа'
                : null
            }
            error={isTouched.login && formData.login.length < 4}
            onChange={(e) => setFormData((prev) => ({ ...prev, login: e.target.value }))}
            onBlur={() => setIsTouched((prevState) => ({ ...prevState, login: true }))}
          />

          <TextField
            name="password"
            label="Пароль"
            value={formData.password}
            placeholder="Введите пароль"
            offset="default"
            helperText={
              isTouched.password && formData.password.length < 8
                ? 'Поле пароль должен содержать минимум 8 символов'
                : null
            }
            error={isTouched.password && formData.password.length < 8}
            onChange={(e) => setFormData((prev) => ({ ...prev, password: e.target.value }))}
            onFocus={() => setIsTouched((prevState) => ({ ...prevState, password: true }))}
          />

          <TextField
            name="url"
            disabled
            label="URL Подключения"
            offset="default"
            value={`${appUrl}seller/api/documentation/`}
          />

          <Button disabled={!formData.login && !formData.password} onClick={saveHandler}>
            Сохранить
          </Button>
        </DialogContent>
      </Dialog>

      <RowItem
        title="API"
        icon={<ApiIcon />}
        status={formData.status}
        error={error}
        onSettingClick={setIsOpenDialog.on}
        onStatusChange={changeActiveService}
      />
    </>
  )
}

export default ClientApi
