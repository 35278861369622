import React, { useCallback, useMemo, useState } from 'react'

import Text, { H1 } from 'components/Typography'
import Button from 'components/Button'
import Dialog from 'components/Dialog'
import DialogContent from 'components/DialogContent'
import DialogCloseButton from 'components/DialogCloseButton'
import TextField from 'components/TextField'
import SelectField from 'components/SelectField'
import { useGetCategoriesQuery, buildGetCategoriesQuery } from 'graphql/queries/getCategories'
import { useGetProductsQuery, buildGetProductsQuery } from 'graphql/queries/getProducts'
import { SEARCH_DEBOUNCE_TIMEOUT, maxPaginate } from 'consts'
import ApiError from 'components/ApiError'
import Pagination from 'components/Pagination'
import Preloader from 'components/Preloader'
import useCmsParams from 'hooks/useCmsParams'
import buildPaginateParams from 'utils/buildPaginateParams'
import hasPagination from 'utils/hasPagination'
import NoSearchResult from 'components/NoSearchResult'
import useDebounceValue from 'hooks/useDebounceValue'

import Product from './components/Product'

import styles from './relatadProductsDialog.module.scss'

type IRelatadProductsDialogProps = {
  isOpen: boolean
  priceListId: IPrimaryKey | null
  initialProductIds: IPrimaryKey[]
  onClose: () => void
  onSubmit: (data: IPrimaryKey[]) => void
}

type IFilters = {
  page: number
  search: string
  categoryId: IPrimaryKey | null
}

const initialFilters: IFilters = {
  page: 1,
  search: '',
  categoryId: null,
}

const GET_CATEGORIES = buildGetCategoriesQuery(`
  id
  name
`)

const GET_PRODUCTS = buildGetProductsQuery(`
  id
  name
  vendor_code
  price
  images {
    id
    path
  }
`)

const RelatadProductsDialog: React.FC<IRelatadProductsDialogProps> = ({
  isOpen,
  priceListId,
  initialProductIds,
  onClose,
  onSubmit,
}) => {
  const { sellerId } = useCmsParams()

  const [{ search, categoryId, page }, setFileters] = useState<IFilters>(initialFilters)
  const [selectedIds, setSelectedIds] = useState<IPrimaryKey[]>(initialProductIds)

  const {
    data: categoriesData,
    loading: categoriesLoadding,
    error: categoriesError,
  } = useGetCategoriesQuery(GET_CATEGORIES, {
    variables: {
      seller_id: sellerId,
      paginate: maxPaginate,
    },
    skip: !isOpen,
    fetchPolicy: 'network-only',
  })

  const categories = useMemo(() => categoriesData?.getCategories.list || [], [categoriesData])

  const debouncedSearch = useDebounceValue(search, SEARCH_DEBOUNCE_TIMEOUT)

  const { data, loading, error } = useGetProductsQuery(GET_PRODUCTS, {
    variables: {
      seller_id: sellerId,
      search: debouncedSearch,
      category_id: categoryId,
      price_list_id: priceListId,
      sort_ids: initialProductIds,
      paginate: buildPaginateParams(page),
    },
    skip: !isOpen,
    fetchPolicy: 'network-only',
  })

  const { getProducts: { elements = 0, pages = 0 } = {} } = data || {}
  const products = useMemo(() => data?.getProducts.list || [], [data])

  const onSearchChange = useCallback((event: InputChangeEvent) => {
    const { value } = event.target
    setFileters((prevState) => ({ ...prevState, page: 1, search: value }))
  }, [])

  const onCategoryChange = useCallback((payload: IPrimaryKey) => {
    setFileters((prevState) => ({ ...prevState, page: 1, categoryId: payload }))
  }, [])

  const onCategoryClear = useCallback(() => {
    setFileters((prevState) => ({ ...prevState, page: 1, categoryId: null }))
  }, [])

  const onPageChange = useCallback((nextPage: number) => {
    setFileters((prevState) => ({ ...prevState, page: nextPage }))
  }, [])

  const onAddProduct = useCallback((payload: IPrimaryKey) => {
    setSelectedIds((prevState) => prevState.concat(payload))
  }, [])

  const onRemoveProduct = useCallback((payload: IPrimaryKey) => {
    setSelectedIds((prevState) => prevState.filter((id) => id !== payload))
  }, [])

  const onSave = useCallback(() => {
    onSubmit(selectedIds)
  }, [onSubmit, selectedIds])

  const onCloseHandler = useCallback(() => {
    onClose()
    setFileters(initialFilters)
    setSelectedIds(initialProductIds)
  }, [onClose, initialProductIds])

  return (
    <Dialog isOpen={isOpen} onClose={onCloseHandler}>
      <DialogCloseButton onClick={onCloseHandler} />

      <DialogContent className={styles.content}>
        <H1 className={styles.title}>Выберите товары</H1>
        <Text className={styles.subTitle}>К выбранным товарам будут применены особые условия</Text>

        <div>{categoriesError ? <ApiError error={categoriesError} /> : null}</div>

        <div className={styles.filtersGroup}>
          <TextField placeholder="Введите название или артикул" value={search} onChange={onSearchChange} />
          <SelectField
            placeholder="Выберите категорию"
            value={categoryId}
            loading={categoriesLoadding}
            options={categories}
            onChange={onCategoryChange}
            onClear={onCategoryClear}
          />
          <Button variant="outlined" onClick={onCloseHandler}>
            Отменить
          </Button>
          <Button onClick={onSave}>Сохранить</Button>
        </div>

        {error ? <ApiError error={error} /> : null}

        <div>
          {loading ? (
            <div className={styles.loadingWrapper}>
              <Preloader variant="relative" />
            </div>
          ) : (
            <div className={styles.productsList}>
              {products.map((product) => (
                <Product
                  key={product.id}
                  product={product}
                  selectedIds={selectedIds}
                  onAddProduct={onAddProduct}
                  onRemoveProduct={onRemoveProduct}
                />
              ))}

              {hasPagination(elements) ? (
                <Pagination page={page} count={pages} onChange={onPageChange} />
              ) : null}

              {!products.length ? <NoSearchResult /> : null}
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default RelatadProductsDialog
