import React, { Suspense, lazy, useMemo } from 'react'
import { Route, Redirect, useLocation, Switch } from 'react-router-dom'
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

import ActiveEngineeringWorks from 'pages/ActiveEngineeringWorks'
import Logout from 'pages/Logout'
import ResetPassword from 'pages/ResetPassword'
import SignIn from 'pages/SignIn'
import SignUp from 'pages/SignUp'
import RecoveryPassword from 'pages/RecoveryPassword'
import VerifyEmail from 'pages/VerifyEmail'
import Profile from 'pages/Profile'
import NotFound from 'pages/NotFound'
import ServerError from 'pages/ServerError'
import Main from 'pages/Main'
import NewIntegrationsRedirect from 'pages/NewIntegrationsRedirect'

import Preloader from 'components/Preloader'

import NotifyProvider from 'contexts/NotifyProvider'
import { useAuth } from 'contexts/AuthProvider'
import ShortcutsProvider from 'contexts/ShortcutsProvider'

import CMSPages from 'routes/CMSPages'

import { THEME } from 'config'

// const CMSPages = lazy(() => import(/* webpackChunkName: "CMSPages" */ 'routes/CMSPages'));
const UiKit = lazy(() => import(/* webpackChunkName: "UiKit" */ 'routes/UiKit'))

const isUrlContains = (path: string, search: string): boolean =>
  !!path.split('/').find((record) => record === search)

// TODO: add private route
const Admin: React.FC = () => {
  const { isAuth } = useAuth()
  const { pathname } = useLocation()

  const isAuthRoute = useMemo(() => isUrlContains(pathname, 'auth'), [pathname])
  const isEmailVerifyRoute = useMemo(() => isUrlContains(pathname, 'email-verify'), [pathname])

  if (window.location.pathname === '/reset-password' && window.location.search.includes('token')) {
    return (
      <Redirect
        to={{
          pathname: '/auth/reset-password',
          search: window.location.search,
          state: { token: window.location.search.replace('?token=', '') },
        }}
      />
    )
  }

  if (!isAuth && !isAuthRoute && !isEmailVerifyRoute) {
    return <Redirect to="/auth/sign-in" />
  }

  if (isAuth && isAuthRoute && !isEmailVerifyRoute) {
    return <Redirect to="/" />
  }

  return (
    // @ts-ignore
    <StylesProvider injectFirst>
      <ThemeProvider theme={THEME}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <NotifyProvider>
            <ShortcutsProvider>
              <Suspense fallback={<Preloader />}>
                <Switch>
                  <Route exact path="/" component={Main} />

                  <Route exact path="/email-verify/seller" component={VerifyEmail} />
                  <Route exact path="/email-verify/user" component={VerifyEmail} />

                  <Route exact path="/auth/reset-password" component={ResetPassword} />
                  <Route exact path="/auth/sign-in" component={SignIn} />
                  <Route exact path="/auth/sign-up" component={SignUp} />
                  <Route exact path="/auth/recovery" component={RecoveryPassword} />

                  <Route exact path="/logout" component={Logout} />

                  <Route exact path="/404" component={NotFound} />
                  <Route exact path="/500" component={ServerError} />

                  <Route exact path="/tech" component={ActiveEngineeringWorks} />
                  <Route exact path="/profile" component={Profile} />
                  <Route exact path="/new-integrations" component={NewIntegrationsRedirect} />

                  <Route path="/ui-kit" component={UiKit} />
                  <Route path="/:sellerId" component={CMSPages} />

                  <Route component={NotFound} />
                </Switch>
              </Suspense>
            </ShortcutsProvider>
          </NotifyProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </StylesProvider>
  )
}

export default Admin
