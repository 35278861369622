import React from 'react'

import { ReactComponent as Icon } from 'assets/img/ActiveEngineeringWorks.svg'
import Text, { H1 } from 'components/Typography'

import style from './activeEngineeringWorks.module.scss'

const ServerError: React.FC = () => (
  <div className={style.EngineeringWorks}>
    <div className={style.EngineeringWorks__WrapImage}>
      <img
        className={style.EngineeringWorks__Image}
        src="/images/ActiveEngineeringWorks.png"
        alt="Active engineering works"
      />
      <Icon className={style.EngineeringWorks__Icon} />
    </div>
    <H1 className={style.EngineeringWorks__Title}>Ведутся технические работы</H1>
    <Text className={style.EngineeringWorks__Text}>Не переживайте, сайт скоро вернется к работе.</Text>
  </div>
)

export default ServerError
