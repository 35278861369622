import formikFieldEvent from './formikFieldEvent'

type IReturn = {
  target: {
    name: string
    value: string
  }
}

const numberFormat = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): IReturn => {
  const { value, name } = event.target

  const nextValue = value.replace(/[^0-9]/g, '')

  return formikFieldEvent(name, nextValue)
}

export default numberFormat
