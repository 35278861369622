import React, { useCallback } from 'react'
import { useField } from 'formik'

import ValueTypeField, { IValueTypeFieldProps } from 'components/ValueTypeField'
import { IValueType } from 'types/types'

export type IValueTypeInputProps = Omit<IValueTypeFieldProps, 'onTypeChange'> & {
  name: string
  transform?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

const defaultTransform = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => event

const ValueTypeInput: React.FC<IValueTypeInputProps> = ({
  name,
  autoComplete = 'off',
  transform = defaultTransform,
  ...props
}) => {
  const [valueInput, { error, touched, initialTouched }] = useField<string>(`${name}.value`)
  const [typeInput] = useField<string>(`${name}.type`)

  const { value, onChange, ...restInput } = valueInput

  const hasError = (touched || initialTouched) && !!error

  const onTypeChange = useCallback(
    (payload: IValueType) => {
      const nextEvent = {
        target: {
          name,
          value: payload,
        },
      }

      typeInput.onChange(nextEvent)
    },
    [typeInput, name],
  )

  return (
    <ValueTypeField
      autoComplete={autoComplete}
      error={hasError}
      helperText={hasError ? error : null}
      value={{ value, type: typeInput.value }}
      {...props}
      {...restInput}
      onChange={(event) => onChange(transform(event))}
      onTypeChange={onTypeChange}
    />
  )
}

export default ValueTypeInput
