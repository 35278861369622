import { gql } from '@apollo/client'

export type RecoveryResponseType = {
  forgotPassword: {
    message?: string
    status?: boolean
  }
}

export const RECOVERY = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email) {
      message
      status
    }
  }
`
