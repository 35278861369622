import { Overrides } from '@material-ui/core/styles/overrides'

const inputLabel: Overrides['MuiInputLabel'] = {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formControl: {
    position: 'relative',
    transform: 'none',
  },
  filled: {
    transform: 'none',

    '&$shrink': {
      transform: 'none',
    },
  },
  shrink: {
    transform: 'none',
  },
}

export default inputLabel
