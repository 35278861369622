import React, { useMemo } from 'react'
import { useField } from 'formik'

import ApiError from 'components/ApiError'
import SelectInput from 'components/Inputs/SelectInput'
import MultiSelectInput from 'components/Inputs/MultiSelectInput'
import convertAttributeToOption from 'utils/convertAttributeToOption'
import { usePriceRuleForm } from 'components/forms/PriceRuleForm/contexts'

import DiscountInputs from '../DiscountInputs'

import styles from './attribute.module.scss'

type IAttributeProps = {
  name: string
}

const Attribute: React.FC<IAttributeProps> = ({ name }) => {
  const { attributesList, attributesLoading, attributesError, attributesHash } = usePriceRuleForm()

  const [{ value: localGroupId }] = useField<IPrimaryKey>(`${name}.localGroupId`)

  const attributesIdsOptions = useMemo(() => {
    const attribute = attributesHash[localGroupId]
    if (!attribute) return []
    return attribute.attribute_values?.map(convertAttributeToOption) || []
  }, [localGroupId, attributesHash])

  if (attributesError) return <ApiError error={attributesError} />

  return (
    <>
      <div className={styles.container}>
        <SelectInput
          name={`${name}.localGroupId`}
          required
          loading={attributesLoading}
          label="Атрибут"
          placeholder="Выберите атрибут"
          options={attributesList}
        />
        <DiscountInputs name={name} />
      </div>
      <MultiSelectInput
        name={`${name}.localValueIds`}
        hasClear
        required
        label="Значения атрибута"
        options={attributesIdsOptions}
      />
    </>
  )
}

export default Attribute
