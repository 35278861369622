import React, { useCallback } from 'react'
import { Formik } from 'formik'
import { object, string, InferType } from 'yup'

import { H2 } from 'components/Typography'
import Button from 'components/Button'
import Dialog from 'components/Dialog'
import DialogContent from 'components/DialogContent'
import DialogCloseButton from 'components/DialogCloseButton'
import TextInput from 'components/Inputs/TextInput'
import { REQUIRED_MESSAGE, WRONG_INN_FORMAT } from 'consts/errorMessages'

import styles from './EditCompanyDialog.module.scss'

type IEditCompanyDialogProps = {
  isOpen: boolean
  initialValues: IEditCompanyFormValues
  onClose: () => void
  onSuccess: (payload: IEditCompanyFormValues) => void
}

type IEditCompanyFormValues = InferType<typeof validationSchema>

const validationSchema = object({
  name: string().default('').required(REQUIRED_MESSAGE),
  inn: string()
    .test('inn', (inn, { createError }) => {
      if (!inn) return true
      if (inn.length === 9 || inn.length === 10 || inn.length === 12) return true
      return createError({ message: WRONG_INN_FORMAT })
    })
    .default(''),
})

const EditCompanyDialog: React.FC<IEditCompanyDialogProps> = ({
  isOpen,
  initialValues,
  onClose,
  onSuccess,
}) => {
  const onSubmit = useCallback(
    (payload: IEditCompanyFormValues) => {
      onSuccess(payload)
      onClose()
    },
    [onClose, onSuccess],
  )

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <DialogCloseButton onClick={onClose} />

      <DialogContent className={styles.content}>
        <H2 className={styles.title}>Редактирование компании</H2>

        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <TextInput
                name="name"
                required
                label="Компания"
                placeholder="Введите название компании"
                margin="default"
              />
              <TextInput name="inn" label="ИНН" placeholder="Введите ИНН" margin="default" />

              <div className={styles.controlsGroup}>
                <Button type="submit">Сохранить</Button>
                <Button variant="outlined" onClick={onClose}>
                  Отменить
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default EditCompanyDialog
