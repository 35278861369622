import React, { useCallback, useState } from 'react'
import { useField } from 'formik'

import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import { Attribute as IAttribute } from 'types/types'
import useCurrentSeller from 'hooks/useCurrentSeller'

import AttributeTableHeader from './components/AttributeTableHeader'
import ProductVariant from './components/ProductVariant'

import { IVariantsFormValues } from '../../types'

import styles from './productVariants.module.scss'

type IProductVariantsProps = {
  name: string
  values: IVariantsFormValues
  attributesHash: Record<string, IAttribute>
}

const ProductVariants: React.FC<IProductVariantsProps> = ({ name, values, attributesHash }) => {
  const { stores_status } = useCurrentSeller()

  const [removeId, setRemoveId] = useState<IPrimaryKey | null>(null)
  const isDeleteOpen = Boolean(removeId)

  const [{ value: products }, meta, { setValue, setTouched }] =
    useField<IVariantsFormValues['products']>(name)
  const { weight, volume, length, width, height } = products[0]

  const removeByIndex = useCallback(() => {
    setTouched(true)
    setValue(products.filter((record) => record.id !== removeId))
    setRemoveId(null)
  }, [products, setValue, setTouched, removeId])

  return (
    <>
      <ConfirmDialog
        isOpen={isDeleteOpen}
        submitBtnText="Да, удалить"
        title="Вы уверены, что хотите удалить вариант товара?"
        onClose={() => setRemoveId(null)}
        onSubmit={removeByIndex}
      />

      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
            <tr>
              <td>Фото</td>
              <td>Активность</td>
              <td>Под заказ</td>
              <td>Артикул</td>
              <td>Сортировка</td>
              {stores_status ? (
                <td>Количество и хранение</td>
              ) : (
                <>
                  <td>Общий остаток</td>
                  <td>Ожидается поступл.</td>
                  <td>Кратность</td>
                </>
              )}
              <td>Цена</td>
              <td>{`Вес, ${weight?.type}`}</td>
              <td>{`Объем, ${volume?.type}`}</td>
              <td>{`Длина, ${length?.type}`}</td>
              <td>{`Ширина, ${width?.type}`}</td>
              <td>{`Высота, ${height?.type}`}</td>
              <AttributeTableHeader
                attributesHash={attributesHash}
                variantAttribute={values.variantAttribute}
              />
              {/* FOR DELETE ICON */}
              <td />
            </tr>
          </thead>
          <tbody>
            {products.map((product, index) => (
              <ProductVariant
                key={product.tempUniqId}
                name={`${name}[${index}]`}
                hasRemove={index > 0 && values.products.length > 1}
                onRemoveClick={() => setRemoveId(product.id)}
                attributesHash={attributesHash}
              />
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default ProductVariants
