import React, { useState, useCallback, useEffect } from 'react'
import { useMutation } from '@apollo/client'

import CodeEditor from 'components/CodeEditor'
import Button from 'components/Button'
import GoBackLink from 'components/GoBackLink'
import PageContainer from 'components/PageContainer'
import ResultTitle from 'components/ResultTitle'
import Preloader from 'components/Preloader'
import ApiError from 'components/ApiError'
import ErrorText from 'components/ErrorText'
import withErrorBoundary from 'hocs/withErrorBoundary'
import useCmsParams from 'hooks/useCmsParams'
import {
  SET_SELLER_SCRIPTS,
  ISetSellerScriptsParams,
  ISetSellerScriptsResponse,
} from 'graphql/mutations/setSellerScripts'
import useNotifyCms from 'hooks/useNotifyCms'
import useCurrentSeller from 'hooks/useCurrentSeller'
import { useSeller } from 'contexts/SellerProvider'

import styles from './customScripts.module.scss'

const CustomScripts: React.FC = () => {
  const { sellerId } = useCmsParams()
  const addNotify = useNotifyCms()

  const { refetch } = useSeller()
  const { head_scripts } = useCurrentSeller()

  const [inputValue, setInputValue] = useState<string>(head_scripts || '')
  const [error, setError] = useState<string | undefined>(undefined)

  const [onSetSellerScripts, { loading, error: apiError }] = useMutation<
    ISetSellerScriptsResponse,
    ISetSellerScriptsParams
  >(SET_SELLER_SCRIPTS, {
    onCompleted(data) {
      refetch()
      if (data.setSellerScripts.status) {
        addNotify('success')
      } else {
        setError(data.setSellerScripts.message)
        addNotify('warning')
      }
    },
    onError: () => addNotify('error'),
  })

  const onSave = useCallback(() => {
    onSetSellerScripts({
      variables: {
        id: sellerId,
        head_scripts: inputValue,
      },
    })
  }, [onSetSellerScripts, sellerId, inputValue])

  useEffect(() => {
    if (!head_scripts) return
    setInputValue(head_scripts)
  }, [head_scripts])

  if (loading) {
    return (
      <PageContainer>
        <GoBackLink href={`/${sellerId}/more`} />
        <ResultTitle>Добавление HTML кода</ResultTitle>
        <Preloader />
      </PageContainer>
    )
  }

  return (
    <PageContainer>
      <GoBackLink href={`/${sellerId}/more`} />
      <ResultTitle>Добавление HTML кода</ResultTitle>
      <div className={styles.content}>
        <CodeEditor value={inputValue} onChange={setInputValue} />
        {error && <ErrorText>{error}</ErrorText>}
        <ApiError error={apiError} />
      </div>
      <Button disabled={loading} onClick={onSave}>
        Сохранить
      </Button>
    </PageContainer>
  )
}

export default withErrorBoundary(CustomScripts)
