import Cookies from 'js-cookie'
import { createBrowserHistory } from 'history'

import { ApolloClient, InMemoryCache, HttpLink, from, ApolloLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'

import { API_URI } from './env'

// Create history
export const HISTORY = createBrowserHistory({
  basename: '/',
  forceRefresh: false,
})

const httpLink = new HttpLink({
  uri: API_URI,
  credentials: 'same-origin',
})

const unAuthError = 'Unauthenticated.'

const errorLink = onError(
  ({
    graphQLErrors,
    networkError,
    // operation,
    // forward,
  }) => {
    if (graphQLErrors) {
      console.log('graphQLErrors :>> ', graphQLErrors)
      if (graphQLErrors.find(({ message }) => message === unAuthError)) {
        HISTORY.push('/logout')
      }
    }

    if (networkError) {
      console.log('networkError :>> ', networkError)
      HISTORY.push('/500')
    }

    // return forward(operation); // calles function twice on error
  },
)

const authLink = new ApolloLink((operation, forward) => {
  const token = Cookies.get('token')

  operation.setContext(({ headers }: any) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }))

  return forward(operation)
})

const cache = new InMemoryCache({
  addTypename: false,
})

export const ADMIN_CLIENT = new ApolloClient({
  cache,
  link: from([errorLink, authLink, createUploadLink({ uri: API_URI }), httpLink]),
})
