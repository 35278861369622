import { DocumentNode, MutationHookOptions, gql, useMutation } from '@apollo/client'

import { Seller } from 'types/types'

export type ISetSellerShowVariantsParams = {
  id: IPrimaryKey
  show_variants: boolean
}

export type ISetSellerShowVariants = {
  setSellerShowVariants: Seller
}

export const buildSetSellerShowVariantsMutation = (fields: string): DocumentNode => gql`
  mutation setSellerShowVariants($id: ID!, $show_variants: Boolean!) {
    setSellerShowVariants(id: $id, show_variants: $show_variants) {
      ${fields}
    }
  }
`

export const useSetSellerShowVariants = <
  TData = ISetSellerShowVariants,
  TOptions = ISetSellerShowVariantsParams,
>(
  fields: DocumentNode,
  options?: MutationHookOptions<TData, TOptions>,
) => useMutation<TData, TOptions>(fields, options)
