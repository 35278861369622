import { gql } from '@apollo/client'

import { Status } from 'types/types'

export type ICopyBaseProductsListResponse = {
  copyBaseProductsList: Status
}

export const COPY_BASE_PRODUCTS_LIST = gql`
  mutation copyBaseProductsList($ids: [ID]!) {
    copyBaseProductsList(ids: $ids) {
      status
      message
    }
  }
`
