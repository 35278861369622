import React, { useCallback, useMemo, useState } from 'react'
import isEqual from 'lodash/isEqual'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import { NetworkStatus } from '@apollo/client'

import { buildGetBannersDefaultQuery, useGetBannersQuery } from 'graphql/queries/getBanners'
import {
  buildSetBannersNumbersQuery,
  useSetBannersNumbersMutation,
} from 'graphql/mutations/setBannersNumbers'
import Button from 'components/Button'
import Preloader from 'components/Preloader'
import ResultTitle from 'components/ResultTitle'
import CheckboxLabel from 'components/CheckboxLabel'
import PageContainer from 'components/PageContainer'
import Link from 'components/Link'
import NoSearchResult from 'components/NoSearchResult'
import ApiError from 'components/ApiError'
import { IBanner, IBannerNumberInput } from 'types/types'
import useCmsParams from 'hooks/useCmsParams'
import useSearchParams from 'hooks/useSearchParams'
import { maxPaginate } from 'consts'

import Banner from './components/Banner'
import Empty from './components/Empty'

import styles from './BannersList.module.scss'

type IPayload = {
  active: boolean
}

const initPayload: IPayload = {
  active: false,
}

const GET_BANNERS = buildGetBannersDefaultQuery(`
  id 
  name
  status
  count
  number
`)

const SET_BANNER_NUMBERS = buildSetBannersNumbersQuery(`
  id 
  number
`)

const reorder = <T,>(list: T[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

const prepareBannersList = (banners: IBanner[]): IBannerNumberInput[] =>
  banners.map(({ id }, index) => ({ id, number: index + 1 }))

const BannersList: React.FC = () => {
  const { sellerId } = useCmsParams()

  const [searchParams, setSearchParams] = useSearchParams<IPayload>(initPayload)
  const { active } = searchParams

  const hasFilters = useMemo(() => !isEqual(initPayload, searchParams), [searchParams])

  const [items, setItems] = useState<IBanner[]>([])

  const { data, loading, networkStatus, error, refetch } = useGetBannersQuery(GET_BANNERS, {
    fetchPolicy: 'network-only',
    variables: {
      seller_id: sellerId,
      status: active ? true : null,
      paginate: maxPaginate,
    },
    onCompleted(response) {
      setItems(response.getBanners.list || [])
    },
  })

  const { getBanners: { list: banners = [] } = {} } = data || {}

  const [setBannersNumbers] = useSetBannersNumbersMutation(SET_BANNER_NUMBERS)

  const onToggleActive = useCallback(() => {
    setSearchParams((prevState) => ({ ...prevState, active: !prevState.active }))
  }, [setSearchParams])

  const isEmpty = !hasFilters && !banners.length

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return
    const nextItems = reorder(items, result.source.index, result.destination.index)
    setItems(nextItems)
    setBannersNumbers({ variables: { seller_id: sellerId, list: prepareBannersList(nextItems) } })
  }

  if (networkStatus === NetworkStatus.loading) return <Preloader />

  if (error) {
    return (
      <PageContainer>
        <ResultTitle>Категории товаров</ResultTitle>
        <ApiError error={error} />
      </PageContainer>
    )
  }

  if (isEmpty) {
    return (
      <PageContainer>
        <Empty />
      </PageContainer>
    )
  }

  return (
    <PageContainer>
      <ResultTitle>Баннеры</ResultTitle>

      <div className={styles.filtersGroup}>
        <Link className={styles.createLink} href={`/${sellerId}/options/banners/create`}>
          <Button>Создать новый баннер</Button>
        </Link>
        <CheckboxLabel label="Только активные" checked={active} onChange={onToggleActive} />
      </div>

      {loading ? (
        <Preloader />
      ) : (
        <>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="banners-list">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {items.map((item, index) => (
                    <Banner key={item.id} index={index} banner={item} refetchList={refetch} />
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          {!banners.length && <NoSearchResult />}
        </>
      )}
    </PageContainer>
  )
}

export default BannersList
