import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import Link from 'components/Link'
import ArrowSmall from 'components/Icons/ArrowDownSmallIcon'
import block from 'utils/block'

import styles from './goBackLink.module.scss'

type IGoBackLinkProps = {
  href: string
  className?: string
  margin?: 'default' | 'none'
}

const b = block(styles)

const GoBackLink: React.FC<IGoBackLinkProps> = ({
  className,
  href,
  margin = 'default',
  children = 'Вернуться назад',
}) => {
  const { goBack, push, length } = useHistory()

  const onClick = useCallback(() => {
    if (length >= 2) {
      goBack()
    } else {
      push(href)
    }
  }, [goBack, push, href, length])

  return (
    <Link
      component="button"
      className={b('link', { margin }, className)}
      startIcon={<ArrowSmall className={b('icon')} />}
      onClick={onClick}
    >
      {children}
    </Link>
  )
}

export default GoBackLink
