import React from 'react'
import { ApolloError } from '@apollo/client'

import ErrorText from 'components/ErrorText'

type IErrorTextProps = {
  title?: string
  error?: ApolloError
}

const ApiError: React.FC<IErrorTextProps> = ({ title, error }) => {
  if (!error) return null

  if (!error.graphQLErrors.length) {
    return (
      <>
        {title ? <ErrorText>{title}</ErrorText> : null}
        <ErrorText>{error.message}</ErrorText>;
      </>
    )
  }

  return (
    <>
      {title ? <ErrorText>{title}</ErrorText> : null}
      {error.graphQLErrors.map((errorItem, index) => (
        <ErrorText key={index}>{errorItem.message}</ErrorText>
      ))}
    </>
  )
}

export default ApiError
