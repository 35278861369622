import { array, object, string } from 'yup'

import { REQUIRED_MESSAGE } from 'consts/errorMessages'

type IAttributesParent = {
  attribute_id?: string
  value_ids: IPrimaryKey[]
}

const validationSchema = () =>
  object({
    name: string().required(REQUIRED_MESSAGE),
    group_id: string().nullable().required(REQUIRED_MESSAGE),
    number: string().required(REQUIRED_MESSAGE),
    attributes: array(
      object({
        value_ids: array(string()).test(
          'value_ids',
          'Поле должно содержать минимум одно значение',
          (value, { parent, createError }) => {
            const { attribute_id } = parent as IAttributesParent

            if (!attribute_id) return true

            if (!!attribute_id && !value?.length) {
              return createError({ message: 'Поле должно содержать минимум один атрибут' })
            }

            return true
          },
        ),
      }),
    ),
  })

export default validationSchema
