import React, { memo, useCallback, useMemo, useState } from 'react'
import isNumber from 'lodash/isNumber'

import { useGet1CAccessesQuery } from 'graphql/queries/get1CAccesses'
import { useSet1CAccessMutation } from 'graphql/mutations/set1CAccess'
import { useAdd1CAccessMutation } from 'graphql/mutations/add1CAccess'
import ResultTitle from 'components/ResultTitle'
import PageContainer from 'components/PageContainer'
import GoBackLink from 'components/GoBackLink'
import Preloader from 'components/Preloader'
import ApiError from 'components/ApiError'
import useCmsParams from 'hooks/useCmsParams'
import DropMenu from 'components/DropMenu'
import RadioGroup from 'components/RadioGroup'
import RadioLabel from 'components/RadioLabel'
import Button from 'components/Button'
import TextField from 'components/TextField'
import Switch from 'components/Switch'
import useBoolean from 'hooks/useBoolean'
import useCmsLinks from 'hooks/useCmsLinks'
import useNotifyCms from 'hooks/useNotifyCms'
import withErrorBoundary from 'hocs/withErrorBoundary'
import getAppUrl from 'utils/getAppUrl'
import { IExchange1CAccess } from 'types/types'

import { ADD_1C_ACCESSES_FIELDS, GET_1C_ACCESSES_FIELDS, SET_1C_ACCESSES_FIELDS } from './queries'

import styles from './oneSIntegration.module.scss'

type IFormValues = {
  id: string
  login: string
  mode: number
  password: string
  status_products: boolean
}

const initFormDataValue: IFormValues = {
  id: '',
  login: '',
  mode: 1,
  password: '',
  status_products: false,
}

const initFormState = {
  login: false,
  password: false,
}

const prepareInitialValues = (access: IExchange1CAccess): IFormValues => {
  const { id, login, mode, password, status_products } = access

  return {
    id,
    login: login || '',
    password: password || '',
    mode: isNumber(mode) ? mode : 1,
    status_products: mode === 2 ? false : Boolean(status_products),
  }
}

const OneSIntegration: React.FC = () => {
  const { sellerId } = useCmsParams()
  const { integrations } = useCmsLinks()
  const addNotify = useNotifyCms()
  const appUrl = getAppUrl()

  const [isEnabled, setIsEnabled] = useBoolean(false)
  const [isCreating, setIsCreating] = useBoolean(false)

  const [formData, setFormData] = useState<IFormValues>(initFormDataValue)
  const [isTouched, setIsTouched] = useState(initFormState)

  const errors = useMemo(
    () => ({
      login:
        isTouched.login && !formData.login?.length
          ? 'Обязательное поле'
          : isTouched.login && (formData.login?.length || 0) < 4
          ? 'Поле логин должен содержать минимум 4 символа'
          : undefined,
      password:
        isTouched.password && !formData.password?.length
          ? 'Обязательное поле'
          : isTouched.password && (formData.password?.length || 0) < 8
          ? 'Поле пароль должен содержать минимум 8 символов'
          : undefined,
    }),
    [formData, isTouched],
  )

  const { loading, error } = useGet1CAccessesQuery(GET_1C_ACCESSES_FIELDS, {
    fetchPolicy: 'network-only',
    variables: { seller_id: sellerId },
    onCompleted({ get1CAccesses }) {
      if (get1CAccesses.length > 0) {
        const access = get1CAccesses[0]
        setFormData(prepareInitialValues(access))
        setIsEnabled.setValue(access.status || false)
      } else {
        setIsCreating.on()
      }
    },
  })

  const [onAdd1CAccess, { loading: add1CAccessLoading, error: add1CAccessError }] = useAdd1CAccessMutation(
    ADD_1C_ACCESSES_FIELDS,
    {
      onCompleted({ add1CAccess }) {
        addNotify('success')
        setIsCreating.off()

        setFormData(prepareInitialValues(add1CAccess))
        setIsTouched(initFormState)
      },
      onError: () => addNotify('error'),
    },
  )

  const [onSet1CAccess, { loading: set1CAccessLoading, error: set1CAccessError }] = useSet1CAccessMutation(
    SET_1C_ACCESSES_FIELDS,
    {
      onCompleted({ set1CAccess }) {
        addNotify('success')

        setFormData(prepareInitialValues(set1CAccess))
        setIsTouched(initFormState)
      },
      onError: () => addNotify('error'),
    },
  )

  const isLoading = loading || add1CAccessLoading || set1CAccessLoading

  const toggleStatus = useCallback(() => {
    setIsEnabled.toggle()
    setIsTouched({ login: true, password: true })

    if (!isCreating) {
      onSet1CAccess({
        variables: {
          id: formData.id,
          input: {
            login: formData.login,
            mode: formData.mode,
            password: formData.password,
            status: !isEnabled,
            status_products: formData.status_products,
          },
        },
      })
    }
  }, [setIsEnabled, isCreating, isEnabled, formData, onSet1CAccess])

  const updateMode = useCallback(
    (_: any, newValue: string) =>
      setFormData((prevState) => ({
        ...prevState,
        mode: Number(newValue),
        status_products: Number(newValue) === 2 ? false : prevState.status_products,
      })),
    [],
  )

  const toggleStatusProductsMode = useCallback(
    () => setFormData((prevState) => ({ ...prevState, status_products: !prevState.status_products })),
    [],
  )

  const saveHandler = useCallback(() => {
    if (isCreating) {
      onAdd1CAccess({
        variables: {
          seller_id: sellerId,
          input: {
            login: formData.login,
            password: formData.password,
            status: isEnabled,
            mode: formData.mode,
            status_products: formData.mode === 2 ? false : formData.status_products,
          },
        },
      })
    } else {
      onSet1CAccess({
        variables: {
          id: formData.id,
          input: {
            login: formData.login,
            password: formData.password,
            mode: formData.mode,
            status_products: formData.mode === 2 ? false : formData.status_products,
          },
        },
      })
    }
  }, [sellerId, isCreating, formData, isEnabled, onAdd1CAccess, onSet1CAccess])

  return (
    <PageContainer>
      <GoBackLink href={integrations} />

      <ResultTitle>1С: ERP, УТ, КА, УНФ</ResultTitle>

      {isLoading ? (
        <Preloader />
      ) : (
        <>
          <DropMenu
            title="Параметры подключения"
            titleChildren={
              <div
                className={styles.switchWrapper}
                role="presentation"
                onClick={(event) => event.stopPropagation()}
              >
                <Switch isChecked={isEnabled} onChange={toggleStatus} />
              </div>
            }
          >
            <div className={styles.contentGroup}>
              <TextField
                name="login"
                required
                label="Логин"
                placeholder="Введите логин"
                value={formData.login}
                onChange={(e) => setFormData((p) => ({ ...p, login: e.target.value }))}
                onBlur={() => setIsTouched((prevState) => ({ ...prevState, login: true }))}
                error={!!errors.login}
                helperText={errors.login}
              />
              <TextField
                name="password"
                required
                label="Пароль"
                placeholder="Введите пароль"
                value={formData.password}
                onChange={(e) => setFormData((p) => ({ ...p, password: e.target.value }))}
                onBlur={() => setIsTouched((prevState) => ({ ...prevState, password: true }))}
                error={!!errors.password}
                helperText={errors.password}
              />
              <TextField name="url" label="URL Подключения" disabled value={`${appUrl}seller/exchange-1c`} />

              <RadioGroup
                className={styles.parametersGroup}
                title="Параметры обновления"
                value={formData.mode?.toString()}
                onChange={updateMode}
              >
                <RadioLabel value="0" label="Обновлять все данные и создавать новые товары" />
                <RadioLabel value="1" label="Обновлять остатки и цены, создавать новые товары" />
                <RadioLabel value="2" label="Обновлять только остатки и цены" />
              </RadioGroup>

              <Switch
                isChecked={formData.status_products}
                disabled={formData.mode === 2}
                placement="right"
                gap="medium"
                label="Создавать новые товары в активном статусе"
                onChange={toggleStatusProductsMode}
              />
            </div>
          </DropMenu>

          <ApiError title="Get Accesses" error={error} />
          <ApiError title="Add Access" error={add1CAccessError} />
          <ApiError title="Set Access" error={set1CAccessError} />

          <Button disabled={isLoading} onClick={saveHandler}>
            Сохранить
          </Button>
        </>
      )}
    </PageContainer>
  )
}

export default withErrorBoundary(memo(OneSIntegration))
