import React from 'react'

import Button from 'components/Button'
import Text from 'components/Typography'
import ResultTitle from 'components/ResultTitle'
import PageContainer from 'components/PageContainer'
import Link from 'components/Link'
import useCmsParams from 'hooks/useCmsParams'

import styles from './empty.module.scss'

const Empty: React.FC = () => {
  const { sellerId } = useCmsParams()

  return (
    <PageContainer>
      <div className={styles.wrapper}>
        <ResultTitle>Здесь будут баннеры вашего магазина</ResultTitle>
        <Text className={styles.text}>У вас пока нет ни одного баннера. Создайте первый баннер!</Text>
        <Link href={`/${sellerId}/options/banners/create`}>
          <Button>Создать баннер</Button>
        </Link>
      </div>
    </PageContainer>
  )
}

export default Empty
