import React from 'react'
import { useField } from 'formik'

import Switch, { ISwitchProps } from 'components/Switch'

export type ISwitchInputProps = ISwitchProps & {
  name: string
}

const SwitchInput: React.FC<ISwitchInputProps> = ({ name, ...props }) => {
  const [input] = useField(name)

  const { value, ...restInput } = input

  return <Switch {...props} {...restInput} checked={value} />
}

export default SwitchInput
