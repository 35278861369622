import { useMemo } from 'react'
import type { ImageInput } from 'types/types'

const useLogo = (image?: ImageInput | string, isDelete = false): ImageInput | undefined => {
  const logo: ImageInput | undefined = useMemo(() => {
    if (image && typeof image !== 'string') {
      return { mime: 'image', base_path: image.base_path, name: image.name }
    }

    if (image) return undefined

    if (!isDelete) return undefined

    return { flag: 'delete' }
  }, [image])

  return logo
}

export default useLogo
