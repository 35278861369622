import MuiDialog, { DialogProps } from '@material-ui/core/Dialog'
import React from 'react'

type IDialogProps = Omit<DialogProps, 'open'> & {
  isOpen: boolean
}

const Dialog: React.FC<IDialogProps> = ({ isOpen, ...props }) => <MuiDialog open={isOpen} {...props} />

export default Dialog
