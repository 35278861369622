import React, { useMemo, useState } from 'react'
import { useMutation } from '@apollo/client'
import { Formik } from 'formik'
import isUndefined from 'lodash/isUndefined'
import omitBy from 'lodash/omitBy'

import TextInput from 'components/Inputs/TextInput'
import Button from 'components/Button'
import { useSeller } from 'contexts/SellerProvider'
import SuccessIcon from 'components/Icons/SuccessIcon'
import DenyIcon from 'components/Icons/DenyIcon'
import GoBackLink from 'components/GoBackLink'
import PageContainer from 'components/PageContainer'
import ResultTitle from 'components/ResultTitle'
import ReloadIcon from 'components/Icons/ReloadIcon'
import {
  SET_SELLER_EXT_DOMAIN,
  ISetSellerExtDomainParams,
  ISetSellerExtDomainResponse,
} from 'graphql/mutations/setSellerExtDomain'
import useInterval from 'hooks/useInterval'
import useCmsParams from 'hooks/useCmsParams'
import useNotifyCms from 'hooks/useNotifyCms'
import withErrorBoundary from 'hocs/withErrorBoundary'
import useCurrentSeller from 'hooks/useCurrentSeller'

import styles from './customDomain.module.scss'

interface IFormValues {
  customDomain?: string
}

const validator = ({ customDomain = '' }: IFormValues) => {
  const errors: Partial<IFormValues> = {}
  errors.customDomain = customDomain.length === 0 ? 'Необходимо заполнить поле' : undefined
  errors.customDomain = customDomain.match(/\.+[a-zа-я]{1,}/i) ? undefined : 'Неверно указан домен'
  const removedUndefindErrors = omitBy(errors, isUndefined) // isNull
  return removedUndefindErrors
}

const ListITem: React.FC = ({ children }) => <li className={styles.listItem}>{children}</li>

const AccentText: React.FC = ({ children }) => <span className={styles.accentText}>{children}</span>

const CustomDomain: React.FC = () => {
  const { sellerId } = useCmsParams()
  const addNotify = useNotifyCms()

  const { refetch } = useSeller()
  const { ext_domain, ext_domain_status = 0 } = useCurrentSeller()
  const [error, setError] = useState<string | null>(null)

  const [onSetSellerExtDomain] = useMutation<ISetSellerExtDomainResponse, ISetSellerExtDomainParams>(
    SET_SELLER_EXT_DOMAIN,
    {
      onCompleted({ setSellerExtDomain }) {
        refetch()
        if (setSellerExtDomain.status) {
          addNotify('success')
        } else {
          setError('Домен привязан к другому сайту')
        }
      },
      onError: () => addNotify('error'),
    },
  )

  const initialValues: IFormValues = useMemo(
    () => ({
      customDomain: ext_domain || '',
    }),
    [ext_domain],
  )

  const onSubmit = ({ customDomain }: any) => {
    setError(null)
    return onSetSellerExtDomain({
      variables: {
        id: sellerId,
        ext_domain: customDomain,
      },
    })
  }

  useInterval(() => {
    if (!ext_domain) return
    if (ext_domain_status !== 1) return
    refetch()
  }, 20000)

  return (
    <PageContainer>
      <GoBackLink href={`/${sellerId}/domain`} />
      <ResultTitle>Подключение своего домена</ResultTitle>
      <Formik enableReinitialize initialValues={initialValues} validate={validator} onSubmit={onSubmit}>
        {({ handleSubmit, isValid, isSubmitting, dirty }) => (
          <form onSubmit={handleSubmit}>
            <div className={styles.inputGroup}>
              <TextInput name="customDomain" className={styles.input} />
              {error && <p className={styles.submitError}>{error}</p>}
            </div>
            <div className={styles.buttonsGroup}>
              {ext_domain && !dirty ? (
                <Button type="submit" variant="outlined" disabled={!isValid || isSubmitting}>
                  Проверить настройку домена
                </Button>
              ) : (
                <Button type="submit" disabled={!isValid || isSubmitting}>
                  Сохранить
                </Button>
              )}
            </div>
          </form>
        )}
      </Formik>
      {!!ext_domain && (
        <>
          <p className={styles.descriptionTitle}>Результаты проверки:</p>
          <div className={styles.statusGroup}>
            {ext_domain_status === 1 && (
              <>
                <ReloadIcon className={styles.statusIcon} />
                <p>Идет настройка домена....</p>
              </>
            )}
            {ext_domain_status > 1 && (
              <>
                <SuccessIcon className={styles.statusIcon} />
                <p>Домен подключен корректно.</p>
              </>
            )}
            {ext_domain_status === 0 && (
              <>
                <DenyIcon className={styles.statusIcon} />
                <p>Домен подключен некорректно.</p>
              </>
            )}
          </div>
        </>
      )}
      <p className={styles.descriptionTitle}>Инструкция по настройке домена:</p>
      <div className={styles.listWrapper}>
        <ol>
          <ListITem>
            <p>
              Пропишите ваш домен в текстовое поле на странице. Необходимо указать непосредственно только сам
              домен, без http или https, например, <AccentText>sellty.ru</AccentText>.
            </p>
          </ListITem>
          <ListITem>
            <p>
              Добавьте одну из следующих записей в настройках регистратора вашего домена или DNS хостинга:
            </p>
            <ol type="a">
              <ListITem>
                <p>
                  Для домена первого уровня (вида <AccentText>https://sellty.ru</AccentText>) необходимо
                  добавить запись типа А со значением &quot;178.154.224.154&quot;, без кавычек.
                </p>
              </ListITem>
              <ListITem>
                <p>
                  Для домена второго уровня и выше (вида <AccentText>https://www.sellty.ru</AccentText> или{' '}
                  <AccentText>https://my.sellty.ru</AccentText> ) рекомендуется добавить запись типа CNAME с
                  адресом магазина на домене Sellty в качестве значения. Узнать, а также изменить ваш текущий
                  адрес магазина на домене Sellty вы можете на предыдущей странице настроек домена.
                </p>
                <p>Важно! Данный тип записи не может быть применен для доменов первого уровня.</p>
              </ListITem>
            </ol>
          </ListITem>
          <ListITem>
            <p>
              После внесения всех изменений нажмите кнопку &quot;Проверить настройку домена&quot; для проверки
              корректности настройки. Статус проверки вы увидите на текущей странице ниже в блоке
              &quot;Результаты проверки&quot;.
            </p>
          </ListITem>
        </ol>
      </div>
    </PageContainer>
  )
}

export default withErrorBoundary(CustomDomain)
