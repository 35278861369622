import { DocumentNode, gql, useMutation, MutationHookOptions } from '@apollo/client'

import { IContact, IContactInput } from 'types/types'

export type IAddContact = {
  addContact: IContact
}

export type IAddContactParams = {
  seller_id: IPrimaryKey
  input: IContactInput
}

export const buildAddContactQuery = (fields: string): DocumentNode => gql`
  mutation addContact($seller_id: ID!, $input: ContactInput) {
    addContact(seller_id: $seller_id, input: $input) {
      ${fields}
    }
  }
`

export const useAddContactMutation = <TData = IAddContact, TOptions = IAddContactParams>(
  fields: DocumentNode,
  options?: MutationHookOptions<TData, TOptions>,
) => useMutation<TData, TOptions>(fields, options)
