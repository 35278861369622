import React from 'react'

import { IContact } from 'types/types'
import Text from 'components/Typography'
import Paper from 'components/Paper'

import Contact from '../Contact'
import Row from '../Row'

type IResultProps = {
  contacts: IContact[]
  refetchList: () => void
}

const Result: React.FC<IResultProps> = ({ contacts, refetchList }) => (
  <Paper>
    <Row>
      <Text bold size="small">
        ФИО
      </Text>
      <Text bold size="small">
        Контрагенты
      </Text>
    </Row>
    {contacts.map((contact) => (
      <Contact key={contact.id} contact={contact} refetchList={refetchList} />
    ))}
  </Paper>
)

export default Result
