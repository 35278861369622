import { DocumentNode, MutationHookOptions, gql, useMutation } from '@apollo/client'

import type { Attribute, AttributeInput } from 'types/types'

export type IAddAttribute = {
  addAttribute: Attribute
}

export type IAddAttributeParams = {
  seller_id: IPrimaryKey
  attributes?: AttributeInput[]
}

export const buildAddAttributesQuery = (fields: string): DocumentNode => gql`
  mutation addAttributes($seller_id: ID!, $attributes: [AttributeInput]!) {
    addAttributes(seller_id: $seller_id, attributes: $attributes) {
      ${fields}
    }
  }
`

export const useAddAttributesMutation = <TData = IAddAttribute, TOptions = IAddAttributeParams>(
  fields: DocumentNode,
  options?: MutationHookOptions<TData, TOptions>,
) => useMutation<TData, TOptions>(fields, options)
