import { gql } from '@apollo/client'

export const SET_GROUP = gql`
  mutation setGroup($id: ID!, $input: GroupInput) {
    setGroup(id: $id, input: $input) {
      id
      seller_id
      name
      description
      number
      categories {
        id
      }
    }
  }
`
