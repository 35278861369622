import React, { ElementType } from 'react'
import MuiTypography, { TypographyProps } from '@material-ui/core/Typography'

import block from 'utils/block'

import styles from './typography.module.scss'

export type ITextProps = TypographyProps & {
  bold?: boolean
  size?: 'medium' | 'small' | 'caption'
  component?: ElementType<any>
}

const b = block(styles)

const Typography: React.FC<ITextProps> = ({
  bold = false,
  className,
  size,
  variant = 'body1',
  component = 'p',
  children,
  ...props
}) => (
  <MuiTypography
    className={b('text', { bold, size }, className)}
    variant={variant}
    component={component}
    {...props}
  >
    {children}
  </MuiTypography>
)

export const H1: React.FC<TypographyProps> = (props) => <Typography variant="h1" {...props} />

export const H2: React.FC<TypographyProps> = (props) => <Typography variant="h2" {...props} />

export const H3: React.FC<TypographyProps> = (props) => <Typography variant="h3" {...props} />

export const H4: React.FC<TypographyProps> = (props) => <Typography variant="h4" {...props} />

export default Typography
