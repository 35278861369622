import React, { InputHTMLAttributes, useState } from 'react'

import block from 'utils/block'

import { ReactComponent as Error } from '../../../../assets/img/Error.svg'

import styles from './TextField.module.scss'

type ITextFieldProps = {
  className?: string
  isError?: boolean
  errorHandler?: (isDirt: boolean) => boolean
  inputProps: InputHTMLAttributes<HTMLInputElement>
  messageError: string
}

const b = block(styles)

// TODO: reafactor existing use cases and remove this component
const TextField: React.FC<ITextFieldProps> = ({
  className,
  isError: isErrorProps = false,
  errorHandler = () => false,
  messageError,
  inputProps,
}) => {
  const [isDirt, setIsDirt] = useState(false)

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsDirt(true)

    const { onChange = () => {} } = inputProps

    onChange(e)
  }

  const isError = isErrorProps || errorHandler(isDirt)

  return (
    <div className={b('container', className)}>
      <div className={styles.TextField__WrapInput}>
        <input
          type="text"
          {...inputProps}
          className={b('input', { error: isError })}
          onChange={handleOnChange}
        />
        {isError && (
          <span className={styles.TextField__IconError}>
            <Error />
          </span>
        )}
      </div>
      {isError && <span className={styles.TextField__MessageError}>{messageError}</span>}
    </div>
  )
}

export default TextField
