import { gql } from '@apollo/client'

import { IAuthPayload, ILoginInput } from 'types/types'

export type ILoginParams = {
  input: ILoginInput
}

export type ILogin = {
  login: IAuthPayload
}

export const LOGIN = gql`
  mutation login($input: LoginInput) {
    login(input: $input) {
      access_token
      expires_in
    }
  }
`
