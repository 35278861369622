import React from 'react'
import clsx from 'clsx'

import SearchIcon from 'components/Icons/SearchIcon'
import IconButton from 'components/IconButton'
import TextField from 'components/TextField'
import CrossSmallIcon from 'components/Icons/CrossSmallIcon'

import styles from './resultSearchFilter.module.scss'

type IResultSearchFilterProps = {
  value?: string
  className?: string
  placeholder: string
  onChange: (event: InputChangeEvent) => void
  onClear: () => void
}

const ResultSearchFilter: React.FC<IResultSearchFilterProps> = ({
  className,
  value,
  placeholder,
  onChange,
  onClear,
}) => (
  <div className={clsx(styles.container, className)}>
    <TextField
      placeholder={placeholder}
      type="serach"
      onChange={onChange}
      value={value}
      endAdornment={
        value ? (
          <IconButton className={styles.clearButton} onClick={onClear}>
            <CrossSmallIcon />
          </IconButton>
        ) : (
          <SearchIcon />
        )
      }
    />
  </div>
)

export default ResultSearchFilter
