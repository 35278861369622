import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import Link from 'components/Link'
import useCmsParams from 'hooks/useCmsParams'
import TrashIcon from 'components/Icons/TrashIcon'
import DeleteCompany from 'components/ModelControls/DeleteCompany'

import styles from './controls.module.scss'

type IControlsProps = {
  canDeleteCompany: boolean
}

const Controls: React.FC<IControlsProps> = ({ canDeleteCompany }) => {
  const { sellerId, companyId } = useCmsParams()
  const { push, goBack, length } = useHistory()

  const onDeleteSuccess = useCallback(() => {
    if (length >= 2) {
      goBack()
    } else {
      push(`/${sellerId}/clients/list/`)
    }
  }, [goBack, push, sellerId, length])

  if (!canDeleteCompany) return null

  return (
    <DeleteCompany
      companyId={companyId}
      onSuccess={onDeleteSuccess}
      render={({ onClick }) => (
        <Link component="button" className={styles.deleteButton} startIcon={<TrashIcon />} onClick={onClick}>
          Удалить компанию
        </Link>
      )}
    />
  )
}

export default Controls
