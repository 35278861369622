import React, { SyntheticEvent, useCallback } from 'react'
import clsx from 'clsx'

import styles from './DropZone.module.scss'

type IDropZoneProps = {
  className?: string
  error?: boolean
  hideBorder?: boolean
  onChange: (value: File) => void
  onClick: () => void
}

const preventsDefaults = (event: SyntheticEvent) => {
  event.preventDefault()
  event.stopPropagation()
}

const DropZone: React.FC<IDropZoneProps> = ({
  className,
  error = false,
  hideBorder = false,
  onChange,
  onClick,
  children,
}) => {
  const handleFiles = useCallback(
    (files: FileList) => {
      const [file] = Object.values(files)
      onChange(file)
    },
    [onChange],
  )

  const onDropHandler = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      preventsDefaults(event)
      handleFiles(event.dataTransfer.files)
    },
    [handleFiles],
  )

  return (
    <div
      className={clsx(styles.container, className, {
        [styles.error]: error,
        [styles.hideBorder]: hideBorder,
      })}
      onDragOver={preventsDefaults}
      onDragEnter={preventsDefaults}
      onDragLeave={preventsDefaults}
      onDrop={onDropHandler}
      onClick={onClick}
    >
      {children}
    </div>
  )
}

export default DropZone
