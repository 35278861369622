import React from 'react'
import { useMutation } from '@apollo/client'

import {
  DELETE_ORDER_FILE,
  IDeleteOrderFileRequest,
  IDeleteOrderFileResponse,
} from 'graphql/mutations/deleteOrderFile'
import TrashIcon from 'components/Icons/TrashIcon'
import DownloadIcon from 'components/Icons/DownloadIcon'
import IconButton from 'components/IconButton'
import { IOrderFile, IOrder } from 'types/types'

import styles from './downloadLink.module.scss'

interface IDownloadLinkProps {
  file: IOrderFile
}

const DownloadLink: React.FC<IDownloadLinkProps> = ({ file }) => {
  const { id: fileId, name, path } = file

  const [deleteFile, { loading: deleteFileLoading }] = useMutation<
    IDeleteOrderFileResponse,
    IDeleteOrderFileRequest
  >(DELETE_ORDER_FILE, {
    fetchPolicy: 'no-cache',
    update(cache) {
      cache.modify({
        fields: {
          getOrder(order: IOrder): IOrder {
            return {
              ...order,
              files: order.files.filter((fileRecord) => fileRecord.id !== fileId),
            }
          },
        },
      })
    },
  })

  const onDeleteClick = () => deleteFile({ variables: { file_id: fileId } })

  return (
    <div className={styles.container}>
      <a className={styles.link} href={path} download target="_blank" rel="noreferrer">
        <DownloadIcon className={styles.downloadIcon} />
        {name}
      </a>
      <IconButton disabled={deleteFileLoading} color="inherit" onClick={onDeleteClick}>
        <TrashIcon />
      </IconButton>
    </div>
  )
}

export default DownloadLink
