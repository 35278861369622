import React from 'react'

import ErrorBoundary from 'components/ErrorBoundary'

// Component: React.ComponentType<T>
const withErrorBoundary = <T,>(Component: React.FC<T>, onError?: () => void): React.FC<T> => {
  const WithErrorBoundaryComponent: React.FC<T> = (props) => (
    <ErrorBoundary onError={onError}>
      <Component {...props} />
    </ErrorBoundary>
  )

  return WithErrorBoundaryComponent
}

export default withErrorBoundary
