import formikFieldEvent from './formikFieldEvent'

type IReturn = {
  target: {
    name: string
    value: string
  }
}

// TODO: rename to float format and add options to format
const priceFormat = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): IReturn => {
  const { name, value } = event.target

  const numbersAndDots = value.replace(/[^0-9.]/g, '')

  const [firstValue, secondValue] = numbersAndDots.split('.')

  const commasCount = (numbersAndDots.match(/\./g) || []).length

  if (commasCount === 0) {
    return formikFieldEvent(name, firstValue)
  }

  const nextValue = `${firstValue}.${secondValue?.slice(0, 2)}`

  return formikFieldEvent(name, nextValue)
}

export default priceFormat
