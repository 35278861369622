import { gql } from '@apollo/client'

import { Status } from 'types/types'

export type ISetSellerScriptsParams = {
  id: IPrimaryKey
  head_scripts: string
}

export type ISetSellerScriptsResponse = {
  setSellerScripts: Status
}

// id === seller_id
export const SET_SELLER_SCRIPTS = gql`
  mutation setSellerScripts($id: ID!, $head_scripts: String!) {
    setSellerScripts(id: $id, head_scripts: $head_scripts) {
      status
      message
    }
  }
`
