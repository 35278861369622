import { buildGetOrdersQuery } from 'graphql/queries/getOrders'

export const GET_ORDERS = buildGetOrdersQuery(`
  id
  number
  new
  created_at
  order_status {
    title
    color
  }
  customer {
    contact {
      name
    }
    company {
      name
    }
  }
  client {
    id
    name
  }
  contact {
    id
    name
  }
  company {
    id
    name
  }
  products {
    id
    price
    amount
  }
`)
