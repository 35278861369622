import React from 'react'

import { H1 } from 'components/Typography'

import styles from './resultTitle.module.scss'

const ResultTitle: React.FC = ({ children }) => (
  <H1 color="primary" className={styles.resultTitle}>
    {children}
  </H1>
)

export default ResultTitle
