import { ISetSellerSettingsParams } from 'graphql/mutations/setSellerSettings'

import { IGeneralFormValues } from '../types'

const prepareSellerSettingsSubmit = (payload: IGeneralFormValues): ISetSellerSettingsParams['input'] => {
  const { settings } = payload
  const { socials, time_works } = settings

  const nextSocials = Object.entries(socials)
    .map(([name, link]) => ({ name, link }))
    .filter(({ link }) => !!link)

  const nextTimeWorks = Object.values(time_works)

  return {
    socials: nextSocials,
    time_works: nextTimeWorks,
  }
}

export default prepareSellerSettingsSubmit
